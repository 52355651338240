import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

export const GET_MANUFACTURERS_QUERY = gql`
    query getManufacturers(
        $salon: ID!
        $manufacturer: ID!
        $withManufacturer: Boolean!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    manufacturers {
                        nodes {
                            id
                            ...manufacturer
                        }
                    }
                }
            }
        }

        manufacturer(id: $manufacturer) @include(if: $withManufacturer) {
            id
            ...manufacturer
        }
    }

    fragment manufacturer on Manufacturer {
        name
    }
`;

const mapOptions = manufacturers =>
    manufacturers.map(item => ({
        value: item.id,
        text: item.name,
    }));

const withData = graphql(GET_MANUFACTURERS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            manufacturer: ownProps.value || '',
            withManufacturer: !!ownProps.value,
        },
    }),
    props: ({ data: { loading, viewer, manufacturer } }) => {
        const options =
            !loading && viewer
                ? mapOptions(viewer.salon.manufacturers.nodes)
                : [];
        return {
            loading,
            options,
        };
    },
});

const limitInputProps = mapProps(({ meta, salon, dispatch, ...props }) => ({
    ...props,
}));

export default compose(
    connect(({ user }) => ({ salon: user.get('salon') })),
    withData,
    limitInputProps,
)(Select);
