/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { find, isEmpty } from 'lodash';
import AppointmentInvoice from '../../../components/details/invoice/AppointmentInvoice';
import { MASTER_APPOINTMENT_INVOICE_FRAGMENT } from '../../masterAccessQueries';

// Query to get invoice of appointment
export const GET_INVOICE_QUERY = gql`
    query getInvoice(
        $appointment: ID!
        $salon: ID!
        $lang: String!
        $client: ID
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    appointment(id: $appointment) {
                        id
                        invoice {
                            id
                            items {
                                id
                                type
                                entity {
                                    ... on Service {
                                        id
                                        name(lang: $lang)
                                    }
                                    ... on Product {
                                        id
                                        name
                                    }
                                }
                                seller
                                sellerType
                                quantity
                                price
                                paymentType
                                appointment {
                                    id
                                }
                            }
                            totalPrice
                            paid
                        }
                    }
                    memberships(client: $client) {
                        id
                        client {
                            id
                            firstName
                            lastName
                        }
                        author {
                            id
                            firstName
                            lastName
                        }
                        createdAt
                        serialNumber
                        service {
                            id
                            name(lang: $lang)
                            duration
                            price
                        }
                        quantity
                        price
                        pricePerUse
                        totalUses
                    }
                }
            }
            ...onMasterAppointmentInvoice
        }
    }
    ${MASTER_APPOINTMENT_INVOICE_FRAGMENT}
`;

const withData = graphql(GET_INVOICE_QUERY, {
    options: ownProps => ({
        variables: {
            appointment: ownProps.appointment.id,
            salon: ownProps.salon,
            lang: ownProps.currentLanguage,
            client: ownProps.appointment.client.id,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer }, ownProps }) => {
        const { appointment } = ownProps;
        const memberships = !loading && viewer ? viewer.salon.memberships : [];

        let membership = null;

        if (appointment.membership) {
            if (!isEmpty(memberships)) {
                membership = find(memberships, {
                    id: appointment.membership.id,
                });
            }
        } else if (
            !appointment.membership &&
            !isEmpty(memberships) &&
            appointment.invoice &&
            !appointment.invoice.paid
        ) {
            membership =
                find(
                    memberships,
                    i =>
                        i.client.id === appointment.client.id &&
                        i.service.id === appointment.service.id &&
                        i.totalUses < i.quantity,
                ) || null;
        }

        return {
            loading,
            invoice:
                !loading && viewer ? viewer.salon.appointment.invoice : null,
            membership,
        };
    },
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
)(AppointmentInvoice);
