/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from '../../../../../components';
import withModal from '../../../../../lib/withModal';
import { FormattedMessage } from 'react-intl';
import AddServiceGroupDialog from '../../../containers/service/group/AddServiceGroupDialog';

import type { ModalDialogTrigger } from '../../../../../type';

type AddServiceGroupButtonProps = ModalDialogTrigger;

const Button = styled(BaseButton)`
    margin-right: 0.75em !important;
`;

const AddServiceGroupButton = ({
    open,
    onOpen,
    onClose,
}: AddServiceGroupButtonProps) => (
    <AddServiceGroupDialog
        trigger={
            <Button onClick={onOpen} primary>
                <FormattedMessage
                    id="pages.settings.services.group.add"
                    defaultMessage="Add new group"
                />
            </Button>
        }
        open={open}
        onClose={onClose}
    />
);

export default withModal(AddServiceGroupButton);
