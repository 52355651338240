/**
 * Represents button that opens dialog with confirmation
 * request to delete specified working time.
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../../lib/withModal';
import { Button } from 'semantic-ui-react';
import DeleteWorkingTimeDialog from '../../containers/schedule/DeleteWorkingTimeDialog';
import { FormattedMessage } from 'react-intl';
import type {
    Master,
    WorkingTime,
    ModalDialogTrigger,
    ScheduleSettings,
} from '../../../../type';

type DeleteWorkingTimeButtonProps = {
    schedule: ScheduleSettings,
    master: Master,
    workingTime: WorkingTime,
} & ModalDialogTrigger;

const DeleteWorkingTimeButton = ({
    schedule,
    master,
    workingTime,
    open,
    onOpen,
    onClose,
}: DeleteWorkingTimeButtonProps) => (
    <DeleteWorkingTimeDialog
        trigger={
            <Button color="red" floated="left" onClick={onOpen}>
                <FormattedMessage
                    id="pages.settings.schedule.form.delete"
                    defaultMessage="Delete"
                />
            </Button>
        }
        schedule={schedule}
        master={master}
        workingTime={workingTime}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteWorkingTimeButton);
