/**
 * @flow
 */

import {
    Map
} from 'immutable';
import {
    pick
} from 'lodash';
import moment from 'moment';

import {
    saveState,
    loadState
} from '../lib/sessionStorage';

const STATE_KEY = 'masterAppointments';
const dateFmt = 'YYYY-MM-DD';

const cachedState = loadState(STATE_KEY) || {
    fromDate: moment().format(dateFmt),
    toDate: moment().add(7, 'days').format(dateFmt),
    master: null,
};

const initialState = new Map(cachedState);

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        case 'MASTER_APPOINTMENTS.SET_DATE':
            {
                Object.assign(cachedState, pick(action, ['fromDate', 'toDate']));
                saveState(STATE_KEY, cachedState);
                return state
                    .set('fromDate', action.fromDate)
                    .set('toDate', action.toDate);
            }
        case 'MASTER_APPOINTMENTS.SET_MASTER':
            {
                Object.assign(cachedState, pick(action, ['master']));
                saveState(STATE_KEY, cachedState);
                return state.set('master', action.master);
            }
        default:
            {
                return state;
            }
    }
};
