/**
 * @flow
 */

import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';

import SeminarsList from '../components/SeminarsList';

import { GET_SEMINARS_QUERY } from './queries';

const withData = graphql(GET_SEMINARS_QUERY, {
    options: ({ searchQuery }) => ({
        variables: {
            search: searchQuery || undefined,
            first: 20,
        },
    }),
    props: ({ data: { loading, viewer, fetchMore } }) => ({
        loading,
        fetchMore,
        seminars: get(viewer, 'seminars.edges', []),
        pageInfo: get(viewer, 'seminars.pageInfo', {}),
    }),
});

const mapStateToProps = ({ seminars, user }) => ({
    searchQuery: seminars.get('searchQuery'),
    supplier: user.get('id'),
});

const handlers = withHandlers({
    /**
     * Handle load more results event by requesting data after
     * end cursor from the last request.
     */
    loadMore: ownProps => () => {
        const { loading, searchQuery, pageInfo, fetchMore } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_SEMINARS_QUERY,
            variables: {
                first: 20,
                after: pageInfo.endCursor,
                search: searchQuery || undefined,
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.seminars.edges;
                const newEdges = fetchMoreResult.viewer.seminars.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.seminars.edges = [...prevEdges, ...newEdges];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(SeminarsList);
