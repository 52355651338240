/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import type { ModalDialog, ServiceGroup } from '../../../../../type';

type DeleteServiceGroupDialogProps = {
    serviceGroup: ServiceGroup,
} & ModalDialog;

const DeleteServiceGroupDialog = ({
    serviceGroup,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteServiceGroupDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.settings.services.delete.deleteGroupHeader"
                defaultMessage="Delete Service Group"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.settings.services.delete.deleteGroupText"
                defaultMessage="Are you sure you want to delete {serviceGroupName} service group?"
                values={{
                    serviceGroupName: serviceGroup.name,
                }}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.services.delete.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.services.delete.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteServiceGroupDialog;
