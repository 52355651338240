/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Image } from 'semantic-ui-react';
import ChangePasswordForm from '../containers/ChangePasswordForm';

const StyledContainer = styled(Container)`
    align-items: center;
    justify-content: center;
    display: flex !important;
    height: 100vh;
`;

const Content = styled.div`
    width: 500px;
`;

const Logo = styled(Image)`
    margin: 0 auto 3em auto;
`;

const ChangePasswordPage = ({ match }) => (
    <StyledContainer fluid>
        <Content>
            <Logo src="/images/appotime-logo-dashboard.png" />
            <ChangePasswordForm token={match.params.token} />
        </Content>
    </StyledContainer>
);

export default ChangePasswordPage;
