/**
 * @flow
 */

import { Map } from 'immutable';
import { loadState, saveState, removeState } from '../lib/sessionStorage';

let userState = loadState('user');

if (userState && userState.token && !userState.role) {
    removeState('user');
    userState = null;
}

const initialState = Map(userState || {});

export default function(state: Object = initialState, action: Object) {
    switch (action.type) {
        // Login by saving his token and details in local storage
        case 'USER.LOGIN': {
            // Keep user data in session storage to not logout user
            // if he refresh the page.
            saveState('user', action.user);
            return Map(action.user);
        }
        // Logout user by removing his auth data from local storage
        case 'USER.LOGOUT': {
            removeState('user');
            return state.clear();
        }
        // Switch focus to provided salon
        case 'USER.SWITCH_SALON': {
            // Updating user salon in state
            const user = loadState('user') || {};
            user.salon = action.salon;
            user.salonName = action.salonName;
            saveState('user', user);
            return state
                .set('salon', action.salon)
                .set('salonName', action.salonName);
        }
        case 'USER.SET_IS_OWNER': {
            return state.set('isOwner', action.state);
        }
        case 'USER.SET_IS_SYSADMIN': {
            return state.set('isSysadmin', action.state);
        }
        case 'USER.SET_IS_MASTER': {
            console.log(action);
            return state.set('isMaster', action.state);
        }
        case 'USER.DATA': {
            return state.set('userData', action.userData);
        }
        default: {
            return state;
        }
    }
}
