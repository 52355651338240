// Remove accents/diacritics
const normalizedNameString = nameString =>
    nameString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const sortByName = services =>
    services && services.length
        ? services.sort((a, b) =>
              normalizedNameString(a.name).localeCompare(
                  normalizedNameString(b.name),
              ),
          )
        : [];
