/**
 * @flow
 */

import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { map, find } from 'lodash';

import { updateLocale } from '../../actions/intl';
import Language from '../components/Language';

const intlMessages = defineMessages({
    en: {
        id: 'layout.salon.Language.en',
        defaultMessage: 'English',
    },
    lv: {
        id: 'layout.salon.Language.lv',
        defaultMessage: 'Latvian',
    },
    ru: {
        id: 'layout.salon.Language.ru',
        defaultMessage: 'Russian',
    },
});

const languages = [
    {
        locale: 'en',
        name: 'English',
        flag: 'us',
    },
    {
        locale: 'lv',
        name: 'Latvian',
        flag: 'lv',
    },
    {
        locale: 'ru',
        name: 'Russian',
        flag: 'ru',
    },
];

const props = withProps(({ currentLocale, intl }) => {
    const currentLanguage = find(languages, { locale: currentLocale });

    return {
        // Provide current language
        language: {
            ...currentLanguage,
            name: intl.formatMessage(intlMessages[currentLanguage.locale]),
        },
        // Map list of languages to options for dropdown menu
        options: map(languages, language => ({
            flag: language.flag,
            value: language.locale,
            text: intl.formatMessage(intlMessages[language.locale]),
        })),
    };
});

const mapStateToProps = ({ intl }) => ({
    currentLocale: intl.get('locale'),
});

export default compose(
    connect(
        mapStateToProps,
        { onChange: updateLocale },
    ),
    injectIntl,
    props,
)(Language);
