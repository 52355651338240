/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty } from 'lodash';
import { List, Loader } from 'semantic-ui-react';
import { Table } from '../../../components';
import EditMembershipButton from './EditMembershipButton';
import DeleteMembershipButton from './DeleteMembershipButton';

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.memberships.list.noMemberships"
                defaultMessage="You don't have memberships yet"
            />
        </Table.Cell>
    </Table.Row>
);

const MembershipRow = ({ membership }) => (
    <Table.Row>
        <Table.Cell>
            <span>{membership.serialNumber}</span>
        </Table.Cell>
        <Table.Cell>
            <span>
                {`${membership.client.firstName} ${membership.client.lastName ||
                    ''}`}
            </span>
        </Table.Cell>
        <Table.Cell>
            <span>{membership.quantity}</span>
        </Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <List.Item>
                        <EditMembershipButton membership={membership} />
                    </List.Item>
                </List.Item>
                <List.Item>
                    <DeleteMembershipButton membership={membership} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

const MembershipList = ({ memberships }) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.memberships.membershipList.name"
                        defaultMessage="Serial number"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.memberships.membershipList.client"
                        defaultMessage="Client"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.memberships.form.numberOfUses"
                        defaultMessage="Number of uses"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.memberships.membershipList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(memberships) ? (
                <EmptyMessage />
            ) : (
                memberships.map(
                    membership =>
                        !!membership && (
                            <MembershipRow
                                key={membership.id}
                                membership={membership}
                            />
                        ),
                )
            )}
        </Table.Body>
    </Table>
);

// Show spinner while cabinet list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(MembershipList);
