/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';

import type { ModalDialog, Master } from '../../../type';

type DeleteMasterDialogProps = {
    master: Master,
} & ModalDialog;

const DeleteMasterDialog = ({
    master,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteMasterDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            Delete Master
        </Header>
        <Modal.Content>
            <p>
                Are you sure you want to delete {master.firstName}{' '}
                {master.lastName}
                ?
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                No
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                Yes
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteMasterDialog;
