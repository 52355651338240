import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';

import CreateMembershipDialog from '../containers/CreateMembershipDialog';

const Button = styled(BaseButton)`
    width: 160px;
`;

const AddMembershipButton = ({ open, onClose, onOpen, memberships }) => (
    <CreateMembershipDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} labelPosition="left" primary icon>
                <Icon name="add" />
                <FormattedMessage
                    id="pages.memberships.form.createMembership"
                    defaultMessage="Create new membership"
                />
            </Button>
        }
        memberships={memberships}
    />
);

export default withModal(AddMembershipButton);
