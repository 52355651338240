/**
 * Represents component with list of time values
 */

import { withProps, mapProps, compose } from 'recompose';
import moment from 'moment';
import { range } from 'lodash';
import { Select } from 'semantic-ui-react';

const withOptions = withProps(({ startAt, endAt, step, formatTime }) => {
    startAt = step === 60 && (startAt / 30) % 2 > 0 ? startAt - 30 : startAt;
    const options = range(startAt, endAt + step, step).map(time => {
        const text = moment()
            .startOf('day')
            .add(time, 'minutes')
            .format(formatTime);
        return { value: time, text };
    });

    return { options };
});

const limitProps = mapProps(
    ({ meta, startAt, endAt, formatTime, ...props }) => ({ ...props }),
);

export default compose(
    withOptions,
    limitProps,
)(Select);
