/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { compose, defaultProps } from 'recompose';
import { map } from 'lodash';

const DEFAULT_COLORS = [
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
];

type ColorPickerProps = {
    colors: Array<string>,
    value: string,
    onChange: Function,
};

const Container = styled.div`
    background: rgb(255, 255, 255);
    position: relative;
`;

const ColorItem = styled.div`
    background: ${props => props.color};
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: relative;
    outline: none;
    display: inline-block;
    border-radius: 4px;
    margin: 0px 6px 0px 0px;
    ${props => props.active && `
        box-shadow: ${props.color} 0 0 4px;
        border: 2px solid ${darken(0.05, props.color)};
    `}
`;

const ColorPicker = ({ colors, value, onChange }: ColorPickerProps) => (
    <Container>
        {map(colors, (color, idx) => (
            <ColorItem
                key={idx}
                color={color}
                active={color === value}
                onClick={e => {
                    onChange(color);
                }}
            />
        ))}
    </Container>
);

const withDefaultProps = defaultProps({
    colors: DEFAULT_COLORS,
});

export default compose(withDefaultProps)(ColorPicker);
