/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DeleteServiceGroupDialog from '../../../components/services/group/DeleteServiceGroupDialog';
import { remove } from 'lodash';

import { GET_SERVICE_GROUPS_QUERY } from '../ServicesList';

// Query that deletes specified service group
const DELETE_SERVICE_GROUP_QUERY = gql`
    mutation deleteServiceGroup($input: DeleteServiceGroupInput!) {
        deleteServiceGroup(input: $input) {
            serviceGroupId
        }
    }
`;

const withData = graphql(DELETE_SERVICE_GROUP_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: { serviceGroupId: ownProps.serviceGroup.id },
                },
                // Implement optimistic response to compensate network latency
                // and delete service group before response
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteServiceGroup: {
                        __typename: 'DeleteServiceGroupPayload',
                        serviceGroupId: ownProps.serviceGroup.id,
                    },
                },
                // Remove deleted service group from local cache
                update: (store, { data: { deleteServiceGroup } }) => {
                    const data = store.readQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                    });
                    remove(
                        data.viewer.salon.serviceGroups.nodes,
                        serviceGroup =>
                            serviceGroup.id ===
                            deleteServiceGroup.serviceGroupId,
                    );
                    store.writeQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when service group was not deleted
                });
        },
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
)(DeleteServiceGroupDialog);
