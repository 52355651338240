import { set, isNil } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    manufacturerNameError: {
        id: 'pages.settings.manufacturers.form.addForm.manufacturerNameError',
        defaultMessage: 'Enter manufacturer name',
    },
});

export default (values, { intl }) => {
    const errors = {};

    if (isNil(values.name)) {
        set(
            errors,
            'name',
            intl.formatMessage(intlMessages.manufacturerNameError),
        );
    }

    return errors;
};
