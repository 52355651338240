/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { compose, branch, renderComponent, withState } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';
import moment from 'moment';

import { Table } from '../../../components';

const RevenueItem = styled.span`
    margin-left: 1.5em;
`;

const RevenueCell = styled(Table.HeaderCell)`
    border-left: none !important;
    .ui.table thead th& {
        text-align: right;
    }
`;

const ShowButton = styled(Icon)`
    margin-right: 8px !important;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const ReportItem = ({ item, showList, handleExpandList }) => (
    <Table celled striped fixed basic>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <ShowButton
                        name={showList ? 'minus' : 'plus'}
                        onClick={() => {
                            handleExpandList(!showList);
                        }}
                    />
                    {moment(item.boughtAt).format('DD.MM.YYYY')}
                </Table.HeaderCell>
                <RevenueCell colSpan={6}>
                    <RevenueItem>
                        <FormattedMessage
                            id="pages.report.ReportTable.quantity"
                            defaultMessage="Quantity"
                        />
                        : {item.quantity}
                    </RevenueItem>
                    <RevenueItem>
                        <FormattedMessage
                            id="pages.report.ReportTable.price"
                            defaultMessage="Price"
                        />
                        : {item.sum.toFixed(2)} &euro;
                    </RevenueItem>
                </RevenueCell>
            </Table.Row>
        </Table.Header>
        {showList && (
            <Table.Body>
                {map(item.entities, (entity, key) => (
                    <Table.Row key={key}>
                        <Table.Cell>{entity.salon.name}</Table.Cell>
                        <Table.Cell>{entity.salon.address}</Table.Cell>
                        <Table.Cell>{`${entity.seller.firstName} ${
                            entity.seller.lastName
                        }`}</Table.Cell>
                        <Table.Cell>{entity.entity.name}</Table.Cell>
                        <Table.Cell>{entity.quantity}</Table.Cell>
                        <Table.Cell>
                            {entity.price.toFixed(2)} &euro;
                        </Table.Cell>
                        <Table.Cell>{entity.sum.toFixed(2)} &euro;</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        )}
    </Table>
);

export default compose(withState('showList', 'handleExpandList', false))(
    ReportItem,
);
