import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { isEmpty, map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Modal, Table, EllipsisLoader } from '../../../../components';

type AppointmentsProps = {
    boughtItems: Array<any>,
};

type AppointmentRowProps = {
    boughtItem: Object,
};

/**
 * Show this message if list of appointments is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.memberships.details.appointments.noAppointments"
                defaultMessage="This membership has not been used yet"
            />
        </Table.Cell>
    </Table.Row>
);

const LoadMoreSpinner = () => <EllipsisLoader size="1em" />;
/**
 * A row of single appointment
 */
const AppointmentRow = ({ boughtItem }: AppointmentRowProps) => {
    const styledRow = moment(
        boughtItem.boughtAt,
        'DD.MM.YYYY HH:mm',
        true,
    ).isAfter(moment(moment(), 'DD.MM.YYYY HH:mm'));
    const TableRow = styled(Table.Row)`
        ${styledRow && ' font-weight: bold;'}
    `;

    return (
        <TableRow>
            <Table.Cell>
                {/* we need to parse number from string, because we receive timestamp from the server as a string
            this is a temporary solution until the problem with the data types in graphql is resolved */}
                {boughtItem.boughtAt}
            </Table.Cell>
            <Table.Cell>
                {`${boughtItem.firstName} ${boughtItem.lastName || ''}`}
            </Table.Cell>
            <Table.Cell>{boughtItem.name}</Table.Cell>
            <Table.Cell textAlign="right">
                {`${boughtItem.price.toFixed(2)} €`}
            </Table.Cell>
        </TableRow>
    );
};

const MembershipAppointments = ({
    boughtItems,
    loading,
}: AppointmentsProps) => {
    return (
        <Modal.Context>
            <Modal.Content>
                {loading ? (
                    <LoadMoreSpinner key={0} />
                ) : (
                    <Table celled striped selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id="pages.clients.details.appointments.date"
                                        defaultMessage="Date"
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id="pages.clients.details.appointments.master"
                                        defaultMessage="Master"
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id="pages.clients.details.appointments.service"
                                        defaultMessage="Service"
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">
                                    <FormattedMessage
                                        id="pages.clients.details.appointments.price"
                                        defaultMessage="Price"
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {isEmpty(boughtItems) ? (
                                <EmptyMessage />
                            ) : (
                                map(boughtItems, (item, key) => (
                                    <AppointmentRow
                                        key={key}
                                        boughtItem={item}
                                    />
                                ))
                            )}
                        </Table.Body>
                    </Table>
                )}
            </Modal.Content>
        </Modal.Context>
    );
};

export default MembershipAppointments;
