import {
    set,
    isNil,
} from 'lodash';
import {
    defineMessages
} from 'react-intl';
import {
    isValidNumber
} from 'libphonenumber-js';

const intlMessages = defineMessages({
    firstNameError: {
        id: 'pages.administrators.form.firstNameError',
        defaultMessage: 'Enter user first name',
    },
    emailError: {
        id: 'pages.administrators.form.emailError',
        defaultMessage: 'Enter user email',
    },
    passwordError: {
        id: 'pages.administrators.form.passwordError',
        defaultMessage: 'Enter user password',
    },
    passwordRepeatError: {
        id: 'pages.administrators.form.passwordRepeatError',
        defaultMessage: 'Confirm user password',
    },
    passwordDontMatchError: {
        id: 'pages.administrators.form.passwordDontMatchError',
        defaultMessage: 'Passwords dont match',
    },
    phoneError: {
        id: 'pages.administrators.form.phoneError',
        defaultMessage: 'Invalid phone number.'
    },
    noCountryCodeError: {
        id: 'pages.administrators.form.noCountryCodeError',
        defaultMessage: 'Phone number should start with country code'
    }
});

export default (values, ownProps) => {
    const {
        intl
    } = ownProps;
    const errors = {};

    if (isNil(values.firstName)) {
        set(errors, 'firstName', intl.formatMessage(intlMessages.firstNameError));
    }

    if (isNil(values.email)) {
        set(errors, 'email', intl.formatMessage(intlMessages.emailError));
    }

    if (ownProps.form === 'addAdministrator') {
        if (isNil(values.password)) {
            set(errors, 'password', intl.formatMessage(intlMessages.passwordError));
        }
    }

    if (!isNil(values.password)) {
        if (isNil(values.passwordRepeat)) {
            set(errors, 'passwordRepeat', intl.formatMessage(intlMessages.passwordRepeatError));
        } else if (values.password !== values.passwordRepeat) {
            const message = intl.formatMessage(intlMessages.passwordDontMatchError);
            set(errors, 'password', message);
            set(errors, 'passwordRepeat', message);
        }
    }

    // phone number can be either empty or valid
    if (values.phone) {
        if (values.phone[0] !== '+') {
            set(errors, 'phone', intl.formatMessage(intlMessages.noCountryCodeError));
        } else if (!isValidNumber(values.phone)) {
            set(errors, 'phone', intl.formatMessage(intlMessages.phoneError));
        }
    }

    return errors;
};
