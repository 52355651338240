import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Modal, Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { ClientForm } from '../form';

import type { ReduxForm } from '../../../../type.js';

type PersonalInformationProps = {
    onClose: Function,
    userLookupStatus: string,
} & ReduxForm;

const PersonalInformation = ({
    onClose,
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    checkFormPristine,
    ...props
}: PersonalInformationProps) => (
    <Modal.Context>
        <Modal.Content>
            <ClientForm
                {...props}
                userLookupStatus={userLookupStatus}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.clients.details.editClient.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.clients.details.editClient.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default PersonalInformation;
