import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Statistic } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import ReportSummaryLoader from './ReportSummaryLoader';

type ReportRevenueSummary = {
    total: number,
    cash: number,
    cc: number,
    gift: number,
};

type ReportRevenueProps = {
    summary: ReportRevenueSummary,
    loading: boolean,
    label: string,
};

const RevenueDetailsLabel = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
`;

const RevenueDetailsValue = styled.span`
    white-space: nowrap;
    font-size: 0.9em;
`;

const RevenueDetails = ({ summary }) => (
    <RevenueDetailsLabel>
        <RevenueDetailsValue>
            <FormattedMessage
                id="pages.report.reportSummary.cash"
                defaultMessage="Cash"
            />
            : {get(summary, 'cash', 0).toFixed(2)} &euro;
        </RevenueDetailsValue>
        <RevenueDetailsValue>
            <FormattedMessage
                id="pages.report.reportSummary.creditcard"
                defaultMessage="Credit Card"
            />
            : {get(summary, 'cc', 0).toFixed(2)}&euro;
        </RevenueDetailsValue>
        <RevenueDetailsValue>
            <FormattedMessage
                id="pages.report.reportSummary.giftcard"
                defaultMessage="Gift Card"
            />
            : {get(summary, 'gift', 0).toFixed(2)}&euro;
        </RevenueDetailsValue>
        <RevenueDetailsValue>
            <FormattedMessage
                id="pages.report.reportSummary.membership"
                defaultMessage="Membership"
            />
            : {get(summary, 'membership', 0).toFixed(2)}&euro;
        </RevenueDetailsValue>
    </RevenueDetailsLabel>
);

const ReportRevenue = ({ summary, loading, label }: ReportRevenueProps) => (
    <Statistic>
        <Statistic.Label>{label}</Statistic.Label>
        <Statistic.Value>
            <ReportSummaryLoader loading={loading}>
                <span>{get(summary, 'total', 0).toFixed(2)} &euro;</span>
            </ReportSummaryLoader>
        </Statistic.Value>
        {!loading && <RevenueDetails summary={summary} />}
    </Statistic>
);

export default ReportRevenue;
