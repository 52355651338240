/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { range } from 'lodash';

import { CELL_STEP } from './Cell';

import type { Master as MasterType, ScheduleSettings } from '../../type';

type ColumnProps = {
    schedule: ScheduleSettings,
    master: MasterType,
    renderCards: Function,
    renderCell: Function,
    cellWidth: number,
};

const ColumnWrapper = styled.div`
    border-right: 1px solid #e9e9e9;
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${props =>
        props.scrollProps &&
        props.scrollProps.minColumnWidth &&
        `min-width: 120px;`};
`;

const ColumnContent = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    & .cell:last-child {
        border-bottom: 0px !important;
    }
`;

const Column = ({
    schedule,
    provider,
    renderCards,
    renderCell,
    renderHeader,
    cellWidth,
    scrollProps,
    inCabinet,
}: ColumnProps) => (
    <ColumnWrapper style={{ width: cellWidth }} scrollProps={scrollProps}>
        {renderHeader({ provider })}
        <ColumnContent>
            {renderCards({ provider, inCabinet })}
            {range(schedule.startAt, schedule.endAt, CELL_STEP).map(time =>
                renderCell({ time, schedule, provider, inCabinet }),
            )}
        </ColumnContent>
    </ColumnWrapper>
);

export default Column;
