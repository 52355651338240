/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import BaseCell from '../../../../../components/timetable/Cell';
import withModal from '../../../../../lib/withModal';
import { FormattedMessage } from 'react-intl';
import CreateWorkingTimeDialog from '../../../containers/schedule/WorkingTimeDialog';

import type {
    ModalDialogTrigger,
    Master,
    ScheduleSettings,
    WorkingTime,
} from '../../../../../type';

type WorkingTimeCellProps = {
    time: number,
    master: Master,
    schedule: ScheduleSettings,
    workingTime: WorkingTime,
    disabled: boolean,
} & ModalDialogTrigger;

const Cell = styled(BaseCell)`
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    color: transparent;
    user-select: none;

    ${props =>
        !props.disabled &&
        `
        &:hover {
            background: #eef8fc;
            color: #35a3d5;
        }
    `};
`;

const WorkingTimeCell = ({
    time,
    provider,
    schedule,
    disabled,
    open,
    onOpen,
    onClose,
    ...props
}: WorkingTimeCellProps) => (
    <CreateWorkingTimeDialog
        trigger={
            <Cell
                {...props}
                className="cell"
                onClick={!disabled ? onOpen : null}
            >
                <FormattedMessage
                    id="pages.settings.schedule.scheduleList.addTime"
                    defaultMessage="+ Add time"
                />
            </Cell>
        }
        open={open}
        onClose={onClose}
        time={time}
        master={provider}
        schedule={schedule}
        workingTime={{
            startAt: time,
            endAt: time + schedule.step,
        }}
    />
);

export default withModal(WorkingTimeCell);
