/**
 * @flow
 */

import { connect } from 'react-redux';
import { setDate } from '../../../actions/appointments';
import { DatePicker } from '../../../components';

const mapStateToProps = ({ appointments }) => ({
    value: appointments.get('date'),
    dayPickerProps: {
        month: appointments.get('date'),
        selectedDays: [appointments.get('date')],
    },
});

export default connect(mapStateToProps, { onChange: setDate })(DatePicker);
