/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Modal, Button, Form, Icon, Message } from 'semantic-ui-react';
import { FormField } from '../../../../components';
import TimeSpanSelect from '../../containers/schedule/TimeSpanSelect';
import DeleteWorkingTimeButton from './DeleteWorkingTimeButton';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

import type {
    ScheduleSettings,
    ModalDialog,
    ReduxForm,
    Master,
    WorkingTime,
    Intl,
} from '../../../../type';

type WorkingTimeDialogProps = {
    schedule: ScheduleSettings,
    master: Master,
    workingTime: WorkingTime,
    edit: boolean,
    intl: Intl,
} & ModalDialog &
    ReduxForm;

const fields = {
    timeSpan: FormField(TimeSpanSelect),
};

const FormGroup = styled(Form.Group)`
    margin-bottom: 0px !important;
`;

const intlMessages = defineMessages({
    editTime: {
        id: 'pages.settings.schedule.form.editTime',
        defaultMessage: 'Edit time',
    },
    addTime: {
        id: 'pages.settings.schedule.form.addTime',
        defaultMessage: 'Add time',
    },
    startAtPlaceholder: {
        id: 'pages.settings.schedule.form.startWorkingAt',
        defaultMessage: 'Start working at',
    },
    endAtPlaceholder: {
        id: 'pages.settings.schedule.form.finishWorkingAt',
        defaultMessage: 'Finish working at',
    },
    saveBtn: {
        id: 'pages.settings.schedule.form.save',
        defaultMessage: 'Save',
    },
    cancelBtn: {
        id: 'pages.settings.schedule.form.cancel',
        defaultMessage: 'Cancel',
    },
});

const WorkingTimeDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    schedule,
    master,
    workingTime,
    edit,
    intl,
    error,
    updated,
    setUpdated,
}: WorkingTimeDialogProps) => (
    <Modal
        size="small"
        trigger={trigger}
        onClose={() => {
            setUpdated(true);
            onClose();
        }}
        open={open}
    >
        <Modal.Header>
            {edit
                ? intl.formatMessage(intlMessages.editTime)
                : intl.formatMessage(intlMessages.addTime)}
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            {!updated && (
                <Message
                    icon="warning sign"
                    negative
                    content={
                        <FormattedMessage
                            id="pages.settings.schedule.form.deleteForm.notUpdated"
                            defaultMessage="Schedule cannot be changed, because there are appointments for that day"
                        />
                    }
                />
            )}
            <Form onSubmit={handleSubmit}>
                <FormGroup widths="equal">
                    <Field
                        name="startAt"
                        component={fields.timeSpan}
                        placeholder={intl.formatMessage(
                            intlMessages.startAtPlaceholder,
                        )}
                        master={master}
                        simpleValue
                        required
                    />
                    <Field
                        name="endAt"
                        component={fields.timeSpan}
                        placeholder={intl.formatMessage(
                            intlMessages.endAtPlaceholder,
                        )}
                        master={master}
                        simpleValue
                        required
                    />
                </FormGroup>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            {workingTime.id && (
                <DeleteWorkingTimeButton
                    schedule={schedule}
                    master={master}
                    workingTime={workingTime}
                />
            )}

            <Button
                onClick={() => {
                    setUpdated(true);
                    handleSubmit();
                }}
                disabled={submitting}
                loading={submitting}
                primary
            >
                <Icon name="checkmark" />
                {intl.formatMessage(intlMessages.saveBtn)}
            </Button>
            <Button
                onClick={() => {
                    setUpdated(true);
                    onClose();
                }}
                disabled={submitting}
                basic
            >
                <Icon name="remove" />
                {intl.formatMessage(intlMessages.cancelBtn)}
            </Button>
        </Modal.Actions>
    </Modal>
);

export default injectIntl(WorkingTimeDialog);
