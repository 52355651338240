import React from 'react';
import { get } from 'lodash';
import { Grid, Statistic } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import ReportSummaryLoader from '../ReportSummaryLoader';
import ReportRevenue from '../ReportRevenue';

const ReportSummary = ({ loading, summary }) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column textAlign="center">
                <FormattedMessage
                    id="pages.report.repostSummary.serviceRevenue"
                    defaultMessage="Revenue of services"
                >
                    {label => (
                        <ReportRevenue
                            loading={loading}
                            summary={get(summary, 'revenue')}
                            label={label}
                        />
                    )}
                </FormattedMessage>
            </Grid.Column>
            <Grid.Column textAlign="center">
                <Statistic>
                    <Statistic.Label>
                        <FormattedMessage
                            id="pages.report.repostSummary.totalServicesSold"
                            defaultMessage="Total Services Sold"
                        />
                    </Statistic.Label>
                    <Statistic.Value>
                        <ReportSummaryLoader loading={loading}>
                            <span>{get(summary, 'totalCount', 0)}</span>
                        </ReportSummaryLoader>
                    </Statistic.Value>
                </Statistic>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default ReportSummary;
