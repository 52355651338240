/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import { Icon, Message, Form, Input } from 'semantic-ui-react';
import { Modal, Button, FormField } from '../../../../components';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import type { ModalDialog, ReduxForm, Intl } from '../../../../type';

type AddManufacturerDialogProps = { intl: Intl } & ModalDialog & ReduxForm;

const fields = {
    input: FormField(Input),
};

const intlMessages = defineMessages({
    manufacturerNamePlaceholder: {
        id: 'pages.settings.manufacturers.form.addForm.manufacturerName',
        defaultMessage: 'Manufacturer name',
    },
});

const AddManufacturerDialog = ({
    trigger,
    open,
    onClose,
    submitting,
    pristine,
    error,
    handleSubmit,
    intl,
}: AddManufacturerDialogProps) => (
    <Modal size="small" trigger={trigger} open={open} onClose={onClose}>
        <Modal.Header>
            <FormattedMessage
                id="pages.settings.manufacturers.form.addForm.addManufacturer"
                defaultMessage="Add manufacturer"
            />
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <Field
                    name="name"
                    component={fields.input}
                    placeholder={intl.formatMessage(
                        intlMessages.manufacturerNamePlaceholder,
                    )}
                    simpleValue
                />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                disabled={submitting || pristine}
                loading={submitting}
                onClick={handleSubmit}
                primary
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.manufacturers.form.addForm.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button disabled={submitting} onClick={onClose} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.manufacturers.form.addForm.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default injectIntl(AddManufacturerDialog);
