/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Segment, Image } from 'semantic-ui-react';
import Header from './Header';

const RootSegment = styled(Segment)`
    &.segment {
        padding: 76px 0 76px;
    }
`;

const CompanyLogo = styled(Image)`
    &.image {
        margin: 10px 24px 0 !important;
    }
`;

const intlMessages = defineMessages({
    header: {
        id: 'pages.landing.companies.header',
        defaultMessage: 'Used by 100+ companies',
    },
    subHeader: {
        id: 'pages.landing.companies.subHeader',
        defaultMessage: 'Here are few of early adopters:',
    },
});

const ComapniesSection = ({ intl }) => (
    <RootSegment textAlign="center" vertical basic>
        <Header as="h2">
            {intl.formatMessage(
                intlMessages.header,
            )}
            <Header.Subheader>
                {intl.formatMessage(
                    intlMessages.subHeader,
                )}
            </Header.Subheader>
        </Header>
        <Image.Group>
            <CompanyLogo src="/images/landing/companies/img1.png" />
            <CompanyLogo src="/images/landing/companies/img2.png" />
            <CompanyLogo src="/images/landing/companies/img3.png" />
            <CompanyLogo src="/images/landing/companies/img4.png" />
        </Image.Group>
    </RootSegment>
);

export default injectIntl(ComapniesSection);
