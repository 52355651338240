/**
 * Represents button that opens dialog with confirmation
 * request to delete specified appointment.
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../../lib/withModal';
import { Button } from 'semantic-ui-react';
import DeleteAppointmentDialog from '../../containers/details/DeleteAppointmentDialog';
import { FormattedMessage } from 'react-intl';
import type { Appointment, ModalDialogTrigger } from '../../../../type';

type DeleteAppointmentButtonProps = {
    appointment: Appointment,
} & ModalDialogTrigger;

const DeleteAppointmentButton = ({
    appointment,
    open,
    onOpen,
    onClose,
    masterAppointments,
    fromDate,
    toDate,
}: DeleteAppointmentButtonProps) => (
    <DeleteAppointmentDialog
        trigger={
            <Button color="red" floated="right" onClick={onOpen}>
                <FormattedMessage
                    id="pages.appointments.details.DeleteAppointmentButton.label"
                    defaultMessage="Delete"
                />
            </Button>
        }
        appointment={appointment}
        open={open}
        onClose={onClose}
        masterAppointments={masterAppointments}
        fromDate={fromDate}
        toDate={toDate}
    />
);

export default withModal(DeleteAppointmentButton);
