/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';
import CreateSupplierDialog from '../containers/CreateSupplierDialog';

import type { ModalDialog } from '../../../type';

const Button = styled(BaseButton)`
    width: 160px;
`;

type CreateSupplierButtonProps = {
    onOpen: Function,
} & ModalDialog;

const CreateSupplierButton = ({
    open,
    onClose,
    onOpen,
}: CreateSupplierButtonProps) => (
    <CreateSupplierDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} labelPosition="left" primary icon>
                <Icon name="add" />
                <FormattedMessage
                    id="page.suppliers.createSupplier.btn"
                    defaultMessage="Add Supplier"
                />
            </Button>
        }
    />
);

export default withModal(CreateSupplierButton);
