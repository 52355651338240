/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import styled from 'styled-components';
import { Form, Input, Button } from 'semantic-ui-react';
import { Field } from 'redux-form';

import { FormField } from '../../../../components';
import SalonSelect from '../../containers/form/SalonSelect';
import ManufacturerSelect from '../../containers/form/ManufacturerSelect';

import type { Field as FieldType, FieldArray } from 'redux-form';

type FieldsListProps = {
    field: FieldType,
    fields: FieldArray,
    change: Function,
    salons: Array,
    manufacturers: Array,
    onRemove: Function,
};

const fieldList = {
    salon: FormField(SalonSelect),
    manufacturer: FormField(ManufacturerSelect),
};

const RemoveButton = styled(Button)`
    margin-right: 0px !important;
`;

const ConnectionFields = ({
    field,
    fields,
    change,
    salons,
    manufacturers,
    onRemove,
}: FieldsListProps) => (
    <Form.Group>
        <Field
            name={`${field}.salon`}
            component={fieldList.salon}
            salons={salons}
            placeholder="Salon"
            fluid
            simpleValue
            fields={fields}
            width={8}
        />
        <Field
            name={`${field}.manufacturer`}
            component={fieldList.manufacturer}
            manufacturers={manufacturers}
            placeholder="Manufacturer"
            fluid
            change={change}
            field={field}
            fields={fields}
            simpleValue
            width={8}
        />
        <Form.Field>
            <RemoveButton onClick={onRemove} color="red" icon="trash" basic />
        </Form.Field>
    </Form.Group>
);

export default ConnectionFields;
