import React from 'react';
import styled from 'styled-components';
import { EllipsisLoader } from '../../../components';

const StyledEllipsisLoader = styled(EllipsisLoader)`
    & .bounce {
        line-height: 1em;
        margin-top: -10px;
    }
`;

const ReportSummaryLoader = ({ loading, children }) => {
    if (!loading) {
        return children;
    }
    return <StyledEllipsisLoader size="0.3em" />;
};

export default ReportSummaryLoader;
