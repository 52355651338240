/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from '../../../lib/withModal';
import { SeminarDetails } from './details';

import type { Seminar, ModalDialogTrigger } from '../../../type';

type EditSeminarButtonProps = {
    seminar: Seminar,
} & ModalDialogTrigger;

const EditSeminarButton = ({
    seminar,
    open,
    onOpen,
    onClose,
}: EditSeminarButtonProps) => (
    <SeminarDetails
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.seminars.EditSeminarButton.label"
                    defaultMessage="Edit"
                />
            </a>
        }
        onClose={onClose}
        open={open}
        seminar={seminar}
    />
);

export default withModal(EditSeminarButton);
