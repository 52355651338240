import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import type { ModalDialog, Client } from '../../../type';

type BlockedClientDialogProps = {
    client: Client,
} & ModalDialog;

const BlockedClientDialog = ({
    client,
    trigger,
    onCloseDialog,
    open,
    onClose,
    onDelete,
}: BlockedClientDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="ban" />
            <FormattedMessage
                id="pages.clients.BlockedClientDialog.blocked"
                defaultMessage="Blocked client!"
            />
        </Header>
        <Modal.Content>
            <p>
                <FormattedMessage
                    id="pages.clients.BlockedClientDialog.blockedText"
                    defaultMessage="{firstName} {lastName} has been blocked on {blockedAt} by {blockedByName} {blockedByLastName}. Would you like to continue?"
                    values={{
                        firstName: client.firstName,
                        lastName: client.lastName,
                        blockedAt: moment(client.blockedAt).format(
                            'DD.MM.YYYY',
                        ),
                        blockedByName:
                            client.blockedBy && client.blockedBy.firstName,
                        blockedByLastName:
                            client.blockedBy && client.blockedBy.lastName,
                    }}
                />
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={() => {
                    onCloseDialog();
                }}
                color="red"
                basic
                inverted
            >
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.clients.BlockedClientDialog.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onClose} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.clients.BlockedClientDialog.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default BlockedClientDialog;
