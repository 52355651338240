/**
 * @flow
 */

import React from 'react';
import { mapProps } from 'recompose';
import { Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const options = [
    {
        value: 'SERVICE',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.service"
                defaultMessage="Service"
            />
        ),
    },
    {
        value: 'PRODUCT',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.product"
                defaultMessage="Product"
            />
        ),
    },
];

const props = mapProps(({ meta, ...props }) => ({
    options,
    ...props,
}));

export default props(Select);
