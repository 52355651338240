/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { get, find } from 'lodash';

import { withTawk } from '../../../providers/Tawk';

import MasterAppointmentsPage from '../components/MasterAppointmentsPage';

const GET_MASTERS_QUERY = gql`
    query getMasters($salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    masters {
                        nodes {
                            id
                            firstName
                            lastName
                            phone
                            email
                            status
                            type
                            serviceGroups {
                                id
                                name(lang: $lang)
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_MASTERS_QUERY, {
    options: ({ salon, currentLanguage }) => ({
        variables: {
            salon,
            lang: currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer }, ownProps: { master } }) => {
        if (!loading && viewer) {
            const masters = get(viewer, 'salon.masters.nodes', []);
            return {
                masters,
                master: master && find(masters, item => item.id === master.id),
            };
        }
    },
});

const mapStateToProps = ({ masterAppointments, user, intl }) => ({
    master: masterAppointments.get('master'),
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    withData,
    withTawk,
    lifecycle({
        componentDidMount() {
            this.props.minimizeTawk();
        },
    }),
)(MasterAppointmentsPage);
