/**
 * @flow
 */

import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'lodash';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, defineMessages } from 'react-intl';

import DeleteSeminarDialog from '../components/DeleteSeminarDialog';

import { DELETE_SEMINAR_QUERY, GET_SEMINARS_QUERY } from './queries';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.seminars.form.SeminarForm.existingsError',
        defaultMessage: 'This seminar does not exist',
    },
    hasVisitorsError: {
        id: 'pages.seminars.form.SeminarForm.hasVisitorsError',
        defaultMessage: 'This seminar already has visitors attended',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withData = graphql(DELETE_SEMINAR_QUERY, {
    props: ({ mutate, ownProps: { seminar, searchQuery, intl, onClose } }) => ({
        onSubmit: () => {
            const mutation = mutate({
                variables: {
                    input: { seminarId: seminar.id },
                },
                update: store => {
                    const variables = {
                        searchQuery,
                        first: 20,
                    };

                    const data = store.readQuery({
                        query: GET_SEMINARS_QUERY,
                        variables,
                    });

                    remove(
                        data.viewer.seminars.edges,
                        edge => edge.node.id === seminar.id,
                    );

                    store.writeQuery({
                        query: GET_SEMINARS_QUERY,
                        variables,
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after seminar was deleted
                    onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when seminar was not deleted
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError && graphQLError.data) {
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.data.error === 'SEMINAR_HAS_VISITORS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.hasVisitorsError,
                                ),
                            });
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'deleteSeminar',
});

const mapStateToProps = ({ seminars, user }) => ({
    searchQuery: seminars.get('searchQuery'),
    supplier: user.get('id'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withForm,
)(DeleteSeminarDialog);
