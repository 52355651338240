/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import withModal from '../../../lib/withModal';
import EditInvoiceDialog from '../containers/EditInvoiceDialog';

const EditButton = styled(Button)`
    .ui.icon.button& > .icon {
        opacity: 1;
    }
`;

const EditInvoiceButton = ({
    open,
    onOpen,
    onClose,
    invoiceId,
    item,
    client,
    invoiceType,
    archived,
}) => (
    <EditInvoiceDialog
        trigger={
            <EditButton
                floated="right"
                color="blue"
                icon="edit"
                onClick={onOpen}
            />
        }
        open={open}
        onClose={onClose}
        invoiceId={invoiceId}
        item={item}
        client={client}
        invoiceType={invoiceType}
        archived={archived}
    />
);

export default withModal(EditInvoiceButton);
