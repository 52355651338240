import React from 'react';
import styled from 'styled-components';
import { compose, withState, withHandlers } from 'recompose';
import { debounce } from 'lodash';
import { Input as BaseInput } from 'semantic-ui-react';

// The default delay that will be used if "debounce"
// peroperty is not provided
const DEBOUNCE_DELAY = 300;

const Input = styled(BaseInput)`
    & > input {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
        line-height: 18px !important;
    }
`;

const Search = ({ setValue, ...props }) => (
    <Input icon="search" placeholder="Search..." {...props} />
);

// The local state for search value
const withValue = withState('value', 'setValue', ownProps => ownProps.value);

// Add delay to onChange callback
const withDebounceOnChange = withHandlers({
    onChange: ownProps =>
        debounce(ownProps.onChange, ownProps.debounce || DEBOUNCE_DELAY),
});

// Hold search value in local state and propagate onChange event
const withOnChange = withHandlers({
    onChange: ownProps => (e, data) => {
        ownProps.setValue(data.value);
        ownProps.onChange(e, data);
    },
});

export default compose(
    withDebounceOnChange,
    withValue,
    withOnChange,
)(Search);
