/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl, defineMessages } from 'react-intl';
import { find, remove } from 'lodash';
import { SubmissionError } from 'redux-form';
import moment from 'moment';

import ArchiveButton from '../../components/details/ArchiveButton';
import { GET_SALON_QUERY } from '../timetable/MasterTable';
import { GET_MASTER_APPOINTMENTS_QUERY } from '../../../master-appointments/containers/queries';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// A mutation that creates a new invoice
const ARCHIVE_APPOINTMENT_MUTATION = gql`
    mutation archiveAppointment($input: ArchiveAppointmentInput!) {
        archiveAppointment(input: $input) {
            appointment {
                id
                archived
            }
        }
    }
`;

const updateAppointment = (
    store,
    salon,
    appointment,
    showArchive,
    currentLanguage,
) => {
    const variables = {
        salon,
        showArchive,
        date: moment(appointment.startAt).format('YYYY-MM-DD'),
        lang: currentLanguage,
    };
    const data = store.readQuery({
        query: GET_SALON_QUERY,
        variables,
    });
    const master = find(
        data.viewer.salon.masters.nodes,
        i => i.id === appointment.master.id,
    );
    remove(master.appointments, item => item.id === appointment.id);
    store.writeQuery({
        query: GET_SALON_QUERY,
        variables,
        data,
    });
};

const updateMasterAppointment = (
    store,
    master,
    salon,
    appointment,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
) => {
    const variables = {
        master,
        salon,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        withMaster: true,
        showArchive,
        lang: currentLanguage,
    };
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables,
    });
    remove(
        data.viewer.salon.master.appointments,
        item => item.id === appointment.id,
    );
    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables,
        data,
    });
};

const withMutation = graphql(ARCHIVE_APPOINTMENT_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onArchive: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        appointmentId: ownProps.appointment.id,
                        archived: !ownProps.appointment.archived,
                    },
                },
                update: store => {
                    if (!ownProps.showArchive) {
                        if (ownProps.masterAppointments) {
                            updateMasterAppointment(
                                store,
                                ownProps.appointment.master.id,
                                ownProps.salon,
                                ownProps.appointment,
                                ownProps.fromDate,
                                ownProps.toDate,
                                ownProps.showArchive,
                                ownProps.currentLanguage,
                            );
                        } else {
                            if (ownProps.archiveAppointment) {
                                ownProps.archiveAppointment(
                                    ownProps.appointment,
                                );
                            } else {
                                updateAppointment(
                                    store,
                                    ownProps.salon,
                                    ownProps.appointment,
                                    ownProps.showArchive,
                                    ownProps.currentLanguage,
                                );
                            }
                        }
                    }
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog
                    ownProps.onClose();
                })
                .catch(() => {
                    throw new SubmissionError({
                        _error: ownProps.intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const mapStateToProps = ({ user, salon, intl }) => ({
    salon: user.get('salon'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withMutation,
)(ArchiveButton);
