/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

import withModal from '../../../lib/withModal';
import MasterDetails from '../../staff/components/details/MasterDetails';

const InfoButton = styled(Button)`
    .ui.basic.button& {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        min-height: 2.71em;
        background-color: #ffffff !important;
    }
`;

const MasterInfoButton = ({ open, onOpen, onClose, master }) => (
    <MasterDetails
        trigger={<InfoButton onClick={onOpen} icon="info" basic />}
        master={master}
        onClose={onClose}
        open={open}
        tab="appointments"
    />
);

export default withModal(MasterInfoButton);
