/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Form, Input, Button } from 'semantic-ui-react';
import { FormField } from '../../../../../components';
import { defineMessages, injectIntl } from 'react-intl';
import { mapProps } from 'recompose';

import ProductSelect from '../../../containers/cart/form/ProductSelect';
import QuantityField from './QuantityField';
import PaymentTypeField from '../../details/invoice/PaymentTypeField';

import type { Intl } from '../../../../../type';

const intlMessages = {
    item: defineMessages({
        placeholder: {
            id: 'pages.appointments.cart.form.product.placeholder',
            defaultMessage: 'Product',
        },
    }),
    quantity: defineMessages({
        placeholder: {
            id: 'pages.appointments.cart.form.quantity.placeholder',
            defaultMessage: 'Qty.',
        },
    }),
    price: defineMessages({
        placeholder: {
            id: 'pages.appointments.cart.form.price.placeholder',
            defaultMessage: 'Price',
        },
    }),
    paymentType: defineMessages({
        placeholder: {
            id: 'pages.appointments.cart.form.paymentType.placeholder',
            defaultMessage: 'Select payment type',
        },
    }),
};

type CartItemFieldsProps = {
    field: string,
    onRemove: Function,
    intl: Intl,
    paid: boolean,
    change: Function,
};

const fields = {
    entity: FormField(ProductSelect),
    quantity: FormField(QuantityField),
    input: FormField(mapProps(({ meta, ...props }) => ({ ...props }))(Input)),
    paymentType: FormField(PaymentTypeField),
};

const RemoveButton = styled(Button)`
    margin-right: 0px !important;
`;

const CartItemFields = ({
    field,
    onRemove,
    intl,
    paid,
    change,
}: CartItemFieldsProps) => (
    <Form.Group>
        <Field
            name={`${field}.entity`}
            component={fields.entity}
            width={paid ? 8 : 7}
            placeholder={intl.formatMessage(intlMessages.item.placeholder)}
            paid={paid}
            disabled={paid}
            change={change}
            fluid
            search
            simpleValue
        />
        <Field
            name={`${field}.quantity`}
            component={fields.quantity}
            width={2}
            placeholder={intl.formatMessage(intlMessages.quantity.placeholder)}
            disabled={paid}
            fluid
            simpleValue
        />
        <Field
            name={`${field}.price`}
            component={fields.input}
            width={2}
            placeholder={intl.formatMessage(intlMessages.price.placeholder)}
            disabled={paid}
            fluid
            simpleValue
        />
        <Field
            name={`${field}.paymentType`}
            component={fields.paymentType}
            placeholder={intl.formatMessage(
                intlMessages.paymentType.placeholder,
            )}
            width={4}
            disabled={paid}
            fluid
            simpleValue
        />
        {!paid && (
            <Form.Field>
                <RemoveButton
                    onClick={onRemove}
                    color="red"
                    icon="trash"
                    basic
                />
            </Form.Field>
        )}
    </Form.Group>
);

export default injectIntl(CartItemFields);
