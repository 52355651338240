/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Button as BaseButton } from '../../../../components';
import withModal from '../../../../lib/withModal';
import { LoginDialog } from '../../../login';

import type { Intl } from '../../../../type';

type LoginButtonProps = {
    open: boolean,
    onClose: Function,
    onOpen: Function,
    intl: Intl
};

const Button = styled(BaseButton)`
    &.inverted.button {
        background-color: rgba(253, 253, 253, .1) !important;
        box-shadow: none !important;
        transition: background-color .3s;

        &:focus,
        &:hover {
            background-color: rgba(253, 253, 253, .2) !important;
            box-shadow: none !important;
            color: #FFFFFF;
        }
    }
`;

const intlMessages = defineMessages({
    login: {
        id: 'pages.landing.promo.navigation.loginButton',
        defaultMessage: 'Login',
    },
});

const LoginButton = ({ open, onClose, onOpen, intl }: LoginButtonProps) => (
    <LoginDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} uppercased inverted>
                {intl.formatMessage(
                    intlMessages.login,
                )}
            </Button>
        }
    />
);

export default withModal(injectIntl(LoginButton));
