/**
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DropTarget } from 'react-dnd';
import { Column } from '../../../../components/timetable';
import {
    CELL_HEIGHT_PX,
    CELL_STEP,
} from '../../../../components/timetable/Cell';
import { offsetToTime } from '../../../../lib/schedule';

class AppointmentColumn extends Component {
    render() {
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div>
                <Column {...this.props} />
            </div>,
        );
    }
}

// Specify the drop target contract
const target = {
    drop: (props, monitor, component) => {
        const { appointment } = monitor.getItem();

        const time = offsetToTime(
            appointment.startAt,
            props.cellHeight,
            CELL_STEP,
            monitor.getDifferenceFromInitialOffset().y,
            props.schedule.step,
        );

        props.onDrop({
            appointment: appointment,
            master: props.inCabinet ? appointment.master : props.provider,
            cabinet: props.inCabinet ? props.provider : appointment.cabinet,
            startAt: time,
        });
    },
};

// Specify which props inject into component
const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
});

const mapStateToProps = ({ appointments }) => ({
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
});

export default compose(
    connect(mapStateToProps),
    DropTarget('APPOINTMENT', target, collect),
)(AppointmentColumn);
