/**
 * Represents button that opens dialog with confirmation
 * request to delete specified cabinet.
 *
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../lib/withModal';
import DeleteCabinetDialog from '../containers/DeleteCabinetDialog';

const DeleteCabinetButton = ({ cabinet, open, onOpen, onClose }) => (
    <DeleteCabinetDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.cabinet.CabinetList.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        cabinet={cabinet}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteCabinetButton);
