import { get, set, isNil, isNaN, each, size } from 'lodash';
import { defineMessages } from 'react-intl';
import { isMongoId } from '../../../../lib/formValidation';

const intlMessages = defineMessages({
    selectCabinetError: {
        id: 'pages.appointments.form.AppointmentForm.selectCabinetError',
        defaultMessage: 'Select cabinet from the list',
    },
    selectMasterError: {
        id: 'pages.appointments.form.AppointmentForm.selectMasterError',
        defaultMessage: 'Select master from the list',
    },
    dateError: {
        id: 'pages.appointments.form.AppointmentForm.dateError',
        defaultMessage: 'Enter appointment date',
    },
    timeSpanError: {
        id: 'pages.appointments.form.AppointmentForm.timeSpanError',
        defaultMessage: 'Select time span',
    },
    durationError: {
        id: 'pages.appointments.form.AppointmentForm.durationError',
        defaultMessage: 'Select procedure duration time',
    },
    serviceTypeError: {
        id: 'pages.appointments.form.AppointmentForm.serviceTypeError',
        defaultMessage: 'Select service type',
    },
    selectClientError: {
        id: 'pages.appointments.form.AppointmentForm.selectClientError',
        defaultMessage: 'Select client OR create a new',
    },
    timeOutOfRangeError: {
        id: 'pages.appointments.form.AppointmentForm.timeOutOfRangeError',
        defaultMessage: 'Time is out of range',
    },
});

export default (values, { salon, intl, inCabinet }) => {
    const errors = {};

    // Validate appointment date
    if (isNil(values.startDate)) {
        set(errors, 'startDate', intl.formatMessage(intlMessages.dateError));
    }

    // Validate service client
    if (!isNil(values.client) && !isMongoId(values.client)) {
        set(
            errors,
            'client',
            intl.formatMessage(intlMessages.selectClientError),
        );
    }

    if (values.services && values.services.length) {
        each(
            values.services,
            ({ cabinet, master, startTime, duration, service }, i) => {
                if (inCabinet) {
                    // Validate cabinet
                    if (!isMongoId(cabinet)) {
                        set(
                            errors,
                            ['services', i, 'cabinet'],
                            intl.formatMessage(intlMessages.selectCabinetError),
                        );
                    }
                } else {
                    // Validate service master
                    if (!isMongoId(master)) {
                        set(
                            errors,
                            ['services', i, 'master'],
                            intl.formatMessage(intlMessages.selectMasterError),
                        );
                    }
                }

                // Validate time span
                if (isNil(startTime) || isNaN(startTime)) {
                    set(
                        errors,
                        ['services', i, 'startTime'],
                        intl.formatMessage(intlMessages.timeSpanError),
                    );
                }

                // Validate duration
                if (isNil(duration)) {
                    set(
                        errors,
                        ['services', i, 'duration'],
                        intl.formatMessage(intlMessages.durationError),
                    );
                }

                // Validate the service type
                if (!isMongoId(service)) {
                    set(
                        errors,
                        ['services', i, 'service'],
                        intl.formatMessage(intlMessages.serviceTypeError),
                    );
                }

                if (
                    !isNil(values.startDate) &&
                    !isNil(startTime) &&
                    !isNil(duration)
                ) {
                    // Verify that service end time is not out of range of salon working time
                    const endAt = startTime + duration;
                    if (endAt > get(salon, 'settings.schedule.endAt')) {
                        set(
                            errors,
                            ['services', i, 'startTime'],
                            intl.formatMessage(
                                intlMessages.timeOutOfRangeError,
                            ),
                        );
                    }
                }
            },
        );
    } else {
        set(
            errors,
            '_error',
            intl.formatMessage(intlMessages.selectMasterError),
        );
    }

    return errors;
};
