import {
    withHandlers,
} from 'recompose';
import PayButton from '../../../components/details/invoice/PayButton';

const handlers = withHandlers({
    onClick: ({
        handleSubmit,
        dispatchChange
    }) => e => {
        dispatchChange('paid', true);
        setTimeout(() => handleSubmit(e), 0);
    }
});

export default handlers(PayButton);
