/**
 * The component represents a dropdown menu that allow user
 * to select one of salons he owns.
 *
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { get, map } from 'lodash';
import { Dropdown, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import withModal from '../../../lib/withModal';
import CreateSalonDialog from '../containers/CreateSalonDialog';

import type { Salon, ModalDialogTrigger } from '../../../type';

type SalonSelectProps = {
    currentSalon: Salon,
    options: Array<Object>,
    onChange: Function,
    isMaster: Boolean,
};

const CreateSalonHeader = styled(Dropdown.Header)`
    cursor: pointer;
`;

/**
 * The button that opens dialog to create a new salon
 */
const CreateSalonButton = withModal(
    ({ open, onOpen, onClose }: ModalDialogTrigger) => (
        <CreateSalonDialog
            trigger={
                <CreateSalonHeader onClick={onOpen}>
                    <Icon name="add" color="grey" />
                    <FormattedMessage
                        id="navbar.menu.newSalon"
                        defaultMessage="Create a new salon"
                    />
                </CreateSalonHeader>
            }
            open={open}
            onClose={onClose}
        />
    ),
);

const SalonSelect = ({
    currentSalon,
    options,
    onChange,
    isMaster,
}: SalonSelectProps) => (
    <Dropdown
        trigger={
            <span>
                <Icon name="home" />
                {get(currentSalon, 'name')}
            </span>
        }
    >
        <Dropdown.Menu>
            {!isMaster && <CreateSalonButton />}
            <Dropdown.Menu scrolling>
                {map(options, option => (
                    <Dropdown.Item
                        key={option.value}
                        active={
                            !!currentSalon && option.value === currentSalon.id
                        }
                        onClick={(e, { value }) => {
                            onChange(value);
                        }}
                        {...option}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown.Menu>
    </Dropdown>
);

export default SalonSelect;
