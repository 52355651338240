/**
 * Groups appointment cards in a timetable.
 *
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {
    min,
    fill,
    mapValues,
    assign,
    cloneDeep,
    remove,
    sortBy,
} from 'lodash';
import { areRangesOverlapping } from '../../../../lib/date';
import moment from 'moment';

import type { Salon, Appointment } from '../../../../type';
import AppointmentCard from './AppointmentCard';

type AppointmentCardContainerProps = {
    appointments: Array<Appointment>,
    salon: Salon,
    updateAppointmentClient: Function,
};

const Container = styled.div`
    position: absolute;
    margin: 2.5px 1.5px 1.5px;
    right: 0;
    left: 0;
    top: 0;
`;

const step = 15;

/**
 * Calculates appointment cards left offset and width (in %).
 *
 * @returns {{[appointmentId]: {width: number, left: number}}}
 */
const calculateCardsAllocation = (appointments: Array<Appointment>) => {
    /**
     * Creates appointments structural representation as matrix.
     *
     * Each row represents timetable step.
     * Each column represents appointments that are assigned to each timetable step.
     * For example, we have 3 appointments - A, B and C. A takes 3 steps, B takes 1 step and C takes 2 steps.
     * Then matrix might look like this:
     * ---------------
     * |  A   |  B   |
     * |  A   | null |
     * |  A   |  C   |
     * | null |  C   |
     * ---------------
     *
     * @returns {Array<Array<?string>>} Matrix of appointment IDs and nulls if no appointment.
     */
    const createMatrix = (appointments: Array<Appointment>, step: number) => {
        const getMinStartTime = (appointments: Array<Appointment>) => {
            return moment.min(
                appointments.map(appointment => moment(appointment.startAt)),
            );
        };

        const getMaxEndTime = (appointments: Array<Appointment>) => {
            return moment.max(
                appointments.map(appointment => moment(appointment.endAt)),
            );
        };

        const getRowsCount = (
            appointments: Array<Appointment>,
            step: number,
        ) => {
            const minStartTime = getMinStartTime(appointments);
            const maxEndTime = getMaxEndTime(appointments);
            return Math.ceil(
                moment(maxEndTime).diff(minStartTime, 'minutes') / step,
            );
        };

        /**
         * Determines how much appointments are assigned to each time step (row)
         * and returns max count of assigned appointments.
         */
        const getColsCount = (appointments: Array<Appointment>) => {
            let colsCount = 0;

            appointments.forEach(current => {
                let iterationColsCount = 0;

                appointments.forEach(appointment => {
                    const inRange = moment(current.startAt).isBetween(
                        appointment.startAt,
                        appointment.endAt,
                        null,
                        '[)',
                    );

                    if (inRange) {
                        iterationColsCount++;
                    }
                });

                if (iterationColsCount > colsCount) {
                    colsCount = iterationColsCount;
                }
            });

            return colsCount;
        };

        const createEmptyMatrix = (rowsCount: number, colsCount: number) => {
            return fill(new Array(rowsCount), null).map(() =>
                fill(new Array(colsCount), null),
            );
        };

        const getColumn = (
            matrix: Array<Array<?string>>,
            columnIndex: number,
        ) => matrix.map(x => x[columnIndex]);

        const sortedAppointments = sortBy(
            appointments,
            appointment =>
                moment(appointment.startAt).format('X') - appointment.duration,
        );
        const startTime = getMinStartTime(sortedAppointments);
        const rowsCount = getRowsCount(sortedAppointments, step);
        const colsCount = getColsCount(sortedAppointments);
        const matrix = createEmptyMatrix(rowsCount, colsCount);

        for (let row = 0; row < rowsCount; row++) {
            const cellTime = moment(startTime).add(step * row, 'minutes');

            for (let col = 0; col < colsCount; col++) {
                sortedAppointments.some(appointment => {
                    const inRange = cellTime.isBetween(
                        appointment.startAt,
                        appointment.endAt,
                        null,
                        '[)',
                    );
                    const alreadyInRow = matrix[row].includes(appointment.id);
                    const takesMultiCells = appointment.duration / step > 1;
                    const mustLookInOtherColumns =
                        takesMultiCells &&
                        cellTime.isAfter(appointment.startAt);
                    const alreadyInColumn = getColumn(matrix, col).includes(
                        appointment.id,
                    );

                    let result = false;

                    if (
                        inRange &&
                        !alreadyInRow &&
                        (!mustLookInOtherColumns || alreadyInColumn)
                    ) {
                        matrix[row][col] = appointment.id;
                        result = true;
                    }

                    return result;
                });
            }
        }

        return matrix;
    };

    /**
     * Calculates appointments width and left offset based on provided matrix.
     *
     * Determines maximum of taken cells on the right from appointment
     * and calculates card width on the basis of this number.
     * Card left offset is calculated on the basis of a column index where
     * an appointment is located.
     *
     * @returns {{[appointmentId]: {width: number, left: number}}}
     */
    const calculateWidthAndLeftOffset = (matrix: Array<Array<?string>>) => {
        const rowsCount = matrix.length;
        const colsCount = matrix[0].length;
        const appointmentCounts = {};

        for (let col = 0; col < colsCount; col++) {
            for (let row = 0; row < rowsCount; row++) {
                const appointmentId = matrix[row][col];

                if (appointmentId === null) {
                    continue;
                }

                appointmentCounts[appointmentId] = appointmentCounts[
                    appointmentId
                ] || {
                    startColumn: col,
                    coefs: [],
                };
                appointmentCounts[appointmentId].coefs[row] = 1;

                for (let cell = col + 1; cell < colsCount; cell++) {
                    if (matrix[row][cell] === null) {
                        if (
                            col +
                                appointmentCounts[appointmentId].coefs[row] ===
                            cell
                        ) {
                            appointmentCounts[appointmentId].coefs[row]++;
                        }
                    }
                }
            }
        }

        return mapValues(appointmentCounts, ({ startColumn, coefs }) => ({
            width: (100 / colsCount) * min(coefs),
            left: (100 * startColumn) / colsCount,
        }));
    };

    /**
     * Splits appointments into chunks by time range overlaps.
     *
     * Each chunk contains appointments overlapping with each other.
     * This is needed to simplify matrix processing algorithm.
     *
     * @returns {Array<Array<Appointment>>}
     */
    const chunkAppointments = (appointments: Array<Appointment>) => {
        const chunks = [];
        const cloned = cloneDeep(appointments);

        while (cloned.length) {
            chunks.push([cloned.shift()]);

            remove(cloned, appointment => {
                const chunk = chunks[chunks.length - 1];

                for (let i = 0; i < chunk.length; i++) {
                    const areOverlapping = areRangesOverlapping(
                        chunk[i].startAt,
                        moment(chunk[i].endAt).subtract(1, 'second'),
                        appointment.startAt,
                        appointment.endAt,
                    );

                    if (areOverlapping) {
                        chunk.push(appointment);
                        return true;
                    }
                }

                return false;
            });
        }
        return chunks;
    };

    const sortedAppointments = sortBy(
        appointments,
        appointment =>
            moment(appointment.startAt).format('X') - appointment.duration,
    );

    const result = {};

    chunkAppointments(sortedAppointments).forEach(chunk => {
        const matrix = createMatrix(chunk, step);
        assign(result, calculateWidthAndLeftOffset(matrix));
    });

    return result;
};

const AppointmentCardContainer = ({
    appointments,
    salon,
    updateAppointment,
    updateAppointmentClient,
    archiveAppointment,
    masterAppointments,
    fromDate,
    toDate,
    inCabinet,
}: AppointmentCardContainerProps) => {
    const allocations = calculateCardsAllocation(appointments);

    return (
        <Container>
            {appointments.map(appointment => (
                <AppointmentCard
                    key={appointment.id}
                    salon={salon}
                    appointment={appointment}
                    schedule={salon.settings.schedule}
                    width={allocations[appointment.id].width}
                    left={allocations[appointment.id].left}
                    updateAppointment={updateAppointment}
                    updateAppointmentClient={updateAppointmentClient}
                    archiveAppointment={archiveAppointment}
                    masterAppointments={masterAppointments}
                    fromDate={fromDate}
                    toDate={toDate}
                    inCabinet={inCabinet}
                />
            ))}
        </Container>
    );
};

export default AppointmentCardContainer;
