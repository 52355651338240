/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import Fuse from 'fuse.js';

import SalonList from '../components/SalonList';

export const GET_SALON_LIST_QUERY = gql`
    query getSalonList($resolveRole: String) {
        viewer(resolveRole: $resolveRole) {
            id
            ... on Sysadmin {
                salonList {
                    id
                    name
                    administrator {
                        id
                        firstName
                        lastName
                        phone
                        email
                    }
                    masterCount
                }
            }
        }
    }
`;

const withData = graphql(GET_SALON_LIST_QUERY, {
    options: () => ({
        variables: {
            resolveRole: 'SYSADMIN',
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => {
        let salonList;
        let salonSearch;
        if (!loading && viewer) {
            salonList = viewer.salonList;
            salonSearch = new Fuse(salonList, {
                keys: [
                    'name',
                    'administrator.firstName',
                    'administrator.lastName',
                    'administrator.phone',
                    'administrator.email',
                ],
                shouldSort: true,
                location: 0,
                distance: 0,
                threshold: 0.0,
            });
        }
        return {
            loading,
            salonList,
            salonSearch,
        };
    },
});

const withSalons = withProps(({ salonList, salonSearch, searchQuery }) => {
    let salons = salonList;
    if (salonSearch && searchQuery) {
        salons = salonSearch.search(searchQuery);
    }
    return {
        salons,
    };
});

const mapStateToProps = ({ salon }) => ({
    searchQuery: salon.get('searchQuery'),
});

export default compose(
    connect(mapStateToProps),
    withData,
    withSalons,
)(SalonList);
