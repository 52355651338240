import { set, isNil } from 'lodash';
import { defineMessages } from 'react-intl';
import { isMongoId } from '../../../../lib/formValidation';
import { priceIsNotValid, isNotNumber } from '../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    serviceTypeError: {
        id: 'pages.schedule.form.AppointmentForm.serviceTypeError',
        defaultMessage: 'Select service type',
    },
    selectClientError: {
        id: 'pages.schedule.form.AppointmentForm.selectClientError',
        defaultMessage: 'Select client OR create a new',
    },
    quantityError: {
        id: 'pages.memberships.form.numberOfUsesError',
        defaultMessage: 'Enter number of uses',
    },
    priceError: {
        id: 'pages.memberships.form.priceError',
        defaultMessage: 'Enter price of the membership',
    },
    serialNumberError: {
        id: 'pages.memberships.form.serialNumberError',
        defaultMessage: 'Enter serial number',
    },
});

export default (values, ownProps) => {
    const { intl } = ownProps;
    const errors = {};

    // Validate membership client
    if (!isMongoId(values.client)) {
        set(
            errors,
            'client',
            intl.formatMessage(intlMessages.selectClientError),
        );
    }

    // Validate membership serial number
    if (
        isNil(values.serialNumber) ||
        isNotNumber(values.serialNumber.toString())
    ) {
        set(
            errors,
            'serialNumber',
            intl.formatMessage(intlMessages.serialNumberError),
        );
    }

    // Validate quantity of uses
    if (isNil(values.quantity) || isNotNumber(values.quantity.toString())) {
        set(errors, 'quantity', intl.formatMessage(intlMessages.quantityError));
    }

    // Validate selling price
    if (isNil(values.price) || priceIsNotValid(values.price)) {
        set(errors, 'price', intl.formatMessage(intlMessages.priceError));
    }

    // Validate the service type
    if (!isMongoId(values.service)) {
        set(
            errors,
            'service',
            intl.formatMessage(intlMessages.serviceTypeError),
        );
    }

    return errors;
};
