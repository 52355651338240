/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, filter, some } from 'lodash';
import { formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';

import CabinetServiceFieldList from '../../components/form/CabinetServiceFieldList';
import { MASTER_CABINET_SERVICE_FRAGMENT } from '../masterAccessQueries';

const GET_CABINETS_QUERY = gql`
    query getCabinets($salon: ID!, $date: String!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    cabinets {
                        id
                        name(lang: $lang)
                        schedule(startDate: $date) {
                            startAt
                            endAt
                        }
                        serviceGroups {
                            id
                            name(lang: $lang)
                            services {
                                id
                                name(lang: $lang)
                                duration
                                price
                            }
                            masters {
                                id
                                firstName
                                lastName
                                schedule(startDate: $date) {
                                    id
                                    startAt
                                    endAt
                                }
                            }
                        }
                    }
                }
            }
            ...onMasterCabinetService
        }
    }
    ${MASTER_CABINET_SERVICE_FRAGMENT}
`;

const withData = graphql(GET_CABINETS_QUERY, {
    options: ({ salon, date, currentLanguage }) => ({
        variables: {
            salon,
            date,
            lang: currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }: Object) => ({
        cabinets:
            !loading && viewer
                ? filter(
                      get(viewer, 'salon.cabinets', []),
                      ({ schedule }) => schedule && schedule.length,
                  )
                : [],
    }),
});

const mapStateToProps = ({ user, intl, ...state }, { meta }) => ({
    salon: user.get('salon'),
    date: formValueSelector(meta.form)(state, 'startDate'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    injectIntl,
    connect(mapStateToProps),
    withData,
)(CabinetServiceFieldList);
