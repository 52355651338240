/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import AppointmentColumn from '../../components/timetable/AppointmentColumn';
import { get } from 'lodash';

import { APPOINTMENT_FRAGMENT } from '../queries';

import { GET_SALON_QUERY } from './MasterTable';

const UPDATE_APPOINTMENT_QUERY = gql`
    mutation updateAppointment(
        $input: UpdateAppointmentInput!
        $salon: ID!
        $lang: String!
    ) {
        updateAppointment(input: $input) {
            appointment {
                id
                ...appointment
            }
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;

const withMutation = graphql(UPDATE_APPOINTMENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDrop: data => {
            const paid = data.appointment.invoice //
                ? data.appointment.invoice.paid
                : null;

            if (!paid) {
                const master = get(data, 'master.id');
                const cabinet = ownProps.inCabinet
                    ? get(data, 'cabinet.id')
                    : null;
                const mutation = mutate({
                    variables: {
                        input: {
                            appointmentId: data.appointment.id,
                            master,
                            cabinet,
                            client: data.appointment.client.id,
                            startAt: moment(data.startAt).format(
                                'YYYY-MM-DD HH:mm',
                            ),
                            notes: data.appointment.notes,
                        },
                        salon: ownProps.salon,
                        lang: ownProps.currentLanguage,
                    },
                    update: (store, { data: { updateAppointment } }) => {
                        if (ownProps.updateAppointment) {
                            ownProps.updateAppointment(
                                updateAppointment.appointment,
                            );
                        }
                    },
                });

                return mutation;
            }
        },
    }),
});

export default compose(
    connect(({ user, salon, intl }) => ({
        salon: user.get('salon'),
        showArchive: salon.get('showArchive'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withMutation,
)(AppointmentColumn);
