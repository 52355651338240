import { set, isEmpty } from 'lodash';

import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    itemPriceError: {
        id: 'pages.report.editInvoice.priceError',
        defaultMessage: 'Enter price',
    },
});

export default (values, { intl }) => {
    const errors = {};

    // Validate entered price
    if (
        isEmpty(values.price) ||
        (values.price && values.price.match(/[^,.\d]/))
    ) {
        set(errors, 'price', intl.formatMessage(intlMessages.itemPriceError));
    }

    return errors;
};
