/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';
import {
    FormField,
    FormLabel as BaseFormLabel,
    PhoneInput,
} from '../../../../components';

import styles from '../../../../styles';

import type { ReduxForm, Administrator } from '../../../../type';

type AdministratorFormProps = {
    administrator: Administrator,
    readOnly: boolean,
    mode: string,
} & ReduxForm;

const FormLabel = styled(BaseFormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;

const fields = {
    input: FormField(({ meta, ...props }) => <Input {...props} />),
    password: FormField(({ meta, ...props }) => (
        <Input {...props} type="password" />
    )),
    passwordRepeat: FormField(({ meta, ...props }) => (
        <Input {...props} type="password" />
    )),
    phone: FormField(PhoneInput),
};

const AdministratorForm = ({
    handleSubmit,
    submitting,
    pristine,
    readOnly,
    administrator,
    error,
    mode,
}: AdministratorFormProps) => (
    <Form onSubmit={handleSubmit} error={!!error}>
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Form.Field>
            <Field
                name="firstName"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.firstName"
                            defaultMessage="First Name"
                        />
                    </FormLabel>
                }
                readOnly={readOnly}
                simpleValue
                required
            />
            <Field
                name="lastName"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.lastName"
                            defaultMessage="Last Name"
                        />
                    </FormLabel>
                }
                readOnly={readOnly}
                simpleValue
            />
        </Form.Field>
        <Form.Field>
            <Field
                name="email"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.email"
                            defaultMessage="E-mail"
                        />
                    </FormLabel>
                }
                required
                readOnly={readOnly}
                simpleValue
            />
        </Form.Field>
        <Form.Field>
            <Field
                name="phone"
                component={fields.phone}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.phone"
                            defaultMessage="Phone number"
                        />
                    </FormLabel>
                }
                readOnly={readOnly}
                simpleValue
            />
        </Form.Field>
        <Form.Field>
            <Field
                name="password"
                component={fields.password}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.password"
                            defaultMessage="Password"
                        />
                    </FormLabel>
                }
                required={mode === 'add'}
                readOnly={readOnly}
                simpleValue
            />
        </Form.Field>
        <Form.Field>
            <Field
                name="passwordRepeat"
                component={fields.passwordRepeat}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.administrators.form.passwordRepeat"
                            defaultMessage="Repeat password"
                        />
                    </FormLabel>
                }
                required={mode === 'add'}
                readOnly={readOnly}
                simpleValue
            />
        </Form.Field>
    </Form>
);

export default AdministratorForm;
