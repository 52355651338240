/**
 * Represents appointment card with information about procedure
 * and it's duration.
 *
 * @flow
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers, withState } from 'recompose';
import { isEqual } from 'lodash';
import styled, { injectGlobal } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { darken, transparentize } from 'polished';
import moment from 'moment';
import { DragSource } from 'react-dnd';
import { defineMessages, injectIntl } from 'react-intl';
import withModal from '../../../../lib/withModal';
import { calcEventOffset, calcEventHeight } from '../../../../lib/schedule';
import { AppointmentDetails } from '../details';
import AppointmentCardPopup from '../../containers/timetable/AppointmentCardPopup';
import ClientDetails from '../../../clients/components/details/ClientDetails';

import {
    CELL_HEIGHT_PX,
    CELL_STEP,
} from '../../../../components/timetable/Cell';

import type {
    Appointment,
    ModalDialogTrigger,
    ScheduleSettings,
    Salon,
} from '../../../../type';

// Minimal height of cell to fit start and end time
const MIN_HEIGHT_FOR_TIME = 80;
// Minimal height of cell to fit client name
const MIN_HEIGHT_FOR_SERVICE = 49;
const MIN_HEIGHT_FOR_CABINET = 59;
// Height AND width of zoomed card
const ZOOMED_CARD_HEIGHT = 100;
const ZOOMED_CARD_WIDTH = 200;

type AppointmentCardProps = {
    salon: Salon,
    schedule: ScheduleSettings,
    appointment: Appointment,
    connectDragSource: Function,
    isDragging: boolean,
    width: number,
    height: number,
    left: number,
    top: number,
    updateAppointmentClient: Function,
} & ModalDialogTrigger;

const ServiceWrapper = styled.div`
    display: ${props =>
        props.height < MIN_HEIGHT_FOR_SERVICE ? 'none' : 'block'};
`;

const CabinetWrapper = styled.div`
    display: ${props =>
        props.height < MIN_HEIGHT_FOR_CABINET ? 'none' : 'block'};
`;

const ClientComponent = styled.div`
    border-bottom: 1px dashed;
    cursor: help;
`;

const Client = ({ onClose, ...props }) => (
    <ClientComponent {...props} onMouseDown={onClose} />
);

const TimeWrapper = styled.div`
    display: ${props =>
        props.height < MIN_HEIGHT_FOR_TIME ? 'none' : 'block'};
`;

const Content = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(${props => props.height}px - 8px);
    text-align: center;
    text-decoration: none;
    border: 1px solid ${props => darken(0.03, props.color)};
    border-radius: 3px;
    color: #333;
    font-size: 12px;
    line-height: 14px;
    user-select: none;
    background: ${props => transparentize(0.5, props.color)};
    overflow: hidden;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        box-shadow: 5px 14px 15px rgba(0, 0, 0, 0.6);
        transition: opacity 0.3s ease-in-out;
    }

    &:hover::after {
        opacity: 1;
    }
`;

const Title = styled.div`
    font-weight: bold;
`;

const StartAt = styled.div`
    position: absolute;
    top: 3.5px;
    left: 3.5px;
    font-size: 11px;
`;

const EndAt = styled.div`
    position: absolute;
    bottom: 4.5px;
    left: 4.5px;
    font-size: 11px;
`;

const PaidIcon = styled(Icon)`
    position: absolute;
    bottom: 2px;
    right: 0px;
`;

const BirthdayIcon = styled(Icon)`
    position: absolute;
    ${props =>
        props.alignRight
            ? `right: 12px; top: 2px;`
            : `right:23px; bottom: 2px;`}
`;

const ClientIcon = styled(Icon)`
    position: absolute;
    ${props =>
        props.alignRight
            ? `right: 12px; bottom: 2px;`
            : `right: 0px; top: 2px;`}
`;

// eslint-disable-next-line
injectGlobal`
    .appointment-card-wrapper {
        position: absolute;
        padding: 1.5px;
        word-wrap: break-word;
        word-break: break-all;
    }
`;

const intlMessages = defineMessages({
    walkinClient: {
        id: 'pages.appointments.details.AppointmentDetails.walkinClient',
        defaultMessage: 'Walk-in Client',
    },
});

class AppointmentCard extends Component {
    props: AppointmentCardProps;

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
        );
    }

    render() {
        const {
            intl,
            schedule,
            salon,
            appointment,
            onOpen,
            onClose,
            open,
            connectDragSource,
            width,
            height,
            top,
            left,
            updateAppointment,
            updateAppointmentClient,
            archiveAppointment,
            masterAppointments,
            fromDate,
            toDate,
            onOpenClientDialog,
            onCloseClientDialog,
            pendingClientModal,
            inCabinet,
            isMaster,
        } = this.props;

        const birthdayDate =
            appointment.client.birthday &&
            moment(appointment.client.birthday)
                .set('year', moment().year())
                .format('YYYY-MM-DD');

        const isBirthday = moment(moment().format('YYYY-MM-DD')).isSame(
            birthdayDate,
            'day',
        );
        let appointmentCard = '';

        const iconNames = {
            CASH: 'money',
            CC: 'credit card',
            GIFT: 'gift',
            MEMBERSHIP: 'address card outline',
        };

        if (appointment.client) {
            const clientWithWalkIn = (
                <Content
                    color={appointment.service.color}
                    height={height}
                    onClick={onOpen}
                    width={width}
                >
                    <Client>
                        {appointment.client.firstName
                            ? appointment.client.firstName +
                              ' ' +
                              appointment.client.lastName
                            : intl.formatMessage(intlMessages.walkinClient)}
                    </Client>

                    {isBirthday && (
                        <BirthdayIcon
                            name="birthday"
                            size="small"
                            alignRight={appointment.duration >= 60}
                        />
                    )}

                    {appointment.authorRole === 'CLIENT' && (
                        <ClientIcon
                            name="user"
                            size="small"
                            alignRight={
                                appointment.invoice &&
                                appointment.invoice.paid &&
                                appointment.duration <= 30
                            }
                        />
                    )}

                    <Title>
                        <ServiceWrapper height={height}>
                            {appointment.service.name}
                        </ServiceWrapper>
                    </Title>
                    {inCabinet
                        ? !!appointment.master && (
                              <Title>
                                  <CabinetWrapper height={height}>
                                      {`${appointment.master.firstName} ${
                                          appointment.master.lastName
                                      }`}
                                  </CabinetWrapper>
                              </Title>
                          )
                        : !!appointment.cabinet && (
                              <Title>
                                  <CabinetWrapper height={height}>
                                      {appointment.cabinet.name}
                                  </CabinetWrapper>
                              </Title>
                          )}

                    <TimeWrapper height={height}>
                        <StartAt>
                            {moment(appointment.startAt).format(
                                schedule.format,
                            )}
                        </StartAt>
                        <EndAt>
                            {moment(appointment.endAt).format(schedule.format)}
                        </EndAt>
                    </TimeWrapper>
                    {appointment.invoice && appointment.invoice.paid && (
                        <PaidIcon
                            name={
                                iconNames[
                                    (appointment.invoice.items &&
                                        appointment.invoice.items[0]
                                            .paymentType) ||
                                        'CC'
                                ]
                            }
                            size="small"
                        />
                    )}
                </Content>
            );

            appointmentCard = (
                <AppointmentCardPopup
                    client={appointment.client}
                    author={appointment.author}
                    cabinet={appointment.cabinet}
                    master={appointment.master}
                    onOpen={onOpen}
                    onOpenClientDialog={onOpenClientDialog}
                    cardHeight={height}
                    inCabinet={inCabinet}
                    isMaster={isMaster}
                >
                    {clientWithWalkIn}
                </AppointmentCardPopup>
            );
        }

        return connectDragSource(
            <div
                style={{
                    top: `${top}px`,
                    left: `${left}%`,
                    width: `${width}%`,
                }}
                className="appointment-card-wrapper"
            >
                <AppointmentDetails
                    salon={salon}
                    appointment={appointment}
                    onClose={onClose}
                    open={open}
                    updateAppointment={updateAppointment}
                    updateAppointmentClient={updateAppointmentClient}
                    archiveAppointment={archiveAppointment}
                    masterAppointments={masterAppointments}
                    fromDate={fromDate}
                    toDate={toDate}
                    inCabinet={inCabinet}
                />

                <ClientDetails
                    client={appointment.client}
                    onModalClose={onCloseClientDialog}
                    isModalOpen={pendingClientModal}
                    forcedSelectedItem={'appointments'}
                />

                {appointmentCard}
            </div>,
        );
    }
}

const props = withProps(({ appointment, schedule, cellHeight }) => ({
    top: calcEventOffset(
        cellHeight,
        appointment.startAt,
        schedule.startAt,
        CELL_STEP,
    ),
    height: calcEventHeight(cellHeight, appointment.duration, CELL_STEP),
}));

// Implement appointment card drag source contract
const source = {
    beginDrag: (props, monitor) => ({
        appointment: props.appointment,
    }),
};

// Specify the props to inject into component
const collect = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
});

const mapStateToProps = ({ appointments, user }) => ({
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
    isMaster: user.get('isMaster'),
});

const handlers = withHandlers({
    onOpenClientDialog: ({
        setPendingClientModal,
        pendingClientModal,
    }) => () => {
        setPendingClientModal(true);
    },
    onCloseClientDialog: ({
        setPendingClientModal,
        pendingClientModal,
    }) => () => {
        setPendingClientModal(false);
    },
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withModal,
    props,
    withState('pendingClientModal', 'setPendingClientModal', false),
    handlers,
    DragSource('APPOINTMENT', source, collect),
)(AppointmentCard);
