/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment';
import { omit, get, find, remove, cloneDeep, map } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';

import EditAppointment from '../../components/details/EditAppointment';
import validate from '../form/validate';
import client from '../../../../apollo-client';

// Query to get salon
import { GET_SALON_QUERY } from '../timetable/MasterTable';
import { GET_MASTER_APPOINTMENTS_QUERY } from '../../../master-appointments/containers/queries';
import { GET_WORKING_DAYS } from '../../../master-appointments/containers/DateRange';
import { APPOINTMENT_FRAGMENT } from '../queries';

import type { Appointment } from '../../../../type';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.schedule.form.AppointmentForm.existingsError',
        defaultMessage: 'This appointment does not exist',
    },
    timeOverlapError: {
        id: 'pages.schedule.form.AppointmentForm.startTimeError',
        defaultMessage: 'Time is overlapping with another appointment',
    },
    appointmentAlreadyExists: {
        id: 'pages.appointments.AppointmentForm.error.appointmentAlreadyExists',
        defaultMessage: 'Same appointment already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The query to update existing appointment with a new data
const UPDATE_APPOINTMENT_QUERY = gql`
    mutation updateAppointment(
        $input: UpdateAppointmentInput!
        $lang: String!
        $salon: ID!
    ) {
        updateAppointment(input: $input) {
            appointment {
                id
                ...appointment
            }
        }
    }
    ${APPOINTMENT_FRAGMENT}
`;

const updateMasterAppointments = (
    store,
    master,
    salon,
    appointment,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
    });

    const toUpdate = find(
        data.viewer.salon.master.appointments,
        item => item.id === appointment.id,
    );

    if (master === appointment.master.id) {
        toUpdate.client = appointment.client;
        toUpdate.durations = appointment.duration;
        toUpdate.endAt = appointment.endAt;
        toUpdate.notes = appointment.notes;
        toUpdate.service = appointment.service;
        toUpdate.startAt = appointment.startAt;
    } else {
        remove(
            data.viewer.salon.master.appointments,
            item => item.id === appointment.id,
        );
    }

    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
        data,
    });
};

const updateWorkingDays = (
    store,
    salon,
    master,
    appointment,
    ownAppointment,
) => {
    const fromDate = moment().format('YYYY-MM-DD');
    const month = moment(fromDate).format('MM');
    const toDate = moment(month, 'MM')
        .month(parseInt(month, 10) + 5)
        .format('YYYY-MM-DD');
    const data = store.readQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
    });

    const oldDate = find(
        data.viewer.salon.workingDays,
        item =>
            item.date === moment(ownAppointment.startAt).format('YYYY-MM-DD'),
    );

    if (appointment.master.id === master) {
        const newDate = find(
            data.viewer.salon.workingDays,
            item =>
                item.date === moment(appointment.startAt).format('YYYY-MM-DD'),
        );

        if (newDate) {
            if (newDate.date !== oldDate.date) {
                if (oldDate.appointmentCount > 1) {
                    oldDate.appointmentCount--;
                    newDate.appointmentCount++;
                } else {
                    remove(
                        data.viewer.salon.workingDays,
                        item => item.date === oldDate.date,
                    );
                    newDate.appointmentCount++;
                }
            }
        } else {
            if (oldDate.appointmentCount > 1) {
                oldDate.appointmentCount--;
            } else {
                remove(
                    data.viewer.salon.workingDays,
                    item => item.date === oldDate.date,
                );
            }
            data.viewer.salon.workingDays.push({
                date: moment(appointment.startAt).format('YYYY-MM-DD'),
                appointmentCount: 1,
                __typename: 'WorkingDay',
            });
        }
    } else if (oldDate.appointmentCount > 1) {
        oldDate.appointmentCount--;
    } else {
        remove(
            data.viewer.salon.workingDays,
            item => item.date === oldDate.date,
        );
    }

    store.writeQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
        data,
    });
};

const withMutation = graphql(UPDATE_APPOINTMENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: formData => {
            const servicesWithAssignedTime = map(formData.services, service => {
                const newService = cloneDeep(service);
                const formattedTime = moment(formData.startDate)
                    .startOf('day')
                    .add(newService.startTime, 'minutes')
                    .format('YYYY-MM-DD HH:mm');
                newService.startTime = formattedTime;
                return newService;
            });

            const formDataWithServices = formData;

            if (formData.services) {
                formDataWithServices.services = servicesWithAssignedTime;
            }

            const result = {
                variables: {
                    input: {
                        appointmentId: ownProps.appointment.id,
                        ...omit(formDataWithServices, [
                            'startDate',
                            'startTime',
                        ]),
                        startAt: moment(formDataWithServices.startDate)
                            .startOf('day')
                            .add(formDataWithServices.startTime, 'minutes')
                            .format('YYYY-MM-DD HH:mm'),
                    },
                    lang: ownProps.currentLanguage,
                    salon: ownProps.salonId,
                },
                update: (store, { data: { updateAppointment } }) => {
                    if (ownProps.masterAppointments) {
                        updateMasterAppointments(
                            store,
                            ownProps.appointment.master.id,
                            ownProps.salonId,
                            updateAppointment.appointment,
                            ownProps.fromDate,
                            ownProps.toDate,
                            ownProps.showArchive,
                            ownProps.currentLanguage,
                        );
                        updateWorkingDays(
                            store,
                            ownProps.salonId,
                            ownProps.appointment.master.id,
                            updateAppointment.appointment,
                            ownProps.appointment,
                        );
                    } else {
                        if (ownProps.updateAppointment) {
                            ownProps.updateAppointment(
                                updateAppointment.appointment,
                            );
                        }
                    }
                },
            };

            const mutation = mutate(result);

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after appointment was created
                    // ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        const errMsg = get(graphQLError, 'data.error');
                        if (
                            graphQLError.name === 'NotExists' &&
                            errMsg === 'ID_DOES_NOT_EXISTS'
                        ) {
                            // Throw error if trying to edit not existing appointment
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'AlreadyExists' &&
                            errMsg === 'TIME_OVERLAPS_WITH_OTHER_RECORD'
                        ) {
                            // Handle time overlapping error
                            throw new SubmissionError({
                                startTime: intl.formatMessage(
                                    intlMessages.timeOverlapError,
                                ),
                            });
                        } else if (errMsg === 'APPOINTMENT_ALREADY_EXISTS') {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.appointmentAlreadyExists,
                                ),
                            });
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

// Provide salon object form local cache
const withSalon = withProps(ownProps => {
    try {
        const data = client.readQuery({
            query: GET_SALON_QUERY,
            variables: {
                salon: ownProps.salonId,
                showArchive: ownProps.showArchive,
                date: moment(ownProps.appointment.startAt).format('YYYY-MM-DD'),
                lang: ownProps.currentLanguage,
            },
        });

        return {
            salon: data.viewer.salon,
        };
    } catch (e) {
        return {};
    }
});

// Init appointment form
const withForm = reduxForm({
    form: 'editAppointment',
    touchOnBlur: false,
    validate,
    enableReinitialize: true,
});

// Populate initial values with data from provided appointment
const withInitialValues = withProps(
    ({ appointment, inCabinet, ...props }: { appointment: Appointment }) => ({
        initialValues: {
            cabinet: inCabinet ? get(appointment, 'cabinet.id') : null,
            master: get(appointment, 'master.id'),
            client: appointment.client && appointment.client.id,
            startDate: moment(appointment.startAt)
                .startOf('day')
                .format('YYYY-MM-DD'),
            startTime: Math.abs(
                moment(appointment.startAt)
                    .startOf('day')
                    .diff(appointment.startAt, 'minutes'),
            ),
            duration: appointment.duration,
            service: appointment.service.id,
            notes: appointment.notes,
            services: [],
        },
    }),
);

const props = withProps(ownProps => ({
    isPaid: get(ownProps, 'appointment.invoice.paid'),
    isGrouped: get(ownProps, 'appointment.group'),
}));

const mapStateToProps = ({ user, schedule, salon, intl }) => ({
    salonId: user.get('salon'),
    date: schedule.get('date'),
    enableArchive: salon.get('settings').enableArchive,
    showArchive: salon.get('showArchive'),
    isSysadmin: user.get('isSysadmin'),
    isOwner: user.get('isOwner'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    isMaster: user.get('isMaster'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withMutation,
    withInitialValues,
    withSalon,
    withForm,
    props,
)(EditAppointment);
