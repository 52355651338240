/**
 * @flow
 */

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { Modal } from '../../../../components';

import Navigation from './Navigation';
import EditSeminar from '../../containers/details/EditSeminar';
import VisitorsList from './VisitorsList';

import type { NavigationItems, ModalDialog, Seminar } from './Navigation';

type SeminarDetailsProps = {
    seminar: Seminar,
    selectedItem: NavigationItems,
    onItemClick: Function,
} & ModalDialog;

const SeminarDetails = ({
    seminar,
    trigger,
    onClose,
    open,
    selectedItem,
    onItemClick,
}: SeminarDetailsProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>{seminar.name}</Modal.Header>
        <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
        {selectedItem === 'editSeminar' && (
            <EditSeminar seminar={seminar} onClose={onClose} />
        )}
        {selectedItem === 'visitorsList' && (
            <VisitorsList seminar={seminar} onClose={onClose} />
        )}
    </Modal>
);

// Setup state handler to hold active section
const state = withState('selectedItem', 'selectItem', 'editSeminar');

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(
    state,
    handlers,
)(SeminarDetails);
