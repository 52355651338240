/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Container, Grid, Menu as BaseMenu } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';

import CabinetList from '../containers/CabinetList';
import AddCabinetButton from './AddCabinetButton';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const CabinetsPage = () => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.cabinets.header"
                        defaultMessage="Cabinets"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                {/* <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.staffSearchPlaceholder,
                        )}
                    />
                </BaseMenu.Item> */}
                <BaseMenu.Item>
                    <AddCabinetButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <CabinetList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(CabinetsPage);
