/**
 * Wrapper around an element that supports animation that should be
 * triggered when the child element is in the viewport.
 *
 * The wrapper will provide an animate property to child component.
 *
 * Usage:
 * <Animation>
 *    <AnimatedComponent />
 * </Animation>
 *
 * @flow
 */

import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

type AnimationProps = {
    // A single child element that should be animated
    children: Object,
    // Repeat animation after it will reappear in the viewport
    repeat: boolean,
};

class Animation extends Component {
    props: AnimationProps;
    static defaultProps = {
        repeat: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            animate: false,
        };
    }

    onChange = isVisible => {
        this.setState({ animate: isVisible });
    };

    render() {
        const { animate } = this.state;
        const { children, repeat } = this.props;

        return (
            <VisibilitySensor
                active={repeat || !animate}
                onChange={this.onChange}
                partialVisibility
                scrollCheck
                scrollThrottle={0}
                resizeCheck
                resizeThrottle={0}
            >
                {React.cloneElement(children, { animate })}
            </VisibilitySensor>
        );
    }
}

export default Animation;
