/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import type { ModalDialog } from '../../../type';

type RemoveInvoiceDialogProps = {
    onDelete: Function,
} & ModalDialog;

const ModalContent = styled(Modal.Content)`
    text-align: center;
`;

const RemoveInvoiceDialog = ({
    trigger,
    open,
    onClose,
    onDelete,
}: RemoveInvoiceDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.report.removeInvoice.title"
                defaultMessage="Remove Invoice"
            />
        </Header>
        <ModalContent>
            <FormattedMessage
                id="pages.report.removeInvoice.message"
                defaultMessage="Are you sure you want to remove this invoice?"
                tagName="p"
            />
        </ModalContent>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.report.removeInvoice.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.report.removeInvoice.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default RemoveInvoiceDialog;
