import { set, isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    groupNameError: {
        id: 'pages.settings.services.groupForm.groupNameError',
        defaultMessage: 'Enter group name',
    },
});

export default (values, { intl, defaultLanguage }) => {
    const errors = {};

    if (!values.nameVariants || !values.nameVariants[defaultLanguage]) {
        set(
            errors,
            `nameVariants[${defaultLanguage}]`,
            intl.formatMessage(intlMessages.groupNameError),
        );
    }

    return errors;
};
