import {
    withHandlers
} from 'recompose';
import config from '../config';

let isTawkLoaded = false;
export default class Tawk {
    static initializeTawk = () => {
        if (!config.tawk || !config.tawk.enabled) {
            return;
        }
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.onLoad = function () {
            Tawk.minimizeTawk();
            //alert('loaded');
        };
        const script = document.createElement('script');
        script.async = true;
        script.src = config.tawk.url;
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        script.onload = () => (isTawkLoaded = true);
        document.head.appendChild(script);
    };

    static minimizeTawk = () => {
        if (isTawkLoaded) {
            window.Tawk_API.minimize();
        }
    };

    static maximizeTawk = () => {
        if (isTawkLoaded) {
            window.Tawk_API.maximize();
        }
    };

    static hideTawkWidget = () => {
        if (isTawkLoaded) {
            window.Tawk_API.hideWidget();
        }
    };

    static showTawkWidget = () => {
        if (isTawkLoaded) {
            window.Tawk_API.showWidget();
        }
    };

    static setTawkVisitorAttributes = attr => {
        if (isTawkLoaded) {
            window.Tawk_API.setAttributes({
                name: attr.name,
                email: attr.email,
                //etc.
            });
        }
    };
}

export const withTawk = withHandlers({
    minimizeTawk: () => () => Tawk.minimizeTawk(),
    maxizimeTawk: () => () => Tawk.maximizeTawk(),
    setTawkVisitorAttributes: () => () => Tawk.setTawkVisitorAttributes(),
    hideTawkWidget: () => () => Tawk.hideTawkWidget(),
    showTawkWidget: () => () => Tawk.showTawkWidget(),
});
