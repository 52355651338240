/**
 * @flow
 */
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { filterByManufacturers } from '../../../actions/inventory';
import { GET_MANUFACTURERS_QUERY } from './form/ManufacturerSelect';
import type { Dispatch } from '../../../type';
import ManufacturerFilterSelect from '../components/ManufacturersFilterSelect';
import { uniqBy, clone } from 'lodash';

const mapOptions = manufacturers => {
    return manufacturers.map(item => ({
        value: item.id,
        text: item.name,
    }));
};

const withData = graphql(GET_MANUFACTURERS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            manufacturer: ownProps.value || '',
            withManufacturer: !!ownProps.value,
        },
    }),
    props: ({ data: { loading, viewer, manufacturer } }) => {
        let options = [];

        if (!loading && viewer) {
            let manufacturers = clone(viewer.salon.manufacturers.nodes);
            manufacturer && manufacturers.push(manufacturer);
            manufacturers = uniqBy(manufacturers, 'id');

            options = mapOptions(manufacturers);
        }

        return {
            loading,
            options,
        };
    },
});

const mapStateToProps = ({ user }) => {
    return {
        salon: user.get('salon'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (e, { value }) => {
        dispatch(filterByManufacturers(value));
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
)(ManufacturerFilterSelect);
