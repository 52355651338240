/**
 * @flow
 */

import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { some, find, reduce, filter, map, get } from 'lodash';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import {
    getFreeTimeSpans,
    getInvertedTimeSpans,
} from '../../../../lib/schedule';
import { sortByName } from '../../../../lib/servicesSort';

import CabinetServiceFields from '../../components/form/CabinetServiceFields';

const withOptions = withProps(
    ({
        cabinets,
        cabinetId,
        serviceId,
        masterId,
        settings,
        duration,
        meta,
        form,
        field,
        change,
    }) => {
        const cabinet = find(cabinets, cabinet => cabinet.id === cabinetId);

        const services = cabinet
            ? reduce(
                  cabinet.serviceGroups,
                  (res, { services }) => [...res, ...services],
                  [],
              )
            : [];

        const serviceGroup =
            cabinet &&
            find(cabinet.serviceGroups, serviceGroup =>
                some(
                    serviceGroup.services,
                    service => service.id === serviceId,
                ),
            );

        const masters = serviceGroup
            ? filter(
                  serviceGroup.masters,
                  ({ schedule }) => schedule && schedule.length,
              )
            : [];

        let timeOptions = null;
        if (cabinetId && serviceId && cabinet) {
            if (
                !services ||
                !services.length ||
                !some(services, ['id', serviceId])
            ) {
                change(field ? `${field}.service` : 'service', null);
            }

            if (
                masterId &&
                (!masters ||
                    !masters.length ||
                    !some(masters, ['id', masterId]))
            ) {
                change(field ? `${field}.master` : 'master', null);
            }

            // Generate list of time spans when cabinet is not working at this date
            const notWorkingTime = getInvertedTimeSpans({
                startAt: settings.startAt,
                endAt: settings.endAt,
                timeSpans: map(cabinet.schedule, workingTime => [
                    workingTime.startAt,
                    workingTime.endAt,
                ]),
            });

            if (masterId && serviceGroup) {
                const master = find(
                    serviceGroup.masters,
                    ({ id }) => id === masterId,
                );
                if (master) {
                    const mastersNotWorkingTime = getInvertedTimeSpans({
                        startAt: settings.startAt,
                        endAt: settings.endAt,
                        timeSpans: map(master.schedule, workingTime => [
                            workingTime.startAt,
                            workingTime.endAt,
                        ]),
                    });
                    notWorkingTime.push(...mastersNotWorkingTime);
                }
            }

            // Find list of free time spans that can fit provided duration
            const freeTimeSpans = getFreeTimeSpans({
                timeSpan: [settings.startAt, settings.endAt],
                duration,
                exclude: notWorkingTime,
                step: settings.step,
            });

            // settings.format || 'HH:mm' - is hack, related to wrong format display in some cases

            // Convert free spans to list of options
            timeOptions = map(freeTimeSpans, timeSpan => {
                return {
                    text: moment()
                        .startOf('day')
                        .add(timeSpan[0], 'minutes')
                        .format(settings.format || 'HH:mm'),
                    value: timeSpan[0],
                };
            });
        }

        return {
            services: sortByName(services),
            masters,
            timeOptions,
        };
    },
);

const mapStateToProps = (
    { salon, user, intl, ...state },
    { meta, form, field },
) => {
    const formName = get(meta, 'form') || form;
    return {
        settings: salon.get('settings'),
        cabinetId: formValueSelector(formName)(
            state,
            field ? `${field}.cabinet` : 'cabinet',
        ),
        serviceId: formValueSelector(formName)(
            state,
            field ? `${field}.service` : 'service',
        ),
        masterId: formValueSelector(formName)(
            state,
            field ? `${field}.master` : 'master',
        ),
        duration: formValueSelector(formName)(
            state,
            field ? `${field}.duration` : 'duration',
        ),
    };
};

export default compose(
    connect(mapStateToProps),
    withOptions,
)(CabinetServiceFields);
