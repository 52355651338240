import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { find } from 'lodash';
import { compose, withProps, withHandlers } from 'recompose';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { map, omit, isEqual, filter } from 'lodash';

import SalonConnectionForm from '../components/form/SalonConnectionForm';
import validate from './form/salonConnectionsFormValidate';

import { GET_SUPPLIERS_QUERY } from './queries';

export const GET_SALON_LIST_QUERY = gql`
    query getSalonList($resolveRole: String) {
        viewer(resolveRole: $resolveRole) {
            id
            ... on Sysadmin {
                salonList {
                    id
                    name
                    administrator {
                        id
                        firstName
                        lastName
                        phone
                    }
                }
                manufacturerList {
                    id
                    name
                    salon
                    deletedAt
                }
            }
        }
    }
`;

const withData = graphql(GET_SALON_LIST_QUERY, {
    options: () => ({
        variables: {
            resolveRole: 'SYSADMIN',
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) =>
        !loading &&
        viewer && {
            loading,
            salons: viewer.salonList,
            manufacturers: viewer.manufacturerList,
        },
});

const withForm = reduxForm({
    form: 'salonConnect',
    validate,
});

const CREATE_CONNECTION_QUERY = gql`
    mutation createSupplierSalonConnection(
        $input: CreateSupplierSalonConnectionInput!
    ) {
        createSupplierSalonConnection(input: $input) {
            supplier
            connections {
                salon
                manufacturer
            }
        }
    }
`;

const withMutation = graphql(CREATE_CONNECTION_QUERY, {
    props: ({ mutate, ownProps: { supplier, onClose, searchQuery } }) => ({
        onSubmit: formData => {
            const input = {
                supplier: supplier.id,
                connections: formData.items,
            };
            const mutation = mutate({
                variables: { input },
                optimisticResponse: {
                    __typename: 'Mutation',
                    createSupplierSalonConnection: {
                        __typename: 'CreateSupplierSalonConnectionPayload',
                        supplier: supplier.id,
                        connections: formData,
                    },
                },
                update: (
                    store,
                    { data: { createSupplierSalonConnection } },
                ) => {
                    const variables = {
                        search: searchQuery || undefined,
                        first: 20,
                        resolveRole: 'SYSADMIN',
                    };

                    const data = store.readQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                    });

                    const item = find(data.viewer.suppliers.edges, [
                        'node.id',
                        createSupplierSalonConnection.supplier,
                    ]);

                    item.node.connections =
                        createSupplierSalonConnection.connections;

                    store.writeQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                        data,
                    });
                },
            });

            return mutation
                .then(({ data: { createSupplier } }) => {
                    onClose();
                })
                .catch(error => {
                    const graphQLError = error.graphQLErrors[0];

                    throw new SubmissionError({
                        _error: graphQLError,
                    });
                });
        },
    }),
});

const withPristineHandlers = withHandlers({
    checkFormPristine: ({ initialValues, formValues }) => () =>
        (initialValues &&
            formValues &&
            isEqual(
                initialValues.items.sort(),
                filter(
                    formValues.items,
                    item => item && item.salon && item.manufacturer,
                ).sort(),
            )) ||
        (formValues && !formValues.items.length),
});

// Setup initial values of the form
const withInitialValues = withProps(ownProps => ({
    initialValues: {
        items: map(ownProps.supplier.connections, item =>
            omit(item, ['__typename']),
        ),
    },
}));

export default compose(
    connect(({ suppliers, ...state }) => ({
        searchQuery: suppliers.get('searchQuery'),
        formValues: getFormValues('salonConnect')(state),
    })),
    injectIntl,
    withInitialValues,
    withData,
    withMutation,
    withForm,
    withPristineHandlers,
)(SalonConnectionForm);
