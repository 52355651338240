/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';

import AddSeminarDialog from '../containers/AddSeminarDialog';

const Button = styled(BaseButton)`
    width: 160px;
`;

const AddSeminarButton = ({ open, onClose, onOpen }) => (
    <AddSeminarDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} labelPosition="left" primary icon>
                <Icon name="add" />
                <FormattedMessage
                    id="pages.seminars.AddSeminarButton.label"
                    defaultMessage="Add New Seminar"
                />
            </Button>
        }
    />
);

export default withModal(AddSeminarButton);
