/**
 * @flow
 */

import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import { omit } from 'lodash';
import Raven from 'raven-js';
import { injectIntl, defineMessages } from 'react-intl';
import { prepareFloatForServer } from '../../../../../lib/numberFormatter';
import EditServiceDialog from '../../../components/services/service/EditServiceDialog';
import validate from './validate';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.settings.services.form.existingsError',
        defaultMessage: 'This service does not exist',
    },
    nameExistingsError: {
        id: 'pages.settings.services.add.nameExistingsError',
        defaultMessage: 'Service with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that update existing service with new data
const UPDATE_SERVICE_QUERY = gql`
    mutation updateService($input: UpdateServiceInput!, $lang: String!) {
        updateService(input: $input) {
            service {
                id
                name(lang: $lang)
                nameVariants {
                    lv
                    ru
                    en
                }
                duration
                price
                color
            }
        }
    }
`;

const withData = graphql(UPDATE_SERVICE_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update service
        onSubmit: formData => {
            const duration = formData.duration.toString();
            const price = prepareFloatForServer(formData.price);
            const submitData = omit(formData, ['name', 'duration', 'price']);
            Object.assign(submitData, {
                nameVariants: omit(submitData.nameVariants, ['__typename']),
            });

            const mutation = mutate({
                variables: {
                    input: {
                        serviceId: ownProps.service.id,
                        duration,
                        price,
                        ...submitData,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency and update
                // service in local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateService: {
                        __typename: 'UpdateServicePayload',
                        service: {
                            __typename: 'Service',
                            id: ownProps.service.id,
                            ...formData,
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                        },
                    },
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    Raven.captureException(error);

                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                name: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// Init service group form
const withForm = reduxForm({
    form: 'editService',
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
});

// Populate form with initial values from provided service object
const withInitialValues = withProps(ownProps => {
    if (ownProps.open) {
        return {
            initialValues: omit(ownProps.service, ['__typename', 'id']),
        };
    }
});

export default compose(
    connect(({ intl }) => ({
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
        currentLanguage: intl.get('locale'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(EditServiceDialog);
