import { Map } from 'immutable';

const now = new Date();

const initialState = new Map({
    fromDate: now,
    toDate: now,
    salonFilter: [],
    masterFilter: [],
    manufacturerFilter: [],
    showAppRecords: false,
    reportFilter: 'all',
});

export default (state = initialState, action) => {
    switch (action.type) {
        // Set a new report date range
        case 'REPORT.SET_DATE': {
            return state
                .set('fromDate', action.fromDate)
                .set('toDate', action.toDate);
        }
        case 'REPORT.FILTER.BY.SALONS': {
            return state.set('salonFilter', action.salons);
        }
        case 'REPORT.FILTER.BY.MASTERS': {
            return state.set('masterFilter', action.masters);
        }
        case 'REPORT.FILTER.BY.MANUFACTURERS': {
            return state.set('manufacturerFilter', action.manufacturers);
        }
        case 'REPORT.SET_APP_RECORDS': {
            return state.set('showAppRecords', action.showAppRecords);
        }
        case 'REPORT.SET_REPORT_FILTER': {
            return state.set('reportFilter', action.reports);
        }
        default: {
            return state;
        }
    }
};
