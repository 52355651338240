/**
 * @flow
 */

import { Map } from 'immutable';
import { saveState, loadState } from '../lib/sessionStorage';
import { uniq } from 'lodash';

const initialState = () => {
    const getPreferredLocale = () => {
        const localesPriority = ['en', 'lv', 'ru'];
        const browserLocales = uniq(
            (window.navigator.languages || []).map(item =>
                item.substring(0, 2),
            ),
        );

        let preferredLocale = 'en';

        localesPriority.some(locale => {
            let result = false;
            if (browserLocales.includes(locale)) {
                preferredLocale = locale;
                result = true;
            }
            return result;
        });

        return preferredLocale;
    };

    return Map(
        Object.assign(
            {
                languages: ['lv', 'ru', 'en'],
                defaultLanguage: 'lv',
            },
            loadState('intl') || {
                locale: getPreferredLocale(),
            },
        ),
    );
};

export default function(state: Object = initialState(), action: Object) {
    switch (action.type) {
        // Replace current locale with specified
        case 'INTL.UPDATE_LOCALE': {
            const newState = state.set('locale', action.locale);
            saveState('intl', {
                locale: action.locale,
            });

            return newState;
        }
        case 'SERVICES.SET_LANGUAGES': {
            return state
                .set('languages', action.languages)
                .set('defaultLanguage', action.defaultLanguage);
        }
        default:
            return state;
    }
}
