/**
 * @flow
 */

import { connect } from 'react-redux';
import { compose, withProps, withHandlers, mapProps } from 'recompose';
import { formValueSelector } from 'redux-form';
import ServiceSelect from '../../components/form/ServiceSelect';
import { each, find, map } from 'lodash';
import Fuse from 'fuse.js';

// const props = withProps(({ masterId, masters }) => {
//     const options = [];
//     const services = [];

//     const master = find(masters, master => master.id === masterId);

//     if (master) {
//         // Populate list of options with services that master provide
//         each(master.serviceGroups, serviceGroup => {
//             each(serviceGroup.services, service => {
//                 services.push({
//                     id: service.id,
//                     duration: service.duration,
//                 });
//                 options.push({
//                     value: service.id,
//                     text: service.name,
//                     description: service.price,
//                     'data-duration': `${service.duration} min`,
//                 });
//             });
//         });
//     }

//     return {
//         options,
//         services,
//         disabled: !master,
//     };
// });

const props = withProps(({ services }) => ({
    options: map(services, ({ id, name, price, duration }) => ({
        value: id,
        text: name,
        description: price,
        'data-duration': `${duration} min`,
    })),
}));

// Add layer that captures onChange event and sets value of duration field
// to duration specified in service
const handlers = withHandlers({
    onChange: ({ name, field, services, onChange, change }) => (
        e,
        { value },
    ) => {
        const service = find(services, {
            id: value,
        });
        if (service) {
            change(field ? `${field}.duration` : 'duration', service.duration);
        }

        onChange(value);
    },
    onSearch: () => (items, value) => {
        const fuse = new Fuse(items, {
            keys: ['text'],
            shouldSort: true,
            threshold: 0.3,
        });
        return fuse.search(value);
    },
});

const limitProps = mapProps(
    ({ meta, master, dispatch, change, services, masters, ...props }) => ({
        ...props,
    }),
);

export default compose(
    props,
    handlers,
    limitProps,
)(ServiceSelect);
