import React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Modal, Button } from '../../../../components';
import SupplierForm from '../form/SupplierForm';

import type { ReduxForm } from '../../../../type';

type PersonalInformationProps = {
    onClose: Function,
    userLookupStatus: string,
} & ReduxForm;

const PersonalInformation = ({
    onClose,
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    checkFormPristine,
    ...props
}: PersonalInformationProps) => (
    <Modal.Context>
        <Modal.Content>
            <SupplierForm
                {...props}
                userLookupStatus={userLookupStatus}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.suppliers.editSupplier.button.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.suppliers.editSupplier.button.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default PersonalInformation;
