import gql from 'graphql-tag';
import moment from 'moment';
import { connect } from 'react-redux';
import { withState, compose } from 'recompose';
import { graphql } from 'react-apollo';
import { setShowArchive } from '../../../actions/salon';
import OwnerSettingsPage from '../components/OwnerSettingsPage';

const mapStateToProps = ({ salon, user }) => ({
    fromDate: salon.get('fromDate'),
    toDate: salon.get('toDate'),
    salon: user.get('salon'),
});

const GET_SALON_QUERY = gql`
    query getSalon($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        enableArchive
                        reportByType
                        hideClientPhone
                        masterPanelLogin
                    }
                }
            }
        }
    }
`;

const ARCHIVE_APPOINTMENTS_MUTATION = gql`
    mutation archiveSelectedAppointments(
        $input: ArchiveSelectedAppointmentsInput!
    ) {
        archiveSelectedAppointments(input: $input) {
            appointment {
                id
                archived
                archivedAt
            }
        }
    }
`;

const UPDATE_SALON_ARCHIVE_QUERY = gql`
    mutation updateSalonArchiveSettings($input: UpdateSalonArchiveInput!) {
        updateSalonArchiveSettings(input: $input) {
            salon {
                id
                settings {
                    enableArchive
                }
            }
        }
    }
`;

const UPDATE_REPORT_TYPE_QUERY = gql`
    mutation updateSalonReportSettings($input: UpdateSalonReportInput!) {
        updateSalonReportSettings(input: $input) {
            salon {
                id
                settings {
                    reportByType
                }
            }
        }
    }
`;

const UPDATE_CLIENT_PHONE_SETTINGS = gql`
    mutation updateSalonClientPhoneSettings(
        $input: UpdateSalonClientPhoneSettingsInput!
    ) {
        updateSalonClientPhoneSettings(input: $input) {
            salon {
                id
                settings {
                    hideClientPhone
                }
            }
        }
    }
`;

const UPDATE_MASTER_LOGIN_SETTINGS = gql`
    mutation updateSalonMasterLoginSettings(
        $input: UpdateSalonMasterLoginSettingsInput!
    ) {
        updateSalonMasterLoginSettings(input: $input) {
            salon {
                id
                settings {
                    masterPanelLogin
                }
            }
        }
    }
`;

const withData = graphql(GET_SALON_QUERY, {
    props: ({ data: { loading, viewer } }) => ({
        loading,
        archiveToggle: !loading && viewer.salon.settings.enableArchive,
        reportToggle: !loading && viewer.salon.settings.reportByType,
        hidePhoneToggle: !loading && viewer.salon.settings.hideClientPhone,
        masterLoginToggle: !loading && viewer.salon.settings.masterPanelLogin,
    }),
});

const withReportMutation = graphql(UPDATE_REPORT_TYPE_QUERY, {
    props: ({ mutate, ownProps: { salon } }) => ({
        onReportEnable: reportByType => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: salon,
                        settings: {
                            reportByType,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon,
                        },
                    },
                ],
            });
            return mutation;
        },
    }),
});

const withArchiveMutation = graphql(UPDATE_SALON_ARCHIVE_QUERY, {
    props: ({ mutate, ownProps: { salon, dispatchSetShowArchive } }) => ({
        onArchiveEnable: enableArchive => {
            const settingsObject = {
                enableArchive,
            };
            const mutation = mutate({
                variables: {
                    input: {
                        id: salon,
                        settings: settingsObject,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon,
                        },
                    },
                ],
            });
            return mutation.then(() => {
                if (!enableArchive) {
                    // if we switch archive off, hide archived appointments
                    dispatchSetShowArchive(false);
                }
            });
        },
    }),
});

const withMutation = graphql(ARCHIVE_APPOINTMENTS_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onArchive: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        fromDate: moment(ownProps.fromDate).format(
                            'YYYY-MM-DD 00:00',
                        ),
                        toDate: moment(ownProps.toDate).format(
                            'YYYY-MM-DD 23:59',
                        ),
                    },
                },
            });
            return mutation;
        },
    }),
});

const withPhoneSettingsMutation = graphql(UPDATE_CLIENT_PHONE_SETTINGS, {
    props: ({ mutate, ownProps: { salon } }) => ({
        onHideClientPhone: hideClientPhone => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: salon,
                        settings: {
                            hideClientPhone,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon,
                        },
                    },
                ],
            });
            return mutation;
        },
    }),
});

const withMasterLoginSettingsMutation = graphql(UPDATE_MASTER_LOGIN_SETTINGS, {
    props: ({ mutate, ownProps: { salon } }) => ({
        onAllowMasterLogin: masterPanelLogin => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: salon,
                        settings: {
                            masterPanelLogin,
                        },
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon,
                        },
                    },
                ],
            });
            return mutation;
        },
    }),
});

const mapDispatchToProps = {
    dispatchSetShowArchive: setShowArchive,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withState('verificationWord', 'setVerificationWord', ''),
    withMutation,
    withArchiveMutation,
    withReportMutation,
    withPhoneSettingsMutation,
    withMasterLoginSettingsMutation,
    withData,
)(OwnerSettingsPage);
