/**
 *  @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { Modal, Button } from '../../../components';
import { FormattedMessage } from 'react-intl';
import { ProductForm } from './form';

import type { ReduxForm } from '../../../type';

type AddProductDialogProps = {
    // The trigger (usually button) that oppens this form
    trigger: Object,
    onClose: Function,
} & ReduxForm;

const AddProductDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    error,
}: AddProductDialogProps) => (
    <Modal trigger={trigger} onClose={onClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.inventory.AddProductDialog.header"
                defaultMessage="New Product"
            />
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <ProductForm
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.inventory.AddProductDialog.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.inventory.AddProductDialog.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default AddProductDialog;
