/**
 * @flow
 */

import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { isString } from 'lodash';

type Meta = {
    touched: boolean,
    error: string,
};

type FormErrorMessageProps = {
    reason: string | Object,
    pointing?: string,
};

type FormFieldProps = {
    input: Object,
    meta: Meta,
    label?: string | Function,
    inline?: boolean,
    required?: boolean,
    errorPointer?: string,
    simpleValue: boolean,
    width: any,
    props: any,
};

const FormErrorMessage = ({ reason, pointing }: FormErrorMessageProps) => {
    const message = reason;
    if (!message) {
        return null;
    }

    return <Label color="red" pointing={pointing} content={message} basic />;
};

const renderLabel = label => {
    if (isString(label)) {
        return <label>{label}</label>;
    }

    return label;
};

const FileField = ({
    input: { value, onChange, ...inputProps },
    meta,
    label,
    inline,
    required,
    errorPointer,
    simpleValue,
    width,
    ...props
}: FormFieldProps) => {
    return (
        <Form.Field
            error={meta.touched && !!meta.error}
            inline={inline}
            required={required}
            width={width}
        >
            {label && renderLabel(label)}
            <input
                type="file"
                accept="image/*,.pdf"
                {...inputProps}
                {...props}
                onChange={(e, data) => {
                    if (data) {
                        return onChange(simpleValue ? data.value : data);
                    }
                    return onChange(e);
                }}
            />
            {meta.touched && meta.error && (
                <FormErrorMessage
                    reason={meta.error}
                    pointing={errorPointer || true}
                />
            )}
        </Form.Field>
    );
};

export default FileField;
