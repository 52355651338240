/**
 * @flow
 */

import { compose, withStateHandlers, mapProps, withProps } from 'recompose';
import { map, includes, filter } from 'lodash';
import { Select } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const mapOptions = item => ({
    text: item.name,
    value: item.id,
    description: `${item.administrator.firstName} ${
        item.administrator.lastName
    }`,
});

const withOptions = withProps(({ salons }) => ({
    options: map(salons, mapOptions),
    search: true,
}));

/**
 * Keep state of search query
 */
const withSearch = withStateHandlers(
    { search: null },
    {
        onSearch: () => (event, data) => ({
            search: data.searchQuery,
        }),
    },
);

const mapStateToProps = (state, { meta, fields }) => {
    return {
        selectedValues: fields.map(field =>
            formValueSelector(meta.form)(state, `${field}.salon`),
        ),
    };
};

const limitProps = mapProps(
    ({
        meta,
        change,
        salons,
        onSearch,
        fields,
        selectedValues,
        dispatch,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(mapStateToProps),
    withSearch,
    withOptions,
    limitProps,
)(Select);
