/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, withProps, branch, renderComponent } from 'recompose';
import Avatar from './Avatar';

type MasterTypeIconProps = {
    type: string,
} & Avatar;

const icons = {
    hairdresser: '/svg/icon-hairdresser.svg',
    cosmetologist: '/svg/icon-cosmetologist.svg',
    manicure: '/svg/icon-manicure.svg',
};

const Icon = styled(Avatar)`
    flex: 0 0 auto;
    overflow: hidden;
    vertical-align: middle;
    img {
        max-width: unset !important;
    }
`;

const EmptyIcon = styled.div`
    display: inline-block;
    width: 24px;
    margin-right: 0.5em;
`;

const MasterTypeIcon = ({ type, ...avatarProps }: MasterTypeIconProps) => (
    <Icon
        src={icons[String(type).toLowerCase()]}
        size={24}
        spaced="right"
        round
        {...avatarProps}
    />
);

const props = withProps(({ type }) => ({
    icon: icons[String(type).toLowerCase()],
}));

const withNoRender = branch(({ icon }) => !icon, renderComponent(EmptyIcon));

export default compose(
    props,
    withNoRender,
)(MasterTypeIcon);
