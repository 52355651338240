/**
 * @flow
 */
import {
    connect
} from 'react-redux';
import {
    compose,
    mapProps,
} from 'recompose';
import {
    Select
} from 'semantic-ui-react';
import {
    range,
    get,
} from 'lodash';

const mapStateToProps = (state, props) => {
    const product = get(state.invoice.get('selectedProducts'), props.name.replace('quantity', 'entity'));
    let max = 100;
    if (product) {
        max = product.quantity;
    }
    return {
        max,
    };
};

const props = mapProps(({
    max,
    meta,
    dispatch,
    ...props,
}) => ({
    // since range doesn't include end, do max + 1
    options: range(0, max + 1).map(item => ({
        value: item,
        text: item,
    })),
    ...props,
}));

export default compose(
    connect(mapStateToProps),
    props,
)(Select);
