/**
 * Represents component with time range defined by
 * provided props.
 *
 * @flow
 */

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { range } from 'lodash';
import moment from 'moment';
import { isNowLineOverlap } from '../../lib/schedule';
import Cell from './Cell';

import { CELL_STEP, CELL_HEIGHT_PX } from './Cell';

import type { ScheduleSettings } from '../../type';

const TIMELINE_WIDTH_PX = 90;

type TimelineProps = {
    schedule: ScheduleSettings,
    date: Object,
    cellHeight: number,
};

type TimelineCellProps = {
    schedule: ScheduleSettings,
    date: Object,
    time: number,
    cellHeight: number,
};

const Container = styled.div`
    width: ${TIMELINE_WIDTH_PX}px;
    flex: 1;
    display: flex;
    flex-direction: column;
    & .cell:first-child .value {
        display: none;
    }
    & .cell:last-child {
        border-bottom: 0px !important;
    }
`;

const TimelineCellContent = styled(Cell)`
    position: relative;
    font-size: 14px;
    padding: 0px 14px 0px 14px;
    border-bottom: 0px;
    flex: 1;
`;

const CellHour = styled.div`
    position: absolute;
    top: -11px;
    right: 14px;
`;

const CellMinute = styled.div`
    position: absolute;
    top: ${props => props.offset - 9.5}px;
    right: 14px;
    font-size: 10px;
    color: #c1c1c1;
    font-weight: normal;
    user-select: none;
`;

/**
 * Render minutes for the cell with provided step
 */
const CellMinutes = ({ schedule, date, time, cellHeight }) => (
    <div>
        {range(schedule.step, CELL_STEP, schedule.step)
            .filter(
                step =>
                    !isNowLineOverlap(
                        moment
                            .utc(date)
                            .startOf('day')
                            .add(time + step, 'minutes')
                            .toDate(),
                    ),
            )
            .map(step => (
                <CellMinute key={step} offset={(cellHeight / CELL_STEP) * step}>
                    {moment
                        .utc(date)
                        .startOf('day')
                        .add(time + step, 'minutes')
                        .format(schedule.format)}
                </CellMinute>
            ))}
    </div>
);

const TimelineCell = ({
    className,
    date,
    time,
    schedule,
    cellHeight,
}: TimelineCellProps) => (
    <TimelineCellContent className="cell">
        {!isNowLineOverlap(
            moment
                .utc(date)
                .startOf('day')
                .add(time, 'minutes')
                .toDate(),
        ) && (
            <CellHour className="value">
                {moment
                    .utc(date)
                    .startOf('day')
                    .add(time, 'minutes')
                    .format(schedule.format)}
            </CellHour>
        )}
        {schedule.step < CELL_STEP && (
            <CellMinutes
                schedule={schedule}
                date={date}
                time={time}
                cellHeight={cellHeight}
            />
        )}
    </TimelineCellContent>
);

/**
 * Represent vertical timeline. All time values should be provided
 * in minutes starting from midnight.
 */
const Timeline = ({ schedule, date, cellHeight }: TimelineProps) => (
    <Container>
        {range(schedule.startAt, schedule.endAt, CELL_STEP).map(time => (
            <TimelineCell
                key={time}
                date={date}
                time={time}
                schedule={schedule}
                format={schedule.format}
                cellHeight={cellHeight}
            />
        ))}
    </Container>
);

const mapStateToProps = ({ appointments }) => ({
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
});

export default connect(mapStateToProps)(Timeline);
