/**
 *  @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Icon, Input, Form } from 'semantic-ui-react';
import { mapProps } from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Modal, Button, Table, FormField, Toggle } from '../../../components';
import RemoveInvoiceButton from './RemoveInvoiceButton';
import PaymentTypeField from '../../appointments/components/details/invoice/PaymentTypeField';
import PaymentTypeFieldMembership from '../../appointments/components/details/invoice/PaymentTypeFieldMembership';

const limitedProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const fields = {
    input: FormField(limitedProps(Input)),
    paymentType: FormField(PaymentTypeField),
    paymentTypeMembership: FormField(PaymentTypeFieldMembership),
};

const intlMessages = defineMessages({
    walkinClient: {
        id: 'pages.appointments.details.AppointmentDetails.walkinClient',
        defaultMessage: 'Walk-in Client',
    },
});

const ClientCell = ({ client, intl }) => {
    let txt = '';
    if (client && client.firstName) {
        txt = `${client.firstName} ${client.lastName}`;
        if (client.phone) {
            txt += ` (${client.phone})`;
        }
    } else {
        txt = intl.formatMessage(intlMessages.walkinClient);
    }

    return <Table.Cell>{txt}</Table.Cell>;
};

const TableRow = styled(Table.Row)`
    position: relative;
`;

const ArchiveInvoiceCell = styled(Table.Cell)`
    width: 145px;
`;

const ArchiveInvoiceHeaderCell = styled(Table.HeaderCell)`
    width: 145px;
`;

const PriceCell = styled(Table.Cell)`
    width: 120px;
`;

const PriceHeaderCell = styled(Table.HeaderCell)`
    width: 120px;
`;

const PaymentTypeCell = styled(Table.Cell)`
    width: 220px;
`;

const PaymentTypeHeaderCell = styled(Table.HeaderCell)`
    width: 220px;
`;

const EditInvoiceDialog = ({
    trigger,
    open,
    onClose,
    onModalClose,
    handleSubmit,
    submitting,
    pristine,
    client,
    item,
    invoiceId,
    intl,
    invoiceType,
    archived,
    onArchive,
    ...props
}) => {
    return (
        <Modal
            size="large"
            trigger={trigger}
            open={open}
            onClose={onModalClose}
        >
            <Modal.Header>
                <FormattedMessage
                    id="pages.report.editInvoice.edit"
                    defaultMessage="Edit invoice"
                />
            </Modal.Header>
            <Modal.Content>
                <Form onSubmit={handleSubmit}>
                    <Table celled fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id="pages.report.ReportTable.client"
                                        defaultMessage="Client"
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id="pages.report.ReportTable.service"
                                        defaultMessage="Service/Product"
                                    />
                                </Table.HeaderCell>
                                <PriceHeaderCell>
                                    <FormattedMessage
                                        id="pages.report.ReportTable.price"
                                        defaultMessage="Price"
                                    />
                                </PriceHeaderCell>
                                <PaymentTypeHeaderCell>
                                    <FormattedMessage
                                        id="pages.report.ReportTable.paymentType"
                                        defaultMessage="Payment type"
                                    />
                                </PaymentTypeHeaderCell>
                                {invoiceType === 'CART' && (
                                    <ArchiveInvoiceHeaderCell>
                                        <FormattedMessage
                                            id="pages.report.ReportTable.archive"
                                            defaultMessage="Unarchive/Archive"
                                        />
                                    </ArchiveInvoiceHeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <TableRow>
                                <ClientCell client={client} intl={intl} />
                                <Table.Cell>{item.entity.name}</Table.Cell>
                                <PriceCell>
                                    <Field
                                        name="price"
                                        component={fields.input}
                                        simpleValue
                                        required
                                    />
                                </PriceCell>
                                <PaymentTypeCell>
                                    <Field
                                        style={{
                                            position: 'absolute',
                                            top: '57px',
                                            width: '196px',
                                        }}
                                        name="paymentType"
                                        component={
                                            item.paymentType === 'MEMBERSHIP'
                                                ? fields.paymentTypeMembership
                                                : fields.paymentType
                                        }
                                        width={2}
                                        simpleValue
                                        required
                                    />
                                </PaymentTypeCell>
                                {invoiceType === 'CART' && (
                                    <ArchiveInvoiceCell textAlign="center">
                                        <Toggle
                                            onChange={e => {
                                                onArchive(e, invoiceId);
                                            }}
                                            value={archived}
                                        />
                                    </ArchiveInvoiceCell>
                                )}
                            </TableRow>
                        </Table.Body>
                    </Table>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={submitting || pristine}
                    loading={submitting}
                    onClick={handleSubmit}
                    primary
                >
                    <Icon name="checkmark" />
                    <FormattedMessage
                        id="pages.settings.manufacturers.form.edit.save"
                        defaultMessage="Save"
                    />
                </Button>
                <Button disabled={submitting} onClick={onModalClose} basic>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.settings.manufacturers.form.edit.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <RemoveInvoiceButton invoiceId={invoiceId} />
            </Modal.Actions>
        </Modal>
    );
};

export default EditInvoiceDialog;
