/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {
    Container,
    Menu as BaseMenu,
    Grid,
    Statistic,
    Divider,
} from 'semantic-ui-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import type { Intl } from '../../../type';
import { PageHeader } from '../../../components';
import { EllipsisLoader } from '../../../components';
import PromoCodePage from '../containers/PromoCodePage';

type StatisticsPageProps = { summary: any, intl: Intl };

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const Label = styled.span`
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
`;

const intlMessages = defineMessages({
    salonLabel: {
        id: 'pages.administrators.statistics.salons',
        defaultMessage: 'Salons',
    },
    masterLabel: {
        id: 'pages.administrators.statistics.masters',
        defaultMessage: 'Masters',
    },
    selfEmployedLabel: {
        id: 'pages.administrators.statistics.selfEmployed',
        defaultMessage: 'Self-employed',
    },
    clientLabel: {
        id: 'pages.administrator.statistics.clients',
        defaultMessage: 'Clients',
    },
    clientInAppLabel: {
        id: 'pages.administrator.statistics.clientsInApp',
        defaultMessage: 'Clients in the app',
    },
    total: {
        id: 'pages.administrator.statistics.total',
        defaultMessage: 'Total',
    },
    thisMonth: {
        id: 'pages.administrator.statistics.thisMonth',
        defaultMessage: 'This month',
    },
});

const RevenueDetailsLabel = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
`;

const RevenueDetailsValue = styled.span`
    white-space: nowrap;
    font-size: 1em;
`;

const StyledEllipsisLoader = styled(EllipsisLoader)`
    & .bounce {
        line-height: 1em;
        margin-top: -10px;
    }
`;

const ReportSummaryLoader = ({ loading, children }) => {
    if (!loading) {
        return children;
    }
    return <StyledEllipsisLoader size="0.3em" />;
};

const CellBase = ({ label, total, thisMonth, intl, loading }) => (
    <div>
        <Label>{label}</Label>
        <ReportSummaryLoader loading={loading}>
            <RevenueDetailsLabel>
                <Statistic value={total} />
                <RevenueDetailsValue>
                    <FormattedMessage
                        id="pages.administrator.statistics.thisMonth"
                        defaultMessage="This month"
                    />
                    : {thisMonth}
                </RevenueDetailsValue>
            </RevenueDetailsLabel>
        </ReportSummaryLoader>
    </div>
);

const Cell = injectIntl(CellBase);

const StatisticsPage = ({ summary, intl, ...props }: StatisticsPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.administrator.statistics.label"
                        defaultMessage="Statistics"
                    />
                </PageHeader>
            </BaseMenu.Item>
        </Menu>
        <Divider />
        <Grid
            style={{
                minHeight: '220px',
            }}
        >
            <Grid.Row columns={5}>
                <Grid.Column textAlign="center">
                    <Cell
                        label={intl.formatMessage(intlMessages.salonLabel)}
                        total={
                            get(summary, 'salonSummary.total', 0) -
                            get(summary, 'selfEmployedSummary.total', 0)
                        }
                        thisMonth={
                            get(summary, 'salonSummary.thisMonth', 0) -
                            get(summary, 'selfEmployedSummary.thisMonth', 0)
                        }
                        {...props}
                    />
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Cell
                        label={intl.formatMessage(intlMessages.masterLabel)}
                        total={get(summary, 'masterSummary.total', 0)}
                        thisMonth={get(summary, 'masterSummary.thisMonth', 0)}
                        {...props}
                    />
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Cell
                        label={intl.formatMessage(
                            intlMessages.selfEmployedLabel,
                        )}
                        total={get(summary, 'selfEmployedSummary.total', 0)}
                        {...props}
                        thisMonth={get(
                            summary,
                            'selfEmployedSummary.thisMonth',
                            0,
                        )}
                    />
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Cell
                        label={intl.formatMessage(intlMessages.clientLabel)}
                        total={get(summary, 'localClientSummary.total', 0)}
                        {...props}
                        thisMonth={get(
                            summary,
                            'localClientSummary.thisMonth',
                            0,
                        )}
                    />
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Cell
                        label={intl.formatMessage(
                            intlMessages.clientInAppLabel,
                        )}
                        total={get(summary, 'globalClientSummary.total', 0)}
                        {...props}
                        thisMonth={get(
                            summary,
                            'globalClientSummary.thisMonth',
                            0,
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <PromoCodePage />
    </Container>
);

export default injectIntl(StatisticsPage);
