/**
 * @flow
 */

import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { remove } from 'lodash';
import { SubmissionError } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';

import DeleteSupplierDialog from '../components/DeleteSupplierDialog';
import { GET_SUPPLIERS_QUERY, DELETE_SUPPLIER_QUERY } from './queries';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withData = graphql(DELETE_SUPPLIER_QUERY, {
    props: ({
        mutate,
        ownProps: { supplier, searchQuery, onClose, intl },
    }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        supplierId: supplier.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete supplier before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteSupplier: {
                        __typename: 'DeleteSupplierPayload',
                        supplierId: supplier.id,
                    },
                },
                // Remove deleted supplier from query cache
                update: (store, { data: { deleteSupplier } }) => {
                    const variables = {
                        search: searchQuery || undefined,
                        first: 20,
                        resolveRole: 'SYSADMIN',
                    };

                    const data = store.readQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                    });

                    remove(
                        data.viewer.suppliers.edges,
                        edge => edge.node.id === deleteSupplier.supplierId,
                    );

                    store.writeQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    onClose();
                })
                .catch(() => {
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

export default compose(
    injectIntl,
    withData,
)(DeleteSupplierDialog);
