/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { branch, renderComponent } from 'recompose';
import { Loader, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Modal } from '../../../../../components';
import EditInvoice from '../../../containers/details/invoice/EditInvoice';

import type { Invoice } from '../../../../../type';

// Fix styling of spinner inside modal window
const Spinner = styled(Loader)`
    &::before {
        border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
    }
    &::after {
        border-color: #767676 transparent transparent !important;
    }
`;

type AppointmentInvoiceProps = {
    invoice: Invoice,
};

const AppointmentInvoice = ({
    invoice,
    membership,
    ...props
}: AppointmentInvoiceProps) => {
    if (invoice) {
        return (
            <EditInvoice invoice={invoice} membership={membership} {...props} />
        );
    }

    return (
        <Modal.Context>
            <Modal.Content>
                <Message>
                    <FormattedMessage
                        id="pages.appointments.details.invoice.AppointmentInvoice.no_invoice"
                        defaultMessage="This appointment has no invoice yet."
                    />
                </Message>
            </Modal.Content>
        </Modal.Context>
    );
};

const withSpinner = branch(
    ({ loading }) => loading,
    renderComponent(() => (
        <Modal.Context>
            <Modal.Content>
                <Spinner inline="centered" size="big" active />
            </Modal.Content>
        </Modal.Context>
    )),
);

export default withSpinner(AppointmentInvoice);
