/**
 * @flow
 */

import React from 'react';
import { mapProps } from 'recompose';
import { Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const options = [
    {
        value: 'CASH',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.cash"
                defaultMessage="Cash"
            />
        ),
    },
    {
        value: 'CC',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.credit_card"
                defaultMessage="Credit Card"
            />
        ),
    },
    {
        value: 'GIFT',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.gift_card"
                defaultMessage="Gift Card"
            />
        ),
    },
];

const props = mapProps(
    ({ meta, membership, allServices, setInfoVisible, ...props }) => ({
        options,
        ...props,
    }),
);

export default props(Select);
