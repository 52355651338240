/**
 * @flow
 */

import { connect } from 'react-redux';
import { Search } from '../../../components';
import { search } from '../../../actions/clients';

import type { Dispatch } from '../../../type';

const mapStateToProps = ({ clients }) => ({
    value: clients.get('searchQuery'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (e, { value }) => {
        dispatch(search(value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
