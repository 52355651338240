/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { prepareFloatForServer } from '../../../../lib/numberFormatter';

import EditProduct from '../../components/details/EditProduct';
import validate from '../form/productFormValidate';

// import { GET_PRODUCTS_QUERY } from '../ProductsList';

import type { Product } from '../../../../type';
import { injectIntl, defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.inventory.form.ProductForm.existingsError',
        defaultMessage: 'This product does not exists',
    },
    nameExistingsError: {
        id: 'pages.inventory.AddProductDialog.nameExistingsError',
        defaultMessage: 'Product with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'editProduct',
    touchOnBlur: false,
    validate,
});

const UPDATE_PRODUCT_QUERY = gql`
    mutation updateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input) {
            product {
                id
                name
                code
                manufacturer {
                    id
                    name
                }
                price
                supplierPrice
                quantity
                type
            }
        }
    }
`;

const withData = graphql(UPDATE_PRODUCT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update product
        onSubmit: formData => {
            if (formData.supplierPrice === '') {
                formData.supplierPrice = null;
            }

            const quantity = parseInt(formData.quantity);
            const price = prepareFloatForServer(formData.price);
            const supplierPrice =
                formData.supplierPrice &&
                prepareFloatForServer(formData.supplierPrice);

            const mutation = mutate({
                variables: {
                    input: {
                        productId: ownProps.product.id,
                        ...omit(formData, ['quantity']),
                        quantity,
                        price,
                        supplierPrice,
                        salon: ownProps.salon,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and update product directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateProduct: {
                        __typename: 'UpdateProductPayload',
                        product: {
                            __typename: 'Product',
                            id: ownProps.product.id,
                            manufacturer: {
                                __typename: 'Manufacturer',
                                id: formData.manufacturer,
                                name: '',
                            },
                            ...omit(formData, ['manufacturer']),
                        },
                    },
                },
                // // Replace old product in cachen with a new one
                // update: (store, { data: { updateProduct } }) => {
                //     const data = store.readQuery({ query: GET_PRODUCTS_QUERY });
                //     data.products.forEach(product => {
                //         if (product.id === updateProduct.product.id) {
                //             product = updateProduct.product;
                //         }
                //     });
                //     store.writeQuery({ query: GET_PRODUCTS_QUERY, data });
                // },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'NotExists') {
                            if (
                                graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.existingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'NAME_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    name: intl.formatMessage(
                                        intlMessages.nameExistingsError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

const initialValues = withProps(({ product }: { product: Product }) => ({
    initialValues: {
        name: product.name,
        code: product.code,
        manufacturer: product.manufacturer.id,
        quantity: product.quantity,
        price: parseFloat(product.price).toFixed(2),
        supplierPrice:
            product.supplierPrice == null
                ? ''
                : parseFloat(product.supplierPrice).toFixed(2),
        type: product.type,
    },
}));

export default compose(
    connect(({ user }) => ({ salon: user.get('salon') })),
    injectIntl,
    withData,
    initialValues,
    withForm,
)(EditProduct);
