/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Menu as BaseMenu, Grid, Container } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../../components';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Search from '../../containers/manufacturers/Search';
import AddManufacturerButton from './AddManufacturerButton';
import ManufacturersList from '../../containers/manufacturers/ManufacturersList';
import type { Intl } from '../../../../type';

type ManufacturersPageProps = { intl: Intl };

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = defineMessages({
    manufacturerSearchPlaceholder: {
        id: 'pages.settings.manufacturers.search',
        defaultMessage: 'Search...',
    },
});

const ManufacturersSettings = ({ intl }: ManufacturersPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.settings.manufacturers"
                        defaultMessage="Manufacturers settings"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <Search
                        icon="search"
                        placeholder={intl.formatMessage(
                            intlMessages.manufacturerSearchPlaceholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <AddManufacturerButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <ManufacturersList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(ManufacturersSettings);
