/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Container, Menu as BaseMenu } from 'semantic-ui-react';

import type { IntlShape } from 'react-intl';

import { Divider, PageHeader } from '../../../components';
import CreateSupplierButton from './CreateSupplierButton';
import SupplierList from '../containers/SupplierList';
import Search from '../containers/Search';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = defineMessages({
    searchPlaceholder: {
        id: 'pages.suppliers.supplierList.search.placeholder',
        defaultMessage: 'Search...',
    },
});

type SuppliersPageProps = {
    intl: IntlShape,
};

const SuppliersPage = ({ intl }: SuppliersPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.suppliers.supplierList.header"
                        defaultMessage="Suppliers"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.searchPlaceholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <CreateSupplierButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <SupplierList />
    </Container>
);

export default injectIntl(SuppliersPage);
