/**
 * Store user lookup status and result value
 *
 * @flow
 */

import gql from 'graphql-tag';
import { compose, withState, withHandlers } from 'recompose';
import { get } from 'lodash';
import client from '../apollo-client';

// Store the status of user lookup
const withUserLookupStatus = withState(
    'userLookupStatus',
    'setUserLookupStatus',
    null,
);

// Store the user of user lookup
const withUserLookupUser = withState(
    'userLookupUser',
    'setUserLookupUser',
    null,
);

const FIND_USER_QUERY = gql`
    query getUser($email: String, $phone: String, $role: UserRole) {
        user(email: $email, phone: $phone, role: $role) {
            id
            ... on Master {
                ...user
            }
            ... on Client {
                ...user
            }
            ... on Administrator {
                ...user
            }
            ... on Supplier {
                ...user
            }
        }
    }

    fragment user on User {
        email
        firstName
        lastName
        phone
        gender
    }
`;

const handlers = withHandlers({
    /**
     * Handle user lookup callback and set correct lookup status
     */
    onUserLookup: ({ setUserLookupStatus, setUserLookupUser }) => ({
        email,
        phone,
        role,
    }) => {
        setUserLookupStatus('CHECKING');
        client
            .query({
                query: FIND_USER_QUERY,
                variables: { email, phone },
            })
            .then(response => {
                const user = get(response, 'data.user');
                setUserLookupStatus(user ? 'EXISTS' : null);
                setUserLookupUser(user);
            });
    },
    /**
     * Reset user lookup status
     */
    resetUserLookup: ({ setUserLookupStatus, setUserLookupUser }) => () => {
        setUserLookupStatus(null);
        setUserLookupUser(null);
    },
});

export default compose(
    withUserLookupStatus,
    withUserLookupUser,
    handlers,
);
