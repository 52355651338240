import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import * as appReducers from './reducers';

const middleware = applyMiddleware(thunk);

const reducers = combineReducers({
    form: formReducer,
    ...appReducers,
});

const store = createStore(
    reducers,
    compose(
        middleware,
        window.devToolsExtension ? window.devToolsExtension() : f => f,
    ),
);

export default store;
