/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderNothing } from 'recompose';
import { List } from 'semantic-ui-react';
import { Color as BaseColor } from '../../../../components';
import { map, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import type { Service } from '../../../../type';

type LegendProps = {
    services: Array<Service>,
};

const Title = styled.span`
    font-weight: bold;
    text-transform: uppercase;
    color: #aaa;
`;

const Color = styled(BaseColor)`
    vertical-align: top;
`;

const ListItem = styled(List.Item)`
    line-height: 14px;
    font-size: 14px;
`;

const Legend = ({ services }: LegendProps) => (
    <List horizontal>
        <ListItem>
            <Title>
                <FormattedMessage
                    id="pages.schedule.legend"
                    defaultMessage="Legend"
                />:
            </Title>
        </ListItem>

        {map(services, service => (
            <ListItem key={service.id}>
                <Color size={14} color={service.color} spaced="right" />
                {service.name}
            </ListItem>
        ))}
    </List>
);

// Show spinner while loading services
const withSpinner = branch(props => isEmpty(props.services), renderNothing);

export default compose(withSpinner)(Legend);
