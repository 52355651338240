/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty, property } from 'lodash';
import { List, Loader } from 'semantic-ui-react';
import { Table } from '../../../components';
import { FormattedMessage } from 'react-intl';

import EditCabinetButton from './EditCabinetButton';
import DeleteCabinetButton from './DeleteCabinetButton';

const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

/**
 * Show this message if list of masters is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={2} textAlign="center">
            <FormattedMessage
                id="pages.cabinets.CabinetList.noCabinets"
                defaultMessage="You don't have cabinets yet"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents component with single row of cabinet details
 */
const CabinetRow = ({ cabinet }) => (
    <Table.Row>
        <Table.Cell>
            <span>{cabinet.name}</span>
        </Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditCabinetButton cabinet={cabinet} />
                </List.Item>
                <List.Item>
                    <DeleteCabinetButton cabinet={cabinet} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents table with list of cabinet
 */
const CabinetList = ({ cabinets }) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.cabinets.CabinetList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.cabinets.CabinetList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(cabinets) ? (
                <EmptyMessage />
            ) : (
                cabinets.map(
                    cabinet =>
                        !!cabinet && (
                            <CabinetRow key={cabinet.id} cabinet={cabinet} />
                        ),
                )
            )}
        </Table.Body>
    </Table>
);

// Show spinner while cabinet list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(CabinetList);
