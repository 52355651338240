import { withProps } from 'recompose';
import moment from 'moment';
import { Select } from 'semantic-ui-react';

const options = [
    {
        value: 'hh:mm a',
        text: 'hh:mm a',
        description: moment().format('hh:mm a'),
    },
    {
        value: 'HH:mm',
        text: 'HH:mm',
        description: moment().format('HH:mm'),
    },
];

export default withProps({ options })(Select);
