/**
 * @flow
 */

import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { MasterTypeIcon } from '../../../../../components';

const options = [
    {
        key: 1,
        value: 'HAIRDRESSER',
        icon: <MasterTypeIcon type="hairdresser" />,
        text: (
            <FormattedMessage
                id="pages.settings.services.groupType.hairdresser"
                defaultMessage="Hairdresser"
            />
        ),
    },
    {
        key: 2,
        value: 'COSMETOLOGIST',
        icon: <MasterTypeIcon type="cosmetologist" />,
        text: (
            <FormattedMessage
                id="pages.settings.services.groupType.cosmetologist"
                defaultMessage="Cosmetologist"
            />
        ),
    },
    {
        key: 3,
        value: 'MANICURE',
        icon: <MasterTypeIcon type="manicure" />,
        text: (
            <FormattedMessage
                id="pages.settings.services.groupType.manicure"
                defaultMessage="Manicure"
            />
        ),
    },
    {
        key: 4,
        value: 'OTHER',
        icon: <MasterTypeIcon type="other" />,
        text: (
            <FormattedMessage
                id="pages.settings.services.groupType.other"
                defaultMessage="Other"
            />
        ),
    },
];

const ServiceTypeSelect = <Dropdown fluid selection options={options} />;

export default ServiceTypeSelect;
