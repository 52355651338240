/**
 * @flow
 */

import styled from 'styled-components';
import { Table as BaseTable } from 'semantic-ui-react';

const Table = styled(BaseTable)`
    position: relative;
    border-radius: 0 !important;
    border: ${({ basic }) => (basic ? 0 : 1)}px solid #e9e9e9 !important;

    // Change color of striped table
    &.ui.striped.table tbody tr:nth-child(2n) {
        background-color: #f5fafd !important;
    }

    // Set background color for on-hover event
    &.ui.selectable.table tbody tr:hover,
    .ui.table tbody tr td.selectable:hover {
        background: #d7edf7 !important;
        color: rgba(0, 0, 0, 0.95) !important;
        transition: background-color 0.3s;
    }

    // Set background color for on-hover event for transparent cell
    &.ui.selectable.table tbody tr.transparent:hover {
        background: transparent !important;
    }

    &.ui.table tr:first-child td {
        border-top: none !important;
    }
    &.ui.celled.table tr td:first-child,
    .ui.celled.table tr th:first-child {
        border-left: none !important;
    }
    &.ui.celled.table tr td,
    .ui.celled.table tr th {
        border-left: 1px solid #e9e9e9;
    }
`;

const TableRow = styled(BaseTable.Row)`
    transition: background-color 0.3s;
`;

const TableCell = styled(BaseTable.Cell)`
    border-top: 1px solid #e9e9e9 !important;
`;

const TableHeaderCell = styled(BaseTable.HeaderCell)`
    background: #f8f8f8 !important;
    font-weight: 600 !important;
`;

Table.Body = BaseTable.Body;
Table.Footer = BaseTable.Footer;
Table.Header = BaseTable.Header;
Table.HeaderCell = TableHeaderCell;
Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
