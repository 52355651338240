/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import {
    some,
    find,
    reduce,
    filter,
    map,
    get,
    each,
    uniqBy,
    intersectionBy,
    sortBy,
} from 'lodash';
import { formValueSelector } from 'redux-form';
import moment from 'moment';
import {
    getFreeTimeSpansFromSchedule,
    getInvertedTimeSpans,
    getFreeTimeSpans,
} from '../../../../lib/schedule';
import { convertMinutesToDateTime } from '../../../../lib/date';
import { sortByName } from '../../../../lib/servicesSort';

import MasterServiceFields from '../../components/form/MasterServiceFields';

const withOptions = withProps(
    ({
        masters,
        masterId,
        serviceId,
        duration,
        startTime,
        settings,
        meta,
        form,
        field,
        change,
        appointment,
        pristine,
        ...props
    }) => {
        const master = find(masters, master => master.id === masterId);

        const serviceGroup =
            master &&
            find(master.serviceGroups, serviceGroup =>
                some(
                    serviceGroup.services,
                    service => service.id === serviceId,
                ),
            );

        const services = master
            ? reduce(
                  master.serviceGroups,
                  (res, { services }) => [...res, ...services],
                  [],
              )
            : [];

        let timeOptions = null;
        if (masterId && serviceId && master) {
            if (
                !services ||
                !services.length ||
                !some(services, ['id', serviceId])
            ) {
                change(field ? `${field}.service` : 'service', null);
            }

            // Generate list of time spans when master is not working at this date
            const notWorkingTime = getInvertedTimeSpans({
                startAt: settings.startAt,
                endAt: settings.endAt,
                timeSpans: map(master.schedule, workingTime => [
                    workingTime.startAt,
                    workingTime.endAt,
                ]),
            });

            // Find list of free time spans that can fit provided duration
            let freeTimeSpans = getFreeTimeSpans({
                timeSpan: [settings.startAt, settings.endAt],
                duration,
                exclude: notWorkingTime,
                step: settings.step,
            });

            if (
                serviceGroup &&
                serviceGroup.cabinets &&
                serviceGroup.cabinets.length
            ) {
                const cabinetsFreeTimeSpans = reduce(
                    serviceGroup.cabinets,
                    (result, cabinet) => {
                        return uniqBy(
                            [
                                ...result,
                                ...getFreeTimeSpansFromSchedule(
                                    cabinet.freeWorkingTime,
                                    duration,
                                    settings.step,
                                ),
                            ],
                            JSON.stringify,
                        );
                    },
                    [],
                );

                freeTimeSpans = intersectionBy(
                    freeTimeSpans,
                    cabinetsFreeTimeSpans,
                    JSON.stringify,
                );
            }

            if (startTime && !some(freeTimeSpans, [0, startTime])) {
                if (pristine) {
                    freeTimeSpans.push([startTime, startTime + duration]);
                } else {
                    change(field ? `${field}.startTime` : 'startTime', null);
                }
            }

            // Convert free spans to list of options
            timeOptions = map(sortBy(freeTimeSpans, i => i[0]), timeSpan => {
                return {
                    text: convertMinutesToDateTime(timeSpan[0]).format(
                        settings.format,
                    ),
                    value: timeSpan[0],
                };
            });
        }

        return {
            services: sortByName(services),
            timeOptions,
        };
    },
);

const mapStateToProps = (
    { user, intl, salon, ...state },
    { meta, form, field, fields },
) => {
    const formName = get(meta, 'form') || form;
    return {
        settings: salon.get('settings'),
        masterId: formValueSelector(formName)(
            state,
            field ? `${field}.master` : 'master',
        ),
        serviceId: formValueSelector(formName)(
            state,
            field ? `${field}.service` : 'service',
        ),
        duration: formValueSelector(formName)(
            state,
            field ? `${field}.duration` : 'duration',
        ),
        startTime: formValueSelector(formName)(
            state,
            field ? `${field}.startTime` : 'startTime',
        ),
    };
};

export default compose(
    connect(mapStateToProps),
    withOptions,
)(MasterServiceFields);
