/**
 * @flow
 */

import gql from 'graphql-tag';
import moment from 'moment';
import { map, get, find } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import Appointments from '../../components/details/Appointments';

// A query to get appointments for specified client identified by ID
const GET_APPOINTMENTS_QUERY = gql`
    query getClientBoughtItems(
        $first: Int
        $after: String
        $client: ID!
        $salon: ID!
        $lang: String!
        $date: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    client(id: $client) {
                        id
                        futureAppointments(
                            salon: $salon
                            date: $date
                            limit: 10
                        ) {
                            id
                            master {
                                id
                                firstName
                                lastName
                            }
                            cabinet {
                                id
                                name(lang: $lang)
                            }
                            service {
                                id
                                name(lang: $lang)
                                price
                            }
                            invoice {
                                id
                                items {
                                    id
                                    price
                                    entity {
                                        ... on Service {
                                            id
                                            name(lang: $lang)
                                        }
                                    }
                                }
                            }
                            startAt
                        }
                        boughtItems(
                            salon: $salon
                            first: $first
                            after: $after
                        ) {
                            pageInfo {
                                endCursor
                                hasNextPage
                            }
                            edges {
                                id
                                boughtAt
                                price
                                paymentType
                                seller {
                                    ... on Master {
                                        id
                                        firstName
                                        lastName
                                    }
                                    ... on Administrator {
                                        id
                                        firstName
                                        lastName
                                    }
                                    ... on Cabinet {
                                        id
                                        name(lang: $lang)
                                    }
                                }
                                entity {
                                    ... on Service {
                                        id
                                        name(lang: $lang)
                                    }
                                    ... on Product {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_APPOINTMENTS_QUERY, {
    options: ownProps => ({
        variables: {
            first: 20,
            salon: ownProps.salon,
            client: ownProps.client.id,
            lang: ownProps.currentLanguage,
            date: moment()
                .utc()
                .format('YYYY-MM-DDTHH:mm[:00.000Z]'),
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer, fetchMore } }) => ({
        loading,
        fetchMore,
        pageInfo: get(viewer, 'salon.client.boughtItems.pageInfo', {}),
        boughtItems: map(
            get(viewer, 'salon.client.boughtItems.edges'),
            item => ({
                id: item.id,
                boughtAt: moment(item.boughtAt).format('DD.MM.YYYY HH:mm'),
                price: item.price,
                name: item.entity.name,
                firstName: item.seller.firstName || item.seller.name,
                lastName: item.seller.lastName || '',
                paymentType: item.paymentType,
            }),
        ),

        futureAppointments: map(
            get(viewer, 'salon.client.futureAppointments'),
            item => {
                let price = parseFloat(item.service.price);

                // Set service price from invoice if future appointment has it
                if (
                    item.invoice &&
                    item.invoice.items &&
                    item.invoice.items.length
                ) {
                    const invoiceItem = find(
                        get(item.invoice, 'items'),
                        i => i.entity.id === item.service.id,
                    );

                    if (invoiceItem) {
                        price = parseFloat(invoiceItem.price);
                    }
                }
                return {
                    id: item.id,
                    boughtAt: moment(item.startAt).format('DD.MM.YYYY HH:mm'),
                    price: price,
                    name: item.service.name,
                    firstName: item.master
                        ? item.master.firstName
                        : item.cabinet.name,
                    lastName: item.master ? item.master.lastName : '',
                };
            },
        ),
    }),
});

const handlers = withHandlers({
    loadMore: ownProps => () => {
        const {
            loading,
            pageInfo,
            fetchMore,
            salon,
            client,
            currentLanguage,
        } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_APPOINTMENTS_QUERY,
            variables: {
                first: 20,
                after: pageInfo.endCursor,
                salon,
                client: client.id,
                lang: currentLanguage,
                date: moment()
                    .utc()
                    .format('YYYY-MM-DDTHH:mm[:00.000Z]'),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges =
                    previousResult.viewer.salon.client.boughtItems.edges;
                const newEdges =
                    fetchMoreResult.viewer.salon.client.boughtItems.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.salon.client.boughtItems.edges = [
                    ...prevEdges,
                    ...newEdges,
                ];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
    handlers,
)(Appointments);
