/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { withProps } from 'recompose';
import { Icon } from 'semantic-ui-react';

import { PhoneInput as BasePhoneInput } from '../../../../components';

const PhoneInput = styled(BasePhoneInput)`
    &.disabled {
        &,
        input:disabled,
        .disabled {
            opacity: 1 !important;
            background-color: #fffaf3;
        }
    }
`;

type PhoneFieldProps = {
    props: any,
    onUserLookup: Function,
    resetUserLookup: Function,
    userLookupStatus: string,
};

const PhoneField = ({
    userLookupStatus,
    onUserLookup,
    resetUserLookup,
    ...props
}: PhoneFieldProps) => (
    <PhoneInput
        {...props}
        icon={
            userLookupStatus === 'EXISTS' && (
                <Icon
                    name="checkmark"
                    color="green"
                    inverted={true}
                    circular={true}
                />
            )
        }
        loading={userLookupStatus === 'CHECKING'}
    />
);

const withClassname = withProps(({ disabled }) => ({
    className: disabled ? 'disabled' : '',
}));

export default withClassname(PhoneField);
