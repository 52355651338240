/**
 * Verify user authentication status and if user
 * is not authenticated, redirect him to home page.
 *
 * @flow
 */
import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

type AuthenticatedRouteProps = {
    component: Function,
    isAuthenticated: boolean,
    props?: Object,
};

const AuthenticatedRoute = ({
    component: Component,
    isAuthenticated,
    ...rest
}: AuthenticatedRouteProps) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/' }} />
            )
        }
    />
);

const mapStateToprops = ({ user, intl }) => ({
    isAuthenticated: user.has('token'),
    intl: intl.toObject(),
});

export default compose(
    withRouter,
    // User is authenticated if token is defined
    connect(mapStateToprops),
)(AuthenticatedRoute);
