import {
    set,
    isNil,
    isEmpty
} from 'lodash';
import {
    defineMessages
} from 'react-intl';
import {
    isValidNumber
} from 'libphonenumber-js';

const intlMessages = defineMessages({
    userStatusError: {
        id: 'pages.staff.masterForm.userStatusError',
        defaultMessage: 'Select user status',
    },
    serviceGroupsError: {
        id: 'pages.staff.masterForm.serviceGroupsError',
        defaultMessage: 'Select service groups the master provide',
    },
    firstNameError: {
        id: 'pages.staff.masterForm.firstNameError',
        defaultMessage: 'Enter user first name',
    },
    phoneError: {
        id: 'pages.staff.masterForm.phoneError',
        defaultMessage: 'Invalid phone number.'
    },
    noCountryCodeError: {
        id: 'pages.staff.masterForm.noCountryCodeError',
        defaultMessage: 'Phone number should start with country code',
    },
    genderError: {
        id: 'pages.staff.masterForm.genderError',
        defaultMessage: 'Please select master gender',
    },
});

export default (values, ownProps) => {
    const {
        intl
    } = ownProps;
    const errors = {};

    // Validate status
    if (isNil(values.status)) {
        set(errors, 'status', intl.formatMessage(intlMessages.userStatusError));
    }

    // Validate service groups
    if (isNil(values.serviceGroups) || isEmpty(values.serviceGroups)) {
        set(
            errors,
            'serviceGroups',
            intl.formatMessage(intlMessages.serviceGroupsError),
        );
    }

    // Do not validate other fields if user with provided email already exists
    if (ownProps.userLookupStatus === 'EXISTS') {
        return errors;
    }

    // Validate first name
    if (isNil(values.firstName)) {
        set(
            errors,
            'firstName',
            intl.formatMessage(intlMessages.firstNameError),
        );
    }

    //validate master gender
    if (isEmpty(values.gender)) {
        set(errors, 'gender', intl.formatMessage(intlMessages.genderError));
    }

    // phone number can be either empty or valid
    if (values.phone) {
        if (values.phone[0] !== '+') {
            set(errors, 'phone', intl.formatMessage(intlMessages.noCountryCodeError));
        } else if (!isValidNumber(values.phone)) {
            set(errors, 'phone', intl.formatMessage(intlMessages.phoneError));
        }
    }

    return errors;
};
