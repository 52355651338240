/**
 * The component represents menu item with vertical content flip effect for
 * on-hover event.
 *
 * flow
 */

import React from 'react';
import { Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

type MenuItemProps = {
    // Entity for Link component
    to: any,
    hiden: Object,
    visible: Object,
    text: string,
};

const MenuItem = styled(Menu.Item)`
    position: relative !important;
    overflow: hidden !important;
    padding-left: 30px !important;
    transition: color 0.3s ease;

    > .content {
        position: absolute;
        transition: top 0.3s ease, transform 0.3s ease;
        left: 0;
        right: auto;
    }

    > .hidden.content {
        top: -50%;
    }

    > .visible.content {
        transform: translateY(0);
    }

    &:hover {
        background: transparent !important;

        &:not(.active) > .hidden.content {
            top: 50%;
            margin-top: -0.5em;
        }

        &:not(.active) > .visible.content {
            transform: translateY(200%);
        }
    }

    ${props =>
        props.active &&
        `
        color: #fff !important;
        background: transparent !important;

        .hidden.content {
            top: 50%;
            margin-top: -.5em;
        }

        .visible.content {
            transform: translateY(200%);
        }
    `};
`;

const AnimatedMenuItem = ({
    to,
    visible,
    hidden,
    text,
    link,
    ...props
}: MenuItemProps) => (
    <Route
        path={to}
        children={({ match }) => (
            <MenuItem
                {...props}
                as={link ? Link : 'div'}
                active={!!match}
                to={to}
                link
            >
                <div className="hidden content">{hidden}</div>
                <div className="visible content">{visible}</div>
                {text}
            </MenuItem>
        )}
    />
);

export default AnimatedMenuItem;
