/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Menu as BaseMenu } from '../../../../components';

export type NavigationItems = 'editSeminar';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #f9fafb !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
`;

const Navigation = ({ selectedItem, onItemClick }: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="editSeminar"
            active={selectedItem === 'editSeminar'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.seminars.details.Navigation.editSeminar"
                defaultMessage="Edit Seminar"
            />
        </BaseMenu.Item>
        <BaseMenu.Item
            name="visitorsList"
            active={selectedItem === 'visitorsList'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.seminars.details.Navigation.visitorsList"
                defaultMessage="Visitors"
            />
        </BaseMenu.Item>
    </Menu>
);

export default Navigation;
