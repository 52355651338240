/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import AddMasterDialog from '../containers/AddMasterDialog';

const Button = styled(BaseButton)`
    width: 160px;
`;

const AddMasterButton = ({ open, onClose, onOpen }) => (
    <AddMasterDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} labelPosition="left" primary icon>
                <Icon name="add" />
                <FormattedMessage
                    id="pages.staff.MastersList.newUser"
                    defaultMessage="Create new user"
                />
            </Button>
        }
    />
);

export default withModal(AddMasterButton);
