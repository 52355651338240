import { set, isEmpty } from 'lodash';
import {defineMessages} from 'react-intl';

const intlMessages = defineMessages({
    salonNameError: {
        id: 'navbar.menu.newSalon.salonNameError',
        defaultMessage: 'Enter salon name',
    },
    salonAddressError: {
        id: 'navbar.menu.newSalon.salonAddressError',
        defaultMessage: 'Enter salon address',
    },
});

export default (values, { intl }) => {
    const errors = {};

    if (isEmpty(values.name)) {
        set(errors, 'name', intl.formatMessage(intlMessages.salonNameError));
    }

    if (isEmpty(values.address)) {
        set(errors, 'address', intl.formatMessage(intlMessages.salonAddressError));
    }

    return errors;
};