/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Form, Button } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import { defineMessages, injectIntl } from 'react-intl';

import styles from '../../../../styles';

import {
    MasterSelect,
    TimeSelect,
    ServiceSelect,
    CabinetSelect,
} from '../../containers/form';
import ServiceDurationSelect from '../../../settings/containers/service/service/ServiceDurationSelect';

// flow types
import type { Intl, Master } from '../../../../type';

const intlMessages = {
    cabinet: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.cabinetPlaceholder',
            defaultMessage: 'Cabinet',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.selectCabinetLabel',
            defaultMessage: 'Cabinet',
        },
    }),
    master: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.masterPlaceholder',
            defaultMessage: 'Master',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.selectMasterLabel',
            defaultMessage: 'Master',
        },
    }),
    service: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.servicePlaceholder',
            defaultMessage: 'Service',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.serviceLabel',
            defaultMessage: 'Service',
        },
    }),
    startTime: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.startTimePlaceholder',
            defaultMessage: 'Time',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.timeLabel',
            defaultMessage: 'Time',
        },
    }),
    duration: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.durationPlaceholder',
            defaultMessage: 'Duration',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.durationLabel',
            defaultMessage: 'Duration',
        },
    }),
};

type ServiceFieldsProps = {
    field: string,
    onRemove: Function,
    readOnly: boolean,
    intl: Intl,
    change: Function,
    masters: Array<Master>,
};

const fields = {
    cabinet: FormField(CabinetSelect),
    master: FormField(MasterSelect),
    service: FormField(ServiceSelect),
    startTime: FormField(TimeSelect),
    duration: FormField(ServiceDurationSelect),
};

const RemoveButton = styled(Button)`
    margin-right: 0px !important;
`;

const FormGroup = styled(Form.Group)`
    align-items: flex-start;
`;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const CabinetServiceFields = ({
    field,
    onRemove,
    readOnly,
    intl,
    change,
    cabinets,
    services,
    masters,
    timeOptions,
    cabinetId,
    serviceId,
    withLabel,
    disableRemove,
    ...props
}: ServiceFieldsProps) => (
    <FormGroup>
        <Field
            name={field ? `${field}.cabinet` : 'cabinet'}
            component={fields.cabinet}
            width={4}
            label={
                withLabel && (
                    <Label className="required">
                        {intl.formatMessage(intlMessages.cabinet.label)}
                    </Label>
                )
            }
            placeholder={intl.formatMessage(intlMessages.cabinet.placeholder)}
            fluid
            search
            simpleValue
            cabinets={cabinets}
        />
        <Field
            name={field ? `${field}.service` : 'service'}
            component={fields.service}
            width={4}
            label={
                withLabel && (
                    <Label className="required">
                        {intl.formatMessage(intlMessages.service.label)}
                    </Label>
                )
            }
            placeholder={intl.formatMessage(intlMessages.service.placeholder)}
            field={field}
            change={change}
            fluid
            search
            simpleValue
            services={services}
            disabled={!cabinetId}
        />
        <Field
            name={field ? `${field}.master` : 'master'}
            component={fields.master}
            width={3}
            label={
                withLabel && (
                    <Label>
                        {intl.formatMessage(intlMessages.master.label)}
                    </Label>
                )
            }
            placeholder={intl.formatMessage(intlMessages.master.placeholder)}
            fluid
            search
            simpleValue
            masters={masters}
            disabled={!serviceId}
            clearable
        />
        <Field
            name={field ? `${field}.duration` : 'duration'}
            component={fields.duration}
            width={3}
            label={
                withLabel && (
                    <Label className="required">
                        {intl.formatMessage(intlMessages.duration.label)}
                    </Label>
                )
            }
            placeholder={intl.formatMessage(intlMessages.duration.placeholder)}
            fluid
            simpleValue
            disabled={!serviceId}
        />
        <Field
            name={field ? `${field}.startTime` : 'startTime'}
            component={fields.startTime}
            width={2}
            label={
                withLabel && (
                    <Label className="required">
                        {intl.formatMessage(intlMessages.startTime.label)}
                    </Label>
                )
            }
            placeholder={intl.formatMessage(intlMessages.startTime.placeholder)}
            fluid
            simpleValue
            options={timeOptions}
            disabled={!serviceId}
        />
        {!!field && (
            <Form.Field>
                <RemoveButton
                    onClick={onRemove}
                    color="red"
                    icon="trash"
                    disabled={disableRemove}
                    basic
                />
            </Form.Field>
        )}
    </FormGroup>
);

export default injectIntl(CabinetServiceFields);
