import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'lodash';
import DeleteMembershipDialog from '../components/DeleteMembershipDialog';
import { GET_MEMBERSHIPS_QUERY } from './queries';

// Query that deletes specified membership
const DELETE_MEMBERSHIP_QUERY = gql`
    mutation deleteMembership($input: DeleteMembershipInput!) {
        deleteMembership(input: $input) {
            membershipId
        }
    }
`;

const withData = graphql(DELETE_MEMBERSHIP_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: ownProps.membership.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete membership before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteMembership: {
                        __typename: 'DeleteMembershipPayload',
                        membershipId: ownProps.membership.id,
                    },
                },
                // Remove deleted membership from query cache
                update: (store, { data: { deleteMembership } }) => {
                    const data = store.readQuery({
                        query: GET_MEMBERSHIPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                    });

                    remove(
                        data.viewer.salon.memberships,
                        membership =>
                            membership.id === deleteMembership.membershipId,
                    );

                    store.writeQuery({
                        query: GET_MEMBERSHIPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            return mutation.then(() => {
                // Close modal dialog after membership was deleted
                ownProps.onClose();
            });
        },
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
        currentLanguage: intl.get('locale'),
    })),
    withData,
)(DeleteMembershipDialog);
