/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import {
    FormattedMessage,
    defineMessages,
    injectIntl,
    IntlShape,
} from 'react-intl';
import { mapProps } from 'recompose';

import { Input, TextArea, Form } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import BaseCalendar from '../../../../containers/Calendar';
import SeminarType from './SeminarType';
import SeminarTime from './SeminarTime';
import SeminarDuration from './SeminarDuration';
import BannerPreviewLink from './BannerPreviewLink';
import FileField from './FileField';

import styles from '../../../../styles';

import type { ReduxForm } from '../../../../type';

type SeminarFormProps = {
    intl: IntlShape,
} & ReduxForm;

const intlMessages = defineMessages({
    datePlaceholder: {
        id: 'pages.seminars.form.SeminarForm.date.placeholder',
        defaultMessage: 'DD.MM.YYYY',
    },
    timePlaceholder: {
        id: 'pages.seminars.form.SeminarForm.time.placeholder',
        defaultMessage: 'HH:MM',
    },
    durationPlaceholder: {
        id: 'pages.seminars.form.SeminarForm.duration.placeholder',
        defaultMessage: 'HH:MM',
    },
});

const limitInputProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const limitCalendarProps = mapProps(({ meta, ...props }) => ({
    format: 'DD.MM.YYYY',
    field: meta.field,
    autoComplete: 'off',
    ...props,
}));

const Calendar = styled(BaseCalendar)`
    & .DayPickerInput-OverlayWrapper {
        top: 46px;
    }
`;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const fields = {
    input: FormField(limitInputProps(Input)),
    text: FormField(limitInputProps(TextArea)),
    type: FormField(SeminarType),
    time: FormField(SeminarTime),
    duration: FormField(SeminarDuration),
    calendar: FormField(limitCalendarProps(Calendar)),
    file: FileField,
    number: FormField(({ meta, ...props }) => (
        <Input {...props} type="number" />
    )),
};

const SeminarForm = ({
    handleSubmit,
    intl: { formatMessage },
    form,
    seminar,
}: SeminarFormProps) => (
    <Form onSubmit={handleSubmit}>
        <Field
            name="name"
            component={fields.input}
            label={
                <Label>
                    <FormattedMessage
                        id="pages.seminars.form.SeminarForm.name.label"
                        defaultMessage="Name"
                    />
                </Label>
            }
            simpleValue
            required
        />
        <Field
            name="description"
            component={fields.text}
            label={
                <Label>
                    <FormattedMessage
                        id="pages.seminars.form.SeminarForm.description.label"
                        defaultMessage="Description"
                    />
                </Label>
            }
            simpleValue
        />
        <Form.Group widths="equal">
            <Field
                name="startAt"
                component={fields.calendar}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.startAt.label"
                            defaultMessage="Date"
                        />
                    </Label>
                }
                placeholder={formatMessage(intlMessages.datePlaceholder)}
                simpleValue
                required
            />
            <Field
                name="eventTime"
                component={fields.time}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.eventTime.label"
                            defaultMessage="Time"
                        />
                    </Label>
                }
                placeholder={formatMessage(intlMessages.timePlaceholder)}
                simpleValue
                required
            />
            <Field
                name="duration"
                component={fields.duration}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.duration.label"
                            defaultMessage="Duration"
                        />
                    </Label>
                }
                placeholder={formatMessage(intlMessages.durationPlaceholder)}
                simpleValue
                required
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="type"
                component={fields.type}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.type.label"
                            defaultMessage="Type"
                        />
                    </Label>
                }
                simpleValue
                required
            />
            <Field
                name="placeCount"
                component={fields.number}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.placeCount.label"
                            defaultMessage="Place Count"
                        />
                    </Label>
                }
                placeholder="0"
                simpleValue
                required
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="banner"
                component={fields.file}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.seminars.form.SeminarForm.banner.label"
                            defaultMessage="Banner"
                        />
                    </Label>
                }
                simpleValue
                required={form === 'addSeminar'}
            />
            {seminar && seminar.banner && seminar.banner.url && (
                <BannerPreviewLink url={seminar.banner.url} />
            )}
        </Form.Group>
    </Form>
);

export default injectIntl(SeminarForm);
