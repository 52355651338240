import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';
import { mapProps } from 'recompose';
import {
    ClientSelect,
    ServiceSelect,
} from '../../../appointments/containers/form';
import BaseCalendar from '../../../appointments/containers/form/Calendar';
import { FormField, FormLabel as BaseFormLabel } from '../../../../components';
import styles from '../../../../styles';

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;

const Label = styled(BaseFormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Calendar = styled(BaseCalendar)`
    & .DayPickerInput-OverlayWrapper {
        top: 46px;
    }
`;

const limitProps = mapProps(({ meta, ...props }) => ({ ...props }));

const limitCalendarProps = mapProps(({ meta, onBlur, ...props }) => ({
    format: 'DD.MM.YYYY',
    ...props,
}));

const fields = {
    input: FormField(limitProps(Input)),
    client: FormField(ClientSelect),
    service: FormField(ServiceSelect),
    calendar: FormField(limitCalendarProps(Calendar)),
};

const intlMessages = {
    client: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.clientPlaceholder',
            defaultMessage: 'Select a client',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.clientLabel',
            defaultMessage: 'Client',
        },
    }),
    date: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.datePlaceholder',
            defaultMessage: 'Select an appointment date',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.dateLabel',
            defaultMessage: 'Date',
        },
    }),
    service: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.servicePlaceholder',
            defaultMessage: 'Service',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.serviceLabel',
            defaultMessage: 'Service',
        },
    }),
};

const MembershipForm = ({
    intl,
    onCloseDialog,
    handleSubmit,
    error,
    change,
    field,
    services,
    disabled,
    createMode,
}) => {
    return (
        <Form onSubmit={handleSubmit} error={!!error}>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form.Group>
                <Field
                    name="client"
                    component={fields.client}
                    label={
                        <Label>
                            {intl.formatMessage(intlMessages.client.label)}
                        </Label>
                    }
                    change={change}
                    search
                    onCloseDialog={onCloseDialog}
                    width={12}
                    required
                    simpleValue
                />
                <Field
                    name="createdAt"
                    component={fields.calendar}
                    label={
                        <Label>
                            {intl.formatMessage(intlMessages.date.label)}
                        </Label>
                    }
                    dayPickerProps={{
                        numberOfMonths: 2,
                        fixedWeeks: true,
                    }}
                    simpleValue
                    width={4}
                />
            </Form.Group>
            <Form.Group>
                <Field
                    name="serialNumber"
                    component={fields.input}
                    label={
                        <Label>
                            <FormattedMessage
                                id="pages.memberships.form.serialNumber"
                                defaultMessage="Serial number"
                            />
                        </Label>
                    }
                    simpleValue
                    required
                    width={8}
                />
                <Field
                    name={field ? `${field}.service` : 'service'}
                    component={fields.service}
                    width={8}
                    label={
                        <Label className="required">
                            {intl.formatMessage(intlMessages.service.label)}
                        </Label>
                    }
                    placeholder={intl.formatMessage(
                        intlMessages.service.placeholder,
                    )}
                    field={field}
                    change={change}
                    fluid
                    search
                    simpleValue
                    required
                    services={services}
                />
            </Form.Group>
            <Form.Group>
                {!createMode && (
                    <Field
                        name="totalUses"
                        component={fields.input}
                        label={
                            <Label>
                                <FormattedMessage
                                    id="pages.memberships.form.numberOfTimesUsed"
                                    defaultMessage="Number of times used"
                                />
                            </Label>
                        }
                        simpleValue
                        width={4}
                    />
                )}
                <Field
                    name="quantity"
                    component={fields.input}
                    label={
                        <Label>
                            <FormattedMessage
                                id="pages.memberships.form.numberOfUses"
                                defaultMessage="Number of uses"
                            />
                        </Label>
                    }
                    simpleValue
                    required
                    width={createMode ? 8 : 4}
                />
                <Field
                    name="price"
                    component={fields.input}
                    label={
                        <Label>
                            <FormattedMessage
                                id="pages.inventory.form.ProductForm.price.label"
                                defaultMessage="Price"
                            />
                        </Label>
                    }
                    simpleValue
                    required
                    width={8}
                />
            </Form.Group>
        </Form>
    );
};

export default injectIntl(MembershipForm);
