/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { mapProps } from 'recompose';

import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import Gender from './Gender';
import EmailField from '../../containers/form/EmailField';
import PhoneField from '../../containers/form/PhoneField';
import Calendar from '../../../appointments/containers/form/Calendar';
import Checkbox from '../../../../components/Checkbox';

import styles from '../../../../styles';

import type { ReduxForm, Client } from '../../../../type';

type ClientFormProps = {
    onUserLookup: Function,
    resetUserLookup: Function,
    userLookupStatus: string,
    userLookupUser: Client,
    readOnly: boolean,
} & ReduxForm;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;
const limitedProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const limitCalendarProps = mapProps(({ meta, ...props }) => ({
    format: 'DD.MM.YYYY',
    field: meta.field,
    ...props,
}));

const limitCheckboxProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const fields = {
    input: FormField(limitedProps(Input)),
    gender: FormField(Gender),
    email: FormField(EmailField),
    phone: FormField(PhoneField),
    calendar: FormField(limitCalendarProps(Calendar)),
    blocked: FormField(limitCheckboxProps(Checkbox)),
};

const ClientForm = ({
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    userLookupUser,
    onUserLookup,
    resetUserLookup,
    readOnly,
    error,
}: ClientFormProps) => (
    <Form onSubmit={handleSubmit} error={!!error}>
        {userLookupUser && (
            <Message info>
                <Icon name="info" />
                <strong>
                    {userLookupUser.firstName} {userLookupUser.lastName}
                </strong>{' '}
                <FormattedMessage
                    id="pages.clients.form.ClientForm.clientExistsMessage"
                    defaultMessage="already exists in our system, you can add it to your salon"
                />
            </Message>
        )}
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Form.Group widths="equal">
            <Field
                name="phone"
                component={fields.phone}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.phone"
                        defaultMessage="Phone"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
            />
            <Field
                name="email"
                component={fields.email}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.email"
                        defaultMessage="E-mail"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="firstName"
                component={fields.input}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.firstName"
                        defaultMessage="First Name"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                readOnly={readOnly}
                simpleValue
                required
            />
            <Field
                name="lastName"
                component={fields.input}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.lastName"
                        defaultMessage="Last Name"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                readOnly={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="notes"
                component={fields.input}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.notes"
                        defaultMessage="Notes"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                simpleValue
            />
            <Field
                name="birthday"
                component={fields.calendar}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.birthday"
                        defaultMessage="Birthday"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                dayPickerProps={{
                    isDayPickerInput: true,
                }}
                width={4}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="gender"
                component={fields.gender}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.gender"
                        defaultMessage="Gender"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                disabled={readOnly}
                simpleValue
                required
            />
            <Field
                name="blocked"
                component={fields.blocked}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.blocked"
                        defaultMessage="Block client"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                simpleValue
            />
        </Form.Group>
    </Form>
);

export default ClientForm;
