/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty, property } from 'lodash';
import { List, Loader } from 'semantic-ui-react';
import { Table, MasterTypeIcon } from '../../../components';
import { FormattedMessage } from 'react-intl';

import MasterDetailsButton from './MasterDetailsButton';
import EditMasterButton from './EditMasterButton';
import DeleteMasterButton from './DeleteMasterButton';
import ServiceGroupName from './ServiceGroupName';

import type { Master } from '../../../type';

type MastersListProps = {
    masters: Array<Master>,
};

type MasterRowProps = {
    master: Master,
};

const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

/**
 * Show this message if list of masters is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.staff.MastersList.noMasters"
                defaultMessage="You don't have masters yet"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents component with single row of master details
 */
const MasterRow = ({ master }: MasterRowProps) => (
    <Table.Row>
        <NameCell>
            <MasterDetailsButton master={master}>
                <MasterTypeIcon
                    name={`${master.firstName} ${master.lastName}`}
                    type={property(['serviceGroups', '0', 'type'])(master)}
                />
                <span>
                    {master.firstName} {master.lastName}
                </span>
            </MasterDetailsButton>
        </NameCell>
        <Table.Cell>
            {master.serviceGroups.map(serviceGroup => (
                <ServiceGroupName key={serviceGroup.id}>
                    {serviceGroup.name}
                </ServiceGroupName>
            ))}
        </Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditMasterButton master={master} />
                </List.Item>
                <List.Item>
                    <DeleteMasterButton master={master} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents table with list of masters
 */
const MastersList = ({ masters }: MastersListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.staff.MastersList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.staff.MastersList.services"
                        defaultMessage="Services"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.staff.MastersList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(masters) ? (
                <EmptyMessage />
            ) : (
                masters.map(
                    master =>
                        !!master && (
                            <MasterRow key={master.id} master={master} />
                        ),
                )
            )}
        </Table.Body>
    </Table>
);

// Show spinner while masters list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(MastersList);
