/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Form, Input, Message, Icon } from 'semantic-ui-react';
import { FormField, FormLabel, Button } from '../../../components';

import { isNil } from 'lodash';

import type { ReduxForm } from '../../../type';

type ChangePasswordFormProps = ReduxForm;

const fields = {
    input: FormField(Input),
};

const SubmitButton = styled(Button)`
    text-transform: uppercase !important;
`;

const ChangePasswordForm = ({
    handleSubmit,
    pristine,
    submitting,
    error,
}: ChangePasswordFormProps) => (
    <Form onSubmit={handleSubmit} error={!isNil(error)}>
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Field
            component={fields.input}
            name="password"
            label={<FormLabel>New password</FormLabel>}
            type="password"
            size="big"
            simpleValue
        />
        <Field
            component={fields.input}
            name="repeatPassword"
            label={<FormLabel>Repeat password</FormLabel>}
            type="password"
            size="big"
            simpleValue
        />
        <SubmitButton
            onClick={handleSubmit}
            disabled={submitting || pristine}
            loading={submitting}
            primary
            fluid
        >
            Change Password
        </SubmitButton>
    </Form>
);

export default ChangePasswordForm;
