/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import { omit, get, findIndex, map } from 'lodash';

import CabinetDetails from '../../components/details/CabinetDetails';
import validate from '../form/CabinetFormValidate';
import { injectIntl, defineMessages } from 'react-intl';

import { GET_CABINETS_QUERY } from '../CabinetList';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const UPDATE_CABINET_QUERY = gql`
    mutation updateCabinet($input: UpdateCabinetInput!, $lang: String!) {
        updateCabinet(input: $input) {
            cabinet {
                id
                name(lang: $lang)
                nameVariants {
                    en
                    ru
                    lv
                }
                serviceGroups {
                    id
                    name(lang: $lang)
                    type
                }
            }
        }
    }
`;

const withData = graphql(UPDATE_CABINET_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update cabinet
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: ownProps.cabinet.id,
                        ...formData,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency
                // and update cabinet directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateCabinet: {
                        __typename: 'UpdateCabinetPayload',
                        cabinet: {
                            __typename: 'Cabinet',
                            id: ownProps.cabinet.id,
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                            serviceGroups: map(
                                formData.serviceGroups,
                                serviceGroup => ({
                                    __typename: 'ServiceGroups',
                                    id: serviceGroup,
                                    name: '',
                                    type: '',
                                }),
                            ),
                            ...omit(formData, ['serviceGroups']),
                        },
                    },
                },
                update: (store, response) => {
                    const cabinet = get(response, 'data.updateCabinet.cabinet');

                    if (cabinet) {
                        const data = store.readQuery({
                            query: GET_CABINETS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                search: ownProps.searchQuery || undefined,
                                lang: ownProps.currentLanguage,
                            },
                        });

                        const idx = findIndex(
                            data.viewer.salon.cabinets,
                            cabinet => cabinet.id === ownProps.cabinet.id,
                        );

                        // remove previous cabinet and add a new cabinet to the store
                        data.viewer.salon.cabinets.splice(idx, 1, cabinet);

                        store.writeQuery({
                            query: GET_CABINETS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                search: ownProps.searchQuery || undefined,
                                lang: ownProps.currentLanguage,
                            },
                            data,
                        });
                    }
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: 'Something went wrong',
                    });
                });
        },
    }),
});

// Provide initial values to the form to fulfill fields with
// data from provided cabinet
const withInitialValues = withProps(ownProps => {
    const cabinetServiceGroups = map(
        ownProps.cabinet.serviceGroups,
        serviceGroup => serviceGroup.id,
    );
    if (ownProps.open) {
        return {
            initialValues: {
                nameVariants: omit(ownProps.cabinet.nameVariants, [
                    '__typename',
                ]),
                serviceGroups: cabinetServiceGroups,
            },
        };
    }
});

const withForm = reduxForm({
    form: 'editCabinet',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

export default compose(
    connect(({ user, intl, ...state }) => ({
        salon: user.get('salon'),
        formValues: getFormValues('editCabinet')(state),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
        currentLanguage: intl.get('locale'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(CabinetDetails);
