/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { map } from 'lodash';

import { FormField, FormLabel, ColorPicker } from '../../../../../components';
import ServiceDurationSelect from '../../../containers/service/service/ServiceDurationSelect';

import type { ReduxForm } from '../../../../../type';

type ServiceFormProps = {
    intl: Intl,
    languages: Array<string>,
    defaultLanguage: string,
} & ReduxForm;

const intlMessages = defineMessages({
    serviceNamePlaceholder: {
        id: 'pages.settings.services.serviceForm.namePlaceholder',
        defaultMessage: 'Service name',
    },
    serviceDurationPlaceholder: {
        id: 'pages.settings.services.serviceForm.durationPlaceholder',
        defaultMessage: 'Service duration',
    },
    servicePricePlaceholder: {
        id: 'pages.settings.services.serviceForm.pricePlaceholder',
        defaultMessage: 'Price of the service',
    },
    serviceColorPlaceholder: {
        id: 'pages.settings.services.serviceForm.colorPlaceholder',
        defaultMessage: 'Visual identity color',
    },
});

const ServiceNameFieldGroup = styled(Form.Group)`
    & .field {
        display: flex;
        align-items: center;
    }
`;

const ServiceNameFieldLabel = styled(FormLabel)`
    flex: 0 0 auto;
`;

const ServiceNameField = styled(Input)`
    flex: 1;
    margin-left: 5px;
`;

const fields = {
    serviceName: FormField(ServiceNameField),
    input: FormField(Input),
    duration: FormField(ServiceDurationSelect),
    color: FormField(ColorPicker),
};

const ServiceForm = ({
    handleSubmit,
    intl,
    languages,
    defaultLanguage,
}: ServiceFormProps) => (
    <Form onSubmit={handleSubmit}>
        <FormLabel>
            <FormattedMessage
                id="pages.settings.services.serviceForm.name"
                defaultMessage="Name"
            />
        </FormLabel>
        <ServiceNameFieldGroup widths="equal">
            {map(languages, lang => (
                <Field
                    key={lang}
                    name={`nameVariants[${lang}]`}
                    component={fields.serviceName}
                    label={
                        <ServiceNameFieldLabel>
                            {lang.toUpperCase()}
                        </ServiceNameFieldLabel>
                    }
                    placeholder={intl.formatMessage(
                        intlMessages.serviceNamePlaceholder,
                    )}
                    required={lang === defaultLanguage}
                    simpleValue
                />
            ))}
        </ServiceNameFieldGroup>
        <Field
            name="duration"
            component={fields.duration}
            label={
                <FormLabel>
                    <FormattedMessage
                        id="pages.settings.services.serviceForm.duration"
                        defaultMessage="Duration"
                    />
                </FormLabel>
            }
            placeholder={intl.formatMessage(
                intlMessages.serviceDurationPlaceholder,
            )}
            simpleValue
        />
        <Field
            name="price"
            component={fields.input}
            label={
                <FormLabel>
                    <FormattedMessage
                        id="pages.settings.services.serviceForm.price"
                        defaultMessage="Price"
                    />
                </FormLabel>
            }
            placeholder={intl.formatMessage(
                intlMessages.servicePricePlaceholder,
            )}
            simpleValue
        />
        <Field
            name="color"
            component={fields.color}
            label={
                <FormLabel>
                    <FormattedMessage
                        id="pages.settings.services.serviceForm.color"
                        defaultMessage="Color"
                    />
                </FormLabel>
            }
            placeholder={intl.formatMessage(
                intlMessages.serviceColorPlaceholder,
            )}
            simpleValue
        />
    </Form>
);

export default injectIntl(ServiceForm);
