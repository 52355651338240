/**
 * @flow
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled, { injectGlobal } from 'styled-components';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import { Container as BaseContainer, Segment } from 'semantic-ui-react';
import { AuthenticatedRoute } from '../../../components';
import Navigation from './Navigation';
import { SeminarPage, WarehousePage } from '../../../pages';

import styles from '../../../styles';

// eslint-disable-next-line
injectGlobal`
    body {
        background: #fafafa;
    }
`;

const Content = styled(Segment)`
    &.segment {
        padding: 0px !important;
        margin-left: 2em !important;
        margin-right: 2em !important;
        flex: 1;
    }
`;

const Container = styled(BaseContainer)`
    ${styles.proximaNovaFontFamily} !important;
    &.container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const SupplierLayout = () => (
    <Container fluid>
        <Navigation />

        <Content vertical basic>
            <Route
                render={() => <Redirect to="/supplier/seminars" />}
                path="/supplier"
                exact
            />
            <AuthenticatedRoute
                path="/supplier/seminars"
                component={SeminarPage}
            />
            <AuthenticatedRoute
                path="/supplier/warehouse"
                component={WarehousePage}
            />
        </Content>
    </Container>
);

export default DragDropContext(HTML5Backend)(SupplierLayout);
