/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';

import withModal from '../../../lib/withModal';
import SupplierDetails from './details/SupplierDetails';

import type { ModalDialogTrigger, Supplier } from '../../../type';

type SupplierDetailsButtonProps = {
    supplier: Supplier,
    children: any,
} & ModalDialogTrigger;

const SupplierDetailsButtonStyled = styled.span`
    cursor: pointer;
    display: flex;
    color: #000000;
    transition: color 0.3s;
    align-items: center;
    height: 44px;
    padding: 0px 11px 0px 11px;

    &:hover {
        font-weight: 600;
        color: #35a3d5;
    }
`;

const SupplierDetailsButton = ({
    supplier,
    children,
    open,
    onOpen,
    onClose,
}: SupplierDetailsButtonProps) => (
    <SupplierDetails
        trigger={
            <SupplierDetailsButtonStyled onClick={onOpen}>
                {children}
            </SupplierDetailsButtonStyled>
        }
        supplier={supplier}
        onClose={onClose}
        open={open}
    />
);

export default withModal(SupplierDetailsButton);
