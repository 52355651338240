/**
 * @flow
 */

import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const GroupedMessage = () => (
    <Message info>
        <Icon name="info" />
        <FormattedMessage
            id="pages.appointments.details.groupedMessage"
            defaultMessage="This appointment is grouped with the other and cannot be moved to another date"
        />
    </Message>
);

export default GroupedMessage;
