import React from 'react';
import ReactDOM from 'react-dom';
import { injectGlobal } from 'styled-components';
import Root from './Root';
import { Tawk } from './providers';

import styles from './styles';

Tawk.initializeTawk();
/* eslint no-unused-expressions: off */
injectGlobal`
    ${styles.fontFace}

    #root {
        height: 100%;
    }
`;

ReactDOM.render(<Root />, document.getElementById('root'));
