/**
 *  @flow
 */

import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, getFormValues, reset } from 'redux-form';
import {
    compose,
    withProps,
    withHandlers,
    withState,
    lifecycle,
} from 'recompose';
import { pick, isEqual, keys } from 'lodash';
import uuid from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';

import CreateSupplierDialog from '../components/CreateSupplierDialog';
import withUserLookup from '../../../lib/withUserLookup';
import validate from './form/supplierFormValidate';
import { GET_SUPPLIERS_QUERY, CREATE_SUPPLIER_QUERY } from './queries';

const intlMessages = defineMessages({
    phoneExistingsError: {
        id: 'pages.suppliers.supplierForm.phoneExistingsError',
        defaultMessage: 'Supplier with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.suppliers.supplierForm.emailExistingsError',
        defaultMessage: 'Supplier with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.suppliers.supplierForm.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'createSupplier',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withData = graphql(CREATE_SUPPLIER_QUERY, {
    props: ({
        mutate,
        ownProps: { searchQuery, intl, onAdd, onModalClose },
    }) => ({
        onSubmit: formData => {
            const input = Object.assign(
                { phone: (formData.phone || '').replace(/[^+\d]/g, '') },
                pick(formData, ['firstName', 'lastName', 'email', 'password']),
            );
            const mutation = mutate({
                variables: { input },
                // Implement optimistinc response to compensate network latency
                // and add a new supplier directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    createSupplier: {
                        __typename: 'CreateSupplierPayload',
                        supplier: {
                            __typename: 'Supplier',
                            ...input,
                            id: uuid.v4(),
                        },
                    },
                },
                // Update local store with a new supplier
                update: (store, { data: { createSupplier } }) => {
                    const variables = {
                        search: searchQuery || undefined,
                        first: 20,
                        resolveRole: 'SYSADMIN',
                    };

                    const data = store.readQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                    });

                    data.viewer.suppliers.edges.unshift({
                        node: createSupplier.supplier,
                        __typename: 'SupplierEdge',
                        cursor: btoa(createSupplier.supplier.id),
                    });

                    store.writeQuery({
                        query: GET_SUPPLIERS_QUERY,
                        variables,
                        data,
                    });
                },
            });

            return mutation
                .then(({ data: { createSupplier } }) => {
                    if (onAdd) {
                        onAdd(createSupplier.supplier);
                    }

                    onModalClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (
                                graphQLError.data.error ===
                                'EMAIL_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    email: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const props = withProps(({ userLookupStatus }) => ({
    // Make form as read-only if user exists
    readOnly: userLookupStatus === 'EXISTS',
}));

const withModalHandlers = withHandlers({
    onModalClose: ({
        onClose,
        resetUserLookup,
        setInitialValues,
        reset,
    }) => () => {
        resetUserLookup();
        setInitialValues({});
        reset('createSupplier');
        onClose();
    },
    checkFormPristine: ({ formValues }) => () =>
        isEqual(
            formValues,
            pick(
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '+371',
                },
                keys(formValues),
            ),
        ),
    handleClear: ({ resetUserLookup, setInitialValues }) => () => {
        resetUserLookup();
        setInitialValues({});
    },
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

const componentWillReceiveProps = ({
    initialValues,
    setInitialValues,
    open,
    userLookupUser,
}) => {
    if (open) {
        if (userLookupUser) {
            const result = pick(userLookupUser, [
                'firstName',
                'lastName',
                'email',
                'phone',
            ]);
            if (!isEqual(initialValues, result)) {
                setInitialValues(result);
            }
        }
    }
};

export default compose(
    connect(
        ({ user, ...state }) => ({
            formValues: getFormValues('createSupplier')(state),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withUserLookup,
    withModalHandlers,
    withData,
    props,
    withForm,
    lifecycle({
        componentWillReceiveProps,
    }),
)(CreateSupplierDialog);
