import {
    set,
    isNil,
    isEmpty
} from 'lodash';
import {
    defineMessages
} from 'react-intl';

const intlMessages = defineMessages({
    salonNameError: {
        id: 'pages.signup.form.salonNameError',
        defaultMessage: 'Enter salon name (or company name)',
    },
    salonAddressError: {
        id: 'pages.signup.form.salonAddressError',
        defaultMessage: 'Enter your salon address (or company address)',
    },
    firstNameError: {
        id: 'pages.signup.form.firstNameError',
        defaultMessage: 'Enter your first name (e.g. John Smith)',
    },
    emailError: {
        id: 'pages.signup.form.emailError',
        defaultMessage: 'Enter your email address',
    },
    passwordError: {
        id: 'pages.signup.form.passwordError',
        defaultMessage: 'Enter password for authetnication',
    },
    promoCodeError: {
        id: 'pages.signup.form.promoCodeError',
        defaultMessage: 'Invalid promo code',
    },
});

export default (values, {
    intl
}) => {
    const errors = {};

    // Validate salon name
    if (isNil(values.salonName) || isEmpty(values.salonName)) {
        set(
            errors,
            'salonName',
            intl.formatMessage(intlMessages.salonNameError),
        );
    }

    if (isNil(values.salonAddress) || isEmpty(values.salonAddress)) {
        set(
            errors,
            'salonAddress',
            intl.formatMessage(intlMessages.salonAddressError),
        );
    }

    // Validate administatro details
    if (isNil(values.firstName) || isEmpty(values.firstName)) {
        set(errors, 'firstName', intl.formatMessage(intlMessages.firstNameError));
    }

    if (isNil(values.email) || isEmpty(values.email)) {
        set(errors, 'email', intl.formatMessage(intlMessages.emailError));
    }

    if (isNil(values.password) || isEmpty(values.password)) {
        set(errors, 'password', intl.formatMessage(intlMessages.passwordError));
    }

    if (values.promoCode && values.promoCode.length >= 20) {
        set(
            errors,
            'promoCode',
            intl.formatMessage(intlMessages.promoCodeError),
        );
    }

    return errors;
};
