/**
 * Re-export configuration based on current environment
 *
 * Environment can be specifiedn over NODE_ENV variable
 */
import { merge } from 'lodash';

import common from './config.default';
import development from './config.dev';
import production from './config.prod';
import staging from './config.stage';

const env = process.env.REACT_APP_ENV || 'development';

export default merge(common, { development, production, staging }[env]);
