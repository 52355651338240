import { isNil, isEmpty, set } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    userNameError: {
        id: 'pages.login.form.userNameError',
        defaultMessage: 'Enter your username',
    },
    passwordError: {
        id: 'pages.login.form.passwordError',
        defaultMessage: 'Enter your password',
    },
});

export default (values, { intl }) => {
    const errors = {};

    // Make sure username is defined and is not empty
    if (isNil(values.username) || isEmpty(values.username)) {
        set(errors, 'username', intl.formatMessage(intlMessages.userNameError));
    }

    // Make sure password is defined and is not empty
    if (isNil(values.password) || isEmpty(values.password)) {
        set(errors, 'password', intl.formatMessage(intlMessages.passwordError));
    }

    return errors;
};
