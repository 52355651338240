/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import CabinetDetails from '../containers/details/CabinetDetails';

const Button = styled(BaseButton)`
    width: 160px;
`;

const EditCabinetButton = ({ open, onClose, onOpen, cabinet }) => (
    <CabinetDetails
        cabinet={cabinet}
        open={open}
        onClose={onClose}
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.cabinets.CabinetList.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
    />
);

export default withModal(EditCabinetButton);
