import React from 'react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../lib/withModal';

import MembershipDetails from '../containers/details/MembershipDetails';

const EditMembershipButton = ({ open, onClose, onOpen, membership }) => (
    <MembershipDetails
        membership={membership}
        open={open}
        onClose={onClose}
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.memberships.list.show"
                    defaultMessage="Show"
                />
            </a>
        }
    />
);

export default withModal(EditMembershipButton);
