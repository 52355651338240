/**
 * @flow
 */

import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';
import moment from 'moment';

import Calendar from '../../../../containers/Calendar';

const props = withProps(({ value, dayPickerProps, isOwner }) => ({
    dayPickerProps: Object.assign(
        {},
        {
            disabledDays: isOwner
                ? null
                : {
                      before: new Date(),
                  },
            selectedDays: date => moment(date).isSame(value, 'day'),
        },
        dayPickerProps,
    ),
}));

const handlers = withHandlers({
    onChange: ({ isOwner, onChange }) => date => {
        if (moment().isSameOrBefore(date, 'day') || isOwner) {
            onChange(date);
        }
    },
});

const mapStateToProps = ({ user }) => ({
    isOwner: user.get('isOwner'),
});

export default compose(
    connect(mapStateToProps),
    props,
    handlers,
)(Calendar);
