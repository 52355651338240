import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { get, sortBy, map, property, indexOf, filter } from 'lodash';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, defineMessages } from 'react-intl';

import MasterList from '../../components/details/MasterList';

const intlMessages = defineMessages({
    notExists: {
        id: 'pages.clients.masterList.error.notExists',
        defaultMessage: 'This client does not exist',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const GET_DEFAULT_MASTER_LIST = gql`
    query getDefaultMasters($salon: ID!, $client: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    masters {
                        nodes {
                            id
                            firstName
                            lastName
                        }
                    }
                    client(id: $client) {
                        id
                        defaultMasters {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const SET_DEFAULT_MASTER_LIST = gql`
    mutation setDefaultMasters($input: SetDefaultMastersInput!) {
        setDefaultMasters(input: $input) {
            client {
                id
                defaultMasters {
                    id
                }
            }
        }
    }
`;

const withData = graphql(GET_DEFAULT_MASTER_LIST, {
    options: ({ salon, client }) => ({
        variables: { salon, client: client.id },
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        masters: sortBy(
            filter(get(viewer, 'salon.masters.nodes', []), Boolean),
            ({ firstName }) => String(firstName).toLowerCase(),
        ),
        defaultMasters: map(
            get(viewer, 'salon.client.defaultMasters', []),
            property('id'),
        ),
    }),
});

const withMutation = graphql(SET_DEFAULT_MASTER_LIST, {
    props: ({
        mutate,
        ownProps: {
            client,
            salon,
            masters: masterList,
            intl: { formatMessage },
            onClose,
        },
    }) => ({
        onSubmit: formData => {
            const masters = filter(
                map(formData.masters, (v, i) => (v ? masterList[i].id : null)),
                Boolean,
            );

            const mutation = mutate({
                variables: {
                    input: { client: client.id, salon, masters },
                },
            });

            return mutation
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (
                            graphQLError.data &&
                            graphQLError.data.error === 'CLIENT_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: formatMessage(intlMessages.notExists),
                            });
                        }
                        throw new SubmissionError({
                            _error: formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                    throw new SubmissionError({
                        _error: formatMessage(intlMessages.defaultErrorMessage),
                    });
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'setupClientMasters',
    enableReinitialize: true,
});

const props = withProps(({ masters: masterList, defaultMasters, client }) => {
    const masters = map(masterList, master =>
        Object.assign(
            { checked: !!master && indexOf(defaultMasters, master.id) !== -1 },
            master,
        ),
    );

    return {
        masters,
        initialValues: {
            masters: map(masters, property('checked')),
        },
    };
});

const mapStateToProps = ({ user }) => ({ salon: user.get('salon') });

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withMutation,
    props,
    withForm,
)(MasterList);
