import { Map } from 'immutable';

const initialState = new Map({
    searchQuery: '',
    manufacturersFilter: [],
    salonFilter: [],
});

export default (state = initialState, action) => {
    switch (action.type) {
        // Save value of search query
        case 'INVENTORY.SEARCH': {
            return state.set('searchQuery', action.query);
        }
        case 'INVENTORY.FILTER.BY.MANUFACTURERS': {
            return state.set('manufacturersFilter', action.manufacturers);
        }
        case 'INVENTORY.FILTER.BY.SALONS': {
            return state.set('salonFilter', action.salons);
        }
        default: {
            return state;
        }
    }
};
