/**
 * @flow
 */

import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { switchSalon } from '../../../actions/user';

const handlers = withHandlers({
    onClick: ({ salon, salonName, switchSalon, history }) => () => {
        switchSalon(salon, salonName);
        history.push('/salon/appointments');
    },
});

const limitProps = mapProps(
    ({
        salon,
        salonName,
        switchSalon,
        match,
        location,
        history,
        staticContext,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(
        null,
        { switchSalon },
    ),
    withRouter,
    handlers,
    limitProps,
)(Button);
