import { Map } from 'immutable';

const initialState = new Map({
    promoCodeFilter: [],
});

export default (state = initialState, action) => {
    switch (action.type) {
        case 'STATISTICS.FILTER.BY.PROMO.CODE': {
            return state.set('promoCodeFilter', action.promoCodes);
        }
        default: {
            return state;
        }
    }
};
