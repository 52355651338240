/**
 * @flow
 */

/**
 * Search staff that match specified query
 * 
 * @param {String} query - The query to search for
 */
export const search = (query: string) => ({
    type: 'STAFF.SEARCH',
    query,
});
