/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps, lifecycle } from 'recompose';
import { map, omit, chain, property, each, get, find, cloneDeep } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import InvoiceForm from '../../../components/details/invoice/InvoiceForm';
import validate from './validate';
import { clearInvoiceProducts } from '../../../../../actions/invoice';
import { prepareFloatForServer } from '../../../../../lib/numberFormatter';

import { GET_SALON_QUERY } from '../../timetable/MasterTable';
import { GET_MASTER_APPOINTMENTS_QUERY } from '../../../../master-appointments/containers/queries';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },

    runOut: {
        id: 'pages.memberships.pay.runOut',
        defaultMessage: 'Membership is run out of uses',
    },
    membershipError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.membershipError',
        defaultMessage: 'Only one use of membership is allowed',
    },
    membershipDoesNotExist: {
        id:
            'pages.appointments.details.invoice.InvoiceFields.membershipDoesNotExist',
        defaultMessage: 'Membership does not exist',
    },
});

// A mutation that updates the invoice
const UPDATE_INVOICE_MUTATION = gql`
    mutation updateInvoice($input: UpdateInvoiceInput!, $lang: String!) {
        updateInvoice(input: $input) {
            invoice {
                id
                items {
                    id
                    type
                    entity {
                        ... on Service {
                            id
                            name(lang: $lang)
                        }
                        ... on Product {
                            id
                            name
                        }
                    }
                    sellerType
                    seller
                    quantity
                    price
                    paymentType
                    appointment {
                        id
                    }
                }
                totalPrice
                paid
            }
        }
    }
`;

const updateAppointment = (
    store,
    salon,
    startAt,
    clientId,
    masterId,
    invoiceId,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
            showArchive,
            date: moment(startAt).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
    });

    const master = find(
        data.viewer.salon.masters.nodes,
        item => item.id === masterId,
    );

    const stats = get(
        find(master.appointments, item => item.client.id === clientId),
        'client.stats',
    );

    stats.failedAppointments--;
    stats.successAppointments++;

    each(
        chain(data)
            .get('viewer.salon.masters.nodes')
            .map(property('appointments'))
            .flatten()
            .filter(({ invoice }) => invoice && invoice.id === invoiceId)
            .value(),
        invoice => (invoice.paid = true),
    );

    store.writeQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
            showArchive,
            date: moment(startAt).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
        data,
    });
};

const updateAppointmentOnMasterAppointment = (
    store,
    salon,
    fromDate,
    toDate,
    clientId,
    masterId,
    invoiceId,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            salon,
            master: masterId,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
    });

    const stats = get(
        find(
            data.viewer.salon.master.appointments,
            item => item.client.id === clientId,
        ),
        'client.stats',
    );

    stats.failedAppointments--;
    stats.successAppointments++;

    each(
        chain(data)
            .get('viewer.salon.master.appointments')
            .filter(({ invoice }) => invoice && invoice.id === invoiceId)
            .value(),
        invoice => (invoice.paid = true),
    );

    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            salon,
            master: masterId,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
        data,
    });
};

const withMutation = graphql(UPDATE_INVOICE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onSubmit: formData => {
            const invoiceData = formData;
            const itemsWithFormattedPrice = map(invoiceData.items, item => {
                const clonedItem = cloneDeep(item);
                clonedItem.price = prepareFloatForServer(clonedItem.price);
                return clonedItem;
            });
            invoiceData.items = itemsWithFormattedPrice;
            const mutation = mutate({
                variables: {
                    input: {
                        invoiceId: ownProps.invoice.id,
                        membership:
                            (ownProps.membership && ownProps.membership.id) ||
                            null,
                        ...invoiceData,
                    },
                    lang: ownProps.currentLanguage,
                },
                update: store => {
                    if (formData.paid) {
                        if (ownProps.masterAppointments) {
                            updateAppointmentOnMasterAppointment(
                                store,
                                ownProps.salon,
                                ownProps.fromDate,
                                ownProps.toDate,
                                ownProps.appointment.client.id,
                                ownProps.appointment.master.id,
                                ownProps.appointment.invoice.id,
                                ownProps.showArchive,
                                ownProps.currentLanguage,
                            );
                        } else {
                            if (ownProps.appointment.master) {
                                updateAppointment(
                                    store,
                                    ownProps.salon,
                                    ownProps.appointment.startAt,
                                    ownProps.appointment.client.id,
                                    ownProps.appointment.master.id,
                                    ownProps.appointment.invoice.id,
                                    ownProps.showArchive,
                                    ownProps.currentLanguage,
                                );
                            }
                        }
                    }
                },
            });

            return mutation.catch(error => {
                const graphQLError =
                    error.graphQLErrors && error.graphQLErrors[0];

                if (graphQLError) {
                    if (
                        graphQLError.data.error ===
                        'NEGATIVE_QUANTITY_OF_MEMBERSHIP_USES'
                    ) {
                        throw new SubmissionError({
                            _error: ownProps.intl.formatMessage(
                                intlMessages.runOut,
                            ),
                        });
                    } else if (graphQLError.data.type === 'membership') {
                        throw new SubmissionError({
                            _error: ownProps.intl.formatMessage(
                                intlMessages.membershipError,
                            ),
                        });
                    } else if (
                        graphQLError.data.error === 'MEMBERSHIP_DOES_NOT_EXIST'
                    ) {
                        throw new SubmissionError({
                            _error: ownProps.intl.formatMessage(
                                intlMessages.membershipDoesNotExist,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error:
                            (graphQLError && graphQLError.message) ||
                            'Something went wrong',
                    });
                }
            });
        },
    }),
});

// Init invoice form
const withForm = reduxForm({
    form: 'invoice',
    validate,
});

// Setup initial values of the form
const withInitialValues = withProps(ownProps => ({
    initialValues: {
        paid: false,
        items: map(ownProps.invoice.items, item => ({
            ...omit(item, ['__typename', 'appointment']),
            entity: item.entity.id,
            price: item.price.toFixed(2),
            paymentType: item.paymentType,
        })),
    },
}));

const withLifeCycle = lifecycle({
    componentWillMount() {
        const { dispatchClearProducts } = this.props;
        dispatchClearProducts();
    },
});

const mapStateToProps = ({ user, salon, intl }) => ({
    salon: user.get('salon'),
    showArchive: salon.get('showArchive'),
    isSysadmin: user.get('isSysadmin'),
    isOwner: user.get('isOwner'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

const mapDispatchToProps = {
    dispatchClearProducts: clearInvoiceProducts,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    injectIntl,
    withMutation,
    withInitialValues,
    withForm,
    withLifeCycle,
)(InvoiceForm);
