/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Menu as BaseMenu } from 'semantic-ui-react';
import DatePicker from '../../containers/schedule/DatePicker';
import { PageHeader } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { Timetable } from './timetable';
import GenerateScheduleButton from './GenerateScheduleButton';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const TimetableWrapper = styled.div`
    margin: 1em -2em;
`;

const SchedulePage = () => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.settings.schedule"
                        defaultMessage="Schedule Settings"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <GenerateScheduleButton />
                </BaseMenu.Item>
                <BaseMenu.Item fitted>
                    <DatePicker
                        value={new Date()}
                        format="MMM D, dddd"
                        readOnly
                    />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <TimetableWrapper>
            <Timetable />
        </TimetableWrapper>
    </Container>
);

export default SchedulePage;
