/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, withProps, withHandlers, withState } from 'recompose';
import moment from 'moment';
import { isNil } from 'lodash';
import { injectIntl } from 'react-intl';

import { convertMinutesToDateTime } from '../../../../lib/date';

import WorkingTimeDialog from '../../components/schedule/WorkingTimeDialog';
import validate from './form/scheduleFormValidate';
import { GET_SALON_QUERY } from './timetable/Timetable';

// Mutation to add new working time
const ADD_WORKING_TIME_MUTATION = gql`
    mutation addWorkingTime($input: AddWorkingTimeInput!) {
        addWorkingTime(input: $input) {
            workingTime {
                id
                startAt
                endAt
            }
        }
    }
`;

const withAddMutation = graphql(ADD_WORKING_TIME_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onAdd: formData => {
            return mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        master: ownProps.master.id,
                        startAt: moment(formData.startAt).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                        endAt: moment(formData.endAt).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            showArchive: ownProps.showArchive,
                            date: moment(ownProps.date).format('YYYY-MM-DD'),
                            lang: ownProps.currentLanguage,
                        },
                    },
                ],
            });
        },
    }),
});

// Mutation to update existing working time
const UPDATE_WORKING_TIME_MUTATION = gql`
    mutation updateWorkingTime($input: UpdateWorkingTimeInput!) {
        updateWorkingTime(input: $input) {
            workingTime {
                id
                startAt
                endAt
            }
            updated
        }
    }
`;

const withUpdateMutation = graphql(UPDATE_WORKING_TIME_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onUpdate: formData => {
            return mutate({
                variables: {
                    input: {
                        workingTimeId: ownProps.workingTime.id,
                        startAt: moment(formData.startAt).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                        endAt: moment(formData.endAt).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_SALON_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            showArchive: ownProps.showArchive,
                            date: moment(ownProps.date).format('YYYY-MM-DD'),
                            lang: ownProps.currentLanguage,
                        },
                    },
                ],
            });
        },
    }),
});

// Handle form submission and perform correct action
const handlers = withHandlers({
    onSubmit: ownProps => formData => {
        const { workingTime, onAdd, onUpdate, onClose } = ownProps;

        return new Promise(resolve => {
            if (isNil(workingTime.id)) {
                resolve(onAdd(formData));
            } else {
                resolve(onUpdate(formData));
            }
        }).then(({ data }) => {
            if (data.updateWorkingTime && !data.updateWorkingTime.updated) {
                ownProps.setUpdated(false);
            } else {
                // Close dialog after successfull action
                onClose();
            }
        });
    },
});

// Initial state is true since here are two mutations
const withUpdated = withState('updated', 'setUpdated', true);

// Init working time form
const withForm = reduxForm({
    form: 'workingTime',
    touchOnBlur: true,
    enableReinitialize: true,
    validate,
    shouldValidate: ({ nextProps }) => nextProps && nextProps.open,
});

const props = withProps(({ open, date, workingTime }) => {
    if (!open) {
        return {};
    }

    // Provide initial values to the form
    const initialValues = {
        startAt: convertMinutesToDateTime(workingTime.startAt, date).toString(),
        endAt: convertMinutesToDateTime(workingTime.endAt, date).toString(),
    };

    return {
        initialValues,
        edit: workingTime.id !== undefined,
        pristine: false,
    };
});

const mapStateToProps = ({ user, schedule, salon, intl }) => ({
    salon: user.get('salon'),
    date: schedule.get('date'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withUpdated,
    withAddMutation,
    withUpdateMutation,
    handlers,
    props,
    withForm,
)(WorkingTimeDialog);
