import {
    set,
    isEmpty,
} from 'lodash';
import {
    defineMessages
} from 'react-intl';
import {
    isValidNumber
} from 'libphonenumber-js';

const intlMessages = defineMessages({
    firstNameError: {
        id: 'pages.clients.ClientPage.clients.firstNameError',
        defaultMessage: 'Enter client first name',
    },
    genderError: {
        id: 'pages.clients.ClientPage.clients.genderError',
        defaultMessage: 'Please select client gender',
    },
    phoneError: {
        id: 'pages.clients.ClientPage.clients.phoneError',
        defaultMessage: 'Invalid phone number.'
    },
    noCountryCodeError: {
        id: 'pages.clients.ClientPage.clients.noCountryCodeError',
        defaultMessage: 'Phone number should start with country code'
    }
});

export default (values, {
    intl
}) => {
    const errors = {};
    // Validate first name
    if (isEmpty(values.firstName)) {
        set(errors, 'firstName', intl.formatMessage(intlMessages.firstNameError));
    }
    //validate client gender
    if (isEmpty(values.gender)) {
        set(errors, 'gender', intl.formatMessage(intlMessages.genderError));
    }

    // phone number can be either empty or valid
    if (values.phone) {
        if (values.phone[0] !== '+') {
            set(errors, 'phone', intl.formatMessage(intlMessages.noCountryCodeError));
        } else if (!isValidNumber(values.phone)) {
            set(errors, 'phone', intl.formatMessage(intlMessages.phoneError));
        }
    }

    return errors;
};
