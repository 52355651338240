import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import ProductsList from '../components/ProductsList';
import { get } from 'lodash';

// Query for list of products of current salon
export const GET_PRODUCTS_QUERY = gql`
    query getProducts(
        $salon: ID!
        $search: String
        $manufacturersFilter: [String]
        $first: Int
        $after: String
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    products(
                        search: $search
                        manufacturersFilter: $manufacturersFilter
                        first: $first
                        after: $after
                    ) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            cursor
                            node {
                                id
                                ...product
                            }
                        }
                    }
                }
            }
        }
    }

    fragment product on Product {
        name
        code
        manufacturer {
            id
            name
        }
        quantity
        price
        supplierPrice
        type
    }
`;

const withData = graphql(GET_PRODUCTS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            search: ownProps.searchQuery || undefined,
            manufacturersFilter: ownProps.manufacturersFilter || undefined,
            first: 500,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer, error, fetchMore } }) => ({
        loading,
        fetchMore,
        products: get(viewer, 'salon.products.edges', []),
        pageInfo: get(viewer, 'salon.products.pageInfo', {}),
    }),
});

const mapStateToProps = ({ inventory, user }) => ({
    searchQuery: inventory.get('searchQuery'),
    manufacturersFilter: inventory.get('manufacturersFilter'),
    salon: user.get('salon'),
});

const handlers = withHandlers({
    /**
     * Handle load more results event by requesting data after
     * end cursor from the last request.
     */
    loadMore: ownProps => () => {
        const {
            loading,
            searchQuery,
            manufacturersFilter,
            salon,
            pageInfo,
            fetchMore,
        } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_PRODUCTS_QUERY,
            variables: {
                salon,
                first: 500,
                after: pageInfo.endCursor,
                search: searchQuery || undefined,
                manufacturersFilter: manufacturersFilter || undefined,
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.salon.products.edges;
                const newEdges = fetchMoreResult.viewer.salon.products.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.salon.products.edges = [
                    ...prevEdges,
                    ...newEdges,
                ];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(ProductsList);
