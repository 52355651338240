import AppointmentsPage from '../components/AppointmentsPage';
import { compose, lifecycle, withState } from 'recompose';
import { withTawk } from '../../../providers/Tawk';
import { connect } from 'react-redux';
import { setTab } from '../../../actions/appointments';

const mapStateToProps = ({ appointments, user }) => ({
    tab: appointments.get('tab'),
    isMaster: user.get('isMaster'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setTab: value => {
        dispatch(setTab(value));
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTawk,
    lifecycle({
        componentDidMount() {
            this.props.minimizeTawk();
        },
    }),
)(AppointmentsPage);
