/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Dropdown, Input, Flag, Icon } from 'semantic-ui-react';
import { keys, map, filter, get } from 'lodash';
import {
    compose,
    withState,
    withHandlers,
    withProps,
    branch,
    mapProps,
    renderComponent,
    lifecycle,
} from 'recompose';
import {
    parseNumber,
    AsYouType,
    getCountryCallingCode,
    formatNumber,
} from 'libphonenumber-js';
import metadata from 'libphonenumber-js/metadata.full.json';

/**
 * Country list
 */
const CountryList = styled(Dropdown)`
    .ui.selection.dropdown& {
        padding-left: 1em;
        padding-right: 1em;
        &::after {
            content: '\\F0D7';
            font-family: Dropdown;
            font-size: 0.85714286em;
            color: rgba(0, 0, 0, 0.87);
            opacity: 0.8;
            font-weight: 400;
        }
        .menu > .item > .flag {
            margin-right: 0;
        }
    }
`;

/**
 * Display either flag or unknown icon
 */
const CountryIcon = branch(
    ({ name }) => name === '?',
    renderComponent(() => <Icon name="question" />),
)(({ name }) => <Flag name={name} />);

type CountryListOption = {
    key: string,
    value: string,
    text: string,
    flag: string,
};

type PhoneInputProps = {
    handleCountryChange: Function,
    handleInputChange: Function,
    disabled: boolean,
    flag: string,
    options: Array<CountryListOption>,
};

/**
 * Phone Input component
 */
const PhoneInput = ({
    handleCountryChange,
    handleInputChange,
    flag,
    options,
    disabled,
    ...props
}: PhoneInputProps) => (
    <Input
        {...props}
        disabled={disabled}
        onChange={handleInputChange}
        label={
            <CountryList
                options={options}
                onChange={handleCountryChange}
                icon={<CountryIcon name={flag} />}
                selectOnBlur={false}
                disabled={disabled}
                scrolling
                selection
                compact
            />
        }
    />
);

const withPhoneInputHandlers = withHandlers({
    /**
     * Changes flag and country code in the input field
     */
    handleCountryChange: ({ setFlag, onChange, value }) => (e, data) => {
        const code = getCountryCallingCode(data.value.toUpperCase());
        const phone = parseNumber(value, { extended: true });
        value = value.replace('+', '');
        if (value.indexOf(phone.countryCallingCode) === 0) {
            value = value.replace(phone.countryCallingCode, `+${code}`);
        } else if (value.length <= 3) {
            value = `+${code}`;
        } else {
            value = `+${code}${value}`;
        }
        value = formatNumber(value, 'International');
        onChange(e, { value });
        setFlag(data.value.toLowerCase());
    },
    /**
     * Changes flag and formats input field value
     */
    handleInputChange: ({ setFlag, onChange, asYouType }) => (e, { value }) => {
        const result = parseNumber(value, { extended: true });
        let flag = result.country;
        if (!flag && result.countryCallingCode) {
            flag = get(metadata, [
                'country_calling_codes',
                result.countryCallingCode,
                '0',
            ]);
        }
        setFlag((flag || '?').toLowerCase());
        onChange(e, { value: asYouType.input(value) });
    },
});

/**
 * Setup country list
 */
const withPhoneInputProps = withProps(() => {
    const excludes = [
        'AC',
        'BL',
        'BQ',
        'CW',
        'GG',
        'IM',
        'JE',
        'MF',
        'SS',
        'SX',
        'TA',
        'XK',
        '001',
    ];

    const options = map(
        filter(
            keys(metadata.countries),
            country => excludes.indexOf(country) === -1,
        ),
        country => {
            country = country.toLowerCase();
            return {
                key: country,
                value: country,
                flag: country,
                text: '',
            };
        },
    );

    return { options, asYouType: new AsYouType() };
});

/**
 * Setup initial values of component
 */
const withPhoneInputLifecycle = lifecycle({
    componentDidMount() {
        const { setFlag, onChange, value, flag } = this.props;
        let flagVal;
        let phone;
        if (value) {
            flagVal = (
                parseNumber(value, { extended: true }).country || flag
            ).toLowerCase();
            phone = formatNumber(value, 'International');
        } else {
            flagVal = flag;
            phone = '+' + getCountryCallingCode(flag.toUpperCase());
        }
        setFlag(flagVal);
        onChange(null, {
            value: phone,
        });
    },
});

/**
 * Remove unneseccary props in order to prevent errors in the console.
 */
const withLimitedProps = mapProps(({ meta, setFlag, asYouType, ...props }) => ({
    ...props,
}));

export default compose(
    withState('flag', 'setFlag', 'lv'),
    withPhoneInputProps,
    withPhoneInputHandlers,
    withPhoneInputLifecycle,
    withLimitedProps,
)(PhoneInput);
