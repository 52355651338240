import gql from 'graphql-tag';

export const APPOINTMENT_FRAGMENT = gql`
    fragment appointment on Appointment {
        author {
            id
            firstName
            lastName
        }
        authorRole
        master {
            id
            firstName
            lastName
        }
        client {
            id
            firstName
            lastName
            phone
            gender
            birthday
            boughtItems(salon: $salon, limit: 1, types: ["PRODUCT"]) {
                edges {
                    boughtAt
                    type
                    quantity
                    entity {
                        ... on Product {
                            id
                            name
                        }
                    }
                }
            }
            stats(salon: $salon) {
                totalAppointments
                failedAppointments
                successAppointments
                lastAppointment {
                    id
                    service {
                        id
                        name(lang: $lang)
                    }
                    startAt
                }
            }
        }
        service {
            id
            name(lang: $lang)
            color
            price
        }
        startAt
        endAt
        duration
        notes
        group
        invoice {
            id
            paid
        }
        archived
    }
`;
