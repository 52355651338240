/**
 * @flow
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import withModal from '../../../../../lib/withModal';

import AddServiceDialog
    from '.././../../containers/service/service/AddServiceDialog';

import type { ModalDialogTrigger, ServiceGroup } from '../../../../../type';

type AddServiceButtonProps = {
    serviceGroup: ServiceGroup,
} & ModalDialogTrigger;

const AddServiceButton = ({
    open,
    onOpen,
    onClose,
    serviceGroup,
}: AddServiceButtonProps) => (
    <AddServiceDialog
        trigger={
            <Button
                onClick={onOpen}
                icon="add"
                floated="right"
                color="blue"
                compact
                basic
            />
        }
        open={open}
        onClose={onClose}
        serviceGroup={serviceGroup}
    />
);

export default withModal(AddServiceButton);
