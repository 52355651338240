/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Menu as BaseMenu, Image, Icon as BaseIcon } from 'semantic-ui-react';

import AnimatedMenuItem from '../../components/AnimatedMenuItem';
import { UserMenu } from '../../components/userMenu';
import Language from '../../containers/Language';
import SalonSelect from '../containers/SalonSelect';
import SettingsMenu from './SettingsMenu';
import SysadminMenu from './SysadminMenu';

const Menu = styled(BaseMenu)`
    &.menu {
        flex-shrink: 0;
        height: 60px;
        background: #323237 !important;
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const Logo = styled(Image)`
    height: 40px;
`;

const LogoMenuItem = styled(BaseMenu.Item)`
    &.item {
        margin-right: 40px !important;
    }
`;

const Icon = styled(BaseIcon)`
    &.icon {
        font-size: 1.2em;
    }
`;

const AdminNavigation = () => (
    <Menu inverted secondary>
        <LogoMenuItem>
            <Logo src="/images/appotime-logo-dashboard.png" />
        </LogoMenuItem>
        <AnimatedMenuItem
            visible={<Icon name="calendar" color="grey" />}
            hidden={<Icon name="calendar" />}
            text={
                <FormattedMessage
                    id="navbar.menu.appointments"
                    defaultMessage="Appointments"
                />
            }
            to="/salon/appointments"
            link
        />
        <AnimatedMenuItem
            visible={<Icon name="user circle outline" color="grey" />}
            hidden={<Icon name="user circle outline" />}
            text={
                <FormattedMessage
                    id="navbar.menu.clients"
                    defaultMessage="Clients"
                />
            }
            to="/salon/clients"
            link
        />
        <AnimatedMenuItem
            visible={<Icon name="cart" color="grey" />}
            hidden={<Icon name="cart" />}
            text={
                <FormattedMessage
                    id="navbar.menu.inventory"
                    defaultMessage="Inventory"
                />
            }
            to="/salon/inventory"
            link
        />
        <AnimatedMenuItem
            visible={<Icon name="group" color="grey" />}
            hidden={<Icon name="group" />}
            text={
                <FormattedMessage
                    id="navbar.menu.staff"
                    defaultMessage="Staff"
                />
            }
            to="/salon/staff"
            link
        />
        <AnimatedMenuItem
            visible={<Icon name="calculator" color="grey" />}
            hidden={<Icon name="calculator" />}
            text={
                <FormattedMessage
                    id="navbar.menu.report"
                    defaultMessage="Report"
                />
            }
            to="/salon/reports"
            link
        />
        <SettingsMenu
            trigger={
                <AnimatedMenuItem
                    visible={<Icon name="setting" color="grey" />}
                    hidden={<Icon name="setting" />}
                    text={
                        <FormattedMessage
                            id="navbar.menu.settings"
                            defaultMessage="Settings"
                        />
                    }
                    to="/salon/settings"
                />
            }
        />
        <SysadminMenu
            trigger={
                <AnimatedMenuItem
                    visible={<Icon name="settings" color="grey" />}
                    hidden={<Icon name="settings" />}
                    text={
                        <FormattedMessage
                            id="navbar.menu.sysadmin.sysadmin"
                            defaultMessage="Admin"
                        />
                    }
                    to="/salon/admin-settings"
                />
            }
        />
        <BaseMenu.Menu position="right">
            <BaseMenu.Item fitted>
                <SalonSelect />
            </BaseMenu.Item>
            <BaseMenu.Item fitted>
                <Language />
            </BaseMenu.Item>
            <BaseMenu.Item>
                <UserMenu />
            </BaseMenu.Item>
        </BaseMenu.Menu>
    </Menu>
);

const MasterNavigation = () => (
    <Menu inverted secondary>
        <LogoMenuItem>
            <Logo src="/images/appotime-logo-dashboard.png" />
        </LogoMenuItem>
        <BaseMenu.Menu position="right">
            <BaseMenu.Item fitted>
                <SalonSelect />
            </BaseMenu.Item>
            <BaseMenu.Item fitted>
                <Language />
            </BaseMenu.Item>
            <BaseMenu.Item>
                <UserMenu />
            </BaseMenu.Item>
        </BaseMenu.Menu>
    </Menu>
);

const Navigation = ({ isMaster }) =>
    !isMaster ? <AdminNavigation /> : <MasterNavigation />;

export default Navigation;
