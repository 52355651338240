/**
 *  @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '../../../components';

import MasterForm from '../components/form/MasterForm';

import type { ReduxForm, ModalDialog } from '../../../type.js';

type AddMasterDialogProps = {
    props: any,
    onModalClose: Function,
    checkFormPristine: Function,
    handleClear: Function,
} & ModalDialog &
    ReduxForm;

const AddMasterDialog = ({
    trigger,
    open,
    onClose,
    onModalClose,
    handleSubmit,
    submitting,
    pristine,
    checkFormPristine,
    handleClear,
    ...props
}: AddMasterDialogProps) => (
    <Modal trigger={trigger} onClose={onModalClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.masters.AddMasterDialog.header"
                defaultMessage="New master"
            />
        </Modal.Header>
        <Modal.Content>
            <MasterForm
                {...props}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.masters.AddMasterDialog.create"
                    defaultMessage="Create"
                />
            </Button>
            <Button onClick={handleClear} disabled={submitting} basic>
                <FormattedMessage
                    id="pages.masters.AddMasterDialog.clear"
                    defaultMessage="Clear"
                />
            </Button>
            <Button onClick={onModalClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.masters.AddMasterDialog.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default AddMasterDialog;
