/**
 * @flow
 */

import gql from 'graphql-tag';
import {
    graphql
} from 'react-apollo';
import {
    compose
} from 'recompose';
import {
    connect
} from 'react-redux';
import {
    remove
} from 'lodash';

import DeleteAdministratorDialog from '../components/DeleteAdministratorDialog';

import {
    GET_ADMINISTRATORS_QUERY
} from './AdministratorsList';

// Query that deletes specified administrator
const DELETE_ADMINISTRATOR_QUERY = gql `
    mutation deleteAdministrator($input: DeleteAdministratorInput!) {
        deleteAdministrator(input: $input) {
            administratorId
        }
    }
`;

const withData = graphql(DELETE_ADMINISTRATOR_QUERY, {
    props: ({
        mutate,
        ownProps
    }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        userId: ownProps.user,
                        salonId: ownProps.salon,
                        administratorId: ownProps.administrator.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete administrator before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteAdministrator: {
                        __typename: 'DeleteAdministratorPayload',
                        administratorId: ownProps.administrator.id,
                    },
                },
                // Remove deleted administrator from query cache
                update: (store, {
                    data: {
                        deleteAdministrator
                    }
                }) => {
                    const data = store.readQuery({
                        query: GET_ADMINISTRATORS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });

                    remove(
                        data.viewer.salon.administrators,
                        administrator => administrator.id === deleteAdministrator.administratorId,
                    );

                    store.writeQuery({
                        query: GET_ADMINISTRATORS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data: data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after administrator was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when administrator was not deleted
                    console.error(error);
                });
        },
    }),
});

export default compose(
    connect(({
        user
    }) => ({
        user: user.get('id'),
        salon: user.get('salon')
    })),
    withData,
)(DeleteAdministratorDialog);
