/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import type {
    ModalDialog,
    WorkingTime,
    Master,
    ScheduleSettings,
} from '../../../../type';

type DeleteWorkingTimeDialogProps = {
    schedule: ScheduleSettings,
    master: Master,
    workingTime: WorkingTime,
    onDelete: Function,
} & ModalDialog;

const DeleteWorkingTimeDialog = ({
    schedule,
    master,
    workingTime,
    trigger,
    open,
    onClose,
    onDelete,
    isDeletable,
}: DeleteWorkingTimeDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        {isDeletable === false && (
            <Message
                icon="warning sign"
                content={
                    <FormattedMessage
                        id="pages.settings.schedule.form.deleteForm.notDeleted"
                        defaultMessage="Schedule cannot be deleted, because there are appointments for that day"
                    />
                }
            />
        )}
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.settings.schedule.form.deleteForm.deleteHeader"
                defaultMessage="Delete Working Time"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.settings.schedule.form.deleteForm.deleteText"
                defaultMessage="Are you sure you want to delete working time of {masterFirstName} {masterLastName} between {startWorkingAt} and {endWorkingAt}?"
                values={{
                    masterFirstName: master.firstName,
                    masterLastName: master.lastName,
                    startWorkingAt: moment()
                        .startOf('day')
                        .add(workingTime.startAt, 'minutes')
                        .format(schedule.format),
                    endWorkingAt: moment()
                        .startOf('day')
                        .add(workingTime.endAt, 'minutes')
                        .format(schedule.format),
                }}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.schedule.form.deleteForm.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.schedule.form.deleteForm.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteWorkingTimeDialog;
