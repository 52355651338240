import { connect } from 'react-redux';
import moment from 'moment';

import ReportPage from '../components/ReportPage';
import { setAppRecords, setReportFilter } from '../../../actions/report';
import type { Dispatch } from '../../../type';

const DATE_FORMAT = 'YYYY-MM-DD';

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSetReportFilter: (e, { value }) => {
        dispatch(setReportFilter(value));
    },
    onSetAppRecords: value => {
        dispatch(setAppRecords(value));
    },
});

const mapStateToProps = ({ user, report, salon, intl }) => ({
    isOwner: user.get('isOwner'),
    salon: user.get('salon'),
    fromDate: moment(report.get('fromDate')).format(DATE_FORMAT),
    toDate: moment(report.get('toDate')).format(DATE_FORMAT),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    showAppRecords: report.get('showAppRecords'),
    reportFilter: report.get('reportFilter'),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportPage);
