/**
 * @flow
 */

import {
    connect
} from 'react-redux';
import {
    compose,
    withHandlers,
    withState
} from 'recompose';

import withModal from '../../../../lib/withModal';
import {
    clearInvoiceProducts
} from '../../../../actions/invoice';

import CartButton from '../../components/cart/CartButton';

const state = withState('paid', 'setPaid', false);

const handlers = withHandlers({
    onModalOpen: ({
        onOpen,
        setPaid,
        dispatchClearProducts
    }) => () => {
        dispatchClearProducts();
        setPaid(false);
        onOpen();
    },
});

const mapDispatchToProps = {
    dispatchClearProducts: clearInvoiceProducts,
};

export default compose(
    connect(null, mapDispatchToProps),
    withModal,
    state,
    handlers,
)(CartButton);
