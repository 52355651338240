/**
 * @flow
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import withModal from '../../../../../lib/withModal';

import EditServiceGroupDialog
    from '../../../containers/service/group/EditServiceGroupDialog';

import type { ServiceGroup, ModalDialogTrigger } from '../../../../../type';

type EditServiceGroupButtonProps = {
    serviceGroup: ServiceGroup,
} & ModalDialogTrigger;

const EditServiceGroupButton = ({
    open,
    onOpen,
    onClose,
    serviceGroup,
}: EditServiceGroupButtonProps) => (
    <EditServiceGroupDialog
        trigger={
            <Button
                onClick={onOpen}
                icon="setting"
                floated="right"
                secondary
                compact
                basic
            />
        }
        open={open}
        onClose={onClose}
        serviceGroup={serviceGroup}
    />
);

export default withModal(EditServiceGroupButton);
