/**
 * @flow
 */

import gql from 'graphql-tag';
import { enableExperimentalFragmentVariables } from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
    get,
    find,
    cloneDeep,
    remove,
    findIndex,
    map,
    assign,
    pick,
    filter,
    each,
    some,
    isEmpty,
} from 'lodash';

import { setMaster } from '../../../../actions/master-appointments';
import { setSettings } from '../../../../actions/salon';
import apollo from '../../../../apollo-client';
import MasterTable from '../../components/timetable/MasterTable';
import { getScheduledServices } from '../../../../lib/schedule';

import {
    APPOINTMENT_FRAGMENT,
    APPOINTMENT_ADD_SUBSCRIPTION,
    APPOINTMENT_DELETE_SUBSCRIPTION,
    MASTER_MIGRATION_SUBSCRIPTION,
} from '../queries';

import { MASTER_TABLE_FRAGMENT } from '../masterAccessQueries';

// The query to get current salon object
export const GET_SALON_QUERY = gql`
    query getSalon(
        $date: String!
        $salon: ID!
        $showArchive: Boolean
        $lang: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    name
                    administrator {
                        id
                    }
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                        enableArchive
                    }
                    masters(date: $date) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        nodes {
                            id
                            ...master
                        }
                    }
                }
            }
            ...onMasterTable
        }
    }

    fragment master on Master {
        firstName
        lastName
        phone
        email
        status
        type
        serviceGroups {
            id
            name(lang: $lang)
            type
        }
        appointments(date: $date, showArchive: $showArchive) {
            id
            ...appointment
        }
        schedule(startDate: $date) {
            id
            startAt
            endAt
        }
    }

    ${APPOINTMENT_FRAGMENT}
    ${MASTER_TABLE_FRAGMENT}
`;

const subscribeToMigratedMaster = subscribeToMore => params => {
    return subscribeToMore({
        document: MASTER_MIGRATION_SUBSCRIPTION,
        variables: {
            salon: params.salon,
        },
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }

            const next = cloneDeep(prev);

            const masterMigrated = get(subscriptionData, 'data.masterMigrated');

            const master = find(next.viewer.salon.masters.nodes, {
                phone: masterMigrated.phone,
            });

            const appointments = map(get(master, 'appointments'), item => {
                const newItem = cloneDeep(item);
                newItem.master = assign(
                    item.master,
                    pick(masterMigrated, ['id', 'firstName', 'lastName']),
                );
                return newItem;
            });

            master.appointments = appointments;

            master.firstName = masterMigrated.firstName;

            master.lastName = masterMigrated.lastName;

            return next;
        },
    });
};

/**
 * Handle new appointment subscription and update local cache with new appointment
 */
const subscribeToAddedAppointment = subscribeToMore => params => {
    return subscribeToMore({
        document: APPOINTMENT_ADD_SUBSCRIPTION,
        variables: {
            salon: params.salon,
            date: params.date,
            lang: params.currentLanguage,
        },
        // Update local cache with a new appointment
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }

            const next = cloneDeep(prev);

            const appointment = subscriptionData.data.appointmentAdded;

            if (
                (params.showArchive || !appointment.archived) &&
                appointment.master &&
                some(next.viewer.salon.masters.nodes, {
                    id: appointment.master.id,
                })
            ) {
                each(next.viewer.salon.masters.nodes, (master, key) => {
                    const index = findIndex(master.appointments, [
                        'id',
                        appointment.id,
                    ]);
                    if (index > -1) {
                        if (appointment.master.id == master.id) {
                            next.viewer.salon.masters.nodes[key].appointments[
                                index
                            ] = appointment;
                        } else {
                            remove(
                                next.viewer.salon.masters.nodes[key]
                                    .appointments,
                                ['id', appointment.id],
                            );
                        }
                    } else {
                        if (appointment.master.id == master.id) {
                            next.viewer.salon.masters.nodes[
                                key
                            ].appointments.push(appointment);
                        }
                    }
                });
            }

            return next;
        },
    });
};

/**
 * Handle deleted appointments subscription and remove data from local cache
 */
const subscribeToDeletedAppointment = subscribeToMore => params => {
    return subscribeToMore({
        document: APPOINTMENT_DELETE_SUBSCRIPTION,
        variables: {
            salon: params.salon,
            date: params.date,
            lang: params.currentLanguage,
        },
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }

            const next = cloneDeep(prev);

            const appointment = subscriptionData.data.appointmentDeleted;

            let master = null;
            // check if appointment has master (related to #312)
            if (appointment.master) {
                master = find(next.viewer.salon.masters.nodes, {
                    id: appointment.master.id,
                });
            }

            if (master) {
                remove(master.appointments, o => o.id === appointment.id);
            }

            return next;
        },
    });
};

const withData = graphql(GET_SALON_QUERY, {
    options: ownProps => ({
        variables: {
            date: moment(ownProps.date).format('YYYY-MM-DD'),
            salon: ownProps.salonId,
            showArchive: ownProps.showArchive,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ ownProps, data: { loading, viewer, subscribeToMore } }) => {
        const salon = get(viewer, 'salon');
        const masters = filter(
            get(salon, 'masters.nodes'),
            master => !!master.schedule.length,
        );

        // Set settings of the other salon in SysAdmin mode
        if (
            !loading &&
            viewer &&
            ownProps.isSysadmin &&
            !isEmpty(salon) &&
            salon.administrator.id !== ownProps.user
        ) {
            const salonSettings = Object.assign(
                { enableArchive: get(salon, 'settings.enableArchive') },
                get(salon, 'settings.schedule'),
            );
            ownProps.setSettings(salonSettings);
        }

        return {
            loading,
            salon,
            services: getScheduledServices(masters),
            masters,
            // Subscribe on appointments updates
            subscribeToAddedAppointment: subscribeToAddedAppointment(
                subscribeToMore,
            ),
            subscribeToDeletedAppointment: subscribeToDeletedAppointment(
                subscribeToMore,
            ),
            subscribeToMigratedMaster: subscribeToMigratedMaster(
                subscribeToMore,
            ),
        };
    },
});

const mapStateToProps = ({ appointments, user, salon, intl }) => ({
    user: user.get('id'),
    date: appointments.get('date'),
    salonId: user.get('salon'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    allowToAddAppointments:
        user.get('isOwner') ||
        moment().isSameOrBefore(appointments.get('date'), 'day'),
    isSysadmin: user.get('isSysadmin'),
    isMaster: user.get('isMaster'),
});

const mapDipatchToProps = {
    setMaster,
    setSettings,
};

/**
 * Make subscriptions to appointment related events when the component has mounted
 */
const withLifecycle = function() {
    // store unsubscribe handlers in a closure
    let unsubscribeToAddedAppointment,
        unsubscribeToDeletedAppointment,
        unsubscribeToMigratedMaster;
    return lifecycle({
        componentWillMount: function() {
            const {
                subscribeToAddedAppointment,
                subscribeToDeletedAppointment,
                subscribeToMigratedMaster,
                user,
                salonId,
                date,
                showArchive,
                currentLanguage,
            } = this.props;

            // save appointment add unsubscribe handler to use later
            unsubscribeToAddedAppointment = subscribeToAddedAppointment({
                user,
                salon: salonId,
                date,
                showArchive,
                currentLanguage,
            });
            // save appointment delete unsubscribe handler to use later
            unsubscribeToDeletedAppointment = subscribeToDeletedAppointment({
                salon: salonId,
                date,
                currentLanguage,
            });

            unsubscribeToMigratedMaster = subscribeToMigratedMaster({
                salon: salonId,
            });
        },
        componentDidUpdate: function(prevProps) {
            const {
                subscribeToAddedAppointment,
                subscribeToDeletedAppointment,
                subscribeToMigratedMaster,
                user,
                salonId,
                date,
                showArchive,
                currentLanguage,
            } = this.props;
            // resubscribe if salon or date has changed
            if (
                !moment(date).isSame(prevProps.date, 'day') ||
                salonId !== prevProps.salonId
            ) {
                // unsubscribe if handler is available
                if (unsubscribeToAddedAppointment) {
                    unsubscribeToAddedAppointment();
                }
                // subscribe and save unsubscribe handler
                unsubscribeToAddedAppointment = subscribeToAddedAppointment({
                    user,
                    salon: salonId,
                    date,
                    showArchive,
                    currentLanguage,
                });
                // unsubscribe if handler is available
                if (unsubscribeToDeletedAppointment) {
                    unsubscribeToDeletedAppointment();
                }
                // subscribe and save unsubscribe handler
                unsubscribeToDeletedAppointment = subscribeToDeletedAppointment(
                    {
                        salon: salonId,
                        date,
                        currentLanguage,
                    },
                );
                // unsubscribe if handler is available
                if (unsubscribeToMigratedMaster) {
                    unsubscribeToMigratedMaster();
                }
                // subscribe and save unsubscribe handler
                unsubscribeToMigratedMaster = subscribeToMigratedMaster({
                    salon: salonId,
                });
            }
        },
    });
};

const handlers = withHandlers({
    updateAppointmentClient: ({
        salonId,
        date,
        showArchive,
        currentLanguage,
    }) => client => {
        const variables = {
            date: moment(date).format('YYYY-MM-DD'),
            salon: salonId,
            showArchive,
            lang: currentLanguage,
        };
        const data = apollo.cache.readQuery({
            query: GET_SALON_QUERY,
            variables,
        });
        data.viewer.salon.masters.nodes.forEach(master => {
            master.appointments.forEach(appointment => {
                if (appointment.client.id === client.id) {
                    appointment.notes = client.notes;
                }
            });
        });
        apollo.cache.writeQuery({
            query: GET_SALON_QUERY,
            variables,
            data,
        });
    },
    onHeaderClick: ({ setMaster, history, isMaster }) => master => {
        if (!isMaster) {
            setMaster(master);
            history.push('/salon/master-appointments');
        }
    },
    createAppointments: ({
        salonId,
        date,
        showArchive,
        currentLanguage,
    }) => appointments => {
        const data = apollo.cache.readQuery({
            query: GET_SALON_QUERY,
            variables: {
                date: moment(date).format('YYYY-MM-DD'),
                salon: salonId,
                showArchive,
                lang: currentLanguage,
            },
        });

        each(appointments, appointment => {
            // Find master of the appointment
            const master = find(
                data.viewer.salon.masters.nodes,
                i => i.id === appointment.master.id,
            );

            if (master) {
                master.appointments.push({
                    ...appointment,
                    invoice: null,
                });
            }
        });

        apollo.cache.writeQuery({
            query: GET_SALON_QUERY,
            variables: {
                date: moment(date).format('YYYY-MM-DD'),
                salon: salonId,
                showArchive,
                lang: currentLanguage,
            },
            data,
        });
    },
    updateAppointment: ({
        salonId,
        date,
        showArchive,
        currentLanguage,
    }) => appointment => {
        const data = apollo.cache.readQuery({
            query: GET_SALON_QUERY,
            variables: {
                date: moment(date).format('YYYY-MM-DD'),
                salon: salonId,
                showArchive,
                lang: currentLanguage,
            },
        });

        if (
            (showArchive || !appointment.archived) &&
            some(data.viewer.salon.masters.nodes, {
                id: appointment.master.id,
            })
        ) {
            each(data.viewer.salon.masters.nodes, (master, key) => {
                const index = findIndex(master.appointments, [
                    'id',
                    appointment.id,
                ]);
                if (index > -1) {
                    if (appointment.master.id == master.id) {
                        data.viewer.salon.masters.nodes[key].appointments[
                            index
                        ] = appointment;
                    } else {
                        remove(
                            data.viewer.salon.masters.nodes[key].appointments,
                            ['id', appointment.id],
                        );
                    }
                } else {
                    if (appointment.master.id == master.id) {
                        data.viewer.salon.masters.nodes[key].appointments.push(
                            appointment,
                        );
                    }
                }
            });
        }

        apollo.cache.writeQuery({
            query: GET_SALON_QUERY,
            variables: {
                date: moment(date).format('YYYY-MM-DD'),
                salon: salonId,
                showArchive,
                lang: currentLanguage,
            },
            data,
        });
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDipatchToProps,
    ),
    withData,
    withLifecycle(),
    withRouter,
    handlers,
)(MasterTable);
