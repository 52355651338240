/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { List, Loader, Grid } from 'semantic-ui-react';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty } from 'lodash';
import { Table, EllipsisLoader } from '../../../components';
import { FormattedMessage } from 'react-intl';

import ReportSummary from './ReportSummary';
import ReportTable from './ReportTable';

export default ({ report: { revenue, totalCount, items }, loading }) => (
    <Grid columns={1} padded>
        <Grid.Row>
            <Grid.Column>
                <ReportSummary
                    loading={loading}
                    revenue={revenue}
                    totalCount={totalCount}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <ReportTable loading={loading} items={items} />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
