/**
 * @flow
 */

import { connect } from 'react-redux';
import {
    filterBySalons,
    filterByMasters,
    filterByManufacturers,
} from '../../../actions/report';
import { withProps, compose } from 'recompose';
import { map, reduce, filter, includes, union, uniqBy, property } from 'lodash';

import ReportMenu from '../components/ReportMenu';

import type { Dispatch } from '../../../type';

const mapStateToProps = ({ report }) => ({
    masterFilter: report.get('searchQuery'),
    salonFilter: report.get('salonFilter'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onMasterSelect: (e, { value }) => {
        dispatch(filterByMasters(value));
    },
    onSalonSelect: (e, { value }) => {
        dispatch(filterBySalons(value));
    },
    onManufacturerSelect: (e, { value }) => {
        dispatch(filterByManufacturers(value));
    },
});

const mapOptions = withProps(({ salons, salonFilter, manufacturers }) => ({
    salonOptions: uniqBy(
        map(salons, ({ id, name, address }) => ({
            text: name,
            value: id,
            description: address,
        })),
        'value',
    ),

    manufacturerOptions: map(
        salonFilter.length
            ? filter(manufacturers, ({ salon }) => includes(salonFilter, salon))
            : manufacturers,
        ({ name, salonName, id }) => ({
            text: name,
            value: id,
            description: salonName,
        }),
    ),

    masterOptions: reduce(
        salonFilter.length
            ? filter(salons, ({ id }) => includes(salonFilter, id))
            : salons,
        (result, { name, masters: { nodes }, administrators }) =>
            uniqBy(
                [
                    ...result,
                    ...map(
                        [...nodes, ...administrators],
                        ({ id, firstName, lastName }) => ({
                            text: `${firstName} ${lastName}`,
                            value: id,
                            'data-salon': name,
                        }),
                    ),
                ],
                property('value'),
            ),
        [],
    ),
}));

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    mapOptions,
)(ReportMenu);
