/**
 * Initialize apollo provider and return configuration free
 * provider that is ready to be used.
 */
import React from 'react';
import { ApolloProvider as BaseApolloProvider } from 'react-apollo';
import client from '../apollo-client';

const ApolloProvider = ({ children }) => (
    <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
);

export default ApolloProvider;
