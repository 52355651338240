/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, getFormValues, reset } from 'redux-form';
import { compose, withProps, withHandlers, withState } from 'recompose';
import { map, omit, pick, isEqual, keys } from 'lodash';
import uuid from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';

import AddCabinetDialog from '../components/AddCabinetDialog';
import withUserLookup from '../../../lib/withUserLookup';
import validate from './form/CabinetFormValidate';
import { GET_CABINETS_QUERY } from './CabinetList';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// GraphQL query to create a new cabinet
export const ADD_CABINET_MUTATION = gql`
    mutation createCabinet($input: CreateCabinetInput!, $lang: String!) {
        createCabinet(input: $input) {
            cabinet {
                id
                name(lang: $lang)
                nameVariants {
                    en
                    ru
                    lv
                }
                serviceGroups {
                    id
                    name(lang: $lang)
                    type
                }
            }
        }
    }
`;

const withData = graphql(ADD_CABINET_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new cabinet
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        ...formData,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistinc response to compensate network latency
                // and add a new cabinet directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    createCabinet: {
                        __typename: 'CreateCabinetPayload',
                        cabinet: {
                            __typename: 'Cabinet',
                            id: uuid.v4(),
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                            serviceGroups: map(
                                formData.serviceGroups,
                                serviceGroup => ({
                                    __typename: 'ServiceGroups',
                                    id: serviceGroup,
                                    name: '',
                                    type: '',
                                }),
                            ),
                            ...omit(formData, ['serviceGroups']),
                        },
                    },
                },
                // Update local store with a new cabinet
                update: (store, { data: { createCabinet } }) => {
                    const data = store.readQuery({
                        query: GET_CABINETS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            lang: ownProps.currentLanguage,
                        },
                    });

                    data.viewer.salon.cabinets.push(createCabinet.cabinet);

                    store.writeQuery({
                        query: GET_CABINETS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after cabinet was created
                    ownProps.onModalClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'NAME_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    name: intl.formatMessage(
                                        intlMessages.nameExistingsError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'addCabinet',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withModalHandlers = withHandlers({
    onModalClose: ({ onClose, setInitialValues, reset }) => () => {
        setInitialValues({});
        reset('addCabinet');
        onClose();
    },
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

export default compose(
    connect(
        ({ user, intl, ...state }) => ({
            salon: user.get('salon'),
            formValues: getFormValues('addCabinet')(state),
            languages: intl.get('languages'),
            defaultLanguage: intl.get('defaultLanguage'),
            currentLanguage: intl.get('locale'),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withModalHandlers,
    withData,
    withForm,
)(AddCabinetDialog);
