/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from 'semantic-ui-react';

const Button = styled(({ uppercased, ...props }) => <BaseButton {...props} />)`
    &.button {
        &.primary {
            box-shadow: inset 0 -2px 0 #2c88b2;
            background-color: #3bb5ed;
            text-shadow: 0 1px 0 #2c88b2;
            transition: background-color .3s, box-shadow .3s;
            &:hover {
                background-color: #35a3d5;
                box-shadow: inset 0 -2px 0 #287aa0;
            }
            &:active {
                background-color: #35a3d5;
                box-shadow: inset 0 -2px 0 #287aa0;
            }
            &:focus {
                background-color: #35a3d5;
                box-shadow: inset 0 -2px 0 #287aa0;
            }
        }
        &.secondary {
            box-shadow: inset 0 -2px 0 #5e5e67;
            background-color: #93939c;
            text-shadow: 0 1px 0 #2c88b2;
            transition: background-color .3s, box-shadow .3s;
            &:hover {
                background-color: #84848c;
                box-shadow: inset 0 -2px 0 #54545c;
            }
            &:active {
                background-color: #84848c;
                box-shadow: inset 0 -2px 0 #54545c;
            }
            &:focus {
                background-color: #84848c;
                box-shadow: inset 0 -2px 0 #54545c;
            }
        }
        &.basic {
            box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
            opacity: .75;
            transition: opacity .3s;
            &.inverted {
                box-shadow: 0 0 0 1px rgba(255,255,255,.5) inset !important;
                &:hover {
                    box-shadow: 0 0 0 1px rgba(255,255,255,.5) inset !important;
                }
            }
            &:hover {
                opacity: 1;
                box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
            }
        }
        ${props => props.uppercased && 'text-transform: uppercase;'}
    }
`;

export default Button;
