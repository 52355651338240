/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';
import { Loader } from 'semantic-ui-react';

import { Table } from '../../../components';
import ReportItem from './ReportItem';

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={7} textAlign="center">
            <FormattedMessage
                id="pages.report.ReportTable.noReports"
                defaultMessage="There is no reports today"
            />
        </Table.Cell>
    </Table.Row>
);

const ReportCell = styled(Table.Cell)`
    padding: 0px !important;
`;

const ReportTable = ({ items }) => (
    <Table celled fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.salon"
                        defaultMessage="Salon"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.address"
                        defaultMessage="Address"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.seller"
                        defaultMessage="Seller"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.product"
                        defaultMessage="Product"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    <FormattedMessage
                        id="pages.report.ReportTable.quantity"
                        defaultMessage="Quantity"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.price"
                        defaultMessage="Price"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.total"
                        defaultMessage="Total"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {items && items.length ? (
                map(items, (item, key) => (
                    <Table.Row key={key}>
                        <ReportCell colSpan={7}>
                            <ReportItem item={item} />
                        </ReportCell>
                    </Table.Row>
                ))
            ) : (
                <EmptyMessage />
            )}
        </Table.Body>
    </Table>
);

// Show spinner while report is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(ReportTable);
