/**
 * Represents working time with information about it's duration.
 *
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { withProps, compose } from 'recompose';
import moment from 'moment';
import { convertMinutesToDateTime } from '../../../../../lib/date';
import { calcEventOffset, calcEventHeight } from '../../../../../lib/schedule';
import withModal from '../../../../../lib/withModal';
import WorkingTimeDialog from '../../../containers/schedule/WorkingTimeDialog';

import {
    CELL_HEIGHT_PX,
    CELL_STEP,
} from '../../../../../components/timetable/Cell';

import type {
    ScheduleSettings,
    WorkingTime,
    ModalDialogTrigger,
    Master,
} from '../../../../../type';

// Minimal height of cell to fit start and end time
const MIN_HEIGHT_FOR_TIME = 80;

type WorkingTimeCardProps = {
    schedule: ScheduleSettings,
    workingTime: WorkingTime,
    master: Master,
    height: number,
    offset: number,
} & ModalDialogTrigger;

const Content = styled.div`
    cursor: pointer;
    position: absolute;
    top: ${props => props.offset + 4}px;
    right: 3px;
    left: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(${props => props.height}px - 8px);
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    color: #333;
    font-size: 12px;
    line-height: 14px;
    user-select: none;

    border-color: ${props => darken(0.03, props.color)};
    background: ${props => transparentize(0.5, props.color)};
`;

const Duration = styled.div`
    font-weight: 600;
`;

const StartAt = styled.div`
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 11px;
`;

const EndAt = styled.div`
    position: absolute;
    bottom: 3px;
    left: 3px;
    font-size: 11px;
`;

const WorkingTimeCard = ({
    workingTime,
    schedule,
    master,
    height,
    offset,
    open,
    onOpen,
    onClose,
}: WorkingTimeCardProps) => (
    <WorkingTimeDialog
        trigger={
            <Content
                color="#C4DEF6"
                offset={offset}
                height={height}
                onClick={onOpen}
            >
                <Duration>
                    {(duration =>
                        `${duration.get('hours')}h ${duration.get(
                            'minutes',
                        )}m`)(
                        moment.duration(
                            workingTime.endAt - workingTime.startAt,
                            'minutes',
                        ),
                    )}
                </Duration>
                {height >= MIN_HEIGHT_FOR_TIME && (
                    <div>
                        <StartAt>
                            {moment
                                .utc()
                                .startOf('day')
                                .add(workingTime.startAt, 'minutes')
                                .format(schedule.format)}
                        </StartAt>
                        <EndAt>
                            {moment
                                .utc()
                                .startOf('day')
                                .add(workingTime.endAt, 'minutes')
                                .format(schedule.format)}
                        </EndAt>
                    </div>
                )}
            </Content>
        }
        onClose={onClose}
        open={open}
        workingTime={workingTime}
        schedule={schedule}
        master={master}
    />
);

const props = withProps(({ workingTime, schedule }) => ({
    offset: calcEventOffset(
        CELL_HEIGHT_PX,
        convertMinutesToDateTime(workingTime.startAt).format(),
        schedule.startAt,
        CELL_STEP,
    ),
    height: calcEventHeight(
        CELL_HEIGHT_PX,
        workingTime.endAt - workingTime.startAt,
        CELL_STEP,
    ),
}));

export default compose(
    withModal,
    props,
)(WorkingTimeCard);
