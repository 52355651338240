/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { uniqBy, property } from 'lodash';

import Timetable from './Timetable';
import AppointmentCell from './AppointmentCell';
import AppointmentCardContainer from './AppointmentCardContainer';
import AppointmentColumn from '../../containers/timetable/AppointmentColumn';
import { Header } from '../../../../components/timetable';
import { MasterTypeIcon } from '../../../../components';

export const renderCards = ({
    provider,
    salon,
    updateAppointmentClient,
    updateAppointment,
    archiveAppointment,
    inCabinet,
}: RenderCardsProps) => {
    if (!provider.appointments.length) {
        return null;
    }

    // fixes flattenChildren error after adding appointment.
    // TODO: need to fix this error in other place, this fix is crutch.
    const appointments = uniqBy(provider.appointments, property('id'));

    // if appointments.client.firstName == '' && appointments.client.lastName == ''
    // here... supposed to be the placeholder for Walk-in Client
    // technically this would be correct, but logic is absent

    return (
        <AppointmentCardContainer
            appointments={appointments}
            salon={salon}
            updateAppointment={updateAppointment}
            updateAppointmentClient={updateAppointmentClient}
            inCabinet={inCabinet}
            archiveAppointment={archiveAppointment}
        />
    );
};

const MasterTable = ({
    salon,
    date,
    allowToAddAppointments,
    updateAppointmentClient,
    onHeaderClick,
    masters,
    updateAppointment,
    ...props
}) => (
    <Timetable
        salon={salon}
        renderColumn={props => (
            <AppointmentColumn
                {...props}
                updateAppointment={updateAppointment}
            />
        )}
        renderCell={props => (
            <AppointmentCell
                key={props.time}
                salon={salon}
                date={date}
                allowToAddAppointments={allowToAddAppointments}
                {...props}
            />
        )}
        renderCards={({ provider }) =>
            renderCards({
                provider,
                salon,
                updateAppointmentClient,
                updateAppointment,
            })
        }
        renderHeader={({ provider }) => (
            <Header
                provider={provider}
                headerText={`${provider.firstName} ${provider.lastName || ''}`}
                onHeaderClick={onHeaderClick}
            >
                <MasterTypeIcon
                    name={`${provider.firstName} ${provider.lastName || ''}`}
                    type={property(['serviceGroups', '0', 'type'])(provider)}
                />
            </Header>
        )}
        providers={masters}
        {...props}
    />
);

export default MasterTable;
