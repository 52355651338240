/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { mapProps } from 'recompose';
import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';

import { FormField, FormLabel } from '../../../../components';
import EmailField from '../../containers/form/EmailField';
import PhoneField from '../../containers/form/PhoneField';
import styles from '../../../../styles';

import type { ReduxForm, Supplier } from '../../../../type';

type SupplierFormProps = {
    onUserLookup: Function,
    resetUserLookup: Function,
    userLookupStatus: string,
    userLookupUser: Supplier,
    readOnly: boolean,
} & ReduxForm;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;
const limitedProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const fields = {
    input: FormField(limitedProps(Input)),
    email: FormField(EmailField),
    phone: FormField(PhoneField),
    password: FormField(({ meta, ...props }) => (
        <Input {...props} type="password" autoComplete="new-password" />
    )),
};

const SupplierForm = ({
    handleSubmit,
    userLookupStatus,
    userLookupUser,
    onUserLookup,
    resetUserLookup,
    readOnly,
    error,
}: SupplierFormProps) => (
    <Form onSubmit={handleSubmit} error={!!error} autoComplete="off">
        {userLookupUser && (
            <Message info>
                <Icon name="info" />
                <FormattedHTMLMessage
                    id="pages.suppliers.supplierForm.supplierExistsMessage"
                    defaultMessage="<strong>{firstName} {lastName}<strong> already exists in our system, you can add it to your salon"
                    values={{
                        firstName: userLookupUser.firstName,
                        lastName: userLookupUser.lastName,
                    }}
                />
            </Message>
        )}
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Form.Group widths="equal">
            <Field
                name="firstName"
                component={fields.input}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.firstName"
                        defaultMessage="First Name"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                readOnly={readOnly}
                simpleValue
                required
            />
            <Field
                name="lastName"
                component={fields.input}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.lastName"
                        defaultMessage="Last Name"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                readOnly={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="email"
                component={fields.email}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.email"
                        defaultMessage="E-mail"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
                required
            />
            <Field
                name="phone"
                component={fields.phone}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.phone"
                        defaultMessage="Phone"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="password"
                component={fields.password}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.password"
                        defaultMessage="Password"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                disabled={readOnly}
                simpleValue
                required
            />
            <Field
                name="repeatPassword"
                component={fields.password}
                label={
                    <FormattedMessage
                        id="pages.suppliers.supplierForm.repeatPassword"
                        defaultMessage="Repeat Password"
                        children={txt => <Label>{txt}</Label>}
                    />
                }
                disabled={readOnly}
                simpleValue
                required
            />
        </Form.Group>
    </Form>
);

export default SupplierForm;
