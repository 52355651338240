/**
 * Represents link that opens dialog with master details
 *
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import withModal from '../../../lib/withModal';

import { MasterDetails } from './details';

import type { ModalDialogTrigger, Master } from '../../../type';

type MasterDetailsButtonProps = {
    master: Master,
    children: any,
} & ModalDialogTrigger;

const MasterDetailsButtonStyled = styled.span`
    cursor: pointer;
    display: flex;
    color: #000000;
    transition: color 0.3s;
    align-items: center;
    height: 44px;
    padding: 0px 11px 0px 11px;

    &:hover {
        font-weight: 600;
        color: #35a3d5;
    }
`;

const MasterDetailsButton = ({
    master,
    children,
    open,
    onOpen,
    onClose,
}: MasterDetailsButtonProps) => (
    <MasterDetails
        trigger={
            <MasterDetailsButtonStyled onClick={onOpen}>
                {children}
            </MasterDetailsButtonStyled>
        }
        master={master}
        onClose={onClose}
        open={open}
    />
);

export default withModal(MasterDetailsButton);
