/**
 * @flow
 */

import React from 'react';
import { injectGlobal } from 'styled-components';
import { Field, FieldArray } from 'redux-form';
import { injectIntl, defineMessages } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { compose, withProps } from 'recompose';

import { FormField, FormLabel } from '../../../../components';
import AdministratorSelect from '../../containers/cart/form/AdministratorSelect';
import CartItems from './form/CartItems';

import type { ReduxForm, Intl } from '../../../../type';

// eslint-disable-next-line
injectGlobal`
    form.invoice-paid .ui.disabled {
        &.dropdown,
        &.input,
        &.input :disabled {
            opacity: 1;
        }
    }
`;

const intlMessages = defineMessages({
    administratorLabel: {
        id: 'pages.appointments.cart.form.administrator.label',
        defaultMessage: 'Seller',
    },
    administratorPlaceholder: {
        id: 'pages.appointments.cart.form.administrator.placeholder',
        defaultMessage: 'Select administrator',
    },
    cartItemsLabel: {
        id: 'pages.appointments.cart.form.cartItems',
        defaultMessage: 'Cart Items',
    },
});

type CartFormProps = {
    handleSubmit: Function,
    form: String,
    intl: Intl,
    paid: boolean,
    change: Function,
    invoicePaidCls: string,
} & ReduxForm;

const CartForm = ({
    handleSubmit,
    intl,
    paid,
    form,
    change,
    invoicePaidCls,
}: CartFormProps) => (
    <Form onSubmit={handleSubmit} className={invoicePaidCls}>
        <Field
            name="administrator"
            component={FormField(AdministratorSelect)}
            placeholder={intl.formatMessage(
                intlMessages.administratorPlaceholder,
            )}
            label={
                <FormLabel>
                    {intl.formatMessage(intlMessages.administratorLabel)}
                </FormLabel>
            }
            disabled={paid}
            simpleValue
        />
        <FormLabel>{intl.formatMessage(intlMessages.cartItemsLabel)}</FormLabel>
        <FieldArray
            name="items"
            component={CartItems}
            paid={paid}
            form={form}
            change={change}
        />
    </Form>
);

const withClassName = withProps(({ paid }) => ({
    invoicePaidCls: paid ? 'invoice-paid' : '',
}));

export default compose(
    injectIntl,
    withClassName,
)(CartForm);
