/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';
import { mapProps } from 'recompose';

import { Input, Form } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import BaseCalendar from '../../containers/form/Calendar';

import { ClientSelect } from '../../containers/form';

import CabinetServiceFieldList from '../../containers/form/CabinetServiceFieldList';
import MasterServiceFieldList from '../../containers/form/MasterServiceFieldList';

import styles from '../../../../styles';

import type { ReduxForm, Salon, Appointment, Intl } from '../../../../type';

const intlMessages = {
    client: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.clientPlaceholder',
            defaultMessage: 'Select a client',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.clientLabel',
            defaultMessage: 'Client',
        },
        walkin: {
            id: 'pages.appointments.details.AppointmentDetails.walkinClient',
            defaultMessage: 'Walk-in Client',
        },
    }),

    date: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.datePlaceholder',
            defaultMessage: 'Select an appointment date',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.dateLabel',
            defaultMessage: 'Date',
        },
    }),

    notes: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.notesPlaceholder',
            defaultMessage: 'Write a note (optional)',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.notesLabel',
            defaultMessage: 'Notes',
        },
    }),
};

type AppointmentFormProps = {
    salon: Salon,
    appointment: ?Appointment,
    intl: Intl,
    formValues: Object,
    change: Function,
    defaultMaster: string,
} & ReduxForm;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Calendar = styled(BaseCalendar)`
    & .DayPickerInput-OverlayWrapper {
        top: 46px;
    }
`;

const limitInputProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const limitCalendarProps = mapProps(({ meta, ...props }) => ({
    format: 'DD.MM.YYYY',
    field: meta.field,
    ...props,
}));

const fields = {
    notes: FormField(limitInputProps(Input)),
    calendar: FormField(limitCalendarProps(Calendar)),
    client: FormField(ClientSelect),
};

const CreateAppointmentForm = ({
    formValues,
    handleSubmit,
    submitting,
    onCloseDialog,
    pristine,
    salon,
    intl,
    change,
    defaultMaster,
    defaultCabinet,
    inCabinet,
}: AppointmentFormProps) => (
    <Form onSubmit={handleSubmit}>
        <Form.Group>
            <Field
                name="client"
                component={fields.client}
                label={
                    <Label>
                        {intl.formatMessage(intlMessages.client.label)}
                    </Label>
                }
                placeholder={intl.formatMessage(intlMessages.client.walkin)}
                change={change}
                search
                onCloseDialog={onCloseDialog}
                width={12}
                simpleValue
                // required
                newAppointmentUseCase={true}
            />
            <Field
                name="startDate"
                component={fields.calendar}
                label={
                    <Label>{intl.formatMessage(intlMessages.date.label)}</Label>
                }
                placeholder={intl.formatMessage(intlMessages.date.placeholder)}
                dayPickerProps={{
                    numberOfMonths: 2,
                    fixedWeeks: true,
                }}
                width={4}
                simpleValue
                required
            />
        </Form.Group>
        <Field
            name="notes"
            component={fields.notes}
            label={
                <Label>{intl.formatMessage(intlMessages.notes.label)}</Label>
            }
            placeholder={intl.formatMessage(intlMessages.notes.placeholder)}
            simpleValue
            fluid
        />
        {inCabinet ? (
            <FieldArray
                name="services"
                component={CabinetServiceFieldList}
                change={change}
                defaultCabinet={defaultCabinet}
                withLabel
                multiple
            />
        ) : (
            <FieldArray
                name="services"
                component={MasterServiceFieldList}
                change={change}
                defaultCabinet={defaultCabinet}
                withLabel
                multiple
            />
        )}
    </Form>
);

export default injectIntl(CreateAppointmentForm);
