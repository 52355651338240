/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withHandlers, compose, lifecycle } from 'recompose';
import Measure from 'react-measure';

import { Cell, Timeline, NowLine } from '../../../../components/timetable';
import { CELL_HEIGHT_PX } from '../../../../components/timetable/Cell';
import { Legend } from '../../../appointments';
import { setCellHeight } from '../../../../actions/appointments';

import AppointmentColumn from '../../containers/timetable/AppointmentColumn';

import type {
    ScheduleSettings,
    Master,
    Salon,
    Service,
} from '../../../../type';

import type { TimetableDay } from '../AppointmentTable';

type TimetableProps = {
    loading: boolean,
    date: Object,
    schedule: ScheduleSettings,
    days: Array<TimetableDay>,
    master: Master,
    salon: Salon,
    services: Array<Service>,
    cellHeight: number,
    headerCellHeight: number,
    updateAppointmentClient: Function,
};

const TimetableGrid = styled(Grid)`
    &.grid {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const TimetableContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background: #ffffff;
`;

const LeftSidebar = styled.div`
    flex: 0 0 auto;
    width: 100px;
    border-right: 1px solid #e9e9e9;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const TimelineHeader = styled(Cell)`
    font-size: 12px;
    padding: 0px 14px 0px 37px;
`;

const CellGrid = styled.div`
    flex: 1 1 auto;
    flex-direction: row;
    display: flex;
    overflow: hidden;
`;

const TimetableColumn = styled(Grid.Column)`
    .ui.grid > &.column:not(.row) {
        flex: 1;
        padding-bottom: 0;
    }
`;

const LegendColumn = styled(Grid.Column)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const Timetable = ({
    loading,
    date,
    schedule,
    days,
    master,
    salon,
    services,
    cellHeight,
    headerCellHeight,
    updateAppointmentClient,
    updateDimensions,
}: TimetableProps) => (
    <Measure
        onResize={contentRect => {
            updateDimensions();
        }}
    >
        {({ measureRef }) => (
            <div style={{ height: '100%' }} ref={measureRef}>
                <TimetableGrid className="timetable" columns={1}>
                    <TimetableColumn>
                        {loading && (
                            <Dimmer active inverted>
                                <Loader size="big" />
                            </Dimmer>
                        )}
                        <TimetableContent className="content">
                            <LeftSidebar>
                                <TimelineHeader className="timeline-header" />
                                <Timeline schedule={schedule} date={date} />
                            </LeftSidebar>
                            <CellGrid>
                                {map(days, day => (
                                    <AppointmentColumn
                                        key={day.date}
                                        day={day}
                                        master={master}
                                        schedule={schedule}
                                        salon={salon}
                                        updateAppointmentClient={
                                            updateAppointmentClient
                                        }
                                    />
                                ))}
                                <NowLine
                                    schedule={schedule}
                                    columns={days.length}
                                    date={date}
                                    width={window.innerWidth - 100}
                                    cellHeight={cellHeight}
                                    headerCellHeight={headerCellHeight}
                                />
                            </CellGrid>
                        </TimetableContent>
                    </TimetableColumn>
                    <LegendColumn textAlign="center">
                        <Legend services={services} />
                    </LegendColumn>
                </TimetableGrid>
            </div>
        )}
    </Measure>
);

const mapStateToProps = ({ appointments }) => ({
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
    headerCellHeight: appointments.get('headerCellHeight') || CELL_HEIGHT_PX,
});

const mapDispatchToProps = {
    setCellHeight,
};

const withTimetableLifecycle = lifecycle({
    componentDidMount() {
        window.addEventListener('resize', this.props.updateDimensions);
        this.props.updateDimensions();
    },
    componentWillUnmount() {
        window.removeEventListener('resize', this.props.updateDimensions);
        this.props.setCellHeight(null, null);
    },
    componentDidUpdate(prevProps) {
        const props = this.props;
        if (
            (props.services && !prevProps.services) ||
            (!props.services && prevProps.services) ||
            (props.services &&
                prevProps.services &&
                props.services.length !== prevProps.services.length)
        ) {
            const cell = document.querySelector('.timetable .cell');
            const headerCell = document.querySelector(
                '.timetable .timeline-header',
            );
            if (cell && headerCell) {
                props.setCellHeight(cell.offsetHeight, headerCell.offsetHeight);
            }
        }
    },
});

const handlers = withHandlers({
    updateDimensions: ownProps => () => {
        const cell = document.querySelector('.timetable .cell');
        const headerCell = document.querySelector(
            '.timetable .timeline-header',
        );
        if (cell && headerCell) {
            ownProps.setCellHeight(cell.offsetHeight, headerCell.offsetHeight);
        }
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    handlers,
    withTimetableLifecycle,
)(Timetable);
