/**
 * @flow
 */

import React from 'react';
import { branch, renderComponent } from 'recompose';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

import Timetable from './timetable/Timetable';

import type {
    Salon,
    Master,
    Service,
    Appointment,
    ScheduleSettings,
} from '../../../type';

export type TimetableDay = {
    date: string,
    appointments: Array<Appointment>,
    schedule: Array<ScheduleSettings>,
};

type AppointmentTimetableProps = {
    loading: boolean,
    salon: Salon,
    master: Master,
    date: Object,
    services: Array<Service>,
    days: Array<TimetableDay>,
    updateAppointmentClient: Function,
};

const AppointmentTimetable = ({
    loading,
    salon,
    date,
    services,
    days,
    master,
    updateAppointmentClient,
}: AppointmentTimetableProps) => (
    <Timetable
        loading={loading}
        schedule={salon.settings.schedule}
        date={moment(date).startOf('day')}
        services={services}
        days={days}
        master={master}
        salon={salon}
        updateAppointmentClient={updateAppointmentClient}
    />
);

// Show spinner while loading timetable data
const withSpinner = branch(
    props => !props.salon && props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default withSpinner(AppointmentTimetable);
