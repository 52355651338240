import gql from 'graphql-tag';

export const GET_SEMINARS_QUERY = gql`
    query getSeminars($search: String, $first: Int, $after: String) {
        viewer {
            id
            ... on Supplier {
                seminars(search: $search, first: $first, after: $after) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        cursor
                        node {
                            id
                            ...seminar
                        }
                    }
                }
            }
        }
    }

    fragment seminar on Seminar {
        id
        name
        description
        startAt
        duration
        placeCount
        visitors {
            count
            list {
                master {
                    id
                    firstName
                    lastName
                    email
                    phone
                }
                attendedAt
            }
        }
        type
        banner {
            url
        }
    }
`;

export const ADD_SEMINAR_QUERY = gql`
    mutation addSeminar($input: AddSeminarInput!) {
        addSeminar(input: $input) {
            seminar {
                id
                name
                description
                startAt
                duration
                placeCount
                visitors {
                    count
                    list {
                        master {
                            id
                            firstName
                            lastName
                            email
                            phone
                        }
                        attendedAt
                    }
                }
                type
                banner {
                    url
                }
            }
        }
    }
`;

export const UPDATE_SEMINAR_QUERY = gql`
    mutation updateSeminar($input: UpdateSeminarInput!) {
        updateSeminar(input: $input) {
            seminar {
                id
                name
                description
                startAt
                duration
                placeCount
                visitors {
                    count
                    list {
                        master {
                            id
                            firstName
                            lastName
                            email
                            phone
                        }
                        attendedAt
                    }
                }
                type
                banner {
                    url
                }
            }
        }
    }
`;

export const DELETE_SEMINAR_QUERY = gql`
    mutation deleteSeminar($input: DeleteSeminarInput!) {
        deleteSeminar(input: $input) {
            seminarId
        }
    }
`;
