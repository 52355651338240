/**
 *  @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '../../../components';

import MembershipForm from './form/MembershipForm';

const CreateMembershipDialog = ({
    trigger,
    open,
    onClose,
    onModalClose,
    handleSubmit,
    submitting,
    pristine,
    change,
    allServices,
    error,
    ...props
}) => {
    return (
        <Modal trigger={trigger} onClose={onModalClose} open={open}>
            <Modal.Header>
                <FormattedMessage
                    id="pages.memberships.form.newMembership"
                    defaultMessage="New membership"
                />
            </Modal.Header>
            <Modal.Content>
                {error && (
                    <Message error>
                        <Icon name="warning sign" />
                        {error}
                    </Message>
                )}
                <MembershipForm
                    change={change}
                    services={allServices}
                    createMode
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={handleSubmit}
                    disabled={submitting || pristine}
                    loading={submitting}
                    primary
                >
                    <FormattedMessage
                        id="pages.memberships.form.save"
                        defaultMessage="Create"
                    />
                </Button>
                <Button onClick={onModalClose} disabled={submitting} basic>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.memberships.form.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateMembershipDialog;
