/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { List, Loader } from 'semantic-ui-react';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';

import { Table, EllipsisLoader } from '../../../components';
import SeminarDetailsButton from './SeminarDetailsButton';
import EditSeminarButton from './EditSeminarButton';
import DeleteSeminarButton from './DeleteSeminarButton';
import type { Seminar, SeminarEdge, PageInfo } from '../../../type';

type SeminarsListProps = {
    seminars: Array<SeminarEdge>,
    loadMore: Function,
    pageInfo: PageInfo,
};

type SeminarRowProps = {
    seminar: Seminar,
};

const LoadMoreSpinner = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={6} textAlign="center">
            <EllipsisLoader size="1em" />
        </Table.Cell>
    </Table.Row>
);
const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={6} textAlign="center">
            <FormattedMessage
                id="pages.seminars.SeminarList.noSeminar"
                defaultMessage={"You don't have seminars yet"}
            />
        </Table.Cell>
    </Table.Row>
);

const SeminarType = ({ type }) => {
    let typeName;

    if (type === 'HAIRDRESSER') {
        typeName = (
            <FormattedMessage
                id="pages.seminars.SeminarList.type.hairdresser"
                defaultMessage="Hairdresser"
            />
        );
    } else if (type === 'MANICURE') {
        typeName = (
            <FormattedMessage
                id="pages.seminars.SeminarList.type.manicyre"
                defaultMessage="Manicure"
            />
        );
    } else if (type === 'COSMETOLOGY') {
        typeName = (
            <FormattedMessage
                id="pages.seminars.SeminarList.type.cosmetology"
                defaultMessage="Cosmetology`"
            />
        );
    } else {
        typeName = (
            <FormattedMessage
                id="pages.seminars.SeminarList.type.all"
                defaultMessage="All"
            />
        );
    }

    return <Table.Cell>{typeName}</Table.Cell>;
};

const SeminarRow = ({ seminar }: SeminarRowProps) => (
    <Table.Row>
        <NameCell>
            <SeminarDetailsButton seminar={seminar}>
                {seminar.name}
            </SeminarDetailsButton>
        </NameCell>
        <Table.Cell>
            {moment(seminar.startAt).format('DD.MM.YYYY HH:mm')}
        </Table.Cell>
        <Table.Cell>
            {moment('0:00', 'HH:mm')
                .add(seminar.duration, 'minutes')
                .format('HH:mm')}
        </Table.Cell>
        <SeminarType type={seminar.type} />
        <Table.Cell textAlign="right">
            {seminar.placeCount - seminar.visitors.count} / {seminar.placeCount}
        </Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditSeminarButton seminar={seminar} />
                </List.Item>
                <List.Item>
                    <DeleteSeminarButton seminar={seminar} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

const SeminarsList = ({ seminars, pageInfo, loadMore }: SeminarsListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.seminars.SeminarList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.seminars.SeminarList.date"
                        defaultMessage="Date"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.seminars.SeminarList.duration"
                        defaultMessage="Duration"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.seminars.SeminarList.type"
                        defaultMessage="Type"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.seminars.SeminarList.placeCount"
                        defaultMessage="Place count (available / total)"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.seminars.SeminarList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <InfiniteScroll
            element="tbody"
            pageStart={0}
            loadMore={loadMore}
            initialLoad={false}
            hasMore={pageInfo.hasNextPage}
            loader={<LoadMoreSpinner key={0} />}
            threshold={1}
            useWindow
        >
            {isEmpty(seminars) ? (
                <EmptyMessage />
            ) : (
                seminars.map(seminar => (
                    <SeminarRow key={seminar.cursor} seminar={seminar.node} />
                ))
            )}
        </InfiniteScroll>
    </Table>
);

const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(SeminarsList);
