import { connect } from 'react-redux';
import { generateToDate } from '../../../../actions/schedule';
import DateRange from '../../../../components/DateRange';

// range for masters schedule generator

const mapStateToProps = ({ schedule }) => ({
    fromDate: schedule.get('secondRangeFrom'),
    toDate: schedule.get('secondRangeTo'),
});

export default connect(
    mapStateToProps,
    { onChange: generateToDate },
)(DateRange);
