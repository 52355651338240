/**
 * @flow
 */

import React from 'react';
import { Select } from 'semantic-ui-react';
import { range, map } from 'lodash';
import moment from 'moment';

const setupTimeSpans = () =>
    // 12 hour range with step 30 minutes including end of range
    map(range((12 * 60) / 30 + 1), i => {
        const fmt = 'HH:mm';
        const time = moment('8:00', fmt)
            .add(i * 30, 'minutes')
            .format(fmt);
        return {
            key: i,
            value: time,
            text: time,
        };
    });

const SeminarTime = ({ meta, ...props }) => (
    <Select {...props} options={setupTimeSpans()} />
);

export default SeminarTime;
