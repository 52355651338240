/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import { Container, Menu, Select as BaseSelect } from 'semantic-ui-react';

import DateRange from '../containers/DateRange';

const intlMessages = {
    salon: defineMessages({
        placeholder: {
            id: 'pages.suppliers.warehouse.salons.placeholder',
            defaultMessage: 'Salons',
        },
    }),
    master: defineMessages({
        placeholder: {
            id: 'pages.suppliers.warehouse.master.placeholder',
            defaultMessage: 'Masters',
        },
    }),
    manufacturers: defineMessages({
        placeholder: {
            id: 'pages.suppliers.warehouse.manufacturers.placeholder',
            defaultMessage: 'Manufacturers',
        },
    }),
};

const Select = styled(BaseSelect)`
    min-width: 300px !important;
`;

const MasterSelect = styled(BaseSelect)`
    min-width: 300px !important;
    .item::after {
        position: static !important;
        content: attr(data-salon) !important;
        display: block;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 10px !important;
        transform: none !important;
        visibility: visible !important;
        width: auto !important;
        height: auto !important;
        border: none !important;
    }
`;

export default ({
    salonFilter,
    masterFilter,
    onSalonSelect,
    onMasterSelect,
    salonOptions,
    manufacturerOptions,
    manufacturerFilter,
    onManufacturerSelect,
    masterOptions,
    intl,
}) => (
    <Menu.Menu position="right">
        <Menu.Item>
            <Select
                placeholder={intl.formatMessage(intlMessages.salon.placeholder)}
                defaultValue={salonFilter}
                onChange={onSalonSelect}
                options={salonOptions}
                search
                multiple
            />
        </Menu.Item>
        <Menu.Item>
            <Select
                placeholder={intl.formatMessage(
                    intlMessages.manufacturers.placeholder,
                )}
                onChange={onManufacturerSelect}
                defaultValue={manufacturerFilter}
                options={manufacturerOptions}
                search
                multiple
            />
        </Menu.Item>
        <Menu.Item>
            <MasterSelect
                placeholder={intl.formatMessage(
                    intlMessages.master.placeholder,
                )}
                defaultValue={masterFilter}
                onChange={onMasterSelect}
                options={masterOptions}
                search
                multiple
            />
        </Menu.Item>
        <Menu.Item>
            <DateRange />
        </Menu.Item>
    </Menu.Menu>
);
