/**
 * Represents link that opens dialog with administrator details
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import { AdministratorDetails } from './details';

import type { ModalDialogTrigger, Administrator } from '../../../type';

type EditAdministratorButtonProps = {
    administrator: Administrator,
    children: any,
} & ModalDialogTrigger;

const EditAdministratorButton = ({
    administrator,
    children,
    open,
    onOpen,
    onClose,
}: EditAdministratorButtonProps) => (
    <AdministratorDetails
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.administrators.administratorsList.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
        administrator={administrator}
        onClose={onClose}
        open={open}
    />
);

export default withModal(EditAdministratorButton);
