/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Segment, Container as BaseContainer } from 'semantic-ui-react';
import { Animation } from '../../../components';
import BaseHeader from './Header';

const appearFromRight = keyframes`
    0% {
       opacity: .5;
       transform: translate3D(100px, 0, 0);
    }
    100% {
       opacity: 1;
       transform: translate3D(0, 0, 0);
    }
`;

const RootSegment = styled(Segment)`
    &.segment {
        padding: 160px 0 168px;
        background-image: url(/images/landing/reporting-bg.jpg) !important;
        background-repeat: no-repeat !important;
        background-position: 27% 50% !important;
    }
`;

const TextSegment = styled(({ animate, ...props }) => <Segment {...props} />)`
    &.segment {
        width: 390px;
        margin-right: 100px !important;
        display: inline-block;
        color: #555555 !important;
        font-size: 16px;
        font-family: inherit;
        ${props => props.animate && `animation: ${appearFromRight} 1s;`}

        > p {
            line-height: 24px;
        }
    }
`;

const Header = styled(BaseHeader)`
    &.header {
        line-height: 36px;
        font-size: 24px;
        font-weight: 600;
        color: #555555 !important;
    }
`;

const Container = styled(BaseContainer)`
    text-align: right;
`;

const intlMessages = defineMessages({
    header: {
        id: 'pages.landing.reporting.header',
        defaultMessage: 'Reporting and Financials made easy',
    },
    more: {
        id: 'pages.landing.reporting.more',
        defaultMessage: 'More about reports...',
    },
    paragraph1: {
        id: 'pages.landing.reporting.paragraph1',
        defaultMessage: `
            Relevant financial information is presented in a structured manner
            and in a form easy to understand. They typically include basic
            financial statements, accompanied by a management analysis.
        `,
    },
    paragraph2: {
        id: 'pages.landing.reporting.paragraph2',
        defaultMessage: `
            For large corporations, these statements may be complex and may
            include an extensive set of footnotes to the financial statements
            and management discussion and analysis.
        `,
    },
});

const ReportingSection = ({ intl }) => (
    <RootSegment vertical basic>
        <Container>
            <Animation>
                <TextSegment textAlign="left" vertical basic>
                    <Header as="h2">
                        {intl.formatMessage(intlMessages.header)}
                    </Header>
                    <p>
                        {intl.formatMessage(intlMessages.paragraph1)}

                        {/* <a href="#more">
                            {intl.formatMessage(
                                intlMessages.more,
                            )}
                            </a> */}
                    </p>
                    <p>{intl.formatMessage(intlMessages.paragraph2)}</p>
                </TextSegment>
            </Animation>
        </Container>
    </RootSegment>
);

export default injectIntl(ReportingSection);
