/**
 * Represents button that opens dialog with confirmation
 * request to delete specified client.
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import DeleteClientDialog from '../containers/DeleteClientDialog';

import type { Client, ModalDialogTrigger } from '../../../type';
import { FormattedMessage } from 'react-intl';

type DeleteClientButtonProps = {
    client: Client,
} & ModalDialogTrigger;

const DeleteClientButton = ({
    client,
    open,
    onOpen,
    onClose,
}: DeleteClientButtonProps) => (
    <DeleteClientDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.clients.DeleteClientButton.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        client={client}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteClientButton);
