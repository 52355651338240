/**
 * @flow
 */

import { mapProps } from 'recompose';
import { property, chain } from 'lodash';

import { Select } from '../../../../components';

const mapCabinets = cabinets =>
    chain(cabinets)
        .uniqBy(property('id'))
        .map(cabinet => ({
            key: cabinet.id,
            value: cabinet.id,
            text: cabinet.name,
        }))
        .filter(property('value'))
        .value();

const withProps = mapProps(
    ({ meta, dispatch, cabinets, change, ...props }) => ({
        ...props,
        options: mapCabinets(cabinets),
    }),
);

export default withProps(Select);
