/**
 * Represents button that opens dialog with confirmation
 * request to delete specified seminar.
 *
 * @flow
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { reset } from 'redux-form';

import withModal from '../../../lib/withModal';
import DeleteSeminarDialog from '../containers/DeleteSeminarDialog';

import type { Seminar, ModalDialogTrigger } from '../../../type';

type DeleteSeminarButtonProps = {
    seminar: Seminar,
} & ModalDialogTrigger;

const DeleteSeminarButton = ({
    seminar,
    open,
    onModalOpen,
    onClose,
}: DeleteSeminarButtonProps) => (
    <DeleteSeminarDialog
        trigger={
            <a onClick={onModalOpen}>
                <FormattedMessage
                    id="pages.seminars.DeleteSeminarButton.label"
                    defaultMessage="Delete"
                />
            </a>
        }
        seminar={seminar}
        open={open}
        onClose={onClose}
    />
);

const handlers = withHandlers({
    onModalOpen: ({ onOpen, reset }) => () => {
        reset('deleteSeminar');
        onOpen();
    },
});

const mapDispatchToProps = { reset };

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withModal,
    handlers,
)(DeleteSeminarButton);
