/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { range } from 'lodash';
import moment from 'moment';
import { DropTarget } from 'react-dnd';
import { find, remove } from 'lodash';

import {
    CELL_HEIGHT_PX,
    CELL_STEP,
} from '../../../../components/timetable/Cell';
import { offsetToTime } from '../../../../lib/schedule';

import { GET_MASTER_APPOINTMENTS_QUERY } from '../queries';
import { GET_WORKING_DAYS } from '../DateRange';
import AppointmentColumn from '../../components/timetable/AppointmentColumn';

const UPDATE_APPOINTMENT_QUERY = gql`
    mutation updateAppointment($input: UpdateAppointmentInput!) {
        updateAppointment(input: $input) {
            appointment {
                id
                master {
                    id
                }
                startAt
                endAt
            }
        }
    }
`;

const updateMasterAppointments = (
    store,
    salon,
    appointment,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master: appointment.master.id,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
    });

    const toUpdate = find(
        data.viewer.salon.master.appointments,
        item => item.id === appointment.id,
    );

    toUpdate.endAt = appointment.endAt;
    toUpdate.startAt = appointment.startAt;

    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master: appointment.master.id,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
        data,
    });
};

const updateWorkingDays = (store, salon, appointment, ownAppointment) => {
    const fromDate = moment().format('YYYY-MM-DD');
    const month = moment(fromDate).format('MM');
    const toDate = moment(month, 'MM')
        .month(parseInt(month, 10) + 5)
        .format('YYYY-MM-DD');
    const data = store.readQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master: appointment.master.id,
            fromDate,
            toDate,
        },
    });

    const oldDate = find(
        data.viewer.salon.workingDays,
        item =>
            item.date === moment(ownAppointment.startAt).format('YYYY-MM-DD'),
    );

    const newDate = find(
        data.viewer.salon.workingDays,
        item => item.date === moment(appointment.startAt).format('YYYY-MM-DD'),
    );

    if (newDate) {
        if (newDate.date !== oldDate.date) {
            if (oldDate.appointmentCount > 1) {
                oldDate.appointmentCount--;
                newDate.appointmentCount++;
            } else {
                remove(
                    data.viewer.salon.workingDays,
                    item => item.date === oldDate.date,
                );
                newDate.appointmentCount++;
            }
        }
    } else {
        if (oldDate.appointmentCount > 1) {
            oldDate.appointmentCount--;
        } else {
            remove(
                data.viewer.salon.workingDays,
                item => item.date === oldDate.date,
            );
        }
        data.viewer.salon.workingDays.push({
            date: moment(appointment.startAt).format('YYYY-MM-DD'),
            appointmentCount: 1,
            __typename: 'WorkingDay',
        });
    }

    store.writeQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master: appointment.master.id,
            fromDate,
            toDate,
        },
        data,
    });
};

const withMutation = graphql(UPDATE_APPOINTMENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDrop: data => {
            const paid = data.appointment.invoice
                ? data.appointment.invoice.paid
                : null;
            if (!paid) {
                return mutate({
                    variables: {
                        input: {
                            appointmentId: data.appointment.id,
                            master: data.master.id,
                            client: data.appointment.client.id,
                            startAt: moment(data.startAt).format(
                                'YYYY-MM-DD HH:mm',
                            ),
                        },
                    },
                    update: (store, { data: { updateAppointment } }) => {
                        const appointment = find(
                            ownProps.master.appointments,
                            item =>
                                item.id === updateAppointment.appointment.id,
                        );
                        updateMasterAppointments(
                            store,
                            ownProps.salon.id,
                            updateAppointment.appointment,
                            ownProps.fromDate,
                            ownProps.toDate,
                            ownProps.showArchive,
                            ownProps.currentLanguage,
                        );
                        updateWorkingDays(
                            store,
                            ownProps.salon.id,
                            updateAppointment.appointment,
                            appointment,
                        );
                    },
                });
            }
        },
    }),
});

const withAppointmentColumnProps = withProps(({ schedule, isOwner }) => ({
    timeSpans: range(schedule.startAt, schedule.endAt, CELL_STEP),
    allowToAddAppointments: date =>
        isOwner || moment().isSameOrBefore(date, 'day'),
}));

const mapStateToProps = ({ appointments, masterAppointments, user }) => ({
    fromDate: masterAppointments.get('fromDate'),
    toDate: masterAppointments.get('toDate'),
    isOwner: user.get('isOwner'),
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
});

// Specify the drop target contract
const target = {
    drop: (props, monitor, component) => {
        const { appointment } = monitor.getItem();
        const time = offsetToTime(
            `${props.day.date} ${moment(appointment.startAt).format('HH:mm')}`,
            props.cellHeight,
            CELL_STEP,
            monitor.getDifferenceFromInitialOffset().y,
            props.schedule.step,
        );
        props.onDrop({
            appointment: appointment,
            master: props.master,
            startAt: time,
        });
    },
};

// Specify which props inject into component
const collect = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
});

export default compose(
    connect(mapStateToProps),
    withMutation,
    withAppointmentColumnProps,
    DropTarget('APPOINTMENT', target, collect),
)(AppointmentColumn);
