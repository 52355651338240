/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'lodash';

import DeleteMasterDialog from '../components/DeleteMasterDialog';

import { GET_MASTERS_QUERY } from './MastersList';

// Query that deletes specified master
const DELETE_MASTER_QUERY = gql`
    mutation deleteMaster($input: DeleteMasterInput!) {
        deleteMaster(input: $input) {
            masterId
        }
    }
`;

const withData = graphql(DELETE_MASTER_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salonId: ownProps.salon,
                        masterId: ownProps.master.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete master before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteMaster: {
                        __typename: 'DeleteMasterPayload',
                        masterId: ownProps.master.id,
                    },
                },
                // Remove deleted master from query cache
                update: (store, { data: { deleteMaster } }) => {
                    const data = store.readQuery({
                        query: GET_MASTERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });

                    remove(
                        data.viewer.salon.masters.nodes,
                        master => master.id === deleteMaster.masterId,
                    );

                    store.writeQuery({
                        query: GET_MASTERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data: data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after master was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when master was not deleted
                });
        },
    }),
});

export default compose(
    connect(({ user }) => ({ salon: user.get('salon') })),
    withData,
)(DeleteMasterDialog);
