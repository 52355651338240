import { reduxForm, SubmissionError } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose, withState } from 'recompose';

import ResetPasswordForm from '../components/ResetPasswordForm';
import validate from './resetPasswordValidator';
import { injectIntl } from 'react-intl';
const withForm = reduxForm({
    form: 'reset-password',
    touchOnBlur: false,
    validate,
});

// GraphQL query to reset user's password
const RESET_PASSWORD_QUERY = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
        }
    }
`;

// Stores state of password reset
const withSuccessState = withState('success', 'setSuccess', false);

// Initiate password recovery procedure
const withData = graphql(RESET_PASSWORD_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: ({ email }) => {
            const mutation = mutate({
                variables: {
                    input: {
                        email,
                    },
                },
            });

            return (
                mutation
                    // Update state of password reset
                    .then(({ data }) => {
                        ownProps.setSuccess(data.resetPassword.success);
                    })
                    // Handle possible errors
                    .catch(error => {
                        const graphQLError =
                            error.graphQLErrors && error.graphQLErrors[0];
                        if (graphQLError && graphQLError.name === 'NotExists') {
                            throw new SubmissionError({
                                email:
                                    'User with specified email address does not exists.',
                            });
                        }

                        throw new SubmissionError({
                            _error: 'Unable to reset your password.',
                        });
                    })
            );
        },
    }),
});

export default compose(
    injectIntl,
    withRouter,
    withSuccessState,
    withData,
    withForm,
)(ResetPasswordForm);
