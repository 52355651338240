import styled from 'styled-components';
import { Select as BaseSelect } from 'semantic-ui-react';

// CSS for large select
const largeSelectCSS = `
    font-size: 1.14285714em !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    min-height: 42.69px !important;
`;

const Select = styled(BaseSelect)`
    ${props => props.size === 'large' && largeSelectCSS}
`;

export default Select;
