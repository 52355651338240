/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Route, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';

import { setShowArchive } from '../../../actions/salon';

type SettingsMenuProps = {
    trigger: Function,
    isOwner: Boolean,
    isSysadmin: Boolean,
    enableArchive: Boolean,
    showArchive: Boolean,
    toggleArchiveDisplay: Function,
};

const StyledDropdown = styled(Dropdown)`
    padding: 11px 0px 11px 0px;
`;
const StyledMenuItem = styled(Menu.Item)`
    .ui.secondary.inverted.menu &.item {
        color: rgba(0, 0, 0, 0.95) !important;
        &:hover {
            color: rgba(0, 0, 0, 0.95) !important;
        }
    }
`;

/**
 * Custom menu item that uses react-router to highlight active item
 * and redirect to menu content.
 */
const MenuItem = ({ to, children, exact }) => (
    <Route
        path={to}
        exact={exact}
        children={({ match }) => (
            <StyledMenuItem as={Link} active={!!match} to={to} link>
                {children}
            </StyledMenuItem>
        )}
    />
);

const SettingsMenu = ({
    trigger,
    isOwner,
    isSysadmin,
    enableArchive,
    showArchive,
    toggleArchiveDisplay,
}: SettingsMenuProps) => (
    <StyledDropdown trigger={trigger} icon={null}>
        <Dropdown.Menu>
            <MenuItem to="/salon/settings" exact>
                <FormattedMessage
                    id="navbar.menu.settings"
                    defaultMessage="Settings"
                />
            </MenuItem>
            <MenuItem to="/salon/cabinets">
                <FormattedMessage
                    id="navbar.menu.cabinets"
                    defaultMessage="Cabinets"
                />
            </MenuItem>
            <MenuItem to="/salon/memberships">
                <FormattedMessage
                    id="navbar.menu.memberships"
                    defaultMessage="Memberships"
                />
            </MenuItem>
            <MenuItem to="/salon/settings/services" exact>
                <FormattedMessage
                    id="navbar.menu.services"
                    defaultMessage="Services"
                />
            </MenuItem>
            <MenuItem to="/salon/settings/manufacturers" exact>
                <FormattedMessage
                    id="navbar.menu.manufacturers"
                    defaultMessage="Manufacturers"
                />
            </MenuItem>
            <MenuItem to="/salon/settings/schedule" exact>
                <FormattedMessage
                    id="navbar.menu.schedule"
                    defaultMessage="Schedule"
                />
            </MenuItem>
            {isOwner && (
                <MenuItem to="/salon/settings/administrators" exact>
                    <FormattedMessage
                        id="navbar.menu.administrators"
                        defaultMessage="Administrators"
                    />
                </MenuItem>
            )}
            {(enableArchive || isOwner || isSysadmin) && (
                <StyledMenuItem link onClick={toggleArchiveDisplay}>
                    {showArchive ? (
                        <FormattedMessage
                            id="navbar.menu.hideArchive"
                            defaultMessage="Hide Archive"
                        />
                    ) : (
                        <FormattedMessage
                            id="navbar.menu.showArchive"
                            defaultMessage="Show Archive"
                        />
                    )}
                </StyledMenuItem>
            )}
            {isOwner && (
                <MenuItem to="/salon/owner-settings" exact>
                    <FormattedMessage
                        id="navbar.menu.ownerSettings"
                        defaultMessage="Owner Settings"
                    />
                </MenuItem>
            )}
        </Dropdown.Menu>
    </StyledDropdown>
);

const mapStateToProps = ({ user, salon }) => ({
    isOwner: user.get('isOwner'),
    isSysadmin: user.get('isSysadmin'),
    enableArchive: salon.get('settings').enableArchive,
    showArchive: salon.get('showArchive'),
});

const mapDispatchToProps = {
    dispatchSetShowArchive: setShowArchive,
};

const handlers = withHandlers({
    toggleArchiveDisplay: ({ showArchive, dispatchSetShowArchive }) => () => {
        dispatchSetShowArchive(!showArchive);
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    handlers,
)(SettingsMenu);
