/**
 * @flow
 */

import React from 'react';
import withModal from '../../../../lib/withModal.js';
import { Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import AddManufacturerDialog from '.././../containers/manufacturers/AddManufacturerDialog';

import type { ModalDialogTrigger, Manufacturer } from '../../../../type';

type AddManufacturerButtonProps = {
    manufacturer: Manufacturer,
} & ModalDialogTrigger;

const AddManufacturerButton = ({
    open,
    onOpen,
    onClose,
}: AddManufacturerButtonProps) => (
    <AddManufacturerDialog
        trigger={
            <Button onClick={onOpen} primary>
                <FormattedMessage
                    id="pages.settings.manufacturers.add"
                    defaultMessage="Add manufacturer"
                />
            </Button>
        }
        open={open}
        onClose={onClose}
    />
);

export default withModal(AddManufacturerButton);
