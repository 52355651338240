/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Menu as BaseMenu } from '../../../../components';
import { FormattedMessage } from 'react-intl';

export type NavigationItems = 'membershipInformation' | 'appointments';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #f9fafb !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
`;

const Navigation = ({ selectedItem, onItemClick }: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="membershipInformation"
            active={selectedItem === 'membershipInformation'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.memberships.form.membershipInfo"
                defaultMessage="Information"
            />
        </BaseMenu.Item>
        <BaseMenu.Item
            name="appointments"
            active={selectedItem === 'appointments'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.clients.navigation.appointments"
                defaultMessage="Appointments"
            />
        </BaseMenu.Item>
    </Menu>
);

export default Navigation;
