/**
 * @flow
 */

import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { map, property } from 'lodash';

import Timetable from '../../../../../components/timetable/Timetable';
import WorkingTimeCell from './WorkingTimeCell';
import WorkingTimeCard from './WorkingTimeCard';
import { Header } from '../../../../../components/timetable';
import { MasterTypeIcon } from '../../../../../components';

import type { Salon, Master } from '../../../../../type';

type ScheduleTimetableProps = {
    loading: boolean,
    salon: Salon,
    date: Object,
};

type RenderCardsProps = {
    master: Master,
    salon: Salon,
};

/**
 * Return list of cards with working times for provided master
 */
const renderCards = ({ provider, salon }: RenderCardsProps) => {
    return map(provider.schedule, workingTime => (
        <WorkingTimeCard
            key={workingTime.id}
            workingTime={workingTime}
            schedule={salon.settings.schedule}
            master={provider}
        />
    ));
};

const ScheduleTimetable = ({
    loading,
    salon,
    date,
    onHeaderClick,
}: ScheduleTimetableProps) => (
    <Timetable
        loading={loading}
        schedule={salon.settings.schedule}
        providers={salon.masters.nodes}
        scrollProps={{ scrollableGrid: true, minColumnWidth: true }}
        date={moment(date)
            .startOf('day')
            .toDate()}
        renderCell={props => <WorkingTimeCell key={props.time} {...props} />}
        renderCards={({ provider }) => renderCards({ provider, salon })}
        renderHeader={({ provider }) => (
            <Header
                provider={provider}
                headerText={`${provider.firstName} ${provider.lastName || ''}`}
                onHeaderClick={onHeaderClick}
            >
                <MasterTypeIcon
                    name={`${provider.firstName} ${provider.lastName || ''}`}
                    type={property(['serviceGroups', '0', 'type'])(provider)}
                />
            </Header>
        )}
    />
);

// Show spinner while loading timetable data
const withSpinner = branch(
    props => !props.salon && props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(ScheduleTimetable);
