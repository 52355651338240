/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { find, remove, filter } from 'lodash';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { reduxForm, SubmissionError } from 'redux-form';

import DeleteAppointmentDialog from '../../components/details/DeleteAppointmentDialog';

import { GET_SALON_QUERY } from '../timetable/MasterTable';
import { GET_MASTER_APPOINTMENTS_QUERY } from '../../../master-appointments/containers/queries';
import { GET_WORKING_DAYS } from '../../../master-appointments/containers/DateRange';

// Query that deletes specified appointment
const DELETE_APPOINTMENT_QUERY = gql`
    mutation deleteAppointment($input: DeleteAppointmentInput!) {
        deleteAppointment(input: $input) {
            appointmentId
        }
    }
`;

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.schedule.form.AppointmentForm.existingsError',
        defaultMessage: 'This appointment does not exist',
    },
    permissionsError: {
        id:
            'pages.appointments.details.DeleteAppointmentDialog.permissionsError',
        defaultMessage:
            'Current user does not have permission to remove paid invoices',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const updateAppointments = (
    store,
    salon,
    appointment,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
            showArchive,
            date: moment(appointment.startAt).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
    });

    // Find master of the appointment
    const master = find(
        data.viewer.salon.masters.nodes,
        i => i.id === appointment.master.id,
    );

    // And remove appointment that we deleted
    const { group } =
        remove(master.appointments, item => item.id === appointment.id)[0] ||
        {};

    if (group) {
        const groupedAppointments = filter(
            master.appointments,
            item => item.group === group,
        );
        if (groupedAppointments.length === 1) {
            Object.assing(groupedAppointments[0], { group: null });
        }
    }

    store.writeQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
            showArchive,
            date: moment(appointment.startAt).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
        data,
    });
};

const updateMasterAppointments = (
    store,
    master,
    salon,
    appointment,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
    });

    const { group } =
        remove(
            data.viewer.salon.master.appointments,
            item => item.id === appointment.id,
        )[0] || {};

    if (group) {
        const groupedAppointments = filter(
            data.viewer.salon.master.appointments,
            item => item.group === group,
        );
        if (groupedAppointments.length === 1) {
            Object.assing(groupedAppointments[0], { group: null });
        }
    }

    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
        data,
    });
};

const updateWorkingDays = (store, salon, master, appointment) => {
    const fromDate = moment().format('YYYY-MM-DD');
    const month = moment(fromDate).format('MM');
    const toDate = moment(month, 'MM')
        .month(parseInt(month, 10) + 5)
        .format('YYYY-MM-DD');
    const data = store.readQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
    });
    const wDate = find(
        data.viewer.salon.workingDays,
        item => item.date === moment(appointment.startAt).format('YYYY-MM-DD'),
    );
    if (wDate.appointmentCount > 1) {
        wDate.appointmentCount--;
    } else {
        remove(
            data.viewer.salon.workingDays,
            item =>
                item.date === moment(appointment.startAt).format('YYYY-MM-DD'),
        );
    }

    store.writeQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
        data,
    });
};

const withData = graphql(DELETE_APPOINTMENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        userId: ownProps.user,
                        appointmentId: ownProps.appointment.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete appointment before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteAppointment: {
                        __typename: 'DeleteAppointmentPayload',
                        appointmentId: ownProps.appointment.id,
                    },
                },
                // Remove deleted appointment from query cache
                update: store => {
                    if (ownProps.masterAppointments) {
                        updateMasterAppointments(
                            store,
                            ownProps.appointment.master.id,
                            ownProps.salon,
                            ownProps.appointment,
                            ownProps.fromDate,
                            ownProps.toDate,
                            ownProps.showArchive,
                            ownProps.currentLanguage,
                        );

                        updateWorkingDays(
                            store,
                            ownProps.salon,
                            ownProps.appointment.master.id,
                            ownProps.appointment,
                        );
                    }
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog after appointment was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when appointment was not deleted
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'Forbidden' &&
                            graphQLError.data.error ===
                                'USER_DOES_NOT_HAVE_PERMISSION_TO_REMOVE'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.permissionsError,
                                ),
                            });
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'deleteAppointment',
});

export default compose(
    connect(({ user, salon, intl }) => ({
        user: user.get('id'),
        salon: user.get('salon'),
        showArchive: salon.get('showArchive'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    injectIntl,
    withData,
    withForm,
)(DeleteAppointmentDialog);
