/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';

import type { Intl } from '../../../../type';

type StatusProps = {
    onChange: Function,
    value: string,
    intl: Intl,
};

const intlMessages = {
    active: defineMessages({
        label: {
            id: 'pages.staff.form.status.activeLabel',
            defaultMessage: 'Active',
        },
    }),
    inactive: defineMessages({
        label: {
            id: 'pages.staff.form.status.inactiveLabel',
            defaultMessage: 'Inactive',
        },
    }),
};

const FormGroup = styled(Form.Group)`
    .ui.form .fields& {
        margin-bottom: 0;
    }
`;

const InactiveRadio = styled(Form.Radio)`
    label {
        color: #c00 !important;
    }
`;

const Status = ({ onChange, value, intl }: StatusProps) => (
    <FormGroup>
        <Form.Radio
            label={intl.formatMessage(intlMessages.active.label)}
            value="ACTIVE"
            checked={value === 'ACTIVE'}
            onChange={onChange}
        />
        <InactiveRadio
            label={intl.formatMessage(intlMessages.inactive.label)}
            value="INACTIVE"
            checked={value === 'INACTIVE'}
            onChange={onChange}
            color="red"
        />
    </FormGroup>
);

export default injectIntl(Status);
