/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import type { ModalDialog, Client } from '../../../type';

type DeleteClientDialogProps = {
    client: Client,
} & ModalDialog;

const DeleteClientDialog = ({
    client,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteClientDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.clients.DeleteClientDialog.delete"
                defaultMessage="Delete Client"
            />
        </Header>
        <Modal.Content>
            <p>
                <FormattedMessage
                    id="pages.clients.DeleteClientDialog.deleteText"
                    defaultMessage="Are you sure you want to delete {firstName} {lastName}?"
                    values={{
                        firstName: client.firstName,
                        lastName: client.lastName,
                    }}
                />
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.clients.DeleteClientDialog.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.clients.DeleteClientDialog.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteClientDialog;
