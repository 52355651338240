/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, branch, renderComponent, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { isEmpty, reduce, get, sortBy, sumBy } from 'lodash';
import { Loader, Grid } from 'semantic-ui-react';

import { Table } from '../../../../components';
import ServiceReport from '../../containers/service/ServiceReport';
import ServiceReportSummary from '../../containers/service/ServiceReportSummary';

const ServiceReportCell = styled(Table.Cell)`
    padding: 0px !important;
`;

const EditInvoiceHeaderCell = styled(Table.HeaderCell)`
    width: 62px;
`;

const EmptyMessage = ({ columns }) => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={columns} textAlign="center">
            <FormattedMessage
                id="pages.report.ReportTable.noReports"
                defaultMessage="There is no reports today"
            />
        </Table.Cell>
    </Table.Row>
);

const ReportTable = ({
    services,
    displayEditButton,
    sellerReportCellColSpan,
    hasReports,
}) => (
    <Table celled fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.date"
                        defaultMessage="Date"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.client"
                        defaultMessage="Client"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.seller"
                        defaultMessage="Seller"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    <FormattedMessage
                        id="pages.report.ReportTable.quantity"
                        defaultMessage="Quantity"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.price"
                        defaultMessage="Price"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.total"
                        defaultMessage="Total"
                    />
                </Table.HeaderCell>
                {displayEditButton && <EditInvoiceHeaderCell />}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {hasReports ? (
                services.map(
                    service =>
                        !isEmpty(service.report.invoices) && (
                            <Table.Row key={service.id}>
                                <ServiceReportCell
                                    colSpan={sellerReportCellColSpan}
                                >
                                    <ServiceReport
                                        service={service}
                                        displayEditButton={displayEditButton}
                                    />
                                </ServiceReportCell>
                            </Table.Row>
                        ),
                )
            ) : (
                <EmptyMessage columns={sellerReportCellColSpan} />
            )}
        </Table.Body>
    </Table>
);

const ServiceReportTable = ({
    loading,
    services,
    displayEditButton,
    sellerReportCellColSpan,
    hasReports,
}) => (
    <Grid columns={1} padded>
        <Grid.Row>
            <Grid.Column>
                <ServiceReportSummary loading={loading} services={services} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <ReportTable
                    loading={loading}
                    services={services}
                    displayEditButton={displayEditButton}
                    sellerReportCellColSpan={sellerReportCellColSpan}
                    hasReports={hasReports}
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

// Show spinner while report is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

const props = withProps(({ isOwner, services }) => ({
    displayEditButton: isOwner,
    sellerReportCellColSpan: isOwner ? 7 : 6,
    hasReports: !!reduce(
        services,
        (sum, service) => sum + get(service, 'report.invoices', []).length,
        0,
    ),
    services: sortBy(
        services,
        service =>
            0 -
            reduce(
                get(service, 'report.invoices', []),
                (result, invoice) =>
                    result +
                    sumBy(
                        get(invoice, 'items', []),
                        ({ price = 0, quantity = 0 }) => price * quantity,
                    ),
                0,
            ),
    ),
}));

const mapStateToProps = ({ user }) => ({
    isOwner: user.get('isOwner'),
});

export default compose(
    connect(mapStateToProps),
    withSpinner,
    props,
)(ServiceReportTable);
