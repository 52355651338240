/**
 * Customly styled menu
 */

import styled from 'styled-components';
import { Menu as BaseMenu } from 'semantic-ui-react';

const Menu = styled(BaseMenu)`
    &.ui.secondary.menu .item {
       color: #777;
    }

    &.ui.secondary.menu .item:hover {
        color: #35a3d5;
        background: transparent;
    }

    &.ui.secondary.menu .active.item {
        background: #35a3d5;
        border-radius: 2px;
        color: #fff;
        cursor: default;
    }
`;

Menu.Item = BaseMenu.Item;
Menu.Header = BaseMenu.Header;
Menu.Menu = BaseMenu.Menu;

export default Menu;
