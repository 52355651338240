/**
 * @flow
 */

import gql from 'graphql-tag';
import moment from 'moment';
import { map, get, filter } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MembershipAppointments from '../../components/details/MembershipAppointments';

// A query to get appointments for specified client identified by ID
const GET_APPOINTMENTS_QUERY = gql`
    query getMembershipBoughtItems(
        $salon: ID!
        $membershipId: ID!
        $lang: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    memberships {
                        id
                        membershipBoughtItems(id: $membershipId) {
                            id
                            boughtAt
                            paymentType
                            price
                            seller {
                                ... on Master {
                                    id
                                    firstName
                                    lastName
                                }
                                ... on Administrator {
                                    id
                                    firstName
                                    lastName
                                }
                                ... on Cabinet {
                                    id
                                    name(lang: $lang)
                                }
                            }
                            entity {
                                ... on Service {
                                    id
                                    name(lang: $lang)
                                }
                                ... on Product {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_APPOINTMENTS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            membershipId: ownProps.membership.id,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        boughtItems: filter(
            map(
                get(viewer, 'salon.memberships[0].membershipBoughtItems'),
                item => ({
                    id: item.id,
                    boughtAt: moment(item.boughtAt).format('DD.MM.YYYY HH:mm'),
                    price: item.price,
                    name: item.entity.name,
                    firstName: item.seller.firstName || item.seller.name,
                    lastName: item.seller.lastName || '',
                    paymentType: item.paymentType,
                }),
            ),
            i => i.paymentType === 'MEMBERSHIP',
        ),
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
)(MembershipAppointments);
