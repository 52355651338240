/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent, toClass } from 'recompose';
import { isEmpty, each } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { List, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Avatar, EllipsisLoader } from '../../../components';

import ClientDetailsButton from './ClientDetailsButton';
import EditClientButton from './EditClientButton';
import DeleteClientButton from './DeleteClientButton';

import type { Client, ClientEdge, PageInfo } from '../../../type';

type ClientsListProps = {
    clients: Array<ClientEdge>,
    loadMore: Function,
    pageInfo: PageInfo,
};

type ClientRowProps = {
    client: Client,
};

const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

/**
 * Show this message if clients list is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.clients.ClientList.noClients"
                defaultMessage="You didn't added any clients yet."
            />
        </Table.Cell>
    </Table.Row>
);

const LoadMoreSpinner = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <EllipsisLoader size="1em" />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents single client row
 */
const ClientRow = ({ client }: ClientRowProps) => {
    if (client.firstName) {
        return (
            <Table.Row>
                <NameCell>
                    <ClientDetailsButton client={client}>
                        <Avatar
                            name={`${client.firstName} ${client.lastName ||
                                ''}`}
                            size={24}
                            spaced="right"
                            round
                        />
                        <span>
                            {client.firstName} {client.lastName}
                        </span>
                    </ClientDetailsButton>
                </NameCell>
                <Table.Cell>{client.phone}</Table.Cell>
                <Table.Cell>
                    <List horizontal divided>
                        <List.Item>
                            <EditClientButton client={client} />
                        </List.Item>
                        <List.Item>
                            <DeleteClientButton client={client} />
                        </List.Item>
                    </List>
                </Table.Cell>
            </Table.Row>
        );
    } else {
        return null;
    }
};

/**
 * Represents list of all salon clients
 */
const ClientsList = ({ clients, pageInfo, loadMore }: ClientsListProps) => {
    // let mClients = clients.filter(client => client.node.firstName !== '');
    // TO-REMEMBER: with pagination working there is no easy way to do this on frontend side

    return (
        <Table celled selectable striped fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.clients.ClientList.name"
                            defaultMessage="Name"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.clients.ClientList.phone"
                            defaultMessage="Phone"
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id="pages.clients.ClientList.actions"
                            defaultMessage="Actions"
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <InfiniteScroll
                element="tbody"
                pageStart={0}
                loadMore={loadMore}
                initialLoad={false}
                hasMore={pageInfo.hasNextPage}
                loader={<LoadMoreSpinner key={0} />}
                threshold={1}
                useWindow
            >
                {isEmpty(clients) ? (
                    <EmptyMessage />
                ) : (
                    clients.map(client => (
                        <ClientRow
                            key={client.cursor}
                            client={client.node}
                            pageInfo={pageInfo}
                        />
                    ))
                )}
            </InfiniteScroll>
        </Table>
    );
};

// Show spinner while clients list is loading
const withSpinner = branch(
    props => props.loading && isEmpty(props.clients),
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(
    withSpinner,
    toClass,
)(ClientsList);
