import gql from 'graphql-tag';
import { withHandlers } from 'recompose';
import { defineMessages } from 'react-intl';

import client from '../apollo-client';

/**
 * Define service group name, service name and master name translations
 */
const intlMessages = defineMessages({
    serviceGroupHairdresser: {
        id: 'salon.defaults.serviceGroup.hairdresser',
        defaultMessage: 'Group 1',
    },
    serviceGroupCosmetologist: {
        id: 'salon.defaults.serviceGroup.cosmetologist',
        defaultMessage: 'Group 2',
    },
    serviceGroupManicure: {
        id: 'salon.defaults.serviceGroup.manicure',
        defaultMessage: 'Group 3',
    },
    serviceHairdresser: {
        id: 'salon.defaults.service.hairdresser',
        defaultMessage: 'Service 1',
    },
    serviceCosmetologist: {
        id: 'salon.defaults.service.cosmetologist',
        defaultMessage: 'Service 2',
    },
    serviceManicure: {
        id: 'salon.defaults.service.manicure',
        defaultMessage: 'Service 3',
    },
    masterHairdresser: {
        id: 'salon.defaults.master.hairdresser',
        defaultMessage: 'Master 1',
    },
    masterCosmetologist: {
        id: 'salon.defaults.master.cosmetologist',
        defaultMessage: 'Master 2',
    },
    masterManicure: {
        id: 'salon.defaults.master.manicure',
        defaultMessage: 'Master 3',
    },
});

const SETUP_NEW_SALON_MUTATION = gql`
    mutation setupNewSalon($input: NewSalonInput!) {
        setupNewSalon(input: $input) {
            salon {
                id
            }
        }
    }
`;

const SET_OWNER_AS_MASTER_MUTATION = gql`
    mutation setOwnerAsMaster($input: SetOwnerAsMasterInput!) {
        setOwnerAsMaster(input: $input) {
            master {
                id
            }
        }
    }
`;

export default withHandlers({
    /**
     * Setup service groups, services and master for empty salon
     */
    setupNewSalon: ({ intl }) => (salon, callback) => {
        const formatMessage = intl.formatMessage;
        client
            .mutate({
                mutation: SETUP_NEW_SALON_MUTATION,
                variables: {
                    input: {
                        salon,
                        serviceGroups: [
                            {
                                name: formatMessage(
                                    intlMessages.serviceGroupHairdresser,
                                ),
                                type: 'OTHER',
                            },
                        ],
                        services: [
                            {
                                name: formatMessage(
                                    intlMessages.serviceHairdresser,
                                ),
                                color: '#B80000',
                                duration: 30,
                            },
                            {
                                name: formatMessage(
                                    intlMessages.serviceCosmetologist,
                                ),
                                color: '#008B02',
                                duration: 60,
                            },
                            {
                                name: formatMessage(
                                    intlMessages.serviceManicure,
                                ),
                                color: '#004DCF',
                                duration: 120,
                            },
                        ],
                        masters: [
                            formatMessage(intlMessages.masterHairdresser),
                            formatMessage(intlMessages.masterCosmetologist),
                            formatMessage(intlMessages.masterManicure),
                        ],
                    },
                },
            })
            .finally(callback);
    },
    setOwnerAsMaster: () => (salon, user, callback) => {
        client
            .mutate({
                mutation: SET_OWNER_AS_MASTER_MUTATION,
                variables: {
                    input: {
                        salon,
                        user,
                    },
                },
            })
            .finally(callback);
    },
});
