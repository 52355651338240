/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Container, Grid, Menu as BaseMenu } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import AdministratorsList from '../containers/AdministratorsList';
import AddAdministratorButton from './AddAdministratorButton';
import Search from '../containers/Search';

import type { Intl } from '../../../type';

type AdministratorsPageProps = { intl: Intl };

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = defineMessages({
    administratorsSearchPlaceholder: {
        id: 'pages.administrators.administratorsList.search',
        defaultMessage: 'Search...',
    },
});

const AdministratorsPage = ({ intl }: AdministratorsPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.administrators.administratorsList.header"
                        defaultMessage="Administrators"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.administratorsSearchPlaceholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <AddAdministratorButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <AdministratorsList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(AdministratorsPage);
