/**
 * @flow
 */

/**
 * Search inventory that match specified query
 *
 * @param {String} query - The query to search for
 */
export const search = (query: string) => ({
    type: 'INVENTORY.SEARCH',
    query,
});

export const filterByManufacturers = (manufacturers: any) => ({
    type: 'INVENTORY.FILTER.BY.MANUFACTURERS',
    manufacturers,
});

export const filterBySalons = (salons: array) => ({
    type: 'INVENTORY.FILTER.BY.SALONS',
    salons,
});
