/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { ModalDialog, Product } from '../../../type';

const MessageBlock = styled(Message)`
    background-color: transparent !important;
    border: 2px solid #ff4545;
    color: #ff4545 !important;
    font-weight: bold;
    box-shadow: none !important;
`;

type DeleteProductDialogProps = {
    product: Product,
} & ModalDialog;

const DeleteProductDialog = ({
    product,
    trigger,
    open,
    onClose,
    onDelete,
    submitting,
    handleSubmit,
    error,
}: DeleteProductDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.inventory.DeleteProductDialog.deleteProduct"
                defaultMessage="Delete Product"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.inventory.DeleteProductDialog.confirmMessage"
                defaultMessage="Are you sure you want to delete {product} from {manufacturer}?"
                values={{
                    product: product.name,
                    manufacturer: product.manufacturer.name,
                }}
            >
                {message => <p>{message}</p>}
            </FormattedMessage>
            {error && (
                <MessageBlock error>
                    <Icon name="warning sign" />
                    {error}
                </MessageBlock>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.inventory.DeleteProductDialog.no"
                    defaultMessage="No"
                />
            </Button>
            <Button
                onClick={handleSubmit}
                disabled={submitting}
                color="green"
                inverted
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.inventory.DeleteProductDialog.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteProductDialog;
