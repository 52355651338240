/**
 * @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Modal, Button } from '../../../../../components';
import ServiceForm from './ServiceForm';

import type { ModalDialog, ReduxForm } from '../../../../../type';

type AddServiceDialogProps = {
    languages: Array<string>,
    defaultLanguage: string,
} & ModalDialog &
    ReduxForm;

const AddServiceDialog = ({
    trigger,
    open,
    onClose,
    submitting,
    pristine,
    error,
    handleSubmit,
    languages,
    defaultLanguage,
}: AddServiceDialogProps) => (
    <Modal size="small" trigger={trigger} open={open} onClose={onClose}>
        <Modal.Header>
            <FormattedMessage
                id="pages.settings.services.add.addService"
                defaultMessage="Add Service"
            />
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <ServiceForm
                handleSubmit={handleSubmit}
                languages={languages}
                defaultLanguage={defaultLanguage}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                disabled={submitting || pristine}
                loading={submitting}
                onClick={handleSubmit}
                primary
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.services.add.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button disabled={submitting} onClick={onClose} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.services.add.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default AddServiceDialog;
