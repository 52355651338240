import { connect } from 'react-redux';
import { generateFromDate } from '../../../../actions/schedule';
import DateRange from '../../../../components/DateRange';

// range for masters schedule generator

const mapStateToProps = ({ schedule }) => ({
    fromDate: schedule.get('firstRangeFrom'),
    toDate: schedule.get('firstRangeTo'),
});

export default connect(
    mapStateToProps,
    { onChange: generateFromDate },
)(DateRange);
