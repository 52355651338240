/**
 * @flow
 */

import { each, set, isNil, isNumber, isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';
import { isMongoId } from '../../../../lib/formValidation';

const intlMessages = defineMessages({
    atLeastOneError: {
        id: 'pages.suppliers.supplierForm.atLeastOneError',
        defaultMessage: 'Add atleast one item',
    },
    selectSalonError: {
        id: 'pages.suppliers.supplierForm.salonSelectError',
        defaultMessage: 'Select salon',
    },
    selectManufacturerError: {
        id: 'pages.suppliers.supplierForm.selectManufacturerError',
        defaultMessage: 'Select manufacturer',
    },
});

export default (values, { intl }) => {
    const errors = {};

    if (isEmpty(values.items) || isNil(values.items)) {
        set(errors, '_error', intl.formatMessage(intlMessages.atLeastOneError));
    }

    each(values.items, (item, idx) => {
        if (!isMongoId(item.salon)) {
            set(
                errors,
                ['items', idx, 'salon'],
                intl.formatMessage(intlMessages.selectSalonError),
            );
        }

        if (!isMongoId(item.manufacturer)) {
            set(
                errors,
                ['items', idx, 'manufacturer'],
                intl.formatMessage(intlMessages.selectManufacturerError),
            );
        }
    });

    return errors;
};
