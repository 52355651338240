/**
 * @flow
 */

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Menu as BaseMenu } from '../../../../components';
import { FormattedMessage } from 'react-intl';

export type NavigationItems = 'personalInformation' | 'appointments';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
    isMaster: Boolean,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #f9fafb !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
`;

const Navigation = ({
    selectedItem,
    onItemClick,
    isMaster,
}: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="personalInformation"
            active={selectedItem === 'personalInformation'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.clients.navivation.personalInformation"
                defaultMessage="Personal information"
            />
        </BaseMenu.Item>
        {!isMaster && (
            <BaseMenu.Item
                name="appointments"
                active={selectedItem === 'appointments'}
                onClick={onItemClick}
                link
            >
                <FormattedMessage
                    id="pages.clients.navigation.appointments"
                    defaultMessage="Appointments"
                />
            </BaseMenu.Item>
        )}
        {!isMaster && (
            <BaseMenu.Item
                name="masters"
                active={selectedItem === 'masters'}
                onClick={onItemClick}
                link
            >
                <FormattedMessage
                    id="pages.clients.navigation.masters"
                    defaultMessage="Masters"
                />
            </BaseMenu.Item>
        )}
    </Menu>
);

const mapStateToProps = ({ user }) => ({
    isMaster: user.get('isMaster'),
});

export default connect(mapStateToProps)(Navigation);
