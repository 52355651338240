/**
 * @flow
 */

import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { get, find } from 'lodash';

import InvoiceFields from './InvoiceFields';

import type { FieldArray } from 'redux-form';
import type { Invoice } from '../../../../../type';

type InvoiceFieldsListProps = {
    fields: FieldArray,
    readOnly: boolean,
    paid: boolean,
    change: Function,
    invoice: Invoice,
};

const InvoiceFieldsList = ({
    fields,
    readOnly,
    paid,
    change,
    masters,
    cabinets,
    invoice: { items },
    membership,
    allServices,
    isMaster,
}: InvoiceFieldsListProps) => (
    <div>
        {fields.map((field, idx) => (
            <Form.Field key={idx}>
                <InvoiceFields
                    onRemove={() => fields.remove(idx)}
                    field={field}
                    readOnly={
                        readOnly || !!get(items, [idx, 'appointment', 'id'])
                    }
                    paid={paid}
                    change={change}
                    masters={masters}
                    cabinets={cabinets}
                    sellerType={get(items, [idx, 'sellerType'])}
                    membership={membership}
                    invoice={items}
                    paidWithMembership={find(
                        items,
                        i => i.paymentType === 'MEMBERSHIP',
                    )}
                    allServices={allServices}
                />
            </Form.Field>
        ))}
        {!readOnly && !isMaster && (
            <a
                href="#add"
                onClick={e => {
                    e.preventDefault();
                    fields.push({});
                }}
            >
                +{' '}
                <FormattedMessage
                    id="pages.appointments.details.invoice.InvoiceForm.add_item"
                    defaultMessage="Add item"
                />
            </a>
        )}
    </div>
);

export default InvoiceFieldsList;
