/**
 * @flow
 */

import { withHandlers } from 'recompose';
import EmailField from '../../components/form/EmailField';

const handlers = withHandlers({
    /**
     * Handle field onBlur callback to update email address and
     * trigger user lookup event.
     */
    onBlur: ({ onUserLookup, resetUserLookup, value }) => () => {
        if (value) {
            onUserLookup({ email: value, role: 'MASTER' });
        } else {
            resetUserLookup();
        }
    },
});

export default handlers(EmailField);
