/**
 * @flow
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    compose,
    branch,
    renderComponent,
    withProps,
    lifecycle,
    withHandlers,
} from 'recompose';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import Measure from 'react-measure';
import { uniqBy, property, get, filter } from 'lodash';

import Timetable from '../../../../components/timetable/Timetable';
import AppointmentCell from './AppointmentCell';
import AppointmentCardContainer from './AppointmentCardContainer';
import AppointmentColumn from '../../containers/timetable/AppointmentColumn';
import Legend from '../../components/timetable/Legend';
import { setCellHeight } from '../../../../actions/appointments';

import type { Salon, Service } from '../../../../type';

type AppointmentTimetableProps = {
    loading: boolean,
    salon: Salon,
    date: Object,
    services: Array<Service>,
    allowToAddAppointments: boolean,
    updateAppointmentClient: Function,
};

// Insert legend into timetable to make that work
// legend={<Legend services={services} />}

const AppointmentTimetable = ({
    loading,
    salon,
    date,
    services,
    providers,
    allowToAddAppointments,
    updateAppointmentClient,
    updateDimensions,
    onHeaderClick,
    renderColumn,
    renderCell,
    renderCards,
    renderHeader,
    noScheduleMessage,
}: AppointmentTimetableProps) => (
    <Measure
        onResize={contentRect => {
            updateDimensions();
        }}
    >
        {({ measureRef }) => (
            <div style={{ height: '100%' }} ref={measureRef}>
                <Timetable
                    loading={loading}
                    schedule={get(salon, 'settings.schedule', {})}
                    providers={providers}
                    date={moment(date).startOf('day')}
                    renderColumn={renderColumn}
                    renderCell={renderCell}
                    renderCards={renderCards}
                    renderHeader={renderHeader}
                    services={services}
                    noScheduleMessage={noScheduleMessage}
                />
            </div>
        )}
    </Measure>
);

// Show spinner while loading timetable data
const withSpinner = branch(
    props => !props.salon && props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

const componentLifecycle = lifecycle({
    componentDidMount() {
        window.addEventListener('resize', this.props.updateDimensions);
        this.props.updateDimensions();
    },
    componentWillUnmount() {
        window.removeEventListener('resize', this.props.updateDimensions);
        this.props.setCellHeight(null, null);
    },
    componentDidUpdate(prevProps) {
        const props = this.props;
        if (
            (props.services && !prevProps.services) ||
            (!props.services && prevProps.services) ||
            (props.services &&
                prevProps.services &&
                props.services.length !== prevProps.services.length)
        ) {
            const cell = document.querySelector('.timetable .cell');
            const headerCell = document.querySelector(
                '.timetable .timeline-header',
            );
            if (cell && headerCell) {
                props.setCellHeight(cell.offsetHeight, headerCell.offsetHeight);
            }
        }
    },
});

const mapDispatchToProps = {
    setCellHeight,
};

const handlers = withHandlers({
    updateDimensions: ownProps => () => {
        const cell = document.querySelector('.timetable .cell');
        const headerCell = document.querySelector(
            '.timetable .timeline-header',
        );
        if (cell && headerCell) {
            ownProps.setCellHeight(cell.offsetHeight, headerCell.offsetHeight);
        }
    },
});

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withSpinner,
    handlers,
    componentLifecycle,
)(AppointmentTimetable);
