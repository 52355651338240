/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { withProps } from 'recompose';
import { Input as BaseInput, Icon } from 'semantic-ui-react';

const Input = styled(BaseInput)`
    &.disabled {
        &,
        input:disabled,
        .disabled {
            opacity: 1 !important;
            background-color: #fffaf3;
        }
    }
`;

type EmailFieldProps = {
    props: any,
    onUserLookup: Function,
    resetUserLookup: Function,
    userLookupStatus: string,
    meta: Object,
};

const EmailField = ({
    meta,
    userLookupStatus,
    onUserLookup,
    resetUserLookup,
    ...props
}: EmailFieldProps) => (
    <Input
        {...props}
        icon={
            userLookupStatus === 'EXISTS' && (
                <Icon name="checkmark" color="green" inverted circular />
            )
        }
        loading={userLookupStatus === 'CHECKING'}
    />
);

const withClassname = withProps(({ disabled }) => ({
    className: disabled ? 'disabled' : '',
}));

export default withClassname(EmailField);
