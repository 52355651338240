/**
 * @flow
 */

import { mapProps, withProps, compose } from 'recompose';
import moment from 'moment';
import { connect } from 'react-redux';
import { Select } from '../../../../../components';

const options = withProps(({ settings }) => {
    let options = [];
    const d = moment().startOf('day');

    for (let i = settings.step; i <= 240; i += settings.step) {
        options.push({
            value: i,
            text: d.add(settings.step, 'minutes').format('HH:mm'),
        });
    }

    return { options };
});

const limitProps = mapProps(({ meta, settings, dispatch, ...props }) => ({
    ...props,
}));

const mapStateToProps = ({ salon }) => ({
    settings: salon.get('settings'),
});

export default compose(
    connect(mapStateToProps),
    options,
    limitProps,
)(Select);
