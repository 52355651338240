/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Container,
    Grid,
    Menu as BaseMenu,
    Loader,
    Select as BaseSelect,
} from 'semantic-ui-react';
import { defineMessages } from 'react-intl';
import { Divider, PageHeader } from '../../../components';

import DateRange from '../containers/DateRange';
import Checkbox from '../../../components/Checkbox';

import SellerReportTable from '../containers/seller/SellerReportTable';
import ServiceReportTable from '../containers/service/ServiceReportTable';
import ClientRatingTable from '../containers/rating/ClientRatingTable';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = defineMessages({
    checkbox: {
        id: 'pages.inventory.ReportPage.inventory.checkbox',
        defaultMessage: 'Show records from application',
    },
});

const Select = styled(BaseSelect)`
    min-width: 200px !important;
`;

const selectOptions = [
    {
        key: 'all',
        value: 'all',
        text: (
            <FormattedMessage
                id="pages.inventory.ReportPage.inventory.all"
                defaultMessage="All"
            />
        ),
    },
    {
        key: 'products',
        value: 'products',
        text: (
            <FormattedMessage
                id="pages.inventory.ReportPage.inventory.products"
                defaultMessage="Products"
            />
        ),
    },
    {
        key: 'grouped_by_services',
        value: 'grouped_by_services',
        text: (
            <FormattedMessage
                id="pages.inventory.ReportPage.inventory.services"
                defaultMessage="Services"
            />
        ),
    },
    {
        key: 'services',
        value: 'services',
        text: (
            <FormattedMessage
                id="pages.inventory.ReportPage.inventory.servicesByMasters"
                defaultMessage="Services (by masters)"
            />
        ),
    },
    {
        key: 'client_rating',
        value: 'client_rating',
        text: (
            <FormattedMessage
                id="pages.inventory.ReportPage.inventory.clientRating"
                defaultMessage="Client rating"
            />
        ),
    },
];

const ReportPage = ({
    onSetAppRecords,
    onSetReportFilter,
    intl,
    showAppRecords,
    reportFilter,
}) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.inventory.ReportPage.inventory.label"
                        defaultMessage="Report"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Item position="left">
                <Select
                    defaultValue={reportFilter}
                    options={selectOptions}
                    onChange={onSetReportFilter}
                    placeholder="All"
                />
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item position="right">
                    {reportFilter !== 'client_rating' && (
                        <Checkbox
                            label={intl.formatMessage(intlMessages.checkbox)}
                            defaultChecked={showAppRecords}
                            onChange={onSetAppRecords}
                        />
                    )}
                </BaseMenu.Item>
                <BaseMenu.Item>
                    {reportFilter !== 'client_rating' && <DateRange />}
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        {reportFilter === 'client_rating' ? (
            <ClientRatingTable />
        ) : (
            [
                reportFilter === 'grouped_by_services' ? (
                    <ServiceReportTable />
                ) : (
                    <SellerReportTable />
                ),
            ]
        )}
    </Container>
);

// Show spinner while report loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(
    // withSpinner,
    injectIntl,
)(ReportPage);
