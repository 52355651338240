/**
 * @flow
 */

import React from 'react';
import { Select } from 'semantic-ui-react';
import { range, map } from 'lodash';
import moment from 'moment';

const setupTimeSpans = () =>
    // 12 hour range with step 30 minutes including end of range
    map(range(1, (12 * 60) / 30 + 1), i => {
        const fmt = 'HH:mm';
        const value = i * 30;
        const text = moment('0:00', fmt)
            .add(value, 'minutes')
            .format(fmt);
        return {
            key: i,
            value,
            text,
        };
    });

const SeminarDuration = ({ meta, ...props }) => (
    <Select {...props} options={setupTimeSpans()} />
);

export default SeminarDuration;
