/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Form, Grid } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';

import { FormLabel } from '../../../../components';
import CabinetServiceFields from '../../containers/form/CabinetServiceFields';

const intlMessages = {
    cabinet: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.cabinetPlaceholder',
            defaultMessage: 'Cabinet',
        },
    }),
    master: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.masterPlaceholder',
            defaultMessage: 'Master',
        },
    }),
    service: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.servicePlaceholder',
            defaultMessage: 'Service',
        },
    }),
    startTime: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.startTimePlaceholder',
            defaultMessage: 'Time',
        },
    }),
    duration: defineMessages({
        placeholder: {
            id: 'pages.appointments.form.ServiceFields.durationPlaceholder',
            defaultMessage: 'Duration',
        },
    }),
};

type CabinetServiceFieldList = {
    fields: FieldArray,
    readOnly: boolean,
    change: Function,
    defaultCabinet: String,
};

const StyledGrid = styled(Grid)`
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
`;

const StyledColumn = styled(Grid.Column)`
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
    label.required {
        &::after {
            margin: -0.2em 0 0 0.2em;
            content: '*';
            color: #db2828;
        }
    }
`;

const FirstRow = styled(Grid.Row)`
    padding-bottom: 0px !important;
    padding-right: 52px !important;
`;

const LastRow = styled(Grid.Row)`
    padding-top: 0px !important;
`;

const CabinetServiceFieldList = ({
    fields,
    readOnly,
    change,
    defaultCabinet,
    cabinets,
    withLabel,
    intl,
    ...props
}: ServiceFieldsListProps) => (
    <StyledGrid>
        {withLabel && (
            <FirstRow>
                <StyledColumn width={4}>
                    <FormLabel className="required">
                        {intl.formatMessage(intlMessages.cabinet.placeholder)}
                    </FormLabel>
                </StyledColumn>
                <StyledColumn width={4}>
                    <FormLabel className="required">
                        {intl.formatMessage(intlMessages.service.placeholder)}
                    </FormLabel>
                </StyledColumn>
                <StyledColumn width={3}>
                    <FormLabel>
                        {intl.formatMessage(intlMessages.master.placeholder)}
                    </FormLabel>
                </StyledColumn>
                <StyledColumn width={3}>
                    <FormLabel className="required">
                        {intl.formatMessage(intlMessages.duration.placeholder)}
                    </FormLabel>
                </StyledColumn>
                <StyledColumn width={2}>
                    <FormLabel className="required">
                        {intl.formatMessage(intlMessages.startTime.placeholder)}
                    </FormLabel>
                </StyledColumn>
            </FirstRow>
        )}
        <LastRow>
            <StyledColumn width={16}>
                {fields.map((field, idx) => (
                    <Form.Field key={idx}>
                        <CabinetServiceFields
                            onRemove={() => fields.remove(idx)}
                            field={field}
                            readOnly={readOnly}
                            change={change}
                            cabinets={cabinets}
                            disableRemove={withLabel && idx === 0}
                            {...props}
                        />
                    </Form.Field>
                ))}
                <a
                    href="#add"
                    onClick={e => {
                        e.preventDefault();
                        fields.push(
                            defaultCabinet ? { cabinet: defaultCabinet } : {},
                        );
                    }}
                >
                    +{' '}
                    <FormattedMessage
                        id="pages.appointments.form.ServiceFieldsList.add_service"
                        defaultMessage="Add Service"
                    />
                </a>
            </StyledColumn>
        </LastRow>
    </StyledGrid>
);

export default CabinetServiceFieldList;
