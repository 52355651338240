/**
 *  @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { Modal, Button } from '../../../components';

import AdministratorForm from '../components/form/AdministratorForm';

import type { ReduxForm, ModalDialog } from '../../../type';

type AddAdministratorDialogProps = {
    props: any,
} & ModalDialog &
    ReduxForm;

const AddAdministratorDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    ...props
}: AddAdministratorDialogProps) => (
    <Modal trigger={trigger} onClose={onClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.administrators.form.newAdministrator"
                defaultMessage="New administrator"
            />
        </Modal.Header>
        <Modal.Content>
            <AdministratorForm
                {...props}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                mode="add"
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.administrators.form.create"
                    defaultMessage="Create"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.administrators.form.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default AddAdministratorDialog;
