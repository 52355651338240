/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, getFormValues, reset } from 'redux-form';
import {
    compose,
    withProps,
    withHandlers,
    withState,
    lifecycle,
} from 'recompose';
import { map, omit, pick, isEqual, keys } from 'lodash';
import uuid from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';

import AddMasterDialog from '../components/AddMasterDialog';
import withUserLookup from '../../../lib/withUserLookup';
import validate from './form/masterFormValidate';
import { GET_MASTERS_QUERY } from './MastersList';

const intlMessages = defineMessages({
    phoneExistingsError: {
        id: 'pages.staff.form.phoneExistingsError',
        defaultMessage: 'Master with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.staff.form.emailExistingsError',
        defaultMessage: 'Master with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.clients.form.ClientForm.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// GraphQL query to create a new master
export const ADD_MASTERS_MUTATION = gql`
    mutation addMaster($input: AddMasterInput!, $lang: String!) {
        addMaster(input: $input) {
            master {
                id
                firstName
                lastName
                phone
                email
                type
                status
                gender
                serviceGroups {
                    id
                    name(lang: $lang)
                    type
                }
            }
        }
    }
`;

const withData = graphql(ADD_MASTERS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new master
        onSubmit: formData => {
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        ...formData,
                        phone,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistinc response to compensate network latency
                // and add a new master directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    addMaster: {
                        __typename: 'AddMasterPayload',
                        master: {
                            __typename: 'Master',
                            id: uuid.v4(),
                            serviceGroups: map(
                                formData.serviceGroups,
                                serviceGroup => ({
                                    __typename: 'ServiceGroups',
                                    id: serviceGroup,
                                    name: '',
                                    type: '',
                                }),
                            ),
                            email: formData.email || null,
                            phone: phone || null,
                            type: 'LOCAL',
                            ...omit(formData, ['serviceGroups']),
                        },
                    },
                },
                // Update local store with a new master
                update: (store, { data: { addMaster } }) => {
                    const data = store.readQuery({
                        query: GET_MASTERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });

                    data.viewer.salon.masters.nodes.push(addMaster.master);

                    store.writeQuery({
                        query: GET_MASTERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after master was created
                    ownProps.onModalClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (graphQLError.data.error === 'email') {
                                throw new SubmissionError({
                                    email: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const props = withProps(({ userLookupStatus }) => ({
    // Make form as read-only if user exists
    readOnly: userLookupStatus === 'EXISTS',
}));

const withForm = reduxForm({
    form: 'addMaster',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withModalHandlers = withHandlers({
    onModalClose: ({
        onClose,
        resetUserLookup,
        setInitialValues,
        reset,
    }) => () => {
        resetUserLookup();
        setInitialValues({});
        reset('addMaster');
        onClose();
    },
    checkFormPristine: ({ formValues }) => () =>
        isEqual(
            formValues,
            pick(
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '+371',
                    serviceGroups: [],
                    status: 'ACTIVE',
                },
                keys(formValues),
            ),
        ),
    handleClear: ({ resetUserLookup, setInitialValues }) => () => {
        resetUserLookup();
        setInitialValues({});
    },
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

const componentWillReceiveProps = ({
    initialValues,
    setInitialValues,
    open,
    userLookupUser,
    formValues,
}) => {
    if (open) {
        if (userLookupUser) {
            const result = pick(userLookupUser, [
                'email',
                'firstName',
                'lastName',
                'phone',
                'gender',
            ]);
            if (!isEqual(initialValues, result)) {
                setInitialValues(result);
            }
        }
    }
};

export default compose(
    connect(
        ({ user, intl, ...state }) => ({
            salon: user.get('salon'),
            formValues: getFormValues('addMaster')(state),
            currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withUserLookup,
    withModalHandlers,
    withData,
    props,
    withForm,
    lifecycle({
        componentWillReceiveProps,
    }),
)(AddMasterDialog);
