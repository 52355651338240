/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import { mapProps } from 'recompose';
import { Form, Icon, Input, Message } from 'semantic-ui-react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import { Modal, Button, FormField } from '../../../components';

import type { ModalDialog, ReduxForm, Intl } from '../../../type';

type CreateSalonDialogProps = { intl: Intl } & ModalDialog & ReduxForm;

const limitProps = mapProps(({ meta, ...props }) => ({ ...props }));

const fields = {
    input: FormField(limitProps(Input)),
};

const intlMessages = defineMessages({
    salonNamePlaceholder: {
        id: 'navbar.menu.newSalon.salonName',
        defaultMessage: 'Salon name',
    },
    salonAddressPlaceholder: {
        id: 'navbar.menu.newSalon.salonAddress',
        defaultMessage: 'Salon address',
    },
});

const CreateSalonDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    pristine,
    submitting,
    error,
    intl,
}: CreateSalonDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small">
        <Modal.Header>
            <FormattedMessage
                id="navbar.menu.newSalon.header"
                defaultMessage="Create a new salon"
            />
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <Field
                    name="name"
                    component={fields.input}
                    placeholder={intl.formatMessage(
                        intlMessages.salonNamePlaceholder,
                    )}
                    simpleValue
                />
                <Field
                    name="address"
                    component={fields.input}
                    placeholder={intl.formatMessage(
                        intlMessages.salonAddressPlaceholder,
                    )}
                    simpleValue
                />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                loading={submitting}
                disabled={pristine || submitting}
                primary
            >
                <FormattedMessage
                    id="navbar.menu.newSalon.create"
                    defaultMessage="Create"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting}>
                <Icon name="remove" />
                <FormattedMessage
                    id="navbar.menu.newSalon.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default injectIntl(CreateSalonDialog);
