export default {
    fontFace: `
        // Source Sans Pro
        @font-face {
            font-family: 'Source Sans Pro';
            src: local('Source Sans Pro'),
                url('/fonts/sourcesanspro-regular.woff2') format('woff2'),
                url('/fonts/sourcesanspro-regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'Source Sans Pro';
            src: local('Source Sans Pro Semibold'),
                url('/fonts/sourcesanspro-semibold.woff2') format('woff2'),
                url('/fonts/sourcesanspro-semibold.woff') format('woff');
            font-weight: 600;
            font-style: normal;
        }
        @font-face {
            font-family: 'Source Sans Pro';
            src: local('Source Sans Pro Bold'),
                url('/fonts/sourcesanspro-bold.woff2') format('woff2'),
                url('/fonts/sourcesanspro-bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
        }

        // Proxima Nova
        @font-face {
            font-family: 'Proxima Nova';
            src: local('Proxima Nova Regular'),
                url('/fonts/proximanova-regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: 'Proxima Nova';
            src: local('Proxima Nova Semibold'),
                url('/fonts/proximanova-semibold.woff') format('woff');
            font-weight: 600;
            font-style: normal;
        }
        @font-face {
            font-family: 'Proxima Nova';
            src: local('Proxima Nova Bold'),
                url('/fonts/proximanova-bold.woff') format('woff');
            font-weight: bold;
            font-style: normal;
        }
    `,

    sansProFontFamily: `
        font-family: "Source Sans Pro", sans-serif;
    `,
    proximaNovaFontFamily: `
        font-family: "Proxima Nova", sans-serif;
    `,
};
