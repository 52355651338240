import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import {
    chain,
    reduce,
    filter,
    property,
    flatten,
    map,
    isEmpty,
    forEach,
} from 'lodash';

import ServiceReportSummary from '../../components/service/ServiceReportSummary';

const getRevenue = items => {
    const sumFn = (sum, item) => item.quantity * item.price + sum;
    const total = reduce(items, sumFn, 0);
    const cash = reduce(
        filter(items, item => item.paymentType === 'CASH'),
        sumFn,
        0,
    );
    const gift = reduce(
        filter(items, item => item.paymentType === 'GIFT'),
        sumFn,
        0,
    );
    const membership = reduce(
        filter(items, item => item.paymentType === 'MEMBERSHIP'),
        sumFn,
        0,
    );
    return {
        total,
        cash,
        gift,
        membership,
        cc: total - cash - gift - membership,
    };
};
const mapStateToProps = ({ report }) => ({
    showAppRecords: report.get('showAppRecords'),
});
const sumQuantity = items =>
    reduce(items, (sum, item) => sum + item.quantity, 0);

const props = withProps(({ services, showAppRecords }) => {
    let invoices = flatten(map(services, property('report.invoices')));

    if (showAppRecords) {
        invoices = filter(
            invoices,
            ({ appointments }) =>
                !isEmpty(appointments) &&
                appointments[0].authorRole !== 'ADMIN',
        );
    }

    const items = flatten(map(invoices, property('items')));

    return {
        summary: {
            totalCount: sumQuantity(items),
            revenue: getRevenue(items),
        },
    };
});
export default compose(
    connect(
        mapStateToProps,
        null,
    ),
    props,
)(ServiceReportSummary);
