/**
 * @flow
 */

import React from 'react';
import { Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const SeminarType = ({ meta, ...props }) => (
    <Select
        {...props}
        options={[
            {
                key: 'ALL',
                value: 'ALL',
                text: (
                    <FormattedMessage
                        id="pages.seminars.AddSeminarDialog.type.all"
                        defaultMessage="All"
                    />
                ),
            },
            {
                key: 'HAIRDRESSER',
                value: 'HAIRDRESSER',
                text: (
                    <FormattedMessage
                        id="pages.seminars.AddSeminarDialog.type.hairdresser"
                        defaultMessage="Hairdresser"
                    />
                ),
            },
            {
                key: 'MANICURE',
                value: 'MANICURE',
                text: (
                    <FormattedMessage
                        id="pages.seminars.AddSeminarDialog.type.manicure"
                        defaultMessage="Manicure"
                    />
                ),
            },
            {
                key: 'COSMETOLOGY',
                value: 'COSMETOLOGY',
                text: (
                    <FormattedMessage
                        id="pages.seminars.AddSeminarDialog.type.cosmetology"
                        defaultMessage="Cosmetology"
                    />
                ),
            },
        ]}
    />
);

export default SeminarType;
