/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Button as BaseButton } from '../../../components';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import AddAdministratorDialog from '../containers/AddAdministratorDialog';

const Button = styled(BaseButton)`
    min-width: 160px;
`;

const AddAdministratorButton = ({ open, onClose, onOpen }) => (
    <AddAdministratorDialog
        open={open}
        onClose={onClose}
        trigger={
            <Button onClick={onOpen} labelPosition="left" primary icon>
                <Icon name="add" />
                <FormattedMessage
                    id="pages.administrators.administratorsList.newUser"
                    defaultMessage="Create new user"
                />
            </Button>
        }
    />
);

export default withModal(AddAdministratorButton);
