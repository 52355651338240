/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';

import { Container, Grid, Menu as BaseMenu } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import ProductsList from '../containers/ProductsList';
import AddProductButton from './AddProductButton';
import Search from '../containers/Search';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import ManufacturerFilterSelect from '../containers/ManufacturerFilterSelect';

const intlMessages = {
    search: defineMessages({
        placeholder: {
            id: 'pages.inventory.InventoryPage.search.placeholder',
            defaultMessage: 'Search...',
        },
    }),
    manufacturerFilter: defineMessages({
        placeholder: {
            id: 'pages.inventory.inventoryPage.manufacturerFilter.placeholder',
            defaultMessage: 'Manufacturers',
        },
    }),
};

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const InventoryPage = ({ intl }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.inventory.InventoryPage.inventory.label"
                        defaultMessage="Inventory"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <ManufacturerFilterSelect
                        placeholder={intl.formatMessage(
                            intlMessages.manufacturerFilter.placeholder,
                        )}
                        multiple
                    />
                </BaseMenu.Item>
                <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.search.placeholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <AddProductButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <ProductsList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(InventoryPage);
