import styled from 'styled-components';
import Header from './Header';

const PageHeader = styled(Header)`
    &.header {
        line-height: 36px !important;
        font-size: 24px !important;
        font-weight: 600;
    }
`;

export default PageHeader;
