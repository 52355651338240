/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';

import { Container, Grid, Menu as BaseMenu, Header } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import ClientsList from '../containers/ClientsList';
import CreateClientButton from './CreateClientButton';
import Search from '../containers/Search';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = {
    search: defineMessages({
        placeholder: {
            id: 'pages.clients.ClientPage.search.placeholder',
            defaultMessage: 'Search...',
        },
    }),
};

const SubHeaderSpan = styled.span`
    margin-right: 20px;
`;

const ClientsPage = ({ intl, summary, loading }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.clients.ClientPage.clients"
                        defaultMessage="Clients"
                    />
                    <Header.Subheader>
                        <SubHeaderSpan>
                            <FormattedMessage
                                id="pages.clients.ClientPage.stats.males"
                                defaultMessage="Males"
                            />
                            <span> - </span>
                            {summary.males}
                        </SubHeaderSpan>
                        <SubHeaderSpan>
                            <FormattedMessage
                                id="pages.clients.ClientPage.stats.females"
                                defaultMessage="Females"
                            />
                            <span> - </span>
                            {summary.females}
                        </SubHeaderSpan>
                        <SubHeaderSpan>
                            <FormattedMessage
                                id="pages.clients.ClientPage.stats.total"
                                defaultMessage="Total"
                            />
                            <span> - </span>
                            {summary.total}
                        </SubHeaderSpan>
                    </Header.Subheader>
                </PageHeader>
            </BaseMenu.Item>

            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.search.placeholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <CreateClientButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <ClientsList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(ClientsPage);
