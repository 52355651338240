/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent, toClass } from 'recompose';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { List, Loader } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';

import { Table, Avatar, EllipsisLoader } from '../../../components';
import SupplierDetailsButton from './SupplierDetailsButton';
import EditSupplierButton from './EditSupplierButton';
import DeleteSupplierButton from './DeleteSupplierButton';

import type { Supplier, SupplierEdge, PageInfo } from '../../../type';

type SupplierListProps = {
    suppliers: Array<SupplierEdge>,
    loadMore: Function,
    pageInfo: PageInfo,
};

type SupplierRowProps = {
    supplier: Supplier,
};

const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.suppliers.supplierList.noSuppliers"
                defaultMessage="You didn't added any suppliers yet."
            />
        </Table.Cell>
    </Table.Row>
);

const LoadMoreSpinner = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <EllipsisLoader size="1em" />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents single supplier row
 */
const SupplierRow = ({ supplier }: SupplierRowProps) => (
    <Table.Row>
        <NameCell>
            <SupplierDetailsButton supplier={supplier}>
                <Avatar
                    name={`${supplier.firstName} ${supplier.lastName || ''}`}
                    size={24}
                    spaced="right"
                    round
                />
                <span>{`${supplier.firstName} ${supplier.lastName}`}</span>
            </SupplierDetailsButton>
        </NameCell>
        <Table.Cell>{supplier.phone}</Table.Cell>
        <Table.Cell>{supplier.email}</Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditSupplierButton supplier={supplier} />
                </List.Item>
                <List.Item>
                    <DeleteSupplierButton supplier={supplier} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents list of all salon suppliers
 */
const SuppliersList = ({
    suppliers,
    pageInfo,
    loadMore,
}: SupplierListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.suppliers.supplierList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.suppliers.supplierList.phone"
                        defaultMessage="Phone"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.suppliers.supplierList.email"
                        defaultMessage="Email"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.suppliers.supplierList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <InfiniteScroll
            element="tbody"
            pageStart={0}
            loadMore={loadMore}
            initialLoad={false}
            hasMore={pageInfo.hasNextPage}
            loader={<LoadMoreSpinner key={0} />}
            threshold={1}
            useWindow
        >
            {isEmpty(suppliers) ? (
                <EmptyMessage />
            ) : (
                suppliers.map(supplier => (
                    <SupplierRow
                        key={supplier.cursor}
                        supplier={supplier.node}
                        pageInfo={pageInfo}
                    />
                ))
            )}
        </InfiniteScroll>
    </Table>
);

// Show spinner while suppliers list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(
    withSpinner,
    toClass,
)(SuppliersList);
