/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Popup, Message, Icon } from 'semantic-ui-react';
import { Menu as BaseMenu } from '../../../../components';
import { FormattedMessage } from 'react-intl';

export type NavigationItems = 'editAppointment';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #f9fafb !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
`;

const Navigation = ({
    selectedItem,
    onItemClick,
    showInvoice,
    disableInvoice,
}: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="editAppointment"
            active={selectedItem === 'editAppointment'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.appointments.details.Navigation.appointment"
                defaultMessage="Edit Appointment"
            />
        </BaseMenu.Item>
        {showInvoice &&
            (disableInvoice ? (
                <Popup
                    style={{ color: 'red', padding: 0 }}
                    trigger={
                        <BaseMenu.Item name="invoice" disabled>
                            <FormattedMessage
                                id="pages.appointments.details.Navigation.invoice"
                                defaultMessage="Invoice"
                            />
                        </BaseMenu.Item>
                    }
                >
                    <Message error>
                        <Icon name="warning sign" />
                        <FormattedMessage
                            id="pages.appointments.details.Navigation.IncompleteActions"
                            defaultMessage="To edit or pay invoice, please apply current appointment changes"
                        />
                    </Message>
                </Popup>
            ) : (
                <BaseMenu.Item
                    name="invoice"
                    active={selectedItem === 'invoice'}
                    onClick={onItemClick}
                    link
                >
                    <FormattedMessage
                        id="pages.appointments.details.Navigation.invoice"
                        defaultMessage="Invoice"
                    />
                </BaseMenu.Item>
            ))}
    </Menu>
);

export default Navigation;
