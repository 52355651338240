/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import Raven from 'raven-js';

import EditManufacturerDialog from '../../components/manufacturers/EditManufacturerDialog';
import validate from './validate';
import { injectIntl, defineMessages } from 'react-intl';
// import { GET_MANUFACTURERS_QUERY } from './ManufacturersList';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.settings.manufacturers.form.existingsError',
        defaultMessage: 'This manufacturer does not exist',
    },
    nameExistingsError: {
        id: 'pages.settings.manufacturers.form.nameExistingsError',
        defaultMessage: 'Manufacturer with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that update existing manufacturer with new data
const UPDATE_MANUFACTURER_QUERY = gql`
    mutation updateManufacturer($input: UpdateManufacturerInput!) {
        updateManufacturer(input: $input) {
            manufacturer {
                id
                name
            }
        }
    }
`;

const withData = graphql(UPDATE_MANUFACTURER_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update manufacturer
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        manufacturerId: ownProps.manufacturer.id,
                        ...formData,
                    },
                },
                // Implement optimistic response to compensate network latency and update
                // manufacturer in local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateManufacturer: {
                        __typename: 'UpdateManufacturerPayload',
                        manufacturer: {
                            __typename: 'Manufacturer',
                            id: ownProps.manufacturer.id,
                            ...formData,
                        },
                    },
                },
                // // Replace old manufacturer in local cache with a new one
                // update: (store, { data: { updateManufacturer } }) => {
                //     const data = store.readQuery({
                //         query: GET_MANUFACTURERS_QUERY,
                //     });
                //     data.manufacturers.forEach(manufacturer => {
                //         if (
                //             manufacturer.id ===
                //             updateManufacturer.manufacturer.id
                //         ) {
                //             manufacturer = updateManufacturer.manufacturer;
                //         }
                //     });
                //     store.writeQuery({ query: GET_MANUFACTURERS_QUERY, data });
                // },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    Raven.captureException(error);

                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                name: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// Init manufacturer form
const withForm = reduxForm({
    form: 'editManufacturer',
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
});

// Populate form with initial values from provided manufacturer object
const withInitialValues = withProps(ownProps => {
    if (ownProps.open) {
        return {
            initialValues: {
                name: ownProps.manufacturer.name,
            },
        };
    }
});

export default compose(
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(EditManufacturerDialog);
