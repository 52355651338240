/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { find, omit } from 'lodash';
import uuid from 'uuid';

import AddServiceDialog from '../../../components/services/service/AddServiceDialog';
import validate from './validate';
import { injectIntl, defineMessages } from 'react-intl';
import { GET_SERVICE_GROUPS_QUERY } from '../ServicesList';
import { prepareFloatForServer } from '../../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.services.add.nameExistingsError',
        defaultMessage: 'Service with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that creates a new service group
export const CREATE_SERVICE_MUTATION = gql`
    mutation createService($input: CreateServiceInput!, $lang: String!) {
        createService(input: $input) {
            service {
                id
                name(lang: $lang)
                nameVariants {
                    lv
                    ru
                    en
                }
                duration
                price
                color
            }
        }
    }
`;

const withData = graphql(CREATE_SERVICE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new service
        onSubmit: formData => {
            const duration = formData.duration.toString();
            const price = prepareFloatForServer(formData.price);
            const mutation = mutate({
                variables: {
                    input: {
                        serviceGroupId: ownProps.serviceGroup.id,
                        duration,
                        price,
                        ...omit(formData, ['duration', 'price']),
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency and add
                // a new service to local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    createService: {
                        __typename: 'CreateServicePayload',
                        service: {
                            __typename: 'Service',
                            serviceGroupId: ownProps.serviceGroup.id,
                            id: uuid.v4(),
                            ...formData,
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                            nameVariants: Object.assign(
                                {
                                    lv: '',
                                    ru: '',
                                    en: '',
                                    __typename: 'ServiceNameVariants',
                                },
                                formData.nameVariants,
                            ),
                        },
                    },
                },
                // Update local store by adding a newly created service
                update: (store, { data: { createService } }) => {
                    const data = store.readQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                    });

                    // Find correct group and push a new service into it
                    const group = find(data.viewer.salon.serviceGroups.nodes, {
                        id: ownProps.serviceGroup.id,
                    });
                    if (group) {
                        group.services.push(createService.service);
                    }

                    store.writeQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                name: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// This allow to reset initial values of the form, even if the form itself is not destroying
const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

// Init service form
const withForm = reduxForm({
    form: 'addService',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
        currentLanguage: intl.get('locale'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddServiceDialog);
