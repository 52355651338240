/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';
import { mapProps } from 'recompose';
import { FormField, FormLabel as BaseFormLabel } from '../../../../components';
import Status from './Status';
import ServiceGroupSelect from '../../containers/form/ServiceGroupSelect';
import EmailField from '../../containers/form/EmailField';
import PhoneField from '../../containers/form/PhoneField';
import Gender from '../../../clients/components/form/Gender';

import styles from '../../../../styles';

import type { ReduxForm, Master } from '../../../../type';

type MasterFormProps = {
    userLookupStatus: string,
    userLookupUser: Master,
    readOnly: boolean,
    onUserLookup: Function,
    resetUserLookup: Function,
} & ReduxForm;

const FormLabel = styled(BaseFormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;

const limitProps = mapProps(({ meta, ...props }) => ({ ...props }));

const fields = {
    input: FormField(limitProps(Input)),
    status: FormField(Status),
    serviceGroup: FormField(ServiceGroupSelect),
    email: FormField(EmailField),
    phone: FormField(PhoneField),
    gender: FormField(Gender),
};

const MasterForm = ({
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    userLookupUser,
    readOnly,
    error,
    onUserLookup,
    resetUserLookup,
}: MasterFormProps) => (
    <Form onSubmit={handleSubmit} error={!!error}>
        {userLookupUser && (
            <Message info>
                <Icon name="info" />
                <strong>
                    {userLookupUser.firstName} {userLookupUser.lastName}
                </strong>{' '}
                <FormattedMessage
                    id="pages.clients.form.ClientForm.clientExistsMessage"
                    defaultMessage="already exists in our system, you can add it to your salon"
                />
            </Message>
        )}
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Form.Group widths="equal">
            <Field
                name="phone"
                component={fields.phone}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.phone"
                            defaultMessage="Phone number"
                        />
                    </FormLabel>
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
            />
            <Field
                name="email"
                component={fields.email}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.email"
                            defaultMessage="E-mail"
                        />
                    </FormLabel>
                }
                onUserLookup={onUserLookup}
                resetUserLookup={resetUserLookup}
                userLookupStatus={userLookupStatus}
                disabled={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="firstName"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.firstName"
                            defaultMessage="First name"
                        />
                    </FormLabel>
                }
                readOnly={readOnly}
                simpleValue
                required
            />
            <Field
                name="lastName"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.lastName"
                            defaultMessage="Last name"
                        />
                    </FormLabel>
                }
                readOnly={readOnly}
                simpleValue
            />
        </Form.Group>
        <Form.Group>
            <Field
                name="serviceGroups"
                component={fields.serviceGroup}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.services"
                            defaultMessage="Services"
                        />
                    </FormLabel>
                }
                multiple
                simpleValue
                required
                width={8}
            />
            <Field
                name="status"
                component={fields.status}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.accountStatus"
                            defaultMessage="Account status"
                        />
                    </FormLabel>
                }
                simpleValue
                required
                width={4}
            />
            <Field
                name="gender"
                component={fields.gender}
                label={
                    <FormattedMessage
                        id="pages.clients.form.ClientForm.gender"
                        defaultMessage="Gender"
                        children={txt => <FormLabel>{txt}</FormLabel>}
                    />
                }
                disabled={readOnly}
                simpleValue
                required
                width={4}
            />
        </Form.Group>
    </Form>
);

export default MasterForm;
