import React from 'react';
import styled from 'styled-components';
import { Select as BaseSelect } from '../../../../components';

const Select = styled(BaseSelect)`
    .description::after {
        content: '\\20AC';
    }
    .item::after {
        content: attr(data-duration);
        display: block;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 10px;
    }
`;

const ServiceSelect = ({ loading, onSearch, ...props }) => (
    <Select search={onSearch} loading={loading} disabled={loading} {...props} />
);

export default ServiceSelect;
