/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Segment, Container, List, Icon, Image } from 'semantic-ui-react';

const RootSegment = styled(Segment)`
    border-top: 1px solid #dee5e7 !important;
`;

const FacebookIcon = styled(Icon)`
    &.facebook.icon {
        color: #455b88;
        box-shadow: 0 0 0 0.1em #455b88 inset;
        margin: 0 0.2em 0 0.2em !important;
    }
`;

const TwitterIcon = styled(Icon)`
    &.twitter.icon {
        color: #6bcae7;
        box-shadow: 0 0 0 0.1em #6bcae7 inset;
        margin: 0 0.2em 0 0.2em !important;
    }
`;

const GoogleIcon = styled(Icon)`
    &.google.plus.icon {
        color: #d7695c;
        box-shadow: 0 0 0 0.1em #d7695c inset;
        margin: 0 0.2em 0 0.2em !important;
    }
`;

const LinkedinIcon = styled(Icon)`
    &.linkedin.icon {
        color: #287ba3;
        box-shadow: 0 0 0 0.1em #287ba3 inset;
        margin: 0 0.2em 0 0.2em !important;
    }
`;

const AppLogo = styled(Image)`
    &.image {
        margin: 2px;
        opacity: 0.65;
    }
`;

const intlMessages = defineMessages({
    home: {
        id: 'pages.landing.footer.home',
        defaultMessage: 'Home',
    },
    features: {
        id: 'pages.landing.footer.features',
        defaultMessage: 'Features',
    },
    pricing: {
        id: 'pages.landing.footer.pricing',
        defaultMessage: 'Pricing',
    },
    about: {
        id: 'pages.landing.footer.about',
        defaultMessage: 'About Us',
    },
    login: {
        id: 'pages.landing.footer.login',
        defaultMessage: 'Login',
    },
    register: {
        id: 'pages.landing.footer.register',
        defaultMessage: 'Register',
    },
});

const FooterSection = ({ intl }) => (
    <RootSegment textAlign="center" secondary vertical basic>
        <Container>
            <Segment vertical basic>
                {/* <List horizontal>
                    <List.Item>
                        <FacebookIcon
                            name="facebook f"
                            size="large"
                            circular
                            link
                        />
                    </List.Item>
                    <List.Item>
                        <TwitterIcon
                            name="twitter"
                            size="large"
                            circular
                            link
                        />
                    </List.Item>
                    <List.Item>
                        <GoogleIcon
                            name="google plus"
                            size="large"
                            circular
                            link
                        />
                    </List.Item>
                    <List.Item>
                        <LinkedinIcon
                            name="linkedin"
                            size="large"
                            circular
                            link
                        />
                    </List.Item>
                </List> */}
                <AppLogo
                    size="small"
                    src="/images/btn-app-store.svg"
                    href="https://apps.apple.com/app/appotime/id1418595516"
                    alt="App store"
                />
                <AppLogo
                    size="small"
                    src="/images/btn-google-play.svg"
                    href="https://apps.apple.com/app/appotime/id1418595516"
                    alt="Play market"
                />
            </Segment>
            <Segment vertical basic>
                {/* <List horizontal divided>
                    <List.Item>
                        <a href="#home">
                            {intl.formatMessage(intlMessages.home)}
                        </a>
                    </List.Item>
                    <List.Item>
                        <a href="#features">
                            {intl.formatMessage(intlMessages.features)}
                        </a>
                    </List.Item>
                    <List.Item>
                        <a href="#pricing">
                            {intl.formatMessage(
                                intlMessages.pricing,
                            )}
                        </a>
                    </List.Item>
                    <List.Item>
                        <a href="#about">
                            {intl.formatMessage(intlMessages.about)}
                        </a>
                    </List.Item>
                    <List.Item>
                        <a href="#login">
                            {intl.formatMessage(intlMessages.login)}
                        </a>
                    </List.Item>
                    <List.Item>
                        <a href="#register">
                            {intl.formatMessage(intlMessages.register)}
                        </a>
                    </List.Item>
                            </List> */}
            </Segment>
        </Container>
    </RootSegment>
);

export default injectIntl(FooterSection);
