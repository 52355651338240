import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ClientsPage from '../components/ClientsPage';

// Query for list of clients for current salon
export const CLIENT_SUMMARY_QUERY = gql`
    query getClientsSummary($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    clients {
                        summary {
                            males
                            females
                            total
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(CLIENT_SUMMARY_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
        },
    }),
    props: ({ data: { viewer, loading } }) => ({
        loading,
        summary: !loading && viewer ? viewer.salon.clients.summary : {},
    }),
});

const mapStateToProps = ({ user }) => ({
    salon: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(ClientsPage);
