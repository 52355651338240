/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Grid, Menu as BaseMenu } from 'semantic-ui-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

import { Divider, PageHeader } from '../../../components';
import SalonList from '../containers/SalonList';
import Search from '../containers/Search';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const SearchContainer = styled(BaseMenu.Item)`
    .ui.menu .fitted.item& {
        padding-right: 9px;
    }
`;

const intlMessages = {
    search: defineMessages({
        placeholder: {
            id: 'pages.salonList.pageHeader.searchPlaceholder',
            defaultMessage: 'Search...',
        },
    }),
};

const SalonListPage = ({ intl }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.salonList.pageHeader.title"
                        defaultMessage="Salon list"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <SearchContainer fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.search.placeholder,
                        )}
                    />
                </SearchContainer>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <SalonList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(SalonListPage);
