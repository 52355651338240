/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type ProductTypeProps = {
    onChange: Function,
    value: string,
};

const FormGroup = styled(Form.Group)`
    margin-bottom: 0 !important;
`;

const ProductType = ({ onChange, value }: ProductTypeProps) => (
    <FormGroup>
        <Form.Radio
            label={
                <FormattedMessage
                    id="pages.inventory.form.ProductType.forSale"
                    defaultMessage="For Sale"
                    tagName="label"
                />
            }
            value="SALE"
            checked={value === 'SALE'}
            onChange={onChange}
        />
        <Form.Radio
            label={
                <FormattedMessage
                    id="pages.inventory.form.ProductType.forUse"
                    defaultMessage="For Use"
                    tagName="label"
                />
            }
            value="INTERNAL"
            checked={value === 'INTERNAL'}
            onChange={onChange}
        />
    </FormGroup>
);

export default ProductType;
