/**
 * @flow
 */

import React from 'react';
import withModal from '../../../../../lib/withModal';
import { FormattedMessage } from 'react-intl';
import EditServiceDialog from '../../../containers/service/service/EditServiceDialog';

import type {
    ServiceGroup,
    Service,
    ModalDialogTrigger,
} from '../../../../../type';

type EditServiceButtonProps = {
    serviceGroup: ServiceGroup,
    service: Service,
} & ModalDialogTrigger;

const EditServiceButton = ({
    open,
    onOpen,
    onClose,
    service,
    serviceGroup,
}: EditServiceButtonProps) => (
    <EditServiceDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.settings.services.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
        open={open}
        onClose={onClose}
        serviceGroup={serviceGroup}
        service={service}
    />
);

export default withModal(EditServiceButton);
