/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';

type PayButtonProps = {
    loading: boolean,
    disabled: boolean,
    onClick: Function,
};

const PayButton = ({ loading, disabled, onClick }: PayButtonProps) => (
    <Button
        onClick={onClick}
        loading={loading}
        disabled={disabled || loading}
        color="green"
        floated="right"
    >
        <Icon name="dollar" />
        <FormattedMessage
            id="pages.appointments.details.invoice.InvoiceForm.pay"
            defaultMessage="Pay"
        />

    </Button>
);

export default PayButton;
