/**
 * @flow
 */

import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { DateUtils } from 'react-day-picker';
import { Calendar } from '../../../containers';

const intlMessages = defineMessages({
    placeholder: {
        id: 'report.DateRange.placeholder',
        defaultMessage: 'Date range',
    },
});

class DateRange extends Component {
    state: {
        from: ?Object,
        to: ?Object,
        enteredTo: ?Object,
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        const { fromDate, toDate } = this.props;
        return {
            from: fromDate,
            to: toDate,
            enteredTo: null,
        };
    }

    isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    };

    onDayClick = day => {
        const { onChange } = this.props;
        const { from, to } = this.state;

        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day,
            });

            if (onChange) {
                onChange(from, day);
            }
        }
    };

    onDayMouseEnter = day => {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    };

    handleResetClick = () => {
        this.setState(this.getInitialState());
    };

    render() {
        const { intl, workingDays } = this.props;
        const { from, to, enteredTo } = this.state;
        const modifiers = {
            start: from,
            end: enteredTo || to,
            workday: workingDays,
        };
        const disabledDays = { before: from };
        const selectedDays = [from, { from, to: enteredTo || to }];

        return (
            <Calendar
                value={from && to ? [from, to] : undefined}
                placeholder={intl.formatMessage(intlMessages.placeholder)}
                iconPosition="left"
                format="MMM D"
                dayPickerProps={{
                    firstDayOfWeek: 1,
                    numberOfMonths: 2,
                    modifiers,
                    disabledDays,
                    selectedDays,
                    onDayMouseEnter: this.onDayMouseEnter,
                    onDayClick: this.onDayClick,
                }}
            />
        );
    }
}

export default injectIntl(DateRange);
