/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { Form, Input, Button } from 'semantic-ui-react';
import { FormField } from '../../../../../components';
import { defineMessages, injectIntl } from 'react-intl';
import { mapProps, withState, compose } from 'recompose';
import {
    TypeField,
    QuantityField,
    EntityField,
    PaymentTypeField,
    PaymentTypeFieldMembership,
} from '../../../containers/details/invoice';

import { MasterSelect, CabinetSelect } from '../../../containers/form';

// flow types
import type { Intl } from '../../../../../type';

import withModal from '../../../../../lib/withModal';
import MembershipDetails from '../../../../memberships/containers/details/MembershipDetails';

const intlMessages = {
    type: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.typePlaceholder',
            defaultMessage: 'Type',
        },
    }),
    item: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.itemPlaceholder',
            defaultMessage: 'Service or Product',
        },
    }),
    seller: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.sellerPlaceholder',
            defaultMessage: 'Seller',
        },
    }),
    quantity: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.quantityPlaceholder',
            defaultMessage: 'Qty.',
        },
    }),
    price: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.pricePlaceholder',
            defaultMessage: 'Price',
        },
    }),
    paymentType: defineMessages({
        placeholder: {
            id:
                'pages.appointments.details.invoice.InvoiceFields.paymentTypePlaceholder',
            defaultMessage: 'Select payment type',
        },
    }),
};

type InvoiceFieldsProps = {
    field: string,
    onRemove: Function,
    readOnly: boolean,
    intl: Intl,
    paid: boolean,
    change: Function,
};

const limitInputProps = mapProps(({ meta, ...props }) => ({ ...props }));

const fields = {
    type: FormField(TypeField),
    entity: FormField(EntityField),
    master: FormField(MasterSelect),
    cabinet: FormField(CabinetSelect),
    quantity: FormField(QuantityField),
    input: FormField(limitInputProps(Input)),
    paymentType: FormField(PaymentTypeField),
    paymentTypeMembership: FormField(PaymentTypeFieldMembership),
};

const RemoveButton = styled(Button)`
    margin-right: 0px !important;
`;

const InfoButtonComponent = styled(Button)`
    &&.disabled {
        box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
    }
`;

const InfoButtonTrigger = withModal(({ open, onOpen, onClose, membership }) => (
    <MembershipDetails
        membership={membership}
        open={open}
        onClose={onClose}
        trigger={
            <InfoButtonComponent
                onClick={e => {
                    e.preventDefault();
                    onOpen();
                }}
                icon="info"
                basic
                disabled={!membership}
            />
        }
    />
));

const InvoiceFields = ({
    field,
    onRemove,
    readOnly,
    intl,
    paid,
    change,
    masters,
    cabinets,
    sellerType,
    membership,
    paidWithMembership,
    allServices,
    setInfoVisible,
    infoVisible,
    invoice,
}: InvoiceFieldsProps) => (
    <Form.Group>
        <Field
            name={`${field}.type`}
            component={fields.type}
            width={3}
            placeholder={intl.formatMessage(intlMessages.type.placeholder)}
            fluid
            simpleValue
            disabled={readOnly}
        />
        <Field
            name={`${field}.entity`}
            component={fields.entity}
            width={4}
            placeholder={intl.formatMessage(intlMessages.item.placeholder)}
            paid={paid}
            change={change}
            fluid
            search
            simpleValue
            disabled={readOnly}
        />
        {sellerType == 'CABINET' ? (
            <Field
                name={`${field}.seller`}
                component={fields.cabinet}
                width={3}
                placeholder={intl.formatMessage(
                    intlMessages.seller.placeholder,
                )}
                change={change}
                fluid
                search
                simpleValue
                disabled={readOnly}
                cabinets={cabinets}
            />
        ) : (
            <Field
                name={`${field}.seller`}
                component={fields.master}
                width={3}
                placeholder={intl.formatMessage(
                    intlMessages.seller.placeholder,
                )}
                change={change}
                fluid
                search
                simpleValue
                disabled={readOnly}
                masters={masters}
            />
        )}
        <Field
            name={`${field}.quantity`}
            component={fields.quantity}
            width={2}
            placeholder={intl.formatMessage(intlMessages.quantity.placeholder)}
            fluid
            simpleValue
            disabled={paid}
        />
        <Field
            name={`${field}.price`}
            component={fields.input}
            width={2}
            placeholder={intl.formatMessage(intlMessages.price.placeholder)}
            fluid
            simpleValue
            disabled={paid}
        />

        <Field
            name={`${field}.paymentType`}
            component={
                membership || paidWithMembership
                    ? fields.paymentTypeMembership
                    : fields.paymentType
            }
            width={2}
            placeholder={intl.formatMessage(
                intlMessages.paymentType.placeholder,
            )}
            fluid
            simpleValue
            disabled={paid}
            membership={membership}
            field={field}
            change={change}
            allServices={allServices}
            setInfoVisible={setInfoVisible}
            invoice={invoice}
        />

        {readOnly && membership && (infoVisible || paidWithMembership) && (
            <Form.Field>
                <InfoButtonTrigger membership={membership} />
            </Form.Field>
        )}

        {!readOnly && (
            <Form.Field>
                <RemoveButton
                    onClick={onRemove}
                    color="red"
                    icon="trash"
                    basic
                />
            </Form.Field>
        )}
    </Form.Group>
);

const state = withState('infoVisible', 'setInfoVisible', false);

export default compose(
    state,
    injectIntl,
)(InvoiceFields);
