import { set, isNil, isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';
import { isValidNumber } from 'libphonenumber-js';

const intlMessages = defineMessages({
    nameError: {
        id: 'pages.cabinets.cabinetForm.nameError',
        defaultMessage: 'Enter name please',
    },
});

export default (values, ownProps) => {
    const { intl, defaultLanguage } = ownProps;
    const errors = {};

    // Validate name
    if (!values.nameVariants || !values.nameVariants[defaultLanguage]) {
        set(
            errors,
            `nameVariants[${defaultLanguage}]`,
            intl.formatMessage(intlMessages.nameError),
        );
    }

    return errors;
};
