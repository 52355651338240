/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { property } from 'lodash';
import { compose, withHandlers } from 'recompose';

import { MasterTypeIcon } from '../../components';
import Cell from './Cell';
import { setMaster } from '../../actions/master-appointments';

const HeaderText = styled.span`
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
`;

const HeaderCell = styled(Cell)`
    padding: 0px 14px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &:hover {
        color: #35a3d5;
        background: #eef8fc;
    }
`;

const Header = ({ headerText, onHeaderClick, children }) => (
    <HeaderCell
        onClick={() => {
            onHeaderClick();
        }}
    >
        {children}
        <HeaderText className="name">{headerText}</HeaderText>
    </HeaderCell>
);

const withHeaderHandlers = withHandlers({
    onHeaderClick: ({ provider, onHeaderClick }) => () => {
        if (onHeaderClick) {
            onHeaderClick(provider);
        }
    },
});

export default compose(withHeaderHandlers)(Header);
