/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Container, Grid, Menu as BaseMenu } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import MastersList from '../containers/MastersList';
import AddMasterButton from './AddMasterButton';
import Search from '../containers/Search';

type StaffPageProps = { intl: Intl };

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const intlMessages = defineMessages({
    staffSearchPlaceholder: {
        id: 'pages.staff.search',
        defaultMessage: 'Search...',
    },
});

const StaffPage = ({ intl }: StaffPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.staff.header"
                        defaultMessage="Staff"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <Search
                        placeholder={intl.formatMessage(
                            intlMessages.staffSearchPlaceholder,
                        )}
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <AddMasterButton />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <MastersList />
            </Grid.Column>
        </Grid>
    </Container>
);

export default injectIntl(StaffPage);
