import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MembershipsPage from '../components/MembershipsPage';
import { GET_MEMBERSHIPS_QUERY } from './queries';

const withData = graphql(GET_MEMBERSHIPS_QUERY, {
    options: ({ salon, currentLanguage }) => ({
        variables: {
            salon,
            lang: currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => ({
        loading,
        memberships: !loading && viewer ? viewer.salon.memberships : [],
    }),
});

const mapStateToProps = ({ user, intl }) => ({
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(MembershipsPage);
