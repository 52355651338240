/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';
import { mapProps } from 'recompose';

import { Input, Form } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import BaseCalendar from '../../containers/form/Calendar';
import {
    CabinetSelect,
    MasterSelect,
    ClientSelect,
    TimeSpanSelect,
    ServiceSelect,
} from '../../containers/form';
import ServiceDurationSelect from '../../../settings/containers/service/service/ServiceDurationSelect';

import CabinetServiceFields from '../../containers/form/CabinetServiceFields';
import MasterServiceFields from '../../containers/form/MasterServiceFields';

import CabinetServiceFieldList from './CabinetServiceFieldList';
import MasterServiceFieldList from './MasterServiceFieldList';

import styles from '../../../../styles';

import type { ReduxForm, Appointment, Intl, Master } from '../../../../type';

const intlMessages = {
    cabinet: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.selectCabinetPlaceholder',
            defaultMessage: 'Search for cabinet or select from list...',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.selectCabinetLabel',
            defaultMessage: 'Cabinet',
        },
    }),
    master: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.selectMasterPlaceholder',
            defaultMessage: 'Search for master or select from list...',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.selectMasterLabel',
            defaultMessage: 'Master',
        },
    }),
    service: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.servicePlaceholder',
            defaultMessage: 'Select a service',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.serviceLabel',
            defaultMessage: 'Service',
        },
    }),
    date: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.datePlaceholder',
            defaultMessage: 'Select an appointment date',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.dateLabel',
            defaultMessage: 'Date',
        },
    }),
    time: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.timePlaceholder',
            defaultMessage: 'Select an appointment time',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.timeLabel',
            defaultMessage: 'Time',
        },
    }),
    duration: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.durationPlaceholder',
            defaultMessage: 'Select an appointment duration',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.durationLabel',
            defaultMessage: 'Duration',
        },
    }),
    client: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.clientPlaceholder',
            defaultMessage: 'Select a client',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.clientLabel',
            defaultMessage: 'Client',
        },
    }),
    notes: defineMessages({
        placeholder: {
            id: 'pages.schedule.form.AppointmentForm.notesPlaceholder',
            defaultMessage: 'Write a note (optional)',
        },
        label: {
            id: 'pages.schedule.form.AppointmentForm.notesLabel',
            defaultMessage: 'Notes',
        },
    }),
};

type AppointmentFormProps = {
    appointment: ?Appointment,
    intl: Intl,
    change: Function,
    updateAppointmentClient: Function,
    masters: Array<Master>,
} & ReduxForm;

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Calendar = styled(BaseCalendar)`
    & .DayPickerInput-OverlayWrapper {
        top: 46px;
    }
`;

const SecondRow = styled.div`
    padding-top: 15px;
`;

const limitInputProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const limitCalendarProps = mapProps(({ meta, onBlur, ...props }) => ({
    format: 'DD.MM.YYYY',
    ...props,
}));

const fields = {
    input: FormField(limitInputProps(Input)),
    calendar: FormField(limitCalendarProps(Calendar)),
    cabinet: FormField(CabinetSelect),
    master: FormField(MasterSelect),
    client: FormField(ClientSelect),
    timeSpan: FormField(TimeSpanSelect),
    service: FormField(ServiceSelect),
    duration: FormField(ServiceDurationSelect),
};

const AppointmentForm = ({
    handleSubmit,
    appointment,
    intl,
    change,
    updateAppointmentClient,
    masters,
    cabinets,
    onCloseDialog,
    inCabinet,
    loading,
    ...props
}: AppointmentFormProps) => (
    <Form onSubmit={handleSubmit}>
        <Form.Group>
            <Field
                name="client"
                component={fields.client}
                label={
                    <Label>
                        {intl.formatMessage(intlMessages.client.label)}
                    </Label>
                }
                placeholder={intl.formatMessage(
                    intlMessages.client.placeholder,
                )}
                change={change}
                updateAppointmentClient={updateAppointmentClient}
                search
                simpleValue
                required
                newAppointmentUseCase={false}
                width={12}
                onCloseDialog={onCloseDialog}
            />
            <Field
                name="startDate"
                component={fields.calendar}
                label={
                    <Label>{intl.formatMessage(intlMessages.date.label)}</Label>
                }
                placeholder={intl.formatMessage(intlMessages.date.placeholder)}
                dayPickerProps={{
                    numberOfMonths: 2,
                    fixedWeeks: true,
                }}
                simpleValue
                required
                disabled={!!(appointment && appointment.group)}
                width={4}
            />
        </Form.Group>
        <Field
            name="notes"
            component={fields.input}
            label={
                <Label>{intl.formatMessage(intlMessages.notes.label)}</Label>
            }
            placeholder={intl.formatMessage(intlMessages.notes.placeholder)}
            simpleValue
        />
        {inCabinet ? (
            <div>
                <CabinetServiceFields
                    change={change}
                    cabinets={cabinets}
                    withLabel
                    loading={loading}
                    appointment={appointment.id}
                    {...props}
                />
                {!appointment.invoice && (
                    <SecondRow>
                        <FieldArray
                            name="services"
                            component={CabinetServiceFieldList}
                            change={change}
                            withLabel={false}
                            loading={loading}
                            cabinets={cabinets}
                        />
                    </SecondRow>
                )}
            </div>
        ) : (
            <div>
                <MasterServiceFields
                    change={change}
                    masters={masters}
                    withLabel
                    loading={loading}
                    appointment={appointment.id}
                    {...props}
                />
                {!appointment.invoice && (
                    <SecondRow>
                        <FieldArray
                            name="services"
                            component={MasterServiceFieldList}
                            change={change}
                            withLabel={false}
                            loading={loading}
                            masters={masters}
                        />
                    </SecondRow>
                )}
            </div>
        )}
    </Form>
);

export default injectIntl(AppointmentForm);
