/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';

import StatisticsPage from '../components/StatisticsPage';

const GET_STATISTIC_QUERY = gql`
    query getStatistics($resolveRole: String) {
        viewer(resolveRole: $resolveRole) {
            id
            ... on Sysadmin {
                salonSummary {
                    total
                    thisMonth
                }
                selfEmployedSummary {
                    total
                    thisMonth
                }
                masterSummary {
                    total
                    thisMonth
                }
                localClientSummary {
                    total
                    thisMonth
                }
                globalClientSummary {
                    total
                    thisMonth
                }
            }
        }
    }
`;

const withData = graphql(GET_STATISTIC_QUERY, {
    options: ownProps => ({
        variables: {
            resolveRole: 'SYSADMIN',
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => ({
        loading,
        summary: !loading && viewer ? viewer : {},
    }),
});

export default compose(withData)(StatisticsPage);
