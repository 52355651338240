/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';

import AddManufacturerDialog from '../../components/manufacturers/AddManufacturerDialog';
import validate from './validate';
import { injectIntl, defineMessages } from 'react-intl';
import { GET_MANUFACTURERS_QUERY } from './ManufacturersList';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.manufacturers.form.nameExistingsError',
        defaultMessage: 'Manufacturer with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that adds a new manufacturer
const ADD_MANUFACTURER_QUERY = gql`
    mutation addManufacturer($input: AddManufacturerInput!) {
        addManufacturer(input: $input) {
            manufacturer {
                id
                name
            }
        }
    }
`;

const withData = graphql(ADD_MANUFACTURER_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and add a new manufacturer
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        ...formData,
                    },
                },
                // Implement optimistic response to compensate network latency and add
                // a new manufacturer to local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    addManufacturer: {
                        __typename: 'AddManufacturerPayload',
                        manufacturer: {
                            __typename: 'Manufacturer',
                            id: uuid.v4(),
                            ...formData,
                        },
                    },
                },
                // Update local store by adding a newly added manufacturer
                update: (store, { data: { addManufacturer } }) => {
                    const data = store.readQuery({
                        query: GET_MANUFACTURERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });

                    data.viewer.salon.manufacturers.nodes.push(
                        addManufacturer.manufacturer,
                    );
                    store.writeQuery({
                        query: GET_MANUFACTURERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                name: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// This allow to reset initial values of the form, even if the form itself is not destroying
const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

// Init manufacturer form
const withForm = reduxForm({
    form: 'addManufacturer',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

export default compose(
    connect(({ user }) => ({
        salon: user.get('salon'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddManufacturerDialog);
