/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

import CreateInvoiceButton from '../../components/details/CreateInvoiceButton';
import { GET_INVOICE_QUERY } from './invoice/AppointmentInvoice';

// A mutation that creates a new invoice
const CREATE_INVOICE_MUTATION = gql`
    mutation createInvoice($input: CreateInvoiceInput!, $lang: String!) {
        createInvoice(input: $input) {
            invoice {
                id
                items {
                    id
                    type
                    entity {
                        ... on Service {
                            id
                            name(lang: $lang)
                        }
                        ... on Product {
                            id
                            name
                        }
                    }
                    seller
                    sellerType
                    quantity
                    price
                    paymentType
                    appointment {
                        id
                    }
                }
                totalPrice
                paid
            }
        }
    }
`;

const withMutation = graphql(CREATE_INVOICE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
        onClick: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        appointment: ownProps.appointment.id,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Add a new invoice to the appointment
                update: (store, { data: { createInvoice } }) => {
                    try {
                        const appointment = ownProps.appointment;

                        const data = store.readQuery({
                            query: GET_INVOICE_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                appointment: appointment.id,
                                lang: ownProps.currentLanguage,
                            },
                        });

                        data.viewer.salon.appointment.invoice =
                            createInvoice.invoice;

                        store.writeQuery({
                            query: GET_INVOICE_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                appointment: appointment.id,
                                lang: ownProps.currentLanguage,
                            },
                            data,
                        });
                    } catch (e) {
                        // It's safe to ignore this exception
                    }
                },
            });

            return mutation
                .then(() => {
                    // Switch to Invoice tab after it was created.
                    ownProps.onClicked();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    throw new Error(
                        'Unable to create invoice. [' +
                            (graphQLError && graphQLError.message) +
                            ']',
                    );
                });
        },
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    injectIntl,
    withMutation,
)(CreateInvoiceButton);
