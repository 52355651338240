import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withProps, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';
import { map } from 'lodash';

// Query for list of service groups avaiable for current salon
const GET_SERVICE_GROUPS_QUERY = gql`
    query getServiceGroups($salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    serviceGroups {
                        nodes {
                            id
                            name(lang: $lang)
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_SERVICE_GROUPS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        serviceGroups: !loading ? viewer.salon.serviceGroups.nodes : [],
    }),
});

/**
 * Map list of received service groups to options in format required for
 * dropdown menu
 */
const withOptions = withProps(({ loading, serviceGroups }) => {
    const options = !loading
        ? map(serviceGroups, group => ({
              value: group.id,
              text: group.name,
          }))
        : [];

    return {
        options,
    };
});

const limitProps = mapProps(
    ({ meta, salon, dispatch, serviceGroups, ...props }) => ({
        ...props,
    }),
);

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
    withOptions,
    limitProps,
)(Select);
