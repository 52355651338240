/**
 * @flow
 */

import { compose, withHandlers, withProps } from 'recompose';
import EmailField from '../../components/form/EmailField';

const props = withProps(() => ({ autoComplete: 'new-password' }));

const handlers = withHandlers({
    /**
     * Handle field onBlur callback to update email address and
     * trigger user lookup event.
     */
    onBlur: ({ onUserLookup, resetUserLookup, value }) => () => {
        if (value) {
            onUserLookup({ email: value, role: 'SUPPLIER' });
        } else {
            resetUserLookup();
        }
    },
});

export default compose(
    props,
    handlers,
)(EmailField);
