/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from '../../../lib/withModal';
import DeleteSupplierDialog from '../containers/DeleteSupplierDialog';
import type { Supplier, ModalDialogTrigger } from '../../../type';

type DeleteSupplierButtonProps = {
    supplier: Supplier,
} & ModalDialogTrigger;

const DeleteSupplierButton = ({
    supplier,
    open,
    onOpen,
    onClose,
}: DeleteSupplierButtonProps) => (
    <DeleteSupplierDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.suppliers.DeleteSupplierButton.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        supplier={supplier}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteSupplierButton);
