import gql from 'graphql-tag';

// Required queries for master access into panel

// Used in MasterTable.js
export const MASTER_TABLE_FRAGMENT = gql`
    fragment onMasterTable on Master {
        salon(id: $salon) {
            id
            name
            administrator {
                id
            }
            settings {
                schedule {
                    startAt
                    endAt
                    step
                    format
                }
                enableArchive
            }
            masters(date: $date) {
                pageInfo {
                    endCursor
                    hasNextPage
                }
                nodes {
                    id
                    ...master
                }
            }
        }
    }
`;

// Used in AppointmentForm.js
export const MASTER_APPOINTMENT_FORM_FRAGMENT = gql`
    fragment onMasterAppointmentForm on Master {
        salon(id: $salon) {
            id
            settings {
                schedule {
                    startAt
                    endAt
                    step
                    format
                }
            }
            masters(deleted: true) @skip(if: $withCabinets) {
                nodes {
                    id
                    ...master
                    freeWorkingTime(
                        date: $date
                        appointment: $appointment
                        showArchive: $showArchive
                    ) {
                        startAt
                        endAt
                    }
                    serviceGroups {
                        id
                        ...serviceGroup
                        cabinets {
                            id
                            ...cabinet
                            freeWorkingTime(
                                date: $date
                                appointment: $appointment
                                showArchive: $showArchive
                            ) {
                                startAt
                                endAt
                            }
                        }
                    }
                }
            }
            master(id: $master) @include(if: $withMaster) {
                id
                ...master
                freeWorkingTime(
                    date: $date
                    appointment: $appointment
                    showArchive: $showArchive
                ) {
                    startAt
                    endAt
                }
                serviceGroups(service: $service) {
                    id
                    ...serviceGroup
                    cabinets {
                        id
                        ...cabinet
                        freeWorkingTime(
                            date: $date
                            appointment: $appointment
                            showArchive: $showArchive
                        ) {
                            startAt
                            endAt
                        }
                    }
                }
            }
            cabinets @include(if: $withCabinets) {
                id
                ...cabinet
                serviceGroups(service: $service) {
                    id
                    ...serviceGroup
                    masters {
                        id
                        ...master
                    }
                }
            }
        }
    }
`;

// Used in AppointmentInvoice.js
export const MASTER_APPOINTMENT_INVOICE_FRAGMENT = gql`
    fragment onMasterAppointmentInvoice on Master {
        salon(id: $salon) {
            id
            appointment(id: $appointment) {
                id
                invoice {
                    id
                    items {
                        id
                        type
                        entity {
                            ... on Service {
                                id
                                name(lang: $lang)
                            }
                            ... on Product {
                                id
                                name
                            }
                        }
                        seller
                        sellerType
                        quantity
                        price
                        paymentType
                        appointment {
                            id
                        }
                    }
                    totalPrice
                    paid
                }
            }
            memberships(client: $client) {
                id
                client {
                    id
                    firstName
                    lastName
                }
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                serialNumber
                service {
                    id
                    name(lang: $lang)
                    duration
                    price
                }
                quantity
                price
                pricePerUse
                totalUses
            }
        }
    }
`;

// Used in CabinetServiceFields.js
export const MASTER_CABINET_SERVICE_FRAGMENT = gql`
    fragment onMasterCabinetService on Master {
        salon(id: $salon) {
            id
            settings {
                schedule {
                    startAt
                    endAt
                    step
                    format
                }
            }
            cabinets {
                id
                name(lang: $lang)
                schedule(startDate: $date) {
                    startAt
                    endAt
                }
                serviceGroups {
                    id
                    name(lang: $lang)
                    services {
                        id
                        name(lang: $lang)
                        duration
                        price
                    }
                    masters {
                        id
                        firstName
                        lastName
                        schedule(startDate: $date) {
                            id
                            startAt
                            endAt
                        }
                    }
                }
            }
        }
    }
`;

// Used in CabinetTable.js
export const MASTER_CABINET_TABLE_FRAGMENT = gql`
    fragment onMasterCabinetTable on Master {
        salon(id: $salon) {
            id
            name
            administrator {
                id
            }
            settings {
                schedule {
                    startAt
                    endAt
                    step
                    format
                }
                enableArchive
            }
            cabinets(date: $date) {
                id
                name(lang: $lang)
                schedule {
                    startAt
                    endAt
                }
                appointments(date: $date, showArchive: $showArchive) {
                    id
                    ...appointment
                }
            }
        }
    }
`;

// Used in CabinetList.js
export const MASTER_CABINET_LIST_FRAGMENT = gql`
    fragment onMasterCabinetList on Master {
        salon(id: $salon) {
            id
            cabinets {
                id
                name(lang: $lang)
                nameVariants {
                    en
                    ru
                    lv
                }
                serviceGroups {
                    id
                    name(lang: $lang)
                    type
                }
            }
        }
    }
`;

// Used in ClientSelect.js
export const MASTER_CLIENT_SELECT_FRAGMENT = gql`
    fragment onMasterClientSelect on Master {
        salon(id: $salon) {
            id
            clients(search: $search, first: 30) {
                nodes {
                    id
                    ...client
                }
            }
            client(id: $client) @include(if: $withClient) {
                id
                ...client
            }
        }
    }
`;

// Used in EntityFields.js
export const MASTER_ENTITY_FIELDS_FRAGMENT = gql`
    fragment onMasterEntityFields on Master {
        salon(id: $salon) {
            id
            products {
                nodes {
                    id
                    ...product
                }
            }
            product(id: $product) @include(if: $withProduct) {
                id
                ...product
            }
            serviceGroups(service: $service) {
                nodes {
                    id
                    services {
                        id
                        ...service
                    }
                }
            }
        }
    }
`;

// Used in InvoiceFieldsList.js
export const MASTER_INVOICE_FIELDS_FRAGMENT = gql`
    fragment onMasterInvoiceFields on Master {
        salon(id: $salon) {
            id
            masters(deleted: true) {
                nodes {
                    id
                    firstName
                    lastName
                    deletedAt
                    serviceGroups(services: $services) {
                        id
                        name
                        services {
                            id
                            name(lang: $lang)
                            duration
                            price
                        }
                    }
                }
            }
            cabinets(deleted: true) {
                id
                name(lang: $lang)
                serviceGroups(services: $services) {
                    id
                    name
                    services {
                        id
                        name(lang: $lang)
                        duration
                        price
                    }
                }
            }
        }
    }
`;

// Used in MasterServiceFieldsList.js
export const MASTER_SERVICE_FIELDS_FRAGMENT = gql`
    fragment onMasterServiceFields on Master {
        salon(id: $salon) {
            id
            settings {
                schedule {
                    startAt
                    endAt
                    step
                    format
                }
            }
            masters(date: $date) {
                nodes {
                    id
                    firstName
                    lastName
                    schedule(startDate: $date) {
                        id
                        startAt
                        endAt
                    }
                    freeWorkingTime(date: $date, showArchive: $showArchive) {
                        startAt
                        endAt
                    }
                    serviceGroups {
                        id
                        name(lang: $lang)
                        services {
                            id
                            name(lang: $lang)
                            duration
                            price
                        }
                        cabinets {
                            id
                            name(lang: $lang)
                            schedule(startDate: $date) {
                                startAt
                                endAt
                            }
                            freeWorkingTime(
                                date: $date
                                showArchive: $showArchive
                            ) {
                                startAt
                                endAt
                            }
                        }
                    }
                }
            }
        }
    }
`;
