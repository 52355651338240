/**
 * @flow
 */
import React from 'react';
import { compose, withHandlers } from 'recompose';
import moment from 'moment';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import Calendar from '../containers/Calendar';

import type { Locale } from '../type';

type DatePickerProps = {
    value: string,
    format: string,
    locale: Locale,
    onChange: Function,
    onToday: Function,
    onPrev: Function,
    onNext: Function,
    dayPickerProps: Object,
    todayButton: boolean,
};

const Wrapper = styled.div`
    & .ui.input {
        height: 37px;
        max-width: 180px;

        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 0px;
            border-right: 0px;
        }
    }

    & .DayPickerInput-OverlayWrapper {
        top: 38px;
        left: 0px;
    }
`;

const PrevDateButton = styled(Button)`
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: 0px !important;
    height: 37px;
`;

const NextDateButton = styled(Button)`
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-right: 0px !important;
    height: 37px;
`;

const DateField = styled(Calendar)`
    margin-top: 1px;
`;

const TodayButton = styled(Button)`
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-left: 0px !important;
    padding-top: 11px !important;
    padding-bottom: 10px !important;
    margin-right: 0px !important;
`;

const DatePicker = ({
    value,
    format,
    locale,
    onChange,
    onToday,
    onPrev,
    onNext,
    dayPickerProps,
    todayButton,
}: DatePickerProps) => (
    <Wrapper>
        <PrevDateButton onClick={onPrev} icon="angle left" basic />
        {todayButton && (
            <TodayButton onClick={onToday} basic>
                Today
            </TodayButton>
        )}
        <DateField
            value={value}
            format={format}
            locale={locale}
            onChange={onChange}
            dayPickerProps={dayPickerProps}
            readOnly
            firstDayOfWeek={1}
        />
        <NextDateButton onClick={onNext} icon="angle right" basic />
    </Wrapper>
);

const handlers = withHandlers({
    onToday: ownProps => () => {
        ownProps.onChange(Date.now());
    },
    onPrev: ownProps => () => {
        ownProps.onChange(
            moment(ownProps.value)
                .subtract(1, 'day')
                .toDate(),
        );
    },
    onNext: ownProps => () => {
        ownProps.onChange(
            moment(ownProps.value)
                .add(1, 'day')
                .toDate(),
        );
    },
});

export default compose(handlers)(DatePicker);
