/**
 * @flow
 */

import { mapProps, withHandlers, compose } from 'recompose';
import { property, chain } from 'lodash';

import { Select } from '../../../../components';

const mapMasters = masters =>
    chain(masters)
        .uniqBy(property('id'))
        .map(master => ({
            key: master.id,
            value: master.id,
            text: `${master.firstName} ${master.lastName || ''}`,
        }))
        .filter(property('value'))
        .value();

const props = mapProps(({ meta, dispatch, masters, change, ...props }) => {
    return {
        ...props,
        options: mapMasters(masters),
    };
});

const handlers = withHandlers({
    onChange: ({ onChange }) => (e, { value }) => {
        setTimeout(() => {
            onChange(value);
        }, 0);
    },
});

export default compose(
    props,
    handlers,
)(Select);
