/**
 * @flow
 */

import React from 'react';
import { mapProps, withHandlers, compose } from 'recompose';
import { get, find } from 'lodash';
import { Select } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const options = [
    {
        value: 'CASH',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.cash"
                defaultMessage="Cash"
            />
        ),
    },
    {
        value: 'CC',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.credit_card"
                defaultMessage="Credit Card"
            />
        ),
    },
    {
        value: 'GIFT',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.gift_card"
                defaultMessage="Gift Card"
            />
        ),
    },
    {
        value: 'MEMBERSHIP',
        text: (
            <FormattedMessage
                id="pages.appointments.details.invoice.InvoiceForm.membership"
                defaultMessage="Membership"
            />
        ),
    },
];

const handlers = withHandlers({
    onChange: ({
        change,
        field,
        membership,
        onChange,
        service,
        paymentType,
        allServices,
        setInfoVisible,
        invoice,
    }) => (e, { value }) => {
        if (membership) {
            if (membership.service.id === service) {
                if (value !== 'MEMBERSHIP') {
                    const invoiceItem = find(
                        invoice,
                        item => item.entity.id === service,
                    );

                    if (invoiceItem) {
                        change(
                            field ? `${field}.price` : 'price',
                            parseFloat(invoiceItem.price).toFixed(2),
                        );
                    }
                } else {
                    change(
                        field ? `${field}.price` : 'price',
                        parseFloat(membership.pricePerUse).toFixed(2),
                    );
                }
            }
        } else if (!membership && paymentType === 'MEMBERSHIP') {
            const defaultServiceData = find(allServices, {
                id: service,
            });

            if (defaultServiceData) {
                change(
                    field ? `${field}.price` : 'price',
                    parseFloat(defaultServiceData.price).toFixed(2),
                );
            }
        }

        if (setInfoVisible) {
            if (value === 'MEMBERSHIP') {
                setInfoVisible(true);
            } else {
                setInfoVisible(false);
            }
        }

        onChange(value);
    },
});

const mapStateToProps = ({ ...state }, { meta, form, field }) => {
    const formName = get(meta, 'form') || form;
    return {
        service: formValueSelector(formName)(
            state,
            field ? `${field}.entity` : 'entity',
        ),
        paymentType: formValueSelector(formName)(
            state,
            field ? `${field}.paymentType` : 'paymentType',
        ),
    };
};

const props = mapProps(({ meta, ...props }) => ({
    options,
    ...props,
}));

export default compose(
    connect(mapStateToProps),
    props,
    handlers,
)(Select);
