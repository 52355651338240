/**
 * @flow
 */

import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty, map } from 'lodash';
import { Modal, Table } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import type { Appointment as AppointmentType } from '../../../../type';

type AppointmentsProps = {
    appointments: Array<AppointmentType>,
};

type AppointmentRowProps = {
    appointment: AppointmentType,
};

// Fix styling of spinner inside modal window
const Spinner = styled(Loader)`
    &::before {
        border: 0.2em solid rgba(0, 0, 0, 0.1) !important;
    }
    &::after {
        border-color: #767676 transparent transparent !important;
    }
`;

/**
 * Show this message if list of appointments is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.staff.details.appointments.noappointments"
                defaultMessage="This master don't have appointments"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * A row of single appointment
 */
const AppointmentRow = ({ appointment }: AppointmentRowProps) => (
    <Table.Row>
        <Table.Cell>
            {moment(appointment.startAt).format('DD.MM.YYYY HH:mm')}
        </Table.Cell>
        <Table.Cell>{appointment.service.name}</Table.Cell>
        <Table.Cell textAlign="right">
            {/* different data, string and integer can come here, so you need to convert them,
        this is a temporary fix until the problem with data types on the server is fixed */}
            {parseFloat(appointment.service.price).toFixed(2)} €
        </Table.Cell>
    </Table.Row>
);

const Appointments = ({ appointments }: AppointmentsProps) => (
    <Modal.Context>
        <Modal.Content>
            <Table celled striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.staff.details.appointments.date"
                                defaultMessage="Date"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.staff.details.appointments.service"
                                defaultMessage="Service"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                            <FormattedMessage
                                id="pages.staff.details.appointments.price"
                                defaultMessage="Price"
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isEmpty(appointments) ? (
                        <EmptyMessage />
                    ) : (
                        map(appointments, appointment => (
                            <AppointmentRow
                                key={appointment.id}
                                appointment={appointment}
                            />
                        ))
                    )}
                </Table.Body>
            </Table>
        </Modal.Content>
    </Modal.Context>
);

// Show spinner while loading data from server
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => (
        <Modal.Context>
            <Modal.Content>
                <Spinner size="big" inline="centered" active />
            </Modal.Content>
        </Modal.Context>
    )),
);

export default compose(withSpinner)(Appointments);
