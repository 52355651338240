/**
 * The component represents a dropdown menu that allow user
 * to select language of the interface.
 *
 * @flow
 */

import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

import type { Language as LanguageType } from '../../type';

type LanguageProps = {
    language: LanguageType,
    options: Array<Object>,
    onChange: Function,
};

const Language = ({ language, options, onChange }: LanguageProps) => {
    return (
        <Dropdown
            trigger={
                <span>
                    <Icon name="world" />
                    {language.name}
                </span>
            }
            value={language.locale}
            options={options}
            onChange={(e, { value }) => {
                onChange(value);
            }}
            selectOnBlur={false}
        />
    );
};

export default Language;
