/**
 *  @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Message } from 'semantic-ui-react';
import { Modal, Button } from '../../../../components';

import AppointmentForm from '../../containers/form/AppointmentForm';
import DeleteAppointmentButton from './DeleteAppointmentButton';
import CreateInvoiceButton from '../../containers/details/CreateInvoiceButton';
import ArchiveButton from '../../containers/details/ArchiveButton';

import InvoiceMessage from './InvoiceMessage';
import GroupedMessage from './GroupedMessage';

import type { ReduxForm, ModalDialog, Appointment } from '../../../../type';

const ErrorMessage = ({ error }) => (
    <Message error>
        <Icon name="warning sign" />
        {error}
    </Message>
);

type EditAppointmentProps = {
    appointment: Appointment,
    isPaid: boolean,
    isGrouped: boolean,
    change: Function,
    updateAppointmentClient: Function,
    onCreateInvoice: Function,
    masterAppointments: any,
    fromDate: string | Date,
    toDate: string | Date,
    form: any,
    isSysadmin: boolean,
    isOwner: boolean,
} & ModalDialog &
    ReduxForm;

const EditAppointment = ({
    isPaid,
    isGrouped,
    onClose,
    onCreateInvoice,
    handleSubmit,
    submitting,
    pristine,
    appointment,
    change,
    updateAppointmentClient,
    archiveAppointment,
    masterAppointments,
    fromDate,
    toDate,
    error,
    form,
    enableArchive,
    isSysadmin,
    isOwner,
    onCloseDialog,
    inCabinet,
    setDisableInvoice,
    isMaster,
}: EditAppointmentProps) => {
    setDisableInvoice(!pristine);
    return (
        <Modal.Context>
            <Modal.Content>
                {error && <ErrorMessage error={error} />}
                {isPaid && <InvoiceMessage />}
                {isGrouped && <GroupedMessage />}
                <AppointmentForm
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    pristine={pristine}
                    appointment={appointment}
                    disabled={isPaid}
                    change={change}
                    updateAppointmentClient={updateAppointmentClient}
                    form={form}
                    onCloseDialog={onClose}
                    inCabinet={inCabinet}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={handleSubmit}
                    disabled={isPaid || pristine || submitting}
                    loading={submitting}
                    primary
                >
                    <FormattedMessage
                        id="pages.appointments.details.EditAppointment.save"
                        defaultMessage="Save"
                    />
                </Button>
                <Button onClick={onClose} disabled={submitting} basic>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.appointments.details.EditAppointment.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                {(enableArchive || isSysadmin || isOwner) &&
                    isPaid &&
                    !isMaster && (
                        <ArchiveButton
                            appointment={appointment}
                            onClose={onClose}
                            masterAppointments={masterAppointments}
                            fromDate={fromDate}
                            toDate={toDate}
                            archiveAppointment={archiveAppointment}
                        />
                    )}
                {!appointment.invoice && (
                    <CreateInvoiceButton
                        appointment={appointment}
                        onClicked={onCreateInvoice}
                        disabled={!pristine}
                    />
                )}
                {!isPaid && (
                    <DeleteAppointmentButton
                        appointment={appointment}
                        onClose={onClose}
                        masterAppointments={masterAppointments}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                )}
            </Modal.Actions>
        </Modal.Context>
    );
};

export default EditAppointment;
