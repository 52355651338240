import gql from 'graphql-tag';
import { APPOINTMENT_FRAGMENT } from '../../appointments/containers/details/Fragment';

// The query to get current salon object
export const GET_MASTER_APPOINTMENTS_QUERY = gql`
    query getMasterAppointments(
        $master: ID!
        $salon: ID!
        $fromDate: String!
        $toDate: String!
        $withMaster: Boolean!
        $showArchive: Boolean
        $lang: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    name
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    master(id: $master) @include(if: $withMaster) {
                        id
                        ...master
                    }
                }
            }
        }
    }

    fragment master on Master {
        firstName
        lastName
        phone
        email
        status
        type
        serviceGroups {
            id
            name(lang: $lang)
            type
        }
        appointments(
            fromDate: $fromDate
            toDate: $toDate
            showArchive: $showArchive
        ) {
            id
            ...appointment
        }
        schedule(startDate: $fromDate, endDate: $toDate) {
            id
            startAt
            endAt
            date
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;

// The subscription to a new appointments
export const APPOINTMENT_ADD_SUBSCRIPTION = gql`
    subscription onMasterAppointmentAdded(
        $salon: ID!
        $date: String!
        $toDate: String
        $lang: String!
    ) {
        appointmentAdded(salon: $salon, date: $date, toDate: $toDate) {
            id
            ...appointment
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;

// The subscription for deleted appointments
export const APPOINTMENT_DELETE_SUBSCRIPTION = gql`
    subscription onMasterAppointmentDeleted(
        $salon: ID!
        $date: String!
        $toDate: String
        $lang: String!
    ) {
        appointmentDeleted(salon: $salon, date: $date, toDate: $toDate) {
            id
            ...appointment
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;
