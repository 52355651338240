/**
 * @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '../../../../components';

import CabinetForm from '../form/CabinetForm';

const CabinetDetails = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    ...props
}) => (
    <Modal trigger={trigger} onClose={onClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.cabinets.EditCabinet.header"
                defaultMessage="Edit cabinet"
            />
        </Modal.Header>
        <Modal.Content>
            <CabinetForm
                {...props}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.cabinets.EditCabinet.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.cabinets.EditCabinet.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default CabinetDetails;
