/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import DeleteManufacturerDialog from '../../components/manufacturers/DeleteManufacturerDialog';
import { remove } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';

import { GET_MANUFACTURERS_QUERY } from './ManufacturersList';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.settings.manufacturers.form.existingsError',
        defaultMessage: 'This manufacturer does not exist',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// Query that deletes specified manufacturer
const DELETE_MANUFACTURER_QUERY = gql`
    mutation deleteManufacturer($input: DeleteManufacturerInput!) {
        deleteManufacturer(input: $input) {
            manufacturerId
        }
    }
`;

const withData = graphql(DELETE_MANUFACTURER_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: { manufacturerId: ownProps.manufacturer.id },
                },
                // Implement optimistic response to compensate network latency
                // and delete manufacturer before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteManufacturer: {
                        __typename: 'DeleteManufacturerPayload',
                        manufacturerId: ownProps.manufacturer.id,
                    },
                },
                // Remove deleted manufacturer from query cache
                update: (store, { data: { deleteManufacturer } }) => {
                    const data = store.readQuery({
                        query: GET_MANUFACTURERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });
                    remove(
                        data.viewer.salon.manufacturers.nodes,
                        manufactuer =>
                            manufactuer.id === ownProps.manufacturer.id,
                    );
                    store.writeQuery({
                        query: GET_MANUFACTURERS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog after manufacturer was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when manufacturer was not deleted
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            ownProps.setError(
                                intl.formatMessage(intlMessages.existingsError),
                            );
                        }
                    }

                    // General error if we not able to identify it specificly
                    ownProps.setError(
                        intl.formatMessage(intlMessages.defaultErrorMessage),
                    );
                });
        },
    }),
});

const state = withState('error', 'setError', null);

export default compose(
    connect(({ user }) => ({ salon: user.get('salon') })),
    injectIntl,
    state,
    withData,
)(DeleteManufacturerDialog);
