import { Map } from 'immutable';
import moment from 'moment';

// Define initial state
const initialState = new Map({
    // Set default date for schedule
    date: new Date(),
    firstRangeFrom: new Date(
        moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek'),
    ),
    firstRangeTo: new Date(
        moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek'),
    ),
    secondRangeFrom: new Date(
        moment()
            .add(1, 'weeks')
            .startOf('isoWeek'),
    ),
    secondRangeTo: new Date(
        moment()
            .add(2, 'weeks')
            .endOf('isoWeek'),
    ),
});

export default (state = initialState, action) => {
    switch (action.type) {
        // Set a new schedule date
        case 'SCHEDULE.SET_DATE': {
            return state.set('date', action.date);
        }
        case 'SCHEDULE.GENERATE_FROM_DATE': {
            return state
                .set('firstRangeFrom', action.firstRangeFrom)
                .set('firstRangeTo', action.firstRangeTo);
        }
        case 'SCHEDULE.GENERATE_TO_DATE': {
            return state
                .set('secondRangeFrom', action.secondRangeFrom)
                .set('secondRangeTo', action.secondRangeTo);
        }
        default: {
            return state;
        }
    }
};
