/**
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import { ProductDetails } from './details';
import { FormattedMessage } from 'react-intl';
import type { Product, ModalDialogTrigger } from '../../../type';

type EditProductButtonProps = {
    product: Product,
} & ModalDialogTrigger;

const EditProductButton = ({
    product,
    open,
    onOpen,
    onClose,
    children,
}: EditProductButtonProps) => (
    <ProductDetails
        trigger={(
            <a onClick={onOpen}>
                <FormattedMessage 
                    id='pages.inventory.EditProductButton.label'
                    defaultMessage='Edit'
                />
            </a>
        )}
        onClose={onClose}
        open={open}
        product={product}
    />
);

export default withModal(EditProductButton);
