import {
    each,
    set,
    isNil,
    isEmpty
} from 'lodash';
import {defineMessages} from 'react-intl';
import { isMongoId } from '../../../../lib/formValidation';
import { priceIsNotValid } from '../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    selectAdministratorError: {
        id: 'pages.appointments.cart.form.selectAdministratorError',
        defaultMessage: 'Select administrator',
    },
    atLeastOneItemError: {
        id: 'pages.appointments.cart.form.atLeastOneItemError',
        defaultMessage: 'Add atleast one item',
    },
    selectProductError: {
        id: 'pages.appointments.cart.form.selectProductError',
        defaultMessage: 'Select product',
    },
    selectProductQuantityError: {
        id: 'pages.appointments.cart.form.selectProductQuantityError',
        defaultMessage: 'Select product quantity',
    },
    productPriceError: {
        id: 'pages.appointments.cart.form.productPriceError',
        defaultMessage: 'Product price is invalid',
    },
    selectPaymentTypeError: {
        id: 'pages.appointments.cart.form.selectPaymentTypeError',
        defaultMessage: 'Select payment type',
    },
});

export default (values, {intl}) => {
    const errors = {};

    if (!isMongoId(values.administrator)) {
        set(errors, 'administrator', intl.formatMessage(intlMessages.selectAdministratorError));
    }

    if (isEmpty(values.items) || isNil(values.items)) {
        set(errors, '_error', intl.formatMessage(intlMessages.atLeastOneItemError));
    }

    each(values.items, (item, idx) => {
        if (!isMongoId(item.entity)) {
            set(errors, ['items', idx, 'entity'], intl.formatMessage(intlMessages.selectProductError));
        }
        if (isNil(item.quantity) || !Number(item.quantity)) {
            set(
                errors,
                ['items', idx, 'quantity'],
                intl.formatMessage(intlMessages.selectProductQuantityError),
            );
        }
        if (
            isNil(item.price) ||
            priceIsNotValid(item.price) ||
            !parseFloat(item.price)
        ) {
            set(
                errors,
                ['items', idx, 'price'],
                intl.formatMessage(intlMessages.productPriceError),
            );
        }

        if (isNil(item.paymentType)) {
            set(errors, ['items', idx, 'paymentType'], intl.formatMessage(intlMessages.selectPaymentTypeError));
        }
    });

    return errors;
};
