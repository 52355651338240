/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { property } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';

import { MasterTypeIcon } from '../../components';
import Cell from './Cell';
import { setMaster } from '../../actions/master-appointments';

import type { Master as MasterType } from '../../type';

type MasterProps = {
    master: MasterType,
    openMasterAppointments: Function,
};

const MasterName = styled.span`
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
`;

const MasterCell = styled(Cell)`
    padding: 0px 14px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &:hover {
        color: #35a3d5;
        background: #eef8fc;
    }
`;

const Master = ({ master, openMasterAppointments }: MasterProps) => (
    <MasterCell onClick={openMasterAppointments}>
        <MasterTypeIcon
            name={`${master.firstName} ${master.lastName || ''}`}
            type={property(['serviceGroups', '0', 'type'])(master)}
        />
        <MasterName className="name">
            {master.firstName} {master.lastName}
        </MasterName>
    </MasterCell>
);

const withMasterHandlers = withHandlers({
    openMasterAppointments: ({ master, setMaster, history }) => () => {
        setMaster(master);
        history.push('/salon/master-appointments');
    },
});

const mapDipatchToProps = {
    setMaster,
};

export default compose(
    connect(
        null,
        mapDipatchToProps,
    ),
    withRouter,
    withMasterHandlers,
)(Master);
