/**
 * @flow
 */

import React from 'react';
import { withState, withHandlers, compose } from 'recompose';

import { Modal, Avatar } from '../../../../components';
import PersonalInformation from '../../containers/details/PersonalInformation';
import CreateConnections from '../../containers/CreateConnections';
import Navigation from './Navigation';

import type { ModalDialog, Supplier } from '../../../../type';

type SupplierDetailsProps = {
    supplier: Supplier,
    onSupplierUpdate: Function,
} & ModalDialog;

const SupplierDetails = ({
    supplier,
    trigger,
    onClose,
    open,
    onSupplierUpdate,
    selectedItem,
    onItemClick,
}: SupplierDetailsProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>
            <Avatar
                name={`${supplier.firstName} ${supplier.lastName}`}
                size={48}
                spaced="right"
                round
            />
            {`${supplier.firstName} ${supplier.lastName}`}
            <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
        </Modal.Header>
        {selectedItem === 'personalInformation' ? (
            <PersonalInformation
                onClose={onClose}
                supplier={supplier}
                open={open}
                onSupplierUpdate={onSupplierUpdate}
            />
        ) : (
            <CreateConnections onClose={onClose} supplier={supplier} />
        )}
    </Modal>
);

const state = withState('selectedItem', 'selectItem', 'personalInformation');

const handlers = withHandlers({
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(
    state,
    handlers,
)(SupplierDetails);
