import React from 'react';
import styled from 'styled-components';
import { Icon, Form, Checkbox as CheckboxBase } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { FieldArray, Field } from 'redux-form';

import { Modal, Button, Table } from '../../../../components';

import type { Master, ReduxForm } from '../../../../type';

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={2} textAlign="center">
            <FormattedMessage
                id="pages.clients.masterList.table.empty"
                defaultMessage="There is no masters in this salon"
            />
        </Table.Cell>
    </Table.Row>
);

const CheckBoxCell = styled(Table.Cell)`
    .ui.table td& {
        text-align: center;
    }
`;

const Checkbox = ({ input: { value, onChange, ...input }, meta, ...props }) => (
    <CheckboxBase
        {...input}
        {...props}
        defaultChecked={!!value}
        onChange={(e, data) => onChange(data.checked)}
        type="checkbox"
    />
);

type MasterRowProps = { master: Master, field: string, readonly: boolean };

const MasterRow = ({ master, field, readonly }: MasterRowProps) => (
    <Table.Row>
        <CheckBoxCell width={1}>
            <Field name={field} component={Checkbox} disabled={readonly} />
        </CheckBoxCell>
        <Table.Cell>
            {`${master.firstName} ${master.lastName || ''}`}
        </Table.Cell>
    </Table.Row>
);

const TableBody = ({ fields, masters, readonly }) => (
    <Table.Body>
        {isEmpty(fields) ? (
            <EmptyMessage />
        ) : (
            fields.map((field, idx) => (
                <MasterRow
                    key={masters[idx].id}
                    master={masters[idx]}
                    field={field}
                    readonly={readonly}
                />
            ))
        )}
    </Table.Body>
);

type MasterListProps = {
    handleSubmit: Function,
    submitting: boolean,
    pristine: boolean,
    onClose: Function,
    masters: Array<Master>,
    readonly: boolean,
} & ReduxForm;

const MasterList = ({
    handleSubmit,
    submitting,
    pristine,
    onClose,
    masters,
    readonly,
}: MasterListProps) => (
    <Modal.Context>
        <Modal.Content>
            <Form>
                <Table celled striped selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1} />
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id="pages.clients.masterList.table.master"
                                    defaultMessage="Master"
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <FieldArray
                        name="masters"
                        component={TableBody}
                        masters={masters}
                        readonly={readonly}
                    />
                </Table>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || pristine}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.clients.masterList.button.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.clients.masterList.button.close"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default MasterList;
