/**
 * @flow
 */

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { Modal } from '../../../../components';

import Navigation from './Navigation';
import EditProduct from '../../containers/details/EditProduct';

import type { NavigationItems, ModalDialog, Product } from './Navigation';

type ProductDetailsProps = {
    product: Product,
    selectedItem: NavigationItems,
    onItemClick: Function,
} & ModalDialog;

const ProductDetails = ({
    product,
    trigger,
    onClose,
    open,
    selectedItem,
    onItemClick,
}: ProductDetailsProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>
            {product.name}
        </Modal.Header>
        <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
        {selectedItem === 'editProduct' &&
            <EditProduct product={product} onClose={onClose} />}
    </Modal>
);

// Setup state handler to hold active section
const state = withState('selectedItem', 'selectItem', 'editProduct');

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(state, handlers)(ProductDetails);
