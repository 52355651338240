/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';

type GenderProps = {
    onChange: Function,
    value: string,
    intl: Object,
    disabled: boolean,
};

const intlMessages = {
    male: defineMessages({
        label: {
            id: 'pages.clients.form.Gender.maleLabel',
            defaultMessage: 'Male',
        },
    }),
    female: defineMessages({
        label: {
            id: 'pages.clients.form.Gender.femaleLabel',
            defaultMessage: 'Female',
        },
    }),
};

const FormGroup = styled(Form.Group)`margin-bottom: 0 !important;`;

const Gender = ({ onChange, value, disabled, intl }: GenderProps) => (
    <FormGroup>
        <Form.Radio
            label={intl.formatMessage(intlMessages.male.label)}
            value="MALE"
            checked={value === 'MALE'}
            onChange={onChange}
            disabled={disabled}
        />
        <Form.Radio
            label={intl.formatMessage(intlMessages.female.label)}
            value="FEMALE"
            checked={value === 'FEMALE'}
            onChange={onChange}
            disabled={disabled}
        />
    </FormGroup>
);

export default injectIntl(Gender);
