/**
 * @flow
 */

import { some, filter, isEmpty, each, find, reduce } from 'lodash';
import moment from 'moment';
import type { Salon, Service } from '../type';
import { convertDateTimeToMinutes } from './date';

// The time span is array with two values [from, to]
type TimeSpan = Array<number>;

type GetFreeTimeSpansProps = {
    timeSpan: TimeSpan,
    duration?: number,
    step: number,
    exclude?: Array<TimeSpan>,
};

type GetInvertedTimeSpans = {
    startAt: number,
    endAt: number,
    timeSpans: Array<TimeSpan>,
};

/**
 * Test if two ranges of numbers overlaps
 */
export const isOverlap = (x1: number, x2: number, y1: number, y2: number) => {
    return Math.max(x2, y2) - Math.min(x1, y1) < x2 - x1 + (y2 - y1);
};

/**
 * Calculate free time spans in provided time range
 */
export const getFreeTimeSpans = ({
    timeSpan,
    duration,
    step,
    exclude,
}: GetFreeTimeSpansProps) => {
    // Validate provided parameters
    if (timeSpan[1] < timeSpan[0]) {
        throw new Error('Invalid time span parameter');
    }

    if (duration !== undefined && duration > timeSpan[1]) {
        throw new Error('Duration is greater then time span');
    }

    const freeTimeSpans = [];
    for (let i = timeSpan[0]; i <= timeSpan[1]; i += step) {
        for (let j = i; j <= timeSpan[1]; j += step) {
            if (duration) {
                // If duration provided, push all time spans that are eqaul or greater
                if (j - i >= duration) {
                    freeTimeSpans.push([i, j]);
                    j = timeSpan[1];
                }
            } else if (j - i === step) {
                // If no duration provided, push all time spans that are equal to step
                freeTimeSpans.push([i, j]);
            }
        }
    }

    return filter(freeTimeSpans, time => {
        const match = some(exclude || [], item =>
            isOverlap(time[0], time[1], item[0], item[1]),
        );

        return !match;
    });
};

/**
 * Calculate free time spans in provided schedule list
 */
export const getFreeTimeSpansFromSchedule = (schedule, duration, step) =>
    reduce(
        schedule,
        (result, { startAt, endAt }) => [
            ...result,
            ...getFreeTimeSpans({
                timeSpan: [startAt, endAt],
                duration,
                step,
            }),
        ],
        [],
    );

/**
 * Return list of inverted time spans in provided range
 */
export const getInvertedTimeSpans = ({
    startAt,
    endAt,
    timeSpans,
}: GetInvertedTimeSpans) => {
    if (startAt > endAt) {
        throw new Error('Invalid time range');
    }

    // If list of time spans is empty, return whole range
    if (isEmpty(timeSpans)) {
        return [[startAt, endAt]];
    }

    const invertion = [];
    for (let a = startAt; a <= endAt; a++) {
        for (let b = a; b <= endAt; b++) {
            for (let c = 0; c < timeSpans.length; c++) {
                const overlap = isOverlap(
                    a,
                    b,
                    timeSpans[c][0],
                    timeSpans[c][1],
                );
                if (overlap) {
                    if (a < timeSpans[c][0]) {
                        invertion.push([a, timeSpans[c][0]]);
                    }
                    a = b = timeSpans[c][1];
                    c = timeSpans.length;
                }
            }
            if (b === endAt && a < b) {
                invertion.push([a, b]);
                a = b;
            }
        }
    }

    return invertion;
};

/**
 * Calculate event offset from the top based on appointment start time
 * and it's duration.
 *
 * @param {Number} cellHeight      - Cell height
 * @param {String} eventStartAt    - Event start date and time
 * @param {Number} scheduleStartAt - Number of minutes since middnight when schedule starts
 * @param {Number} step            - Step in minutes with whitch timetable renders
 */
export const calcEventOffset = (
    cellHeight: number,
    eventStartAt: moment.MomentInput,
    scheduleStartAt: number,
    step: number,
) => {
    const startInMinutes = convertDateTimeToMinutes(eventStartAt);
    return ((startInMinutes - scheduleStartAt) / step) * cellHeight;
};

/**
 * Apply provided offset to current event time and calculate a new time.
 *
 * @param {String} eventStartAt - Event start date and time
 * @param {Number} cellHeight - Cell height
 * @param {Number} step - Step in minutes with which timetable renders
 * @param {Number} offset - Step in minutes with which timetable renders
 * @param {Number} round - Round time to speicifed number of minutes
 */
export const offsetToTime = (
    eventStartAt: string,
    cellHeight: number,
    step: number,
    offset: number,
    round: number,
) => {
    const minutes = moment(eventStartAt).get('minutes');
    let diff = 0;
    if (minutes > 0 && minutes % round > 0) {
        diff = round - minutes;
    }
    const time = Math.round((offset / cellHeight) * step);
    const roundedTime = round ? Math.round(time / round) * round : time;

    return moment(eventStartAt)
        .add(roundedTime + diff, 'minutes')
        .toDate();
};

/**
 * Calculate card height based on it's duration
 *
 * @param {Number} cellHeight - Cell height
 * @param {Number} duration   - The appointment duration in minutes
 * @param {Number} step       - Step in minutes with whitch timetable renders
 */
export const calcEventHeight = (
    cellHeight: number,
    duration: number,
    step: number,
) => {
    return (duration / step) * cellHeight;
};

/**
 * Calculate timeline height
 *
 * @param {Number} cellHeight - Cell height
 * @param {Number} startAt    - Time when schedule starts at
 * @param {Number} endAt      - Time when schedule ends at
 * @param {Number} step       - Step in minutes with whitch timetable renders
 */
export const calcTimetableHeight = (
    cellHeight: number,
    startAt: number,
    endAt: number,
    step: number,
) => {
    return ((endAt - startAt) / step) * cellHeight;
};

/**
 * Check if line that highlight current time overlap with provided date
 *
 * @param {Date} date - The date to verify
 */
export const isNowLineOverlap = (date: Date): boolean =>
    moment().isBetween(
        moment(date).subtract(30, 'minutes'),
        moment(date).add(30, 'minutes'),
        null,
        '[]',
    );

/**
 * Get scheduled service list.
 *
 * @param {Object} salon - Salon data
 * @return {Array} service list
 */
export const getScheduledServices = (items): Array<Service> => {
    const services: Array<Service> = [];

    each(items, item => {
        if (item.appointments) {
            const appointments = item.appointments;
            each(appointments, appointment => {
                const hasService = find(services, {
                    id: appointment.service.id,
                });
                if (!hasService) {
                    services.push(appointment.service);
                }
            });
        }
    });
    return services;
};
