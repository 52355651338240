/**
 * @flow
 */

import React from 'react';
import { compose, branch, renderComponent, mapProps } from 'recompose';
import { Field } from 'redux-form';
import styled from 'styled-components';
import {
    Form,
    Input,
    Icon,
    Loader,
    Message,
    Container,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
    FormField,
    FormLabel,
    Button,
    Divider,
    PageHeader,
    Toggle,
} from '../../../../components';

import TimeSelect from './TimeSelect';
import StepSelect from './StepSelect';
import TimeFormatSelect from './TimeFormatSelect';

import type { ReduxForm, Intl } from '../../../../type';

type GeneralSettingsProps = {
    values: Object,
    intl: Intl,
    isSysadmin: Boolean,
    isOwner: Boolean,
} & ReduxForm;

const intlMessages = defineMessages({
    salonNamePlaceholder: {
        id: 'pages.settings.generalSettings.salonName.placeholder',
        defaultMessage: 'Salon name',
    },
    salonAddressPlaceholder: {
        id: 'pages.settings.generalSettings.salonAddress.placeholder',
        defaultMessage: 'Salon address',
    },
    startAtPlaceholder: {
        id: 'pages.settings.generalSettings.startAt.placeholder',
        defaultMessage: 'Start At',
    },
    endAtPlaceholder: {
        id: 'pages.settings.generalSettings.endAt.placeholder',
        defaultMessage: 'End At',
    },
    stepPlaceholder: {
        id: 'pages.settings.generalSettings.step.placeholder',
        defaultMessage: 'Step',
    },
    formatPlaceholder: {
        id: 'pages.settings.generalSettings.format.placeholder',
        defaultMessage: 'Format',
    },
});

// Number of minutes in 24 hours
const DAY_IN_MINUTES = 1440;

const limitProps = mapProps(({ meta, ...props }) => ({ ...props }));

const fields = {
    input: FormField(limitProps(Input)),
    time: FormField(TimeSelect),
    step: FormField(limitProps(StepSelect)),
    format: FormField(limitProps(TimeFormatSelect)),
};

const ActionButton = styled(Button)`
    margin-left: 0.75em !important;
`;

const GeneralSettings = ({
    handleSubmit,
    submitting,
    pristine,
    reset,
    error,
    values,
    intl,
    isSysadmin,
    isOwner,
}: GeneralSettingsProps) => (
    <Container fluid>
        <PageHeader>
            <FormattedMessage
                id="pages.settings.generalSettings"
                defaultMessage="General Settings"
            />
        </PageHeader>
        <Divider />
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <Form onSubmit={handleSubmit}>
            <Field
                name="name"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.settings.generalSettings.salonName"
                            defaultMessage="Salon name (or company)"
                        />
                    </FormLabel>
                }
                placeholder={intl.formatMessage(
                    intlMessages.salonNamePlaceholder,
                )}
                required
                simpleValue
            />
            <Field
                name="address"
                component={fields.input}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.settings.generalSettings.salonAddress"
                            defaultMessage="Salon address (or company)"
                        />
                    </FormLabel>
                }
                placeholder={intl.formatMessage(
                    intlMessages.salonAddressPlaceholder,
                )}
                required
                simpleValue
            />
            <Form.Field required>
                <Form.Group widths="equal">
                    <Field
                        name="settings.schedule.startAt"
                        component={fields.time}
                        label={
                            <FormLabel>
                                <FormattedMessage
                                    id="pages.settings.generalSettings.startAt"
                                    defaultMessage="Start At"
                                />
                            </FormLabel>
                        }
                        placeholder={intl.formatMessage(
                            intlMessages.startAtPlaceholder,
                        )}
                        startAt={0}
                        endAt={values.settings.schedule.endAt}
                        step={values.settings.schedule.step}
                        formatTime={values.settings.schedule.format}
                        required
                        simpleValue
                    />
                    <Field
                        name="settings.schedule.endAt"
                        component={fields.time}
                        placeholder={intl.formatMessage(
                            intlMessages.endAtPlaceholder,
                        )}
                        label={
                            <FormLabel>
                                <FormattedMessage
                                    id="pages.settings.generalSettings.endAt"
                                    defaultMessage="End At"
                                />
                            </FormLabel>
                        }
                        startAt={values.settings.schedule.startAt}
                        endAt={DAY_IN_MINUTES}
                        step={values.settings.schedule.step}
                        formatTime={values.settings.schedule.format}
                        required
                        simpleValue
                    />
                    <Field
                        name="settings.schedule.step"
                        component={fields.step}
                        placeholder={intl.formatMessage(
                            intlMessages.stepPlaceholder,
                        )}
                        label={
                            <FormLabel>
                                <FormattedMessage
                                    id="pages.settings.generalSettings.step"
                                    defaultMessage="Step"
                                />
                            </FormLabel>
                        }
                        required
                        simpleValue
                    />
                    <Field
                        name="settings.schedule.format"
                        component={fields.format}
                        placeholder={intl.formatMessage(
                            intlMessages.formatPlaceholder,
                        )}
                        label={
                            <FormLabel>
                                <FormattedMessage
                                    id="pages.settings.generalSettings.format"
                                    defaultMessage="Format"
                                />
                            </FormLabel>
                        }
                        required
                        simpleValue
                    />
                </Form.Group>
            </Form.Field>
            <Divider />

            <ActionButton
                onClick={e => {
                    e.preventDefault();
                    reset();
                }}
                disabled={submitting || pristine}
                floated="right"
                basic
            >
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.generalSettings.cancel"
                    defaultMessage="Cancel"
                />
            </ActionButton>
            <ActionButton
                onClick={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
                disabled={submitting || pristine}
                loading={submitting}
                floated="right"
                primary
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.generalSettings.save"
                    defaultMessage="Save"
                />
            </ActionButton>
        </Form>
    </Container>
);

// Show spinner while loading data from server
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" active />),
);

export default compose(withSpinner)(GeneralSettings);
