import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import Raven from 'raven-js';
import { injectIntl, defineMessages } from 'react-intl';

import SignupDialog from '../components/SignupDialog';
import validate from './validate';
import { login } from '../../../actions/user';
import withNewSalon from '../../../lib/withNewSalon';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.generalSettings.nameExistingsError',
        defaultMessage: 'Salon with the same name already exists',
    },
    emailExistingsError: {
        id: 'pages.signup.form.nameExistingsError',
        defaultMessage:
            'Administrator with the same email address already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'signup',
    touchOnBlur: false,
    validate,
});

const SIGNUP_MUTATION = gql`
    mutation signup($input: SignupAdministratorInput!) {
        signupAdministrator(input: $input) {
            token
            user {
                id
                firstName
                lastName
            }
            salon {
                id
            }
            role
        }
    }
`;

const state = withState('token', 'setToken', false);

// Provide GraphQL mutation for creation of a new salon and administator. In case
// of success user will be immediately autehtnicated otherways an error will be presented.
const withData = graphql(SIGNUP_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: { ...formData, token: ownProps.token },
                },
            });

            const { intl } = ownProps;

            return (
                mutation
                    // Imediately authenticated new user
                    .then(({ data }) => {
                        ownProps.login({
                            id: data.signupAdministrator.user.id,
                            role: data.signupAdministrator.role,
                            token: data.signupAdministrator.token,
                            salon: data.signupAdministrator.salon.id,
                            isOwner: true,
                        });
                        ownProps.setupNewSalon(
                            data.signupAdministrator.salon.id,
                            () => {
                                ownProps.setOwnerAsMaster(
                                    data.signupAdministrator.salon.id,
                                    data.signupAdministrator.user.id,
                                    () => {
                                        ownProps.history.push(
                                            '/salon/appointments',
                                        );
                                    },
                                );
                            },
                        );
                    })
                    // Handle possible errors
                    .catch(error => {
                        Raven.captureException(error);

                        const graphQLError = (error.graphQLErrors || [])[0];
                        if (graphQLError) {
                            if (graphQLError.name === 'AlreadyExists') {
                                if (
                                    graphQLError.data.error ===
                                    'NAME_ALREADY_EXISTS'
                                ) {
                                    throw new SubmissionError({
                                        salonName: intl.formatMessage(
                                            intlMessages.nameExistingsError,
                                        ),
                                    });
                                } else if (
                                    graphQLError.data.error ===
                                    'EMAIL_ALREADY_EXISTS'
                                ) {
                                    throw new SubmissionError({
                                        email: intl.formatMessage(
                                            intlMessages.emailExistingsError,
                                        ),
                                    });
                                } else {
                                    throw new SubmissionError({
                                        _error: intl.formatMessage(
                                            intlMessages.defaultErrorMessage,
                                        ),
                                    });
                                }
                            } else {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.defaultErrorMessage,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    })
            );
        },
    }),
});


export default compose(
    withRouter,
    connect(
        null,
        {
            login,
        },
    ),
    state,
    injectIntl,
    withNewSalon,
    withData,
    withForm,
)(SignupDialog);
