import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Report from '../components/Report';
import { get } from 'lodash';

const GET_REPORT_QUERY = gql`
    query getReport(
        $fromDate: String!
        $toDate: String!
        $salonFilter: [ID]
        $masterFilter: [ID]
        $manufacturerFilter: [ID]
    ) {
        viewer {
            id
            ... on Supplier {
                report(
                    fromDate: $fromDate
                    toDate: $toDate
                    salonFilter: $salonFilter
                    masterFilter: $masterFilter
                    manufacturerFilter: $manufacturerFilter
                ) {
                    totalCount
                    revenue {
                        cash
                        total
                    }
                    items {
                        boughtAt
                        quantity
                        sum
                        entities {
                            quantity
                            sum
                            price
                            salon {
                                id
                                name
                                address
                            }
                            seller {
                                ... on Master {
                                    id
                                    firstName
                                    lastName
                                }
                                ... on Administrator {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                            entity {
                                name
                                code
                                price
                                supplierPrice
                                manufacturer {
                                    id
                                    name
                                }
                                type
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_REPORT_QUERY, {
    options: ownProps => ({
        variables: {
            fromDate: ownProps.fromDate,
            toDate: ownProps.toDate,
            salonFilter: ownProps.salonFilter || [],
            masterFilter: ownProps.masterFilter || [],
            manufacturerFilter: ownProps.manufacturerFilter || [],
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer, error, fetchMore } }) => ({
        loading,
        fetchMore,
        report: get(viewer, 'report', {}),
    }),
});

const mapStateToProps = ({ report }) => ({
    masterFilter: report.get('masterFilter'),
    salonFilter: report.get('salonFilter'),
    manufacturerFilter: report.get('manufacturerFilter'),
    fromDate: report.get('fromDate'),
    toDate: report.get('toDate'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(Report);
