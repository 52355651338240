import { withState, withHandlers, compose, withProps } from 'recompose';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    flatten,
    map,
    forEach,
    isEmpty,
    sumBy,
    filter,
    reduce,
    get,
    property,
} from 'lodash';
import ServiceReport from '../../components/service/ServiceReport';

const state = withState('showList', 'setShowList', false);

const handlers = withHandlers({
    handleExpandList: ({ setShowList, showList }) => () =>
        setShowList(!showList),
});

const withValues = withProps(({ service, showAppRecords }) => {
    let invoices = service.report.invoices;

    const items = reduce(
        invoices,
        (res, { id, items, appointments, ...invoice }) => {
            const newItems = map(items, ({ sellerObject, ...item }, i) => ({
                ...item,
                seller: sellerObject,
                key: `${id}${i}`,
                client: get(appointments, [0, 'client']),
                ...invoice,
            }));

            res.push(...newItems);

            return res;
        },
        [],
    );

    const appointments = flatten(map(invoices, property('appointments')));

    const revenueOfServices = sumBy(
        items,
        service => service.price * service.quantity,
    );

    const minutes = reduce(appointments, (sum, item) => sum + item.duration, 0);

    const hours = `${(minutes / 60) | 0}:${moment()
        .minutes(minutes)
        .format('mm')}`;

    return {
        items,
        revenueOfServices,
        hours,
    };
});

const mapStateToProps = ({ report, user, salon, intl }) => ({
    showAppRecords: report.get('showAppRecords'),
    isOwner: user.get('isOwner'),
    salon: user.get('salon'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    enableArchive: salon.get('settings').enableArchive,
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    state,
    handlers,
    withValues,
)(ServiceReport);
