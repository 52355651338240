/**
 * @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';

import CartDialog from '../../containers/cart/CartDialog';

import type { ModalDialogTrigger } from '../../../../type';

type CartButtonProps = {
    onModalOpen: Function,
    setPaid: Function,
    paid: boolean,
} & ModalDialogTrigger;

const CartButton = ({
    open,
    onClose,
    onModalOpen,
    setPaid,
    paid,
}: CartButtonProps) => (
    <CartDialog
        open={open}
        onClose={onClose}
        setPaid={setPaid}
        paid={paid}
        trigger={
            <Button onClick={onModalOpen} labelPosition="left" primary icon>
                <Icon name="cart" />
                <FormattedMessage
                    id="pages.appointments.cart.button.cart"
                    defaultMessage="Sale"
                />
            </Button>
        }
    />
);

export default CartButton;
