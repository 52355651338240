import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CabinetList from '../components/CabinetList';
import { MASTER_CABINET_LIST_FRAGMENT } from '../../appointments/containers/masterAccessQueries';

export const GET_CABINETS_QUERY = gql`
    query getCabinets($salon: ID!, $lang: String) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    cabinets {
                        id
                        name(lang: $lang)
                        nameVariants {
                            en
                            ru
                            lv
                        }
                        serviceGroups {
                            id
                            name(lang: $lang)
                            type
                        }
                    }
                }
            }
            ...onMasterCabinetList
        }
    }
    ${MASTER_CABINET_LIST_FRAGMENT}
`;

const withData = graphql(GET_CABINETS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            search: ownProps.searchQuery || undefined,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading, error } }) => ({
        loading,
        cabinets: !loading && viewer ? viewer.salon.cabinets : [],
    }),
});

const mapStateToProps = ({ staff, user, intl }) => ({
    searchQuery: staff.get('searchQuery'),
    salon: user.get('salon'),
    currentLanguage: intl.get('locale'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(CabinetList);
