import { reduxForm, SubmissionError } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ChangePasswordForm from '../components/ChangePasswordForm';
import validate from './changePasswordValidator';
import { login } from '../../../actions/user';
import { injectIntl } from 'react-intl';
const withForm = reduxForm({
    form: 'change-password',
    touchOnBlur: false,
    validate,
});

// GraphQL query to change user's password
const CHANGE_PASSWORD_QUERY = gql`
    mutation changePasswordByToken($input: ChangePasswordByTokenInput!) {
        changePasswordByToken(input: $input) {
            token
            user {
                id
                ... on Administrator {
                    firstName
                    lastName
                    salon {
                        id
                        administrator {
                            id
                        }
                    }
                }
            }
        }
    }
`;

// Change user's password using secret token
const withData = graphql(CHANGE_PASSWORD_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: ({ password }) => {
            const mutation = mutate({
                variables: {
                    input: {
                        token: ownProps.token,
                        password: password,
                    },
                },
            });

            return (
                mutation
                    // Update state of password reset
                    .then(
                        ({
                            data: {
                                changePasswordByToken: { user, token, role },
                            },
                        }) => {
                            let userProps;
                            let url;

                            if (role === 'SUPPLIER') {
                                userProps = {
                                    id: user.id,
                                    token,
                                    role,
                                };
                                url = '/supplier/seminars';
                            } else {
                                userProps = {
                                    id: user.id,
                                    role,
                                    token,
                                    salon: user.salon.id,
                                    isOwner:
                                        user.id === user.salon.administrator.id,
                                };
                                url = '/salon/appointments';
                            }

                            ownProps.login(userProps);
                            ownProps.history.push(url);
                        },
                    )
                    // Handle possible errors
                    .catch(error => {
                        if (error.graphQLErrors) {
                            const graphQLError = error.graphQLErrors[0];

                            switch (graphQLError.name) {
                                case 'NotExists': {
                                    throw new SubmissionError({
                                        _error:
                                            'User with provided token does not exists in our database.',
                                    });
                                }
                                case 'NotAllowed': {
                                    throw new SubmissionError({
                                        _error:
                                            'Invalid token or it has expired.',
                                    });
                                }
                                default: {
                                    throw new SubmissionError({
                                        _error:
                                            'Unable to change your password.',
                                    });
                                }
                            }
                        }

                        throw new SubmissionError({
                            _error: 'Unable to change your password.',
                        });
                    })
            );
        },
    }),
});

export default compose(
    withRouter,
    connect(
        null,
        {
            login,
        },
    ),
    injectIntl,
    withData,
    withForm,
)(ChangePasswordForm);
