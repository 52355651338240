/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup, Message } from 'semantic-ui-react';
import styled from 'styled-components';

type CreateInvoiceButtonProps = {
    loading: boolean,
    disabled: boolean,
    onClick: Function,
};

const StyledButton = styled(Button)`
    opacity: 0.45;
    cursor: default !important;
`;

const DisabledButton = props => (
    <StyledButton color="green" floated="right" {...props}>
        <Icon name="money" />
        <FormattedMessage
            id="pages.appointments.details.CreateInvoiceButton.create"
            defaultMessage="Create Invoice"
        />
    </StyledButton>
);

const CreateInvoiceButton = ({
    loading,
    disabled,
    onClick,
}: CreateInvoiceButtonProps) =>
    disabled ? (
        <Popup
            style={{ color: 'red', padding: 0 }}
            trigger={<DisabledButton />}
        >
            <Message error>
                <Icon name="warning sign" />
                <FormattedMessage
                    id="pages.appointments.details.CreateInvoiceButton.IncompleteActions"
                    defaultMessage="To create invoice, please apply current appointment changes"
                />
            </Message>
        </Popup>
    ) : (
        <Button
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            color="green"
            floated="right"
        >
            <Icon name="money" />
            <FormattedMessage
                id="pages.appointments.details.CreateInvoiceButton.create"
                defaultMessage="Create Invoice"
            />
        </Button>
    );

export default CreateInvoiceButton;
