/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, filter, some } from 'lodash';
import { formValueSelector } from 'redux-form';

import MasterServiceFieldList from '../../components/form/MasterServiceFieldList';
import { MASTER_SERVICE_FIELDS_FRAGMENT } from '../masterAccessQueries';

const GET_CABINETS_QUERY = gql`
    query getMasters(
        $salon: ID!
        $date: String!
        $lang: String!
        $showArchive: Boolean!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    masters(date: $date) {
                        nodes {
                            id
                            firstName
                            lastName
                            schedule(startDate: $date) {
                                id
                                startAt
                                endAt
                            }
                            freeWorkingTime(
                                date: $date
                                showArchive: $showArchive
                            ) {
                                startAt
                                endAt
                            }
                            serviceGroups {
                                id
                                name(lang: $lang)
                                services {
                                    id
                                    name(lang: $lang)
                                    duration
                                    price
                                }
                                cabinets {
                                    id
                                    name(lang: $lang)
                                    schedule(startDate: $date) {
                                        startAt
                                        endAt
                                    }
                                    freeWorkingTime(
                                        date: $date
                                        showArchive: $showArchive
                                    ) {
                                        startAt
                                        endAt
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ...onMasterServiceFields
        }
    }
    ${MASTER_SERVICE_FIELDS_FRAGMENT}
`;

const withData = graphql(GET_CABINETS_QUERY, {
    options: ({ salon, date, currentLanguage, showArchive }) => ({
        variables: {
            salon,
            date,
            lang: currentLanguage,
            showArchive,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }: Object) => ({
        masters:
            !loading && viewer
                ? filter(
                      get(viewer, 'salon.masters.nodes', []),
                      ({ schedule }) => schedule && schedule.length,
                  )
                : [],
        loading,
    }),
});

const mapStateToProps = ({ salon, user, intl, ...state }, { meta }) => ({
    salon: user.get('salon'),
    date: formValueSelector(meta.form)(state, 'startDate'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    showArchive: salon.get('showArchive'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(MasterServiceFieldList);
