/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import { map, uniqBy, property } from 'lodash';
import Fuse from 'fuse.js';

import { Select } from '../../../../../components';

const GET_ADMINISTRATORS_QUERY = gql`
    query getAdministrators($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    administrator {
                        id
                        ...administrator
                    }
                    administrators {
                        id
                        ...administrator
                    }
                    masters {
                        nodes {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }

    fragment administrator on Administrator {
        firstName
        lastName
    }
`;

const mapOptions = ({ id, firstName, lastName }) => ({
    text: `${firstName} ${lastName}`,
    value: id,
});

const withData = graphql(GET_ADMINISTRATORS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
        },
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        options:
            !loading && viewer
                ? map(
                      uniqBy(
                          [
                              viewer.salon.administrator,
                              ...viewer.salon.administrators,
                              ...viewer.salon.masters.nodes,
                          ],
                          property('id'),
                      ),
                      mapOptions,
                  )
                : [],
        sellers:
            !loading && viewer
                ? uniqBy(
                      [
                          viewer.salon.administrator,
                          ...viewer.salon.administrators,
                          ...viewer.salon.masters.nodes,
                      ],
                      property('id'),
                  )
                : [],
    }),
});

const mapStateToProps = ({ user }) => ({
    salon: user.get('salon'),
});

const limitedProps = mapProps(({ salon, dispatch, meta, ...props }) => ({
    ...props,
}));

const handlers = withHandlers({
    search: ({ sellers }) => (items, value) => {
        const fuse = new Fuse(sellers, {
            keys: ['lastName', 'firstName'],
            shouldSort: true,
            threshold: 0.3,
        });

        return map(fuse.search(value), mapOptions);
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
    limitedProps,
)(Select);
