import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import ProductList from '../components/ProductList';
import { get } from 'lodash';

// Query for list of products of current supplier
const GET_PRODUCTS_QUERY = gql`
    query getProducts(
        $first: Int
        $after: String
        $salonFilter: [ID]
        $search: String
    ) {
        viewer {
            id
            ... on Supplier {
                id
                products(
                    first: $first
                    after: $after
                    salonFilter: $salonFilter
                    search: $search
                ) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        cursor
                        node {
                            id
                            ...product
                        }
                    }
                }
            }
        }
    }

    fragment product on Product {
        name
        salon {
            id
            name
            address
        }
        code
        manufacturer {
            id
            name
        }
        quantity
        price
        supplierPrice
        type
    }
`;

const withData = graphql(GET_PRODUCTS_QUERY, {
    options: ownProps => ({
        variables: {
            first: 20,
            salonFilter: ownProps.salonFilter || [],
            search: ownProps.searchQuery || undefined,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer, error, fetchMore } }) => ({
        loading,
        fetchMore,
        products: get(viewer, 'products.edges', []),
        pageInfo: get(viewer, 'products.pageInfo', {}),
    }),
});

const mapStateToProps = ({ inventory, user }) => ({
    searchQuery: inventory.get('searchQuery'),
    salonFilter: inventory.get('salonFilter'),
});

const handlers = withHandlers({
    /**
     * Handle load more results event by requesting data after
     * end cursor from the last request.
     */
    loadMore: ownProps => () => {
        const {
            loading,
            pageInfo,
            fetchMore,
            salonFilter,
            searchQuery,
        } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_PRODUCTS_QUERY,
            variables: {
                first: 20,
                after: pageInfo.endCursor,
                salonFilter: salonFilter || [],
                search: searchQuery || undefined,
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.products.edges;
                const newEdges = fetchMoreResult.viewer.products.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.products.edges = [...prevEdges, ...newEdges];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(ProductList);
