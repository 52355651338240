/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import type { ModalDialog, Appointment } from '../../../../type';

const MessageBlock = styled(Message)`
    background-color: transparent !important;
    border: 2px solid #ff4545;
    color: #ff4545 !important;
    font-weight: bold;
    box-shadow: none !important;
`;

type ArchiveDialogProps = {
    appointment: Appointment,
    onArchive: Function,
} & ModalDialog;

const ArhiveDialog = ({
    appointment,
    trigger,
    open,
    onClose,
    submitting,
    onArchive,
    error,
}: ArchiveDialogProps) => (
        <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
            <Header icon>
                <Icon name="archive" />
                <FormattedMessage
                    id="pages.appointments.details.ArhiveDialog.title"
                    defaultMessage="Archive Appointment"
                />
            </Header>
            <Modal.Content>
                <FormattedMessage
                    id="pages.appointments.AppointmentDetails.confirmMessage"
                    defaultMessage="Are you sure you want to archive this appointment?"
                    tagName="p"
                />
                {error && (
                    <MessageBlock error>
                        <Icon name="warning sign" />
                        {error}
                    </MessageBlock>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} color="red" basic inverted>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.appointments.details.ArhiveDialog.no"
                        defaultMessage="No"
                    />
                </Button>
                <Button
                    onClick={onArchive}
                    disabled={submitting}
                    color="green"
                    inverted
                >
                    <Icon name="checkmark" />
                    <FormattedMessage
                        id="pages.appointments.details.ArhiveDialog.yes"
                        defaultMessage="Yes"
                    />
                </Button>
            </Modal.Actions>
        </Modal>
    );

export default ArhiveDialog;
