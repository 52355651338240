/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {
    compose,
    withState,
    withProps,
    lifecycle,
    branch,
    renderNothing,
} from 'recompose';
import moment from 'moment';
import { calcEventOffset, calcTimetableHeight } from '../../lib/schedule';

import { CELL_STEP } from './Cell';

// Update position and value of timeline once a minute
const UPDATE_INTERVAL = 60 * 1000;

const Wrapper = styled.div`
    position: absolute;
    top: ${props => props.offset + 8}px;
    left: 108px;
    right: 0px;
    height: 15px;
    margin-top: ${props => props.headerCellHeight - 1}px;
`;

const Line = styled.div`
    position: absolute;
    top: 10px;
    height: 1px;
    width: 100%;
    background-color: #ff3b30;
    z-index: 1;
`;

const Time = styled.div`
    position: absolute;
    text-align: right;
    top: 0px;
    bottom: 0px;
    left: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #ff3b30;
    margin-left: -71px;
    width: 71px;
    height: 20px;
    border: 1px solid #ff3b30;
    border-radius: 5px;
    padding-right: 18px;
    background-color: #fff;
`;

const NowLine = ({ offset, columns, schedule, width, headerCellHeight }) => (
    <Wrapper
        offset={offset}
        columns={columns}
        width={width}
        headerCellHeight={headerCellHeight}
    >
        <Time>{moment().format(schedule.format)}</Time>
        <Line />
    </Wrapper>
);

// Store now time value in order to update timeline each minute
const withNowState = withState('now', 'setNow', moment().format());

// Calculate offset in a prop
const withOffset = withProps(({ cellHeight, schedule, now }) => ({
    offset: calcEventOffset(cellHeight, now, schedule.startAt, CELL_STEP),
}));

/**
 * Update now time periodicly with specific interval
 */
let intervalId;

const componentDidMount = function() {
    const { setNow } = this.props;
    intervalId = setInterval(() => {
        setNow(moment().format());
    }, UPDATE_INTERVAL);
};

const componentWillUnmount = function() {
    clearInterval(intervalId);
};

/**
 * Don't show line if
 * 1. It's out of current schedule
 * 2. Schedule is not for current date
 */
const withNoRender = branch(function({ date, offset, schedule, cellHeight }) {
    const timelineHeight = calcTimetableHeight(
        cellHeight,
        schedule.startAt,
        schedule.endAt,
        CELL_STEP,
    );

    if (
        !moment(date).isSame(Date.now(), 'day') ||
        offset < 4 ||
        offset >= timelineHeight
    ) {
        return true;
    }
}, renderNothing);

export default compose(
    withNowState,
    withOffset,
    lifecycle({ componentDidMount, componentWillUnmount }),
    withNoRender,
)(NowLine);
