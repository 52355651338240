/**
 * @flow
 */

/**
 * Set date range to generate report for
 *
 * @param {String} fromDate - A starting date
 * @param {String} toDate - An ending date
 */
export const setDate = (fromDate: string, toDate: string) => ({
    type: 'REPORT.SET_DATE',
    fromDate,
    toDate,
});

export const filterBySalons = (salons: array) => ({
    type: 'REPORT.FILTER.BY.SALONS',
    salons,
});

export const filterByMasters = (masters: array) => ({
    type: 'REPORT.FILTER.BY.MASTERS',
    masters,
});

export const filterByManufacturers = (manufacturers: array) => ({
    type: 'REPORT.FILTER.BY.MANUFACTURERS',
    manufacturers,
});

export const setAppRecords = (showAppRecords: Boolean) => ({
    type: 'REPORT.SET_APP_RECORDS',
    showAppRecords,
});

export const setReportFilter = (reports: string) => ({
    type: 'REPORT.SET_REPORT_FILTER',
    reports,
});
