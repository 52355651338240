import styled from 'styled-components';

export let CELL_HEIGHT_PX = 40;
export const CELL_STEP = 60;

const Cell = styled.div`
    border-bottom: 1px solid #e9e9e9;
    min-height: ${CELL_HEIGHT_PX}px;
    font-weight: 600;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Cell;
