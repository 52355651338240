/**
 * @flow
 */

import { connect } from 'react-redux';
import { search, filterBySalons } from '../../../actions/inventory';
import { withProps, compose } from 'recompose';
import { map } from 'lodash';

import InventoryMenu from '../components/InventoryMenu';

import type { Dispatch } from '../../../type';

const mapStateToProps = ({ inventory }) => ({
    searchQuery: inventory.get('searchQuery'),
    salonFilter: inventory.get('salonFilter'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSearch: (e, { value }) => {
        dispatch(search(value));
    },
    onSalonSelect: (e, { value }) => {
        dispatch(filterBySalons(value));
    },
});

const mapOptions = withProps(({ salons }) => ({
    salonOptions: map(salons, ({ id, name, address }) => ({
        text: name,
        value: id,
        description: address,
    })),
}));

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    mapOptions,
)(InventoryMenu);
