import styled from 'styled-components';

const Label = styled.label`
    color: #626262 !important;
    display: block !important;
    margin-bottom: 4px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
`;

export default Label;
