import { addLocaleData } from 'react-intl';

import en from 'react-intl/locale-data/en';
import lv from 'react-intl/locale-data/lv';
import ru from 'react-intl/locale-data/ru';

import * as locales from './locales';

addLocaleData([...en, ...lv, ...ru]);

export { locales };
