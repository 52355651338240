/**
 * @flow
 */

/**
 * Search services that match specified query
 * 
 * @param {String} query - The query to search for
 */
export const search = (query: string) => ({
    type: 'SERVICES.SEARCH',
    query,
});
