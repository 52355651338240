/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Menu as BaseMenu, Image, Icon as BaseIcon } from 'semantic-ui-react';
import AnimatedMenuItem from '../../components/AnimatedMenuItem';
import { UserMenu } from '../../components/userMenu';
import Language from '../../containers/Language';

const Menu = styled(BaseMenu)`
    &.menu {
        flex-shrink: 0;
        height: 60px;
        background: #323237 !important;
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const Logo = styled(Image)`
    height: 40px;
`;

const LogoMenuItem = styled(BaseMenu.Item)`
    &.item {
        margin-right: 40px !important;
    }
`;

const Icon = styled(BaseIcon)`
    &.icon {
        font-size: 1.2em;
    }
`;

const Navigation = () => (
    <Menu inverted secondary>
        <LogoMenuItem>
            <Logo src="/images/appotime-logo-dashboard.png" />
        </LogoMenuItem>
        <AnimatedMenuItem
            visible={<Icon name="calendar" color="grey" />}
            hidden={<Icon name="calendar" />}
            text={
                <FormattedMessage
                    id="navbar.menu.seminars"
                    defaultMessage="Seminars"
                />
            }
            to="/supplier/seminars"
            link
        />
        <AnimatedMenuItem
            visible={<Icon name="clipboard" color="grey" />}
            hidden={<Icon name="clipboard" />}
            text={
                <FormattedMessage
                    id="navbar.menu.warehouse"
                    defaultMessage="Warehouse"
                />
            }
            to="/supplier/warehouse"
            link
        />
        <BaseMenu.Menu position="right">
            <BaseMenu.Item fitted>
                <Language />
            </BaseMenu.Item>
            <BaseMenu.Item>
                <UserMenu />
            </BaseMenu.Item>
        </BaseMenu.Menu>
    </Menu>
);

export default Navigation;
