import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MastersList from '../components/MastersList';

// Query for list of masters for current salon
export const GET_MASTERS_QUERY = gql`
    query getMasters($salon: ID!, $search: String, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    masters(search: $search) {
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                        nodes {
                            id
                            ...master
                        }
                    }
                }
            }
        }
    }

    fragment master on Master {
        firstName
        lastName
        phone
        email
        status
        type
        gender
        serviceGroups {
            id
            name(lang: $lang)
            type
        }
    }
`;

const withData = graphql(GET_MASTERS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            search: ownProps.searchQuery || undefined,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading, error } }) => ({
        loading,
        masters: !loading && viewer ? viewer.salon.masters.nodes : [],
    }),
});

const mapStateToProps = ({ staff, user, intl }) => ({
    searchQuery: staff.get('searchQuery'),
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(MastersList);
