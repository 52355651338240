export function prepareFloatForServer(number) {
    return typeof number === 'string' ? number.replace(',', '.') : number;
}

export function priceIsNotValid(price) {
    return price.match(/[^,.\d]/);
}

export function isNotNumber(number) {
    return number.match(/[^\d]/);
}
