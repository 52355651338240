/**
 * @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Modal, Button } from '../../../components';
import SupplierForm from './form/SupplierForm';

import type { ReduxForm, ModalDialog } from '../../../type';

type CreateSupplierDialogProps = {
    trigger: Object,
    onClose: Function,
    userLookupStatus: string,
    onModalClose: Function,
    checkFormPristine: Function,
    handleClear: Function,
} & ModalDialog &
    ReduxForm;

const CreateSupplierDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    onModalClose,
    checkFormPristine,
    handleClear,
    ...props
}: CreateSupplierDialogProps) => (
    <Modal trigger={trigger} onClose={onModalClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.suppliers.createSupplier.newSupplier"
                defaultMessage="New Supplier"
            />
        </Modal.Header>
        <Modal.Content>
            <SupplierForm
                {...props}
                userLookupStatus={userLookupStatus}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.suppliers.createSupplier.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button onClick={handleClear} disabled={submitting} basic>
                <FormattedMessage
                    id="pages.suppliers.createSupplier.clear"
                    defaultMessage="Clear"
                />
            </Button>
            <Button onClick={onModalClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.suppliers.createSupplier.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default CreateSupplierDialog;
