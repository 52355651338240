/**
 * @flow
 */

import styled from 'styled-components';

import { Header as BaseHeader } from '../../../components';

const Header = styled(BaseHeader)`
    &.header {
        line-height: 36px;
        font-size: 24px;
        font-weight: 600;
    }
`;

const Subheader = styled(BaseHeader.Subheader)`
    &.sub.header {
        line-height: 24px !important;
        font-size: 16px !important;
    }
`;

Header.Subheader = Subheader;

export default Header;
