/**
 *  @flow
 */

import gql from 'graphql-tag';
import {
    graphql
} from 'react-apollo';
import {
    connect
} from 'react-redux';
import {
    compose,
    withProps
} from 'recompose';
import {
    reduxForm,
    SubmissionError,
} from 'redux-form';

import {
    omit
} from 'lodash'

import PersonalInformation from '../../components/details/PersonalInformation';
import validate from '../form/administratorFormValidate';

import type {
    Administrator
} from '../../../../type';
import {
    injectIntl
} from 'react-intl';

const UPDATE_ADMINISTRATOR_QUERY = gql `
    mutation updateAdministrator($input: UpdateAdministratorInput!) {
        updateAdministrator(input: $input) {
            administrator {
                id
                firstName
                lastName
                phone
                email
            }
        }
    }
`;

const withData = graphql(UPDATE_ADMINISTRATOR_QUERY, {
    props: ({
        mutate,
        ownProps
    }) => ({
        // Handle form submission and update administrator
        onSubmit: formData => {
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const mutation = mutate({
                variables: {
                    input: {
                        userId: ownProps.user,
                        salonId: ownProps.salon,
                        administratorId: ownProps.administrator.id,
                        ...omit(formData, 'passwordRepeat'),
                        phone
                    },
                },
                // Implement optimistic response to compensate network latency
                // and update administrator directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateAdministrator: {
                        __typename: 'UpdateAdministratorPayload',
                        administrator: {
                            __typename: 'Administrator',
                            id: ownProps.administrator.id,
                            firstName: formData.firstName,
                            lastName: formData.lastName,
                            phone: phone,
                            email: formData.email,
                        },
                    },
                },
            });

            return mutation
                .then(() => {
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError = error.graphQLErrors && error.graphQLErrors[0];
                    throw new SubmissionError({
                        _error: (graphQLError && graphQLError.message) || 'Something went wrong',
                    });
                });
        },
    }),
});

// Provide initial values to the form to fulfill fields with
// data from provided administrator
const initialValues = withProps(({
    administrator
}: {
    administrator: Administrator
}) => ({
    initialValues: {
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        phone: administrator.phone,
        email: administrator.email,
    },
}));

const withForm = reduxForm({
    form: 'editAdministrator',
    touchOnBlur: false,
    validate,
});

export default compose(
    connect(({
        user
    }) => ({
        user: user.get('id'),
        salon: user.get('salon')
    })),
    injectIntl,
    withData,
    initialValues,
    withForm,
)(PersonalInformation);
