/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Container as BaseContainer, Menu as BaseMenu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { PageHeader } from '../../../components';
import CreateAppointmentButton from '../../appointments/components/CreateAppointmentButton';
import AppointmentTable from '../containers/AppointmentTable';
import DateRange from '../containers/DateRange';
import BaseMasterSelect from '../containers/MasterSelect';
import MasterInfoButton from './MasterInfoButton';
import GenerateScheduleButton from '../../settings/components/schedule/GenerateScheduleButton';
import type { Master } from '../../../type';

const Container = styled(BaseContainer)`
    &.container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const TimetableWrapper = styled.div`
    margin: 0.5em -2em 0 -2em;
    flex: 1;
`;

const MasterSelect = styled(BaseMasterSelect)`
    .ui.selection.dropdown& {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

type MasterAppointmentsPageProps = {
    master: Master,
    masters: Array<Master>,
};

const MasterAppointmentsPage = ({ master, masters }: MasterAppointmentsPageProps) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.masterAppointments.header"
                        defaultMessage="Master Appointments"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item>
                    <GenerateScheduleButton master={master} />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <MasterSelect masters={masters} />
                    <MasterInfoButton master={master} />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <DateRange />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <CreateAppointmentButton master={master.id} disabled={!master.id} />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <TimetableWrapper>
            <AppointmentTable />
        </TimetableWrapper>
    </Container>
);

export default MasterAppointmentsPage;
