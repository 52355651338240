/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';
import { omit } from 'lodash';
import { prepareFloatForServer } from '../../../lib/numberFormatter';

import AddProductDialog from '../components/AddProductDialog';
import validate from './form/productFormValidate';

import { GET_PRODUCTS_QUERY } from './ProductsList';
import { injectIntl, defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.inventory.AddProductDialog.nameExistingsError',
        defaultMessage: 'Product with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'addProduct',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const ADD_PRODUCT_QUERY = gql`
    mutation addProduct($input: AddProductInput!) {
        addProduct(input: $input) {
            product {
                id
                name
                code
                price
                supplierPrice
                quantity
                manufacturer {
                    id
                    name
                }
                type
            }
        }
    }
`;

const withData = graphql(ADD_PRODUCT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and add a new product
        onSubmit: formData => {
            const quantity = parseInt(formData.quantity);
            const price = prepareFloatForServer(formData.price);
            const supplierPrice =
                formData.supplierPrice &&
                prepareFloatForServer(formData.supplierPrice);
            const mutation = mutate({
                variables: {
                    input: {
                        ...omit(formData, ['quantity']),
                        quantity,
                        price,
                        supplierPrice,
                        salon: ownProps.salon,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and add a new product directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    addProduct: {
                        __typename: 'AddProductPayload',
                        product: {
                            __typename: 'Product',
                            id: uuid.v4(),
                            manufacturer: {
                                __typename: 'Manufacturer',
                                id: formData.manufacturer,
                                name: '', // Can we get actual name from cache?
                            },
                            code: formData.code || null,
                            supplierPrice: formData.code || null,
                            ...omit(formData, ['manufacturer']),
                        },
                    },
                },
                // Update local store with a new product
                update: (store, { data: { addProduct } }) => {
                    const data = store.readQuery({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            searchQuery: ownProps.searchQuery,
                            manufacturersFilter: ownProps.manufacturersFilter,
                            first: 500,
                        },
                    });

                    const newProduct = {
                        node: addProduct.product,
                        __typename: 'ClientEdge',
                        cursor: btoa(addProduct.product.id),
                    };

                    data.viewer.salon.products.edges.unshift(newProduct);

                    store.writeQuery({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            searchQuery: ownProps.searchQuery,
                            manufacturersFilter: ownProps.manufacturersFilter,
                            first: 500,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after product was added
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'NAME_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    name: intl.formatMessage(
                                        intlMessages.nameExistingsError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

// This allow to reset initial values of the form, even if the form itself is not destroying
const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

const mapStateToProps = ({ inventory, user }) => ({
    searchQuery: inventory.get('searchQuery'),
    manufacturersFilter: inventory.get('manufacturersFilter'),
    salon: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddProductDialog);
