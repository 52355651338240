import React from 'react';
import { isEmpty } from 'lodash';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { Loader, Grid } from 'semantic-ui-react';

import { Table } from '../../../../components';

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={5} textAlign="center">
            <FormattedMessage
                id="pages.report.ReportTable.noRating"
                defaultMessage="You don't have client rating yet"
            />
        </Table.Cell>
    </Table.Row>
);

const RatingRow = ({ client }) => (
    <Table.Row>
        <Table.Cell>
            {`${client.firstName} ${client.lastName || ''}`}
        </Table.Cell>
        <Table.Cell>{client.phone}</Table.Cell>
        <Table.Cell textAlign="center">{client.totalVisits}</Table.Cell>
        <Table.Cell textAlign="right">{client.firstAppointment}</Table.Cell>
        <Table.Cell textAlign="right">{client.lastAppointment}</Table.Cell>
    </Table.Row>
);

const RatingTable = ({ loading, clientRating }) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.client"
                        defaultMessage="Client"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.report.ReportTable.rating.phone"
                        defaultMessage="Phone"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    <FormattedMessage
                        id="pages.report.ReportTable.rating.totalVisists"
                        defaultMessage="Total visits"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.rating.firstVisit"
                        defaultMessage="First visit"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.report.ReportTable.rating.lastVisit"
                        defaultMessage="Last visit"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(clientRating) ? (
                <EmptyMessage />
            ) : (
                clientRating.map(
                    client =>
                        !!client && (
                            <RatingRow key={client.id} client={client} />
                        ),
                )
            )}
        </Table.Body>
    </Table>
);

const ClientRatingTable = ({ loading, clientRating }) => (
    <Grid columns={1} padded>
        <Grid.Row>
            <Grid.Column>
                <RatingTable loading={loading} clientRating={clientRating} />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

// Show spinner while report loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(ClientRatingTable);
