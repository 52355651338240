/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import { omit, get, pick, findIndex, isEqual, keys, find } from 'lodash';
import { formatNumber } from 'libphonenumber-js';

import PersonalInformation from '../../components/details/PersonalInformation';
import withUserLookup from '../../../../lib/withUserLookup';
import validate from '../form/clientFormValidate';
import { injectIntl, defineMessages } from 'react-intl';

import { GET_CLIENTS_QUERY } from '../ClientsList';
import { CLIENT_SUMMARY_QUERY } from '../ClientsPage';
import { GET_ALL_CLIENTS_QUERY } from '../../../appointments/containers/form/ClientSelect';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.clients.form.ClientForm.existingsError',
        defaultMessage: 'This client does not exists',
    },
    phoneExistingsError: {
        id: 'pages.clients.form.ClientForm.phoneExistingsError',
        defaultMessage: 'Client with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.clients.form.ClientForm.emailExistingsError',
        defaultMessage: 'Client with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.clients.form.ClientForm.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const UPDATE_CLIENT_QUERY = gql`
    mutation updateClient($input: UpdateClientInput!) {
        updateClient(input: $input) {
            client {
                id
                email
                firstName
                lastName
                phone
                notes
                birthday
                gender
                type
                blockedAt
                blockedBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

const updateSummary = (store, salon, gender) => {
    const data = store.readQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
    });

    const summary = get(data, 'viewer.salon.clients.summary');

    if (gender === 'MALE') {
        summary.males++;
        summary.females--;
    } else if (gender === 'FEMALE') {
        summary.females++;
        summary.males--;
    }

    store.writeQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
        data,
    });
};

const withData = graphql(UPDATE_CLIENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update client
        onSubmit: formData => {
            const birthday = formData.birthday
                ? moment(formData.birthday).format('MM.DD.YYYY')
                : null;
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const blockedAt = formData.blocked
                ? ownProps.client.blockedAt ||
                  moment().format('YYYY-MM-DD HH:mm')
                : null;
            const blockedBy = formData.blocked
                ? (ownProps.client.blockedBy && ownProps.client.blockedBy.id) ||
                  ownProps.userId
                : null;
            const { blocked, ...rest } = formData;
            const mutation = mutate({
                variables: {
                    input: {
                        salonId: ownProps.salon,
                        clientId: ownProps.client.id,
                        ...rest,
                        phone,
                        birthday,
                        blockedAt,
                        blockedBy,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and update client directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateClient: {
                        __typename: 'UpdateClientPayload',
                        client: {
                            __typename: 'Client',
                            id: ownProps.client.id,
                            type: ownProps.client.type,
                            ...omit(formData, ['phone']),
                            phone,
                            birthday,
                            blockedAt: ownProps.client.blockedAt,
                            blockedBy: ownProps.client.blockedBy,
                        },
                    },
                },
                update: (store, response) => {
                    const client = get(response, 'data.updateClient.client');

                    if (client) {
                        try {
                            const data = store.readQuery({
                                query: GET_ALL_CLIENTS_QUERY,
                                variables: {
                                    salon: ownProps.salon,
                                    client: ownProps.value || '',
                                    withClient: !!ownProps.value,
                                },
                            });

                            if (
                                !data.viewer.salon.clients.nodes.some(
                                    o => o.id === client.id,
                                )
                            ) {
                                client.deletedAt = null;
                                data.viewer.salon.clients.nodes.unshift(client);
                            }

                            store.writeQuery({
                                query: GET_ALL_CLIENTS_QUERY,
                                variables: {
                                    salon: ownProps.salon,
                                    client: client.id,
                                    withClient: true,
                                },
                                data,
                            });
                        } catch (e) {
                            // It's safe to ignore this exception
                        }
                    }

                    // check if client connection was linked to another client account
                    if (client && client.id !== ownProps.client.id) {
                        const data = store.readQuery({
                            query: GET_CLIENTS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                search: ownProps.searchQuery || undefined,
                                first: 20,
                            },
                        });

                        const idx = findIndex(
                            data.viewer.salon.clients.edges,
                            edge => edge.node.id === ownProps.client.id,
                        );

                        // replace updateable client
                        if (idx !== -1) {
                            data.viewer.salon.clients.edges[idx].node = client;
                        }

                        store.writeQuery({
                            query: GET_CLIENTS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                search: ownProps.searchQuery || undefined,
                                first: 20,
                            },
                            data,
                        });
                    }
                    if (client.gender !== ownProps.client.gender)
                        updateSummary(store, ownProps.salon, client.gender);
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(({ data: { updateClient } }) => {
                    if (ownProps.onClientUpdate) {
                        ownProps.onClientUpdate(updateClient.client);
                    }
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'NotExists') {
                            if (
                                graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.existingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (
                                graphQLError.data.error ===
                                'EMAIL_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const formatPhone = phone =>
    phone ? formatNumber(phone, 'International') : '+371';

const compareClientWithFormValues = (client, formValues) => {
    const pristineValues = pick(client, keys(formValues));
    pristineValues.phone = formatPhone(pristineValues.phone);
    return isEqual(formValues, pristineValues);
};

const props = withProps(({ client, userLookupStatus, formValues }) => ({
    // Make form as read-only if client is global
    readOnly:
        userLookupStatus === 'EXISTS' ||
        (client.type === 'GLOBAL' &&
            compareClientWithFormValues(
                client,
                omit(formValues, ['notes', 'blocked', 'birthday']),
            )),
}));

// Provide initial values to the form to fulfill fields with
// data from provided client
const initialValues = withPropsOnChange(
    (props, nextProps) =>
        !isEqual(props.userLookupUser, nextProps.userLookupUser),
    ({ open, client, userLookupUser, formValues }) => {
        if (open) {
            const fields = [
                'email',
                'firstName',
                'lastName',
                'gender',
                'birthday',
                'blocked',
            ];
            let initialValues;
            const birthday = client.birthday
                ? moment(client.birthday).format('MM.DD.YYYY')
                : '';
            const blocked = client.blockedAt ? true : false;
            if (userLookupUser) {
                initialValues = Object.assign(pick(userLookupUser, fields), {
                    phone: formatPhone(userLookupUser.phone),
                    notes: client.notes,
                    birthday,
                    blocked,
                });
            } else {
                initialValues =
                    formValues ||
                    Object.assign(pick(client, fields), {
                        phone: formatPhone(client.phone),
                        notes: client.notes,
                        birthday,
                        blocked,
                    });
            }
            return { initialValues };
        }
    },
);

const withForm = reduxForm({
    form: 'editClient',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withPristineHandlers = withHandlers({
    checkFormPristine: ({ formValues, client }) => () =>
        compareClientWithFormValues(client, formValues),
});

export default compose(
    connect(({ user, clients, ...state }) => ({
        salon: user.get('salon'),
        userId: user.get('id'),
        searchQuery: clients.get('searchQuery'),
        formValues: getFormValues('editClient')(state),
    })),
    injectIntl,
    withData,
    withUserLookup,
    props,
    initialValues,
    withForm,
    withPristineHandlers,
)(PersonalInformation);
