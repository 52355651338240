/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Container as BaseContainer, Divider } from 'semantic-ui-react';

import { PromoSection } from './promoSection';
import BenefitsSection from './BenefitsSection';
import ClientsSection from './ClientsSection';
import InventorySection from './InventorySection';
import ReportingSection from './ReportingSection';
// import TrialSection from './TrialSection';
import CompaniesSection from './CompaniesSection';
import FooterSection from './FooterSection';

import styles from '../../../styles.js';

const Container = styled(BaseContainer)`
    ${styles.sansProFontFamily} !important;
    background-color: #ffffff;
`;

const LandingPage = () => (
    <Container fluid>
        <PromoSection />
        <BenefitsSection />
        <ClientsSection />
        <InventorySection />
        <Divider fitted />
        <ReportingSection />
        {/* <TrialSection /> */}
        <CompaniesSection />
        <FooterSection />
    </Container>
);

export default LandingPage;
