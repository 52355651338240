/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import {
    map,
    omit,
    get,
    pick,
    findIndex,
    isEqual,
    keys,
    property,
} from 'lodash';
import { formatNumber } from 'libphonenumber-js';

import PersonalInformation from '../../components/details/PersonalInformation';
import withUserLookup from '../../../../lib/withUserLookup';
import validate from '../form/masterFormValidate';
import { injectIntl, defineMessages } from 'react-intl';

import { GET_MASTERS_QUERY } from '../MastersList';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.staff.form.existingsError',
        defaultMessage: 'This master does not exist',
    },
    phoneExistingsError: {
        id: 'pages.staff.form.phoneExistingsError',
        defaultMessage: 'Master with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.staff.form.emailExistingsError',
        defaultMessage: 'Master with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.clients.form.ClientForm.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const UPDATE_MASTER_QUERY = gql`
    mutation updateMaster($input: UpdateMasterInput!, $lang: String!) {
        updateMaster(input: $input) {
            master {
                id
                firstName
                lastName
                phone
                email
                status
                type
                gender
                serviceGroups {
                    id
                    name(lang: $lang)
                    type
                }
            }
        }
    }
`;

const withData = graphql(UPDATE_MASTER_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update master
        onSubmit: formData => {
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const mutation = mutate({
                variables: {
                    input: {
                        salonId: ownProps.salon,
                        masterId: ownProps.master.id,
                        ...formData,
                        phone,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency
                // and update master directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateMaster: {
                        __typename: 'UpdateMasterPayload',
                        master: {
                            __typename: 'Master',
                            id: ownProps.master.id,
                            type: ownProps.master.type,
                            serviceGroups: map(
                                formData.serviceGroups,
                                serviceGroup => ({
                                    __typename: 'ServiceGroups',
                                    id: serviceGroup,
                                    name: '',
                                    type: '',
                                }),
                            ),
                            ...omit(formData, ['serviceGroups']),
                            phone,
                        },
                    },
                },
                update: (store, response) => {
                    const master = get(response, 'data.updateMaster.master');
                    // check if master connection was linked to another master account
                    if (master && master.id !== ownProps.master.id) {
                        const data = store.readQuery({
                            query: GET_MASTERS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                            },
                        });

                        const idx = findIndex(
                            data.viewer.salon.masters.nodes,
                            master => master.id === ownProps.master.id,
                        );

                        // remove previous master and add a new master to the store
                        data.viewer.salon.masters.nodes.splice(idx, 1, master);

                        store.writeQuery({
                            query: GET_MASTERS_QUERY,
                            variables: {
                                salon: ownProps.salon,
                            },
                            data,
                        });
                    }
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'NotExists') {
                            if (
                                graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.existingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (graphQLError.data.error === 'email') {
                                throw new SubmissionError({
                                    email: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: 'Something went wrong',
                    });
                });
        },
    }),
});

const formatPhone = phone =>
    phone ? formatNumber(phone, 'International') : '+371';

const compareMasterWithFormValues = (master, formValues) => {
    const fields = keys(formValues);
    const pristineValues = pick(master, fields);
    pristineValues.phone = formatPhone(pristineValues.phone);
    if (fields.indexOf('serviceGroups') !== -1) {
        pristineValues.serviceGroups = map(
            pristineValues.serviceGroups,
            property('id'),
        );
    }
    return isEqual(formValues, pristineValues);
};

const props = withProps(({ master, userLookupStatus, formValues }) => ({
    // Make form as read-only if master is global
    readOnly:
        userLookupStatus === 'EXISTS' ||
        (master.type === 'GLOBAL' &&
            compareMasterWithFormValues(
                master,
                omit(formValues, ['serviceGroups', 'status']),
            )),
}));

// Provide initial values to the form to fulfill fields with
// data from provided master
const initialValues = withPropsOnChange(
    (props, nextProps) =>
        !isEqual(props.userLookupUser, nextProps.userLookupUser),
    ({ open, master, userLookupUser, formValues }) => {
        if (open) {
            const fields = ['email', 'firstName', 'lastName', 'gender'];
            const masterServiceGroups = map(
                master.serviceGroups,
                serviceGroup => serviceGroup.id,
            );
            let initialValues;
            if (userLookupUser) {
                initialValues = Object.assign(pick(userLookupUser, fields), {
                    status: 'ACTIVE',
                    serviceGroups: masterServiceGroups,
                    phone: formatPhone(userLookupUser.phone),
                });
            } else {
                initialValues =
                    formValues ||
                    Object.assign(pick(master, fields), {
                        status: master.status,
                        serviceGroups: masterServiceGroups,
                        phone: formatPhone(master.phone),
                    });
            }
            return { initialValues };
        }
    },
);

const withForm = reduxForm({
    form: 'editMaster',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withPristineHandlers = withHandlers({
    checkFormPristine: ({ formValues, master }) => () =>
        compareMasterWithFormValues(master, formValues),
});

export default compose(
    connect(({ user, intl, ...state }) => ({
        salon: user.get('salon'),
        formValues: getFormValues('editMaster')(state),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    injectIntl,
    withData,
    withUserLookup,
    props,
    initialValues,
    withForm,
    withPristineHandlers,
)(PersonalInformation);
