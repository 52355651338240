/**
 * @flow
 */

import { connect } from 'react-redux';
import { setDate } from '../../../../actions/schedule';
import { DatePicker } from '../../../../components';

const mapStateToProps = ({ schedule }) => ({
    value: schedule.get('date'),
    dayPickerProps: {
        month: schedule.get('date'),
        selectedDays: [schedule.get('date')],
    },
});

export default connect(mapStateToProps, { onChange: setDate })(DatePicker);
