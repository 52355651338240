import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import moment from 'moment';

import ClientRatingTable from '../../components/rating/ClientRatingTable';

export const GET_CLIENT_RATING_QUERY = gql`
    query getClientRating($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    clientRating {
                        id
                        firstName
                        lastName
                        phone
                        totalVisits
                        firstAppointment
                        lastAppointment
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_CLIENT_RATING_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => {
        let clientRating = [];

        if (!loading && viewer) {
            clientRating = map(get(viewer, 'salon.clientRating'), i => ({
                id: i.id,
                firstName: i.firstName,
                lastName: i.lastName || '',
                phone: i.phone || '',
                totalVisits: i.totalVisits,
                firstAppointment: moment(i.firstAppointment).format(
                    'DD.MM.YYYY',
                ),
                lastAppointment: moment(i.lastAppointment).format('DD.MM.YYYY'),
            }));
        }

        return {
            loading,
            clientRating,
        };
    },
});

const mapStateToProps = ({ user }) => ({
    salon: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(ClientRatingTable);
