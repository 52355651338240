/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';

import AddAdministratorDialog from '../components/AddAdministratorDialog';
import validate from './form/administratorFormValidate';

import { GET_ADMINISTRATORS_QUERY } from './AdministratorsList';
import { omit } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.administrators.form.salonExistingsError',
        defaultMessage: 'Salon does not exists',
    },
    permissionsError: {
        id: 'pages.administrators.form.permissionsError',
        defaultMessage:
            'Current user does not have permission to add administrators',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// GraphQL query to create a new administrator
const ADD_ADMINISTRATOR_QUERY = gql`
    mutation addAdministrator($input: AddAdministratorInput!) {
        addAdministrator(input: $input) {
            administrator {
                id
                firstName
                lastName
                phone
                email
                roles
            }
        }
    }
`;

const withData = graphql(ADD_ADMINISTRATOR_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new administrator
        onSubmit: formData => {
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const mutation = mutate({
                variables: {
                    input: {
                        userId: ownProps.user,
                        salonId: ownProps.salon,
                        ...omit(formData, 'passwordRepeat'),
                        phone,
                    },
                },
                // Implement optimistinc response to compensate network latency
                // and add a new administrator directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    addAdministrator: {
                        __typename: 'AddAdministratorPayload',
                        administrator: {
                            __typename: 'Administrator',
                            id: uuid.v4(),
                            firstName: formData.firstName || null,
                            lastName: formData.lastName || null,
                            email: formData.email || null,
                            phone: phone || null,
                        },
                    },
                },
                // Update local store with a new administrator
                update: (store, { data: { addAdministrator } }) => {
                    const data = store.readQuery({
                        query: GET_ADMINISTRATORS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                    });

                    data.viewer.salon.administrators.push(
                        addAdministrator.administrator,
                    );

                    store.writeQuery({
                        query: GET_ADMINISTRATORS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after administrator was created
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];

                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'SALON_DOES_NOT_EXIST'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'Forbidden' &&
                            graphQLError.data.error ===
                                'USER_DOES_NOT_HAVE_PERMISSION_TO_ADD'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.permissionsError,
                                ),
                            });
                        }
                    }

                    throw new SubmissionError({
                        _error:
                            (graphQLError && graphQLError.message) ||
                            'Something went wrong',
                    });
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'addAdministrator',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
            },
        },
);

export default compose(
    connect(({ user }) => ({
        user: user.get('id'),
        salon: user.get('salon'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddAdministratorDialog);
