/**
 * @flow
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled, { injectGlobal } from 'styled-components';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { Container as BaseContainer, Segment } from 'semantic-ui-react';

import { AuthenticatedRoute } from '../../../components';
import Navigation from './Navigation';
import {
    ClientsPage,
    InventoryPage,
    StaffPage,
    AppointmentsPage,
    SettingsPage,
    ReportPage,
    AdministratorsPage,
    StatisticsPage,
    MasterAppointmentsPage,
    SalonListPage,
    SuppliersPage,
    OwnerSettingsPage,
    CabinetsPage,
    MembershipsPage,
} from '../../../pages';

import styles from '../../../styles.js';

// eslint-disable-next-line
injectGlobal`
    body {
        background: #fafafa;
    }
`;

const Content = styled(Segment)`
    &.segment {
        padding: 0px !important;
        margin-left: 2em !important;
        margin-right: 2em !important;
        flex: 1;
    }
`;

const Container = styled(BaseContainer)`
    ${styles.proximaNovaFontFamily} !important;
    &.container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const AdminLayout = () => (
    <Container fluid>
        <Navigation />

        <Content vertical basic>
            <Route
                render={() => <Redirect to="/salon/appointments" />}
                path="/salon"
                exact
            />

            <AuthenticatedRoute path="/salon/clients" component={ClientsPage} />
            <AuthenticatedRoute
                path="/salon/inventory"
                component={InventoryPage}
            />
            <AuthenticatedRoute path="/salon/staff" component={StaffPage} />
            <AuthenticatedRoute
                path="/salon/settings/administrators"
                component={AdministratorsPage}
            />
            <AuthenticatedRoute
                path="/salon/appointments"
                component={AppointmentsPage}
            />
            <AuthenticatedRoute
                path="/salon/settings"
                component={SettingsPage}
            />
            <AuthenticatedRoute path="/salon/reports" component={ReportPage} />
            <AuthenticatedRoute
                path="/salon/master-appointments"
                component={MasterAppointmentsPage}
            />
            <AuthenticatedRoute
                path="/salon/salon-list"
                component={SalonListPage}
            />
            <AuthenticatedRoute
                path="/salon/suppliers-list"
                component={SuppliersPage}
            />
            <AuthenticatedRoute
                path="/salon/statistics"
                component={StatisticsPage}
            />
            <AuthenticatedRoute
                path="/salon/owner-settings"
                component={OwnerSettingsPage}
            />
            <AuthenticatedRoute
                path="/salon/cabinets"
                component={CabinetsPage}
            />
            <AuthenticatedRoute
                path="/salon/memberships"
                component={MembershipsPage}
            />
        </Content>
    </Container>
);

const MasterLayout = ({ isMaster }) => (
    <Container fluid>
        <Navigation isMaster={isMaster} />

        <Content vertical basic>
            <Route
                render={() => <Redirect to="/salon/appointments" />}
                path="/salon"
                exact
            />

            <AuthenticatedRoute
                path="/salon/appointments"
                component={AppointmentsPage}
            />
        </Content>
    </Container>
);

const SalonLayout = ({ isMaster }) =>
    !isMaster ? <AdminLayout /> : <MasterLayout isMaster={isMaster} />;

const mapStateToProps = ({ user }) => ({ isMaster: user.get('isMaster') });

export default compose(
    connect(mapStateToProps),
    DragDropContext(HTML5Backend),
)(SalonLayout);
