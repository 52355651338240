/**
 * @flow
 */

import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import { search } from '../../../../../actions/services';

import type { Dispatch } from '../../../../../type';

const mapStateToProps = ({ services }) => ({
    value: services.get('searchQuery'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChange: (e, { value }) => {
        dispatch(search(value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
