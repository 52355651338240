/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DeleteServiceGroupDialog from '../../../components/services/service/DeleteServiceDialog';
import { find, remove } from 'lodash';

import { GET_SERVICE_GROUPS_QUERY } from '../ServicesList';

// Query that deletes specified service
const DELETE_SERVICE_QUERY = gql`
    mutation deleteService($input: DeleteServiceInput!) {
        deleteService(input: $input) {
            serviceId
        }
    }
`;

const withData = graphql(DELETE_SERVICE_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: { serviceId: ownProps.service.id },
                },
                // Implement optimistic response to compensate network latency
                // and delete service before server response
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteService: {
                        __typename: 'DeleteServicePayload',
                        serviceId: ownProps.service.id,
                    },
                },
                // Remove deleted service from local cache
                update: (store, { data: { deleteService } }) => {
                    const data = store.readQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                    });

                    // Find corrent service group
                    const group = find(data.viewer.salon.serviceGroups.nodes, {
                        id: ownProps.serviceGroup.id,
                    });

                    if (group) {
                        remove(
                            group.services,
                            service => service.id === ownProps.service.id,
                        );
                    }
                    store.writeQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when service group was not deleted
                });
        },
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
)(DeleteServiceGroupDialog);
