/**
 * @flow
 */

import type { User, ID } from '../type';

/**
 * Save provided user object in local storage
 *
 * @param {Object} user
 */
export const login = (user: User) => ({
    type: 'USER.LOGIN',
    user,
});

/**
 * Logout currently authenticated user
 */
export const logout = () => ({
    type: 'USER.LOGOUT',
});

/**
 * Switch to provided salon
 */
export const switchSalon = (salon: ID, salonName: String) => ({
    type: 'USER.SWITCH_SALON',
    salon,
    salonName,
});

/**
 * Toggle isOwner state
 */
export const setIsOwner = (state: boolean) => ({
    type: 'USER.SET_IS_OWNER',
    state,
});

export const setIsSysadmin = (state: boolean) => ({
    type: 'USER.SET_IS_SYSADMIN',
    state,
});

export const setIsMaster = (state: boolean) => ({
    type: 'USER.SET_IS_MASTER',
    state,
});

export const setUserData = userData => ({
    type: 'USER.DATA',
    userData,
});
