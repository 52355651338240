/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';

import AddServiceGroupDialog from '../../../components/services/group/AddServiceGroupDialog';
import validate from './validate';
import { GET_SERVICE_GROUPS_QUERY } from '../ServicesList';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.services.groupForm.nameExistingsError',
        defaultMessage: 'Service group with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that creates a new service group
export const CREATE_SERVICE_GROUP_MUTATION = gql`
    mutation createServiceGroup(
        $input: CreateServiceGroupInput!
        $lang: String!
    ) {
        createServiceGroup(input: $input) {
            serviceGroup {
                id
                name(lang: $lang)
                nameVariants {
                    lv
                    ru
                    en
                }
                type
                services {
                    id
                }
            }
        }
    }
`;

const withData = graphql(CREATE_SERVICE_GROUP_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new service group
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        ...formData,
                        type: formData.type || null,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency and add
                // a new service group to local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    createServiceGroup: {
                        __typename: 'CreateServiceGroupPayload',
                        serviceGroup: {
                            __typename: 'ServiceGroup',
                            id: uuid.v4(),
                            services: [],
                            ...formData,
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                            nameVariants: Object.assign(
                                {
                                    lv: '',
                                    ru: '',
                                    en: '',
                                    __typename: 'ServiceNameVariants',
                                },
                                formData.nameVariants,
                            ),
                        },
                    },
                },
                // Update local store by adding a newly created service group
                update: (store, { data: { createServiceGroup } }) => {
                    const data = store.readQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                    });
                    data.viewer.salon.serviceGroups.nodes.push(
                        createServiceGroup.serviceGroup,
                    );
                    store.writeQuery({
                        query: GET_SERVICE_GROUPS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            lang: ownProps.currentLanguage,
                        },
                        data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// This allow to reset initial values of the form, even if the form itself is not destroying
const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

// Init service group form
const withForm = reduxForm({
    form: 'addServiceGroup',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddServiceGroupDialog);
