/**
 * @flow
 */

import {
    connect
} from 'react-redux';
import {
    compose,
    withHandlers,
    mapProps
} from 'recompose';
import {
    get
} from 'lodash';

import {
    setMaster
} from '../../../actions/master-appointments';
import {
    MasterSelect
} from '../../appointments';

const handlers = withHandlers({
    onChange: ({
        dispatchSetMaster,
    }) => (e, {
        value,
    }) => {
        dispatchSetMaster({
            id: value
        });
    }
});

const mapStateToProps = ({
    masterAppointments
}) => ({
    value: get(masterAppointments.get('master'), 'id'),
});

const mapDispatchToProps = {
    dispatchSetMaster: setMaster
};

const limitedProps = mapProps(({
    master,
    dispatchSetMaster,
    ...props
}) => ({
    ...props
}));

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    handlers,
    limitedProps
)(MasterSelect);
