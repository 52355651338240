/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Segment, Container, Image } from 'semantic-ui-react';
import {
    Button,
    Header as BaseHeader,
    Animation,
} from '../../../../components';
import Navigation from './Navigation';
import BaseSignupButton from '../SignupButton';
import type { Intl } from '../../../../type';

type PromoProps = {
    authenticated: boolean,
    intl: Intl,
};

const fadeIn = keyframes`
    0% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
`;

const appearFromBottom = keyframes`
    0% {
        opacity: .5;
        transform: translate3D(0, 100px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
`;

const RootSegment = styled(Segment)`
    padding-bottom: 0 !important;
    background-size: cover !important;
    background-position: 50% 50% !important;
    background-image: url(/images/landing/promo.jpg) !important;
    overflow: hidden;
    min-height: 722px;
`;

const ContentSegment = styled(({ animate, ...props }) => (
    <Segment {...props} />
))`
    margin: 72px 0 20px !important;
    ${props => props.animate && `animation: ${fadeIn} 1.5s;`}
`;

const SignupButton = styled(BaseSignupButton)`
    &.button {
        min-width: 230px;
        padding: 1em 1.5em !important;
        margin: 8px;
    }
`;

const DemoButton = styled(Button)`
    &.button {
        padding: 1em 2.6em !important;
        margin: 8px;
    }
`;

const Header = styled(BaseHeader)`
    &.header {
        font-size: 36px !important;
    }
`;

const Subheader = styled(BaseHeader.Subheader)`
    &.sub.header {
        font-size: 18px !important;
        margin-top: 3px;
    }
`;

const AppStore = styled(Image)`
    &.image {
        margin-bottom: 6px;
    }
`;

const PlayMarket = styled(Image)`
    &.image {
        margin-left: 4px;
        margin-bottom: 6px;
    }
`;

const PromoImage = styled(({ animate, ...props }) => <Image {...props} />)`
    ${props => props.animate && `animation: ${appearFromBottom} 1s;`}
`;

const intlMessages = defineMessages({
    header: {
        id: 'pages.landing.promo.header',
        defaultMessage: 'Plans for employees and clients',
    },
    subHeader: {
        id: 'pages.landing.promo.subHeader',
        defaultMessage: 'Simplest way to schedule your clients employees.',
    },
    signup: {
        id: 'pages.landing.promo.signupButton',
        defaultMessage: 'Register',
    },
    demo: {
        id: 'pages.landing.promo.demoButton',
        defaultMessage: 'Demo',
    },
});

const Promo = ({ authenticated, intl }: PromoProps) => (
    <RootSegment textAlign="center" vertical inverted>
        <Navigation authenticated={authenticated} />
        <Container>
            <Animation>
                <ContentSegment basic>
                    <Header as="h1" inverted>
                        {intl.formatMessage(
                            intlMessages.header,
                        )}
                        <Subheader>
                            {intl.formatMessage(
                                intlMessages.subHeader,
                            )}
                        </Subheader>
                    </Header>

                    <Segment vertical basic>
                        <SignupButton size="large">
                            {intl.formatMessage(
                                intlMessages.signup,
                            )}
                        </SignupButton>
                        {/* <DemoButton size="large" basic inverted uppercased>
                            {intl.formatMessage(
                                intlMessages.demo,
                            )}
                            </DemoButton> */}
                        <AppStore
                            size="small"
                            src="/images/btn-app-store.svg"
                            href="https://apps.apple.com/app/appotime/id1418595516"
                            alt="App store"
                        />
                        <PlayMarket
                            size="small"
                            src="/images/btn-google-play.svg"
                            href="https://play.google.com/store/apps/details?id=com.appotime"
                            alt="Play market"
                        />
                    </Segment>
                </ContentSegment>
            </Animation>

            <Animation>
                <PromoImage
                    src="/images/landing/app.png"
                    verticalAlign="bottom"
                    centered
                />
            </Animation>
        </Container>
    </RootSegment>
);

export default injectIntl(Promo);
