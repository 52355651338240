/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'redux-form';
import { isNil, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Icon, Form, Message } from 'semantic-ui-react';

import { Modal, Button, FormLabel } from '../../../../components';
import FieldList from './FieldList';

const SalonConnectionForm = ({
    salons,
    manufacturers,
    error,
    handleSubmit,
    submitting,
    onClose,
    change,
    checkFormPristine,
}: InvoiceFormProps) => (
    <Modal.Context>
        <Modal.Content>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <FormLabel>
                        <FormattedMessage
                            id="pages.suppliers.supplierForm.salonConnection.items"
                            defaultMessage="Connections to salons and manufacturers"
                        />
                    </FormLabel>
                    <FieldArray
                        name="items"
                        component={FieldList}
                        change={change}
                        salons={salons}
                        manufacturers={manufacturers}
                    />
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.suppliers.editSupplier.button.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.suppliers.editSupplier.button.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default SalonConnectionForm;
