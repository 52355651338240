/**
 * This component presents provided color as a circle of this color
 * 
 * @flow
 */

import styled from 'styled-components';

const DEFAULT_COLOR = '#35a3d5';
const DEFAULT_SIZE = 50;

const Color = styled.div`
    background: ${props => props.color || DEFAULT_COLOR};
    width: ${props => props.size || DEFAULT_SIZE}px;
    height: ${props => props.size || DEFAULT_SIZE}px;
    ${props => props.spaced === 'left' && `margin-left: 0.5em;`}
    ${props => props.spaced === 'right' && `margin-right: 0.5em;`}
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
`;

export default Color;
