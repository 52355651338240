/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Route, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';

type SysadminMenuProps = {
    trigger: Function,
};

const StyledDropdown = styled(Dropdown)`
    padding: 11px 0px 11px 0px;
`;
const StyledMenuItem = styled(Menu.Item)`
    .ui.secondary.inverted.menu &.item {
        color: rgba(0, 0, 0, 0.95) !important;
        &:hover {
            color: rgba(0, 0, 0, 0.95) !important;
        }
    }
`;

const MenuItem = ({ to, children, exact }) => (
    <Route
        path={to}
        exact={exact}
        children={({ match }) => (
            <StyledMenuItem as={Link} active={!!match} to={to} link>
                {children}
            </StyledMenuItem>
        )}
    />
);

const SysadminMenu = ({ trigger }: SysadminMenuProps) => (
    <StyledDropdown trigger={trigger} icon={null}>
        <Dropdown.Menu>
            <MenuItem to="/salon/salon-list" exact>
                <FormattedMessage
                    id="navbar.menu.sysadmin.salons"
                    defaultMessage="Salons"
                />
            </MenuItem>
            <MenuItem to="/salon/suppliers-list" exact>
                <FormattedMessage
                    id="navbar.menu.sysadmin.suppliers"
                    defaultMessage="Suppliers"
                />
            </MenuItem>
            <MenuItem to="/salon/statistics" exact>
                <FormattedMessage
                    id="navbar.menu.sysadmin.statistics"
                    defaultMessage="Statistics"
                />
            </MenuItem>
        </Dropdown.Menu>
    </StyledDropdown>
);

const mapStateToProps = ({ user }) => ({
    isSysadmin: user.get('isSysadmin'),
});

const withBranch = branch(({ isSysadmin }) => !isSysadmin, renderNothing);

export default compose(
    connect(mapStateToProps),
    withBranch,
)(SysadminMenu);
