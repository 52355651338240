/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { getFormInitialValues } from 'redux-form';
import { compose, withProps, mapProps } from 'recompose';
import moment from 'moment';
import { getFreeTimeSpans } from '../../../../lib/schedule';
import client from '../../../../apollo-client';
import { convertMinutesToDateTime } from '../../../../lib/date';

import { map } from 'lodash';

import { TimeSpanSelect } from '../../../../components';
import { fromJS } from 'immutable';

// Query to get salon
const GET_SALON_QUERY = gql`
    query getSalonSettings($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                }
            }
        }
    }
`;

// Query to get schedule for given date for specific master
const GET_MASTER_QUERY = gql`
    query getMaster($master: ID!, $date: String!, $salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    master(id: $master) {
                        id
                        schedule(startDate: $date) {
                            startAt
                            endAt
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_MASTER_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            master: ownProps.master.id,
            date: ownProps.date,
        },
    }),
    props: ({ data, data: { viewer, loading } }) => ({
        loading,
        master: !loading && viewer !== undefined ? viewer.salon.master : null,
    }),
});

const props = withProps(ownProps => {
    const { loading, master, startAt, endAt, date, name, salon } = ownProps;

    // Get salon settings from local cache
    const data = client.readQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
        },
    });
    const { schedule } = data.viewer.salon.settings;

    let options = null;
    if (!loading && master) {
        const index = name === 'startAt' ? 0 : 1;

        // Exclude time spans that are already take
        const exclude = map(master.schedule, workingTime => [
            workingTime.startAt,
            workingTime.endAt,
        ]).filter(
            timeSpan => timeSpan[index] < startAt && timeSpan[index] > endAt,
        );

        const freeTimeSpans = getFreeTimeSpans({
            timeSpan: [schedule.startAt, schedule.endAt],
            step: schedule.step,
            exclude,
        });

        // Convert free spans to list of options
        options = map(freeTimeSpans, timeSpan => {
            const time = convertMinutesToDateTime(timeSpan[index], date);
            return {
                text: time.format(schedule.format),
                value: time.toString(),
            };
        });
    }

    return {
        options,
    };
});

const mapStateToProps = (state, ownProps) => ({
    salon: state.user.get('salon'),
    startAt: getFormInitialValues(ownProps.meta.form)(state, 'startAt'),
    endAt: getFormInitialValues(ownProps.meta.form)(state, 'endAt'),
    date: state.schedule.get('date'),
});

const limitedProps = mapProps(
    ({ meta, master, salon, startAt, endAt, date, dispatch, ...props }) => ({
        ...props,
    }),
);

export default compose(
    connect(mapStateToProps),
    withData,
    props,
    limitedProps,
)(TimeSpanSelect);
