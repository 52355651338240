/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, filter, uniqBy, map, property, reduce, flatten } from 'lodash';

import InvoiceFieldsList from '../../../components/details/invoice/InvoiceFieldsList';
import { MASTER_INVOICE_FIELDS_FRAGMENT } from '../../masterAccessQueries';

const GET_MASTERS_QUERY = gql`
    query getMasters($salon: ID!, $lang: String!, $services: [String]) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    masters(deleted: true) {
                        nodes {
                            id
                            firstName
                            lastName
                            deletedAt
                            serviceGroups(services: $services) {
                                id
                                name
                                services {
                                    id
                                    name(lang: $lang)
                                    duration
                                    price
                                }
                            }
                        }
                    }
                    cabinets(deleted: true) {
                        id
                        name(lang: $lang)
                        serviceGroups(services: $services) {
                            id
                            name
                            services {
                                id
                                name(lang: $lang)
                                duration
                                price
                            }
                        }
                    }
                }
            }
            ...onMasterInvoiceFields
        }
    }

    ${MASTER_INVOICE_FIELDS_FRAGMENT}
`;

const withData = graphql(GET_MASTERS_QUERY, {
    options: ({ salon, currentLanguage, invoice }) => ({
        variables: {
            salon,
            lang: currentLanguage,
            services: invoice
                ? map(
                      filter(invoice.items, item => item.type === 'SERVICE'),
                      i => i.entity.id,
                  )
                : [],
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer }, ownProps: { paid } }) => {
        let masters = [];
        let cabinets = [];
        let allServices = [];

        if (!loading && viewer) {
            masters = filter(
                get(viewer, 'salon.masters.nodes'),
                ({ deletedAt }) => (!paid ? !deletedAt : true),
            );

            cabinets = filter(get(viewer, 'salon.cabinets'), ({ deletedAt }) =>
                !paid ? !deletedAt : true,
            );

            const mastersServiceGroups = masters
                ? uniqBy(
                      flatten(map(masters, i => i.serviceGroups)),
                      property('id'),
                  )
                : [];

            const mastersServices =
                mastersServiceGroups && mastersServiceGroups.length
                    ? reduce(
                          mastersServiceGroups,
                          (res, { services }) => [...res, ...services],
                          [],
                      )
                    : [];

            const cabinetServiceGroups = cabinets
                ? uniqBy(
                      flatten(map(cabinets, i => i.serviceGroups)),
                      property('id'),
                  )
                : [];

            const cabinetsServices =
                cabinetServiceGroups && cabinetServiceGroups.length
                    ? reduce(
                          cabinetServiceGroups,
                          (res, { services }) => [...res, ...services],
                          [],
                      )
                    : [];

            allServices = [...mastersServices, ...cabinetsServices];
        }

        return {
            masters,
            cabinets,
            allServices,
        };
    },
});

const mapStateToProps = ({ user, intl }) => ({
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    isMaster: user.get('isMaster'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(InvoiceFieldsList);
