/**
 * @flow
 */

import {
    pick
} from 'lodash';

/**
 * Set date range to get appointments
 * @param {String} fromDate - A starting date
 * @param {String} toDate - An ending date
 */
export const setDate = (fromDate: string, toDate: string) => ({
    type: 'MASTER_APPOINTMENTS.SET_DATE',
    fromDate,
    toDate,
});

/**
 * Set master
 * @param {Object} master
 */
export const setMaster = (master: Object) => ({
    type: 'MASTER_APPOINTMENTS.SET_MASTER',
    master: pick(master, ['id', 'firstName', 'lastName']),
});
