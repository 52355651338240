/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment from 'moment';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import GenerateScheduleDialog from '../../components/schedule/GenerateScheduleDialog';

const mapStateToProps = ({ schedule, user }) => ({
    firstRangeFrom: schedule.get('firstRangeFrom'),
    firstRangeTo: schedule.get('firstRangeTo'),
    secondRangeFrom: schedule.get('secondRangeFrom'),
    secondRangeTo: schedule.get('secondRangeTo'),
    salon: user.get('salon'),
});

const GENERATE_SCHEDULE_QUERY = gql`
    mutation generateSchedule($input: GenerateScheduleInput!) {
        generateSchedule(input: $input) {
            salonId
            masterId
        }
    }
`;

const withGenerateSchedule = graphql(GENERATE_SCHEDULE_QUERY, {
    props: ({
        mutate,
        ownProps: {
            salon,
            firstRangeFrom,
            firstRangeTo,
            secondRangeFrom,
            secondRangeTo,
            onClose,
            master,
        },
    }) => ({
        onGenerate: () => {
            const masterId = (master && master.id) || null;
            const mutation = mutate({
                variables: {
                    input: {
                        salonId: salon,
                        masterId,
                        firstRangeFrom: moment(firstRangeFrom).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                        firstRangeTo: moment(firstRangeTo).format(
                            'YYYY-MM-DD HH:mm',
                        ),
                        secondRangeFrom: moment(secondRangeFrom).format(
                            'YYYY-MM-DD',
                        ),
                        secondRangeTo: moment(secondRangeTo).format(
                            'YYYY-MM-DD',
                        ),
                    },
                },
            });
            return mutation.then(() => {
                onClose();
            });
        },
    }),
});

const withModalHandlers = withHandlers({
    onModalClose: ({ onClose }) => () => {
        onClose();
    },
});

export default compose(
    connect(mapStateToProps),
    withGenerateSchedule,
    injectIntl,
    withModalHandlers,
)(GenerateScheduleDialog);
