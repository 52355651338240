/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment';
import { find, each, map, get } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';
import { convertMinutesToDateTime } from '../../../lib/date';

import client from '../../../apollo-client';
import CreateAppointmentDialog from '../components/CreateAppointmentDialog';
import validate from './form/create-form-validate';

// Query to get salon
import { GET_SALON_QUERY } from './timetable/MasterTable';
import { GET_WORKING_DAYS } from '../../master-appointments/containers/DateRange';
import { GET_MASTER_APPOINTMENTS_QUERY } from '../../master-appointments/containers/queries';

const intlMessages = defineMessages({
    timeOverlapError: {
        id: 'pages.schedule.form.AppointmentForm.startTimeError',
        defaultMessage: 'Time is overlapping with another appointment',
    },
    appointmentAlreadyExists: {
        id: 'pages.appointments.AppointmentForm.error.appointmentAlreadyExists',
        defaultMessage: 'Same appointment already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// Query to create a new appointment
const CREATE_APPOINTMENTS_QUERY = gql`
    mutation createAppointments(
        $input: CreateAppointmentsInput!
        $lang: String!
    ) {
        createAppointments(input: $input) {
            appointments {
                id
                author {
                    id
                    firstName
                    lastName
                }
                authorRole
                cabinet {
                    id
                    name(lang: $lang)
                }
                master {
                    id
                    firstName
                    lastName
                }
                client {
                    id
                    firstName
                    lastName
                    stats {
                        totalAppointments
                        failedAppointments
                        successAppointments
                        lastAppointment {
                            id
                            service {
                                id
                                name(lang: $lang)
                            }
                            startAt
                        }
                    }
                }
                service {
                    id
                    name(lang: $lang)
                    color
                    price
                }
                startAt
                endAt
                duration
                notes
                invoice {
                    id
                    paid
                }
                archived
            }
        }
    }
`;

const updateMasterAppointments = (
    store,
    master,
    salon,
    appointments,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
) => {
    const data = store.readQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
    });

    appointments.forEach(appointment => {
        data.viewer.salon.master.appointments.push({
            ...appointment,
            invoice: null,
        });
    });

    store.writeQuery({
        query: GET_MASTER_APPOINTMENTS_QUERY,
        variables: {
            master,
            salon,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            withMaster: true,
            showArchive,
            lang: currentLanguage,
        },
        data,
    });
};

const updateWorkingDays = (store, salon, master, appointments) => {
    const fromDate = moment().format('YYYY-MM-DD');
    const month = moment(fromDate).format('MM');
    const toDate = moment(month, 'MM')
        .month(parseInt(month, 10) + 5)
        .format('YYYY-MM-DD');
    const data = store.readQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
    });

    appointments.forEach(appointment => {
        const wDate = find(
            data.viewer.salon.workingDays,
            item =>
                item.date === moment(appointment.startAt).format('YYYY-MM-DD'),
        );
        if (wDate) {
            wDate.appointmentCount++;
        } else {
            data.viewer.salon.workingDays.push({
                date: moment(appointment.startAt).format('YYYY-MM-DD'),
                appointmentCount: 1,
                __typename: 'WorkingDay',
            });
        }
    });

    store.writeQuery({
        query: GET_WORKING_DAYS,
        variables: {
            salon,
            master,
            fromDate,
            toDate,
        },
        data,
    });
};

const withMutation = graphql(CREATE_APPOINTMENTS_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new appointment
        onSubmit: formData => {
            // construct a list of appointments
            const appointments = map(formData.services, service => ({
                salon: ownProps.salon,
                cabinet: service.cabinet,
                master: service.master,
                client: formData.client,
                service: service.service,
                duration: service.duration,
                notes: formData.notes,
                startAt: convertMinutesToDateTime(
                    service.startTime,
                    formData.startDate,
                ).format('YYYY-MM-DD HH:mm'),
                author: ownProps.userId,
                authorRole: 'ADMIN',
            }));

            const mutation = mutate({
                variables: {
                    input: {
                        appointments,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Add a new appointment to the local cache
                update: (store, { data: { createAppointments } }) => {
                    if (ownProps.masterAppointments) {
                        const appointments = createAppointments.appointments.filter(
                            appointment => {
                                return (
                                    appointment.master.id === ownProps.master
                                );
                            },
                        );

                        updateMasterAppointments(
                            store,
                            ownProps.master,
                            ownProps.salon,
                            appointments,
                            ownProps.fromDate,
                            ownProps.toDate,
                            ownProps.showArchive,
                            ownProps.currentLanguage,
                        );
                        updateWorkingDays(
                            store,
                            ownProps.salon,
                            ownProps.master,
                            appointments,
                        );
                    } else {
                        if (ownProps.createAppointments) {
                            ownProps.createAppointments(
                                createAppointments.appointments,
                            );
                        }
                    }
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close dialog after appointment was created
                    ownProps.onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];

                    if (graphQLError) {
                        const errorMsg = get(graphQLError, 'data.error');
                        // Handle time overlapping error
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                errorMsg === 'TIME_OVERLAPS_WITH_OTHER_RECORD'
                            ) {
                                throw new SubmissionError({
                                    startTime: intl.formatMessage(
                                        intlMessages.timeOverlapError,
                                    ),
                                });
                            } else if (
                                errorMsg === 'APPOINTMENT_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.appointmentAlreadyExists,
                                    ),
                                });
                            }
                        }
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// Provide salon object form local cache
const withSalon = withProps(ownProps => {
    try {
        const data = client.readQuery({
            query: GET_SALON_QUERY,
            variables: {
                salon: ownProps.salon,
                showArchive: ownProps.showArchive,
                date: moment(ownProps.date).format('YYYY-MM-DD'),
                lang: ownProps.currentLanguage,
            },
        });
        return {
            salon: data.viewer.salon,
        };
    } catch (e) {}
});

// Init appointment form
const withForm = reduxForm({
    form: 'createAppointment',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

// This allow to reset initial values of the form, even if the form itself is not destroying
const withInitialValues = withProps(
    ({ open, time, date, inCabinet, id, additionalTime }) => {
        const service = { startTime: time + additionalTime };
        if (inCabinet) {
            service.cabinet = id;
        } else {
            service.master = id;
        }
        return (
            open && {
                initialValues: {
                    startDate: date,
                    services: [service],
                },
            }
        );
    },
);

// Pass default master or cabinet when adding new service
const withDefaultMasterOrCabinet = withProps(({ inCabinet, id }) =>
    inCabinet
        ? {
              defaultCabinet: id,
          }
        : {
              defaultMaster: id,
          },
);

const mapStateToProps = ({ appointments, user, salon, intl }, { date }) => ({
    date: date || appointments.get('date'),
    salon: user.get('salon'),
    userId: user.get('id'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withMutation,
    withInitialValues,
    withSalon,
    withForm,
    withDefaultMasterOrCabinet,
)(CreateAppointmentDialog);
