/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Input as BaseInput, Form, Message, Icon } from 'semantic-ui-react';
import { mapProps } from 'recompose';
import { map } from 'lodash';
import ServiceGroupSelect from '../../../staff/containers/form/ServiceGroupSelect';

import { FormField, FormLabel as BaseFormLabel } from '../../../../components';

import styles from '../../../../styles';

import type { ReduxForm, Master } from '../../../../type';

const FormLabel = styled(BaseFormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const Input = styled(BaseInput)`
    & input {
        background-color: ${props =>
            props.readOnly ? '#fffaf3' : '#fff'} !important;
    }
`;

const limitProps = mapProps(({ meta, ...props }) => ({ ...props }));

const fields = {
    input: FormField(limitProps(Input)),
    serviceGroup: FormField(ServiceGroupSelect),
};

const CabinetForm = ({
    handleSubmit,
    submitting,
    pristine,
    readOnly,
    error,
    languages,
    defaultLanguage,
}) => (
    <Form onSubmit={handleSubmit} error={!!error}>
        {error && (
            <Message error>
                <Icon name="warning sign" />
                {error}
            </Message>
        )}
        <FormLabel>
            <FormattedMessage
                id="pages.cabinets.form.name"
                defaultMessage="Name"
            />
        </FormLabel>
        <Form.Group widths="equal">
            {map(languages, lang => (
                <Field
                    key={lang}
                    name={`nameVariants[${lang}]`}
                    component={fields.input}
                    label={<FormLabel>{lang.toUpperCase()}</FormLabel>}
                    required={lang === defaultLanguage}
                    simpleValue
                />
            ))}
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="serviceGroups"
                component={fields.serviceGroup}
                label={
                    <FormLabel>
                        <FormattedMessage
                            id="pages.staff.form.clientForm.services"
                            defaultMessage="Services"
                        />
                    </FormLabel>
                }
                multiple
                simpleValue
            />
        </Form.Group>
    </Form>
);

export default CabinetForm;
