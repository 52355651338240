/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Input, Form } from 'semantic-ui-react';
import { FormField, FormLabel } from '../../../../components';
import ProductType from './ProductType';
import ManufacturerSelect from '../../containers/form/ManufacturerSelect';
import { FormattedMessage } from 'react-intl';
import { mapProps } from 'recompose';

import styles from '../../../../styles';

import type { ReduxForm } from '../../../../type';

type ProductFormProps = ReduxForm;

const limitInputProps = mapProps(({ meta, ...props }) => ({
    ...props,
}));

const Label = styled(FormLabel)`
    ${styles.proximaNovaFontFamily} !important;
`;

const fields = {
    input: FormField(limitInputProps(Input)),
    type: FormField(ProductType),
    manufacturer: FormField(ManufacturerSelect),
};

const ProductForm = ({
    handleSubmit,
    submitting,
    pristine,
}: ProductFormProps) => (
    <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
            <Field
                name="name"
                component={fields.input}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.name.label"
                            defaultMessage="Name"
                        />
                    </Label>
                }
                simpleValue
                required
            />
            <Field
                name="manufacturer"
                component={fields.manufacturer}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.manufacturer.label"
                            defaultMessage="Manufacturer"
                        />
                    </Label>
                }
                simpleValue
                required
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="price"
                component={fields.input}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.price.label"
                            defaultMessage="Price"
                        />
                    </Label>
                }
                simpleValue
                required
            />
            <Field
                name="quantity"
                component={fields.input}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.quantity.label"
                            defaultMessage="Quantity"
                        />
                    </Label>
                }
                simpleValue
                required
            />
        </Form.Group>
        <Form.Group widths="equal">
            <Field
                name="supplierPrice"
                component={fields.input}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.supplierPrice.label"
                            defaultMessage="Supplier Price"
                        />
                    </Label>
                }
                simpleValue
            />
            <Field
                name="code"
                component={fields.input}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.code.label"
                            defaultMessage="Product Code"
                        />
                    </Label>
                }
                simpleValue
            />
        </Form.Group>
        <Form.Field>
            <Field
                name="type"
                component={fields.type}
                label={
                    <Label>
                        <FormattedMessage
                            id="pages.inventory.form.ProductForm.type.label"
                            defaultMessage="Type"
                        />
                    </Label>
                }
                simpleValue
                required
            />
        </Form.Field>
    </Form>
);

export default ProductForm;
