/**
 * The wrapper for form fields components. 
 * 
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Label } from 'semantic-ui-react';
import { isObject, isString, has, isFunction } from 'lodash';

type Meta = {
    touched: boolean,
    error: string,
};

type FormErrorMessageProps = {
    reason: string | Object,
    pointing?: string,
};

type FormFieldProps = {
    input: Object,
    meta: Meta,
    label?: string | Function,
    inline?: boolean,
    required?: boolean,
    errorPointer?: string,
    // If set as true, only the value key of received data will be returned
    simpleValue: boolean,
    width: any,
    props: any,
};

/**
 * A thin helper that decices how to present an error message.
 * It supports two cases:
 *   1. The error is react-intl message descriptor and should
 *      be rendered in context of FormattedMessage component.
 *   2. The error is a regular string and can be rendered as it is.
 *
 * @param {String|Object} message - The error message
 */
const renderErrorMessage = (message: any) => {
    // The case for object of react-intl message description
    if (
        isObject(message) &&
        has(message, 'id') &&
        has(message, 'defaultMessage')
    ) {
        return <FormattedMessage {...message} />;
    } else if (isString(message)) {
        // The basic string can be just returned
        return message;
    } else if (message === true) {
        return null;
    }

    return '';
};

/**
 * Render error message for single field
 *
 * @param {String} reason - Reason of the error
 */
const FormErrorMessage = ({ reason, pointing }: FormErrorMessageProps) => {
    const message = renderErrorMessage(reason);
    if (!message) {
        return null;
    }

    return <Label color="red" pointing={pointing} content={message} basic />;
};

/**
 * Render component with specified list of props.
 */
const renderComponent = (component: any, props: Object) => {
    if (isFunction(component)) {
        const Component = component;
        return <Component {...props} />;
    }

    return React.cloneElement(component, props);
};

/**
 * Render label component which could be a simple text and should be
 * wrapped into <label> or custom component.
 */
const renderLabel = label => {
    if (isString(label)) {
        return (
            <label>
                {label}
            </label>
        );
    }

    return label;
};

/**
 * Takes input property provided by redux-form and distruct it
 * algon side with other props and pass them to the component.
 *
 * @param {Node} Component - Wrapped component
 */
const FormComponent = (Component: any) => ({
    input,
    meta: { touched, error },
    ...props
}: {
    input: Object,
    meta: Meta,
    props: any,
}) => renderComponent(Component, { ...input, ...props });

/**
 * Takes input property provided by redux-form and distruct it
 * algon side with other props and pass them to the component.
 *
 * @param {Node} Component - Wrapped component
 */
const FormField = (component: Function) => ({
    input,
    meta,
    label,
    inline,
    required,
    errorPointer,
    simpleValue,
    width,
    ...props
}: FormFieldProps) => {
    return (
        <Form.Field
            error={meta.touched && !!meta.error}
            inline={inline}
            required={required}
            width={width}
        >
            {label && renderLabel(label)}
            {renderComponent(component, {
                meta,
                ...input,
                ...props,
                onChange: (e, data) => {
                    if (data) {
                        return input.onChange(simpleValue ? data.value : data);
                    }
                    return input.onChange(e);
                },
            })}
            {meta.touched &&
                meta.error &&
                <FormErrorMessage
                    reason={meta.error}
                    pointing={errorPointer || true}
                />}
        </Form.Field>
    );
};

export { FormField, FormComponent, FormErrorMessage };
