/**
 * @flow
 */

import { Map } from 'immutable';
import moment from 'moment';

const toDate = new Date(moment().add(-2, 'months'));

const initialState = Map({
    searchQuery: '',
    settings: {},
    showArchive: false,
    fromDate: new Date(moment(toDate).startOf('year')),
    toDate,
});

export default (state: Map<string, any> = initialState, action: Object) => {
    switch (action.type) {
        case 'SALON.SEARCH': {
            return state.set('searchQuery', action.query);
        }
        case 'SALON.SETTINGS': {
            return state.set('settings', action.settings);
        }
        case 'SALON.SHOW_ARCHIVE': {
            return state.set('showArchive', action.showArchive);
        }
        case 'SALON.SET_DATE': {
            return state
                .set('fromDate', action.fromDate)
                .set('toDate', action.toDate);
        }
        default: {
            return state;
        }
    }
};
