/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'lodash';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl, defineMessages } from 'react-intl';

import DeleteProductDialog from '../components/DeleteProductDialog';

import { GET_PRODUCTS_QUERY } from './ProductsList';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.inventory.form.ProductForm.existingsError',
        defaultMessage: 'This product does not exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// Query that deletes specified product
const DELETE_PRODUCT_QUERY = gql`
    mutation deleteProduct($input: DeleteProductInput!) {
        deleteProduct(input: $input) {
            productId
        }
    }
`;

const withData = graphql(DELETE_PRODUCT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: () => {
            const mutation = mutate({
                variables: {
                    input: { productId: ownProps.product.id },
                },
                // Implement optimistic response to compensate network latency
                // and delete product before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteProduct: {
                        __typename: 'DeleteProductPayload',
                        productId: ownProps.product.id,
                    },
                },
                // Remove deleted product from query cache
                update: (store, { data: { deleteProduct } }) => {
                    const data = store.readQuery({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            searchQuery: ownProps.searchQuery,
                            manufacturersFilter: ownProps.manufacturersFilter,
                            first: 500,
                        },
                    });

                    remove(
                        data.viewer.salon.products.edges,
                        product => product.node.id === ownProps.product.id,
                    );

                    store.writeQuery({
                        query: GET_PRODUCTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            searchQuery: ownProps.searchQuery,
                            manufacturersFilter: ownProps.manufacturersFilter,
                            first: 500,
                        },
                        data: data,
                    });
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog after product was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when product was not deleted
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

const withForm = reduxForm({
    form: 'deleteProduct',
});

const mapStateToProps = ({ inventory, user }) => ({
    searchQuery: inventory.get('searchQuery'),
    manufacturersFilter: inventory.get('manufacturersFilter'),
    salon: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withForm,
)(DeleteProductDialog);
