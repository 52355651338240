/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduce } from 'lodash';
import { getFormValues } from 'redux-form';

type TotalPriceProps = {
    formValues: Object,
};

const Container = styled.div`
    margin-top: 1em;
`;

const calcTotal = (values: Array<Object>) =>
    reduce(
        values,
        (sum, item) => sum + (item.quantity || 0) * (Number(item.price) || 0),
        0,
    ).toFixed(2);

const TotalPrice = ({ formValues }: TotalPriceProps) => (
    <Container>
        <FormattedMessage
            id="pages.appointments.cart.form.totalPrice"
            defaultMessage="Total price"
        />: &euro; {calcTotal(formValues.items)}
    </Container>
);

const mapStateToProps = (state, { form }) => ({
    formValues: getFormValues(form)(state),
});

export default connect(mapStateToProps)(TotalPrice);
