/**
 * @flow
 */

import React from 'react';

import withModal from '../../../lib/withModal';
import { Button } from '../../../components';
import { SignupDialog } from '../../signup';

type SignupButtonProps = {
    open: boolean,
    onClose: Function,
    onOpen: Function,
    children: string,
    className: string,
    size: string,
};

const SignupButton = ({
    open,
    onClose,
    onOpen,
    children,
    className,
    size,
}: SignupButtonProps) => {
    return (
        <SignupDialog
            open={open}
            onClose={onClose}
            trigger={
                <Button
                    className={className}
                    size={size}
                    onClick={onOpen}
                    uppercased
                    primary
                >
                    {children}
                </Button>
            }
        />
    );
};

export default withModal(SignupButton);
