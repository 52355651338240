/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import { omit, isUndefined } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';

import GeneralSettings from '../../components/general/GeneralSettings';
import validate from './form/settingsFormValidate';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.settings.generalSettings.nameExistingsError',
        defaultMessage: 'Salon with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The query gets salon details
const GET_SALON_QUERY = gql`
    query getSalon($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    name
                    address
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_SALON_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salonId,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        salon: !loading ? viewer.salon : {},
    }),
});

// This mutation save new settings provided by form
const UPDATE_SALON_QUERY = gql`
    mutation updateSalon($input: UpdateSalonInput!) {
        updateSalon(input: $input) {
            salon {
                id
                name
                address
                settings {
                    schedule {
                        startAt
                        endAt
                        step
                        format
                    }
                }
            }
        }
    }
`;

const withMutation = graphql(UPDATE_SALON_QUERY, {
    props: ({ mutate, ownProps: { salonId, intl } }) => ({
        // Handle form submission and save data on the server
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: salonId,
                        ...formData,
                    },
                },
            });

            return mutation.catch(error => {
                // Handle errors received from errors
                const graphQLError =
                    error.graphQLErrors && error.graphQLErrors[0];
                if (graphQLError) {
                    if (
                        graphQLError.name === 'AlreadyExists' &&
                        graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                    ) {
                        throw new SubmissionError({
                            name: intl.formatMessage(
                                intlMessages.nameExistingsError,
                            ),
                        });
                    }
                }

                throw new SubmissionError({
                    _error: intl.formatMessage(
                        intlMessages.defaultErrorMessage,
                    ),
                });
            });
        },
    }),
});

const withForm = reduxForm({
    form: 'generalSettings',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

// Define initial values from current salon settings
const withInitialValues = withProps(ownProps => {
    const { salon, loading } = ownProps;

    if (loading) {
        return {};
    }

    return {
        initialValues: {
            ...omit(salon, ['__typename', 'id', 'settings']),
            settings: {
                schedule: {
                    ...omit(salon.settings.schedule, ['__typename']),
                },
            },
        },
    };
});

// Pass current form values to the form
const withValues = connect(state => ({
    values: getFormValues('generalSettings')(state),
}));

const mapStateToProps = ({ user }) => ({
    salonId: user.get('salon'),
    isOwner: user.get('isOwner'),
    isSysadmin: user.get('isSysadmin'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withMutation,
    withInitialValues,
    withForm,
    withValues,
)(GeneralSettings);
