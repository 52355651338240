/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, reset, SubmissionError } from 'redux-form';
import { compose, withHandlers, withState, withProps } from 'recompose';
import {
    map,
    omit,
    get,
    reduce,
    flatten,
    uniqBy,
    property,
    sortBy,
    isEmpty,
} from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import uuid from 'uuid';
import CreateMembershipDialog from '../components/CreateMembershipDialog';
import { prepareFloatForServer } from '../../../lib/numberFormatter';
import { GET_ALL_SERVICES_QUERY, GET_MEMBERSHIPS_QUERY } from './queries';
import validate from './form/validate';

const intlMessages = defineMessages({
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },

    nameExistingsError: {
        id: 'pages.memberships.add.nameExistingsError',
        defaultMessage: 'Membership with same serial number already exists',
    },
});

const ADD_MEMBERSHIP_MUTATION = gql`
    mutation createMembership($input: CreateMembershipInput!) {
        createMembership(input: $input) {
            membership {
                id
                client {
                    id
                }
                author {
                    id
                }
                createdAt
                serialNumber
                service {
                    id
                    name
                    duration
                    price
                }
                totalUses
                quantity
                price
                pricePerUse
            }
        }
    }
`;

const withMembership = graphql(ADD_MEMBERSHIP_MUTATION, {
    props: ({
        mutate,
        ownProps: { salon, userId, onClose, intl, currentLanguage },
    }) => ({
        onSubmit: formData => {
            const submitData = omit(formData, [
                'notes',
                'duration',
                'createdAt',
                'totalUses',
                'quantity',
                'price',
                'pricePerUse',
                'serialNumber',
            ]);

            const price = prepareFloatForServer(formData.price);

            const quantity = parseInt(formData.quantity);

            const serialNumber = parseInt(formData.serialNumber);

            const pricePerUse = prepareFloatForServer(price / quantity).toFixed(
                2,
            );

            const createdAt = moment(formData.createdAt).format(
                'YYYY-MM-DD HH:mm',
            );

            const mutation = mutate({
                variables: {
                    input: {
                        salon,
                        ...submitData,
                        author: userId,
                        price,
                        quantity,
                        createdAt,
                        pricePerUse,
                        serialNumber,
                    },
                },
                // Implement optimistinc response to compensate network latency
                // and add a new membership directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    createMembership: {
                        __typename: 'CreateMembershipPayload',
                        membership: {
                            __typename: 'Membership',
                            id: uuid.v4(),
                            client: { id: formData.client },
                            author: { id: userId },
                            serialNumber: formData.serialNumber,
                            service: { id: formData.service },
                            quantity,
                            price,
                            pricePerUse,
                            totalUses: 0,
                            createdAt,
                        },
                    },
                },
                // Update local store with a new membership
                update: (store, { data: { createMembership } }) => {
                    const data = store.readQuery({
                        query: GET_MEMBERSHIPS_QUERY,
                        variables: {
                            salon,
                            lang: currentLanguage,
                        },
                    });

                    data.viewer.salon.memberships.push(
                        createMembership.membership,
                    );

                    store.writeQuery({
                        query: GET_MEMBERSHIPS_QUERY,
                        variables: {
                            salon,
                            lang: currentLanguage,
                        },
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }
                });
        },
    }),
});

const withData = graphql(GET_ALL_SERVICES_QUERY, {
    options: ({ salon, currentLanguage }) => ({
        variables: {
            salon,
            lang: currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => {
        let allServices = [];

        if (!loading && viewer) {
            const mastersServiceGroups = uniqBy(
                flatten(
                    map(
                        get(viewer, 'salon.masters.nodes', []),
                        i => i.serviceGroups,
                    ),
                ),
                property('id'),
            );

            const mastersServices =
                mastersServiceGroups && mastersServiceGroups.length
                    ? reduce(
                          mastersServiceGroups,
                          (res, { services }) => [...res, ...services],
                          [],
                      )
                    : [];

            const cabinetServiceGroups = uniqBy(
                flatten(
                    map(
                        get(viewer, 'salon.cabinets', []),
                        i => i.serviceGroups,
                    ),
                ),
                property('id'),
            );

            const cabinetsServices =
                cabinetServiceGroups && cabinetServiceGroups.length
                    ? reduce(
                          cabinetServiceGroups,
                          (res, { services }) => [...res, ...services],
                          [],
                      )
                    : [];

            allServices = [...mastersServices, ...cabinetsServices];
        }
        return {
            allServices,
        };
    },
});

const withForm = reduxForm({
    form: 'createMembership',
    touchOnBlur: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
});

const withModalHandlers = withHandlers({
    onModalClose: ({ onClose, setInitialValues, reset }) => () => {
        setInitialValues({});
        reset('createMembership');
        onClose();
    },
});

const withInitialFormValues = withProps(ownProps => {
    const defaultValue =
        !isEmpty(ownProps.memberships) &&
        sortBy(ownProps.memberships, i => i.serialNumber)[
            ownProps.memberships.length - 1
        ];

    if (ownProps.open) {
        return {
            initialValues: {
                serialNumber:
                    (defaultValue && parseInt(defaultValue.serialNumber) + 1) ||
                    1,
                createdAt: moment().format('YYYY-MM-DD HH:mm'),
            },
        };
    }
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

export default compose(
    connect(
        ({ user, intl, ...state }) => ({
            salon: user.get('salon'),
            formData: getFormValues('createMembership')(state),
            languages: intl.get('languages'),
            defaultLanguage: intl.get('defaultLanguage'),
            currentLanguage: intl.get('locale'),
            userId: user.get('id'),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withInitialFormValues,
    withModalHandlers,
    withData,
    withMembership,
    withForm,
)(CreateMembershipDialog);
