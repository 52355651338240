/**
 * @flow
 */

import moment from 'moment';

/**
 * Change appointments date
 *
 * @param {String} date - A new date to set
 */
export const setDate = (date: string) => ({
    type: 'APPOINTMENTS.SET_DATE',
    date: moment(date).toDate(),
});

/**
 * Set cell height in order to calculate appointment card and nowline offset
 * @param {number} height
 */
export const setCellHeight = (
    cellHeight: ?number,
    headerCellHeight: ?number,
) => ({
    type: 'APPOINTMENTS.SET_CELL_HEIGHT',
    cellHeight,
    headerCellHeight,
});

/**
 * Change appointment page tab
 *
 * @param {String} tab - A tab name to set
 */
export const setTab = (tab: string) => ({
    type: 'APPOINTMENTS.SET_TAB',
    tab,
});
