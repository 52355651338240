import { reduxForm, SubmissionError } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl, defineMessages } from 'react-intl';

import LoginForm from '../components/LoginForm';
import validate from './loginValidator';
import { login } from '../../../actions/user';
import { setShowArchive } from '../../../actions/salon';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.login.form.existingsError',
        defaultMessage: 'User with specified username does not exists',
    },
    passwordValidError: {
        id: 'pages.login.form.passwordValidError',
        defaultMessage: 'Password does not match',
    },
    defaultErrorMessage: {
        id: 'pages.login.form.defaultErrorMessage',
        defaultMessage: 'Unable to authenticate',
    },
});

const withForm = reduxForm({
    form: 'login',
    touchOnBlur: false,
    validate,
});

// GraphQL query that exchange user credentails to JWT token
const LOGIN_QUERY = gql`
    mutation login($input: UserLoginInput!) {
        login(input: $input) {
            token
            role
            user {
                id
                ... on Administrator {
                    firstName
                    lastName
                    salon {
                        id
                        administrator {
                            id
                        }
                    }
                }
                ... on Supplier {
                    firstName
                    lastName
                }
                ... on Master {
                    firstName
                    lastName
                    salon {
                        id
                        administrator {
                            id
                        }
                    }
                }
            }
        }
    }
`;

// Provide GraphQL mutation for user authentication. In case of success
// the token will be saved in session otherways an error will be presented.
const withData = graphql(LOGIN_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: ({ username, password }) => {
            const mutation = mutate({
                variables: {
                    input: {
                        username,
                        password,
                        role: 'ADMIN',
                    },
                },
            });

            const { intl } = ownProps;

            return (
                mutation
                    // Save received token in session and redirect user
                    .then(({ data: { login: { token, user, role } } }) => {
                        let userProps;
                        let url;

                        if (role === 'SUPPLIER') {
                            userProps = {
                                id: user.id,
                                token,
                                role,
                            };
                            url = '/supplier/seminars';
                        } else {
                            userProps = {
                                id: user.id,
                                token,
                                salon: user.salon.id,
                                role,
                                isOwner:
                                    user.id === user.salon.administrator.id,
                                isMaster: role === 'MASTER',
                            };
                            url = '/salon/appointments';
                        }

                        ownProps.setShowArchive(false);
                        ownProps.login(userProps);
                        ownProps.history.push(url);
                    })
                    // Handle possible errors
                    .catch(error => {
                        const graphQLError =
                            error.graphQLErrors && error.graphQLErrors[0];
                        if (graphQLError) {
                            switch (graphQLError.name) {
                                case 'UserNotFound': {
                                    throw new SubmissionError({
                                        username: intl.formatMessage(
                                            intlMessages.existingsError,
                                        ),
                                    });
                                }
                                case 'InvalidPassword': {
                                    throw new SubmissionError({
                                        password: intl.formatMessage(
                                            intlMessages.passwordValidError,
                                        ),
                                    });
                                }
                                default: {
                                    throw new SubmissionError({
                                        _error:
                                            graphQLError.message ||
                                            intl.formatMessage(
                                                intlMessages.defaultErrorMessage,
                                            ),
                                    });
                                }
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    })
            );
        },
    }),
});

export default compose(
    withRouter,
    connect(
        null,
        {
            login,
            setShowArchive,
        },
    ),
    injectIntl,
    withData,
    withForm,
)(LoginForm);
