/**
 * @flow
 */

import { compose, withStateHandlers, mapProps, withProps } from 'recompose';
import { map, filter, includes } from 'lodash';
import { Select } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const mapOptions = item => ({
    value: item.id,
    text: item.name,
});

const filterOptions = (selectedValues, value, salon) => item => {
    if (!item.deletedAt) {
        return (
            (!includes(selectedValues, item.id) || item.id === value) &&
            item.salon === salon
        );
    }

    return item.id === value;
};

const props = withProps(({ salon, manufacturers, value, selectedValues }) => {
    let options = [];
    if (salon) {
        options = map(
            filter(manufacturers, filterOptions(selectedValues, value, salon)),
            mapOptions,
        );
    }
    return {
        search: true,
        disabled: !salon,
        options,
    };
});

/**
 * Keep state of search query
 */
const withSearch = withStateHandlers(
    { search: null },
    {
        onSearch: () => (event, data) => ({
            search: data.searchQuery,
        }),
    },
);

const mapStateToProps = (state, { meta, field, fields }) => {
    return {
        salon: formValueSelector(meta.form)(
            state,
            field ? `${field}.salon` : 'salon',
        ),
        selectedValues: fields.map(item =>
            formValueSelector(meta.form)(state, `${item}.manufacturer`),
        ),
    };
};
const limitProps = mapProps(
    ({
        meta,
        change,
        manufacturers,
        field,
        dispatch,
        salon,
        fields,
        selectedValues,
        onSearch,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(mapStateToProps),
    withSearch,
    props,
    limitProps,
)(Select);
