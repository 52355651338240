/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { ModalDialog, Manufacturer } from '../../../../type';

type DeleteManufacturerDialogProps = {
    manufacturer: Manufacturer,
} & ModalDialog;

const MessageBlock = styled(Message)`
    background-color: transparent !important;
    border: 2px solid #ff4545;
    color: #ff4545 !important;
    font-weight: bold;
    box-shadow: none !important;
`;

const DeleteManufacturerDialog = ({
    manufacturer,
    trigger,
    open,
    onClose,
    onDelete,
    error,
}: DeleteManufacturerDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.settings.manufacturers.form.delete.deleteHeader"
                defaultMessage="Delete Manufacturer"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.settings.manufacturers.form.delete.deleteText"
                defaultMessage="Are you sure you want to delete {manufacturerName}?"
                values={{
                    manufacturerName: manufacturer.name,
                }}
            />
            {error && (
                <MessageBlock error>
                    <Icon name="warning sign" />
                    {error}
                </MessageBlock>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.manufacturers.form.delete.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.manufacturers.form.delete.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteManufacturerDialog;
