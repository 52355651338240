/**
 * Represents button that opens dialog with confirmation
 * request to delete specified administrator.
 *
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../lib/withModal';
import DeleteAdministratorDialog from '../containers/DeleteAdministratorDialog';

import type { Administrator, ModalDialogTrigger } from '../../../type';

type DeleteAdministratorButtonProps = {
    administrator: Administrator,
} & ModalDialogTrigger;

const DeleteAdministratorButton = ({
    administrator,
    open,
    onOpen,
    onClose,
}: DeleteAdministratorButtonProps) => (
    <DeleteAdministratorDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.administrators.administratorsList.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        administrator={administrator}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteAdministratorButton);
