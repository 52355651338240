/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Container, Segment, Icon } from 'semantic-ui-react';
import { Animation } from '../../../components';
import BaseHeader from './Header';

const appearFromLeft = keyframes`
    0% {
        opacity: .5;
        transform: translate3D(-100px, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
`;

const RootSegment = styled(Segment)`
    &.segment {
        padding: 145px 0 191px;
        background-image: url(/images/landing/inventory-bg.jpg) !important;
        background-repeat: no-repeat !important;
        background-position: 100% 33% !important;
    }
`;

const TextSegment = styled(({ animate, ...props }) => <Segment {...props} />)`
    &.segment {
        width: 370px;
        margin-left: 90px !important;
        font-size: 16px;
        line-height: 24px;
        color: #555555 !important;
        font-family: inherit;
        ${props => props.animate && `animation: ${appearFromLeft} 1s;`}

        > p {
            line-height: 24px;
        }
    }
`;

const Header = styled(BaseHeader)`
    &.header {
        color: #555555 !important;
    }
`;

const Suitable = styled.div`
    margin-top: 8px;
    color: #4ab2e2;
    font-size: 16px;
    line-height: 24px;
    font-family: inherit;
`;

const CheckmarkIcon = styled(Icon)`
    &.icon.checkmark {
        box-shadow: 0 0 0 2px #4ab2e2 inset !important;
        margin-right: 15px !important;
    }
`;

const intlMessages = defineMessages({
    header: {
        id: 'pages.landing.inventory.header',
        defaultMessage: 'Inventory management',
    },
    content: {
        id: 'pages.landing.inventory.text',
        defaultMessage: `
            Inventory management is a science primarily about specifying the
            shape and placement of stocked goods. It is required at different
            locations within a facility or within many locations of a supply
            network to precede the regular and planned course of production
            and stock of materials.
        `,
    },
    suitable: {
        id: 'pages.landing.inventory.suitable',
        defaultMessage: 'Suitable for all inventory types',
    },
});

const InventorySection = ({ animate, intl }) => (
    <RootSegment vertical basic>
        <Container>
            <Animation>
                <TextSegment vertical basic>
                    <Header as="h2">
                        {intl.formatMessage(
                            intlMessages.header,
                        )}
                    </Header>
                    <p>
                        {intl.formatMessage(
                            intlMessages.content,
                        )}
                    </p>

                    <Suitable>
                        <CheckmarkIcon name="checkmark" circular />
                        <span>
                            {intl.formatMessage(
                                intlMessages.suitable,
                            )}
                        </span>
                    </Suitable>
                </TextSegment>
            </Animation>
        </Container>
    </RootSegment>
);

// export default withAnimation(InventorySection);

export default injectIntl(InventorySection);
