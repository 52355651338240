/**
 * @flow
 */

/**
 * Search salons that match specified query
 *
 * @param {String} query - The query to search for
 */
export const search = (query: string) => ({
    type: 'SALON.SEARCH',
    query,
});

export const setSettings = settings => ({
    type: 'SALON.SETTINGS',
    settings,
});

export const setShowArchive = val => ({
    type: 'SALON.SHOW_ARCHIVE',
    showArchive: val,
});

export const setDate = (fromDate, toDate) => ({
    type: 'SALON.SET_DATE',
    fromDate,
    toDate,
});
