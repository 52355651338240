/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const StyledButton = styled(Button)`
    && {
        height: 44px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        span {
            font-weight: bold;
        }
    }
`;

type BannerPreviewLinkProps = {
    url: string,
};

const BannerPreviewLink = ({ url }: BannerPreviewLinkProps) => (
    <StyledButton basic href={url} target="_blank">
        <Icon name="eye" />
        <FormattedMessage
            id="pages.seminars.form.SeminarForm.banner.preview"
            defaultMessage="Preview previously uploaded file"
        />
    </StyledButton>
);

export default BannerPreviewLink;
