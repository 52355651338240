/**
 * Represents button that opens dialog with confirmation
 * request to delete specified master.
 *
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../lib/withModal';
import DeleteMasterDialog from '../containers/DeleteMasterDialog';

import type { Master, ModalDialogTrigger } from '../../../type';

type DeleteMasterButtonProps = {
    master: Master,
} & ModalDialogTrigger;

const DeleteMasterButton = ({
    master,
    open,
    onOpen,
    onClose,
}: DeleteMasterButtonProps) => (
    <DeleteMasterDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.staff.MastersList.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        master={master}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteMasterButton);
