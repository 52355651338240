/**
 * @flow
 */

import React from 'react';
import {
    compose,
    withProps,
    withState,
    withHandlers,
    mapProps,
} from 'recompose';
import styled, { css } from 'styled-components';
import moment from 'moment';
import BaseCell from '../../../../components/timetable/Cell';
import withModal from '../../../../lib/withModal';
import { isOverlap } from '../../../../lib/schedule';
import { convertMinutesToDateTime } from '../../../../lib/date';
import { some } from 'lodash';

import CreateAppointmentDialog from '../../containers/CreateAppointmentDialog';

import type { ModalDialogTrigger, Master, Salon } from '../../../../type';

type AppointmentCellProps = {
    time: number,
    salon: Salon,
    master: Master,
    disabled: boolean,
    onModalOpen: Function,
    additionalTime: number,
    date: Object,
    allowToAddAppointments: boolean,
    disabledFirstHalfOfHour: boolean,
    disabledLastHalfOfHour: boolean,
    masterAppointments: boolean,
} & ModalDialogTrigger;

const disabledCss = css`
    background-image: linear-gradient(
        45deg,
        transparent 46%,
        rgba(36, 51, 74, 0.2) 49%,
        rgba(36, 51, 74, 0.2) 51%,
        transparent 55%
    );
    background-size: 8px 8px;
`;

const CellWrapper = styled(BaseCell)`
    flex: 1;
    font-size: 0;
    ${({ disabled }) => disabled && disabledCss};
`;

const DialogTriggerContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const DialogTriggerItem = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    transition: background 0.3s, color 0.3s;
    color: transparent;
    user-select: none;
    cursor: pointer;
    min-height: 0;
    + .disabled {
        border-top: 1px solid #e9e9e9;
    }
    &:hover {
        background: #eef8fc;
        color: #35a3d5;
        // if trigger has height on render, timetable markup will break
        font-size: 0.9rem;
    }
`;

const DisabledSpace = styled.div`
    flex: ${({ rowspan }) => rowspan};
    ${disabledCss};
    + .trigger-item {
        border-top: 1px solid #e9e9e9;
    }
`;

const DialogTrigger = ({ onModalOpen, schedule: { format }, parts }) => (
    <DialogTriggerContainer>
        {parts.map(
            ({ startAt, endAt, disabled, rowspan, additionalTime, key }) =>
                disabled ? (
                    <DisabledSpace
                        key={key}
                        rowspan={rowspan}
                        className="disabled"
                    />
                ) : (
                    <DialogTriggerItem
                        key={key}
                        className="trigger-item"
                        onClick={onModalOpen(additionalTime)}
                    >
                        {startAt.format(format)} - {endAt.format(format)}
                    </DialogTriggerItem>
                ),
        )}
    </DialogTriggerContainer>
);

// const DisabledSpace = mapProps(({ rowspan, ...props }) => ({ ...props }))(
//     BaseDisabledSpace,
// );

const AppointmentCell = ({
    time,
    salon,
    provider,
    open,
    onOpen,
    onModalOpen,
    onClose,
    disabled,
    additionalTime,
    date,
    allowToAddAppointments,
    disabledFirstHalfOfHour,
    disabledLastHalfOfHour,
    masterAppointments,
    fromDate,
    toDate,
    parts,
    updateAppointments,
    inCabinet,
    ...props
}: AppointmentCellProps) => (
    <CreateAppointmentDialog
        trigger={
            <CellWrapper {...props} className="cell" disabled={disabled}>
                {!disabled && allowToAddAppointments && (
                    <DialogTrigger
                        onModalOpen={onModalOpen}
                        schedule={props.schedule}
                        parts={parts}
                    />
                )}
            </CellWrapper>
        }
        open={open}
        onClose={onClose}
        time={time}
        date={date}
        salon={salon}
        id={provider.id}
        additionalTime={additionalTime}
        masterAppointments={masterAppointments}
        updateAppointments={updateAppointments}
        fromDate={fromDate}
        toDate={toDate}
        inCabinet={inCabinet}
    />
);

const props = withProps(
    ({ provider: { schedule }, date, time, schedule: { step } }) => {
        const parts = [];
        let i = 0;
        for (let t = time; t < time + 60; t += step) {
            const disabled = !some(schedule, ({ startAt, endAt }) =>
                isOverlap(startAt, endAt, t, t + step),
            );
            if (i > 0 && parts[i - 1] && parts[i - 1].disabled && disabled) {
                parts[i - 1].endAt = moment(date)
                    .startOf('day')
                    .add(t + step, 'minutes');
                parts[i - 1].rowspan++;
            } else {
                parts.push({
                    startAt: convertMinutesToDateTime(t, date),
                    endAt: convertMinutesToDateTime(t + step, date),
                    additionalTime: t - time,
                    disabled,
                    rowspan: 1,
                    key: i,
                });
                i++;
            }
        }
        return {
            parts,
        };
    },
);

const state = withState('additionalTime', 'setAdditionalTime', 0);

const handlers = withHandlers({
    onModalOpen: ({ onOpen, setAdditionalTime }) => time => () => {
        setAdditionalTime(time);
        onOpen();
    },
});

export default compose(
    withModal,
    props,
    state,
    handlers,
)(AppointmentCell);
