/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import withModal from '../../../lib/withModal';
import { SeminarDetails } from './details';

import type { Seminar, ModalDialogTrigger } from '../../../type';

type SeminarDetailsButtonProps = {
    seminar: Seminar,
} & ModalDialogTrigger;

const SeminarDetailsButtonStyled = styled.span`
    cursor: pointer;
    display: flex;
    color: #000000;
    transition: color 0.3s;
    align-items: center;
    height: 44px;
    padding: 0px 11px 0px 11px;

    &:hover {
        font-weight: 600;
        color: #35a3d5;
    }
`;

const SeminarDetailsButton = ({
    seminar,
    open,
    onOpen,
    onClose,
    children,
}: SeminarDetailsButtonProps) => (
    <SeminarDetails
        trigger={
            <SeminarDetailsButtonStyled onClick={onOpen}>
                {children}
            </SeminarDetailsButtonStyled>
        }
        onClose={onClose}
        open={open}
        seminar={seminar}
    />
);

export default withModal(SeminarDetailsButton);
