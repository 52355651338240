/**
 * Represents button that opens dialog with confirmation
 * request to delete specified service.
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../../../lib/withModal';
import { FormattedMessage } from 'react-intl';
import DeleteServiceDialog from '../../../containers/service/service/DeleteServiceDialog';

import type {
    Service,
    ServiceGroup,
    ModalDialogTrigger,
} from '../../../../../type';

type DeleteServiceProps = {
    serviceGroup: ServiceGroup,
    service: Service,
} & ModalDialogTrigger;

const DeleteServiceButton = ({
    open,
    onOpen,
    onClose,
    serviceGroup,
    service,
}: DeleteServiceProps) => (
    <DeleteServiceDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.settings.services.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        open={open}
        onClose={onClose}
        serviceGroup={serviceGroup}
        service={service}
    />
);

export default withModal(DeleteServiceButton);
