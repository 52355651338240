/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';

const LogoutItem = ({ logout }) => (
    <Dropdown.Item onClick={logout} icon="sign out" text={<FormattedMessage
                   id="navbar.menu.signOut"
                   defaultMessage="Sign Out"
                />}
    />
);

export default LogoutItem;
