/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';

import type { ModalDialog, Administrator } from '../../../type';

type DeleteAdministratorDialogProps = {
    administrator: Administrator,
    onDelete: Function,
} & ModalDialog;

const DeleteAdministratorDialog = ({
    administrator,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteAdministratorDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.administrators.deleteDialog.header"
                defaultMessage="Delete Administrator"
            />
        </Header>
        <Modal.Content>
            <p>
                <FormattedMessage
                    id="pages.administrators.deleteDialog.confirmation"
                    defaultMessage="Are you sure you want to delete {administrator}?"
                    values={{
                        administrator: `${administrator.firstName} ${
                            administrator.lastName
                        }`,
                    }}
                />
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.administrators.deleteDialog.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.administrators.deleteDialog.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteAdministratorDialog;
