/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment from 'moment';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { map, cloneDeep } from 'lodash';
import { reduxForm, reset } from 'redux-form';
import { injectIntl } from 'react-intl';
import { prepareFloatForServer } from '../../../lib/numberFormatter';
import { GET_REPORT_QUERY } from './seller/SellerReportTable';
import validate from '../form/editInvoiceValidate';

import EditInvoiceDialog from '../components/EditInvoiceDialog';

const EDIT_INVOICE_QUERY = gql`
    mutation editInvoice($input: EditInvoiceInput!) {
        editInvoice(input: $input) {
            invoice {
                id
                items {
                    id
                    price
                    paymentType
                }
            }
        }
    }
`;

const ARCHIVE_PRODUCTS_MUTATION = gql`
    mutation archiveCartInvoice($input: ArchiveCartInvoiceInput!) {
        archiveCartInvoice(input: $input) {
            invoice {
                id
                archived
            }
        }
    }
`;

const DATE_FORMAT = 'YYYY-MM-DD';

const withData = graphql(EDIT_INVOICE_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onSubmit: formData => {
            const price = prepareFloatForServer(formData.price);

            const mutation = mutate({
                variables: {
                    input: {
                        invoiceId: ownProps.invoiceId,
                        itemId: ownProps.item.id,
                        price,
                        paymentType: formData.paymentType,
                    },
                },
                update: store => {
                    try {
                        const data = store.readQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                        });

                        store.writeQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                            data,
                        });
                    } catch (e) {
                        // It's safe to ignore this exception
                    }
                },
            });

            return mutation.then(() => {
                ownProps.onClose();
            });
        },
    }),
});

const withArchiveMutation = graphql(ARCHIVE_PRODUCTS_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        onArchive: (archived, invoiceId) => {
            const mutation = mutate({
                variables: {
                    input: {
                        invoiceId,
                        archived,
                    },
                },
                update: (
                    store,
                    {
                        data: {
                            archiveCartInvoice: { invoice },
                        },
                    },
                ) => {
                    try {
                        const data = store.readQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                        });

                        const updateArchive = item => {
                            const newItem = cloneDeep(item);
                            if (newItem.invoiceId === invoiceId) {
                                newItem.archived = invoice.archived;
                            }
                            return newItem;
                        };

                        const adminReportInvoices = map(
                            data.viewer.salon.administrator.report.invoices,
                            item => updateArchive(item),
                        );

                        data.viewer.salon.administrator.report.invoices = adminReportInvoices;

                        const updatedAdministrators = map(
                            data.viewer.salon.administrators,
                            administrator => {
                                const newAdmin = cloneDeep(administrator);
                                const newInvoices = map(
                                    newAdmin.report.invoices,
                                    item => updateArchive(item),
                                );
                                newAdmin.report.invoices = newInvoices;
                                return newAdmin;
                            },
                        );

                        data.viewer.salon.administrators = updatedAdministrators;

                        const updatedMasters = map(
                            data.viewer.salon.masters.nodes,
                            master => {
                                const newMaster = cloneDeep(master);
                                const newInvoices = map(
                                    newMaster.report.invoices,
                                    item => updateArchive(item),
                                );
                                newMaster.report.invoices = newInvoices;
                                return newMaster;
                            },
                        );

                        data.viewer.salon.masters.nodes = updatedMasters;

                        store.writeQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                            data,
                        });
                    } catch (e) {
                        // It's safe to ignore this exception
                    }
                },
            });

            return mutation.then(() => {
                ownProps.onClose();
            });
        },
    }),
});

const withForm = reduxForm({
    form: 'editInvoice',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withModalHandlers = withHandlers({
    onModalClose: ({ onClose }) => () => {
        reset('editInvoice');
        onClose();
    },
});

const withInitialValues = withProps(ownProps => {
    if (ownProps.open) {
        return {
            initialValues: {
                price: parseFloat(ownProps.item.price).toFixed(2),
                paymentType: ownProps.item.paymentType,
            },
        };
    }
});

const mapStateToProps = ({ user, report, salon, intl }) => ({
    reportFilter: report.get('reportFilter'),
    user: user.get('id'),
    salon: user.get('salon'),
    fromDate: moment(report.get('fromDate')).format(DATE_FORMAT),
    toDate: moment(report.get('toDate')).format(DATE_FORMAT),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(
        mapStateToProps,
        { reset },
    ),
    injectIntl,
    withData,
    withArchiveMutation,
    withInitialValues,
    withModalHandlers,
    withForm,
)(EditInvoiceDialog);
