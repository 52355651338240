/**
 * @flow
 */

import moment from 'moment';

/**
 * Change schedule date
 *
 * @param {String} date - A new date to set
 */
export const setDate = (date: string) => ({
    type: 'SCHEDULE.SET_DATE',
    date: moment(date).toDate(),
});

export const generateFromDate = (firstRangeFrom, firstRangeTo) => ({
    type: 'SCHEDULE.GENERATE_FROM_DATE',
    firstRangeFrom,
    firstRangeTo,
});

export const generateToDate = (secondRangeFrom, secondRangeTo) => ({
    type: 'SCHEDULE.GENERATE_TO_DATE',
    secondRangeFrom,
    secondRangeTo,
});
