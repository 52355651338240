/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Form, Input, Button } from 'semantic-ui-react';
import { Field } from 'redux-form';

import { FormField } from '../../../../components';
import ConnectionFields from './ConnectionFields';

import type { FieldArray } from 'redux-form';

type FieldsListProps = {
    fields: FieldArray,
    change: Function,
};

const FieldList = ({
    fields,
    change,
    salons,
    manufacturers,
}: FieldsListProps) => (
    <div>
        {fields.map((field, idx) => (
            <Form.Field key={idx}>
                <ConnectionFields
                    field={field}
                    fields={fields}
                    change={change}
                    salons={salons}
                    manufacturers={manufacturers}
                    onRemove={() => fields.remove(idx)}
                />
            </Form.Field>
        ))}
        <a
            href="#add"
            onClick={e => {
                e.preventDefault();
                fields.push({});
            }}
        >
            <FormattedMessage
                id="pages.suppliers.supplierForm.salonConnection.addNew"
                defaultMessage="Add new +"
            />
        </a>
    </div>
);

export default FieldList;
