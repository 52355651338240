/**
 * Represents link that opens dialog with master details
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import { MasterDetails } from './details';

import type { ModalDialogTrigger, Master } from '../../../type';

type EditMasterButtonProps = {
    master: Master,
    children: any,
} & ModalDialogTrigger;

const EditMasterButton = ({
    master,
    children,
    open,
    onOpen,
    onClose,
}: EditMasterButtonProps) => (
    <MasterDetails
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.staff.MastersList.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
        master={master}
        onClose={onClose}
        open={open}
    />
);

export default withModal(EditMasterButton);
