/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';

type EllipsisLoaderProps = {
    size?: string,
    fontSize?: string,
    color?: string,
    label?: string,
    inline?: boolean,
    className: string,
};

const bounce = keyframes`
    0%, 80%, 100% { 
        transform: scale(0);
    } 40% { 
        transform: scale(1.0);
    }
`;

const RootContainer = styled.div`
    text-align: center;
    ${props => props.inline && `display: inline-block;`};
`;

const BounceWrapper = styled.div`
    & > div {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        animation: ${bounce} 1.4s infinite ease-in-out both;        
        ${({ color }) => color && `background-color: ${color};`}
        width: ${({ size }) => size};
        height: ${({ size }) => size};
        vertical-align: middle;
    }
`;

const FirstBounce = styled.div`
    animation-delay: -0.32s !important;
`;

const SecondBounce = styled.div`
    animation-delay: -0.16s !important;
`;

const ThirdBounce = styled.div``;

const Label = styled.div`
    ${({ size }) => `font-size: ${size}`};
`;

const EllipsisLoader = ({
    size,
    fontSize,
    color,
    label,
    inline,
    className,
}: EllipsisLoaderProps) => (
    <RootContainer inline={inline} className={className}>
        <BounceWrapper size={size} color={color}>
            <FirstBounce className="bounce" />
            <SecondBounce className="bounce" />
            <ThirdBounce className="bounce" />
        </BounceWrapper>
        <Label size={fontSize}>{label}</Label>
    </RootContainer>
);

EllipsisLoader.defaultProps = {
    size: '1em',
    fontSize: '0.85714286em',
    color: '#333',
};

export default EllipsisLoader;
