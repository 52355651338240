/**
 * @flow
 */

import { connect } from 'react-redux';
import PromoSection from '../../components/promoSection/PromoSection';

// User is authetnicated if we have a token
export default connect(({ user }) => ({
    authenticated: user.has('token'),
}))(PromoSection);
