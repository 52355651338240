/**
 * @flow
 */

import { each, set, isNil, isNumber, isEmpty, filter, find } from 'lodash';
import { defineMessages } from 'react-intl';
import { isMongoId } from '../../../../../lib/formValidation';
import { priceIsNotValid } from '../../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    atLeastOneError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.atLeastOneError',
        defaultMessage: 'Add atleast one item',
    },
    typeError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.typeError',
        defaultMessage: 'Select type',
    },
    selectProductOrServiceError: {
        id:
            'pages.appointments.details.invoice.InvoiceFields.selectProductOrServiceError',
        defaultMessage: 'Select product or service',
    },
    selectProductQuantityError: {
        id:
            'pages.appointments.details.invoice.InvoiceFields.selectQuantityError',
        defaultMessage: 'Select quantity',
    },
    priceError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.priceError',
        defaultMessage: 'Enter price',
    },
    paymentTypeError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.paymentTypeError',
        defaultMessage: 'Select payment type',
    },
    sellerError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.sellerError',
        defaultMessage: 'Select seller',
    },
    membershipError: {
        id: 'pages.appointments.details.invoice.InvoiceFields.membershipError',
        defaultMessage: 'Only one use of membership is allowed',
    },
    membershipDoesNotExist: {
        id:
            'pages.appointments.details.invoice.InvoiceFields.membershipDoesNotExist',
        defaultMessage: 'Membership does not exist',
    },
});

export default (values, { intl, membership }) => {
    const errors = {};

    if (isEmpty(values.items) || isNil(values.items)) {
        set(errors, '_error', intl.formatMessage(intlMessages.atLeastOneError));
    }

    if (filter(values.items, i => i.paymentType === 'MEMBERSHIP').length > 1) {
        set(errors, '_error', intl.formatMessage(intlMessages.membershipError));
    }

    if (
        !isNil(values.items) &&
        !isEmpty(values.items) &&
        find(values.items, i => i.paymentType === 'MEMBERSHIP') &&
        !membership
    ) {
        set(
            errors,
            '_error',
            intl.formatMessage(intlMessages.membershipDoesNotExist),
        );
    }

    each(values.items, (item, idx) => {
        if (isNil(item.type)) {
            set(
                errors,
                ['items', idx, 'type'],
                intl.formatMessage(intlMessages.typeError),
            );
        }

        if (item.paymentType === 'MEMBERSHIP' && item.quantity > 1) {
            set(
                errors,
                ['items', idx, 'quantity'],
                intl.formatMessage(intlMessages.membershipError),
            );
        }

        if (
            membership &&
            item.paymentType === 'MEMBERSHIP' &&
            item.entity !== membership.service.id
        ) {
            set(
                errors,
                ['items', idx, 'entity'],
                intl.formatMessage(intlMessages.membershipDoesNotExist),
            );
        }

        if (!isMongoId(item.entity)) {
            set(
                errors,
                ['items', idx, 'entity'],
                intl.formatMessage(intlMessages.selectProductOrServiceError),
            );
        }
        if (!Number(item.quantity)) {
            set(
                errors,
                ['items', idx, 'quantity'],
                intl.formatMessage(intlMessages.selectProductQuantityError),
            );
        }
        if (
            isNil(item.price) ||
            priceIsNotValid(item.price) ||
            !isNumber(parseFloat(item.price))
        ) {
            set(
                errors,
                ['items', idx, 'price'],
                intl.formatMessage(intlMessages.priceError),
            );
        }
        if (isNil(item.paymentType)) {
            set(
                errors,
                ['items', idx, 'paymentType'],
                intl.formatMessage(intlMessages.paymentTypeError),
            );
        }

        if (!isMongoId(item.seller)) {
            set(
                errors,
                ['items', idx, 'seller'],
                intl.formatMessage(intlMessages.sellerError),
            );
        }
    });

    return errors;
};
