/**
 * @flow
 */

import styled from 'styled-components';
import Select from './Select';

const TimeSpanSelect = styled(Select)`
    & .text:before {
        font-family: 'Icons';
        content: '\uf017';
        padding-right: 13px;
        color: #767676;
    }
`;

export default TimeSpanSelect;
