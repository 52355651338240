import { isNil, size } from 'lodash';

/**
 * Check if given string conforms to the MongoDB ObjectId specification
 *
 * @param {String} value - Value of the form field to be validated
 */
export const isMongoId = (value: string) => {
    var checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    const mongoObjIdLen = 24;

    return !isNil(value) &&
        size(value) === mongoObjIdLen &&
        value.match(checkForHexRegExp)
        ? true
        : false;
};
