import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const Toggle = ({ value, onChange }) => {
    return (
        <Checkbox
            toggle
            checked={value ? true : false}
            onChange={(e, { checked }) => onChange(checked)}
        />
    );
};

export default Toggle;
