/**
 * @flow
 */

export const addInvoiceProduct = (field: string, option: Object) => ({
    type: 'INVOICE.ADD_INVOICE_PRODUCT',
    field,
    option,
});

export const deleteInvoiceProduct = (field: string) => ({
    type: 'INVOICE.DELETE_INVOICE_PRODUCT',
    field,
});

export const clearInvoiceProducts = () => ({
    type: 'INVOICE.CLEAR_PRODUCTS',
});
