/**
 * @flow
 */

import React from 'react';
import withModal from '../../../../lib/withModal';
import DeleteManufacturerDialog from '../../containers/manufacturers/DeleteManufacturerDialog';
import { FormattedMessage } from 'react-intl';
import type { Manufacturer, ModalDialogTrigger } from '../../../../type';

type EditManufacturerButtonProps = {
    manufacturer: Manufacturer,
} & ModalDialogTrigger;

const EditManufacturerButton = ({
    manufacturer,
    open,
    onOpen,
    onClose,
}: EditManufacturerButtonProps) => (
    <DeleteManufacturerDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.settings.manufacturers.Delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        onClose={onClose}
        open={open}
        manufacturer={manufacturer}
    />
);

export default withModal(EditManufacturerButton);
