/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import withModal from '../../../../lib/withModal';
import ArchiveDialog from './ArchiveDialog';

type ArchiveButtonProps = {
    loading: boolean,
    disabled: boolean,
    onClick: Function,
};

const renderLabel = isArchived => {
    if (isArchived) {
        return (
            <FormattedMessage
                id="pages.appointments.details.ArchiveButton.unarchive"
                defaultMessage="Unarchive"
            />
        );
    }
    return (
        <FormattedMessage
            id="pages.appointments.details.ArchiveButton.archive"
            defaultMessage="Archive"
        />
    );
};

const ArchiveButton = ({
    appointment,
    loading,
    disabled,
    open,
    onOpen,
    onClose,
    onArchive,
}: ArchiveButtonProps) => (
    <ArchiveDialog
        trigger={
            <Button onClick={onOpen} color="orange" floated="right">
                <Icon name="archive" />
                {renderLabel(appointment.archived)}
            </Button>
        }
        appointment={appointment}
        open={open}
        onClose={onClose}
        onArchive={onArchive}
    />
);

export default withModal(ArchiveButton);
