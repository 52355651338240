/**
 * @flow
 */

import moment from 'moment';

/**
 * Check whether date ranges are overlapping.
 * @param {Date} initialStartDate initial start date
 * @param {Date} initialEndDate initial end date
 * @param {Date} comparedStartDate compared start date
 * @param {Date} comparedEndDate compared and date
 * @return {boolean}
 */
export const areRangesOverlapping = (
    initialStartDate: moment.MomentInput,
    initialEndDate: moment.MomentInput,
    comparedStartDate: moment.MomentInput,
    comparedEndDate: moment.MomentInput,
): boolean => {
    const initialMoment = moment(initialStartDate);
    const comparedMoment = moment(comparedStartDate);

    if (
        initialMoment.isAfter(initialEndDate) ||
        comparedMoment.isAfter(comparedEndDate)
    ) {
        throw new Error(
            'The start of the range cannot be after the end of the range',
        );
    }

    return (
        initialMoment.isBefore(comparedEndDate) &&
        comparedMoment.isBefore(initialEndDate)
    );
};

/**
 * Return the array of dates within the specified range.
 * @param {Date} startDate start date
 * @param {Date} endDate end date
 */
export const eachDay = (
    startDate: moment.MomentInput,
    endDate: moment.MomentInput,
    format: ?string = null,
): Array<Date> => {
    let day = moment(startDate).startOf('day');
    const result = [];

    if (day.isAfter(endDate)) {
        throw new Error('The first date cannot be after the second date');
    }

    while (day.isSameOrBefore(endDate)) {
        result.push(format ? day.format(format) : day.toDate());
        day.add(1, 'day');
    }

    return result;
};

/**
 * Return date time string, converted from number of minutes from the beginning of the day.
 * @param {Number} number of minutes from the beginnig of the day
 * @param {String} date to which minutes will be added
 */
export const convertMinutesToDateTime = (
    minutes: number,
    date: string = new Date(),
) => {
    const hour = Math.trunc(minutes / 60);
    const minute = minutes % 60;
    return moment(date)
        .set('hour', hour)
        .set('minute', minute);
};

/**
 * Return number of minutes from the beginning of the day.
 * @param {String} datetime string to be converted to number of minutes
 */
export const convertDateTimeToMinutes = (dateTime: string) => {
    const hours = moment(dateTime).get('hours');
    const minutes = moment(dateTime).get('minutes');
    return hours * 60 + minutes;
};
