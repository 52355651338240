/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { isEmpty, map, orderBy } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Modal, Table, EllipsisLoader } from '../../../../components';

const intlMessages = defineMessages({
    CASH: {
        id: 'pages.appointments.details.invoice.InvoiceForm.cash',
        defaultMessage: 'Cash',
    },
    CC: {
        id: 'pages.appointments.details.invoice.InvoiceForm.credit_card',
        defaultMessage: 'Credit Card',
    },
    GIFT: {
        id: 'pages.appointments.details.invoice.InvoiceForm.gift_card',
        defaultMessage: 'Gift Card',
    },
    MEMBERSHIP: {
        id: 'pages.appointments.details.invoice.InvoiceForm.membership',
        defaultMessage: 'Membership',
    },
});

type AppointmentsProps = {
    boughtItems: Array<any>,
};

type AppointmentRowProps = {
    boughtItem: Object,
};

/**
 * Show this message if list of appointments is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.clients.details.appointments.noAppointments"
                defaultMessage="This client don't have appointments"
            />
        </Table.Cell>
    </Table.Row>
);

const LoadMoreSpinner = () => <EllipsisLoader size="1em" />;

/**
 * A row of single appointment
 */
const AppointmentRow = ({ boughtItem, intl }: AppointmentRowProps) => {
    const styledRow = moment(
        boughtItem.boughtAt,
        'DD.MM.YYYY HH:mm',
        true,
    ).isAfter(moment(moment(), 'DD.MM.YYYY HH:mm'));
    const TableRow = styled(Table.Row)`
        ${styledRow && ' font-weight: bold;'}
    `;

    const iconNames = {
        CASH: 'money',
        CC: 'credit card',
        GIFT: 'gift',
        MEMBERSHIP: 'address card outline',
    };

    return (
        <TableRow>
            <Table.Cell>
                {/* we need to parse number from string, because we receive timestamp from the server as a string
            this is a temporary solution until the problem with the data types in graphql is resolved */}
                {boughtItem.boughtAt}
            </Table.Cell>
            <Table.Cell>
                {`${boughtItem.firstName} ${boughtItem.lastName || ''}`}
            </Table.Cell>
            <Table.Cell>{boughtItem.name}</Table.Cell>
            <Table.Cell textAlign="right">
                {`${boughtItem.price.toFixed(2)} €`}
            </Table.Cell>
            <Table.Cell
                textAlign="center"
                title={
                    boughtItem.paymentType
                        ? intl.formatMessage(
                              intlMessages[boughtItem.paymentType],
                          )
                        : ''
                }
                style={{ width: '50px' }}
            >
                {boughtItem.paymentType && (
                    <Icon
                        name={iconNames[boughtItem.paymentType]}
                        size="large"
                    />
                )}
            </Table.Cell>
        </TableRow>
    );
};

const Appointments = ({
    boughtItems,
    futureAppointments,
    loadMore,
    pageInfo,
    intl,
}: AppointmentsProps) => {
    const sortedItems =
        futureAppointments &&
        boughtItems &&
        orderBy(
            [...futureAppointments, ...boughtItems],
            i => moment(i.boughtAt, 'DD.MM.YYYY HH:mm'),
            ['desc'],
        );

    return (
        <Modal.Context>
            <Modal.Content>
                <div
                    style={{
                        maxHeight: '400px',
                        overflow: 'auto',
                        width: '100%',
                    }}
                >
                    <InfiniteScroll
                        pageStart={0}
                        initialLoad={false}
                        loadMore={loadMore}
                        hasMore={pageInfo.hasNextPage}
                        loader={
                            boughtItems.length <= 10 ? (
                                ''
                            ) : (
                                <LoadMoreSpinner key={0} />
                            )
                        }
                        useWindow={false}
                    >
                        <Table celled striped selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <FormattedMessage
                                            id="pages.clients.details.appointments.date"
                                            defaultMessage="Date"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <FormattedMessage
                                            id="pages.clients.details.appointments.master"
                                            defaultMessage="Master"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <FormattedMessage
                                            id="pages.clients.details.appointments.service"
                                            defaultMessage="Service"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        <FormattedMessage
                                            id="pages.clients.details.appointments.price"
                                            defaultMessage="Price"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{ width: '50px' }}
                                    />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {isEmpty(futureAppointments) &&
                                isEmpty(boughtItems) ? (
                                    <EmptyMessage />
                                ) : (
                                    map(sortedItems, (item, key) => (
                                        <AppointmentRow
                                            key={key}
                                            boughtItem={item}
                                            intl={intl}
                                        />
                                    ))
                                )}
                            </Table.Body>
                        </Table>
                    </InfiniteScroll>
                </div>
            </Modal.Content>
        </Modal.Context>
    );
};

export default injectIntl(Appointments);
