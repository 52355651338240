/**
 * @flow
 */

import { connect } from 'react-redux';
import { compose, withState, withHandlers, mapProps } from 'recompose';

import { Calendar } from '../components';

const mapStateToProps = ({ intl }) => ({
    locale: intl.get('locale'),
});

const state = withState('isOpen', 'setOpen', false);

const handlers = withHandlers({
    openPopup: ({ setOpen }) => () => {
        setOpen(true);
    },
    closePopup: ({ setOpen }) => () => {
        setTimeout(() => setOpen(false), 0);
    },
});

const limitProps = mapProps(({ setOpen, ...props }) => ({
    ...props,
}));

export default compose(
    connect(mapStateToProps),
    state,
    handlers,
    limitProps,
)(Calendar);
