/**
 *  @flow
 */

import { connect } from 'react-redux';
import { reduxForm, getFormValues, reset } from 'redux-form';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import MembershipDetails from '../../components/details/MembershipDetails';
import validate from '../form/validate';

const withForm = reduxForm({
    form: 'createMembership',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withModalHandlers = withHandlers({
    onModalClose: ({ onClose, setInitialValues, reset }) => () => {
        setInitialValues({});
        reset('createMembership');
        onClose();
    },
});

const withInitialFormValues = withProps(ownProps => {
    if (ownProps.open) {
        const {
            client,
            createdAt,
            price,
            totalUses,
            quantity,
            serialNumber,
            service,
        } = ownProps.membership;

        return {
            initialValues: {
                client: client.id,
                service: service.id,
                price: parseFloat(price).toFixed(2),
                totalUses,
                quantity,
                serialNumber,
                createdAt: moment(createdAt).format('YYYY-MM-DD HH:mm'),
            },
        };
    }
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

export default compose(
    connect(
        ({ user, intl, ...state }) => ({
            salon: user.get('salon'),
            formData: getFormValues('createMembership')(state),
            languages: intl.get('languages'),
            defaultLanguage: intl.get('defaultLanguage'),
            currentLanguage: intl.get('locale'),
            userId: user.get('id'),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withInitialFormValues,
    withModalHandlers,
    withForm,
)(MembershipDetails);
