/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import { Container, Menu, Select as BaseSelect } from 'semantic-ui-react';

import { Search } from '../../../components';

const intlMessages = {
    search: defineMessages({
        placeholder: {
            id: 'pages.inventory.InventoryPage.search.placeholder',
            defaultMessage: 'Search...',
        },
    }),
    salon: defineMessages({
        placeholder: {
            id: 'pages.suppliers.warehouse.salons.placeholder',
            defaultMessage: 'Salons',
        },
    }),
};

const Select = styled(BaseSelect)`
    min-width: 300px !important;
`;

export default ({
    searchQuery,
    salonFilter,
    onSearch,
    onSalonSelect,
    salonOptions,
    intl,
}) => (
    <Menu.Menu position="right">
        <Menu.Item>
            <Select
                placeholder={intl.formatMessage(intlMessages.salon.placeholder)}
                options={salonOptions}
                value={salonFilter}
                onChange={onSalonSelect}
                search
                multiple
            />
        </Menu.Item>
        <Menu.Item>
            <Search
                placeholder={intl.formatMessage(
                    intlMessages.search.placeholder,
                )}
                value={searchQuery}
                onChange={onSearch}
            />
        </Menu.Item>
    </Menu.Menu>
);
