/**
 * @flow
 */

import { connect } from 'react-redux';
import { compose, withState, withHandlers } from 'recompose';

import AppointmentCardPopup from '../../components/timetable/AppointmentCardPopup';

// Handle popup visibility state to skip request while it's closed yet
const withPopupState = withState('open', 'setOpen', false);
const withPopupHandlers = withHandlers({
    onOpen: ownProps => () => {
        ownProps.setOpen(true);
    },
    onClose: ownProps => () => {
        setTimeout(() => ownProps.setOpen(false), 0);
        // tricky thing - the following code: ownProps.setOpen(false);
        // will make target popup to disappear
        // but eventually will close all other popups/modals (?)
    },
});

const mapStateToProps = ({ user }) => ({
    salonId: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    withPopupState,
    withPopupHandlers,
)(AppointmentCardPopup);
