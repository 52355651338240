import { isNil, isEmpty, set } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    passwordError: {
        id: 'pages.password.form.changePassword.passwordError',
        defaultMessage: 'Enter your new password',
    },
    repeatPasswordError: {
        id: 'pages.password.form.changePassword.repeatPasswordError',
        defaultMessage: 'Repeat your new password',
    },
    passwordDoesntMatchError: {
        id: 'pages.password.form.changePassword.passwordDoesntMatchError',
        defaultMessage: 'Your password doesnt match',
    },
});

export default (values, { intl }) => {
    const errors = {};

    // Make sure that user has provided a new password
    if (isNil(values.password) || isEmpty(values.password)) {
        set(errors, 'password', intl.formatMessage(intlMessages.passwordError));
    }

    // Make sure that user has repeated his new password
    if (isNil(values.repeatPassword) || isEmpty(values.repeatPassword)) {
        set(
            errors,
            'repeatPassword',
            intl.formatMessage(intlMessages.repeatPasswordError),
        );
    } else {
        // Make sure that user has correctly repeated a new password
        if (values.password !== values.repeatPassword) {
            set(
                errors,
                'repeatPassword',
                intl.formatMessage(intlMessages.passwordDoesntMatchError),
            );
        }
    }

    return errors;
};
