/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {
    Container as BaseContainer,
    Menu as BaseMenu,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { PageHeader } from '../../../components';
import CreateAppointmentButton from './CreateAppointmentButton';
import CartButton from '../containers/cart/CartButton';
import MasterTable from '../containers/timetable/MasterTable';
import CabinetTable from '../containers/timetable/CabinetTable';
import DatePicker from '../containers/DatePicker';

const Container = styled(BaseContainer)`
    &.container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const TimetableWrapper = styled.div`
    margin: 0.5em -2em 0 -2em;
    flex: 1;
`;

const CartButtonContainer = styled(BaseMenu.Item)`
    .ui.secondary.menu .item& {
        padding-left: 0;
    }
`;

const TabItem = styled(BaseMenu.Item)`
    &.active {
        background-color: #2185d0 !important;
        color: #fff !important;
    }
`;

const AppointmentsPage = ({ setTab, tab, isMaster }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="pages.schedule.SchedulePage"
                        defaultMessage="Appointments"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <TabItem
                name="masters"
                active={tab === 'masters'}
                onClick={(e, { name }) => setTab(name)}
            >
                <FormattedMessage
                    id="pages.appointments.masters.label"
                    defaultMessage="By masters"
                />
            </TabItem>
            <TabItem
                name="cabinets"
                active={tab === 'cabinets'}
                onClick={(e, { name }) => setTab(name)}
            >
                <FormattedMessage
                    id="pages.appointments.cabinets.label"
                    defaultMessage="By cabinets"
                />
            </TabItem>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item fitted>
                    <DatePicker
                        value={new Date()}
                        format="MMM D, dddd"
                        readOnly
                        todayButton
                    />
                </BaseMenu.Item>
                <BaseMenu.Item>
                    <CreateAppointmentButton inCabinet={tab === 'cabinets'} />
                </BaseMenu.Item>
                {!isMaster && (
                    <CartButtonContainer>
                        <CartButton />
                    </CartButtonContainer>
                )}
            </BaseMenu.Menu>
        </Menu>
        <TimetableWrapper>
            {tab === 'masters' ? <MasterTable /> : <CabinetTable />}
        </TimetableWrapper>
    </Container>
);

export default AppointmentsPage;
