/**
 * Represents link that opens dialog with client details
 *
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import withModal from '../../../lib/withModal';

import { ClientDetails } from './details';

import type { ModalDialogTrigger, Client } from '../../../type';

type ClientDetailsButtonProps = {
    client: Client,
    children: any,
    tab: string,
} & ModalDialogTrigger;

const ClientDetailsButtonStyled = styled.span`
    cursor: pointer;
    display: flex;
    color: #000000;
    transition: color 0.3s;
    align-items: center;
    height: 44px;
    padding: 0px 11px 0px 11px;

    &:hover {
        font-weight: 600;
        color: #35a3d5;
    }
`;

const ClientDetailsButton = ({
    client,
    children,
    tab,
    open,
    onOpen,
    onClose,
}: ClientDetailsButtonProps) => (
    <ClientDetails
        trigger={
            <ClientDetailsButtonStyled onClick={onOpen}>
                {children}
            </ClientDetailsButtonStyled>
        }
        client={client}
        onModalClose={onClose}
        isModalOpen={open}
        forcedSelectedItem={tab}
    />
);

export default withModal(ClientDetailsButton);
