import { set, isEmpty, get } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    salonNameError: {
        id: 'pages.settings.generalSettings.salonNameError',
        defaultMessage: 'Please enter salon name',
    },
    salonAddressError: {
        id: 'pages.settings.generalSettings.salonAddressError',
        defaultMessage: 'Please enter salon address',
    },
    salonStartAtError: {
        id: 'pages.settings.generalSettings.salonStartAtError',
        defaultMessage: 'Please enter the start time of the working day',
    },
    salonEndAtError: {
        id: 'pages.settings.generalSettings.salonEndAtError',
        defaultMessage: 'Please enter the end time of the working day',
    },
});

export default (values, { intl }) => {
    const errors = {};
    // Validate salon name
    if (isEmpty(values.name)) {
        set(errors, 'name', intl.formatMessage(intlMessages.salonNameError));
    }
    //validate salon address
    if (isEmpty(values.address)) {
        set(
            errors,
            'address',
            intl.formatMessage(intlMessages.salonAddressError),
        );
    }
    //validate start at
    const step = get(values, 'settings.schedule.step');
    const startAt = get(values, 'settings.schedule.startAt');
    const endAt = get(values, 'settings.schedule.endAt');

    if (step === 60) {
        if ((startAt / 30) % 2 > 0) {
            set(
                errors,
                'settings.schedule.startAt',
                intl.formatMessage(intlMessages.salonStartAtError),
            );
        }

        if ((endAt / 30) % 2 > 0) {
            set(
                errors,
                'settings.schedule.endAt',
                intl.formatMessage(intlMessages.salonEndAtError),
            );
        }
    }

    return errors;
};
