import { isNil, isEmpty, set } from 'lodash';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    emailError: {
        id: 'pages.clients.resetPasswordForm.emailError',
        defaultMessage: 'Enter your email address',
    },
});

export default (values, { intl }) => {
    const errors = {};

    // Make sure email is defined and is not empty
    if (isNil(values.email) || isEmpty(values.email)) {
        set(errors, 'email', intl.formatMessage(intlMessages.emailError));
    }

    return errors;
};
