/**
 * @flow
 */

import gql from 'graphql-tag';
import {
    connect
} from 'react-redux';
import {
    formValueSelector
} from 'redux-form';
import {
    compose,
    withProps,
    mapProps
} from 'recompose';
import {
    map,
    isEmpty,
    find
} from 'lodash';
import moment from 'moment';
import {
    getFreeTimeSpans,
    getInvertedTimeSpans
} from '../../../../lib/schedule';
import {
    TimeSpanSelect
} from '../../../../components';
import client from '../../../../apollo-client';

// Query to get salon
const GET_SALON_QUERY = gql `
    query getSalonSettings($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                }
            }
        }
    }
`;

// Get requred values from form
const mapStateToProps = (state, ownProps) => ({
    salon: state.user.get('salon'),
    masterId: formValueSelector(ownProps.meta.form)(state, 'master'),
    startDate: formValueSelector(ownProps.meta.form)(state, 'startDate'),
    serviceId: formValueSelector(ownProps.meta.form)(state, 'service'),
    duration: formValueSelector(ownProps.meta.form)(state, 'duration'),
});

const props = withProps(({
    value,
    masterId,
    serviceId,
    duration,
    loading,
    salon,
    masters
}) => {
    // Get salon settings from local cache
    const data = client.readQuery({
        query: GET_SALON_QUERY,
        variables: {
            salon,
        },
    });
    const {
        schedule
    } = data.viewer.salon.settings;

    const master = find(masters, master => master.id === masterId);

    const disabled = loading || !serviceId || !master || !duration;

    let options = null;
    if (!disabled) {
        // Generate list of time spans when master is not working at this date
        const notWorkingTime = getInvertedTimeSpans({
            startAt: schedule.startAt,
            endAt: schedule.endAt,
            timeSpans: map(master.schedule, workingTime => [
                schedule.step === 60 && (workingTime.startAt / 30) % 2 > 0 ?
                workingTime.startAt - 30 :
                workingTime.startAt,
                schedule.step === 60 && (workingTime.endAt / 30) % 2 > 0 ?
                workingTime.endAt + 30 :
                workingTime.endAt,
            ]),
        });

        // Find list of free time spans that can fit provided duration
        const freeTimeSpans = getFreeTimeSpans({
            timeSpan: [schedule.startAt, schedule.endAt],
            duration,
            exclude: notWorkingTime,
            step: schedule.step,
        });

        // Convert free spans to list of options
        options = map(freeTimeSpans, timeSpan => {
            return {
                text: [
                    moment()
                    .startOf('day')
                    .add(timeSpan[0], 'minutes')
                    .format(schedule.format),
                    moment()
                    .startOf('day')
                    .add(timeSpan[1], 'minutes')
                    .format(schedule.format),
                ].join(' - '),
                value: timeSpan[0],
            };
        });
    }

    // if there is value but no options, show the value as placeholder
    let text = null;
    if (value && isEmpty(options)) {
        text = moment()
            .startOf('day')
            .add(value, 'minutes')
            .format(schedule.format);
    }

    return {
        options,
        disabled,
        text,
    };
});

const limitProps = mapProps(
    ({
        meta,
        appointment,
        salon,
        masterId,
        startDate,
        serviceId,
        duration,
        dispatch,
        master,
        masters,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(mapStateToProps),
    props,
    limitProps,
)(TimeSpanSelect);
