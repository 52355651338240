/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Menu as BaseMenu } from '../../../../components';

export type NavigationItems = 'personalInformation' | 'appointments';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #F9FAFB !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34,36,38,.15) !important;
`;

const Navigation = ({ selectedItem, onItemClick }: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="personalInformation"
            active={selectedItem === 'personalInformation'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.staff.navivation.personalInformation"
                defaultMessage="Personal information"
            />
        </BaseMenu.Item>
        <BaseMenu.Item
            name="appointments"
            active={selectedItem === 'appointments'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.staff.navigation.appointments"
                defaultMessage="Appointments"
            />
        </BaseMenu.Item>
    </Menu>
);

export default Navigation;
