/**
 * @flow
 */

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Modal } from '../../../../components';
import Navigation from './Navigation';
import EditAppointment from '../../containers/details/EditAppointment';
import AppointmentInvoice from '../../containers/details/invoice/AppointmentInvoice';

import type { ModalDialog, Appointment, Salon } from '../../../../type';
import type { NavigationItems } from './Navigation';

type AppointmentDetailsProps = {
    salon: Salon,
    appointment: Appointment,
    selectedItem: NavigationItems,
    onItemClick: Function,
    children: Function,
    updateAppointmentClient: Function,
    onCreateInvoice: Function,
    masterAppointments: Array<Appointment>,
    fromDate: string | Date,
    toDate: string | Date,
} & ModalDialog;

const Subheader = styled(Header.Subheader)`
    font-size: 1rem;
    font-weight: normal;
`;

const AppointmentDetails = ({
    salon,
    appointment,
    children,
    onClose,
    open,
    selectedItem,
    disableInvoice,
    setDisableInvoice,
    onItemClick,
    onCreateInvoice,
    updateAppointment,
    setUpdateAppointment,
    updateAppointmentClient,
    archiveAppointment,
    masterAppointments,
    fromDate,
    toDate,
    inCabinet,
}: AppointmentDetailsProps) => (
    <Modal size="large" onClose={onClose} open={open} trigger={children}>
        <Modal.Header>
            <FormattedMessage
                id="pages.appointments.details.AppointmentDetails.title"
                defaultMessage="Appointment"
            />
            {appointment.authorRole && appointment.author && (
                <Subheader
                    title={`${appointment.author.firstName} ${
                        appointment.author.lastName
                    }`}
                >
                    <FormattedMessage
                        id="pages.appointments.details.AppointmentDetails.authorRole"
                        defaultMessage="Created by {role, select, ADMIN {administrator} MASTER {master} CLIENT {client}}."
                        values={{
                            role: appointment.authorRole,
                        }}
                    />
                </Subheader>
            )}
        </Modal.Header>
        <Navigation
            selectedItem={selectedItem}
            onItemClick={onItemClick}
            showInvoice={appointment.invoice}
            disableInvoice={disableInvoice}
        />
        {selectedItem === 'editAppointment' && (
            <EditAppointment
                onClose={onClose}
                appointment={appointment}
                onCreateInvoice={onCreateInvoice}
                updateAppointment={updateAppointment}
                updateAppointmentClient={updateAppointmentClient}
                archiveAppointment={archiveAppointment}
                masterAppointments={masterAppointments}
                fromDate={fromDate}
                toDate={toDate}
                inCabinet={inCabinet}
                setDisableInvoice={setDisableInvoice}
            />
        )}
        {selectedItem === 'invoice' && (
            <AppointmentInvoice
                onClose={onClose}
                salon={salon}
                appointment={appointment}
                masterAppointments={masterAppointments}
                fromDate={fromDate}
                toDate={toDate}
            />
        )}
    </Modal>
);

// Setup state handler to hold active section
const state = withState('selectedItem', 'selectItem', 'editAppointment');

const disableInvoiceState = withState(
    'disableInvoice',
    'setDisableInvoice',
    false,
);

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
    onCreateInvoice: ownProps => () => {
        ownProps.selectItem('invoice');
    },
});

export default compose(
    state,
    disableInvoiceState,
    handlers,
)(AppointmentDetails);
