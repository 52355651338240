import { set, isNil } from 'lodash';
import { defineMessages } from 'react-intl';
import { priceIsNotValid } from '../../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    serviceNameError: {
        id: 'pages.settings.services.add.serviceNameError',
        defaultMessage: 'Enter service name',
    },
    serviceDurationError: {
        id: 'pages.settings.services.add.serviceDurationError',
        defaultMessage: 'Enter duration the services requries',
    },
    servicePriceError: {
        id: 'pages.settings.services.add.servicePriceError',
        defaultMessage: 'Enter service price',
    },
    serviceColorError: {
        id: 'pages.settings.services.add.serviceColorError',
        defaultMessage: 'Select color that visualy identifies service',
    },
});

export default (values, { intl, defaultLanguage }) => {
    const errors = {};

    // Validate service name
    if (!values.nameVariants || !values.nameVariants[defaultLanguage]) {
        set(
            errors,
            `nameVariants[${defaultLanguage}]`,
            intl.formatMessage(intlMessages.serviceNameError),
        );
    }

    // Validate service duration
    if (isNil(values.duration)) {
        set(
            errors,
            'duration',
            intl.formatMessage(intlMessages.serviceDurationError),
        );
    }

    // Validate service price
    if (isNil(values.price) || priceIsNotValid(values.price)) {
        set(
            errors,
            'price',
            intl.formatMessage(intlMessages.servicePriceError),
        );
    }

    // Validate service color
    if (isNil(values.color)) {
        set(
            errors,
            'color',
            intl.formatMessage(intlMessages.serviceColorError),
        );
    }

    return errors;
};
