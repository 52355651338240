/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { remove } from 'lodash';

import DeleteCabinetDialog from '../components/DeleteCabinetDialog';

import { GET_CABINETS_QUERY } from './CabinetList';

// Query that deletes specified cabinet
const DELETE_CABINET_QUERY = gql`
    mutation deleteCabinet($input: DeleteCabinetInput!) {
        deleteCabinet(input: $input) {
            cabinetId
        }
    }
`;

const withData = graphql(DELETE_CABINET_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        id: ownProps.cabinet.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete cabinet before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteCabinet: {
                        __typename: 'DeleteCabinetPayload',
                        cabinetId: ownProps.cabinet.id,
                    },
                },
                // Remove deleted cabinet from query cache
                update: (store, { data: { deleteCabinet } }) => {
                    const data = store.readQuery({
                        query: GET_CABINETS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            lang: ownProps.currentLanguage,
                        },
                    });

                    remove(
                        data.viewer.salon.cabinets,
                        cabinet => cabinet.id === deleteCabinet.cabinetId,
                    );

                    store.writeQuery({
                        query: GET_CABINETS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            lang: ownProps.currentLanguage,
                        },
                        data: data,
                    });
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after cabinet was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when cabinet was not deleted
                });
        },
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
        currentLanguage: intl.get('locale'),
    })),
    withData,
)(DeleteCabinetDialog);
