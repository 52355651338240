import { set } from 'lodash';
import moment from 'moment';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    nameError: {
        id: 'pages.seminars.AddSeminarDialog.nameError',
        defaultMessage: 'Enter seminar name',
    },
    dateError: {
        id: 'pages.seminars.AddSeminarDialog.dateError',
        defaultMessage: 'Invalid date',
    },
    placeCountError: {
        id: 'pages.seminars.AddSeminarDialog.placeCountError',
        defaultMessage: 'Invalid number',
    },
    minPlaceCountError: {
        id: 'pages.seminars.AddSeminarDialog.minPlaceCountError',
        defaultMessage:
            'Place count should not be less than people already attended to seminar',
    },
    invalidTimeError: {
        id: 'pages.seminars.AddSeminarDialog.invalidTimeError',
        defaultMessage: 'Invalid time',
    },
    invalidDurationError: {
        id: 'pages.seminars.AddSeminarDialog.invalidDurationError',
        defaultMessage: 'Invalid duration',
    },
    typeError: {
        id: 'pages.seminars.AddSeminarDialog.typeError',
        defaultMessage: 'Select type',
    },
    bannerError: {
        id: 'pages.seminars.AddSeminarDialog.bannerError',
        defaultMessage: 'Select file',
    },
});

export default (values, { intl, form, seminar }) => {
    const errors = {};

    if (!values.name) {
        set(errors, 'name', intl.formatMessage(intlMessages.nameError));
    }

    if (!values.startAt) {
        set(errors, 'startAt', intl.formatMessage(intlMessages.dateError));
    }

    if (values.eventTime) {
        if (
            values.startAt &&
            !moment(
                moment(values.startAt).format('YYYY-MM-DD ') + values.eventTime,
            ).isValid()
        ) {
            set(
                errors,
                'eventTime',
                intl.formatMessage(intlMessages.invalidTimeError),
            );
        }
    } else {
        set(
            errors,
            'eventTime',
            intl.formatMessage(intlMessages.invalidTimeError),
        );
    }

    if (!values.duration) {
        set(
            errors,
            'duration',
            intl.formatMessage(intlMessages.invalidDurationError),
        );
    }

    if (!values.type) {
        set(errors, 'type', intl.formatMessage(intlMessages.typeError));
    }

    if (!values.placeCount) {
        set(
            errors,
            'placeCount',
            intl.formatMessage(intlMessages.placeCountError),
        );
    } else if (
        seminar &&
        seminar.visitors &&
        seminar.visitors.count &&
        seminar.visitors.count > values.placeCount
    ) {
        set(
            errors,
            'placeCount',
            intl.formatMessage(intlMessages.minPlaceCountError),
        );
    }

    if (!values.banner && form === 'addSeminar') {
        set(errors, 'banner', intl.formatMessage(intlMessages.bannerError));
    }

    return errors;
};
