import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { uniqBy, get } from 'lodash';
import moment from 'moment';

import SellerReportTable from '../../components/seller/SellerReportTable';
import { setAppRecords, setReportFilter } from '../../../../actions/report';
import type { Dispatch } from '../../../type';

const DATE_FORMAT = 'YYYY-MM-DD';

// Query to get report of current salon for provided date range
export const GET_REPORT_QUERY = gql`
    query getReport(
        $salon: ID!
        $fromDate: String!
        $toDate: String!
        $deleted: Boolean!
        $showArchive: Boolean
        $reportFilter: String
        $lang: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    administrator {
                        id
                        ...administrator
                    }
                    administrators(deleted: $deleted) {
                        id
                        ...administrator
                    }
                    masters(deleted: $deleted) {
                        nodes {
                            id
                            ...master
                        }
                    }
                    cabinets(deleted: $deleted) {
                        id
                        ...cabinet
                    }
                }
            }
        }
    }

    fragment master on Master {
        firstName
        lastName
        report(
            fromDate: $fromDate
            toDate: $toDate
            salon: $salon
            showArchive: $showArchive
            reportFilter: $reportFilter
        ) {
            ...report
        }
    }

    fragment cabinet on Cabinet {
        name(lang: $lang)
        report(
            fromDate: $fromDate
            toDate: $toDate
            salon: $salon
            showArchive: $showArchive
            reportFilter: $reportFilter
        ) {
            ...report
        }
    }

    fragment administrator on Administrator {
        firstName
        lastName
        report(
            fromDate: $fromDate
            toDate: $toDate
            salon: $salon
            showArchive: $showArchive
            reportFilter: $reportFilter
        ) {
            ...report
        }
    }

    fragment report on Report {
        invoices {
            id
            invoiceId
            createdAt
            invoiceType
            archived
            appointments {
                id
                client {
                    id
                    firstName
                    lastName
                    phone
                }
                duration
                authorRole
            }
            items {
                id
                price
                quantity
                type
                paymentType
                entity {
                    ... on Product {
                        id
                        name
                    }
                    ... on Service {
                        id
                        name(lang: $lang)
                    }
                }
                seller
            }
        }
    }
`;

const withData = graphql(GET_REPORT_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            fromDate: ownProps.fromDate,
            toDate: ownProps.toDate,
            deleted: true,
            showArchive: ownProps.showArchive,
            reportFilter: ownProps.reportFilter,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => {
        let masters = [];
        let cabinets = [];
        if (!loading && viewer) {
            masters = uniqBy(
                [
                    get(viewer, 'salon.administrator', []),
                    ...get(viewer, 'salon.administrators', []),
                    ...get(viewer, 'salon.masters.nodes', []),
                ],
                'id',
            );
            cabinets = get(viewer, 'salon.cabinets', []);
        }
        return {
            loading,
            masters,
            cabinets,
        };
    },
});

const mapStateToProps = ({ user, report, salon, intl }) => ({
    isOwner: user.get('isOwner'),
    salon: user.get('salon'),
    fromDate: moment(report.get('fromDate')).format(DATE_FORMAT),
    toDate: moment(report.get('toDate')).format(DATE_FORMAT),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    reportFilter: report.get('reportFilter'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(SellerReportTable);
