/**
 * @flow
 */

import styled from 'styled-components';
import { Divider as BaseDivider } from 'semantic-ui-react';

const Divider = styled(BaseDivider)`
    &.divider {
        ${props => props.fluid && 'margin-left: -2em;'}
        ${props => props.fluid && 'margin-right: -2em;'}
    }
`;

export default Divider;
