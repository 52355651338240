/**
 * @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { Modal, Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { ProductForm } from '../form';

type EditProductProps = {
    onClose: Function,
} & ReduxForm;

const EditProduct = ({
    onClose,
    handleSubmit,
    submitting,
    pristine,
    error,
}: EditProductProps) => (
    <Modal.Context>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <ProductForm
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.inventory.details.EditProduct.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.inventory.details.EditProduct.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default EditProduct;
