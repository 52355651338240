import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { compose, branch, renderComponent } from 'recompose';
import { Container, Grid, Menu as BaseMenu, Loader } from 'semantic-ui-react';
import { Divider, PageHeader } from '../../../components';
import CreateMembershipButton from './CreateMembershipButton';
import MembershipList from './MembershipList';

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const MembershipsPage = ({ memberships }) => (
    <Container fluid>
        <Menu secondary>
            <BaseMenu.Item header>
                <PageHeader>
                    <FormattedMessage
                        id="navbar.menu.memberships"
                        defaultMessage="Memberships"
                    />
                </PageHeader>
            </BaseMenu.Item>
            <BaseMenu.Menu position="right">
                <BaseMenu.Item>
                    <CreateMembershipButton memberships={memberships} />
                </BaseMenu.Item>
            </BaseMenu.Menu>
        </Menu>
        <Divider />
        <Grid columns={1}>
            <Grid.Column>
                <MembershipList memberships={memberships} />
            </Grid.Column>
        </Grid>
    </Container>
);

// Show spinner while cabinet list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(MembershipsPage);
