/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { find, remove } from 'lodash';
import moment from 'moment';
import DeleteWorkingTimeDialog from '../../components/schedule/DeleteWorkingTimeDialog';

import { GET_SALON_QUERY } from './timetable/Timetable';

// Mutation that deletes specified working time
const DELETE_WORKING_TIME_MUTATION = gql`
    mutation deleteWorkingTime($input: DeleteWorkingTimeInput!) {
        deleteWorkingTime(input: $input) {
            workingTimeId
            deleted
        }
    }
`;

const withMutation = graphql(DELETE_WORKING_TIME_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        workingTimeId: ownProps.workingTime.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete working time before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteWorkingTime: {
                        __typename: 'DeleteWorkingTimePayload',
                        workingTimeId: ownProps.workingTime.id,
                    },
                },
                // Remove deleted working time from query cache
                update: (
                    store,
                    {
                        data: {
                            deleteWorkingTime: { deleted },
                        },
                    },
                ) => {
                    ownProps.setIsDeletable(deleted);
                    if (deleted) {
                        try {
                            const data = store.readQuery({
                                query: GET_SALON_QUERY,
                                variables: {
                                    salon: ownProps.salon,
                                    showArchive: ownProps.showArchive,
                                    date: moment(ownProps.date).format(
                                        'YYYY-MM-DD',
                                    ),
                                    lang: ownProps.currentLanguage,
                                },
                            });

                            // Find master of the working time
                            const master = find(
                                data.viewer.salon.masters.nodes,
                                i => i.id === ownProps.master.id,
                            );

                            // And remove working time that we deleted
                            remove(
                                master.schedule,
                                workingTime =>
                                    workingTime.id === ownProps.workingTime.id,
                            );

                            store.writeQuery({
                                query: GET_SALON_QUERY,
                                variables: {
                                    salon: ownProps.salon,
                                    showArchive: ownProps.showArchive,
                                    date: moment(ownProps.date).format(
                                        'YYYY-MM-DD',
                                    ),
                                    lang: ownProps.currentLanguage,
                                },
                                data,
                            });
                        } catch (e) {
                            // It's safe to ignore this exception
                            console.log(e);
                        }
                    }
                },
            });

            return mutation
                .then(({ data: { deleteWorkingTime: { deleted } } }) => {
                    if (deleted) {
                        // Close modal dialog after working time was deleted
                        ownProps.onClose();
                    }
                })
                .catch(error => {
                    // TODO: Do something with an error when working time was not deleted
                    console.log(error);
                });
        },
    }),
});

const withDelete = withState('isDeletable', 'setIsDeletable', true);

export default compose(
    connect(({ user, schedule, salon, intl }) => ({
        salon: user.get('salon'),
        date: schedule.get('date'),
        showArchive: salon.get('showArchive'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withDelete,
    withMutation,
)(DeleteWorkingTimeDialog);
