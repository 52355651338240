/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { map, isEmpty } from 'lodash';
import { Segment, List, Message, Loader } from 'semantic-ui-react';
import { Table, Color } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import EditServiceGroupButton from './group/EditServiceGroupButton';
import AddServiceButton from './service/AddServiceButton';
import DeleteServiceButton from './service/DeleteServiceButton';
import EditServiceButton from './service/EditServiceButton';

import type {
    Service,
    ServiceGroup as ServiceGroupType,
} from '../../../../type';

type ServiceGroupProps = {
    serviceGroup: ServiceGroupType,
};

type ServiceItemProps = {
    serviceGroup: ServiceGroupType,
    service: Service,
};

type ServicesListProps = {
    serviceGroups: Array<ServiceGroupType>,
};

const GroupName = styled.span`
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
`;

const CenteredMessage = styled(Message)`
    text-align: center;
`;

/**
 * Represents single service item
 */
const ServiceItem = ({ serviceGroup, service }: ServiceItemProps) => (
    <Table.Row>
        <Table.Cell>
            <Color size={16} spaced="right" color={service.color} />
            {service.name}
        </Table.Cell>
        <Table.Cell>
            {service.duration}{' '}
            <FormattedMessage
                id="pages.settings.services.servicesList.minutes"
                defaultMessage="minutes"
            />
        </Table.Cell>
        <Table.Cell textAlign="right">
            € {parseFloat(service.price).toFixed(2)}
        </Table.Cell>
        <Table.Cell textAlign="right">
            <List horizontal divided>
                <List.Item>
                    <EditServiceButton
                        serviceGroup={serviceGroup}
                        service={service}
                    />
                </List.Item>
                <List.Item>
                    <DeleteServiceButton
                        serviceGroup={serviceGroup}
                        service={service}
                    />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

/**
 * Show this message if services list is empty
 */
const NoServicesMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.settings.services.servicesList.groupNoServices"
                defaultMessage="This group does not have any services"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents group of services and it's service items
 */
const ServiceGroup = ({ serviceGroup }: ServiceGroupProps) => (
    <Segment.Group>
        <Segment attached="top" secondary clearing>
            <GroupName>{serviceGroup.name}</GroupName>
            <EditServiceGroupButton serviceGroup={serviceGroup} />
            <AddServiceButton serviceGroup={serviceGroup} />
        </Segment>
        <Segment attached="bottom">
            <Table fixed celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.settings.services.servicesList.name"
                                defaultMessage="Name"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.settings.services.servicesList.duration"
                                defaultMessage="Duration"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                            <FormattedMessage
                                id="pages.settings.services.servicesList.price"
                                defaultMessage="Price"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                            <FormattedMessage
                                id="pages.settings.services.servicesList.actions"
                                defaultMessage="Actions"
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isEmpty(serviceGroup.services) ? (
                        <NoServicesMessage />
                    ) : (
                        map(serviceGroup.services, service => (
                            <ServiceItem
                                key={service.id}
                                serviceGroup={serviceGroup}
                                service={service}
                            />
                        ))
                    )}
                </Table.Body>
            </Table>
        </Segment>
    </Segment.Group>
);

/**
 * Presents list of service groups
 */
const ServicesList = ({ serviceGroups }: ServicesListProps) => (
    <div>
        {map(serviceGroups, serviceGroup => (
            <ServiceGroup key={serviceGroup.id} serviceGroup={serviceGroup} />
        ))}
    </div>
);

// Show spinner while loading list of service groups
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="large" inline="centered" active />),
);

// Show this message if list of service groups is empty
const withNoServiceGroupsMessage = branch(
    props => isEmpty(props.serviceGroups),
    renderComponent(() => (
        <CenteredMessage>
            <FormattedMessage
                id="pages.settings.services.servicesList.noServices"
                defaultMessage="You don't have any services yet"
            />
        </CenteredMessage>
    )),
);

export default compose(
    withSpinner,
    withNoServiceGroupsMessage,
)(ServicesList);
