/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import withModal from '../../../lib/withModal';
import SupplierDetails from './details/SupplierDetails';

import type { ModalDialogTrigger, Supplier } from '../../../type';

type EditSupplierButtonProps = {
    supplier: Supplier,
} & ModalDialogTrigger;

const EditSupplierButton = ({
    supplier,
    open,
    onOpen,
    onClose,
}: EditSupplierButtonProps) => (
    <SupplierDetails
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.suppliers.editSupplier.button.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
        supplier={supplier}
        onClose={onClose}
        open={open}
    />
);

export default withModal(EditSupplierButton);
