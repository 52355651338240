import { Map } from 'immutable';

const initialState = new Map({
    searchQuery: '',
});

export default (state = initialState, action) => {
    switch (action.type) {
        // Save value of search query
        case 'CLIENTS.SEARCH': {
            return state.set('searchQuery', action.query);
        }
        default: {
            return state;
        }
    }
};
