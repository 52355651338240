/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { each, remove } from 'lodash';
import moment from 'moment';

import RemoveInvoiceDialog from '../components/RemoveInvoiceDialog';
import { GET_REPORT_QUERY } from './seller/SellerReportTable';

const DATE_FORMAT = 'YYYY-MM-DD';

const DELETE_INVOICE_MUTATION = gql`
    mutation deleteInvoice($input: DeleteInvoiceInput!) {
        deleteInvoice(input: $input) {
            invoiceId
        }
    }
`;

const withData = graphql(DELETE_INVOICE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        userId: ownProps.user,
                        invoiceId: ownProps.invoiceId,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteInvoice: {
                        __typename: 'DeleteInvoicePayload',
                        invoiceId: ownProps.invoiceId,
                    },
                },
                update: (store, { data: { deleteInvoice } }) => {
                    try {
                        const data = store.readQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                        });

                        const removeFn = invoice =>
                            invoice.invoiceId === deleteInvoice.invoiceId;

                        remove(
                            data.viewer.salon.administrator.report.invoices,
                            removeFn,
                        );

                        each(
                            data.viewer.salon.administrators,
                            administrator => {
                                remove(administrator.report.invoices, removeFn);
                            },
                        );

                        each(data.viewer.salon.masters.nodes, master => {
                            remove(master.report.invoices, removeFn);
                        });

                        store.writeQuery({
                            query: GET_REPORT_QUERY,
                            variables: {
                                salon: ownProps.salon,
                                fromDate: ownProps.fromDate,
                                toDate: ownProps.toDate,
                                deleted: true,
                                showArchive: ownProps.showArchive,
                                reportFilter: ownProps.reportFilter,
                                lang: ownProps.currentLanguage,
                            },
                            data,
                        });
                    } catch (e) {
                        // It's safe to ignore this exception
                    }
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after appointment was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when appointment was not deleted
                });
        },
    }),
});

const mapStateToProps = ({ user, report, salon, intl }) => ({
    reportFilter: report.get('reportFilter'),
    user: user.get('id'),
    salon: user.get('salon'),
    fromDate: moment(report.get('fromDate')).format(DATE_FORMAT),
    toDate: moment(report.get('toDate')).format(DATE_FORMAT),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(RemoveInvoiceDialog);
