/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    compose,
    branch,
    renderComponent,
    mapProps,
    withHandlers,
} from 'recompose';
import { FormattedMessage, defineMessages } from 'react-intl';
import {
    Form as BaseForm,
    Label,
    Input,
    Icon,
    Loader,
    Message,
    Container,
    Button,
    Header,
    Menu,
} from 'semantic-ui-react';

import { Divider, PageHeader, Toggle } from '../../../components';
import DateRange from '../../../components/DateRange';

const intlMessages = defineMessages({
    salonNamePlaceholder: {
        id: 'pages.settings.generalSettings.salonName.placeholder',
        defaultMessage: 'Salon name',
    },
    salonAddressPlaceholder: {
        id: 'pages.settings.generalSettings.salonAddress.placeholder',
        defaultMessage: 'Salon address',
    },
    startAtPlaceholder: {
        id: 'pages.settings.generalSettings.startAt.placeholder',
        defaultMessage: 'Start At',
    },
    endAtPlaceholder: {
        id: 'pages.settings.generalSettings.endAt.placeholder',
        defaultMessage: 'End At',
    },
    stepPlaceholder: {
        id: 'pages.settings.generalSettings.step.placeholder',
        defaultMessage: 'Step',
    },
    formatPlaceholder: {
        id: 'pages.settings.generalSettings.format.placeholder',
        defaultMessage: 'Format',
    },
    enableArchivePlaceholder: {
        id: 'pages.settings.generalSettings.enableArchive.placeholder',
        defaultMessage: 'Enable Archive For Common Administrators',
    },
});

const Form = styled(BaseForm)`
    max-width: 200px;
`;

const OwnerSettingsPage = ({
    fromDate,
    toDate,
    setDate,
    verificationWord,
    setVerificationWord,
    onArchive,
    onArchiveEnable,
    archiveToggle,
    onReportEnable,
    reportToggle,
    onHideClientPhone,
    hidePhoneToggle,
    onAllowMasterLogin,
    masterLoginToggle,
}) => {
    return (
        <Container fluid>
            <PageHeader>
                <FormattedMessage
                    id="pages.settings.generalSettings"
                    defaultMessage="General Settings"
                />
            </PageHeader>
            <Divider />
            <Menu compact>
                <Menu.Item as="a">
                    <Toggle
                        onChange={e => {
                            onArchiveEnable(e);
                        }}
                        value={archiveToggle}
                    />
                </Menu.Item>
                <Menu.Item as="a">
                    <FormattedMessage
                        id="pages.settings.generalSettings.enableArchive"
                        defaultMessage="Enable archive for common administrators"
                    />
                </Menu.Item>
            </Menu>

            <br />
            <br />

            <Menu compact>
                <Menu.Item>
                    <Toggle
                        onChange={e => {
                            onHideClientPhone(e);
                        }}
                        value={hidePhoneToggle}
                    />
                </Menu.Item>
                <Menu.Item>
                    <FormattedMessage
                        id="pages.settings.generalSettings.hideClientPhone"
                        defaultMessage="Don't show client phone number for masters"
                    />
                </Menu.Item>
            </Menu>

            <br />
            <br />

            <Menu compact>
                <Menu.Item>
                    <Toggle
                        onChange={e => {
                            onAllowMasterLogin(e);
                        }}
                        value={masterLoginToggle}
                    />
                </Menu.Item>
                <Menu.Item>
                    <FormattedMessage
                        id="pages.settings.generalSettings.masterPanelLogin"
                        defaultMessage="Allow masters to login into administrator panel"
                    />
                </Menu.Item>
            </Menu>

            <Divider />

            <PageHeader>
                <FormattedMessage
                    id="pages.settings.generalSettings.reports"
                    defaultMessage="Reports"
                />
            </PageHeader>
            <Menu compact>
                <Menu.Item as="report">
                    <Toggle
                        onChange={e => {
                            onReportEnable(e);
                        }}
                        value={reportToggle}
                    />
                </Menu.Item>
                <Menu.Item as="report">
                    <FormattedMessage
                        id="pages.settings.generalSettings.enableReportByType"
                        defaultMessage="Split the amount by type"
                    />
                </Menu.Item>
            </Menu>

            <Header as="h2">
                <FormattedMessage
                    id="pages.settings.generalSettings.deleteAppointments"
                    defaultMessage="Delete appointments"
                />
            </Header>
            <DateRange
                fromDate={fromDate}
                toDate={toDate}
                onChange={setDate}
                customFormat="MMM D YYYY"
                customSize
                disabledAfter={new Date()}
            />
            <br />
            <br />
            <div>
                <FormattedMessage
                    id="pages.settings.generalSettings.delete"
                    defaultMessage='Please enter "Delete" in the field if you want to delete the history.'
                />
                <br />
                <br />
                <div>
                    <Input
                        labelPosition="right"
                        type="text"
                        placeholder="Delete"
                        onChange={(v, { value }) => {
                            setVerificationWord(value);
                        }}
                    >
                        <input />
                        <Label color="red">
                            <Button
                                onClick={onArchive}
                                color="red"
                                disabled={verificationWord != 'Delete'}
                            >
                                <FormattedMessage
                                    id="pages.settings.generalSettings.deleteAppointments"
                                    defaultMessage="Delete appointments"
                                />
                            </Button>
                        </Label>
                    </Input>
                </div>
            </div>
        </Container>
    );
};

export default OwnerSettingsPage;
