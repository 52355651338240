import React from 'react';
import {
    Select
} from 'semantic-ui-react';

const ManufacturerFilterSelect = ({
    options,
    placeholder,
    onChange,
    multiple
}) => (
        <Select options={options} placeholder={placeholder} onChange={onChange} multiple={multiple} />
    );

export default ManufacturerFilterSelect;
