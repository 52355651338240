/**
 * Represents button that opens dialog with confirmation
 * request to delete specified service group.
 *
 * @flow
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../../../lib/withModal';
import DeleteServiceGroupDialog from '../../../containers/service/group/DeleteServiceGroupDialog';

import type { ServiceGroup, ModalDialogTrigger } from '../../../../../type';

type DeleteServiceGroupProps = {
    serviceGroup: ServiceGroup,
} & ModalDialogTrigger;

const DeleteServiceGroupButton = ({
    open,
    onOpen,
    onClose,
    serviceGroup,
}: DeleteServiceGroupProps) => (
    <DeleteServiceGroupDialog
        trigger={
            <Button onClick={onOpen} color="red" floated="right">
                <FormattedMessage
                    id="pages.settings.services.groupForm.buttons.delete"
                    defaultMessage="Delete"
                />
            </Button>
        }
        serviceGroup={serviceGroup}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteServiceGroupButton);
