/**
 * @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Button } from '../../../components';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';
import CreateAppointmentDialog from '../containers/CreateAppointmentDialog';

import type { ModalDialogTrigger } from '../../../type';

type CreateAppointmentButtonProps = {
    master: string,
    disabled: boolean,
} & ModalDialogTrigger;

const CreateAppointmentButton = ({
    open,
    onClose,
    onOpen,
    master,
    disabled,
    inCabinet,
}: CreateAppointmentButtonProps) => (
    <CreateAppointmentDialog
        id={master}
        open={open}
        onClose={onClose}
        trigger={
            <Button
                onClick={onOpen}
                labelPosition="left"
                primary
                icon
                disabled={disabled}
            >
                <Icon name="add" />
                <FormattedMessage
                    id="pages.schedule.CreateAppointmentButton.create"
                    defaultMessage="New Appointment"
                />
            </Button>
        }
        inCabinet={inCabinet}
    />
);

export default withModal(CreateAppointmentButton);
