/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty } from 'lodash';
import { List, Loader } from 'semantic-ui-react';
import { Table, Avatar } from '../../../components';
import { FormattedMessage } from 'react-intl';

import AdministratorDetailsButton from './AdministratorDetailsButton';
import EditAdministratorButton from './EditAdministratorButton';
import DeleteAdministratorButton from './DeleteAdministratorButton';

import type { Administrator } from '../../../type';

type AdministratorsListProps = {
    administrators: Array<Administrator>,
};

type AdministratorRowProps = {
    administrator: Administrator,
};

const NameCell = styled(Table.Cell)`
    padding: 0px !important;
`;

/**
 * Show this message if list of administrators is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={2} textAlign="center">
            <FormattedMessage
                id="pages.administrators.administratorsList.noAdministrators"
                defaultMessage="You don't have administrators yet"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents component with single row of administrator details
 */
const AdministratorRow = ({ administrator }: AdministratorRowProps) => (
    <Table.Row>
        <NameCell>
            <AdministratorDetailsButton administrator={administrator}>
                <Avatar
                    name={`${administrator.firstName} ${
                        administrator.lastName
                    }`}
                    size={24}
                    spaced="right"
                    round
                />
                <span>
                    {administrator.firstName} {administrator.lastName}
                </span>
            </AdministratorDetailsButton>
        </NameCell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditAdministratorButton administrator={administrator} />
                </List.Item>
                <List.Item>
                    <DeleteAdministratorButton administrator={administrator} />
                </List.Item>
            </List>
        </Table.Cell>
    </Table.Row>
);

/**
 * Represents table with list of administrators
 */
const AdministratorsList = ({ administrators }: AdministratorsListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.administrators.administratorsList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.administrators.administratorsList.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(administrators) ? (
                <EmptyMessage />
            ) : (
                administrators.map(administrator => (
                    <AdministratorRow
                        key={administrator.id}
                        administrator={administrator}
                    />
                ))
            )}
        </Table.Body>
    </Table>
);

// Show spinner while administrators list is loading
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(AdministratorsList);
