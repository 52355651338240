/**
 * Represents button that opens dialog with confirmation
 * request to delete specified product.
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import DeleteProductDialog from '../containers/DeleteProductDialog';
import { FormattedMessage } from 'react-intl';
import type { Product, ModalDialogTrigger } from '../../../type';

type DeleteProductButtonProps = {
    product: Product,
} & ModalDialogTrigger;

const DeleteProductButton = ({
    product,
    open,
    onOpen,
    onClose,
}: DeleteProductButtonProps) => (
    <DeleteProductDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.inventory.DeleteProductButton.label"
                    defaultMessage="Delete"
                />
            </a>
        }
        product={product}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteProductButton);
