/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';

import { setMaster } from '../../../../../actions/master-appointments';
import Timetable from '../../../components/schedule/timetable/Timetable';

// The query gets salon details
export const GET_SALON_QUERY = gql`
    query getSalon($date: String!, $salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    masters {
                        nodes {
                            id
                            ...master
                        }
                    }
                }
            }
        }
    }

    fragment master on Master {
        firstName
        lastName
        phone
        email
        status
        type
        serviceGroups {
            id
            name(lang: $lang)
            type
        }
        schedule(startDate: $date) {
            id
            startAt
            endAt
        }
    }
`;

const withData = graphql(GET_SALON_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            date: moment(ownProps.date).format('YYYY-MM-DD'),
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        salon: get(viewer, 'salon'),
    }),
});

const mapStateToProps = ({ schedule, user, intl }) => ({
    date: schedule.get('date'),
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

const mapDipatchToProps = {
    setMaster,
};

const handlers = withHandlers({
    onHeaderClick: ({ setMaster, history }) => master => {
        setMaster(master);
        history.push('/salon/master-appointments');
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDipatchToProps,
    ),
    withRouter,
    handlers,
    withData,
)(Timetable);
