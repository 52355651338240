/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { compose, branch, renderComponent, toClass } from 'recompose';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';

import { Table, Avatar } from '../../../components';
import SwitchSalonButton from '../containers/SwitchSalonButton';

import type { Salon } from '../../../type';

type SalonListProps = {
    salons: Array<Salon>,
    loading: boolean,
};

type SalonRowProps = {
    salon: Salon,
};

const NameCell = styled(Table.Cell)`
    .ui.table td& {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.salonList.noSalons"
                defaultMessage="No salon found"
            />
        </Table.Cell>
    </Table.Row>
);

const OwnerCell = ({ owner }) => (
    <Table.Cell>
        {[
            `${owner.firstName} ${owner.lastName} `,
            '(',
            owner.phone ? `${owner.phone}, ` : '',
            owner.email,
            ')',
        ].join('')}
    </Table.Cell>
);

const SalonRow = ({ salon }: SalonRowProps) => (
    <Table.Row>
        <NameCell>
            <Avatar name={salon.name} size={24} spaced="right" round />{' '}
            {salon.name}
        </NameCell>
        <OwnerCell owner={salon.administrator} />
        <Table.Cell>{salon.masterCount}</Table.Cell>
        <Table.Cell>
            <SwitchSalonButton
                salon={salon.id}
                salonName={salon.name}
                primary
                compact
            >
                <FormattedMessage
                    id="pages.salonList.swithSalon"
                    defaultMessage="Login"
                />
            </SwitchSalonButton>
        </Table.Cell>
    </Table.Row>
);

const SalonList = ({ salons }: SalonListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.salonList.column.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.salonList.column.owner"
                        defaultMessage="Owner"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.salonList.column.masters"
                        defaultMessage="Masters"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.salonList.column.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(salons) ? (
                <EmptyMessage />
            ) : (
                salons.map(salon => <SalonRow key={salon.id} salon={salon} />)
            )}
        </Table.Body>
    </Table>
);

const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(
    withSpinner,
    toClass,
)(SalonList);
