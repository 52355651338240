import React from 'react';
import styled from 'styled-components';
import { get, map } from 'lodash';
import moment from 'moment';
import { Popup, Table, Header, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';
import { compose, withHandlers } from 'recompose';

import ClientDetailsButton from '../../../clients/components/ClientDetailsButton';
import { EllipsisLoader } from '../../../../components';

import type { Client } from '../../../../type';

// Delay to wait before showing popup
const POPUP_DELAY = 0;

const LINE_HEIGHT = 16;

type AppointmentCardPopupProps = {
    trigger: Function,
    client: Client,
};

const intlMessages = defineMessages({
    walkinClient: {
        id: 'pages.appointments.details.AppointmentDetails.walkinClient',
        defaultMessage: 'Walk-in Client',
    },
});

const PhoneIcon = styled(Icon)`
    padding-right: 5px !important;
`;

const SubHeader = styled(Header.Subheader)`
    margin-top: 3px !important;
`;

const GenderIcon = styled(Icon)`
    font-size: 1em !important;
    padding-left: 5px;
`;

const AppointmentDetailsButtonStyled = styled.span`
    cursor: pointer;
    color: #000000;
    transition: color 0.3s;
    height: 44px;
    padding: 0px 11px 0px 11px;
`;

/**
 * Returns correct gender icon based on provided gender
 */
const Gender = ({ gender }) => {
    switch (gender) {
        case 'MALE': {
            return <GenderIcon name="mars" color="blue" />;
        }
        case 'FEMALE': {
            return <GenderIcon name="venus" color="pink" />;
        }
        default:
            return null;
    }
};

const ClientDetails = ({
    loading,
    client,
    master,
    author,
    cabinet,
    onOpenClientDialog,
    onClosePopup,
    inCabinet,
    isMaster,
}) => {
    if (loading) {
        return <EllipsisLoader />;
    }
    const lastAppointment = get(client, 'stats.lastAppointment');
    return (
        <Table basic="very" celled collapsing compact="very">
            <Table.Body>
                <Table.Row positive>
                    <Table.Cell>
                        <FormattedMessage
                            id="pages.appointments.timetable.AppointmentCardPopup.authorName"
                            defaultMessage="Created by"
                        />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        {`${author.firstName} ${author.lastName}`}
                    </Table.Cell>
                </Table.Row>
                {inCabinet
                    ? !!master && (
                          <Table.Row>
                              <Table.Cell>
                                  <FormattedMessage
                                      id="pages.appointments.timetable.AppointmentCardPopup.master"
                                      defaultMessage="Master"
                                  />
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                  {`${master.firstName} ${master.lastName}`}
                              </Table.Cell>
                          </Table.Row>
                      )
                    : !!cabinet && (
                          <Table.Row>
                              <Table.Cell>
                                  <FormattedMessage
                                      id="pages.appointments.timetable.AppointmentCardPopup.cabinet"
                                      defaultMessage="Cabinet"
                                  />
                              </Table.Cell>
                              <Table.Cell textAlign="right">
                                  {cabinet.name}
                              </Table.Cell>
                          </Table.Row>
                      )}
                {lastAppointment &&
                    lastAppointment.service && [
                        <Table.Row key={0}>
                            <Table.Cell>
                                <FormattedMessage
                                    id="pages.appointments.timetable.AppointmentCardPopup.lastAppointment"
                                    defaultMessage="Last appointment"
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                {moment(lastAppointment.startAt).format(
                                    'DD.MM.YYYY',
                                )}
                            </Table.Cell>
                        </Table.Row>,
                        <Table.Row key={1}>
                            <Table.Cell>
                                <FormattedMessage
                                    id="pages.appointments.timetable.AppointmentCardPopup.lastService"
                                    defaultMessage="Last service"
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                {lastAppointment.service.name}
                            </Table.Cell>
                        </Table.Row>,
                    ]}
                <Table.Row>
                    <Table.Cell>
                        <FormattedMessage
                            id="pages.appointments.timetable.AppointmentCardPopup.lastNotes"
                            defaultMessage="Notes"
                        />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        {lastAppointment ? lastAppointment.notes : client.notes}
                    </Table.Cell>
                </Table.Row>
                {lastAppointment &&
                    lastAppointment.service &&
                    lastAppointment.invoice && [
                        <Table.Row>
                            <Table.Cell>
                                <FormattedMessage
                                    id="pages.appointments.timetable.AppointmentCardPopup.lastPrice"
                                    defaultMessage="Price"
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                {lastAppointment.invoice.totalPrice.toFixed(2)}
                                &euro;
                            </Table.Cell>
                        </Table.Row>,
                    ]}
                {map(client.boughtItems.edges, (item, idx) => (
                    <Table.Row key={idx + 2}>
                        <Table.Cell>
                            {!idx && (
                                <FormattedMessage
                                    id="pages.appointments.timetable.AppointmentCardPopup.lastProducts"
                                    defaultMessage="Products"
                                />
                            )}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            {item.entity.name}
                        </Table.Cell>
                    </Table.Row>
                ))}
                {!!lastAppointment && !isMaster && (
                    <Table.Row warning>
                        <Table.Cell textAlign="center" colSpan={2}>
                            <AppointmentDetailsButtonStyled
                                onClick={() => {
                                    onOpenClientDialog();
                                    onClosePopup();
                                }}
                            >
                                <FormattedMessage
                                    id="pages.appointments.timetable.AppointmentCardPopup.appointmentLink"
                                    defaultMessage="All appointments"
                                />
                            </AppointmentDetailsButtonStyled>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};

const AppointmentCardPopup = ({
    intl,
    loading,
    client,
    master,
    author,
    cabinet,
    open,
    onOpen,
    onClose,
    children,
    cardHeight,
    onOpenClientDialog,
    inCabinet,
    isMaster,
}: AppointmentCardPopupProps) => {
    if (!client) {
        return children;
    }

    return (
        <Popup
            trigger={React.cloneElement(children, { onClose })}
            onOpen={onOpen}
            onClose={onClose}
            open={open}
            verticalOffset={-(cardHeight / 2) + LINE_HEIGHT}
            position="top center"
            mouseEnterDelay={POPUP_DELAY}
            flowing
            hoverable
        >
            <Popup.Header style={{ display: 'flex', flexDirection: 'row' }}>
                <Header>
                    <Header.Content>
                        {client.firstName
                            ? client.firstName + ' ' + client.lastName
                            : intl.formatMessage(intlMessages.walkinClient)}

                        {client.firstName && <Gender gender={client.gender} />}
                    </Header.Content>
                    {client.phone && (
                        <SubHeader>
                            <PhoneIcon name="phone" fitted />
                            {client.phone}
                        </SubHeader>
                    )}
                </Header>
            </Popup.Header>
            <Popup.Content>
                {open && (
                    <ClientDetails
                        client={client}
                        author={author}
                        cabinet={cabinet}
                        master={master}
                        loading={loading}
                        onOpenClientDialog={onOpenClientDialog}
                        onClosePopup={onClose}
                        inCabinet={inCabinet}
                        isMaster={isMaster}
                    />
                )}
            </Popup.Content>
        </Popup>
    );
};

export default injectIntl(AppointmentCardPopup);
