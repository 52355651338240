/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import moment from 'moment';

import { Cell } from '../../../../components/timetable';
import {
    AppointmentCardContainer,
    AppointmentCell,
} from '../../../appointments';

import type { ScheduleSettings, Salon, Master } from '../../../../type';
import type { TimetableDay } from '../AppointmentTable';

type AppointmentColumnProps = {
    schedule: ScheduleSettings,
    cellWidth: number,
    connectDropTarget: Function,
    master: Master,
    day: TimetableDay,
    salon: Salon,
    timeSpans: Array<Number>,
    allowToAddAppointments: Function,
    locale: string,
    updateAppointmentClient: Function,
};

const ColumnWrapper = styled.div`
    border-right: 1px solid #e9e9e9;
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ColumnHeader = styled(Cell)`
    padding: 0px 14px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    text-align: center;
    text-transform: capitalize;

    &:hover {
        color: #35a3d5;
        background: #eef8fc;
    }
`;

const ColumnContent = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    & .cell:last-child {
        border-bottom: 0px !important;
    }
`;

const AppointmentColumn = ({
    connectDropTarget,
    cellWidth,
    schedule,
    master,
    day,
    salon,
    timeSpans,
    allowToAddAppointments,
    locale,
    updateAppointmentClient,
    fromDate,
    toDate,
}: AppointmentColumnProps) =>
    connectDropTarget(
        <div style={{ flex: 1 }}>
            <ColumnWrapper>
                <ColumnHeader>
                    {moment(day.date)
                        .locale(locale)
                        .format('dddd, D MMM')}
                </ColumnHeader>
                <ColumnContent>
                    {timeSpans.map(time => (
                        <AppointmentCell
                            key={time}
                            salon={salon}
                            date={day.date}
                            time={time}
                            schedule={schedule}
                            provider={{
                                id: master.id,
                                schedule: day.schedule,
                            }}
                            allowToAddAppointments={allowToAddAppointments(
                                day.date,
                            )}
                            masterAppointments={true}
                            fromDate={fromDate}
                            toDate={toDate}
                        />
                    ))}
                    <AppointmentCardContainer
                        appointments={uniqBy(day.appointments, 'id')}
                        salon={salon}
                        updateAppointmentClient={updateAppointmentClient}
                        masterAppointments={true}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </ColumnContent>
            </ColumnWrapper>
        </div>,
    );

const mapStateToProps = ({ intl }) => ({ locale: intl.get('locale') });

export default connect(mapStateToProps)(AppointmentColumn);
