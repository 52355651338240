/**
 * @flow
 */

import styled from 'styled-components';
import { Header as BaseHeader } from 'semantic-ui-react';

const Header = styled(BaseHeader)`
    &.ui.header {
        font-family: inherit;
    }
`;

const Subheader = styled(BaseHeader.Subheader)`
    &.ui.sub.header {
        font-family: inherit;
    }
`;

Header.Subheader = Subheader;

export default Header;
