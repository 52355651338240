/**
 * @flow
 */

import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';

import SupplierList from '../components/SupplierList';
import { GET_SUPPLIERS_QUERY } from './queries';

const withData = graphql(GET_SUPPLIERS_QUERY, {
    options: ownProps => ({
        variables: {
            search: ownProps.searchQuery || undefined,
            first: 20,
            resolveRole: 'SYSADMIN',
        },
    }),
    props: ({ data: { viewer, loading, fetchMore } }) => ({
        loading,
        fetchMore,
        suppliers: get(viewer, 'suppliers.edges', []),
        pageInfo: get(viewer, 'suppliers.pageInfo', {}),
    }),
});

const mapStateToProps = ({ suppliers }) => ({
    searchQuery: suppliers.get('searchQuery'),
});

const handlers = withHandlers({
    loadMore: ({ loading, searchQuery, pageInfo, fetchMore }) => () => {
        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_SUPPLIERS_QUERY,
            variables: {
                first: 20,
                after: pageInfo.endCursor,
                search: searchQuery || undefined,
                resolveRole: 'SYSADMIN',
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.suppliers.edges;
                const newEdges = fetchMoreResult.viewer.suppliers.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.suppliers.edges = [...prevEdges, ...newEdges];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(SupplierList);
