/**
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { Select } from 'semantic-ui-react';
import { range, get } from 'lodash';
import type { Dispatch } from '../../../../../type';

const mapStateToProps = ({ invoice }, { name }) => {
    const product = get(
        invoice.get('selectedProducts'),
        name.replace('quantity', 'entity'),
    );
    let max = 100;
    if (product) {
        max = product.quantity;
    }
    return {
        max,
    };
};

const props = withProps(({ max }) => ({
    // since range doesn't include end, do max + 1
    options: range(1, max + 1).map(item => ({
        value: item,
        text: item,
    })),
}));

type QuantityFieldProps = {
    meta: Object,
    dispatch: Dispatch,
};

const QuantityField = ({ meta, dispatch, ...props }: QuantityFieldProps) => (
    <Select {...props} />
);

export default compose(
    connect(mapStateToProps),
    props,
)(QuantityField);
