/**
 *  @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { Modal, Button } from '../../../../components';
import { FormattedMessage } from 'react-intl';

import CartForm from './CartForm';

import type { ModalDialog, ReduxForm } from '../../../../type';

type CartDialogProps = {
    paid: boolean,
    form: String,
    change: Function,
} & ModalDialog &
    ReduxForm;

const PaidMessage = () => (
    <Message info>
        <Icon name="info" />
        <FormattedMessage
            id="pages.appointments.cart.message.paid"
            defaultMessage="Submit success"
        />
    </Message>
);

const ErrorMessage = ({ error }) => (
    <Message error>
        <Icon name="warning sign" />
        {error}
    </Message>
);

const CartDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    error,
    paid,
    form,
    change,
    onArchive,
    enableArchive,
    isOwner,
    isSysadmin,
}: CartDialogProps) => (
    <Modal size="small" trigger={trigger} onClose={onClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.appointments.cart.form.header"
                defaultMessage="Sale"
            />
        </Modal.Header>
        <Modal.Content>
            {error && <ErrorMessage error={error} />}
            {paid && <PaidMessage />}
            <CartForm
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                paid={paid}
                form={form}
                change={change}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting || paid}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.appointments.cart.button.submit"
                    defaultMessage="Submit"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.appointments.cart.button.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
            {(enableArchive || isSysadmin || isOwner) && paid && (
                <Button
                    onClick={onArchive}
                    disabled={!paid}
                    color="orange"
                    floated="right"
                >
                    <Icon name="archive" />
                    <FormattedMessage
                        id="pages.appointments.details.ArchiveButton.archive"
                        defaultMessage="Archive"
                    />
                </Button>
            )}
        </Modal.Actions>
    </Modal>
);

export default CartDialog;
