import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ClientsList from '../components/ClientsList';

// Query for list of clients for current salon
export const GET_CLIENTS_QUERY = gql`
    query getClients(
        $salon: ID!
        $search: String
        $first: Int
        $after: String
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    clients(search: $search, first: $first, after: $after) {
                        pageInfo {
                            endCursor
                            hasNextPage
                        }
                        edges {
                            cursor
                            node {
                                id
                                ...client
                            }
                        }
                    }
                }
            }
        }
    }

    fragment client on Client {
        email
        firstName
        lastName
        phone
        notes
        birthday
        blockedAt
        blockedBy {
            id
            firstName
            lastName
        }
        gender
        type
    }
`;

const withData = graphql(GET_CLIENTS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            search: ownProps.searchQuery || undefined,
            first: 20,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading, fetchMore } }) => ({
        loading,
        fetchMore,
        clients: get(viewer, 'salon.clients.edges', []),
        pageInfo: get(viewer, 'salon.clients.pageInfo', {}),
    }),
});

const mapStateToProps = ({ clients, user }) => ({
    searchQuery: clients.get('searchQuery'),
    salon: user.get('salon'),
});

const handlers = withHandlers({
    /**
     * Handle load more results event by requesting data after
     * end cursor from the last request.
     */
    loadMore: ownProps => () => {
        const { loading, searchQuery, salon, pageInfo, fetchMore } = ownProps;

        // Skip if we already loading results or that was the last page
        if (loading || !pageInfo.hasNextPage) {
            return false;
        }

        fetchMore({
            query: GET_CLIENTS_QUERY,
            variables: {
                salon,
                first: 20,
                after: pageInfo.endCursor,
                search: searchQuery || undefined,
            },
            // Merge current results with a new results
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const prevEdges = previousResult.viewer.salon.clients.edges;
                const newEdges = fetchMoreResult.viewer.salon.clients.edges;

                const newResults = Object.assign({}, fetchMoreResult);
                newResults.viewer.salon.clients.edges = [
                    ...prevEdges,
                    ...newEdges,
                ];

                return newResults;
            },
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    handlers,
)(ClientsList);
