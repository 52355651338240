import { withProps } from 'recompose';
import { Select } from 'semantic-ui-react';

const options = [
    { value: 15, text: '00:15' },
    { value: 30, text: '00:30' },
    { value: 60, text: '01:00' },
];

export default withProps({ options })(Select);
