/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import withModal from '../../../lib/withModal';
import { ProductDetails } from './details';

import type { Product, ModalDialogTrigger } from '../../../type';

type ProductDetailsButtonProps = {
    product: Product,
} & ModalDialogTrigger;

const ProductDetailsButtonStyled = styled.span`
    cursor: pointer;
    display: flex;
    color: #000000;
    transition: color .3s;
    align-items: center;
    height: 44px;
    padding: 0px 11px 0px 11px;

    &:hover {
        font-weight: 600;
        color: #35a3d5;
    }
`;

const ProductDetailsButton = ({
    product,
    open,
    onOpen,
    onClose,
    children,
}: ProductDetailsButtonProps) => (
    <ProductDetails
        trigger={
            <ProductDetailsButtonStyled onClick={onOpen}>
                {children}
            </ProductDetailsButtonStyled>
        }
        onClose={onClose}
        open={open}
        product={product}
    />
);

export default withModal(ProductDetailsButton);
