/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import { omit } from 'lodash';
import EditServiceGroupDialog from '../../../components/services/group/EditServiceGroupDialog';
import validate from './validate';
import { injectIntl, defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.settings.services.groupForm.existingsError',
        defaultMessage: 'This service does not exist',
    },
    nameExistingsError: {
        id: 'pages.settings.services.groupForm.nameExistingsError',
        defaultMessage: 'Service group with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

// The mutation that update existing service group with new data
const UPDATE_SERVICE_GROUP_QUERY = gql`
    mutation updateServiceGroup(
        $input: UpdateServiceGroupInput!
        $lang: String!
    ) {
        updateServiceGroup(input: $input) {
            serviceGroup {
                id
                name(lang: $lang)
                nameVariants {
                    lv
                    ru
                    en
                }
                type
                services {
                    id
                }
            }
        }
    }
`;

const withData = graphql(UPDATE_SERVICE_GROUP_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and update service group
        onSubmit: formData => {
            const mutation = mutate({
                variables: {
                    input: {
                        serviceGroupId: ownProps.serviceGroup.id,
                        nameVariants: omit(formData.nameVariants, [
                            '__typename',
                        ]),
                        type: formData.type || null,
                    },
                    lang: ownProps.currentLanguage,
                },
                // Implement optimistic response to compensate network latency and update
                // service group inl local cache before response from server
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateServiceGroup: {
                        __typename: 'UpdateServiceGroupPayload',
                        serviceGroup: {
                            __typename: 'ServiceGroup',
                            id: ownProps.serviceGroup.id,
                            name:
                                formData.nameVariants[ownProps.currentLanguage],
                            nameVariants: formData.nameVariants,
                            type: formData.type,
                            services: [],
                        },
                    },
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(() => {
                    // Close modal dialog in case of success
                    ownProps.onClose();
                })
                .catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'NotExists' &&
                            graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.existingsError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'AlreadyExists' &&
                            graphQLError.data.error === 'NAME_ALREADY_EXISTS'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.nameExistingsError,
                                ),
                            });
                        }
                    }

                    // General error if we not able to identify it specificly
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

// Init service group form
const withForm = reduxForm({
    form: 'editServiceGroup',
    enableReinitialize: true,
    touchOnBlur: false,
    validate,
});

// Populate form with initial values from provided service group object
const withInitialValues = withProps(ownProps => {
    if (ownProps.open) {
        return {
            initialValues: {
                nameVariants: ownProps.serviceGroup.nameVariants,
                type: ownProps.serviceGroup.type,
            },
        };
    }
});

export default compose(
    connect(({ intl }) => ({
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
        languages: intl.get('languages'),
        defaultLanguage: intl.get('defaultLanguage'),
    })),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(EditServiceGroupDialog);
