/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Container, Segment, Grid, Icon } from 'semantic-ui-react';
import { Animation } from '../../../components';
import Header from './Header';

const appearFromBottom = keyframes`
    0% {
        opacity: .5;
        transform: translate3D(0, 100px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }
`;

const RootSegment = styled(Segment)`
    &.segment {
        padding: 128px 0 111px;
        background-image: url(/images/landing/clients-bg.jpg) !important;
        background-repeat: no-repeat !important;
        background-position: 0 0 !important;
        background-size: cover !important;
    }
`;

const Benefits = styled(({ animate, ...props }) => <Segment {...props} />)`
    &.segment {
        margin-top: 1.5em;
        ${props => props.animate && `animation: ${appearFromBottom} 1s;`}
    }
`;

const Benefit = styled.div`
    color: #FFFFFF;
    margin-top: 4px;
    line-height: 28px;
    font-size: 16px;
    font-family: inherit;
`;

const CheckmarkIcon = styled(Icon)`
    &.icon.checkmark {
        box-shadow: 0 0 0 2px rgba(255,255,255,.8) inset !important;
        margin-right: 15px !important;
    }
`;

const intlMessages = {
    header: defineMessages({
        main: {
            id: 'pages.landing.clients.header.main',
            defaultMessage: 'Manage relationships with your clients',
        },
        sub: {
            id: 'pages.landing.clients.header.sub',
            defaultMessage: 'Simplest way to connect your clients with employees.',
        },
    }),
    benefits: defineMessages({
        benefit1: {
            id: 'pages.landing.clients.benefits.benefit1',
            defaultMessage: 'Fully transparent system',
        },
        benefit2: {
            id: 'pages.landing.clients.benefits.benefit2',
            defaultMessage: 'Appointments and reminders',
        },
        benefit3: {
            id: 'pages.landing.clients.benefits.benefit3',
            defaultMessage: 'Secure client data',
        },
        benefit4: {
            id: 'pages.landing.clients.benefits.benefit4',
            defaultMessage: 'Newest promotions and discounts',
        },
    }),
};

const ClientsSection = ({ intl }) => (
    <RootSegment textAlign="center" vertical basic inverted>
        <Container text>
            <Header as="h2" inverted>
                {intl.formatMessage(
                    intlMessages.header.main,
                )}
                <Header.Subheader>
                    {intl.formatMessage(
                        intlMessages.header.sub,
                    )}
                </Header.Subheader>
            </Header>
            <Animation>
                <Benefits basic>
                    <Grid columns={2} textAlign="left">
                        <Grid.Row>
                            <Grid.Column>
                                <Benefit>
                                    <CheckmarkIcon name="checkmark" circular />
                                    <span>
                                        {intl.formatMessage(
                                            intlMessages.benefits.benefit1,
                                        )}
                                    </span>
                                </Benefit>
                            </Grid.Column>
                            <Grid.Column>
                                <Benefit>
                                    <CheckmarkIcon name="checkmark" circular />
                                    <span>
                                        {intl.formatMessage(
                                            intlMessages.benefits.benefit2,
                                        )}
                                    </span>
                                </Benefit>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Benefit>
                                    <CheckmarkIcon name="checkmark" circular />
                                    <span>
                                        {intl.formatMessage(
                                            intlMessages.benefits.benefit3,
                                        )}
                                    </span>
                                </Benefit>
                            </Grid.Column>
                            <Grid.Column>
                                <Benefit>
                                    <CheckmarkIcon name="checkmark" circular />
                                    <span>
                                        {intl.formatMessage(
                                            intlMessages.benefits.benefit4,
                                        )}
                                    </span>
                                </Benefit>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Benefits>
            </Animation>
        </Container>
    </RootSegment>
);

export default injectIntl(ClientsSection);
