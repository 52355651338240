/**
 * @flow
 */

import React from 'react';
import { Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { withHandlers } from 'recompose';

import CartItemFields from './CartItemFields';
import TotalPrice from './TotalPrice';

import type { FieldArray } from 'redux-form';

type CartItemsProps = {
    form: String,
    fields: FieldArray,
    paid: boolean,
    onItemAdd: Function,
    onItemRemove: Function,
    change: Function,
};

const CartItems = ({
    form,
    fields,
    paid,
    onItemAdd,
    onItemRemove,
    change,
}: CartItemsProps) => (
    <div>
        {fields.map((field, idx) => (
            <Form.Field key={idx}>
                <CartItemFields
                    onRemove={onItemRemove(idx)}
                    field={field}
                    paid={paid}
                    change={change}
                />
            </Form.Field>
        ))}
        {!paid && (
            <a href="#add" onClick={onItemAdd}>
                +{' '}
                <FormattedMessage
                    id="pages.appointments.cart.form.addItem"
                    defaultMessage="Add item"
                />
            </a>
        )}
        <TotalPrice form={form} />
    </div>
);

const handlers = withHandlers({
    onItemAdd: ({ fields }) => e => {
        e.preventDefault();
        fields.push({});
    },
    onItemRemove: ({ fields }) => idx => () => fields.remove(idx),
});

export default handlers(CartItems);
