/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'redux-form';
import { isNil, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Icon, Form, Message } from 'semantic-ui-react';
import { withProps } from 'recompose';

import { Modal, Button, FormLabel } from '../../../../../components';
import InvoiceFieldsList from '../../../containers/details/invoice/InvoiceFieldsList';
import InvoiceMessage from '../InvoiceMessage';
import { PayButton } from '../../../containers/details/invoice';
import ArchiveButton from '../../../containers/details/ArchiveButton';

import type {
    ReduxForm,
    ModalDialog,
    Appointment,
    Invoice,
} from '../../../../../type';

type InvoiceFormProps = {
    appointment: Appointment,
    invoice: Invoice,
    change: Function,
    paid: Boolean,
    change: Function,
} & ModalDialog &
    ReduxForm;

type TotalPriceProps = {
    invoice: Invoice,
};

const TotalPriceWrapper = styled.div`
    margin-top: 1em;
`;

const TotalPrice = ({ invoice }: TotalPriceProps) => (
    <TotalPriceWrapper>
        <FormattedMessage
            id="pages.appointments.details.invoice.InvoiceForm.total_price"
            defaultMessage="Total price"
        />
        : &euro; {invoice.totalPrice.toFixed(2)}
    </TotalPriceWrapper>
);

const StyledForm = styled(Form)`
    & .ui.disabled.dropdown,
    & .ui.disabled.input {
        opacity: 0.7;
        input[disabled] {
            opacity: 0.7;
        }
    }
`;

const InvoiceForm = ({
    invoice,
    error,
    handleSubmit,
    submitting,
    onClose,
    appointment,
    change,
    paid,
    enableArchive,
    isOwner,
    isSysadmin,
    masterAppointments,
    fromDate,
    toDate,
    membership,
}: InvoiceFormProps) => (
    <Modal.Context>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            {paid && <InvoiceMessage />}

            <StyledForm onSubmit={handleSubmit}>
                <Form.Field>
                    <FormLabel>
                        <FormattedMessage
                            id="pages.appointments.details.invoice.InvoiceForm.invoice_items"
                            defaultMessage="Invoice items"
                        />
                    </FormLabel>
                    <FieldArray
                        name="items"
                        component={InvoiceFieldsList}
                        readOnly={paid}
                        paid={paid}
                        change={change}
                        invoice={invoice}
                        membership={membership}
                    />
                </Form.Field>
            </StyledForm>

            {!isNil(invoice) && <TotalPrice invoice={invoice} />}
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={paid || submitting}
                loading={submitting}
                primary
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.appointments.details.invoice.InvoiceForm.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.appointments.details.invoice.InvoiceForm.cancel"
                    defaultMessage="Cancel"
                />
            </Button>

            {invoice && !paid && (
                <PayButton
                    invoice={invoice}
                    disabled={submitting}
                    loading={submitting}
                    handleSubmit={handleSubmit}
                    dispatchChange={change}
                />
            )}
            {(enableArchive || isSysadmin || isOwner) && paid && (
                <ArchiveButton
                    appointment={appointment}
                    onClose={onClose}
                    masterAppointments={masterAppointments}
                    fromDate={fromDate}
                    toDate={toDate}
                />
            )}
        </Modal.Actions>
    </Modal.Context>
);

const props = withProps(({ invoice }) => ({
    paid: get(invoice, 'paid', false),
}));

export default props(InvoiceForm);
