/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { map, filter, find, get, set, cloneDeep } from 'lodash';
import Appointments from '../../components/details/Appointments';

// A query to get appointments for specified master identified by ID
const GET_APPOINTMENTS_QUERY = gql`
    query getMasterAppointments($master: ID!, $salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    master(id: $master) {
                        id
                        appointments {
                            id
                            startAt
                            service {
                                id
                                name(lang: $lang)
                                price
                            }
                            invoice {
                                id
                                paid
                                items {
                                    id
                                    type
                                    quantity
                                    price
                                    entity {
                                        ... on Service {
                                            id
                                            name(lang: $lang)
                                        }
                                        ... on Product {
                                            id
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_APPOINTMENTS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            master: ownProps.master.id,
            lang: ownProps.currentLanguage,
        },
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        appointments:
            !loading && viewer
                ? map(
                      filter(viewer.salon.master.appointments, appointment =>
                          get(appointment, 'invoice.paid'),
                      ),
                      appointment => {
                          appointment = cloneDeep(appointment);
                          const invoiceItem = find(
                              get(appointment, 'invoice.items'),
                              item =>
                                  get(item, 'entity.id') ===
                                  get(appointment, 'service.id'),
                          );
                          if (invoiceItem) {
                              set(
                                  appointment,
                                  'service.price',
                                  invoiceItem.price,
                              );
                          }
                          return appointment;
                      },
                  )
                : null,
    }),
});

export default compose(
    connect(({ user, intl }) => ({
        salon: user.get('salon'),
        currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    })),
    withData,
)(Appointments);
