import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { uniqBy, get, reduce } from 'lodash';
import moment from 'moment';

import ServiceReportTable from '../../components/service/ServiceReportTable';
import { setAppRecords, setReportFilter } from '../../../../actions/report';
import type { Dispatch } from '../../../type';

const DATE_FORMAT = 'YYYY-MM-DD';

// Query to get report of current salon for provided date range
export const GET_REPORT_QUERY = gql`
    query getReport(
        $salon: ID!
        $fromDate: String!
        $toDate: String!
        $showArchive: Boolean
        $lang: String!
        $showAppRecords: Boolean
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    serviceGroups {
                        nodes {
                            id
                            services {
                                id
                                name(lang: $lang)
                                report(
                                    fromDate: $fromDate
                                    toDate: $toDate
                                    salon: $salon
                                    showArchive: $showArchive
                                    showAppRecords: $showAppRecords
                                ) {
                                    ...report
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fragment report on Report {
        invoices {
            id
            invoiceId
            createdAt
            invoiceType
            archived
            appointments {
                id
                client {
                    id
                    firstName
                    lastName
                    phone
                }
                master {
                    id
                    firstName
                    lastName
                    phone
                }
                duration
                authorRole
            }
            items {
                id
                price
                quantity
                type
                paymentType
                entity {
                    ... on Service {
                        id
                        name(lang: $lang)
                    }
                }
                sellerObject {
                    ... on Administrator {
                        id
                        firstName
                        lastName
                    }
                    ... on Master {
                        id
                        firstName
                        lastName
                    }
                    ... on Cabinet {
                        id
                        name(lang: $lang)
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_REPORT_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            fromDate: ownProps.fromDate,
            toDate: ownProps.toDate,
            showArchive: ownProps.showArchive,
            lang: ownProps.currentLanguage,
            showAppRecords: ownProps.showAppRecords,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => {
        let services = [];
        if (!loading && viewer) {
            services = reduce(
                get(viewer, 'salon.serviceGroups.nodes'),
                (result, item) => [...result, ...item.services],
                [],
            );
        }
        return {
            services,
            loading,
        };
    },
});

const mapStateToProps = ({ user, report, salon, intl }) => ({
    isOwner: user.get('isOwner'),
    salon: user.get('salon'),
    fromDate: moment(report.get('fromDate')).format(DATE_FORMAT),
    toDate: moment(report.get('toDate')).format(DATE_FORMAT),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    showAppRecords: report.get('showAppRecords'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(ServiceReportTable);
