/**
 * @flow
 */

import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { compose, withProps, mapProps } from 'recompose';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Select } from '../../../../components';
import { convertMinutesToDateTime } from '../../../../lib/date';

const mapStateToProps = ({ salon, ...state }) => ({
    settings: salon.get('settings'),
});

const props = withProps(({ value, options, settings }) => {
    // if there is value but no options, show the value as placeholder
    let text = null;
    if (value && isEmpty(options)) {
        text = convertMinutesToDateTime(value).format(settings.format);
    }

    return {
        text,
    };
});

const limitProps = mapProps(
    ({
        meta,
        field,
        appointment,
        settings,
        startDate,
        dispatch,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(mapStateToProps),
    props,
    limitProps,
)(Select);
