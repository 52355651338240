import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withProps, withState, compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { map, get, filter } from 'lodash';

import WarehousePage from '../components/WarehousePage';

const GET_SALONS_QUERY = gql`
    query getSalons {
        viewer {
            id
            ... on Supplier {
                id
                salons {
                    id
                    name
                    address
                    masters {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                            phone
                        }
                    }
                    administrators {
                        id
                        firstName
                        lastName
                        email
                        phone
                    }
                }
                manufacturers {
                    id
                    name
                    salon
                    salonName
                }
            }
        }
    }
`;

const withData = graphql(GET_SALONS_QUERY, {
    options: ownProps => ({
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        salons: get(viewer, 'salons'),
        manufacturers: get(viewer, 'manufacturers'),
    }),
});

export default compose(
    injectIntl,
    withData,
    withState('activeItem', 'setActiveItem', 'report'),
)(WarehousePage);
