import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { map, get } from 'lodash';
import { connect } from 'react-redux';
import type { Dispatch } from '../../../type';

import PromoCodePage from '../components/PromoCodePage';
import { filterByPromoCode } from '../../../actions/statistics';

const GET_PROMO_CODE_USERS = gql`
    query getPromoCodeUsers($resolveRole: String, $promoCodeFilter: [String]) {
        viewer(resolveRole: $resolveRole) {
            id
            ... on Sysadmin {
                promoCodes
                promoCodeUsers(promoCodeFilter: $promoCodeFilter) {
                    id
                    firstName
                    lastName
                    phone
                    createdAt
                    promoCode
                }
            }
        }
    }
`;

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onPromoCodeSelect: (e, { value }) => {
        dispatch(filterByPromoCode(value));
    },
});

const mapStateToProps = ({ statistics }) => {
    return {
        promoCodeFilter: statistics.get('promoCodeFilter'),
    };
};

const withData = graphql(GET_PROMO_CODE_USERS, {
    options: ownProps => ({
        variables: {
            resolveRole: 'SYSADMIN',
            promoCodeFilter: ownProps.promoCodeFilter || [],
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading, fetchMore } }) => {
        return {
            loading,
            fetchMore,
            promoCodes: get(viewer, 'promoCodes', {}),
            users: get(viewer, 'promoCodeUsers', {}),
        };
    },
});

const mapOptions = withProps(({ promoCodes }) => {
    return {
        promoCodeOptions: map(promoCodes, item => ({
            value: item,
            text: item,
        })),
    };
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
    mapOptions,
)(PromoCodePage);
