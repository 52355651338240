/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { List, Loader } from 'semantic-ui-react';
import { compose, branch, renderComponent } from 'recompose';
import { isEmpty } from 'lodash';
import { Table, EllipsisLoader } from '../../../components';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import type { Product, ProductEdge, PageInfo } from '../../../type';

type ProductListProps = {
    products: Array<ProductEdge>,
    loadMore: Function,
    pageInfo: PageInfo,
};

type ProductRowProps = {
    product: Product,
};

const LoadMoreSpinner = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={7} textAlign="center">
            <EllipsisLoader size="1em" />
        </Table.Cell>
    </Table.Row>
);

/**
 * Show this message if inventory list is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={7} textAlign="center">
            <FormattedMessage
                id="pages.inventory.ProductList.noProduct"
                defaultMessage={"You don't have products yet"}
            />
        </Table.Cell>
    </Table.Row>
);

const ProductRow = ({ product }: ProductRowProps) => (
    <Table.Row>
        <Table.Cell>{product.name}</Table.Cell>
        <Table.Cell>{product.salon.name}</Table.Cell>
        <Table.Cell>{product.salon.address}</Table.Cell>
        <Table.Cell>{product.manufacturer.name}</Table.Cell>
        <Table.Cell>{product.code}</Table.Cell>
        <Table.Cell textAlign="right">{product.quantity}</Table.Cell>
        <Table.Cell textAlign="right">
            {parseFloat(product.price).toFixed(2)} €
        </Table.Cell>
    </Table.Row>
);

const ProductList = ({ products, pageInfo, loadMore }: ProductListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.inventory.ProductList.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.inventory.ProductList.salon"
                        defaultMessage="Salon"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.inventory.ProductList.salonAddress"
                        defaultMessage="Address"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.inventory.ProductList.manufacturer"
                        defaultMessage="Manufacturer"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.inventory.ProductList.productCode"
                        defaultMessage="Product Code"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.inventory.ProductList.qty"
                        defaultMessage="Quantity"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    <FormattedMessage
                        id="pages.inventory.ProductList.price"
                        defaultMessage="Price"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <InfiniteScroll
            element="tbody"
            pageStart={0}
            loadMore={loadMore}
            initialLoad={false}
            hasMore={pageInfo.hasNextPage}
            loader={<LoadMoreSpinner key={0} />}
            threshold={1}
            useWindow
        >
            {isEmpty(products) ? (
                <EmptyMessage />
            ) : (
                products.map(product => (
                    <ProductRow key={product.cursor} product={product.node} />
                ))
            )}
        </InfiniteScroll>
    </Table>
);

// Show spinner while products list is loading
const withSpinner = branch(
    props => props.loading && isEmpty(props.products),
    renderComponent(() => <Loader size="big" inline="centered" active />),
);

export default compose(withSpinner)(ProductList);
