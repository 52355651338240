import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Form, Input, Message, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { mapProps } from 'recompose';
import { Modal, FormField, FormLabel, Button } from '../../../components';

import type { ReduxForm } from '../../../type';

type LoginFormProps = {
    showResetPasswordForm: Function,
} & ReduxForm;

const fields = {
    input: FormField(mapProps(({ meta, ...props }) => ({ ...props }))(Input)),
};

const ModalActions = styled(Modal.Actions)`
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
`;

const SubmitButton = styled(Button)`
    margin: 0px !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    text-transform: uppercase !important;
`;

const ForgotPasswordLink = styled.div`
    margin-top: 14px;
    text-align: center;
`;

const LoginForm = ({
    handleSubmit,
    pristine,
    submitting,
    error,
    showResetPasswordForm,
}: LoginFormProps) => (
    <Modal.Context>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <Field
                    component={fields.input}
                    name="username"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.login.form.username"
                                defaultMessage="E-mail or username"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="password"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.login.form.password"
                                defaultMessage="Password"
                            />
                        </FormLabel>
                    }
                    type="password"
                    size="big"
                    simpleValue
                />
            </Form>
        </Modal.Content>
        <ModalActions>
            <SubmitButton
                onClick={handleSubmit}
                disabled={submitting || pristine}
                loading={submitting}
                primary
                fluid
            >
                <FormattedMessage
                    id="pages.login.button.signin"
                    defaultMessage="Sign in"
                />
            </SubmitButton>
            <ForgotPasswordLink>
                <a
                    href="#forgot"
                    onClick={() => {
                        setTimeout(() => showResetPasswordForm(true), 0);
                    }}
                >
                    <FormattedMessage
                        id="pages.login.button.reset"
                        defaultMessage="Forgot your password?"
                    />
                </a>
            </ForgotPasswordLink>
        </ModalActions>
    </Modal.Context>
);

export default LoginForm;
