/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import { Icon, Message, Form, Input } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { map } from 'lodash';
import { Modal, Button, FormField, FormLabel } from '../../../../../components';
import ServiceTypeSelect from './ServiceTypeSelect';

import type { ModalDialog, ReduxForm } from '../../../../../type';

const intlMessages = defineMessages({
    serviceGroupNamePlaceholder: {
        id: 'pages.settings.services.groupForm.placeholder.name',
        defaultMessage: 'Group name',
    },
});

type AddServiceGroupDialogProps = ModalDialog & ReduxForm;

const fields = {
    input: FormField(Input),
    type: FormField(ServiceTypeSelect),
};

const AddServiceGroupDialog = ({
    trigger,
    open,
    onClose,
    submitting,
    pristine,
    error,
    handleSubmit,
    languages,
    defaultLanguage,
    intl,
}: AddServiceGroupDialogProps) => (
    <Modal size="small" trigger={trigger} open={open} onClose={onClose}>
        <Modal.Header>
            <FormattedMessage
                id="pages.settings.services.groupForm.addServiceGroup"
                defaultMessage="Add Service Group"
            />
        </Modal.Header>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <FormLabel>
                    <FormattedMessage
                        id="pages.settings.services.serviceForm.name"
                        defaultMessage="Name"
                    />
                </FormLabel>
                <Form.Group widths="equal">
                    {map(languages, lang => (
                        <Field
                            key={lang}
                            name={`nameVariants[${lang}]`}
                            component={fields.input}
                            label={<FormLabel>{lang.toUpperCase()}</FormLabel>}
                            required={lang === defaultLanguage}
                            simpleValue
                            placeholder={intl.formatMessage(
                                intlMessages.serviceGroupNamePlaceholder,
                            )}
                        />
                    ))}
                </Form.Group>
                <Form.Group widths="equal">
                    <FormattedMessage
                        id="pages.settings.services.groupForm.placeholder.type"
                        defaultMessage="Group type"
                    >
                        {placeholder => (
                            <Field
                                name="type"
                                component={fields.type}
                                placeholder={placeholder}
                                simpleValue
                            />
                        )}
                    </FormattedMessage>
                </Form.Group>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                disabled={submitting || pristine}
                loading={submitting}
                onClick={handleSubmit}
                primary
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.services.groupForm.buttons.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button disabled={submitting} onClick={onClose} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.services.groupForm.buttons.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default AddServiceGroupDialog;
