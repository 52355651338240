/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Menu } from '../../../../components';
import { FormattedMessage } from 'react-intl';

export type NavigationItems = 'personalInformation';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Navigation = ({ selectedItem, onItemClick }: NavigationProps) => (
    <Menu secondary>
        <Menu.Item
            name="personalInformation"
            active={selectedItem === 'personalInformation'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.suppliers.details.personalInformation"
                defaultMessage="Personal Information"
            />
        </Menu.Item>
        <Menu.Item
            name="salonConnection"
            active={selectedItem === 'salonConnection'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage
                id="pages.suppliers.details.salonConnection"
                defaultMessage="Connections"
            />
        </Menu.Item>
    </Menu>
);

export default Navigation;
