/**
 * @flow
 */

import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { List, Loader } from 'semantic-ui-react';
import { Table } from '../../../../components';
import { map, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import EditManufacturerButton from './EditManufacturerButton';
import DeleteManufacturerButton from './DeleteManufacturerButton';

import type { Manufacturer } from '../../../../type';

type ManufacturersListProps = {
    manufacturers: Array<Manufacturer>,
};

type ManufacturerRowProps = {
    manufacturer: Manufacturer,
};

/**
 * Show this message if inventory list is empty
 */
const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={2} textAlign="center">
            <FormattedMessage
                id="pages.settings.manufacturers.noManufacturers"
                defaultMessage="You don't have manufacturers yet"
            />
        </Table.Cell>
    </Table.Row>
);

/**
 * The component represents single manufacturer row with name and actions
 */
const ManufacturerRow = ({ manufacturer }: ManufacturerRowProps) => (
    <Table.Row>
        <Table.Cell>{manufacturer.name}</Table.Cell>
        <Table.Cell>
            <List horizontal divided>
                <List.Item>
                    <EditManufacturerButton manufacturer={manufacturer} />
                </List.Item>
                <List.Item>
                    <DeleteManufacturerButton manufacturer={manufacturer} />
                </List.Item>
            </List>{' '}
        </Table.Cell>
    </Table.Row>
);

/**
 * Renders list of manufacturers
 */
const ManufacturersList = ({ manufacturers }: ManufacturersListProps) => (
    <Table celled selectable striped fixed>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.settings.manufacturers.name"
                        defaultMessage="Name"
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id="pages.settings.manufacturers.actions"
                        defaultMessage="Actions"
                    />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isEmpty(manufacturers) ? (
                <EmptyMessage />
            ) : (
                map(manufacturers, manufacturer => (
                    <ManufacturerRow
                        key={manufacturer.id}
                        manufacturer={manufacturer}
                    />
                ))
            )}
        </Table.Body>
    </Table>
);

// Show spinner while loading manufacturers
const withSpinner = branch(
    props => props.loading,
    renderComponent(() => <Loader size="large" inline="centered" active />),
);

export default compose(withSpinner)(ManufacturersList);
