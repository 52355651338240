/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Input as BaseInput, Icon, Popup } from 'semantic-ui-react';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { castArray } from 'lodash';

import LocaleUtils from 'react-day-picker/moment';

// Import localization files
import 'moment/locale/ru';
import 'moment/locale/lv';

import 'react-day-picker/lib/style.css';

import type { Locale } from '../type';

type PopupPosition = 'top left' | 'top right' | 'bottom left' | 'bottom right';

type CalendarProps = {
    value: string,
    onChange: Function,
    onBlur: Function,
    format: string,
    position: PopupPosition,
    locale: Locale,
    dayPickerProps: Object,
    className: string,
    isOpen: boolean,
    openPopup: Function,
    closePopup: Function,
    props: any,
};

const CalendarPopup = styled(Popup)`
    padding: 0 !important;
`;

const Input = styled(BaseInput)`
    ${props => props.customSize && 'width: 240px;'}
    &.ui.icon.input input {
        padding-left: 2.67142857em !important;
        padding-right: 1em !important;
        cursor: pointer;
        text-transform: capitalize;
    }

    & .DayPickerInput {
        width: 100%;
        display: inline-flex;
    }

    & .DayPickerInput-OverlayWrapper {
        position: absolute;
        z-index: 999;
    }
`;

const StyledDayPicker = styled(DayPicker)`
    &
        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f0f8ff !important;
        color: #4a90e2;
    }

    & .DayPicker-Day {
        border-radius: 0 !important;
    }
    & .DayPicker-Day--start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    & .DayPicker-Day--end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    & .DayPicker-Day--workday {
        position: relative;
    }
    & .DayPicker-Day--workday:after {
        position: absolute;
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        background-color: #ff4b4b;
        border-radius: 50%;
        bottom: 2px;
        left: calc(50% - 2.5px);
    }
    & .DayPicker-Day--workday.DayPicker-Day--outside:after {
        display: none;
    }
`;

/**
 * Helper that returns date as a string with provided format and locale
 *
 * @param {*} date   - Date to format and localize
 * @param {*} format - Date format
 * @param {*} locale - Locale of date
 */
const formatDate = (date, format, locale) => {
    const localMoment = moment(date).locale(locale);
    return localMoment.format(format);
};

const Calendar = ({
    rawValue,
    value,
    onChange,
    onBlur,
    format,
    position,
    locale,
    dayPickerProps,
    className,
    firstDayOfWeek,
    dispatch,
    isOwner,
    isOpen,
    openPopup,
    closePopup,
    field,
    ...props
}: CalendarProps) =>
    dayPickerProps.isDayPickerInput ? (
        <DayPickerInput
            value={
                value &&
                castArray(value)
                    .map(item => formatDate(item, format, locale))
                    .join(' - ')
            }
            onDayChange={date => {
                if (onChange) {
                    onChange(date || '');
                }
            }}
            dayPickerProps={{
                localeUtils: LocaleUtils,
                locale,
                numberOfMonths: 2,
            }}
            format={format}
        />
    ) : (
        <CalendarPopup
            trigger={
                <Input
                    value={
                        value &&
                        castArray(value)
                            .map(item => formatDate(item, format, locale))
                            .join(' - ')
                    }
                    icon={<Icon name="calendar" color="blue" />}
                    iconPosition="left"
                    onBlur={() => {
                        onBlur && onBlur(value);
                    }}
                    {...props}
                />
            }
            on="click"
            open={isOpen}
            onClose={closePopup}
            onOpen={openPopup}
            position={position}
            flowing
        >
            <Popup.Content>
                <StyledDayPicker
                    className={className}
                    onDayClick={date => {
                        if (onChange) {
                            onChange(date);
                        }
                        closePopup();
                    }}
                    locale={locale}
                    localeUtils={LocaleUtils}
                    {...dayPickerProps}
                />
            </Popup.Content>
        </CalendarPopup>
    );

Calendar.defaultProps = {
    position: 'bottom left',
    dayPickerProps: {},
};

export default Calendar;
