import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import { compose, branch, renderComponent, mapProps } from 'recompose';
import { Form, Input, Message, Icon } from 'semantic-ui-react';
import { Modal, FormField, FormLabel, Button } from '../../../components';

import type { ReduxForm } from '../../../type';

type ResetPasswordFormProps = {
    showResetPasswordForm: Function,
} & ReduxForm;

const fields = {
    input: FormField(mapProps(({ meta, ...props }) => ({ ...props }))(Input)),
};

const SubmitButton = styled(Button)`
    margin: 0px !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    text-transform: uppercase !important;
`;

const ModalActions = styled(Modal.Actions)`
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
`;

const LoginLink = styled.div`
    margin-top: 14px;
    text-align: center;
`;

const StyledSuccessMessage = styled(Message)`
    text-align: center;
`;

const ResetPasswordForm = ({
    handleSubmit,
    pristine,
    submitting,
    error,
    showResetPasswordForm,
}: ResetPasswordFormProps) => (
    <Modal.Context>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <Field
                    component={fields.input}
                    name="email"
                    label={<FormLabel>E-mail address</FormLabel>}
                    placeholder="Please enter your email address"
                    type="text"
                    size="big"
                    simpleValue
                />
            </Form>
        </Modal.Content>
        <ModalActions>
            <SubmitButton
                onClick={handleSubmit}
                disabled={submitting || pristine}
                loading={submitting}
                primary
                fluid
            >
                Reset password
            </SubmitButton>
            <LoginLink>
                <a href="#login" onClick={() => showResetPasswordForm(false)}>
                    Already have account?
                </a>
            </LoginLink>
        </ModalActions>
    </Modal.Context>
);

/**
 * The message that shows when first step of password recovery procedure
 * is successfully completed.
 */
const SuccessMessage = () => (
    <StyledSuccessMessage success>
        Please check your email, and follow instructions.
    </StyledSuccessMessage>
);

// Show success message if "success" flag is defined
const withSuccessMessage = branch(
    props => props.success,
    renderComponent(SuccessMessage),
);

export default compose(withSuccessMessage)(ResetPasswordForm);
