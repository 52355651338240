import { set, isNil } from 'lodash';

import { defineMessages } from 'react-intl';

import { isMongoId } from '../../../../lib/formValidation';
import { priceIsNotValid, isNotNumber } from '../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    productNameError: {
        id: 'pages.inventory.AddProductDialog.productNameError',
        defaultMessage: 'Enter product name',
    },
    manufacturerError: {
        id: 'pages.inventory.AddProductDialog.manufacturerError',
        defaultMessage: 'Enter product manufacturer',
    },
    quantityError: {
        id: 'pages.inventory.AddProductDialog.quantityError',
        defaultMessage: 'Enter number of available items',
    },
    productPriceError: {
        id: 'pages.inventory.AddProductDialog.productPriceError',
        defaultMessage: 'Enter price the product sales for',
    },
    productTypeError: {
        id: 'pages.inventory.AddProductDialog.productTypeError',
        defaultMessage: 'Choice one of product types',
    },
    supplierPriceError: {
        id: 'pages.inventory.AddProductDialog.supplierPriceError',
        defaultMessage: 'Price contains invalid characters',
    },
});

export default (values, { intl }) => {
    const errors = {};

    // Validate product name
    if (isNil(values.name)) {
        set(errors, 'name', intl.formatMessage(intlMessages.productNameError));
    }

    // Validate manufacturer
    if (!isMongoId(values.manufacturer)) {
        set(
            errors,
            'manufacturer',
            intl.formatMessage(intlMessages.manufacturerError),
        );
    }

    // Validate quantity
    if (isNil(values.quantity) || isNotNumber(values.quantity.toString())) {
        set(errors, 'quantity', intl.formatMessage(intlMessages.quantityError));
    }

    // Validate selling price
    if (isNil(values.price) || priceIsNotValid(values.price)) {
        set(
            errors,
            'price',
            intl.formatMessage(intlMessages.productPriceError),
        );
    }

    if (
        values.supplierPrice &&
        values.supplierPrice.length &&
        priceIsNotValid(values.supplierPrice)
    ) {
        set(
            errors,
            'supplierPrice',
            intl.formatMessage(intlMessages.supplierPriceError),
        );
    }

    // Validate product type
    if (isNil(values.type)) {
        set(errors, 'type', intl.formatMessage(intlMessages.productTypeError));
    }

    return errors;
};
