import React from 'react';
import { Checkbox as CheckboxUI } from 'semantic-ui-react';

const Checkbox = ({ onChange, label, value, defaultChecked }) => (
    <CheckboxUI
        label={label}
        defaultChecked={value || defaultChecked}
        onChange={(e, data) => onChange(data.checked)}
        type="checkbox"
    />
);

export default Checkbox;
