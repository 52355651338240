/**
 * @flow
 */
import { Map } from 'immutable';
import { omit } from 'lodash';

const initialState = new Map({
    selectedProducts: {},
});

export default (state: Map<String, Object> = initialState, action: Object) => {
    switch (action.type) {
        case 'INVOICE.ADD_INVOICE_PRODUCT':
            return state.set(
                'selectedProducts',
                Object.assign(state.get('selectedProducts'), {
                    [action.field]: action.option,
                }),
            );
        case 'INVOICE.DELETE_INVOICE_PRODUCT':
            return state.set(
                'selectedProducts',
                state.get('selectedProducts')[action.field]
                    ? omit(state.get('selectedProducts'), [action.field])
                    : state.get('selectedProducts'),
            );
        case 'INVOICE.CLEAR_PRODUCTS':
            return state.set('selectedProducts', {});
        default:
            return state;
    }
};
