/**
 * @flow
 */

import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle, withHandlers } from 'recompose';
import {
    get,
    map,
    each,
    uniqBy,
    property,
    cloneDeep,
    findIndex,
    remove,
} from 'lodash';
import moment from 'moment';

import apollo from '../../../apollo-client';
import { eachDay } from '../../../lib/date';
import AppointmentTable from '../components/AppointmentTable';

import {
    GET_MASTER_APPOINTMENTS_QUERY,
    APPOINTMENT_ADD_SUBSCRIPTION,
    APPOINTMENT_DELETE_SUBSCRIPTION,
} from './queries';

/**
 * Handle new appointment subscription and update local cache with new appointment
 */
const subscribeToAddedAppointment = subscribeToMore => ({
    salon,
    fromDate,
    toDate,
    showArchive,
    currentLanguage,
}) => {
    return subscribeToMore({
        document: APPOINTMENT_ADD_SUBSCRIPTION,
        variables: {
            salon,
            date: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
        // Update local cache with a new appointment
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }

            const next = cloneDeep(prev);

            const appointment = subscriptionData.data.appointmentAdded;
            const master = next.viewer.salon.master;

            if (
                (showArchive || !appointment.archived) &&
                master &&
                master.id === appointment.master.id
            ) {
                // Try to find master appointment with the same ID as a new appointment
                const oldAppointmentIndex = findIndex(
                    master.appointments,
                    o => o.id === appointment.id,
                );

                if (oldAppointmentIndex !== -1) {
                    // If appointment with the same ID exists, replace it with a new one
                    master.appointments[oldAppointmentIndex] = appointment;
                } else {
                    // If it does not exists, push it to the list of master appointments
                    master.appointments.push(appointment);
                }
            }

            return next;
        },
    });
};

/**
 * Handle deleted appointments subscription and remove data from local cache
 */
const subscribeToDeletedAppointment = subscribeToMore => ({
    salon,
    fromDate,
    toDate,
    currentLanguage,
}) => {
    return subscribeToMore({
        document: APPOINTMENT_DELETE_SUBSCRIPTION,
        variables: {
            salon,
            date: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            lang: currentLanguage,
        },
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) {
                return prev;
            }

            const next = cloneDeep(prev);

            const appointment = subscriptionData.data.appointmentDeleted;
            const master = next.viewer.salon.master;

            if (master && master.id === appointment.master.id) {
                remove(master.appointments, o => o.id === appointment.id);
            }

            return next;
        },
    });
};

const withData = graphql(GET_MASTER_APPOINTMENTS_QUERY, {
    options: ({
        salonId,
        master,
        fromDate,
        toDate,
        showArchive,
        currentLanguage,
    }) => ({
        variables: {
            master: master.id,
            withMaster: !!master.id,
            salon: salonId,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            showArchive,
            lang: currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({
        data: { loading, viewer, subscribeToMore },
        ownProps: { fromDate, toDate },
    }) => {
        const dateFormat = 'YYYY-MM-DD';
        const dateRange = eachDay(fromDate, toDate, dateFormat);
        const days = map(dateRange, date => ({
            date,
            appointments: [],
            schedule: [],
        }));
        let master = {};
        if (!loading && viewer && viewer.salon.master) {
            master = viewer.salon.master;
            each(master.appointments, appointment => {
                const idx = dateRange.indexOf(
                    moment(appointment.startAt).format(dateFormat),
                );
                if (days[idx]) {
                    days[idx].appointments.push(appointment);
                }
            });
            each(master.schedule, day => {
                const idx = dateRange.indexOf(day.date);
                if (days[idx]) {
                    days[idx].schedule.push(day);
                }
            });
        }
        return {
            loading,
            salon: get(viewer, 'salon'),
            days,
            master,
            // Subscribe on appointments updates
            subscribeToAddedAppointment: subscribeToAddedAppointment(
                subscribeToMore,
            ),
            subscribeToDeletedAppointment: subscribeToDeletedAppointment(
                subscribeToMore,
            ),
        };
    },
});

const withAppointmentTableProps = withProps(({ master }) => ({
    services: uniqBy(
        map(get(master, 'appointments', []), property('service')),
        property('id'),
    ),
}));

const mapStateToProps = ({ user, masterAppointments, salon, intl }) => ({
    salonId: user.get('salon'),
    fromDate: masterAppointments.get('fromDate'),
    toDate: masterAppointments.get('toDate'),
    master: masterAppointments.get('master') || {},
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

const withAppointmentTableLifecycle = function() {
    // store unsubscribe handlers in a closure
    let unsubscribeToAddedAppointment, unsubscribeToDeletedAppointment;
    return lifecycle({
        componentWillMount: function() {
            const {
                subscribeToAddedAppointment,
                subscribeToDeletedAppointment,
                salonId,
                fromDate,
                toDate,
                showArchive,
                currentLanguage,
            } = this.props;

            // save appointment add unsubscribe handler to use later
            unsubscribeToAddedAppointment = subscribeToAddedAppointment({
                salon: salonId,
                fromDate,
                toDate,
                showArchive,
                currentLanguage,
            });
            // save appointment delete unsubscribe handler to use later
            unsubscribeToDeletedAppointment = subscribeToDeletedAppointment({
                salon: salonId,
                fromDate,
                toDate,
                currentLanguage,
            });
        },
        componentDidUpdate: function(prevProps) {
            const {
                subscribeToAddedAppointment,
                subscribeToDeletedAppointment,
                salonId,
                fromDate,
                toDate,
                showArchive,
                currentLanguage,
            } = this.props;
            // resubscribe if salon or date has changed
            if (
                !moment(fromDate).isSame(prevProps.fromDate, 'day') ||
                !moment(toDate).isSame(prevProps.toDate, 'day') ||
                salonId !== prevProps.salonId
            ) {
                // unsubscribe if handler is available
                if (unsubscribeToAddedAppointment) {
                    unsubscribeToAddedAppointment();
                }
                // subscribe and save unsubscribe handler
                unsubscribeToAddedAppointment = subscribeToAddedAppointment({
                    salon: salonId,
                    fromDate,
                    toDate,
                    showArchive,
                    currentLanguage,
                });
                // unsubscribe if handler is available
                if (unsubscribeToDeletedAppointment) {
                    unsubscribeToDeletedAppointment();
                }
                // subscribe and save unsubscribe handler
                unsubscribeToDeletedAppointment = subscribeToDeletedAppointment(
                    {
                        salon: salonId,
                        fromDate,
                        toDate,
                        currentLanguage,
                    },
                );
            }
        },
    });
};

const withAppointmentTableHandlers = withHandlers({
    updateAppointmentClient: ({
        salonId,
        master,
        fromDate,
        toDate,
        showArchive,
        currentLanguage,
    }) => client => {
        const variables = {
            master: master.id,
            withMaster: !!master.id,
            salon: salonId,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            showArchive,
            lang: currentLanguage,
        };
        const data = apollo.cache.readQuery({
            query: GET_MASTER_APPOINTMENTS_QUERY,
            variables,
        });
        data.viewer.salon.master.appointments.forEach(appointment => {
            if (appointment.client.id === client.id) {
                appointment.notes = client.notes;
            }
        });
        apollo.cache.writeQuery({
            query: GET_MASTER_APPOINTMENTS_QUERY,
            variables,
            data,
        });
    },
});

export default compose(
    connect(mapStateToProps),
    withData,
    withAppointmentTableProps,
    withAppointmentTableLifecycle(),
    withAppointmentTableHandlers,
)(AppointmentTable);
