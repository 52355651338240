import React from 'react';
import { get } from 'lodash';
import { Grid, Statistic } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import ReportSummaryLoader from '../ReportSummaryLoader';
import ReportRevenue from '../ReportRevenue';

const ReportSummary = ({ loading, summary }) => (
    <Grid>
        <Grid.Row columns={5}>
            <Grid.Column textAlign="center">
                <FormattedMessage
                    id="pages.report.repostSummary.serviceRevenue"
                    defaultMessage="Revenue of services"
                >
                    {label => (
                        <ReportRevenue
                            loading={loading}
                            summary={get(summary, 'revenueOfServices')}
                            label={label}
                        />
                    )}
                </FormattedMessage>
            </Grid.Column>
            <Grid.Column textAlign="center">
                <FormattedMessage
                    id="pages.report.repostSummary.productsRevenue"
                    defaultMessage="Revenue of products"
                >
                    {label => (
                        <ReportRevenue
                            loading={loading}
                            summary={get(summary, 'revenueOfProducts')}
                            label={label}
                        />
                    )}
                </FormattedMessage>
            </Grid.Column>
            <Grid.Column textAlign="center">
                <FormattedMessage
                    id="pages.report.repostSummary.totalRevenue"
                    defaultMessage="Total revenue"
                >
                    {label => (
                        <ReportRevenue
                            loading={loading}
                            summary={{
                                total:
                                    get(summary, 'revenueOfServices.total', 0) +
                                    get(summary, 'revenueOfProducts.total', 0),
                                cash:
                                    get(summary, 'revenueOfServices.cash', 0) +
                                    get(summary, 'revenueOfProducts.cash', 0),
                                cc:
                                    get(summary, 'revenueOfServices.cc', 0) +
                                    get(summary, 'revenueOfProducts.cc', 0),
                                gift:
                                    get(summary, 'revenueOfServices.gift', 0) +
                                    get(summary, 'revenueOfProducts.gift', 0),
                                membership:
                                    get(
                                        summary,
                                        'revenueOfServices.membership',
                                        0,
                                    ) +
                                    get(
                                        summary,
                                        'revenueOfProducts.membership',
                                        0,
                                    ),
                            }}
                            label={label}
                        />
                    )}
                </FormattedMessage>
            </Grid.Column>
            <Grid.Column textAlign="center">
                <Statistic>
                    <Statistic.Label>
                        <FormattedMessage
                            id="pages.report.repostSummary.totalServicesSold"
                            defaultMessage="Total Services Sold"
                        />
                    </Statistic.Label>
                    <Statistic.Value>
                        <ReportSummaryLoader loading={loading}>
                            <span>{get(summary, 'numOfServices', 0)}</span>
                        </ReportSummaryLoader>
                    </Statistic.Value>
                </Statistic>
            </Grid.Column>
            <Grid.Column textAlign="center">
                <Statistic>
                    <Statistic.Label>
                        <FormattedMessage
                            id="pages.report.repostSummary.totalItemsSold"
                            defaultMessage="Total Items Sold"
                        />
                    </Statistic.Label>
                    <Statistic.Value>
                        <ReportSummaryLoader loading={loading}>
                            <span>{get(summary, 'numOfProducts', 0)}</span>
                        </ReportSummaryLoader>
                    </Statistic.Value>
                </Statistic>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default ReportSummary;
