import React from 'react';
import styled from 'styled-components';
import { withProps } from 'recompose';
import { get, flatten, map, property, reduce, filter, isEmpty } from 'lodash';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

import { Table } from '../../../../components';
import EditInvoiceButton from '../EditInvoiceButton';

const DATE_FORMAT = 'DD.MM.YYYY';

const SellerTable = styled(Table)`
    tr th:first-child,
    tr td:first-child {
        padding-left: 1.5em;
    }
`;

const Revenue = styled(Table.HeaderCell)`
    border-left: none !important;
    .ui.table thead th& {
        text-align: right;
    }
`;

const RevenueItem = styled.span`
    margin-left: 1.5em;
`;

const EditInvoiceButtonCell = styled(Table.Cell)`
    width: 62px;
`;

const EditButtonHeaderCell = styled(Table.HeaderCell)`
    width: 62px;
`;

const ShowButton = styled(Icon)`
    margin-left: -12px !important;
    margin-right: 8px !important;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

const ClientCell = ({ client, intl }) => {
    let txt = '';
    if (client && client.firstName) {
        txt = `${client.firstName} ${client.lastName}`;
        if (client.phone) {
            txt += ` (${client.phone})`;
        }
    } else {
        txt = intl.formatMessage(intlMessages.walkinClient);
    }

    return <Table.Cell>{txt}</Table.Cell>;
};

const intlMessages = defineMessages({
    walkinClient: {
        id: 'pages.appointments.details.AppointmentDetails.walkinClient',
        defaultMessage: 'Walk-in Client',
    },
});

const ReportItem = ({
    intl,
    invoiceId,
    invoiceType,
    archived,
    item,
    createdAt,
    client,
    displayEditButton,
}) => {
    return (
        <Table.Row>
            <Table.Cell>
                {/* we need to parse number from string, because we receive timestamp from the server as a string
            this is a temporary solution until the problem with the data types in graphql is resolved */}
                {moment(moment.utc(createdAt).toDate()).format(DATE_FORMAT)}
            </Table.Cell>
            <ClientCell client={client} intl={intl} />
            <Table.Cell>{item.entity.name}</Table.Cell>
            <Table.Cell textAlign="center">{item.quantity}</Table.Cell>
            <Table.Cell textAlign="right">
                {item.price.toFixed(2)} &euro;
            </Table.Cell>
            <Table.Cell textAlign="right">
                {(item.price * item.quantity).toFixed(2)} &euro;
            </Table.Cell>
            {displayEditButton && (
                <EditInvoiceButtonCell>
                    <EditInvoiceButton
                        invoiceId={invoiceId}
                        client={client}
                        item={item}
                        invoiceType={invoiceType}
                        archived={archived}
                    />
                </EditInvoiceButtonCell>
            )}
        </Table.Row>
    );
};

const SellerReport = ({
    intl,
    seller,
    items,
    displayEditButton,
    revenueOfServices,
    revenueOfProducts,
    showList,
    handleExpandList,
    hours,
    reportFilter,
}) => {
    return reportFilter === 'products' ? (
        <SellerTable celled striped fixed basic>
            <Table.Body>
                {map(
                    items,
                    ({
                        key,
                        invoiceId,
                        invoiceType,
                        archived,
                        createdAt,
                        client,
                        ...item
                    }) => (
                        <ReportItem
                            key={key}
                            invoiceId={invoiceId}
                            invoiceType={invoiceType}
                            archived={archived}
                            item={item}
                            createdAt={createdAt}
                            client={client}
                            displayEditButton={displayEditButton}
                            intl={intl}
                        />
                    ),
                )}
            </Table.Body>
        </SellerTable>
    ) : (
        !isEmpty(items) && (
            <SellerTable celled striped fixed basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={2}>
                            <ShowButton
                                name={showList ? 'minus' : 'plus'}
                                onClick={handleExpandList}
                            />
                            {seller.name ||
                                `${seller.firstName} ${seller.lastName}`}
                        </Table.HeaderCell>
                        <Revenue colSpan={4}>
                            <RevenueItem>
                                <FormattedMessage
                                    id="pages.report.repostSummary.hours"
                                    defaultMessage="Hours"
                                />
                                : {hours}
                            </RevenueItem>
                            <RevenueItem>
                                <FormattedMessage
                                    id="pages.report.repostSummary.services"
                                    defaultMessage="Services"
                                />
                                : {revenueOfServices.toFixed(2)} &euro;
                            </RevenueItem>
                            <RevenueItem>
                                <FormattedMessage
                                    id="pages.report.repostSummary.products"
                                    defaultMessage="Products"
                                />
                                : {revenueOfProducts.toFixed(2)} &euro;
                            </RevenueItem>
                            <RevenueItem>
                                <FormattedMessage
                                    id="pages.report.repostSummary.total"
                                    defaultMessage="Total"
                                />
                                :{' '}
                                {(
                                    revenueOfServices + revenueOfProducts
                                ).toFixed(2)}{' '}
                                &euro;
                            </RevenueItem>
                        </Revenue>
                        {displayEditButton && <EditButtonHeaderCell />}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {showList &&
                        map(
                            items,
                            ({
                                key,
                                invoiceId,
                                invoiceType,
                                archived,
                                createdAt,
                                client,
                                ...item
                            }) => (
                                <ReportItem
                                    key={key}
                                    invoiceId={invoiceId}
                                    invoiceType={invoiceType}
                                    archived={archived}
                                    item={item}
                                    createdAt={createdAt}
                                    client={client}
                                    displayEditButton={displayEditButton}
                                    intl={intl}
                                />
                            ),
                        )}
                </Table.Body>
            </SellerTable>
        )
    );
};

export default injectIntl(SellerReport);
