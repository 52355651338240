/**
 * Represents link that opens dialog with profile details
 *
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';

import withModal from '../../../lib/withModal';
import { AdministratorDetails } from '../../../pages/administrators/components/details';

import type { ModalDialogTrigger, Administrator } from '../../../type';

type ProfileItemProps = {
    administrator: Administrator,
} & ModalDialogTrigger;

const ProfileItem = ({
    administrator,
    open,
    onOpen,
    onClose,
}: ProfileItemProps) => (
    <AdministratorDetails
        trigger={
            <Dropdown.Item
                onClick={onOpen}
                icon="id card outline"
                text={
                    <FormattedMessage
                        id="navbar.menu.profile"
                        defaultMessage="Profile"
                    />
                }
            />
        }
        administrator={administrator}
        onClose={onClose}
        open={open}
    />
);

export default withModal(ProfileItem);
