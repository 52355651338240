/**
 * @flow
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import { Container, Segment, Grid, Image } from 'semantic-ui-react';
import { Animation } from '../../../components';
import Header from './Header';

const fadeIn = keyframes`
    0% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
`;

const RootSegment = styled(Segment)`
    &.segment {
        padding: 63px 0 63px;
    }
`;

const Benefits = styled(({ animate, ...props }) => <Segment {...props} />)`
    &.segment {
        margin-top: 8px;
        ${props => props.animate && `animation: ${fadeIn} 1.5s;`}
    }
`;

const Benefit = styled(Segment)`
    &.segment {
        padding-left: 95px;
        margin: 0;
    }
`;

const BenefitHeader = styled(Header)`
    &.header {
        font-size: 16px !important;
        line-height: 24px;
    }
`;

const BenefitImage = styled(Image)`
    &.image {
        margin-top: 17px;
    }
`;

const GridRow = styled(Grid.Row)`
    margin-top: 15px;
    padding: 0 !important;
`;

const intlMessages = {
    header: defineMessages({
        main: {
            id: 'pages.landing.benefits.header.main',
            defaultMessage: 'Easy to use scheduler and calendar',
        },
        sub: {
            id: 'pages.landing.benefits.header.sub',
            defaultMessage: 'Most businesses spend an average of 8 hours per week or more on employee scheduling using programs like Excel, or even worse— a pencil and paper.',
        },
    }),
    benefits: {
        money: defineMessages({
            header: {
                id: 'pages.landing.benefits.benefits.money.header',
                defaultMessage: 'Schedule money flows',
            },
            text: {
                id: 'pages.landing.benefits.benefits.money.text',
                defaultMessage: 'Makes your life easier, your employees happier, and your financials cleaner.',
            },
        }),
        multiStore: defineMessages({
            header: {
                id: 'pages.landing.benefits.benefits.multiStore.header',
                defaultMessage: 'Multi-store',
            },
            text: {
                id: 'pages.landing.benefits.benefits.multiStore.text',
                defaultMessage: 'Time.me allows you to manage places separately or together',
            },
        }),
        clients: defineMessages({
            header: {
                id: 'pages.landing.benefits.benefits.clients.header',
                defaultMessage: 'Clients lists',
            },
            text: {
                id: 'pages.landing.benefits.benefits.clients.text',
                defaultMessage: 'Manage client lists and their appearance in calendars',
            },
        }),
        timing: defineMessages({
            header: {
                id: 'pages.landing.benefits.benefits.timing.header',
                defaultMessage: 'Perfect timing',
            },
            text: {
                id: 'pages.landing.benefits.benefits.timing.text',
                defaultMessage: 'Never miss a client with schedules and masters management',
            },
        }),
    },
};

const BenefitsSection = ({ intl }) => (
    <RootSegment textAlign="center" vertical basic>
        <Container text>
            <Header as="h2">
                {intl.formatMessage(
                    intlMessages.header.main,
                )}
                <Header.Subheader>
                    {intl.formatMessage(
                        intlMessages.header.sub,
                    )}
                </Header.Subheader>
            </Header>
            <Animation>
                <Benefits basic vertical>
                    <Grid textAlign="left" columns={2} padded>
                        <GridRow>
                            <Grid.Column>
                                <BenefitImage
                                    src="/images/landing/benefits/money-flows.png"
                                    floated="left"
                                    verticalAlign="top"
                                />
                                <Benefit basic>
                                    <BenefitHeader as="h5">
                                        {intl.formatMessage(
                                            intlMessages.benefits.money.header,
                                        )}
                                    </BenefitHeader>
                                    {intl.formatMessage(
                                        intlMessages.benefits.money.text,
                                    )}
                                </Benefit>
                            </Grid.Column>
                            <Grid.Column>
                                <BenefitImage
                                    src="/images/landing/benefits/multi-store.png"
                                    floated="left"
                                    verticalAlign="top"
                                />
                                <Benefit basic>
                                    <BenefitHeader as="h5">
                                        {intl.formatMessage(
                                            intlMessages.benefits.multiStore.header,
                                        )}
                                    </BenefitHeader>
                                    {intl.formatMessage(
                                        intlMessages.benefits.multiStore.text,
                                    )}
                                </Benefit>
                            </Grid.Column>
                        </GridRow>
                        <GridRow>
                            <Grid.Column>
                                <BenefitImage
                                    src="/images/landing/benefits/clients-lists.png"
                                    floated="left"
                                    verticalAlign="top"
                                />
                                <Benefit basic>
                                    <BenefitHeader as="h5">
                                        {intl.formatMessage(
                                            intlMessages.benefits.clients.header,
                                        )}
                                    </BenefitHeader>
                                    {intl.formatMessage(
                                        intlMessages.benefits.clients.text,
                                    )}
                                </Benefit>
                            </Grid.Column>
                            <Grid.Column>
                                <BenefitImage
                                    src="/images/landing/benefits/perfect-timing.png"
                                    floated="left"
                                    verticalAlign="top"
                                />
                                <Benefit basic>
                                    <BenefitHeader as="h5">
                                        {intl.formatMessage(
                                            intlMessages.benefits.timing.header,
                                        )}
                                    </BenefitHeader>
                                    {intl.formatMessage(
                                        intlMessages.benefits.timing.text,
                                    )}
                                </Benefit>
                            </Grid.Column>
                        </GridRow>
                    </Grid>
                </Benefits>
            </Animation>
        </Container>
    </RootSegment>
);

export default injectIntl(BenefitsSection);
