/**
 *  @flow
 */

import React from 'react';
import { Icon, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { compose, withState, withHandlers } from 'recompose';
import { Modal, Button } from '../../../../components';

import MembershipForm from '../form/MembershipForm';
import MembershipAppointments from '../../containers/details/MembershipAppointments';
import Navigation from './Navigation';

const CloseButton = styled(Button)`
    margin-bottom: 1em !important;
`;

const Subheader = styled(Header.Subheader)`
    font-size: 1rem;
    font-weight: normal;
`;

const ModalActions = styled(Modal.Actions)`
    height: 65px;
`;

const MembershipDetails = ({
    trigger,
    open,
    onClose,
    onModalClose,
    handleSubmit,
    submitting,
    pristine,
    change,
    membership,
    selectedItem,
    onItemClick,
    ...props
}) => {
    return (
        <Modal trigger={trigger} onClose={onModalClose} open={open}>
            <Modal.Header>
                <FormattedMessage
                    id="pages.memberships.form.membership"
                    defaultMessage="Membership"
                />
                <Subheader>
                    <FormattedMessage
                        id="pages.memberships.deleteMembership.author"
                        defaultMessage="Created by {firstName} {lastName}"
                        values={{
                            firstName: membership.author.firstName || '',
                            lastName: membership.author.lastName || '',
                        }}
                    />
                </Subheader>
            </Modal.Header>
            <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
            <Modal.Content>
                {selectedItem === 'membershipInformation' ? (
                    <MembershipForm
                        change={change}
                        services={[membership.service]}
                    />
                ) : (
                    <MembershipAppointments membership={membership} />
                )}
            </Modal.Content>
            <ModalActions>
                <CloseButton onClick={onModalClose} color="red" floated="right">
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.memberships.form.cancel"
                        defaultMessage="Close"
                    />
                </CloseButton>
            </ModalActions>
        </Modal>
    );
};

// Setup state handler to hold active section
const state = withState('selectedItem', 'selectItem', 'membershipInformation');

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(
    state,
    handlers,
)(MembershipDetails);
