/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
    compose,
    withProps,
    withHandlers,
    lifecycle,
    mapProps,
} from 'recompose';
import { formValueSelector } from 'redux-form';
import { each, find, uniqBy, clone, map } from 'lodash';
import { Select } from 'semantic-ui-react';

import {
    addInvoiceProduct,
    deleteInvoiceProduct,
} from '../../../../../actions/invoice';
import { MASTER_ENTITY_FIELDS_FRAGMENT } from '../../masterAccessQueries';

// The query that gets products and services that the salon provide
const GET_ENTITIES_QUERY = gql`
    query getTitles(
        $salon: ID!
        $product: ID!
        $withProduct: Boolean!
        $lang: String!
        $service: String
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    products {
                        nodes {
                            id
                            ...product
                        }
                    }
                    product(id: $product) @include(if: $withProduct) {
                        id
                        ...product
                    }
                    serviceGroups(service: $service) {
                        nodes {
                            id
                            services {
                                id
                                ...service
                            }
                        }
                    }
                }
            }
            ...onMasterEntityFields
        }
    }

    fragment product on Product {
        name
        price
        quantity
    }

    fragment service on Service {
        name(lang: $lang)
        price
    }
    ${MASTER_ENTITY_FIELDS_FRAGMENT}
`;

const withData = graphql(GET_ENTITIES_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            product: ownProps.value || '',
            withProduct: !!ownProps.value,
            lang: ownProps.currentLanguage,
            service: ownProps.value || null,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        viewer,
    }),
});

const formatOption = (description, option) => ({
    text: option.name,
    value: option.id,
    description,
});

// Map list of products and services to options on format
// for selection menu.
const withOptions = withProps(({ type, loading, viewer, paid, disabled }) => {
    let options = [];
    let entities = [];

    if (loading === false) {
        if (type === 'PRODUCT') {
            if (viewer.salon.product) {
                entities = clone(viewer.salon.products.nodes);
                entities.push(viewer.salon.product);
            } else {
                entities = viewer.salon.products.nodes;
            }

            entities = uniqBy(entities, 'id');

            options = map(entities, item => formatOption(item.quantity, item));
        } else if (type === 'SERVICE') {
            each(viewer.salon.serviceGroups.nodes, group =>
                each(group.services, service => {
                    options.push(formatOption('Service', service));
                    entities.push(service);
                }),
            );
        }
    }

    return {
        options,
        entities,
        disabled: !type || disabled,
    };
});

// Handle onChange event to update price field with price of selected entity
const handlers = withHandlers({
    onChange: ({
        name,
        entities,
        type,
        paid,
        change,
        dispatchInvoiceProduct,
        dispatchDeleteInvoiceProduct,
        onChange,
    }) => async (e, { value }) => {
        const entity = find(entities, {
            id: value,
        });
        const namePrefix = name.replace('entity', '');
        if (entity) {
            {
                /* different data, string and integer can come here, so you need to convert them,
        this is a temporary fix until the problem with data types on the server is fixed */
            }
            change(namePrefix + 'price', parseFloat(entity.price).toFixed(2));
            if (!paid) {
                if (type === 'PRODUCT') {
                    await dispatchInvoiceProduct(name, entity);
                }

                if (type === 'SERVICE') {
                    await dispatchDeleteInvoiceProduct(name);
                }

                const getQuantity = () => {
                    if (type === 'SERVICE') {
                        return 1;
                    }

                    if (type === 'PRODUCT') {
                        return entity.quantity ? 1 : 0;
                    }

                    return 0;
                };

                change(namePrefix + 'quantity', getQuantity());
                change(namePrefix + 'paymentType', 'CASH');
            }
        }
        setTimeout(() => onChange(value), 0);
    },
});

const mapStateToProps = (state, ownProps) => ({
    salon: state.user.get('salon'),
    type: formValueSelector(ownProps.meta.form)(
        state,
        ownProps.name.replace('entity', 'type'),
    ),
    currentLanguage:
        state.intl.get('locale') || state.intl.get('defaultLanguage'),
});

const mapDispatchToProps = {
    dispatchInvoiceProduct: addInvoiceProduct,
    dispatchDeleteInvoiceProduct: deleteInvoiceProduct,
};

const withLifeCycle = lifecycle({
    componentWillReceiveProps({
        type,
        paid,
        name,
        value,
        dispatchInvoiceProduct,
        entities,
    }) {
        if (type === 'PRODUCT' && !paid) {
            const selected = find(entities, {
                id: value,
            });
            if (selected) {
                dispatchInvoiceProduct(name, selected);
            }
        }
    },
});

const limitProps = mapProps(
    ({
        meta,
        paid,
        change,
        salon,
        dispatchInvoiceProduct,
        viewer,
        entities,
        currentLanguage,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
    withOptions,
    handlers,
    withLifeCycle,
    limitProps,
)(Select);
