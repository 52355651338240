import { get, set, isNil, isNaN, each } from 'lodash';
import { defineMessages } from 'react-intl';
import { isMongoId } from '../../../../lib/formValidation';

const intlMessages = defineMessages({
    selectCabinetError: {
        id: 'pages.schedule.form.AppointmentForm.selectCabinetError',
        defaultMessage: 'Select cabinet from the list',
    },
    selectMasterError: {
        id: 'pages.schedule.form.AppointmentForm.selectMasterError',
        defaultMessage: 'Select master from the list',
    },
    dateError: {
        id: 'pages.schedule.form.AppointmentForm.dateError',
        defaultMessage: 'Enter appointment date',
    },
    timeSpanError: {
        id: 'pages.schedule.form.AppointmentForm.timeSpanError',
        defaultMessage: 'Select time span',
    },
    durationError: {
        id: 'pages.schedule.form.AppointmentForm.durationError',
        defaultMessage: 'Select procedure duration time',
    },
    serviceTypeError: {
        id: 'pages.schedule.form.AppointmentForm.serviceTypeError',
        defaultMessage: 'Select service type',
    },
    selectClientError: {
        id: 'pages.schedule.form.AppointmentForm.selectClientError',
        defaultMessage: 'Select client OR create a new',
    },
    timeOutOfRangeError: {
        id: 'pages.schedule.form.AppointmentForm.timeOutOfRangeError',
        defaultMessage: 'Time is out of range',
    },
});

export default (values, ownProps) => {
    const { intl, salon } = ownProps;
    const errors = {};

    if (ownProps.inCabinet) {
        if (!isMongoId(values.cabinet)) {
            set(
                errors,
                'cabinet',
                intl.formatMessage(intlMessages.selectCabinetError),
            );
        }
    } else {
        // Validate service master
        if (!isMongoId(values.master)) {
            set(
                errors,
                'master',
                intl.formatMessage(intlMessages.selectMasterError),
            );
        }
    }

    // Validate appointment date
    if (isNil(values.startDate)) {
        set(errors, 'startDate', intl.formatMessage(intlMessages.dateError));
    }

    // Validate time span
    if (isNil(values.startTime)) {
        set(
            errors,
            'startTime',
            intl.formatMessage(intlMessages.timeSpanError),
        );
    }

    // Validate duration
    if (isNil(values.duration)) {
        set(errors, 'duration', intl.formatMessage(intlMessages.durationError));
    }

    // Validate the service type
    if (!isMongoId(values.service)) {
        set(
            errors,
            'service',
            intl.formatMessage(intlMessages.serviceTypeError),
        );
    }

    // Validate service client
    if (!isMongoId(values.client)) {
        set(
            errors,
            'client',
            intl.formatMessage(intlMessages.selectClientError),
        );
    }

    if (
        !isNil(values.startDate) &&
        !isNil(values.startTime) &&
        !isNil(values.duration)
    ) {
        // Verify that service end time is not out of range of salon working time
        const endAt = values.startTime + values.duration;
        if (endAt > get(salon, 'settings.schedule.endAt')) {
            set(
                errors,
                'startTime',
                intl.formatMessage(intlMessages.timeOutOfRangeError),
            );
        }
    }

    if (values.services && values.services.length) {
        each(
            values.services,
            ({ cabinet, master, startTime, duration, service }, i) => {
                if (ownProps.inCabinet) {
                    if (!isMongoId(cabinet)) {
                        set(
                            errors,
                            ['services', i, 'cabinet'],
                            intl.formatMessage(intlMessages.selectCabinetError),
                        );
                    }
                } else {
                    // Validate service master
                    if (!isMongoId(master)) {
                        set(
                            errors,
                            ['services', i, 'master'],
                            intl.formatMessage(intlMessages.selectMasterError),
                        );
                    }
                }

                // Validate time span
                if (isNil(startTime) || isNaN(startTime)) {
                    set(
                        errors,
                        ['services', i, 'startTime'],
                        intl.formatMessage(intlMessages.timeSpanError),
                    );
                }

                // Validate duration
                if (isNil(duration)) {
                    set(
                        errors,
                        ['services', i, 'duration'],
                        intl.formatMessage(intlMessages.durationError),
                    );
                }

                // Validate the service type
                if (!isMongoId(service)) {
                    set(
                        errors,
                        ['services', i, 'service'],
                        intl.formatMessage(intlMessages.serviceTypeError),
                    );
                }

                if (!isNil(startTime) && !isNil(duration)) {
                    // Verify that service end time is not out of range of salon working time
                    const endAt = startTime + duration;
                    if (endAt > get(salon, 'settings.schedule.endAt')) {
                        set(
                            errors,
                            ['services', i, 'startTime'],
                            intl.formatMessage(
                                intlMessages.timeOutOfRangeError,
                            ),
                        );
                    }
                }
            },
        );
    }

    return errors;
};
