/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import type { ModalDialog, Supplier } from '../../../type';

type DeleteSupplierDialogProps = {
    supplier: Supplier,
} & ModalDialog;

const DeleteSupplierDialog = ({
    supplier,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteSupplierDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.suppliers.deleteSupplier.deleteHeader"
                defaultMessage="Delete Supplier"
            />
        </Header>
        <Modal.Content>
            <p>
                <FormattedMessage
                    id="pages.suppliers.deleteSupplier.deleteText"
                    defaultMessage="Are you sure you want to delete {firstName} {lastName}?"
                    values={{
                        firstName: supplier.firstName,
                        lastName: supplier.lastName,
                    }}
                />
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.suppliers.deleteSupplier.button.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.suppliers.deleteSupplier.button.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteSupplierDialog;
