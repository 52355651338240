/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import type { ModalDialog, Appointment } from '../../../../type';

const MessageBlock = styled(Message)`
    background-color: transparent !important;
    border: 2px solid #ff4545;
    color: #ff4545 !important;
    font-weight: bold;
    box-shadow: none !important;
`;

type DeleteAppointmentDialogProps = {
    appointment: Appointment,
    onDelete: Function,
} & ModalDialog;

const DeleteAppointmentDialog = ({
    master,
    appointment,
    trigger,
    open,
    onClose,
    submitting,
    handleSubmit,
    error,
}: DeleteAppointmentDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.appointments.details.DeleteAppointmentDialog.title"
                defaultMessage="Delete Appointment"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.appointments.AppointmentDetails.deleteMessage"
                defaultMessage="Are you sure you want to delete appointment for {clientFirstName} {clientLastName} with {masterFirstName} {masterLastName}?"
                values={{
                    clientFirstName: get(appointment, 'client.firstName'),
                    clientLastName: get(appointment, 'client.lastName'),
                    masterFirstName: get(appointment, 'master.firstName'),
                    masterLastName:
                        get(appointment, 'master.lastName') ||
                        `${get(appointment, 'cabinet.name') || ''}`,
                }}
                tagName="p"
            />
            {error && (
                <MessageBlock error>
                    <Icon name="warning sign" />
                    {error}
                </MessageBlock>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.appointments.details.DeleteAppointmentDialog.no"
                    defaultMessage="No"
                />
            </Button>
            <Button
                onClick={handleSubmit}
                disabled={submitting}
                color="green"
                inverted
            >
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.appointments.details.DeleteAppointmentDialog.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteAppointmentDialog;
