import gql from 'graphql-tag';

export const GET_SUPPLIERS_QUERY = gql`
    query getSuppliers(
        $resolveRole: String
        $search: String
        $first: Int
        $after: String
    ) {
        viewer(resolveRole: $resolveRole) {
            id
            ... on Sysadmin {
                suppliers(search: $search, first: $first, after: $after) {
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                    edges {
                        cursor
                        node {
                            id
                            firstName
                            lastName
                            email
                            phone
                            connections {
                                salon
                                manufacturer
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_SUPPLIER_QUERY = gql`
    mutation createSupplier($input: CreateSupplierInput!) {
        createSupplier(input: $input) {
            supplier {
                id
                firstName
                lastName
                email
                phone
            }
        }
    }
`;

export const UPDATE_SUPPLIER_QUERY = gql`
    mutation updateSupplier($input: UpdateSupplierInput!) {
        updateSupplier(input: $input) {
            supplier {
                id
                firstName
                lastName
                email
                phone
            }
        }
    }
`;

export const DELETE_SUPPLIER_QUERY = gql`
    mutation deleteSupplier($input: DeleteSupplierInput!) {
        deleteSupplier(input: $input) {
            supplierId
        }
    }
`;
