/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ServicesList from '../../components/services/ServicesList';

// Query for list of service groups of current salon
export const GET_SERVICE_GROUPS_QUERY = gql`
    query getServiceGroups($salon: ID!, $search: String, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    serviceGroups(search: $search, lang: $lang) {
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                        nodes {
                            id
                            ...serviceGroup
                        }
                    }
                }
            }
        }
    }

    fragment serviceGroup on ServiceGroup {
        name(lang: $lang)
        nameVariants {
            lv
            ru
            en
        }
        type
        services {
            id
            name(lang: $lang)
            nameVariants {
                lv
                ru
                en
            }
            duration
            price
            color
        }
    }
`;

const withData = graphql(GET_SERVICE_GROUPS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            search: ownProps.searchQuery || undefined,
            lang: ownProps.currentLanguage,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        serviceGroups:
            !loading && viewer ? viewer.salon.serviceGroups.nodes : [],
    }),
});

const mapStateToProps = ({ services, user, intl }) => ({
    searchQuery: services.get('searchQuery'),
    salon: user.get('salon'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(ServicesList);
