/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, filter, uniqBy } from 'lodash';
import { formValueSelector } from 'redux-form';

import AppointmentForm from '../../components/form/AppointmentForm';
import { MASTER_APPOINTMENT_FORM_FRAGMENT } from '../masterAccessQueries';

const GET_MASTERS_QUERY = gql`
    query getMasters(
        $salon: ID!
        $date: String!
        $master: ID!
        $withMaster: Boolean!
        $lang: String!
        $withCabinets: Boolean!
        $showArchive: Boolean!
        $appointment: String
        $service: String
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                    }
                    masters(deleted: true) @skip(if: $withCabinets) {
                        nodes {
                            id
                            ...master
                            freeWorkingTime(
                                date: $date
                                appointment: $appointment
                                showArchive: $showArchive
                            ) {
                                startAt
                                endAt
                            }
                            serviceGroups {
                                id
                                ...serviceGroup
                                cabinets {
                                    id
                                    ...cabinet
                                    freeWorkingTime(
                                        date: $date
                                        appointment: $appointment
                                        showArchive: $showArchive
                                    ) {
                                        startAt
                                        endAt
                                    }
                                }
                            }
                        }
                    }
                    master(id: $master) @include(if: $withMaster) {
                        id
                        ...master
                        freeWorkingTime(
                            date: $date
                            appointment: $appointment
                            showArchive: $showArchive
                        ) {
                            startAt
                            endAt
                        }
                        serviceGroups(service: $service) {
                            id
                            ...serviceGroup
                            cabinets {
                                id
                                ...cabinet
                                freeWorkingTime(
                                    date: $date
                                    appointment: $appointment
                                    showArchive: $showArchive
                                ) {
                                    startAt
                                    endAt
                                }
                            }
                        }
                    }
                    cabinets @include(if: $withCabinets) {
                        id
                        ...cabinet
                        serviceGroups(service: $service) {
                            id
                            ...serviceGroup
                            masters {
                                id
                                ...master
                            }
                        }
                    }
                }
            }
            ...onMasterAppointmentForm
        }
    }

    fragment cabinet on Cabinet {
        name(lang: $lang)
        schedule(startDate: $date) {
            startAt
            endAt
        }
    }

    fragment master on Master {
        firstName
        lastName
        deletedAt
        schedule(startDate: $date) {
            id
            startAt
            endAt
        }
    }

    fragment serviceGroup on ServiceGroup {
        name(lang: $lang)
        services {
            id
            name(lang: $lang)
            duration
            price
        }
    }

    ${MASTER_APPOINTMENT_FORM_FRAGMENT}
`;

const withData = graphql(GET_MASTERS_QUERY, {
    options: ({
        salon,
        date,
        masterId,
        currentLanguage,
        inCabinet,
        showArchive,
        appointment,
    }) => ({
        variables: {
            salon,
            date,
            master: masterId || '',
            withMaster: !!masterId,
            lang: currentLanguage,
            withCabinets: inCabinet || false,
            showArchive,
            appointment: appointment.id,
            service: appointment.service.id,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer }, ownProps: { masterId } }) => ({
        masters:
            !loading && viewer
                ? uniqBy(
                      filter(
                          [
                              get(viewer, 'salon.master', {}),
                              ...get(viewer, 'salon.masters.nodes', []),
                          ],
                          ({ id, schedule }) => {
                              let result = schedule && schedule.length;
                              if (!result && id === masterId) {
                                  result = true;
                              }
                              return result;
                          },
                      ),
                      'id',
                  )
                : [],
        cabinets: !loading && viewer ? get(viewer, 'salon.cabinets') : [],
        loading,
    }),
});

const mapStateToProps = ({ salon, user, intl, ...state }, { form }) => ({
    salon: user.get('salon'),
    date: formValueSelector(form)(state, 'startDate'),
    masterId: formValueSelector(form)(state, 'master'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    showArchive: salon.get('showArchive'),
});

export default compose(
    connect(mapStateToProps),
    withData,
)(AppointmentForm);
