/**
 *  @flow
 */

import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Modal, Button } from '../../../components';

import { ClientForm } from './form';
import { FormattedMessage } from 'react-intl';

import type { ReduxForm, ModalDialog } from '../../../type.js';

type CreateClientDialogProps = {
    // The trigger (usually button) that oppens this form
    trigger: Object,
    onClose: Function,
    userLookupStatus: string,
    onModalClose: Function,
    checkFormPristine: Function,
    handleClear: Function,
} & ModalDialog &
    ReduxForm;

const CreateClientDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    userLookupStatus,
    onModalClose,
    checkFormPristine,
    handleClear,
    ...props
}: CreateClientDialogProps) => (
    <Modal trigger={trigger} onClose={onModalClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.clients.CreateClientDialog.newClient"
                defaultMessage="New Client"
            />
        </Modal.Header>
        <Modal.Content>
            <ClientForm
                {...props}
                userLookupStatus={userLookupStatus}
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={submitting || checkFormPristine()}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.clients.CreateClientDialog.add"
                    defaultMessage="Add"
                />
            </Button>
            <Button onClick={handleClear} disabled={submitting} basic>
                <FormattedMessage
                    id="pages.clients.CreateClientDialog.clear"
                    defaultMessage="Clear"
                />
            </Button>
            <Button onClick={onModalClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.clients.CreateClientDialog.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default CreateClientDialog;
