/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';

import ManufacturersList from '../../components/manufacturers/ManufacturersList';

// Query for list of manufacturers of current salon
export const GET_MANUFACTURERS_QUERY = gql`
    query getManufacturers($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    manufacturers {
                        pageInfo {
                            hasNextPage
                            endCursor
                        }
                        nodes {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_MANUFACTURERS_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => ({
        loading,
        manufacturers: !loading ? viewer.salon.manufacturers.nodes : [],
    }),
});

// Filter results by provided search query
const withSearch = withProps(ownProps => {
    const { loading, manufacturers, searchQuery } = ownProps;
    if (loading) return;

    if (searchQuery) {
        const fuse = new Fuse(manufacturers, {
            keys: ['name'],
            shouldSort: true,
            threshold: 0.3,
        });
        const results = fuse.search(searchQuery);

        return { manufacturers: results };
    }
});

const mapStateToProps = ({ manufacturers, user }) => ({
    searchQuery: manufacturers.get('searchQuery'),
    salon: user.get('salon'),
});

export default compose(
    connect(mapStateToProps),
    withData,
    withSearch,
)(ManufacturersList);
