import gql from 'graphql-tag';

// GraphQL query to create a new client
export const CREATE_CLIENT_QUERY = gql`
    mutation createClient($input: CreateClientInput!) {
        createClient(input: $input) {
            client {
                id
                email
                firstName
                lastName
                phone
                notes
                birthday
                gender
                type
                deletedAt
            }
        }
    }
`;

const DELETE_CLIENT_QUERY = gql`
    mutation deleteClient($input: DeleteClientInput!) {
        deleteClient(input: $input) {
            clientId
        }
    }
`;

// The fragment query for appointment
export const APPOINTMENT_FRAGMENT = gql`
    fragment appointment on Appointment {
        author {
            id
            firstName
            lastName
        }
        authorRole
        cabinet {
            id
            name(lang: $lang)
        }
        master {
            id
            firstName
            lastName
        }
        client {
            id
            firstName
            lastName
            phone
            gender
            email
            notes
            birthday
            boughtItems(salon: $salon, limit: 1, types: ["PRODUCT"]) {
                edges {
                    boughtAt
                    type
                    quantity
                    entity {
                        ... on Product {
                            id
                            name
                        }
                    }
                }
            }
            stats(salon: $salon) {
                totalAppointments
                failedAppointments
                successAppointments
                lastAppointment {
                    id
                    service {
                        id
                        name(lang: $lang)
                        price
                    }
                    startAt
                    notes
                    invoice {
                        id
                        totalPrice
                    }
                }
            }
        }
        service {
            id
            name(lang: $lang)
            color
            price
        }
        startAt
        endAt
        duration
        notes
        group
        invoice {
            id
            paid
            items {
                id
                paymentType
            }
        }
        archived
        membership {
            id
        }
    }
`;

// Teh subscription to a new appointments
export const APPOINTMENT_ADD_SUBSCRIPTION = gql`
    subscription onAppointmentAdded(
        $salon: ID!
        $date: String!
        $lang: String!
    ) {
        appointmentAdded(salon: $salon, date: $date) {
            id
            ...appointment
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;

// The subscription for deleted appointments
export const APPOINTMENT_DELETE_SUBSCRIPTION = gql`
    subscription onAppointmentDeleted(
        $salon: ID!
        $date: String!
        $lang: String!
    ) {
        appointmentDeleted(salon: $salon, date: $date) {
            id
            ...appointment
        }
    }

    ${APPOINTMENT_FRAGMENT}
`;

// The subscription to migrated master
export const MASTER_MIGRATION_SUBSCRIPTION = gql`
    subscription {
        masterMigrated {
            id
            firstName
            lastName
            phone
        }
    }
`;
