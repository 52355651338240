/**
 * @flow
 */

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { Modal, Avatar } from '../../../../components';

import Navigation from './Navigation';
import PersonalInformation from '../../containers/details/PersonalInformation';
import Appointments from '../../containers/details/Appointments';
import MasterList from '../../containers/details/MasterList';

import type { ModalDialog, Client } from '../../../../type';
import type { NavigationItems } from './Navigation';

type ClientDetailsProps = {
    client: Client,
    selectedItem: NavigationItems,
    onItemClick: Function,
    onClientUpdate: Function,
    onModalClose: Function,
    isModalOpen: boolean,
} & ModalDialog;

const ClientDetails = ({
    client,
    trigger,
    onModalClose,
    isModalOpen,
    selectedItem,
    forcedSelectedItem,
    onItemClick,
    onClientUpdate,
    open
}: ClientDetailsProps) => {
    if (forcedSelectedItem && !selectedItem) {
        selectedItem = forcedSelectedItem;
    } else if (!selectedItem) {
        selectedItem = 'personalInformation';
    }

    return (
        <Modal onClose={onModalClose} open={isModalOpen | open} trigger={trigger}>
            <Modal.Header>
                <Avatar
                    name={`${client.firstName} ${client.lastName}`}
                    size={48}
                    spaced="right"
                    round
                />
                {client.firstName} {client.lastName}
            </Modal.Header>
            <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
            {selectedItem === 'personalInformation' && (
                <PersonalInformation
                    onClose={onModalClose}
                    client={client}
                    open={isModalOpen | open}
                    onClientUpdate={onClientUpdate}
                />
            )}
            {selectedItem === 'appointments' && (
                <Appointments client={client} />
            )}
            {selectedItem === 'masters' && (
                <MasterList client={client} onClose={onModalClose} />
            )}
        </Modal>
    );
};

// Setup state handler to hold active section
const state = withState('selectedItem', 'selectItem', '');

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(
    state,
    handlers,
)(ClientDetails);
