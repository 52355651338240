/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const DeleteCabinetDialog = ({ cabinet, trigger, open, onClose, onDelete }) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.cabinets.DeleteCabinet.header"
                defaultMessage="Delete Cabinet"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.cabinets.DeleteCabinet.text"
                defaultMessage="Are you sure you want to delete {cabinetName} cabinet?"
                values={{
                    cabinetName: cabinet.name,
                }}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.cabinets.DeleteCabinet.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.cabinets.DeleteCabinet.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteCabinetDialog;
