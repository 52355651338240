/**
 * @flow
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { GeneralSettings } from './general';
import { ServicesSettings } from './services';
import { ManufacturersSettings } from './manufacturers';
import { ScheduleSettings } from './schedule';

const SettingsPage = () => (
    <Container fluid>
        <Route path="/salon/settings" component={GeneralSettings} exact />
        <Route
            path="/salon/settings/services"
            component={ServicesSettings}
            exact
        />
        <Route
            path="/salon/settings/manufacturers"
            component={ManufacturersSettings}
            exact
        />
        <Route
            path="/salon/settings/schedule"
            component={ScheduleSettings}
            exact
        />
    </Container>
);

export default SettingsPage;
