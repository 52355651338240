/**
 *  @flow
 */

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Icon, Grid } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '../../../../components';
import DateRangeFrom from '../../containers/schedule/DateRangeFrom';
import DateRangeTo from '../../containers/schedule/DateRangeTo';

const StyledModal = styled(Modal)`
    width: 480px !important;
`;

const GenerateInvoiceDialog = ({
    trigger,
    open,
    onClose,
    onModalClose,
    handleSubmit,
    submitting,
    pristine,
    firstRangeFrom,
    firstRangeTo,
    secondRangeFrom,
    secondRangeTo,
    onGenerate,
    master,
    intl,
}) => {
    const firstRangeLimit = new Date(
        moment(secondRangeFrom)
            .subtract(1, 'day')
            .format('YYYY-MM-DD 23:59'),
    );
    return (
        <StyledModal trigger={trigger} open={open} onClose={onModalClose}>
            <Modal.Header>
                <FormattedMessage
                    id="pages.settings.schedule.scheduleGenerate.title"
                    defaultMessage="Generate schedule"
                />
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormattedMessage
                                id="pages.settings.schedule.scheduleGenerate.fromRange"
                                defaultMessage="From range:"
                            />
                            <br />
                            <br />
                            <DateRangeFrom
                                disabledAfter={firstRangeLimit}
                                limitDays={7}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormattedMessage
                                id="pages.settings.schedule.scheduleGenerate.toRange"
                                defaultMessage="To range:"
                            />
                            <br />
                            <br />
                            <DateRangeTo disabledBefore={firstRangeTo} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onGenerate} primary>
                    <Icon name="checkmark" />
                    <FormattedMessage
                        id="pages.settings.schedule.scheduleGenerate.generate"
                        defaultMessage="Generate"
                    />
                </Button>
                <Button disabled={submitting} onClick={onModalClose} basic>
                    <Icon name="remove" />
                    <FormattedMessage
                        id="pages.settings.schedule.scheduleGenerate.cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
            </Modal.Actions>
        </StyledModal>
    );
};

export default GenerateInvoiceDialog;
