import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const DeleteMembershipDialog = ({
    membership,
    trigger,
    open,
    onClose,
    onDelete,
}) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.memberships.deleteMembership.header"
                defaultMessage="Delete membership"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.memberships.deleteMembership.text"
                defaultMessage="Are you sure you want to delete membership {serialNumber}?"
                values={{
                    serialNumber: membership.serialNumber,
                }}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.memberships.delete.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.memberships.delete.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteMembershipDialog;
