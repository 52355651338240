import gql from 'graphql-tag';

export const GET_MEMBERSHIPS_QUERY = gql`
    query getMemberships($salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    memberships {
                        id
                        client {
                            id
                            firstName
                            lastName
                        }
                        author {
                            id
                            firstName
                            lastName
                        }
                        createdAt
                        serialNumber
                        service {
                            id
                            name(lang: $lang)
                            duration
                            price
                        }
                        quantity
                        price
                        pricePerUse
                        totalUses
                    }
                }
            }
        }
    }
`;

export const GET_ALL_SERVICES_QUERY = gql`
    query getAllServices($salon: ID!, $lang: String!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    masters(deleted: true) {
                        nodes {
                            serviceGroups {
                                id
                                name(lang: $lang)
                                services {
                                    id
                                    name(lang: $lang)
                                    duration
                                    price
                                }
                            }
                        }
                    }
                    cabinets(deleted: true) {
                        serviceGroups {
                            id
                            name(lang: $lang)
                            services {
                                id
                                name(lang: $lang)
                                duration
                                price
                            }
                        }
                    }
                }
            }
        }
    }
`;
