/**
 * Customly styled modal window
 */

import React from 'react';
import styled from 'styled-components';
import { Modal as BaseModal } from 'semantic-ui-react';
import './Modal.css';

const ModalHeader = styled(BaseModal.Header)`
    background: #f9fafb !important;
`;

const ModalActions = styled(BaseModal.Actions)`
    background: #f9fafb;
    padding: 1rem;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    text-align: left !important;
    border-radius: inherit;
`;

const ModalContent = styled(BaseModal.Content)`
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.4;
    padding: 1.5rem;
    background: #fff;
    border-radius: inherit;
`;

const ModalContext = styled.div`
    border-bottom-left-radius: 0.28571429rem;
    border-bottom-right-radius: 0.28571429rem;
`;

const Modal = styled(props => <BaseModal {...props} />)`
    margin-top: 0px !important;
`;
Modal.Header = ModalHeader;
Modal.Actions = ModalActions;
Modal.Content = ModalContent;
Modal.Context = ModalContext;

export default Modal;
