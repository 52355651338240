/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu as BaseMenu, Image } from 'semantic-ui-react';
import { injectIntl, defineMessages } from 'react-intl';

import { Button } from '../../../../components';
import Language from '../../../../layouts/containers/Language';
import LoginButton from './LoginButton';
import SignupButton from '../SignupButton';

import type { Intl } from '../../../../type';

type NavigationProps = {
    authenticated: boolean,
    intl: Intl,
};

const Menu = styled(BaseMenu)`
    padding-left: 1em;
    padding-right: 1em;
`;

const MenuItem = styled(BaseMenu.Item)`
    font-weight: 600!important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    transition: background .3s !important;
    font-family: inherit;
`;

const SubMenu = styled(BaseMenu.Menu)`
    display: flex;
`;

const DashboardButton = styled(Button)`
    text-transform: uppercase !important;
`;

const LogoImage = styled(Image)`
    height: 35px;
`;

const intlMessages = defineMessages({
    dashboard: {
        id: 'pages.landing.promo.navigation.dashboardButton',
        defaultMessage: 'Dashboard',
    },
    signup: {
        id: 'pages.landing.promo.navigation.signupButton',
        defaultMessage: 'Register',
    },
    features: {
        id: 'pages.landing.promo.navigation.features',
        defaultMessage: 'Features',
    },
    pricing: {
        id: 'pages.landing.promo.navigation.pricing',
        defaultMessage: 'Pricing',
    },
    about: {
        id: 'pages.landing.promo.navigation.about',
        defaultMessage: 'About us',
    },
});

/**
 * Section of menu that is available only for authenticated user
 */
const PrivateMenu = ({ intl }) => (
    <SubMenu>
        <BaseMenu.Item fitted>
            <DashboardButton as={Link} to="/salon" primary>
                {intl.formatMessage(
                    intlMessages.dashboard,
                )}
            </DashboardButton>
        </BaseMenu.Item>
    </SubMenu>
);

/**
 * Section of menu that is available only for non-authenticated user
 */
const PublicMenu = ({ intl }) => (
    <SubMenu>
        <BaseMenu.Item fitted>
            <Language />
        </BaseMenu.Item>
        <BaseMenu.Item fitted>
            <LoginButton />
        </BaseMenu.Item>
        <BaseMenu.Item fitted>
            <SignupButton>
                {intl.formatMessage(
                    intlMessages.signup,
                )}
            </SignupButton>
        </BaseMenu.Item>
    </SubMenu>
);

const Navigation = ({ authenticated, intl }: NavigationProps) => (
    <Menu fluid secondary inverted>
        <BaseMenu.Item>
            <LogoImage src="/images/landing/appotime-logo.png" />
        </BaseMenu.Item>
        <BaseMenu.Menu position="right">
            {/* <MenuItem link>
                {intl.formatMessage(
                    intlMessages.features,
                )}
            </MenuItem>  */}
            {/* <MenuItem link>
                {intl.formatMessage(
                    intlMessages.pricing,
                )}
            </MenuItem> */}
            {/* <MenuItem link>{intl.formatMessage(intlMessages.about)}</MenuItem> */}
            {authenticated ? (
                <PrivateMenu intl={intl} />
            ) : (
                <PublicMenu intl={intl} />
            )}
        </BaseMenu.Menu>
    </Menu>
);

export default injectIntl(Navigation);
