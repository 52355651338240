/**
 * @flow;
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { map } from 'lodash';

import CartDialog from '../../components/cart/CartDialog';
import validate from './validate';
import { injectIntl, defineMessages } from 'react-intl';
import { prepareFloatForServer } from '../../../../lib/numberFormatter';

const intlMessages = defineMessages({
    productQuantityError: {
        id: 'pages.administrators.form.productQuantityError',
        defaultMessage: 'Invoice item quantity is too big',
    },
    InternalError: {
        id: 'pages.administrators.form.internalError',
        defaultMessage: 'Internal server error',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const SELL_PRODUCTS_MUTATION = gql`
    mutation createCartInvoice($input: CreateCartInvoiceInput!) {
        createCartInvoice(input: $input) {
            invoice {
                id
                paid
            }
        }
    }
`;

const ARCHIVE_PRODUCTS_MUTATION = gql`
    mutation archiveCartInvoice($input: ArchiveCartInvoiceInput!) {
        archiveCartInvoice(input: $input) {
            invoice {
                id
            }
        }
    }
`;

const withMutation = graphql(SELL_PRODUCTS_MUTATION, {
    props: ({ ownProps: { salon, setPaid, intl, setInvoice }, mutate }) => ({
        onSubmit: formData => {
            formData.items = map(formData.items, item =>
                Object.assign({}, item, {
                    type: 'PRODUCT',
                    seller: formData.administrator,
                    price: prepareFloatForServer(item.price),
                }),
            );

            const mutation = mutate({
                variables: {
                    input: {
                        salon,
                        ...formData,
                    },
                },
            });

            return mutation
                .then(({ data: { createCartInvoice: { invoice } } }) => {
                    setInvoice(invoice);
                    setPaid(true);
                })
                .catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length) {
                        const graphQLError = error.graphQLErrors[0];
                        if (
                            graphQLError.name === 'IllegalArgument' &&
                            graphQLError.data.error ===
                                'NEGATIVE_PRODUCT_QUANTITY'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.productQuantityError,
                                ),
                            });
                        } else if (
                            graphQLError.name === 'InternalError' &&
                            graphQLError.data.error === 'INTERNAL_SERVER_ERROR'
                        ) {
                            throw new SubmissionError({
                                _error: intl.formatMessage(
                                    intlMessages.InternalError,
                                ),
                            });
                        }
                    }
                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const withArchiveMutation = graphql(ARCHIVE_PRODUCTS_MUTATION, {
    props: ({ ownProps: { invoice, onClose }, mutate }) => ({
        onArchive: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        invoiceId: invoice.id,
                        archived: true,
                    },
                },
            });
            return mutation.then(() => {
                onClose();
            });
        },
    }),
});

const withForm = reduxForm({
    form: 'shoppingCart',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withInitialValues = withProps(
    ({ open, administrator }) =>
        open && {
            initialValues: {
                administrator,
                items: [
                    {
                        entity: null,
                        quantity: null,
                        price: null,
                        paymentType: null,
                    },
                ],
            },
        },
);

const mapStateToProps = ({ user, salon }) => ({
    salon: user.get('salon'),
    administrator: user.get('id'),
    enableArchive: salon.get('settings').enableArchive,
    isSysadmin: user.get('isSysadmin'),
    isOwner: user.get('isOwner'),
});

const withInvoice = withState('invoice', 'setInvoice', {});

export default compose(
    connect(mapStateToProps),
    withInvoice,
    injectIntl,
    withMutation,
    withArchiveMutation,
    withInitialValues,
    withForm,
)(CartDialog);
