/**
 * @flow
 */

import { withHandlers } from 'recompose';
import PhoneField from '../../components/form/PhoneField';

const handlers = withHandlers({
    /**
     * Handle field onBlur callback to update phone number and trigger user lookup event.
     */
    onBlur: ({ onUserLookup, resetUserLookup, value }) => () => {
        if (value) {
            onUserLookup({
                phone: value.replace(/[^+\d]+/g, ''),
                role: 'MASTER',
            });
        } else {
            resetUserLookup();
        }
    },
});

export default handlers(PhoneField);
