// import { ApolloClient, toIdValue } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import { loadState } from './lib/sessionStorage';
import config from './config';

import fragmentTypes from './fragment-types.json';

// The shourtcat for current running protocol
const PROTOCOL = document.location.protocol;

// Provide authentication token
const middlewareLink = setContext((request, previousContext) => {
    const user = loadState('user');

    const headers = { frontend: 'PANEL' };
    if (user) {
        headers.authorization = `Bearer ${user.token}`;
        headers.role = user.role;
    }

    return { headers };
});

// Setup custom network interface using URI provided in
// configuration file
const httpLink = createUploadLink({
    uri: `${PROTOCOL}//${config.graphqlServer}/graphql`,
});

// Setup web-socket connection to receive updates in real time
const wsLink = new WebSocketLink({
    uri: `${PROTOCOL === 'https:' ? 'wss' : 'ws'}://${
        config.graphqlServer
    }/subscriptions`,
    options: {
        reconnect: true,
    },
});

// Handle GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
        });
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

// Setup link based on operation type
const link = split(
    // split based on operation type
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    middlewareLink.concat(errorLink).concat(httpLink),
);

// Setup introspection fragment matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes,
});

// Setup in-memory caching
const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: ({ id, __typename }) =>
        id ? `${__typename}_${id}` : undefined,
});

const client = new ApolloClient({
    link,
    cache,
});

export default client;
