/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import {
    Container,
    Menu as BaseMenu,
    Statistic,
    Divider,
    Select as BaseSelect,
} from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { PageHeader, Table } from '../../../components';

type PromoCodePageProps = { users: any };

const Menu = styled(BaseMenu)`
    &.menu {
        margin: -0.5em -1em !important;
    }
`;

const Select = styled(BaseSelect)`
    min-width: 300px !important;
`;

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={4} textAlign="center">
            <FormattedMessage
                id="pages.administrator.statistics.noPromoCodeUsers"
                defaultMessage="You don't have promo code user yet"
            />
        </Table.Cell>
    </Table.Row>
);

const UserRow = ({ user }: UserRowProps) => (
    <Table.Row>
        <Table.Cell>{user.firstName}</Table.Cell>
        <Table.Cell>{user.lastName}</Table.Cell>
        <Table.Cell>{user.phone}</Table.Cell>
        <Table.Cell>{moment(user.createdAt).format('DD.MM.YYYY')}</Table.Cell>
    </Table.Row>
);

const PromoCodePage = ({
    users,
    promoCodeOptions,
    onPromoCodeSelect,
    promoCodeFilter,
}: PromoCodePageProps) => {
    return (
        <Container fluid>
            <Menu secondary>
                <BaseMenu.Item header>
                    <PageHeader>
                        <FormattedMessage
                            id="pages.administrator.statistics.promoCode"
                            defaultMessage="Promo codes"
                        />
                    </PageHeader>
                </BaseMenu.Item>
                <BaseMenu.Item position="left">
                    <Select
                        defaultValue={promoCodeFilter}
                        options={promoCodeOptions}
                        onChange={onPromoCodeSelect}
                        search
                        multiple
                    />
                </BaseMenu.Item>
                <BaseMenu.Item position="right">
                    <Statistic>
                        <Statistic.Label>
                            <FormattedMessage
                                id="pages.administrator.statistics.totalRecords"
                                defaultMessage="Total records"
                            />
                        </Statistic.Label>
                        <Statistic.Value>{users.length}</Statistic.Value>
                    </Statistic>
                </BaseMenu.Item>
            </Menu>
            <Divider />
            <Table celled selectable striped fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.administrator.statistics.firstName"
                                defaultMessage="First name"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.administrator.statistics.lastName"
                                defaultMessage="Last name"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.administrator.statistics.phone"
                                defaultMessage="Phone"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.administrator.statistics.registrationDate"
                                defaultMessage="Registration date"
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isEmpty(users) ? (
                        <EmptyMessage />
                    ) : (
                        users.map(user => <UserRow key={user.id} user={user} />)
                    )}
                </Table.Body>
            </Table>
            <br />
            <br />
            <br />
        </Container>
    );
};

export default injectIntl(PromoCodePage);
