/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import withModal from '../../../lib/withModal';
import { Button } from 'semantic-ui-react';
import RemoveInvoiceDialog from '../containers/RemoveInvoiceDialog';
import type { ID, ModalDialogTrigger } from '../../../type';

type RemoveInvoiceButtonProps = {
    invoiceId: ID,
} & ModalDialogTrigger;

const TrashButton = styled(Button)`
    .ui.icon.button& > .icon {
        opacity: 1;
    }
`;

const RemoveInvoiceButton = ({
    open,
    onOpen,
    onClose,
    invoiceId,
}: RemoveInvoiceButtonProps) => (
    <RemoveInvoiceDialog
        trigger={
            <TrashButton
                floated="right"
                color="red"
                icon="trash"
                onClick={onOpen}
            />
        }
        open={open}
        onClose={onClose}
        invoiceId={invoiceId}
    />
);

export default withModal(RemoveInvoiceButton);
