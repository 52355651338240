/**
 *  @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { Modal, Button } from '../../../components';
import { FormattedMessage } from 'react-intl';

import CreateAppointmentForm from '../components/form/CreateAppointmentForm';

import type { ModalDialog, ReduxForm } from '../../../type';

const ErrorMessage = ({ error }) => (
    <Message error>
        <Icon name="warning sign" />
        {error}
    </Message>
);

type CreateAppointmentDialogProps = {
    change: Function,
    defaultMaster: String,
} & ModalDialog &
    ReduxForm;

const CreateAppointmentDialog = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    submitting,
    pristine,
    change,
    defaultMaster,
    error,
    inCabinet,
}: CreateAppointmentDialogProps) => (
    <Modal size="large" trigger={trigger} onClose={onClose} open={open}>
        <Modal.Header>
            <FormattedMessage
                id="pages.schedule.CreateAppointmentDialog.newAppointment"
                defaultMessage="New Appointment"
            />
        </Modal.Header>
        <Modal.Content>
            {error && <ErrorMessage error={error} />}
            <CreateAppointmentForm
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                change={change}
                defaultMaster={defaultMaster}
                onCloseDialog={onClose}
                inCabinet={inCabinet}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.schedule.CreateAppointmentDialog.createAppointment"
                    defaultMessage="Create Appointment"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.schedule.CreateAppointmentDialog.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default CreateAppointmentDialog;
