/**
 * @flow
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Menu } from 'semantic-ui-react';

import type { IntlShape } from 'react-intl';

import { PageHeader } from '../../../components';

import ReportMenu from '../containers/ReportMenu';
import InventoryMenu from '../containers/InventoryMenu';
import ProductList from '../containers/ProductList';
import Report from '../containers/Report';

type SuppliersPageProps = {
    intl: IntlShape,
};

export default ({
    activeItem,
    setActiveItem,
    salons,
    manufacturers,
    searchQuery,
    salonFilter,
    masterFilter,
    ...props
}: SuppliersPageProps) => (
    <Container fluid>
        <PageHeader>
            <FormattedMessage
                id="pages.warehouse.label"
                defaultMessage="Warehouse"
            />
        </PageHeader>
        <Menu pointing>
            <Menu.Item
                name="report"
                active={activeItem === 'report'}
                onClick={(e, { name }) => setActiveItem(name)}
            >
                <FormattedMessage
                    id="pages.warehouse.report.label"
                    defaultMessage="Report"
                />
            </Menu.Item>
            <Menu.Item
                name="inventory"
                active={activeItem === 'inventory'}
                onClick={(e, { name }) => setActiveItem(name)}
            >
                <FormattedMessage
                    id="pages.warehouse.inventory.label"
                    defaultMessage="Inventory"
                />
            </Menu.Item>
            {activeItem === 'report' ? (
                <ReportMenu
                    salons={salons}
                    manufacturers={manufacturers}
                    {...props}
                />
            ) : (
                <InventoryMenu salons={salons} {...props} />
            )}
        </Menu>
        {activeItem === 'report' ? <Report /> : <ProductList />}
    </Container>
);
