/**
 * @flow
 */

import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { Modal, Button } from '../../../../components';
import { SeminarForm } from '../form';

type EditSeminarProps = {
    onClose: Function,
} & ReduxForm;

const EditSeminar = ({
    onClose,
    handleSubmit,
    submitting,
    pristine,
    error,
    seminar,
}: EditSeminarProps) => (
    <Modal.Context>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <SeminarForm
                handleSubmit={handleSubmit}
                submitting={submitting}
                pristine={pristine}
                seminar={seminar}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button
                onClick={handleSubmit}
                disabled={pristine || submitting}
                loading={submitting}
                primary
            >
                <FormattedMessage
                    id="pages.seminars.details.EditSeminar.save"
                    defaultMessage="Save"
                />
            </Button>
            <Button onClick={onClose} disabled={submitting} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.seminars.details.EditSeminar.cancel"
                    defaultMessage="Cancel"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default EditSeminar;
