/**
 * @flow
 */

import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { Modal, MasterTypeIcon } from '../../../../components';
import { property } from 'lodash';

import Navigation from './Navigation';
import PersonalInformation from '../../containers/details/PersonalInformation';
import Appointments from '../../containers/details/Appointments';

import type { ModalDialog, Master } from '../../../../type';
import type { NavigationItems } from './Navigation';

type MasterDetailsProps = {
    master: Master,
    selectedItem: NavigationItems,
    onItemClick: Function,
} & ModalDialog;

const MasterDetails = ({
    master,
    trigger,
    onClose,
    open,
    selectedItem,
    onItemClick,
}: MasterDetailsProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>
            <MasterTypeIcon
                name={`${master.firstName} ${master.lastName}`}
                type={property(['serviceGroups', '0', 'type'])(master)}
            />
            {master.firstName} {master.lastName}
        </Modal.Header>
        <Navigation selectedItem={selectedItem} onItemClick={onItemClick} />
        {selectedItem === 'personalInformation' && (
            <PersonalInformation
                master={master}
                onClose={onClose}
                open={open}
            />
        )}
        {selectedItem === 'appointments' && <Appointments master={master} />}
    </Modal>
);

// Setup state handler to hold active section
const state = withState(
    'selectedItem',
    'selectItem',
    props => props.tab || 'personalInformation',
);

const handlers = withHandlers({
    // Handle item click event and save it's name to the state
    onItemClick: ownProps => (e, { name }) => {
        ownProps.selectItem(name);
    },
});

export default compose(
    state,
    handlers,
)(MasterDetails);
