import { connect } from 'react-redux';
import moment from 'moment';

import { setDate } from '../../../actions/master-appointments';
import DateRange from '../../report/components/DateRange';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { compose, withProps } from 'recompose';
import { get, map } from 'lodash';

export const GET_WORKING_DAYS = gql`
    query getWorkingDays(
        $salon: ID!
        $master: ID!
        $fromDate: String!
        $toDate: String!
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    name
                    workingDays(
                        master: $master
                        fromDate: $fromDate
                        toDate: $toDate
                    ) {
                        date
                        appointmentCount
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_WORKING_DAYS, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
            master: ownProps.master,
            fromDate: ownProps.from,
            toDate: ownProps.to,
        },
    }),
    props: ({ data: { loading, viewer, error, fetchMore } }) => {
        return {
            loading,
            fetchMore,
            workingDays: map(
                get(viewer, 'salon.workingDays', []),
                item => new Date(item.date),
            ),
        };
    },
});

const range = withProps(() => {
    const fromDate = moment().format('YYYY-MM-DD');
    const month = moment(fromDate).format('MM');
    const toDate = moment(month, 'MM')
        .month(parseInt(month, 10) + 5)
        .format('YYYY-MM-DD');

    return {
        from: fromDate,
        to: toDate,
    };
});

const mapStateToProps = ({ masterAppointments, user }) => {
    const master = masterAppointments.get('master');
    return {
        salon: user.get('salon'),
        master: master.id,
        fromDate: moment(masterAppointments.get('fromDate')).toDate(),
        toDate: moment(masterAppointments.get('toDate')).toDate(),
    };
};

export default compose(
    connect(
        mapStateToProps,
        {
            onChange: setDate,
        },
    ),
    range,
    withData,
)(DateRange);
