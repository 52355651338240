/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DeleteClientDialog from '../components/DeleteClientDialog';
import { remove, get } from 'lodash';

import { GET_CLIENTS_QUERY } from './ClientsList';
import { CLIENT_SUMMARY_QUERY } from './ClientsPage';

// Query that deletes specified client
const DELETE_CLIENT_QUERY = gql`
    mutation deleteClient($input: DeleteClientInput!) {
        deleteClient(input: $input) {
            clientId
        }
    }
`;

const updateSummary = (store, salon, gender) => {
    const data = store.readQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
    });

    const summary = get(data, 'viewer.salon.clients.summary');

    summary.total--;
    if (gender === 'MALE') {
        summary.males--;
    } else if (gender === 'FEMALE') {
        summary.females--;
    }

    store.writeQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
        data,
    });
};

const withData = graphql(DELETE_CLIENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        onDelete: () => {
            const mutation = mutate({
                variables: {
                    input: {
                        salonId: ownProps.salon,
                        clientId: ownProps.client.id,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and delete client before response will be received
                optimisticResponse: {
                    __typename: 'Mutation',
                    deleteClient: {
                        __typename: 'DeleteClientPayload',
                        clientId: ownProps.client.id,
                    },
                },
                // Remove deleted client from query cache
                update: (store, { data: { deleteClient } }) => {
                    const data = store.readQuery({
                        query: GET_CLIENTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            first: 20,
                        },
                    });
                    remove(
                        data.viewer.salon.clients.edges,
                        edge => edge.node.id === ownProps.client.id,
                    );
                    store.writeQuery({
                        query: GET_CLIENTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            first: 20,
                        },
                        data: data,
                    });

                    updateSummary(
                        store,
                        ownProps.salon,
                        ownProps.client.gender,
                    );
                },
            });

            return mutation
                .then(() => {
                    // Close modal dialog after client was deleted
                    ownProps.onClose();
                })
                .catch(error => {
                    // TODO: Do something with an error when client was not deleted
                });
        },
    }),
});

export default compose(
    connect(({ user }) => ({ salon: user.get('salon') })),
    withData,
)(DeleteClientDialog);
