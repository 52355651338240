import React from 'react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../lib/withModal';
import DeleteMembershipDialog from '../containers/DeleteMembershipDialog';

const DeleteMembershipButton = ({ membership, open, onOpen, onClose }) => (
    <DeleteMembershipDialog
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.memberships.list.delete"
                    defaultMessage="Delete"
                />
            </a>
        }
        membership={membership}
        open={open}
        onClose={onClose}
    />
);

export default withModal(DeleteMembershipButton);
