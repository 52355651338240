/**
 * Represents link that opens dialog with client details
 *
 * @flow
 */

import React from 'react';
import withModal from '../../../lib/withModal';
import { FormattedMessage } from 'react-intl';

import { ClientDetails } from './details';

import type { ModalDialogTrigger, Client } from '../../../type';

type EditClientButtonProps = {
    client: Client,
} & ModalDialogTrigger;

const EditClientButton = ({
    client,
    open,
    onOpen,
    onClose,
}: EditClientButtonProps) => (
    <ClientDetails
        trigger={
            <a onClick={onOpen}>
                <FormattedMessage
                    id="pages.clients.EditClientButton.edit"
                    defaultMessage="Edit"
                />
            </a>
        }
        client={client}
        onModalClose={onClose}
        open={open}
    />
);

export default withModal(EditClientButton);
