/**
 * @flow
 */

import React from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import withModal from '../../../../lib/withModal';
import GenerateScheduleDialog from '../../containers/schedule/GenerateScheduleDialog';

const GenerateScheduleButton = ({ open, onOpen, onClose, master }) => (
    <GenerateScheduleDialog
        trigger={
            <Button onClick={onOpen} primary>
                <FormattedMessage
                    id="pages.settings.schedule.scheduleGenerate.title"
                    defaultMessage="Generate schedule"
                />
            </Button>
        }
        open={open}
        onClose={onClose}
        master={master}
    />
);

export default withModal(GenerateScheduleButton);
