/**
 * @flow
 */

import { loadState, saveState, removeState } from '../lib/sessionStorage';
import { Map } from 'immutable';

const pageState = loadState('appointments_page');
// Define initial state
const initialState = new Map({
    // Set default date for appointments
    date: new Date(),
    cellHeight: null,
    headerCellHeight: null,
    tab: pageState ? pageState.tab || 'masters' : 'masters',
});

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        // Set a new appointments date
        case 'APPOINTMENTS.SET_DATE': {
            return state.set('date', action.date);
        }
        case 'APPOINTMENTS.SET_CELL_HEIGHT': {
            return state
                .set('cellHeight', action.cellHeight)
                .set('headerCellHeight', action.headerCellHeight);
        }
        case 'APPOINTMENTS.SET_TAB': {
            saveState('appointments_page', { tab: action.tab });
            return state.set('tab', action.tab);
        }
        default: {
            return state;
        }
    }
};
