/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { uniqBy, property } from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';

import Timetable from './Timetable';
import AppointmentCell from './AppointmentCell';
import AppointmentCardContainer from './AppointmentCardContainer';
import AppointmentColumn from '../../containers/timetable/AppointmentColumn';
import { Header as TableHeader } from '../../../../components/timetable';
import { MasterTypeIcon } from '../../../../components';
import { renderCards } from './MasterTable';

const intlMessages = defineMessages({
    noSchedule: {
        id: 'pages.appointments.timetable.cabinets.noShedule',
        defaultMessage: 'No cabinet added',
    },
});

const NoScheduleMessage = () => (
    <div>
        <FormattedMessage
            id="pages.appointments.timetable.cabinets.noShedule"
            defaultMessage="No cabinet added"
        />
        <Header.Subheader>
            <FormattedMessage
                id="pages.appointments.timetable.cabinet.scheduleIntructions"
                defaultMessage={`To add an cabinet, go to "Settings → Cabinets"`}
            />
        </Header.Subheader>
    </div>
);

const CabinetTable = ({
    salon,
    date,
    allowToAddAppointments,
    updateAppointmentClient,
    archiveAppointment,
    onHeaderClick,
    cabinets,
    createAppointments,
    updateAppointment,
    intl,
    ...props
}) => (
    <Timetable
        salon={salon}
        renderColumn={props => (
            <AppointmentColumn
                {...props}
                updateAppointment={updateAppointment}
                inCabinet
            />
        )}
        noScheduleMessage={NoScheduleMessage}
        renderCell={props => (
            <AppointmentCell
                key={props.time}
                salon={salon}
                date={date}
                allowToAddAppointments={allowToAddAppointments}
                createAppointments={createAppointments}
                {...props}
            />
        )}
        renderCards={({ provider, inCabinet }) =>
            renderCards({
                provider,
                inCabinet,
                salon,
                updateAppointmentClient,
                archiveAppointment,
            })
        }
        renderHeader={({ provider }) => (
            <TableHeader provider={provider} headerText={provider.name} />
        )}
        providers={cabinets}
        {...props}
    />
);

export default CabinetTable;
