import { withState, withHandlers, compose, withProps } from 'recompose';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    flatten,
    map,
    forEach,
    isEmpty,
    sumBy,
    filter,
    property,
    reduce,
    get,
} from 'lodash';
import SellerReport from '../../components/seller/SellerReport';

const state = withState('showList', 'setShowList', false);

const handlers = withHandlers({
    handleExpandList: ({ setShowList, showList }) => () =>
        setShowList(!showList),
});

const withValues = withProps(({ seller, showAppRecords }) => {
    let invoices = seller.report.invoices;

    if (showAppRecords) {
        invoices = filter(
            invoices,
            ({ appointments }) =>
                !isEmpty(appointments) &&
                appointments[0].authorRole !== 'ADMIN',
        );
    }

    const items = reduce(
        invoices,
        (res, { id, items, appointments, ...invoice }) => {
            const newItems = map(items, (item, i) => ({
                ...item,
                key: `${id}${i}`,
                client: get(appointments, [0, 'client']),
                ...invoice,
            }));

            res.push(...newItems);

            return res;
        },
        [],
    );

    const appointments = flatten(map(invoices, property('appointments')));

    const serviceItems = filter(items, ({ type }) => type === 'SERVICE');

    const revenueOfServices = sumBy(serviceItems, service => {
        return service.price * service.quantity;
    });

    const productItems = filter(items, ({ type }) => type === 'PRODUCT');

    const revenueOfProducts = sumBy(productItems, product => {
        return product.price * product.quantity;
    });

    const minutes = reduce(appointments, (sum, item) => sum + item.duration, 0);

    const hours = `${(minutes / 60) | 0}:${moment()
        .minutes(minutes)
        .format('mm')}`;

    return {
        items,
        revenueOfServices,
        revenueOfProducts,
        hours,
    };
});

const mapStateToProps = ({ report, user, salon, intl }) => ({
    showAppRecords: report.get('showAppRecords'),
    reportFilter: report.get('reportFilter'),
    isOwner: user.get('isOwner'),
    salon: user.get('salon'),
    showArchive: salon.get('showArchive'),
    currentLanguage: intl.get('locale') || intl.get('defaultLanguage'),
    enableArchive: salon.get('settings').enableArchive,
    reportFilter: report.get('reportFilter'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    state,
    handlers,
    withValues,
)(SellerReport);
