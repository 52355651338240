import { connect } from 'react-redux';
import { setDate } from '../../../actions/report';
import DateRange from '../components/DateRange';

const mapStateToProps = ({ report }) => ({
    fromDate: report.get('fromDate'),
    toDate: report.get('toDate'),
});

export default connect(
    mapStateToProps,
    { onChange: setDate },
)(DateRange);
