/**
 * @flow
 */

import { set } from 'lodash';
import { defineMessages } from 'react-intl';
import { isValidNumber } from 'libphonenumber-js';

import type { IntlShape } from 'react-intl';

const intlMessages = defineMessages({
    firstNameError: {
        id: 'pages.suppliers.supplierForm.firstNameError',
        defaultMessage: 'Enter client first name',
    },
    emailError: {
        id: 'pages.suppliers.supplierForm.emailError',
        defaultMessage: 'Invalid email',
    },
    phoneError: {
        id: 'pages.suppliers.supplierForm.phoneError',
        defaultMessage: 'Invalid phone number',
    },
    noCountryCodeError: {
        id: 'pages.suppliers.supplierForm.noCountryCodeError',
        defaultMessage: 'Phone number should start with country code',
    },
    passwordError: {
        id: 'pages.suppliers.supplierForm.passwordError',
        defaultMessage: 'Enter password',
    },
    repeatPasswordError: {
        id: 'pages.suppliers.supplierForm.repeatPasswordError',
        defaultMessage: 'Repeat password',
    },
    passwordDoesntMatchError: {
        id: 'pages.suppliers.supplierForm.passwordDoesntMatchError',
        defaultMessage: "Password doesn't match",
    },
});

type FromProps = {
    intl: IntlShape,
};

export default (
    values: Object,
    { intl, userLookupStatus, form }: FromProps,
) => {
    const errors = {};

    if (!values.firstName) {
        set(
            errors,
            'firstName',
            intl.formatMessage(intlMessages.firstNameError),
        );
    }

    if (!values.email) {
        set(errors, 'email', intl.formatMessage(intlMessages.emailError));
    }

    // phone number can be either empty or valid
    if (values.phone) {
        if (values.phone[0] !== '+') {
            set(
                errors,
                'phone',
                intl.formatMessage(intlMessages.noCountryCodeError),
            );
        } else if (!isValidNumber(values.phone)) {
            set(errors, 'phone', intl.formatMessage(intlMessages.phoneError));
        }
    }

    if (
        !form === 'editSupplier' &&
        !values.password &&
        userLookupStatus !== 'EXISTS'
    ) {
        set(errors, 'password', intl.formatMessage(intlMessages.passwordError));
    }

    if (
        !form === 'editSupplier' &&
        !values.repeatPassword &&
        userLookupStatus !== 'EXISTS'
    ) {
        set(
            errors,
            'repeatPassword',
            intl.formatMessage(intlMessages.repeatPasswordError),
        );
    }

    if (
        values.password !== values.repeatPassword &&
        userLookupStatus !== 'EXISTS'
    ) {
        set(
            errors,
            'repeatPassword',
            intl.formatMessage(intlMessages.passwordDoesntMatchError),
        );
    }

    return errors;
};
