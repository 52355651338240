import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Grid, Statistic } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { EllipsisLoader } from '../../../components';

const StyledEllipsisLoader = styled(EllipsisLoader)`
    & .bounce {
        line-height: 1em;
        margin-top: -10px;
    }
`;

const ReportSummaryLoader = ({ loading, children }) => {
    if (!loading) {
        return children;
    }
    return <StyledEllipsisLoader size="0.3em" />;
};

export default ({ loading, totalCount, revenue }) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column textAlign="left">
                <Statistic>
                    <Statistic.Label>
                        <FormattedMessage
                            id="pages.report.repostSummary.totalQuantity"
                            defaultMessage="Total quantity"
                        />
                    </Statistic.Label>
                    <Statistic.Value>
                        <ReportSummaryLoader loading={loading}>
                            <span>{totalCount || 0}</span>
                        </ReportSummaryLoader>
                    </Statistic.Value>
                </Statistic>
            </Grid.Column>
            <Grid.Column textAlign="right">
                <Statistic>
                    <Statistic.Label>
                        <FormattedMessage
                            id="pages.report.repostSummary.totalRevenue"
                            defaultMessage="Total revenue"
                        />
                    </Statistic.Label>
                    <Statistic.Value>
                        <ReportSummaryLoader loading={loading}>
                            <span>
                                {get(revenue, 'total', 0).toFixed(2)} &euro;
                            </span>
                        </ReportSummaryLoader>
                    </Statistic.Value>
                </Statistic>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
