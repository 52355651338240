/**
 *  @flow
 */

import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import EditSeminar from '../../components/details/EditSeminar';
import validate from '../form/seminarFormValidate';
import { UPDATE_SEMINAR_QUERY } from '../queries';

import type { Seminar } from '../../../../type';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.seminars.form.SeminarForm.existingsError',
        defaultMessage: 'This seminar does not exists',
    },
    nameExistingsError: {
        id: 'pages.seminars.AddSeminarDialog.nameExistingsError',
        defaultMessage: 'Seminar with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'editSeminar',
    touchOnBlur: false,
    validate,
});

const withData = graphql(UPDATE_SEMINAR_QUERY, {
    props: ({ mutate, ownProps: { seminar, supplier, intl, onClose } }) => ({
        // Handle form submission and update seminar
        onSubmit: formData => {
            const startAt =
                moment(formData.startAt).format('YYYY-MM-DD ') +
                formData.eventTime;

            const placeCount = parseInt(formData.placeCount);

            if (formData.banner) {
                formData.banner = formData.banner[0];
            }

            const mutation = mutate({
                variables: {
                    input: {
                        seminarId: seminar.id,
                        ...omit(formData, [
                            'startAt',
                            'eventTime',
                            'placeCount',
                        ]),
                        placeCount,
                        supplier,
                        startAt,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and update seminar directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateSeminar: {
                        __typename: 'UpdateSeminarPayload',
                        seminar: {
                            __typename: 'Seminar',
                            id: seminar.id,
                            ...omit(formData, [
                                'banner',
                                'startAt',
                                'eventTime',
                                'description',
                            ]),
                            supplier,
                            startAt,
                            description: formData.description || '',
                            banner: { __typename: 'SeminarBanner', url: '' },
                            visitors: { count: 0, list: [] },
                        },
                    },
                },
            });

            return mutation
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'NotExists') {
                            if (
                                graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.existingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'NAME_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    name: intl.formatMessage(
                                        intlMessages.nameExistingsError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const initialValues = withProps(({ seminar }: { seminar: Seminar }) => ({
    initialValues: {
        name: seminar.name,
        description: seminar.description,
        startAt: seminar.startAt,
        duration: seminar.duration,
        placeCount: seminar.placeCount,
        type: seminar.type,
        eventTime: moment(seminar.startAt).format('HH:mm'),
    },
}));

export default compose(
    connect(({ user }) => ({ supplier: user.get('id') })),
    injectIntl,
    withData,
    initialValues,
    withForm,
)(EditSeminar);
