/**
 *  @flow
 */

import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';
import uuid from 'uuid';
import { omit } from 'lodash';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import AddSeminarDialog from '../components/AddSeminarDialog';
import validate from './form/seminarFormValidate';

import { ADD_SEMINAR_QUERY, GET_SEMINARS_QUERY } from './queries';

const intlMessages = defineMessages({
    nameExistingsError: {
        id: 'pages.seminars.AddSeminarDialog.nameExistingsError',
        defaultMessage: 'Seminar with the same name already exists',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'addSeminar',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withData = graphql(ADD_SEMINAR_QUERY, {
    props: ({
        mutate,
        ownProps: { supplier, searchQuery, intl, onClose },
    }) => ({
        onSubmit: formData => {
            const startAt =
                moment(formData.startAt).format('YYYY-MM-DD ') +
                formData.eventTime;

            const placeCount = parseInt(formData.placeCount);

            const banner = formData.banner[0];

            const mutation = mutate({
                variables: {
                    input: {
                        ...omit(formData, [
                            'banner',
                            'startAt',
                            'eventTime',
                            'placeCount',
                        ]),
                        supplier,
                        startAt,
                        banner,
                        placeCount,
                    },
                },
                // Implement optimistic response to compensate network latency
                // and add a new seminar directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    addSeminar: {
                        __typename: 'AddSeminarPayload',
                        seminar: {
                            __typename: 'Seminar',
                            id: uuid.v4(),
                            ...omit(formData, [
                                'banner',
                                'startAt',
                                'eventTime',
                                'description',
                            ]),
                            supplier,
                            startAt,
                            description: formData.description || '',
                            banner: { __typename: 'SeminarBanner', url: '' },
                            visitors: { count: 0, list: [] },
                        },
                    },
                },

                update: (store, { data: { addSeminar } }) => {
                    const variables = {
                        searchQuery,
                        first: 20,
                    };

                    const data = store.readQuery({
                        query: GET_SEMINARS_QUERY,
                        variables,
                    });

                    const newSeminar = {
                        node: addSeminar.seminar,
                        __typename: 'ClientEdge',
                        cursor: btoa(addSeminar.seminar.id),
                    };

                    data.viewer.seminars.edges.unshift(newSeminar);

                    store.writeQuery({
                        query: GET_SEMINARS_QUERY,
                        variables,
                        data,
                    });
                },
            });

            return mutation
                .then(() => {
                    onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'NAME_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    name: intl.formatMessage(
                                        intlMessages.nameExistingsError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const withInitialValues = withProps(
    ({ open }) =>
        open && {
            initialValues: {},
        },
);

const mapStateToProps = ({ seminars, user }) => ({
    searchQuery: seminars.get('searchQuery'),
    supplier: user.get('id'),
});

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withData,
    withInitialValues,
    withForm,
)(AddSeminarDialog);
