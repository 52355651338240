/**
 * @flow
 */

import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { map } from 'lodash';

import { Modal, Table, Button } from '../../../../components';

import type { Seminar, SeminarVisitor } from '../../../../type';

const EmptyMessage = () => (
    <Table.Row className="transparent">
        <Table.Cell colSpan={3} textAlign="center">
            <FormattedMessage
                id="pages.seminars.details.visitors.noVisitors"
                defaultMessage="There are no visitors attended to this seminar"
            />
        </Table.Cell>
    </Table.Row>
);

type VisitorRowProps = {
    visitor: SeminarVisitor,
};

const VisitorRow = ({ visitor: { master } }: VisitorRowProps) => (
    <Table.Row>
        <Table.Cell>
            {`${master.firstName} ${master.lastName || ''}`}
        </Table.Cell>
        <Table.Cell>{master.email}</Table.Cell>
        <Table.Cell>{master.phone}</Table.Cell>
    </Table.Row>
);

type VisitorsListProps = {
    seminar: Seminar,
    onClose: Function,
};

const VisitorsList = ({
    seminar: { visitors },
    onClose,
}: VisitorsListProps) => (
    <Modal.Context>
        <Modal.Content>
            <Table celled striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.seminars.details.visitors.header.master"
                                defaultMessage="Master"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.seminars.details.visitors.header.email"
                                defaultMessage="Email"
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id="pages.seminars.details.visitors.header.phone"
                                defaultMessage="Phone"
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {visitors && visitors.count ? (
                        map(visitors.list, item => (
                            <VisitorRow key={item.id} visitor={item} />
                        ))
                    ) : (
                        <EmptyMessage />
                    )}
                </Table.Body>
            </Table>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} basic>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.seminars.details.visitors.close"
                    defaultMessage="Close"
                />
            </Button>
        </Modal.Actions>
    </Modal.Context>
);

export default VisitorsList;
