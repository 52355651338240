/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../components';
import { compose, withState, withHandlers } from 'recompose';
import { LoginForm, ResetPasswordForm } from '../containers';

import type { ReduxForm } from '../../../type';

type LoginDialogProps = {
    trigger: Function,
    open: boolean,
    onClose: Function,
    showResetPasswordForm: Function,
    resetPasswordDialog: boolean,
} & ReduxForm;

const StyledModal = styled(Modal)`
    width: 500px !important;
`;

const LoginDialog = ({
    trigger,
    open,
    onClose,
    resetPasswordDialog,
    showResetPasswordForm,
}: LoginDialogProps) => (
    <StyledModal
        trigger={trigger}
        open={open}
        onClose={onClose}
        dimmer="blurring"
    >
        {resetPasswordDialog ? (
            <ResetPasswordForm showResetPasswordForm={showResetPasswordForm} />
        ) : (
            <LoginForm showResetPasswordForm={showResetPasswordForm} />
        )}
    </StyledModal>
);

// Hold state of whether we should show login form or reset-password form
const withPasswordResetDialog = withState(
    'resetPasswordDialog',
    'showResetPasswordForm',
    false,
);

const handlers = withHandlers({
    // Override onClose event to hide reset-password form before
    // closing current dialog.
    onClose: ownProps => () => {
        ownProps.showResetPasswordForm(false);
        ownProps.onClose();
    },
});

export default compose(withPasswordResetDialog, handlers)(LoginDialog);
