/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Menu as BaseMenu } from '../../../../components';
import { FormattedMessage } from 'react-intl';

export type NavigationItems = 'editProduct';

type NavigationProps = {
    selectedItem: NavigationItems,
    onItemClick: Function,
};

const Menu = styled(BaseMenu)`
    padding: 1em !important;
    background: #F9FAFB !important;
    margin: 0px !important;
    border-bottom: 1px solid rgba(34,36,38,.15) !important;
`;

const Navigation = ({ selectedItem, onItemClick }: NavigationProps) => (
    <Menu secondary>
        <BaseMenu.Item
            name="editProduct"
            active={selectedItem === 'editProduct'}
            onClick={onItemClick}
            link
        >
            <FormattedMessage 
                    id='pages.inventory.details.Navigation.editProduct'
                    defaultMessage='Edit Product'
                />
        </BaseMenu.Item>
    </Menu>
);

export default Navigation;
