import { set } from 'lodash';
import moment from 'moment';
import { defineMessages } from 'react-intl';

const intlMessages = defineMessages({
    scheduleEndAtError: {
        id: 'pages.settings.schedule.form.endTimeError',
        defaultMessage: 'End time cannot be less then start time',
    },
    scheduleOverlapError: {
        id: 'pages.settings.schedule.form.overlapError',
        defaultMessage:
            'Working time is overlapping with the existing schedule',
    },
});

export default (values, { intl, master, workingTime }) => {
    const errors = {};

    if (values.startAt && values.endAt) {
        const startAt = Math.abs(
            moment(values.startAt)
                .startOf('day')
                .diff(moment(values.startAt), 'minutes'),
        );
        const endAt = Math.abs(
            moment(values.endAt)
                .startOf('day')
                .diff(moment(values.endAt), 'minutes'),
        );

        master.schedule.forEach(item => {
            if (item.id !== workingTime.id) {
                if (startAt <= item.startAt && endAt > item.startAt) {
                    set(
                        errors,
                        '_error',
                        intl.formatMessage(intlMessages.scheduleOverlapError),
                    );
                } else if (startAt >= item.startAt && startAt < item.endAt) {
                    set(
                        errors,
                        '_error',
                        intl.formatMessage(intlMessages.scheduleOverlapError),
                    );
                }
            }
        });

        if (moment(values.startAt).isSameOrAfter(values.endAt)) {
            set(
                errors,
                'endAt',
                intl.formatMessage(intlMessages.scheduleEndAtError),
            );
        }
    }

    return errors;
};
