/**
 * @flow
 */

import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const InvoiceMessage = () =>
    <Message info>
        <Icon name="info" />
        <FormattedMessage
            id="pages.appointments.details.InvoiceMessage.invoiceLocked"
            defaultMessage="The appointment has invoice and can't be changed anymore."
        />
    </Message>;

export default InvoiceMessage;
