/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
    compose,
    withHandlers,
    withStateHandlers,
    mapProps,
    withState,
} from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { uniqBy, property, cloneDeep, find } from 'lodash';

import ClientSelect from '../../components/form/ClientSelect';
import { MASTER_CLIENT_SELECT_FRAGMENT } from '../masterAccessQueries';

export const GET_ALL_CLIENTS_QUERY = gql`
    query getClients(
        $salon: ID!
        $client: ID!
        $withClient: Boolean!
        $search: String
    ) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    clients(search: $search, first: 30) {
                        nodes {
                            id
                            ...client
                        }
                    }
                    client(id: $client) @include(if: $withClient) {
                        id
                        ...client
                    }
                }
            }
            ...onMasterClientSelect
        }
    }

    fragment client on Client {
        email
        firstName
        lastName
        phone
        notes
        birthday
        blockedAt
        blockedBy {
            id
            firstName
            lastName
        }
        gender
        type
        deletedAt
    }

    ${MASTER_CLIENT_SELECT_FRAGMENT}
`;

const intlMessages = defineMessages({
    walkinClient: {
        id: 'pages.appointments.details.AppointmentDetails.walkinClient',
        defaultMessage: 'Walk-in Client',
    },
});

const mapOptions = (intl, item) => {
    return {
        text:
            item.firstName !== '' || item.lastName !== ''
                ? `${item.firstName} ${item.lastName}`
                : intl.formatMessage(intlMessages.walkinClient),
        value: item.id,
        description: item.phone,
        'data-notes': item.notes,
    };
};

const withData = graphql(GET_ALL_CLIENTS_QUERY, {
    options: ownProps => {
        return {
            variables: {
                salon: ownProps.salon,
                client: ownProps.value || '',
                withClient: !!ownProps.value,
                search: ownProps.search || undefined,
            },
            fetchPolicy: 'cache-and-network',
        };
    },
    props: ({ ownProps, data: { loading, viewer } }) => {
        let clients = [];
        let options = [];

        if (!loading && viewer) {
            clients = cloneDeep(viewer.salon.clients.nodes);

            if (viewer.salon.client) {
                clients.unshift(viewer.salon.client); // push
            }

            // each(viewer.salon.clients.nodes, (item, idx) => {
            //     if (!item) {
            //         console.log('idx: ', idx);
            //     }
            // });

            clients = uniqBy(clients, property('id'));

            const clientFound = clients.some(el => el.id === ownProps.value);
            const isWalkinClient = ownProps.value && !clientFound;
            if (isWalkinClient) {
                clients.unshift({
                    deletedAt: null,
                    email: '',
                    firstName: '',
                    gender: 'MALE',
                    id: ownProps.value,
                    lastName: '',
                    notes: null,
                    birthday: null,
                    blockedAt: null,
                    blockedBy: null,
                    phone: '',
                    type: 'LOCAL',
                    __typename: 'Client',
                });
            }

            options = clients.map(mapOptions.bind(null, ownProps.intl));
        }

        return {
            loading,
            options,
            clients,
        };
    },
});

/**
 * Keep state of search query
 */
const withSearch = withStateHandlers(
    { search: null },
    {
        onSearch: () => (event, data) => ({
            search: data.searchQuery,
        }),
    },
);

const handlers = withHandlers({
    // Automatically select a new client in the appointment form
    onAdd: ({ name, change }) => client => {
        change(name, client.id);
        change('notes', client.notes);
    },
    onClientUpdate: ({ change, updateAppointmentClient }) => client => {
        change('notes', client.notes);
        if (updateAppointmentClient) {
            updateAppointmentClient(client);
        }
    },
    onChange: ({ onChange, change, clients, props }) => (e, data) => {
        const client = find(clients, { id: data.value });
        change('notes', client ? client.notes : '');
        // on value change component tries to get new data and rerender.
        // without delay click event is triggered on the modal backdrop and modal closes.
        setTimeout(() => onChange(e, data), 0);
    },
});

const limitProps = mapProps(
    ({ meta, salon, dispatch, change, updateAppointmentClient, ...props }) => ({
        ...props,
    }),
);

const clientState = withState('clientState', 'setClient', {});
const openModalState = withState('open', 'setOpen', false);

export default compose(
    connect(({ user }) => ({
        salon: user.get('salon'),
    })),
    // withState('walkinClient', 'setWalkinClient', 0),
    injectIntl,
    withSearch,
    withData,
    handlers,
    clientState,
    openModalState,
    // withMutation,
    // handlers2,
    limitProps,
)(ClientSelect);
