/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { map, orderBy, property } from 'lodash';

import {
    compose,
    lifecycle,
    withHandlers,
    withState,
    withProps,
} from 'recompose';

import { Grid, Dimmer, Loader, Header } from 'semantic-ui-react';
import Cell, { CELL_HEIGHT_PX } from './Cell';
import Timeline from './Timeline';
import Column from './Column';
import NowLine from './NowLine';
import { FormattedMessage } from 'react-intl';
import type { ScheduleSettings, Master } from '../../type';

type TimetableProps = {
    loading: boolean,
    date: Object,
    schedule: ScheduleSettings,
    masters: Array<Master>,
    renderColumn: Function,
    renderCards: Function,
    renderCell: Function,
    legend: ?Object,
    cellWidth: Number,
    cellHeight: number,
    headerCellHeight: number,
};

const TimetableGrid = styled(Grid)`
    &.grid {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const TimetableContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background: #ffffff;
`;

const LeftSidebar = styled.div`
    flex: 0 0 auto;
    width: 100px;
    border-right: 1px solid #e9e9e9;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const TimelineHeader = styled(Cell)`
    font-size: 12px;
    padding: 0px 14px 0px 37px;
`;

const CellGrid = styled.div`
    flex: 1 1 auto;
    flex-direction: row;
    display: flex;
    ${props =>
        props.scrollProps && props.scrollProps.scrollableGrid
            ? ` overflow: auto;`
            : ` overflow: hidden;`};
`;

const TimetableColumn = styled(Grid.Column)`
    .ui.grid > &.column:not(.row) {
        flex: 1;
        padding-bottom: 0;
    }
`;

const LegendColumn = styled(Grid.Column)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const CenteredDiv = styled.div`
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 20px;
`;

const NoScheduleMessage = styled(Header)`
    text-align: center;
`;

const Timetable = ({
    loading,
    date,
    schedule,
    providers,
    renderColumn,
    renderCards,
    renderCell,
    renderHeader,
    legend,
    cellWidth,
    cellHeight,
    headerCellHeight,
    scrollProps,
    noScheduleMessage,
}: TimetableProps) => (
    <TimetableGrid className="timetable" columns={1}>
        <TimetableColumn>
            {loading && (
                <Dimmer active inverted>
                    <Loader size="big" />
                </Dimmer>
            )}
            <TimetableContent className="content">
                <div style={{ display: 'flex' }}>
                    <LeftSidebar>
                        <TimelineHeader className="timeline-header" />
                        <Timeline schedule={schedule} date={date} />
                    </LeftSidebar>
                </div>
                {providers.length ? (
                    <CellGrid scrollProps={scrollProps}>
                        {map(providers, provider => {
                            return renderColumn({
                                key: provider.id,
                                provider,
                                schedule,
                                renderCards,
                                renderCell,
                                renderHeader,
                                cellWidth: cellWidth,
                                scrollProps,
                            });
                        })}
                        <NowLine
                            schedule={schedule}
                            columns={providers.length}
                            date={date}
                            width={window.innerWidth - 100}
                            cellHeight={cellHeight}
                            headerCellHeight={headerCellHeight}
                        />
                    </CellGrid>
                ) : (
                    <CenteredDiv>
                        <NoScheduleMessage as="h3">
                            {noScheduleMessage ? (
                                noScheduleMessage()
                            ) : (
                                <div>
                                    <FormattedMessage
                                        id="pages.appointments.timetable.noShedule"
                                        defaultMessage="No masters schedule on this day"
                                    />
                                    <Header.Subheader>
                                        <FormattedMessage
                                            id="pages.appointments.timetable.scheduleIntructions"
                                            defaultMessage={`In order to see master calendar go to "Settings → Schedule" menu and add some working time to masters`}
                                        />
                                    </Header.Subheader>
                                </div>
                            )}
                        </NoScheduleMessage>
                    </CenteredDiv>
                )}
            </TimetableContent>
        </TimetableColumn>
        {legend && <LegendColumn textAlign="center">{legend}</LegendColumn>}
    </TimetableGrid>
);

const renderColumn = props => {
    return <Column {...props} />;
};

Timetable.defaultProps = {
    renderColumn,
};

const withSortedProviders = withProps(({ providers }) => ({
    providers: orderBy(
        providers,
        provider => {
            const type = property(['serviceGroups', '0', 'type'])(provider);
            const types = {
                hairdresser: 3,
                cosmetologist: 2,
                manicure: 1,
            };
            return types[String(type).toLowerCase()] || 0;
        },
        'desc',
    ),
}));

const mapStateToProps = ({ appointments }) => ({
    cellHeight: appointments.get('cellHeight') || CELL_HEIGHT_PX,
    headerCellHeight: appointments.get('headerCellHeight') || CELL_HEIGHT_PX,
});

export default compose(
    connect(mapStateToProps),
    withState('cellWidth', 'setCellWidth', 0),
    withHandlers({
        resizeHandler: props => args => {
            // hack related to #286, default is 100
            const ratio = props.schedule.step !== 15 ? 100 : 116;
            props.setCellWidth(
                (window.innerWidth - ratio) / props.providers.length,
            );
        },
    }),
    lifecycle({
        componentWillReceiveProps(nextProps) {
            if (nextProps.providers.length !== this.props.providers.length)
                this.props.resizeHandler();
        },
        componentWillMount() {
            this.props.resizeHandler();
        },
        componentDidMount() {
            window.addEventListener('resize', this.props.resizeHandler);
        },
        componentWillUnmount() {
            window.removeEventListener('resize', this.props.resizeHandler);
        },
    }),
    withSortedProviders,
)(Timetable);
