/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withHandlers, lifecycle, mapProps } from 'recompose';
import { filter, find, map, get } from 'lodash';

import { addInvoiceProduct } from '../../../../../actions/invoice';

import { Select } from 'semantic-ui-react';

const GET_ENTITIES_QUERY = gql`
    query getTitles($salon: ID!) {
        viewer {
            id
            ... on Administrator {
                salon(id: $salon) {
                    id
                    products {
                        nodes {
                            id
                            name
                            price
                            quantity
                        }
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_ENTITIES_QUERY, {
    options: ownProps => ({
        variables: {
            salon: ownProps.salon,
        },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, viewer } }) => {
        const products =
            !loading && viewer
                ? filter(
                      get(viewer, 'salon.products.nodes', []),
                      item => item.quantity > 0,
                  )
                : [];
        const options = map(products, item => ({
            key: item.id,
            text: item.name,
            value: item.id,
            description: item.quantity,
        }));
        return {
            loading,
            products,
            options,
        };
    },
});

const handlers = withHandlers({
    onChange: ({
        name,
        meta,
        dispatchInvoiceProduct,
        onChange,
        products,
        change,
    }) => (e, { value }) => {
        const product = find(products, {
            id: value,
        });
        const namePrefix = name.replace('entity', '');
        if (product) {
            change(namePrefix + 'price', product.price.toFixed(2));
            change(namePrefix + 'quantity', 1);
            change(namePrefix + 'paymentType', 'CASH');
            dispatchInvoiceProduct(name, product);
        }
        onChange(value);
    },
});

const mapStateToProps = (state, ownProps) => ({
    salon: state.user.get('salon'),
});

const mapDispatchToProps = {
    dispatchInvoiceProduct: addInvoiceProduct,
};

const withLifeCycle = lifecycle({
    componentWillReceiveProps({
        paid,
        name,
        value,
        dispatchInvoiceProduct,
        products,
    }) {
        if (!paid) {
            const product = find(products, {
                id: value,
            });
            if (product) {
                dispatchInvoiceProduct(name, product);
            }
        }
    },
});

const limitedProps = mapProps(
    ({
        meta,
        paid,
        salon,
        products,
        dispatchInvoiceProduct,
        change,
        ...props
    }) => ({
        ...props,
    }),
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
    handlers,
    withLifeCycle,
    limitedProps,
)(Select);
