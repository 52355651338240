/**
 * @flow
 */

import React from 'react';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import type { ModalDialog, Service } from '../../../../../type';

type DeleteServiceDialogProps = {
    service: Service,
} & ModalDialog;

const DeleteServiceDialog = ({
    service,
    trigger,
    open,
    onClose,
    onDelete,
}: DeleteServiceDialogProps) => (
    <Modal trigger={trigger} open={open} onClose={onClose} size="small" basic>
        <Header icon>
            <Icon name="remove" />
            <FormattedMessage
                id="pages.settings.services.delete.deleteHeader"
                defaultMessage="Delete Service"
            />
        </Header>
        <Modal.Content>
            <FormattedMessage
                id="pages.settings.services.delete.deleteText"
                defaultMessage="Are you sure you want to delete {serviceName} service ?"
                values={{
                    serviceName: service.name,
                }}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onClose} color="red" basic inverted>
                <Icon name="remove" />
                <FormattedMessage
                    id="pages.settings.services.delete.no"
                    defaultMessage="No"
                />
            </Button>
            <Button onClick={onDelete} color="green" inverted>
                <Icon name="checkmark" />
                <FormattedMessage
                    id="pages.settings.services.delete.yes"
                    defaultMessage="Yes"
                />
            </Button>
        </Modal.Actions>
    </Modal>
);

export default DeleteServiceDialog;
