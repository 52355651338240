/**
 *  @flow
 */

import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withPropsOnChange, withHandlers } from 'recompose';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import { omit, get, pick, findIndex, isEqual, keys } from 'lodash';
import { formatNumber } from 'libphonenumber-js';
import { injectIntl, defineMessages } from 'react-intl';

import PersonalInformation from '../../components/details/PersonalInformation';
import withUserLookup from '../../../../lib/withUserLookup';
import validate from '../form/supplierFormValidate';

import { GET_SUPPLIERS_QUERY, UPDATE_SUPPLIER_QUERY } from '../queries';

const intlMessages = defineMessages({
    existingsError: {
        id: 'pages.suppliers.updateSupplier.existingsError',
        defaultMessage: 'This supplier does not exists',
    },
    phoneExistingsError: {
        id: 'pages.suppliers.updateSupplier.phoneExistingsError',
        defaultMessage: 'Supplier with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.suppliers.updateSupplier.emailExistingsError',
        defaultMessage: 'Supplier with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.suppliers.updateSupplier.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withData = graphql(UPDATE_SUPPLIER_QUERY, {
    props: ({
        mutate,
        ownProps: { supplier, searchQuery, intl, onSupplierUpdate, onClose },
    }) => ({
        onSubmit: formData => {
            const input = Object.assign(
                {
                    supplierId: supplier.id,
                    phone: (formData.phone || '').replace(/[^+\d]/g, ''),
                },
                pick(formData, ['firstName', 'lastName', 'email', 'password']),
            );
            const mutation = mutate({
                variables: { input },
                // Implement optimistic response to compensate network latency
                // and update supplier directly in cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    updateSupplier: {
                        __typename: 'UpdateSupplierPayload',
                        supplier: {
                            __typename: 'Supplier',
                            ...input,
                        },
                    },
                },
                update: (store, response) => {
                    const updatedSupplier = get(
                        response,
                        'data.updateSupplier.supplier',
                    );
                    if (updatedSupplier && updatedSupplier.id !== supplier.id) {
                        const variables = {
                            search: searchQuery || undefined,
                            first: 20,
                            resolveRole: 'SYSADMIN',
                        };

                        const data = store.readQuery({
                            query: GET_SUPPLIERS_QUERY,
                            variables,
                        });

                        const idx = findIndex(
                            data.viewer.suppliers.edges,
                            edge => edge.node.id === supplier.id,
                        );

                        // replace updateable supplier
                        if (idx !== -1) {
                            data.viewer.suppliers.edges[
                                idx
                            ].node = updatedSupplier;
                        }

                        store.writeQuery({
                            query: GET_SUPPLIERS_QUERY,
                            variables,
                            data,
                        });
                    }
                },
            });

            return mutation
                .then(({ data: { updateSupplier } }) => {
                    if (onSupplierUpdate) {
                        onSupplierUpdate(updateSupplier.suplier);
                    }
                    onClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'NotExists') {
                            if (
                                graphQLError.data.error === 'ID_DOES_NOT_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.existingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (
                                graphQLError.data.error ===
                                'EMAIL_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    email: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: intl.formatMessage(
                                intlMessages.defaultErrorMessage,
                            ),
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const formatPhone = phone =>
    phone ? formatNumber(phone, 'International') : '+371';

const compareSupplierWithFormValues = (supplier, formValues) => {
    const pristineValues = pick(supplier, keys(formValues));
    pristineValues.phone = formatPhone(pristineValues.phone);
    return isEqual(formValues, pristineValues);
};

const props = withProps(({ supplier, userLookupStatus, formValues }) => ({
    // Make form as read-only if supplier is global
    readOnly:
        userLookupStatus === 'EXISTS' ||
        (supplier.type === 'GLOBAL' &&
            compareSupplierWithFormValues(
                supplier,
                omit(formValues, ['notes']),
            )),
}));

// Provide initial values to the form to fulfill fields with
// data from provided supplier
const withInitialValues = withPropsOnChange(
    (currentProps, nextProps) =>
        !isEqual(currentProps.userLookupUser, nextProps.userLookupUser),
    ({ open, supplier, userLookupUser, formValues }) => {
        if (open) {
            const fields = ['email', 'firstName', 'lastName'];
            let initialValues;
            if (userLookupUser) {
                initialValues = Object.assign(pick(userLookupUser, fields), {
                    phone: formatPhone(userLookupUser.phone),
                });
            } else {
                initialValues =
                    formValues ||
                    Object.assign(pick(supplier, fields), {
                        phone: formatPhone(supplier.phone),
                    });
            }
            return { initialValues };
        }
    },
);

const withForm = reduxForm({
    form: 'editSupplier',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

const withPristineHandlers = withHandlers({
    checkFormPristine: ({ formValues, supplier }) => () =>
        compareSupplierWithFormValues(supplier, formValues),
});

export default compose(
    connect(({ suppliers, ...state }) => ({
        searchQuery: suppliers.get('searchQuery'),
        formValues: getFormValues('editSupplier')(state),
    })),
    injectIntl,
    withData,
    withUserLookup,
    props,
    withInitialValues,
    withForm,
    withPristineHandlers,
)(PersonalInformation);
